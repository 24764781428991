/**
 * @generated SignedSource<<f8d1b0341afac993c1647a31b47d6a9d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AsideSection_EssentialsOverview_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EquityPlanParametersCard_EssentialsOverview_Organization" | "EquityRepartitionCard_EssentialsOverview_Organization">;
  readonly " $fragmentType": "AsideSection_EssentialsOverview_Organization";
};
export type AsideSection_EssentialsOverview_Organization$key = {
  readonly " $data"?: AsideSection_EssentialsOverview_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"AsideSection_EssentialsOverview_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AsideSection_EssentialsOverview_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquityPlanParametersCard_EssentialsOverview_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquityRepartitionCard_EssentialsOverview_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "4168c0b7e1222220934a8d20ff1fe8e3";

export default node;
