import { graphql, useFragment } from "react-relay";

import {
  BentoCard,
  BentoCardCell,
  BentoCardRow,
} from "../../../../components/BentoCard";
import { EmployeeVestingGraph } from "../../../../components/EmployeeVestingGraph";
import { Section } from "../../Grantee/GranteeDetails/Section";
import { GrantDetailsVestingScheduleSection_CTMSGrant$key } from "./__generated__/GrantDetailsVestingScheduleSection_CTMSGrant.graphql";
import { GrantDetailsVestingScheduleSection_Organization$key } from "./__generated__/GrantDetailsVestingScheduleSection_Organization.graphql";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment GrantDetailsVestingScheduleSection_CTMSGrant on CTMSGrant {
    ...EmployeeVestingGraph_CTMSGrants
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment GrantDetailsVestingScheduleSection_Organization on Organization {
    ...EmployeeVestingGraph_Organization
  }
`;

export function GrantDetailsVestingScheduleSection({
  ctmsGrantFragment,
  employeeId,
  organizationFragment,
}: {
  ctmsGrantFragment: GrantDetailsVestingScheduleSection_CTMSGrant$key;
  employeeId: string;
  organizationFragment: GrantDetailsVestingScheduleSection_Organization$key;
}) {
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <Section title="Vesting schedule">
      <BentoCard>
        <BentoCardRow>
          <BentoCardCell>
            <div className="space-y-8">
              <div className="h-[348px] w-full">
                <EmployeeVestingGraph
                  ctmsGrantsFragment={[ctmsGrant]}
                  groupBy="month"
                  organizationFragment={organization}
                  randomColorSeed={employeeId}
                  view="split"
                />
              </div>
            </div>
          </BentoCardCell>
        </BentoCardRow>
      </BentoCard>
    </Section>
  );
}
