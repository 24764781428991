import { Text } from "@remote-com/norma";
import classNames from "classnames";

export function RemoteLikeFakeInput({
  label,
  tone = "muted",
  value,
}: {
  label: React.ReactNode;
  tone?: "accent" | "muted";
  value: React.ReactNode;
}) {
  return (
    <div
      className={classNames("rounded-remote-md border px-4 py-2", {
        "border-dashed border-grey-500 bg-grey-100": tone === "muted",
        "border-purple-600 bg-purple-100": tone === "accent",
      })}
    >
      <Text className="text-grey-600" variant="xs">
        {label}
      </Text>
      <Text className="text-grey-900" variant="sm">
        {value}
      </Text>
    </div>
  );
}
