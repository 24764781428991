import {
  ArrowPathIcon,
  EllipsisHorizontalIcon,
  FolderArrowDownIcon,
  Square2StackIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { compact } from "lodash";
import { forwardRef, useCallback, useMemo, useTransition } from "react";
import { useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import {
  DraftGrantDeletionConfirmationModal,
  useDraftGrantDeletionConfirmationModalState,
} from "../../../../components/DraftGrantDeletionConfirmationModal";
import { DraftWatchoutTag } from "../../../../components/DraftWatchoutTag";
import { FormattedFMV } from "../../../../components/FormattedFMV";
import { GrantFormSlideRemote } from "../../../../components/GrantForm/GrantFormSlide";
import { GrantsTableStatusTag } from "../../../../components/GrantsTableStatusTag";
import { LongDate } from "../../../../components/LongDate";
import { RefresherTag } from "../../../../components/RefresherTag";
import { ShortDate } from "../../../../components/ShortDate";
import { StopClickPropagationWrapper } from "../../../../components/StopClickPropagationWrapper";
import { useToaster } from "../../../../components/Toaster";
import { Button } from "../../../../components/ui/Button";
import { Checkbox } from "../../../../components/ui/Form/Checkbox";
import { MenuButton } from "../../../../components/ui/MenuButton";
import { NoticeMessage } from "../../../../components/ui/NoticeMessage";
import { Table } from "../../../../components/ui/Table";
import { TableCellGrantee } from "../../../../components/ui/Table/TableCellGrantee";
import { TableCellSharesOverview } from "../../../../components/ui/Table/TableCellSharesOverview";
import { Tag } from "../../../../components/ui/Tag";
import { Toast } from "../../../../components/ui/Toast";
import { TooltipContainer } from "../../../../components/ui/TooltipContainer";
import { Typography } from "../../../../components/ui/Typography";
import { useDownloadGrantDocumentationForImplementation } from "../../../../hooks/useDownloadGrantDocumentationForImplementation";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import { APPLICATION_ROUTES } from "../../../../paths";
import { GrantsTable_Account$key } from "./__generated__/GrantsTable_Account.graphql";
import {
  GrantsTable_EasopGrant$data,
  GrantsTable_EasopGrant$key,
} from "./__generated__/GrantsTable_EasopGrant.graphql";
import {
  GrantsTable_Organization$data,
  GrantsTable_Organization$key,
} from "./__generated__/GrantsTable_Organization.graphql";
import { GrantsTable_RetryCreateCTMSDraftForEasopGrantJob_Mutation } from "./__generated__/GrantsTable_RetryCreateCTMSDraftForEasopGrantJob_Mutation.graphql";
import {
  EASOP_GRANT_OUTDATED_REASON_MAP,
  WARNING_VALUATION_EXPIRED_MESSAGE_MAP,
  WarningEasopGrantsOutdated,
} from "./useInstrumentValuationGuard";
import { WarningMismatchBetweenCtmsAndEasopCapTableMessage } from "./useMismatchBetweenCtmsAndEasopCapTableGuard";
import { WarningTaxFavoredSubplanExpiredMessage } from "./useTaxFavoredSubplanExpiredGuard";

const ORGANIZATION_FRAGMENT = graphql`
  fragment GrantsTable_Organization on Organization {
    id
    name
    equityGrid {
      activated
    }
    ...TableCellSharesOverview_Organization
    ...DraftWatchoutTag_Organization
    ...useTaxFavoredSubplanExpiredGuard_Organization
    ...GrantsTableStatusTag_Organization
    ...useDownloadGrantDocumentationForImplementation_Organization
    ...FormattedFMV_Organization
  }
`;

const EASOP_GRANT_FRAGMENT = graphql`
  fragment GrantsTable_EasopGrant on EasopGrant
  @argumentDefinitions(organizationId: { type: "OrganizationId!" })
  @relay(plural: true) {
    id
    label
    grantee {
      id
      name
      contractStartDate
      ...TableCellGrantee_Grantee
    }
    instrument {
      equityType {
        name
      }
    }
    vestingStartDate
    quantityGranted
    grantStatus
    warnings
    notReadyForBoardConsentReasons
    isDeletable
    isEditable
    exercisePrice
    instrumentValuationType
    createCTMSDraftJobStatus
    ...DraftWatchoutTag_EasopGrant @arguments(organizationId: $organizationId)
    ...RefresherTag_EasopGrant
    ...useInstrumentValuationGuard_Grants
      @arguments(organizationId: $organizationId)
    ...GrantsTableStatusTag_EasopGrant
    ...useDownloadGrantDocumentationForImplementation_EasopGrant
      @arguments(organizationId: $organizationId)
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment GrantsTable_Account on Account {
    isSuperAdmin
  }
`;

const RETRY_CREATE_CTMS_DRAFT_FOR_EASOP_GRANT_JOB_MUTATION = graphql`
  mutation GrantsTable_RetryCreateCTMSDraftForEasopGrantJob_Mutation(
    $easopGrantId: ID!
  ) {
    retryCreateCTMSDraftForEasopGrantJob(easopGrantId: $easopGrantId) {
      createCTMSDraftJobStatus
    }
  }
`;

const BlockedTooltipContent: React.FC<{
  grant: GrantsTable_EasopGrant$data[number];
  organization: GrantsTable_Organization$data;
}> = ({ grant, organization }) => {
  return (
    <>
      {grant.notReadyForBoardConsentReasons.map((reason) => {
        switch (reason) {
          case "CAP_TABLE_MISMATCH":
            return (
              <p>
                <WarningMismatchBetweenCtmsAndEasopCapTableMessage />
              </p>
            );
          case "GRANTEE_CONTRACT_NOT_STARTED":
            return (
              <p>
                The grantee’s contract start date is set in the future
                {grant.grantee.contractStartDate ? (
                  <>
                    , on <LongDate value={grant.grantee.contractStartDate} />
                  </>
                ) : null}
                .
                <br />
                We’ll let you release this draft to the board on the grantee’s
                first day at the company
              </p>
            );
          case "NOT_REVIEWED":
            return null;
          case "TAX_FAVORED_SUBPLAN_EXPIRED":
            return (
              <p>
                <WarningTaxFavoredSubplanExpiredMessage
                  organizationName={organization.name}
                />
              </p>
            );
          case "VALUATION_EXPIRED":
          case "VALUATION_MANUALLY_INVALIDATED":
          case "VALUATION_MISSING":
          case "VALUATION_NOT_BOARD_APPROVED":
          case "VALUATION_PENDING_BOARD_APPROVAL":
            return (
              <p>
                {
                  WARNING_VALUATION_EXPIRED_MESSAGE_MAP[
                    grant.instrumentValuationType
                  ]
                }
              </p>
            );
          case "VALUATION_OUTDATED":
            return (
              <p>
                {EASOP_GRANT_OUTDATED_REASON_MAP[grant.instrumentValuationType]}
              </p>
            );
        }
      })}
    </>
  );
};

const DownloadDocumentationButton = forwardRef<
  HTMLButtonElement,
  {
    easopGrant: GrantsTable_EasopGrant$data[number];
    organization: GrantsTable_Organization$data;
  }
>(function DownloadDocumentationButton({ easopGrant, organization }, ref) {
  const downloadGrantDocumentation =
    useDownloadGrantDocumentationForImplementation({
      easopGrantFragment: easopGrant,
      organizationFragment: organization,
    });

  return (
    <MenuButton.Item
      leftIcon={<FolderArrowDownIcon />}
      onClick={downloadGrantDocumentation}
      ref={ref}
    >
      Download documentation
    </MenuButton.Item>
  );
});

const columnHelper = createColumnHelper<GrantsTable_EasopGrant$data[number]>();

type Column =
  | "Actions"
  | "Details"
  | "Draft"
  | "Employee"
  | "Grant type"
  | "Ownership"
  | "Status"
  | "Strike price";

export const GrantsTable: React.FC<{
  columns: Column[];
  easopGrantsFragment: GrantsTable_EasopGrant$key;
  emptyListPlaceholder?: React.ReactNode;
  grantSelection?: Set<string>;
  loading?: boolean;
  onGrantSelectionChange?: (selection: Set<string>) => void;
  onUpdate: () => void;
  organizationFragment: GrantsTable_Organization$key;
  showBoardConsentReadinessStatus?: boolean;
  viewerFragment: GrantsTable_Account$key;
}> = ({
  columns: _columns,
  easopGrantsFragment,
  emptyListPlaceholder,
  grantSelection,
  loading,
  onGrantSelectionChange,
  onUpdate,
  organizationFragment,
  showBoardConsentReadinessStatus,
  viewerFragment,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const easopGrants = useFragment(EASOP_GRANT_FRAGMENT, easopGrantsFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const enableRowSelection = useMemo(() => !!grantSelection, [grantSelection]);
  const [updateTransitionInProgress, startUpdateTransition] = useTransition();

  const handleUpdate = useCallback(() => {
    startUpdateTransition(() => {
      onUpdate();
    });
  }, [onUpdate]);

  const toaster = useToaster();

  const {
    closeDraftGrantDeletionConfirmationModal,
    draftGrantDeletionConfirmationModalState,
    showDraftGrantDeletionConfirmationModal,
  } = useDraftGrantDeletionConfirmationModalState();

  const someGrantsAreOutdated = useMemo(
    () =>
      easopGrants.some(({ warnings }) =>
        warnings.includes("VALUATION_OUTDATED"),
      ),
    [easopGrants],
  );

  const [_retryCreateCTMSDraftForEasopGrantJob] =
    useSafeMutation<GrantsTable_RetryCreateCTMSDraftForEasopGrantJob_Mutation>(
      RETRY_CREATE_CTMS_DRAFT_FOR_EASOP_GRANT_JOB_MUTATION,
    );

  const retryCreateCTMSDraftForEasopGrantJob = useCallback(
    async (easopGrantId: string) => {
      await _retryCreateCTMSDraftForEasopGrantJob({
        variables: { easopGrantId },
      });
      toaster.push(
        <Toast title="Wonderful!">
          The draft creation is being processed. It might take some time to
          complete.
        </Toast>,
      );
    },
    [_retryCreateCTMSDraftForEasopGrantJob, toaster],
  );

  const columns = useMemo(
    () =>
      compact([
        enableRowSelection &&
          columnHelper.display({
            cell: (context) => (
              <Checkbox
                checked={context.row.getIsSelected()}
                onChange={context.row.getToggleSelectedHandler()}
                onClick={(event) => {
                  event.preventDefault();
                }}
              />
            ),
            header: (context) => (
              <Checkbox
                checked={context.table.getIsAllRowsSelected()}
                onChange={context.table.getToggleAllPageRowsSelectedHandler()}
              />
            ),
            id: "select",
          }),
        _columns.includes("Draft") &&
          columnHelper.accessor((row) => row.label, {
            cell: (context) => {
              const grant = context.row.original;

              return (
                <div className="flex items-center gap-2">
                  <div className="flex flex-wrap items-center gap-2">
                    <Tag color="gray">{context.getValue()}</Tag>
                    {grant.warnings.includes("VALUATION_OUTDATED") && (
                      <Tag color="red">Outdated</Tag>
                    )}
                    {grant.warnings.includes(
                      "VALUATION_NOT_BOARD_APPROVED",
                    ) && <Tag color="orange">Pending 409A</Tag>}
                    {showBoardConsentReadinessStatus &&
                      grant.notReadyForBoardConsentReasons.some(
                        (reason) => reason !== "NOT_REVIEWED",
                      ) && (
                        <TooltipContainer
                          tooltipContent={
                            <BlockedTooltipContent
                              grant={grant}
                              organization={organization}
                            />
                          }
                        >
                          <Tag color="red">Blocked</Tag>
                        </TooltipContainer>
                      )}
                  </div>
                </div>
              );
            },
            enableSorting: true,
            header: () => "Draft",
            id: "label",
          }),
        _columns.includes("Employee") &&
          columnHelper.accessor((row) => row.grantee.name, {
            cell: (context) => (
              <TableCellGrantee
                granteeFragment={context.row.original.grantee}
              />
            ),
            enableSorting: true,
            header: () => "Employee",
            id: "grantee.name",
          }),

        _columns.includes("Ownership") &&
          columnHelper.accessor((row) => row.quantityGranted, {
            cell: (context) => {
              const totalGrantedShares = context.getValue();
              return (
                <TableCellSharesOverview
                  organizationFragment={organization}
                  shares={totalGrantedShares}
                />
              );
            },
            enableSorting: true,
            header: () => "Ownership",
            id: "ownership",
            meta: { alignRight: true },
          }),

        _columns.includes("Details") &&
          columnHelper.accessor((row) => row.instrument.equityType?.name, {
            cell: (context) => {
              const grant = context.row.original;
              const equityType = grant.instrument.equityType;

              return (
                <div className="space-y-1">
                  <Typography as="div" variant="Regular/Extra Small">
                    {equityType ? equityType.name : "-"}
                  </Typography>
                  <Typography
                    as="div"
                    className="text-black-05"
                    variant="Regular/Caption"
                  >
                    <ShortDate value={grant.vestingStartDate} />
                  </Typography>
                </div>
              );
            },
            enableSorting: true,
            header: () => "Details",
            id: "grant-details",
          }),

        _columns.includes("Strike price") &&
          columnHelper.accessor((row) => row.exercisePrice, {
            cell: (context) => {
              const exercisePrice = context.getValue();
              return (
                <Typography variant="Regular/Extra Small">
                  <FormattedFMV
                    organizationFragment={organization}
                    value={exercisePrice}
                  />
                </Typography>
              );
            },
            enableSorting: true,
            header: () => "Strike Price",
            id: "strike-price",
          }),

        _columns.includes("Grant type") &&
          columnHelper.accessor((row) => row.instrument.equityType?.name, {
            cell: (context) => {
              const grant = context.row.original;
              return <RefresherTag grantFragment={grant} />;
            },
            enableSorting: true,
            header: () => "Grant type",
            id: "type",
          }),

        _columns.includes("Status") &&
          columnHelper.display({
            cell: (contex) => {
              const easopGrant = contex.row.original;
              return (
                <GrantsTableStatusTag
                  easopGrantFragment={easopGrant}
                  organizationFragment={organization}
                />
              );
            },
            header: () => "Status",
            id: "status",
          }),

        _columns.includes("Actions") &&
          columnHelper.accessor((row) => row, {
            cell: (context) => {
              const grant = context.getValue();
              return (
                <StopClickPropagationWrapper className="flex w-full items-center justify-end gap-2">
                  <DraftWatchoutTag.Watchout
                    grantFragment={grant}
                    organizationFragment={organization}
                  />
                  {organization.equityGrid.activated && (
                    <DraftWatchoutTag.EquityGridWatchout
                      grantFragment={grant}
                      organizationFragment={organization}
                    />
                  )}

                  {grant.grantStatus !== "Reviewed" && (
                    <MenuButton
                      button={
                        <Button
                          leftIcon={<EllipsisHorizontalIcon />}
                          size="extra small"
                          variant="Secondary Full"
                        />
                      }
                      placement="bottom end"
                    >
                      <GrantFormSlideRemote.Controller
                        render={({ open, transitionInProgress }) => (
                          <MenuButton.Item
                            leftIcon={<Square2StackIcon />}
                            loading={transitionInProgress}
                            onClick={() => {
                              open({
                                data: {
                                  duplication: true,
                                  easopGrantId: grant.id,
                                  onGrantCreated: handleUpdate,
                                  onGrantDeleted: handleUpdate,
                                  onGrantUpdated: handleUpdate,
                                  organizationId: organization.id,
                                },
                              });
                            }}
                          >
                            Duplicate
                          </MenuButton.Item>
                        )}
                      />
                      {grant.isDeletable && (
                        <MenuButton.Item
                          leftIcon={<TrashIcon />}
                          onClick={() => {
                            showDraftGrantDeletionConfirmationModal(grant.id);
                          }}
                          variant="Danger"
                        >
                          Delete draft
                        </MenuButton.Item>
                      )}
                      {viewer.isSuperAdmin &&
                        grant.createCTMSDraftJobStatus === "FAILED" && (
                          <MenuButton.Item
                            leftIcon={<ArrowPathIcon />}
                            onClick={() =>
                              retryCreateCTMSDraftForEasopGrantJob(grant.id)
                            }
                          >
                            Retry draft creation
                          </MenuButton.Item>
                        )}
                      {viewer.isSuperAdmin &&
                        grant.grantStatus === "PendingCtmsImplementation" && (
                          <DownloadDocumentationButton
                            easopGrant={grant}
                            organization={organization}
                          />
                        )}
                    </MenuButton>
                  )}

                  {grant.isEditable && (
                    <GrantFormSlideRemote.Controller
                      render={({ open, transitionInProgress }) => (
                        <Button
                          loading={transitionInProgress}
                          onClick={(event) => {
                            event.preventDefault();

                            open({
                              data: {
                                easopGrantId: grant.id,
                                onGrantCreated: handleUpdate,
                                onGrantDeleted: handleUpdate,
                                onGrantUpdated: handleUpdate,
                                organizationId: organization.id,
                              },
                            });
                          }}
                          size="extra small"
                          type="button"
                          variant="Secondary Outline"
                        >
                          Edit
                        </Button>
                      )}
                    />
                  )}
                </StopClickPropagationWrapper>
              );
            },
            enableSorting: false,
            header: () => "Action",
            id: "actions",
            meta: { alignRight: true },
          }),
      ]),
    [
      enableRowSelection,
      _columns,
      showBoardConsentReadinessStatus,
      organization,
      viewer.isSuperAdmin,
      handleUpdate,
      showDraftGrantDeletionConfirmationModal,
      retryCreateCTMSDraftForEasopGrantJob,
    ],
  );

  const data = useMemo(() => [...easopGrants], [easopGrants]);

  const rowSelection = useMemo(
    () =>
      data.reduce(
        (rowSelection: Record<string, boolean>, grant, index) => ({
          ...rowSelection,
          [index]: grantSelection?.has(grant.id),
        }),
        {},
      ),
    [data, grantSelection],
  );

  const table = useReactTable({
    columns,
    data,
    enableRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: (getSelection) => {
      if (typeof getSelection === "function") {
        const newRowSelection = getSelection(rowSelection);

        onGrantSelectionChange?.(
          data.reduce((set, grant, index) => {
            if (newRowSelection[index]) {
              set.add(grant.id);
            }

            return set;
          }, new Set<string>()),
        );
      }
    },
    state: {
      rowSelection,
    },
  });

  const handleDraftGrantDeletionConfirmationModalGrantDeleted =
    useCallback(() => {
      closeDraftGrantDeletionConfirmationModal();
      handleUpdate();
    }, [closeDraftGrantDeletionConfirmationModal, handleUpdate]);

  return (
    <>
      <DraftGrantDeletionConfirmationModal
        onClose={closeDraftGrantDeletionConfirmationModal}
        onGrantDeleted={handleDraftGrantDeletionConfirmationModalGrantDeleted}
        state={draftGrantDeletionConfirmationModalState}
      />
      <div className="w-full">
        {easopGrants.length === 0 ? (
          emptyListPlaceholder && (
            <NoticeMessage size="Large">{emptyListPlaceholder}</NoticeMessage>
          )
        ) : (
          <div className="flex flex-col gap-4">
            {someGrantsAreOutdated ? (
              <WarningEasopGrantsOutdated easopGrantsFragment={easopGrants} />
            ) : null}
            <Table.Smart
              loading={updateTransitionInProgress || loading}
              rowRenderer={({ cells, rowData }) => (
                <Table.LinkRow
                  to={generatePath(
                    APPLICATION_ROUTES.organizationPrepareYourGrantsDetails,
                    {
                      easopGrantId: rowData.original.id,
                      organizationId: organization.id,
                    },
                  )}
                >
                  {cells}
                </Table.LinkRow>
              )}
              table={table}
              tableClassName="min-w-[720px]"
            />
          </div>
        )}
      </div>
    </>
  );
};
