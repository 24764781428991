/**
 * @generated SignedSource<<f11aec3e2ff73556a3e2225d9edbff7b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FormattedFMV_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FormattedCurrency_Organization">;
  readonly " $fragmentType": "FormattedFMV_Organization";
};
export type FormattedFMV_Organization$key = {
  readonly " $data"?: FormattedFMV_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"FormattedFMV_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FormattedFMV_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedCurrency_Organization"
    }
  ],
  "type": "IOrganization",
  "abstractKey": "__isIOrganization"
};

(node as any).hash = "9ad6d69607084f4558c8ba5edcb9ad8c";

export default node;
