/**
 * @generated SignedSource<<f0dd22223ca4570edeb13e520a05f73f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GranteeStatus = "Active" | "Terminated";
import { FragmentRefs } from "relay-runtime";
export type TeamIncentivization_Organization$data = {
  readonly grantees: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly grantedSharesLatestValuation: number | null;
        readonly status: GranteeStatus;
      };
    }>;
  };
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"CurrencySymbol_Organization" | "FormattedCurrency_Organization" | "GranteesListSlideOver_Organization" | "TeamIncentivizationGraph_Organization">;
  readonly " $fragmentType": "TeamIncentivization_Organization";
};
export type TeamIncentivization_Organization$key = {
  readonly " $data"?: TeamIncentivization_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"TeamIncentivization_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TeamIncentivization_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GranteesConnection",
      "kind": "LinkedField",
      "name": "grantees",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GranteeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Grantee",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "grantedSharesLatestValuation",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TeamIncentivizationGraph_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteesListSlideOver_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurrencySymbol_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedCurrency_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "6743a1757e6d052343494b92b6e8731d";

export default node;
