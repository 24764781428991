import {
  ArrowDownTrayIcon,
  FolderOpenIcon,
  TicketIcon,
} from "@heroicons/react/24/outline";
import { saveAs } from "file-saver";
import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import { BackButton } from "../../../components/BackButton";
import { GranteeNameWithCountryFlag } from "../../../components/GranteeNameWithCountryFlag";
import { Page } from "../../../components/Page";
import { BreadCrumb } from "../../../components/ui/BreadCrumb";
import { Button } from "../../../components/ui/Button";
import { OneColumnLayout } from "../../../components/ui/Layout/OneColumnLayout";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { Tag } from "../../../components/ui/Tag";
import { TooltipContainer } from "../../../components/ui/TooltipContainer";
import { Typography } from "../../../components/ui/Typography";
import { useTrackDocumentsDownloaded } from "../../../hooks/useAnalytics";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { GrantDocumentationPage_GrantCard$key } from "./__generated__/GrantDocumentationPage_GrantCard.graphql";
import { GrantDocumentationPage_Organization$key } from "./__generated__/GrantDocumentationPage_Organization.graphql";
import { GrantDocumentationPage_Query } from "./__generated__/GrantDocumentationPage_Query.graphql";
import { useGrantDocuments } from "./useGrantDocuments";

const GRANT_CARD_FRAGMENT = graphql`
  fragment GrantDocumentationPage_GrantCard on EasopGrant {
    ...useGrantDocuments_EasopGrant
    label
    quantityGranted
    instrument {
      awardSuperType
    }
    grantee {
      name
      ...GranteeNameWithCountryFlag_Grantee
    }
    signedBoardConsentDocument {
      __typename
    }
  }
`;

const GrantCard_ = (
  {
    easopGrantFragment,
  }: {
    easopGrantFragment: GrantDocumentationPage_GrantCard$key;
  },
  ref: React.ComponentProps<"div">["ref"],
) => {
  const easopGrant = useFragment(GRANT_CARD_FRAGMENT, easopGrantFragment);
  const grantDocuments = useGrantDocuments({
    easopGrantFragment: easopGrant,
  });

  const trackDocumentsDownloaded = useTrackDocumentsDownloaded();
  const handleDownloadGrantDocumentationButtonClick = useCallback(() => {
    trackDocumentsDownloaded({
      documentsTypes: _(grantDocuments)
        .map(({ type }) => type)
        .uniq()
        .value(),
      downloadType: "MULTIPLE",
    });
    grantDocuments.forEach((item) => {
      saveAs(item.downloadUrl, item.fileName);
    });
  }, [grantDocuments, trackDocumentsDownloaded]);

  return (
    <div
      className="group flex h-40 select-none flex-col justify-between rounded-lg border-[0.5px] border-gray-03 bg-white p-4 transition-all hover:bg-gray-01"
      ref={ref}
    >
      <div className="flex flex-row justify-between">
        <div className="flex flex-row items-center gap-2">
          <div className="h-8 w-8 rounded-lg bg-primary-01">
            <FolderOpenIcon className="h-full w-full text-primary-05" />
          </div>
          <div className="text-sm text-gray-09">{easopGrant.label}</div>
        </div>
        <div>
          <span className="rounded bg-green-01 px-2 py-1 text-xs text-green-06">
            {easopGrant.instrument.awardSuperType}
          </span>
        </div>
      </div>
      <div className="flex gap-1">
        <div className="min-w-0 flex-1">
          <div className="truncate text-sm text-black-07">
            <GranteeNameWithCountryFlag granteeFragment={easopGrant.grantee} />
          </div>
          <div className="mt-2 flex items-center gap-1">
            <TicketIcon className="block h-4 w-4 shrink-0 text-primary-05" />
            <div className="truncate text-xs text-gray-09">
              <FormattedMessage
                defaultMessage={`{quantityGranted, plural, 
                  one {# share}
                  other {# shares}
                }`}
                values={{
                  quantityGranted: easopGrant.quantityGranted,
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex shrink-0 items-end">
          <Button
            disabled={!easopGrant.signedBoardConsentDocument}
            leftIcon={<ArrowDownTrayIcon />}
            onClick={(event) => {
              event.preventDefault();
              handleDownloadGrantDocumentationButtonClick();
            }}
            size="extra small"
            type="button"
            variant="Secondary Full"
          />
        </div>
      </div>
    </div>
  );
};

const GrantCard = React.forwardRef(GrantCard_);

const ORGANIZATION_FRAGMENT = graphql`
  fragment GrantDocumentationPage_Organization on Organization {
    id
    name
    easopGrants(filters: { orderBy: { field: label, direction: ASC } }) {
      ...GrantDocumentationPage_GrantCard
      id
      label
      signedBoardConsentDocument {
        __typename
      }
    }
  }
`;

const AdminDocumentsGrantDocumentationPage_: React.FC<{
  organizationFragment: GrantDocumentationPage_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const easopGrantsWithSignedDocumentsCount = useMemo(
    () =>
      organization.easopGrants.filter(
        (easopGrant) => easopGrant.signedBoardConsentDocument,
      ).length,
    [organization.easopGrants],
  );

  return (
    <OneColumnLayout
      Breadcrumb={
        <BreadCrumb>
          <BreadCrumb.Link
            to={generatePath(APPLICATION_ROUTES.organizationHome, {
              organizationId: organization.id,
            })}
          >
            {organization.name}
          </BreadCrumb.Link>
          <BreadCrumb.Link to="..">Documents</BreadCrumb.Link>
          <BreadCrumb.Link to=".">Grant documentation</BreadCrumb.Link>
        </BreadCrumb>
      }
      className="space-y-6"
      showFooter
    >
      <RoundedBox className="space-y-4 p-6" withBorder withShadow>
        <div className="flex items-center gap-4">
          <BackButton />
          <div className="flex items-center gap-4">
            <Typography variant="Medium/Default">
              Grant documentation
            </Typography>
            <Tag className="uppercase">
              <FormattedMessage
                defaultMessage="{count, plural, one {# grant} other {# grants}}"
                values={{
                  count: easopGrantsWithSignedDocumentsCount,
                }}
              />
            </Tag>
          </div>
        </div>
        <div className="laptop:grid-cols-2 grid grid-cols-4 gap-6">
          {organization.easopGrants.map((easopGrant) => {
            const card = <GrantCard easopGrantFragment={easopGrant} />;

            if (!easopGrant.signedBoardConsentDocument) {
              return (
                <TooltipContainer
                  key={easopGrant.id}
                  tooltipContent="Board consent must be signed first in order to generate grant documentation"
                >
                  {card}
                </TooltipContainer>
              );
            }

            return (
              <Link
                key={easopGrant.id}
                to={generatePath(
                  APPLICATION_ROUTES.organizationDocumentsGrantDocumentationDetails,
                  {
                    easopGrantId: easopGrant.id,
                    organizationId: organization.id,
                  },
                )}
              >
                {card}
              </Link>
            );
          })}
        </div>
      </RoundedBox>
    </OneColumnLayout>
  );
};

const QUERY = graphql`
  query GrantDocumentationPage_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...GrantDocumentationPage_Organization
    }
  }
`;

const AdminDocumentsGrantDocumentationPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const query = useLazyLoadQuery<GrantDocumentationPage_Query>(
    QUERY,
    {
      organizationId,
    },
    { fetchPolicy: "store-and-network" },
  );

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Documents"
      analyticsName="Admin - Documents Grants"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} grants documentation`}
    >
      <AdminDocumentsGrantDocumentationPage_
        organizationFragment={query.organization}
      />
    </Page>
  );
};

export default AdminDocumentsGrantDocumentationPage;
