import { useEffect } from "react";
import { Control, useController, useWatch } from "react-hook-form";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { CurrencySymbol } from "../../../../../components/CurrencySymbol";
import {
  GrantedSharesInput,
  useGrantedSharesController,
} from "../../../../../components/GrantedSharesInput";
import { FormRow } from "../../../../../components/ui/Form/FormRow";
import { Input } from "../../../../../components/ui/Form/Inputs/Input";
import { MaskedInput } from "../../../../../components/ui/Form/Inputs/MaskedInput";
import { EquityGridLevelForm_ConfigureGrants_Organization$key } from "./__generated__/EquityGridLevelForm_ConfigureGrants_Organization.graphql";
import { FormValues } from "./FORM_SCHEMA";

const ORGANIZATION_FRAGMENT = graphql`
  fragment EquityGridLevelForm_ConfigureGrants_Organization on Organization {
    fullyDilutedShares
    latestPricePerShare {
      value
    }
    ...CurrencySymbol_Organization
    ...GrantedSharesInput_Organization
  }
`;

export function EquityGridLevelForm({
  control,
  id,
  onSubmit,
  organizationFragment,
}: {
  control: Control<FormValues>;
  id?: string;
  onSubmit: React.ComponentProps<"form">["onSubmit"];
  organizationFragment: EquityGridLevelForm_ConfigureGrants_Organization$key;
}) {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const targetInUSD = useWatch({
    control,
    name: "equityInUsd",
  });
  const grantedSharesInputController = useGrantedSharesController({
    fullyDilutedShares: organization.fullyDilutedShares,
    initialState: { mode: "USD_VALUE", value: targetInUSD },
    latestPricePerShare: organization.latestPricePerShare?.value ?? null,
  });
  const grantedSharesController = useController({
    control,
    name: "equityInUsd",
  });

  useEffect(() => {
    grantedSharesController.field.onChange(
      grantedSharesInputController.value
        ? Math.round(grantedSharesInputController.value)
        : undefined,
    );
  }, [grantedSharesController.field, grantedSharesInputController.value]);

  return (
    <form className="block space-y-6" id={id} onSubmit={onSubmit}>
      <FormRow.Form control={control} label="Level name" name="name">
        <Input.Form control={control} name="name" placeholder="IC-1" />
      </FormRow.Form>
      <FormRow.Form control={control} label="Role" name="role">
        <Input.Form control={control} name="role" placeholder="e.g. Entry" />
      </FormRow.Form>
      <FormRow.Form
        control={control}
        label={
          <>
            <CurrencySymbol organizationFragment={organization} /> Equity (fully
            diluted)
          </>
        }
        name="equityInUsd"
      >
        <GrantedSharesInput
          {...grantedSharesInputController.inputController}
          autoFocus
          organizationFragment={organization}
        />
      </FormRow.Form>
      <FormRow.Form
        control={control}
        label="Yearly salary (optional)"
        name="yearlySalary"
      >
        <MaskedInput.FormCurrency
          control={control}
          name="yearlySalary"
          placeholder="$15.000"
        />
      </FormRow.Form>
    </form>
  );
}
