/**
 * @generated SignedSource<<b7842f5e799f5dd104f587b0009cbf06>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PendingApproval_FairMarketValueApprovals_Query$variables = {
  organizationId: string;
};
export type PendingApproval_FairMarketValueApprovals_Query$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"PendingApproval_FairMarketValueApprovals_Viewer">;
  };
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"PendingApproval_FairMarketValueApprovals_Organization">;
  } | null;
};
export type PendingApproval_FairMarketValueApprovals_Query = {
  response: PendingApproval_FairMarketValueApprovals_Query$data;
  variables: PendingApproval_FairMarketValueApprovals_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PendingApproval_FairMarketValueApprovals_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PendingApproval_FairMarketValueApprovals_Organization"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PendingApproval_FairMarketValueApprovals_Viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PendingApproval_FairMarketValueApprovals_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FairMarketValueBoardConsent",
            "kind": "LinkedField",
            "name": "pendingApprovalFairMarketValueBoardConsents",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "voidedReason",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isPandaDocDocumentSent",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canBoardMemberSignatureRequestReminderEmailsBeSent",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SignatureRequest",
                "kind": "LinkedField",
                "name": "signatureRequests",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "completedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "signatoryFirstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "signatoryLastName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "FairMarketValue",
                "kind": "LinkedField",
                "name": "fairMarketValue",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "date",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "valuationFirm",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Document",
                    "kind": "LinkedField",
                    "name": "valuationReportDocument",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "downloadUrl",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isBoardConsent"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToCarta",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "defaultCurrencyCode",
                "storageKey": null
              }
            ],
            "type": "IOrganization",
            "abstractKey": "__isIOrganization"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isSuperAdmin",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3e6545e7fb8e2ec13e3f6cee36f7d0bd",
    "id": null,
    "metadata": {},
    "name": "PendingApproval_FairMarketValueApprovals_Query",
    "operationKind": "query",
    "text": "query PendingApproval_FairMarketValueApprovals_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    id\n    name\n    ...PendingApproval_FairMarketValueApprovals_Organization\n  }\n  me {\n    ...PendingApproval_FairMarketValueApprovals_Viewer\n    id\n  }\n}\n\nfragment FairMarketValueApprovalTable_FairMarketValue on FairMarketValue {\n  id\n  date\n  value\n  valuationFirm\n  status\n  valuationReportDocument {\n    downloadUrl\n    id\n  }\n}\n\nfragment FairMarketValueApprovalTable_Organization on Organization {\n  ...FormattedFMV_Organization\n}\n\nfragment Footer_Organization on Organization {\n  isConnectedToCarta\n}\n\nfragment FormattedCurrency_Organization on IOrganization {\n  __isIOrganization: __typename\n  defaultCurrencyCode\n}\n\nfragment FormattedFMV_Organization on IOrganization {\n  __isIOrganization: __typename\n  ...FormattedCurrency_Organization\n}\n\nfragment LargeOneColumnLayout_Organization on Organization {\n  ...Footer_Organization\n}\n\nfragment PendingApproval_FairMarketValueApprovals_Organization on Organization {\n  id\n  name\n  pendingApprovalFairMarketValueBoardConsents {\n    id\n    voidedReason\n    isPandaDocDocumentSent\n    canBoardMemberSignatureRequestReminderEmailsBeSent\n    signatureRequests {\n      id\n      completedAt\n      signatoryFirstName\n      signatoryLastName\n    }\n    fairMarketValue {\n      ...FairMarketValueApprovalTable_FairMarketValue\n      id\n    }\n    ...SendReminderButton_BoardConsent\n  }\n  ...LargeOneColumnLayout_Organization\n  ...FairMarketValueApprovalTable_Organization\n}\n\nfragment PendingApproval_FairMarketValueApprovals_Viewer on Account {\n  isSuperAdmin\n}\n\nfragment SendReminderButton_BoardConsent on BoardConsent {\n  __isBoardConsent: __typename\n  id\n}\n"
  }
};
})();

(node as any).hash = "df1100407180312e7763863ff0a64c9c";

export default node;
