import { Pill } from "@remote-com/norma";
import React from "react";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import { FormattedCurrency } from "../../../../components/Formatted/FormattedCurrency";
import { LongDate } from "../../../../components/LongDate";
import { RemoteEquityPlanCountryThresholdProgressBar } from "../../../../components/RemoteEquityPlanCountryThresholdProgressBar";
import { RemoteEquityPlanEmployeeThresholdProgressBar } from "../../../../components/RemoteEquityPlanEmployeeThresholdProgressBar";
import { ShortDate } from "../../../../components/ShortDate";
import { BreadCrumb } from "../../../../components/ui/BreadCrumb";
import { HelpCard } from "../../../../components/ui/HelpCard";
import { CenteredColumnLayout } from "../../../../components/ui/Layout/CenteredColumnLayout";
import { NoticeMessage } from "../../../../components/ui/NoticeMessage";
import { useApplicationSupportEmailAddress } from "../../../../hooks/useApplicationTheme";
import { APPLICATION_ROUTES } from "../../../../paths";
import { SettingsContainer } from "../SettingsContainer";
import { RemoteEquityOrganizationSettingsBilling_Organization$key } from "./__generated__/RemoteEquityOrganizationSettingsBilling_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment RemoteEquityOrganizationSettingsBilling_Organization on Organization {
    id
    name
    remoteEquityPlanPrice
    remoteEquityPlanRenewalDate
    remoteEquityPlanCountryThreshold
    remoteEquityPlanEmployeeThreshold
    remoteEquityPlanThresholdsExceeded
    remoteEquityPlanDiscountPercentage
    billableGrantees {
      __typename
    }
    billableOrganizationGeographies {
      __typename
    }
    ...RemoteEquityPlanEmployeeThresholdProgressBar_Organization
    ...RemoteEquityPlanCountryThresholdProgressBar_Organization
    ...FormattedCurrency_Organization
  }
`;

const Layout = ({
  children,
  organizationId,
  organizationName,
}: React.PropsWithChildren<{
  organizationId: string;
  organizationName: string;
}>) => {
  return (
    <CenteredColumnLayout
      breadcrumbs={
        <BreadCrumb>
          <BreadCrumb.Link
            to={generatePath(APPLICATION_ROUTES.organizationHome, {
              organizationId,
            })}
          >
            {organizationName}
          </BreadCrumb.Link>
          <BreadCrumb.Link to="..">Settings</BreadCrumb.Link>
          <BreadCrumb.Link to=".">Plan & Billing</BreadCrumb.Link>
        </BreadCrumb>
      }
    >
      <div className="space-y-6">
        <SettingsContainer title="Plan & Billing">{children}</SettingsContainer>

        <HelpCard
          buttonLabel="Talk to our team"
          label="Anything unclear? You need more information regarding your countries or our billing methods?"
        />
      </div>
    </CenteredColumnLayout>
  );
};

export const RemoteEquityOrganizationSettingsBillingPage: React.FC<{
  organizationFragment: RemoteEquityOrganizationSettingsBilling_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const supportEmailAddress = useApplicationSupportEmailAddress();
  const mailtoSubject = encodeURIComponent(
    `[${organization.name}] Remote equity plan`,
  );
  const billingHasBeenConfigured =
    typeof organization.remoteEquityPlanPrice === "number";

  if (!billingHasBeenConfigured) {
    return (
      <Layout
        organizationId={organization.id}
        organizationName={organization.name}
      >
        <SettingsContainer.Item title="Coming Soon" titleSize="large">
          <span className="text-XS/Normal">
            <Link
              className="text-primary"
              to={`mailto:${supportEmailAddress}?subject=${mailtoSubject}`}
            >
              Contact us
            </Link>{" "}
            to get more information on our tiers and plans.
          </span>
        </SettingsContainer.Item>
      </Layout>
    );
  }

  return (
    <Layout
      organizationId={organization.id}
      organizationName={organization.name}
    >
      <SettingsContainer.Item title="Your next payment" titleSize="large">
        <div className="space-y-4">
          <div className="space-y-2">
            {organization.remoteEquityPlanRenewalDate &&
              typeof organization.remoteEquityPlanPrice === "number" && (
                <div>
                  The next payment of{" "}
                  <span className="text-Base/SemiBold">
                    <FormattedCurrency
                      minimumFractionDigits={0}
                      organizationFragment={organization}
                      value={organization.remoteEquityPlanPrice}
                    />
                  </span>{" "}
                  is set on{" "}
                  <span className="text-Base/SemiBold">
                    <LongDate
                      value={organization.remoteEquityPlanRenewalDate}
                    />
                  </span>
                  .
                </div>
              )}
            <div className="text-XS/Normal text-gray-09">
              Your recurring fees are subject to increase based on your usage.
            </div>
          </div>

          <div className="space-y-10 rounded-2xl border-[0.5px] border-gray-04 p-10">
            <div className="grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3">
              {typeof organization.remoteEquityPlanEmployeeThreshold ===
                "number" && (
                <div className="space-y-2">
                  <div className="text-XS/Normal text-gray-09">Employees</div>
                  <div className="text-LG/Medium">
                    {organization.billableGrantees.length}/
                    {organization.remoteEquityPlanEmployeeThreshold}
                  </div>
                  <RemoteEquityPlanEmployeeThresholdProgressBar
                    organizationFragment={organization}
                  />
                </div>
              )}
              {typeof organization.remoteEquityPlanCountryThreshold ===
                "number" && (
                <div className="space-y-2">
                  <div className="text-XS/Normal text-gray-09">Countries</div>
                  <div className="text-LG/Medium">
                    {organization.billableOrganizationGeographies.length}/
                    {organization.remoteEquityPlanCountryThreshold}
                  </div>
                  <RemoteEquityPlanCountryThresholdProgressBar
                    organizationFragment={organization}
                  />
                </div>
              )}
              <div>
                <Link
                  className="block text-right text-SM/Medium text-primary"
                  to={`mailto:${supportEmailAddress}?subject=${mailtoSubject}`}
                >
                  Talk to us
                </Link>
              </div>
            </div>
            {organization.remoteEquityPlanThresholdsExceeded && (
              <NoticeMessage size="Small" variant="Warning">
                You have reached the maximum amount of employees or countries
                allowed under your current plan. Your account manager will reach
                out to you shortly to upgrade it.
              </NoticeMessage>
            )}
          </div>

          <div className="grid grid-cols-1 gap-10 rounded-2xl border-[0.5px] border-gray-04 p-10 md:grid-cols-2 lg:grid-cols-3">
            {organization.remoteEquityPlanRenewalDate && (
              <div className="space-y-2">
                <div className="text-XS/Normal text-gray-09">Renewal date</div>
                <div className="text-LG/Medium">
                  <ShortDate value={organization.remoteEquityPlanRenewalDate} />
                </div>
              </div>
            )}
            {typeof organization.remoteEquityPlanPrice === "number" && (
              <div className="space-y-2">
                <div className="text-XS/Normal text-gray-09">Fee</div>
                <div className="flex items-center gap-2 text-LG/Medium">
                  {organization.remoteEquityPlanDiscountPercentage ? (
                    <div className="text-grey-500 line-through">
                      <FormattedCurrency
                        minimumFractionDigits={0}
                        organizationFragment={organization}
                        value={organization.remoteEquityPlanPrice}
                      />
                    </div>
                  ) : null}
                  <div>
                    <FormattedCurrency
                      maximumFractionDigits={0}
                      organizationFragment={organization}
                      value={
                        organization.remoteEquityPlanPrice -
                        organization.remoteEquityPlanPrice *
                          ((organization.remoteEquityPlanDiscountPercentage ??
                            0) /
                            100)
                      }
                    />
                    <span className="text-grey-500"> /year</span>
                  </div>
                </div>
              </div>
            )}
            {organization.remoteEquityPlanDiscountPercentage ? (
              <div className="space-y-2">
                <div className="text-XS/Normal text-gray-09">
                  Annual discount
                </div>
                <div>
                  <Pill tone="purple">
                    <FormattedNumber
                      minimumFractionDigits={0}
                      style="percent"
                      value={
                        organization.remoteEquityPlanDiscountPercentage / 100
                      }
                    />
                  </Pill>
                </div>
                {organization.remoteEquityPlanRenewalDate && (
                  <div className="text-SM/Normal">
                    valid until{" "}
                    <ShortDate
                      value={organization.remoteEquityPlanRenewalDate}
                    />
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </SettingsContainer.Item>
    </Layout>
  );
};
