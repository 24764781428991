/**
 * @generated SignedSource<<84a51aa7c05db343bd85aebc188b733a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmployeePortalLayout_TopBar_Account$data = {
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationRoleSwitcher_Account">;
  readonly " $fragmentType": "EmployeePortalLayout_TopBar_Account";
};
export type EmployeePortalLayout_TopBar_Account$key = {
  readonly " $data"?: EmployeePortalLayout_TopBar_Account$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeePortalLayout_TopBar_Account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmployeePortalLayout_TopBar_Account",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrganizationRoleSwitcher_Account"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "a2fddc22c684809ddc71a1785d617140";

export default node;
