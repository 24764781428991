import React, { useMemo } from "react";
import { useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { EquityTypeHandbookEdition } from "../../../../components/EquityTypeHandbookEdition/EquityTypeHandbookEdition";
import { Page } from "../../../../components/Page";
import { LargeCenteredDetailsLayout } from "../../../../components/ui/Layout/LargeCenteredDetailsLayout";
import { TabNavigation } from "../../../../components/ui/TabNavigation";
import { useQuery } from "../../../../hooks/useQuery";
import {
  APPLICATION_ROUTES,
  useCountryCodeParam,
  useEquityTypeIdParam,
} from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { EquityTypePage_SuperAdminEquityTypeHandbooks_EquityType$key } from "./__generated__/EquityTypePage_SuperAdminEquityTypeHandbooks_EquityType.graphql";
import { EquityTypePage_SuperAdminEquityTypeHandbooks_Geography$key } from "./__generated__/EquityTypePage_SuperAdminEquityTypeHandbooks_Geography.graphql";
import { EquityTypePage_SuperAdminEquityTypeHandbooks_Query } from "./__generated__/EquityTypePage_SuperAdminEquityTypeHandbooks_Query.graphql";

const GEOGRAPHY_FRAGMENT = graphql`
  fragment EquityTypePage_SuperAdminEquityTypeHandbooks_Geography on Geography {
    country {
      displayName
      code
    }
  }
`;

const EQUITY_TYPE_FRAGMENT = graphql`
  fragment EquityTypePage_SuperAdminEquityTypeHandbooks_EquityType on EquityType {
    id
    name
    directEmployeeHandbook {
      ...EquityTypeHandbookEdition_EquityTypeHandbook
    }
    eoREmployeeHandbook {
      ...EquityTypeHandbookEdition_EquityTypeHandbook
    }
    contractorHandbook {
      ...EquityTypeHandbookEdition_EquityTypeHandbook
    }
    ...EquityTypeHandbookEdition_EquityType
  }
`;

const SuperAdminEquityTypeHandbooksEquityTypePage_: React.FC<{
  equityTypeFragment: EquityTypePage_SuperAdminEquityTypeHandbooks_EquityType$key;
  equityTypeWorkRelationshipCategory:
    | "Contractor"
    | "DirectEmployee"
    | "EoREmployee";
  geographyFragment: EquityTypePage_SuperAdminEquityTypeHandbooks_Geography$key;
  onDataUpdated: () => void;
}> = ({
  equityTypeFragment,
  equityTypeWorkRelationshipCategory,
  geographyFragment,
  onDataUpdated,
}) => {
  const geography = useFragment(GEOGRAPHY_FRAGMENT, geographyFragment);
  const equityType = useFragment(EQUITY_TYPE_FRAGMENT, equityTypeFragment);

  const equityTypeHandbook = useMemo(() => {
    switch (equityTypeWorkRelationshipCategory) {
      case "Contractor":
        return equityType.contractorHandbook;
      case "DirectEmployee":
        return equityType.directEmployeeHandbook;
      case "EoREmployee":
        return equityType.eoREmployeeHandbook;
    }
  }, [equityTypeWorkRelationshipCategory, equityType]);

  return (
    <LargeCenteredDetailsLayout
      footerHelpCard={null}
      navigationTitle={equityType.name}
      organizationFragment={null}
      parents={[
        {
          link: generatePath(APPLICATION_ROUTES.superAdminLegalContent),
          title: "Legal content",
        },
        {
          link: generatePath(APPLICATION_ROUTES.superAdminEquityHandbooks),
          title: "Equity handbooks",
        },
        {
          link: generatePath(
            APPLICATION_ROUTES.superAdminEquityHandbooksGeography,
            {
              countryCode: geography.country.code,
            },
          ),
          title: geography.country.displayName,
        },
      ]}
      title={
        <>
          {geography.country.displayName} - {equityType.name}
        </>
      }
    >
      <div className="space-y-6">
        <TabNavigation layoutId="equity-type-handbooks-equity-type-tab-navigation">
          <TabNavigation.Link
            to={generatePath(
              APPLICATION_ROUTES.superAdminEquityHandbooksEquityTypeForEoREmployee,
              {
                countryCode: geography.country.code,
                equityTypeId: equityType.id,
              },
            )}
          >
            EoR employees
          </TabNavigation.Link>
          <TabNavigation.Link
            to={generatePath(
              APPLICATION_ROUTES.superAdminEquityHandbooksEquityTypeForDirectEmployee,
              {
                countryCode: geography.country.code,
                equityTypeId: equityType.id,
              },
            )}
          >
            Direct employees
          </TabNavigation.Link>
          <TabNavigation.Link
            to={generatePath(
              APPLICATION_ROUTES.superAdminEquityHandbooksEquityTypeForContractor,
              {
                countryCode: geography.country.code,
                equityTypeId: equityType.id,
              },
            )}
          >
            Contractors
          </TabNavigation.Link>
        </TabNavigation>
        <EquityTypeHandbookEdition
          equityTypeFragment={equityType}
          equityTypeHandbookFragment={equityTypeHandbook}
          equityTypeWorkRelationshipCategory={
            equityTypeWorkRelationshipCategory
          }
          onDataUpdated={onDataUpdated}
        />
      </div>
    </LargeCenteredDetailsLayout>
  );
};

const QUERY = graphql`
  query EquityTypePage_SuperAdminEquityTypeHandbooks_Query(
    $countryCode: String!
    $equityTypeId: UUID!
  ) {
    geography(countryCode: $countryCode) {
      country {
        name
      }
      ...EquityTypePage_SuperAdminEquityTypeHandbooks_Geography
    }
    equityType(id: $equityTypeId) {
      name
      ...EquityTypePage_SuperAdminEquityTypeHandbooks_EquityType
    }
  }
`;

const SuperAdminEquityTypeHandbooksEquityTypePage: React.FC<{
  equityTypeWorkRelationshipCategory:
    | "Contractor"
    | "DirectEmployee"
    | "EoREmployee";
}> = ({ equityTypeWorkRelationshipCategory }) => {
  const countryCode = useCountryCodeParam();
  const equityTypeId = useEquityTypeIdParam();
  const {
    query: { equityType, geography },
    refreshQuery,
  } = useQuery<EquityTypePage_SuperAdminEquityTypeHandbooks_Query>(QUERY, {
    countryCode,
    equityTypeId,
  });

  if (!equityType) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsName="Super Admin - Equity type handbooks"
      title={`Super admin | Equity type handbooks - ${geography.country.name} - ${equityType.name}`}
    >
      <SuperAdminEquityTypeHandbooksEquityTypePage_
        equityTypeFragment={equityType}
        equityTypeWorkRelationshipCategory={equityTypeWorkRelationshipCategory}
        geographyFragment={geography}
        onDataUpdated={refreshQuery}
      />
    </Page>
  );
};

export default SuperAdminEquityTypeHandbooksEquityTypePage;
