/**
 * @generated SignedSource<<603bffe583691b31a43c536554cb5cde>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SelectedItemsActionCardWithNumbers_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FormattedCurrency_Organization" | "useOrganizationSharesUtil_Organization">;
  readonly " $fragmentType": "SelectedItemsActionCardWithNumbers_Organization";
};
export type SelectedItemsActionCardWithNumbers_Organization$key = {
  readonly " $data"?: SelectedItemsActionCardWithNumbers_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"SelectedItemsActionCardWithNumbers_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SelectedItemsActionCardWithNumbers_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationSharesUtil_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedCurrency_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "0e25da92eca698938d2f672c6b042982";

export default node;
