import { RadioGroup } from "@headlessui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Pill, Text } from "@remote-com/norma";
import { compact, isNil } from "lodash";
import { useCallback, useEffect, useId, useMemo } from "react";
import {
  Controller,
  DeepPartial,
  DefaultValues,
  useForm,
} from "react-hook-form";
import { useFragment } from "react-relay";
import { generatePath, Link, useNavigate, useParams } from "react-router-dom";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { DeclarationNoticeMessage } from "../../../../components/DeclarationNoticeMessage";
import { FormattedCurrency } from "../../../../components/Formatted/FormattedCurrency";
import { FormattedNumber } from "../../../../components/Formatted/FormattedNumber";
import { GrantDeclarationBillingInformationSection } from "../../../../components/GrantDeclarationBillingInformationSection";
import { RemoteSelectOrganizationPostTerminationExercisePeriod } from "../../../../components/GrantForm/RemoteSelectOrganizationPostTerminationExercisePeriod";
import { SuspenseWithLoadingFallback } from "../../../../components/LoadingPlaceholder";
import { Page } from "../../../../components/Page";
import { RadioCard } from "../../../../components/RadioCard";
import { ShortDate } from "../../../../components/ShortDate";
import {
  FileInput,
  useFileInputFormUploadController,
} from "../../../../components/ui/Form/FileInput";
import { FormRow } from "../../../../components/ui/Form/FormRow";
import { RemoteInputCurrency } from "../../../../components/ui/Form/Inputs/RemoteInputCurrency";
import { RemoteInputDatePicker } from "../../../../components/ui/Form/Inputs/RemoteInputDatePicker";
import { RemoteInputSelect } from "../../../../components/ui/Form/Inputs/RemoteInputSelect";
import { RemoteInputText } from "../../../../components/ui/Form/Inputs/RemoteInputText";
import { RemoteSelectVestingScheduleInput } from "../../../../components/ui/Form/RemoteSelectVestingScheduleInput";
import {
  CheckoutLikeLayout,
  CheckoutLikeLayoutAside,
  CheckoutLikeLayoutAsideSection,
  CheckoutLikeLayoutAsideSectionItem,
  CheckoutLikeLayoutMainCard,
  CheckoutLikeLayoutMainSection,
  CheckoutLikeLayoutMainTitle,
} from "../../../../components/ui/Layout/CheckoutLikeLayout";
import { VestingPreviewGraph } from "../../../../components/VestingPreviewGraph";
import { useQuery } from "../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../../paths";
import { ACCELERATION_CLAUSE_LABELS } from "../../../../services/AccelerationClause";
import NotFoundPage from "../../../NotFound/NotFound";
import { CreateSlideOverRemote as CreatePTEPSlideOverRemote } from "../../Grants/Configure/PostTermination/CreateSlideOver";
import { CreateSlideOverRemote as CreateVestingScheduleSlideOverRemote } from "../../Grants/Configure/VestingSchedules/CreateSlideOver";
import { TAXATION_LABELS } from "../NewDetails/GrantDetailsInformationSection";
import { GrantDeclareNew_DeclareGrant_Mutation } from "./__generated__/GrantDeclareNew_DeclareGrant_Mutation.graphql";
import {
  GrantDeclareNew_Organization$data,
  GrantDeclareNew_Organization$key,
} from "./__generated__/GrantDeclareNew_Organization.graphql";
import { GrantDeclareNew_Query } from "./__generated__/GrantDeclareNew_Query.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment GrantDeclareNew_Organization on Organization {
    id
    esopPoolShares
    latestFairMarketValue {
      value
    }
    vestingSchedules {
      id
      name
    }
    postTerminationExercisePeriods {
      id
      displayName
    }
    grantees {
      edges {
        node {
          id
          name
          taxResidenceCountry {
            emoji
          }
          equityInformationProvidedDuringRemoteOnboarding
          defaultPostTerminationExercisePeriod {
            id
          }
          defaultVestingSchedule {
            id
          }
          equityNeedsReview
          defaultAccelerationClause
          earlyExerciseIsAllowedByDefault
          ctmsGrantsCount
          instruments {
            equityType {
              name
              humanReadableName
              exercisable
            }
          }
        }
      }
    }
    ctmsGrants {
      edges {
        node {
          label
        }
      }
    }
    ...RemoteSelectVestingScheduleInput_Organization
    ...RemoteSelectOrganizationPostTerminationExercisePeriod_Organization
    ...GrantDeclarationBillingInformationSection_Organization
    ...VestingPreviewGraph_Organization
    ...FormattedCurrency_Organization
    ...RemoteInputCurrency_Organization
  }
`;

const DECLARE_GRANT_MUTATION = graphql`
  mutation GrantDeclareNew_DeclareGrant_Mutation(
    $attributes: DeclareGrantAttributes!
    $organizationId: OrganizationId!
  ) {
    declareGrant(attributes: $attributes, organizationId: $organizationId) {
      id
    }
  }
`;

const useFormSchema = (organization: GrantDeclareNew_Organization$data) =>
  useMemo(
    () =>
      z.object({
        acceleration: z
          .enum([
            "None",
            "SingleTrigger",
            "DoubleTrigger100",
            "DoubleTrigger50",
            "DoubleTrigger35",
            "DoubleTrigger25",
          ])
          .optional(),
        documentIds: z.array(z.string()).optional(),
        earlyExercise: z.enum(["YES", "NO"]).optional(),
        equityType: z.enum(["BSPCE", "EMI", "ISO", "NSO", "RSA", "RSU", "SAR"]),
        exercisePrice: z.coerce.number().positive().optional(),
        granteeId: z.string(),
        label: z
          .string()
          .trim()
          .min(1)
          .refine(
            (label) =>
              !organization.ctmsGrants.edges.some(
                (ctmsGrant) => ctmsGrant.node.label === label,
              ),
            "This label is already used",
          ),
        postTerminationExercisePeriodId: z.string().optional(),
        quantityIssued: z.coerce
          .number()
          .int()
          .positive()
          .max(
            organization.esopPoolShares ?? Infinity,
            "You cannot grant more shares than those issued in your pool",
          ),
        signatureDate: z.string().date(),
        taxation: z.enum(["UPON_VESTING", "UPON_LIQUIDITY_EVENT"]).optional(),
        vestingScheduleId: z.string(),
        vestingStartDate: z.string().date(),
      }),
    [organization.ctmsGrants.edges, organization.esopPoolShares],
  );

type FormValues = z.infer<ReturnType<typeof useFormSchema>>;

const ACCELERATION_OPTIONS: {
  label: string;
  value: NonNullable<FormValues["acceleration"]>;
}[] = (
  [
    "None",
    "SingleTrigger",
    "DoubleTrigger100",
    "DoubleTrigger50",
    "DoubleTrigger35",
    "DoubleTrigger25",
  ] as const
).map((accelerationClause) => {
  return {
    label:
      accelerationClause === "None"
        ? "No acceleration terms"
        : ACCELERATION_CLAUSE_LABELS[accelerationClause],
    value: accelerationClause,
  };
});

const ALLOW_EARLY_EXERCISE_OPTIONS = [
  {
    label: "No early exercise clause",
    value: "NO",
  },
  {
    label: "Allow early exercise",
    value: "YES",
  },
] as const;

const GrantDeclareNewContent: React.FC<{
  organizationFragment: GrantDeclareNew_Organization$key;
  selectedGranteeId: string | undefined;
}> = ({ organizationFragment, selectedGranteeId }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const formId = useId();

  const [declareGrant, declareGrantMutationIsInFlight] =
    useSafeMutation<GrantDeclareNew_DeclareGrant_Mutation>(
      DECLARE_GRANT_MUTATION,
    );

  const grantees = organization.grantees.edges.map((edge) => edge.node);

  const getDefaultValues = useCallback(
    (granteeId: string | undefined): DefaultValues<FormValues> => {
      const selectedGrantee = grantees.find(
        (grantee) => grantee.id === granteeId,
      );
      return {
        acceleration: selectedGrantee?.defaultAccelerationClause ?? "None",
        earlyExercise: selectedGrantee?.earlyExerciseIsAllowedByDefault
          ? "YES"
          : "NO",
        exercisePrice: organization.latestFairMarketValue?.value,
        granteeId,
        postTerminationExercisePeriodId:
          selectedGrantee?.defaultPostTerminationExercisePeriod?.id,
        vestingScheduleId: selectedGrantee?.defaultVestingSchedule?.id,
      };
    },
    [grantees, organization.latestFairMarketValue?.value],
  );

  const formSchema = useFormSchema(organization);

  const form = useForm<DeepPartial<FormValues>>({
    defaultValues: getDefaultValues(selectedGranteeId),
    disabled: declareGrantMutationIsInFlight,
    resolver: zodResolver(formSchema),
  });

  const [
    equityType,
    exercisePrice,
    granteeId,
    postTerminationExercisePeriodId,
    quantityIssued,
    vestingScheduleId,
    vestingStartDate,
    acceleration,
    earlyExercise,
    taxation,
  ] = form.watch([
    "equityType",
    "exercisePrice",
    "granteeId",
    "postTerminationExercisePeriodId",
    "quantityIssued",
    "vestingScheduleId",
    "vestingStartDate",
    "acceleration",
    "earlyExercise",
    "taxation",
  ]);

  useEffect(() => {
    const defaultValues = getDefaultValues(granteeId);

    form.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [granteeId]);

  const selectedGrantee = useMemo(
    () => grantees.find((grantee) => grantee.id === granteeId),
    [grantees, granteeId],
  );
  const selectedVestingSchedule = useMemo(
    () =>
      organization.vestingSchedules.find(
        (vestingSchedule) => vestingSchedule.id === vestingScheduleId,
      ),
    [organization.vestingSchedules, vestingScheduleId],
  );
  const selectedPostTerminationExercisePeriod = useMemo(
    () =>
      organization.postTerminationExercisePeriods.find(
        (postTerminationExercisePeriod) =>
          postTerminationExercisePeriod.id === postTerminationExercisePeriodId,
      ),
    [
      organization.postTerminationExercisePeriods,
      postTerminationExercisePeriodId,
    ],
  );

  const navigate = useNavigate();

  const onSubmit = form.handleSubmit(async (data) => {
    const formInputs = formSchema.parse(data);
    let hasErrors = false;
    if (selectedEquityTypeIsExercisable) {
      if (isNil(formInputs.exercisePrice)) {
        form.setError("exercisePrice", {
          type: "required",
        });
        hasErrors = true;
      }
      if (isNil(formInputs.postTerminationExercisePeriodId)) {
        form.setError("postTerminationExercisePeriodId", {
          type: "required",
        });
        hasErrors = true;
      }
      if (isNil(formInputs.acceleration)) {
        form.setError("acceleration", {
          type: "required",
        });
        hasErrors = true;
      }
      if (isNil(formInputs.earlyExercise)) {
        form.setError("earlyExercise", {
          type: "required",
        });
        hasErrors = true;
      }
    }
    if (equityType === "RSU") {
      if (isNil(formInputs.taxation)) {
        form.setError("taxation", {
          type: "required",
        });
        hasErrors = true;
      }
    }
    if (hasErrors) {
      return;
    }
    const exercisableFields = selectedEquityTypeIsExercisable
      ? {
          accelerationClause:
            formInputs.acceleration !== "None" ? formInputs.acceleration : null,
          earlyExercise: formInputs.earlyExercise
            ? formInputs.earlyExercise === "YES"
            : null,
          exercisePrice: formInputs.exercisePrice,
          postTerminationExercisePeriodId:
            formInputs.postTerminationExercisePeriodId,
        }
      : {
          accelerationClause: null,
          earlyExercise: null,
          exercisePrice: null,
          postTerminationExercisePeriodId: null,
        };
    const rsuFields =
      equityType === "RSU"
        ? {
            taxation: formInputs.taxation,
          }
        : {
            taxation: null,
          };
    await declareGrant({
      variables: {
        attributes: {
          documentIds: formInputs.documentIds ?? [],
          equityType: formInputs.equityType,
          granteeId: formInputs.granteeId,
          label: formInputs.label,
          quantityIssued: formInputs.quantityIssued,
          signatureDate: formInputs.signatureDate,
          vestingScheduleId: formInputs.vestingScheduleId,
          vestingStartDate: formInputs.vestingStartDate,
          ...exercisableFields,
          ...rsuFields,
        },
        organizationId: organization.id,
      },
    });

    await navigate(
      generatePath(APPLICATION_ROUTES.organizationEquityGrants, {
        organizationId: organization.id,
      }),
      {
        replace: true,
      },
    );
  });

  const fileInputUploadController = useFileInputFormUploadController({
    control: form.control,
    multiple: true,
    name: "documentIds",
  });

  const granteesOptions = useMemo(
    () =>
      grantees.map((grantee) => ({
        label: [grantee.taxResidenceCountry?.emoji, grantee.name].join(" "),
        value: grantee.id,
      })),
    [grantees],
  );

  const newGrantIsIncludedInPlan = useMemo(() => {
    if (!selectedGrantee) {
      return false;
    }

    if (selectedGrantee.ctmsGrantsCount > 0) {
      return true;
    }

    if (
      selectedGrantee.equityInformationProvidedDuringRemoteOnboarding !== null
    ) {
      return true;
    }

    return false;
  }, [selectedGrantee]);

  const postTerminationTypeLabel =
    equityType === "SAR" ? "settlement" : "exercise";

  const equityTypesAvailableForGrantee = useMemo(() => {
    return compact(
      selectedGrantee?.instruments.map((instrument) => instrument.equityType) ??
        [],
    );
  }, [selectedGrantee]);

  const selectedEquityType = useMemo(() => {
    return equityTypesAvailableForGrantee.find(
      ({ name: equityTypeName }) => equityTypeName === equityType,
    );
  }, [equityTypesAvailableForGrantee, equityType]);

  const selectedEquityTypeIsExercisable = useMemo(
    () => !!selectedEquityType?.exercisable,
    [selectedEquityType],
  );

  return (
    <CreatePTEPSlideOverRemote.Provider>
      <CreateVestingScheduleSlideOverRemote.Provider>
        <CheckoutLikeLayout
          aside={
            <CheckoutLikeLayoutAside title="Grant details">
              <CheckoutLikeLayoutAsideSection>
                <CheckoutLikeLayoutAsideSectionItem title="Name">
                  {selectedGrantee?.name}
                </CheckoutLikeLayoutAsideSectionItem>
                <CheckoutLikeLayoutAsideSectionItem title="Equity incentive">
                  {selectedEquityType?.humanReadableName}
                </CheckoutLikeLayoutAsideSectionItem>
                <CheckoutLikeLayoutAsideSectionItem title="Quantity granted">
                  {!isNil(quantityIssued) && (
                    <FormattedNumber animated value={quantityIssued} />
                  )}
                </CheckoutLikeLayoutAsideSectionItem>
                <CheckoutLikeLayoutAsideSectionItem title="Vesting schedule">
                  {selectedVestingSchedule?.name}
                </CheckoutLikeLayoutAsideSectionItem>
                <CheckoutLikeLayoutAsideSectionItem title="Vesting start date">
                  {vestingStartDate && <ShortDate value={vestingStartDate} />}
                </CheckoutLikeLayoutAsideSectionItem>
                {selectedEquityTypeIsExercisable && (
                  <>
                    <CheckoutLikeLayoutAsideSectionItem title="Strike price">
                      {exercisePrice && (
                        <FormattedCurrency
                          animated
                          organizationFragment={organization}
                          value={exercisePrice}
                        />
                      )}
                    </CheckoutLikeLayoutAsideSectionItem>
                    <CheckoutLikeLayoutAsideSectionItem title="Post-termination exercise period">
                      {selectedPostTerminationExercisePeriod?.displayName}
                    </CheckoutLikeLayoutAsideSectionItem>
                    <CheckoutLikeLayoutAsideSectionItem title="Early exercise">
                      {earlyExercise &&
                        (earlyExercise === "YES" ? "Yes" : "No")}
                    </CheckoutLikeLayoutAsideSectionItem>
                    <CheckoutLikeLayoutAsideSectionItem title="Acceleration">
                      {acceleration &&
                        (acceleration === "None"
                          ? "No"
                          : ACCELERATION_CLAUSE_LABELS[acceleration])}
                    </CheckoutLikeLayoutAsideSectionItem>
                  </>
                )}
                {equityType === "RSU" && (
                  <CheckoutLikeLayoutAsideSectionItem title="Grant taxation">
                    {taxation && TAXATION_LABELS[taxation]}
                  </CheckoutLikeLayoutAsideSectionItem>
                )}
              </CheckoutLikeLayoutAsideSection>
            </CheckoutLikeLayoutAside>
          }
          navigationBreadcrumbs={[
            {
              label: "Equity grants",
              to: generatePath(APPLICATION_ROUTES.organizationEquityGrants, {
                organizationId: organization.id,
              }),
            },
          ]}
          pageTitle="Declare equity grant"
        >
          <CheckoutLikeLayoutMainCard>
            <CheckoutLikeLayoutMainTitle
              description="Grant declarations are essential in order for Remote to take care of local reporting obligations relating to equity compensation, without missing deadlines and incurring fines and other penalties. We offer employee comprehensive coverage with Remote Equity."
              illustration={<Pill>Grant declaration</Pill>}
              title="Declare a new grant"
            />
            <form id={formId} onSubmit={onSubmit}>
              <CheckoutLikeLayoutMainSection
                subtitle="Start creating your grant by selecting a team member"
                title="Employee"
              >
                <RemoteInputSelect.Form
                  control={form.control}
                  label="Employee"
                  name="granteeId"
                  options={granteesOptions}
                />
              </CheckoutLikeLayoutMainSection>
              {selectedGrantee && (
                <CheckoutLikeLayoutMainSection
                  subtitle="Select the equity incentive granted to the employee"
                  title="Equity incentive"
                >
                  {equityTypesAvailableForGrantee.length > 0 ? (
                    <FormRow.Form
                      context="Equity incentives listed above are the most common ones. These incentives are taxed and reported differently depending on local regulations."
                      control={form.control}
                      name="equityType"
                    >
                      <Controller
                        control={form.control}
                        name="equityType"
                        render={({ field }) => (
                          <RadioGroup
                            className="flex flex-wrap gap-2"
                            onChange={field.onChange}
                            value={field.value}
                          >
                            {equityTypesAvailableForGrantee.map(
                              (equityType) => (
                                <RadioCard
                                  key={equityType.name}
                                  value={equityType.name}
                                >
                                  {equityType.humanReadableName}
                                </RadioCard>
                              ),
                            )}
                          </RadioGroup>
                        )}
                      />
                    </FormRow.Form>
                  ) : (
                    <DeclarationNoticeMessage>
                      No equity incentive available for this employee
                    </DeclarationNoticeMessage>
                  )}
                </CheckoutLikeLayoutMainSection>
              )}
              <CheckoutLikeLayoutMainSection
                subtitle="How much equity is granted to the employee?"
                title="Quantity granted"
              >
                {selectedGrantee?.equityNeedsReview &&
                  selectedGrantee.equityInformationProvidedDuringRemoteOnboarding && (
                    <DeclarationNoticeMessage>
                      <div className="flex flex-col gap-2 pl-2">
                        <Text className="text-grey-600" variant="sm">
                          Based on what you mentioned during the onboarding:
                        </Text>
                        <Text variant="smMedium">
                          “
                          {
                            selectedGrantee.equityInformationProvidedDuringRemoteOnboarding
                          }
                          ”
                        </Text>
                      </div>
                    </DeclarationNoticeMessage>
                  )}
                <RemoteInputText.Form
                  control={form.control}
                  label="Grant size"
                  name="quantityIssued"
                  type="number"
                />
              </CheckoutLikeLayoutMainSection>
              <CheckoutLikeLayoutMainSection
                subtitle="Define how the employee should vest their options over time."
                title="Vesting schedule"
              >
                <RemoteSelectVestingScheduleInput.Form
                  control={form.control}
                  label="Select a vesting schedule"
                  name="vestingScheduleId"
                  organizationFragment={organization}
                />
                <RemoteInputDatePicker.Form
                  control={form.control}
                  label="Select a vesting start date"
                  name="vestingStartDate"
                />
                <DeclarationNoticeMessage>
                  The vesting start date usually coincides with the employee
                  contract start date.
                </DeclarationNoticeMessage>
                {vestingScheduleId && vestingStartDate && quantityIssued && (
                  <SuspenseWithLoadingFallback>
                    <div className="h-[320px] w-full space-y-4 rounded-remote-lg border-[0.5px] border-grey-300 p-6 pb-16">
                      <Text variant="baseMedium">Vesting schedule preview</Text>
                      <VestingPreviewGraph
                        organizationFragment={organization}
                        quantityGranted={Number(quantityIssued)}
                        vestingScheduleId={vestingScheduleId}
                        vestingStartDate={new Date(vestingStartDate)}
                      />
                    </div>
                  </SuspenseWithLoadingFallback>
                )}
              </CheckoutLikeLayoutMainSection>
              {selectedEquityTypeIsExercisable && (
                <>
                  <CheckoutLikeLayoutMainSection
                    subtitle="The price your team member will have to pay to exercise the options. The strike price is usually based on the fair market value of the company's shares at the time of grant."
                    title="Strike price"
                  >
                    <DeclarationNoticeMessage
                      action={
                        <Link
                          to={generatePath(
                            APPLICATION_ROUTES.organizationCompanySettingsFairMarketValue,
                            {
                              organizationId: organization.id,
                            },
                          )}
                        >
                          Add new Fair Market Value
                        </Link>
                      }
                    >
                      Based on your current Fair Market Value.
                    </DeclarationNoticeMessage>
                    <RemoteInputCurrency.Form
                      control={form.control}
                      label="Strike price"
                      locale="en-US"
                      name="exercisePrice"
                      organizationFragment={organization}
                    />
                    <Text variant="sm">
                      Regarding the post-termination {postTerminationTypeLabel}{" "}
                      window
                    </Text>
                    <DeclarationNoticeMessage>
                      The post-termination {postTerminationTypeLabel} period or
                      post-termination {postTerminationTypeLabel} window, is the
                      period the employee has in order to decide whether they
                      want to exercise their stock options when they leave the
                      company. Options that are not exercised during that period
                      are usually cancelled and available for grant to other
                      employees
                    </DeclarationNoticeMessage>
                    <RemoteSelectOrganizationPostTerminationExercisePeriod
                      control={form.control}
                      isVirtual={equityType === "SAR"}
                      label={`Select or add a post-termination ${postTerminationTypeLabel} period`}
                      name="postTerminationExercisePeriodId"
                      organizationFragment={organization}
                    />
                  </CheckoutLikeLayoutMainSection>
                  <CheckoutLikeLayoutMainSection
                    subtitle={
                      <>
                        Advanced terms are automatically prefilled for you based
                        on your{" "}
                        <Link
                          className="font-medium text-brand-600"
                          target="_blank"
                          to={generatePath(
                            APPLICATION_ROUTES.organizationEquityConfigureAcceleration,
                            {
                              organizationId: organization.id,
                            },
                          )}
                        >
                          equity configuration
                        </Link>
                        . If you never edited those settings, they’re based on
                        common industry practices.
                      </>
                    }
                    title="Advanced terms"
                  >
                    <RemoteInputSelect.Form
                      control={form.control}
                      label="Early exercise"
                      name="earlyExercise"
                      options={ALLOW_EARLY_EXERCISE_OPTIONS}
                    />
                    <RemoteInputSelect.Form
                      control={form.control}
                      label="Acceleration"
                      name="acceleration"
                      options={ACCELERATION_OPTIONS}
                    />
                  </CheckoutLikeLayoutMainSection>
                </>
              )}
              {equityType === "RSU" && (
                <CheckoutLikeLayoutMainSection
                  subtitle="When are the RSUs taxed upon?"
                  title="Grant taxation"
                >
                  <RemoteInputSelect.Form
                    control={form.control}
                    label="Taxation at vesting"
                    name="taxation"
                    options={[
                      {
                        label: "Taxed upon vesting",
                        value: "UPON_VESTING",
                      },
                      {
                        label: "Taxed upon liquidity event",
                        value: "UPON_LIQUIDITY_EVENT",
                      },
                    ]}
                  />
                </CheckoutLikeLayoutMainSection>
              )}
              <CheckoutLikeLayoutMainSection
                subtitle="We recommend to input a grant label for organisation and classification purposes. Usually cap table are organized around grant labels."
                title="Grant label"
              >
                <RemoteInputText.Form
                  control={form.control}
                  label="Grant label"
                  name="label"
                />
              </CheckoutLikeLayoutMainSection>
              <CheckoutLikeLayoutMainSection
                subtitle="When was this grant accepted by the employee?"
                title="Signature date"
              >
                <RemoteInputDatePicker.Form
                  control={form.control}
                  label="Select a signature date"
                  name="signatureDate"
                />
              </CheckoutLikeLayoutMainSection>
              <CheckoutLikeLayoutMainSection
                subtitle="Upload the grant documentation, board consent and any document associated to this grant."
                title="Upload grant and corporate approval (such as board consent)"
              >
                <FormRow.Form control={form.control} name="documentIds">
                  <FileInput {...fileInputUploadController.inputProps} />
                </FormRow.Form>
              </CheckoutLikeLayoutMainSection>
            </form>
          </CheckoutLikeLayoutMainCard>
          <GrantDeclarationBillingInformationSection
            formId={formId}
            includedInPlan={newGrantIsIncludedInPlan}
            mutationIsInFlight={declareGrantMutationIsInFlight}
            organizationFragment={organization}
          />
        </CheckoutLikeLayout>
      </CreateVestingScheduleSlideOverRemote.Provider>
    </CreatePTEPSlideOverRemote.Provider>
  );
};

const QUERY = graphql`
  query GrantDeclareNew_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...GrantDeclareNew_Organization
    }
  }
`;

export const GrantDeclareNew: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { selectedGranteeId } = useParams();

  const {
    query: { organization },
  } = useQuery<GrantDeclareNew_Query>(QUERY, {
    organizationId,
  });

  if (!organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Declare New Grant"
      analyticsName="Admin - Declare New Grant"
      organizationId={organization.id}
      title={`Admin | ${organization.name} declare new grant`}
    >
      <GrantDeclareNewContent
        organizationFragment={organization}
        selectedGranteeId={selectedGranteeId}
      />
    </Page>
  );
};
