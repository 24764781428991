/**
 * @generated SignedSource<<55b809bb4f4b44515118f2daf2d60efe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type SafeCalculatorSimulatedGrantGrantedSharesInputMode = "FULLY_DILUTED" | "SHARES" | "VALUE";
export type SafeCalculatorSimulatedGrantType = "POST_MONEY" | "PRE_MONEY";
import { FragmentRefs } from "relay-runtime";
export type OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion$data = {
  readonly id: string;
  readonly optionPoolSize: number | null;
  readonly preConversionFDS: number | null;
  readonly safes: ReadonlyArray<{
    readonly __typename: "SafePostMoney";
    readonly id: string;
    readonly lastIssuanceDate: string;
    readonly totalAmountInvestedInUSD: number;
    readonly useAsValuation: boolean;
    readonly valuationCapInUSD: number;
  } | {
    readonly __typename: "SafePostMoneyMFN";
    readonly id: string;
    readonly lastIssuanceDate: string;
    readonly mfnDate: string;
    readonly totalAmountInvestedInUSD: number;
    readonly useAsValuation: boolean;
    readonly valuationCapInUSD: number;
  } | {
    readonly __typename: "SafePreMoney";
    readonly id: string;
    readonly lastIssuanceDate: string;
    readonly totalAmountInvestedInUSD: number;
    readonly useAsValuation: boolean;
    readonly valuationCapInUSD: number;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  }>;
  readonly simulatedGrants: ReadonlyArray<{
    readonly grantedSharesInputMode: SafeCalculatorSimulatedGrantGrantedSharesInputMode;
    readonly id: string;
    readonly shares: number;
    readonly type: SafeCalculatorSimulatedGrantType;
  }>;
  readonly " $fragmentType": "OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion";
};
export type OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion$key = {
  readonly " $data"?: OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "valuationCapInUSD",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalAmountInvestedInUSD",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastIssuanceDate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "useAsValuation",
  "storageKey": null
},
v5 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "preConversionFDS",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "optionPoolSize",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "safes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v5/*: any*/),
          "type": "SafePostMoney",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v5/*: any*/),
          "type": "SafePreMoney",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mfnDate",
              "storageKey": null
            },
            (v4/*: any*/)
          ],
          "type": "SafePostMoneyMFN",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SafeCalculatorSimulatedGrant",
      "kind": "LinkedField",
      "name": "simulatedGrants",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grantedSharesInputMode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shares",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SafeCalculatorVersion",
  "abstractKey": null
};
})();

(node as any).hash = "05853a6a2e9bc723c268b364a244ae28";

export default node;
