import { IconV2DuotoneAlertTriangle } from "@remote-com/norma/icons/IconV2DuotoneAlertTriangle";
import { IconV2DuotoneRocket } from "@remote-com/norma/icons/IconV2DuotoneRocket";
import { IconV2DuotoneThumbsUp } from "@remote-com/norma/icons/IconV2DuotoneThumbsUp";
import { IconV2DuotoneUserX } from "@remote-com/norma/icons/IconV2DuotoneUserX";
import { VariantProps } from "cva";
import { isNil } from "lodash";
import { useMemo } from "react";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { cva, cx } from "../helpers/cva.config";
import { useGranteePositionToEquityGridLevel } from "../hooks/useGranteePositionToEquityGridLevel";
import { useOrganizationSharesUtil } from "../hooks/useOrganizationSharesUtil";
import {
  EmployeeEquityGridLevelTag_Grantee$data,
  EmployeeEquityGridLevelTag_Grantee$key,
} from "./__generated__/EmployeeEquityGridLevelTag_Grantee.graphql";
import { EmployeeEquityGridLevelTag_Organization$key } from "./__generated__/EmployeeEquityGridLevelTag_Organization.graphql";
import { FormattedCurrency } from "./Formatted/FormattedCurrency";

const GRANTEE_FRAGMENT = graphql`
  fragment EmployeeEquityGridLevelTag_Grantee on Grantee {
    ctmsGrants {
      __typename
    }
    missingSharesToEquityGridLevel
    ...useGranteePositionToEquityGridLevel_Grantee
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment EmployeeEquityGridLevelTag_Organization on Organization {
    equityGrid {
      activated
    }
    ...useOrganizationSharesUtil_Organization
    ...FormattedCurrency_Organization
  }
`;

export const EmployeeEquityGridLevelTag: React.FC<{
  className?: string;
  grantedSharesInDraft: number;
  granteeFragment: EmployeeEquityGridLevelTag_Grantee$key;
  hideActionButtons?: boolean;
  onAddEquityLevelClick?: (
    grantee: EmployeeEquityGridLevelTag_Grantee$data,
  ) => void;
  onCreateTopUpClick?: () => void;
  organizationFragment: EmployeeEquityGridLevelTag_Organization$key;
}> = ({
  className,
  grantedSharesInDraft,
  granteeFragment,
  hideActionButtons,
  onAddEquityLevelClick,
  onCreateTopUpClick,
  organizationFragment,
}) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const { sharesToValue } = useOrganizationSharesUtil({
    organizationFragment: organization,
  });

  const hasExistingGrant =
    grantee.ctmsGrants.length > 0 || !!grantedSharesInDraft;

  const granteePositionToEquityGridLevel = useGranteePositionToEquityGridLevel({
    grantedSharesInDraft,
    granteeFragment: grantee,
  });

  const { missingShares, missingValue } = useMemo(() => {
    if (grantee.missingSharesToEquityGridLevel === null) {
      return { missingShares: null, missingValue: null };
    }

    const missingShares = Math.abs(grantee.missingSharesToEquityGridLevel);

    return {
      missingShares,
      missingValue: sharesToValue(missingShares),
    };
  }, [grantee.missingSharesToEquityGridLevel, sharesToValue]);

  if (
    !organization.equityGrid.activated ||
    isNil(granteePositionToEquityGridLevel)
  )
    return null;

  switch (granteePositionToEquityGridLevel) {
    case "ABOVE_LEVEL":
      return (
        <Banner className={className} tone="positive">
          <IconV2DuotoneRocket />
          Employee is above equity grid level
        </Banner>
      );
    case "BELOW_LEVEL":
      return (
        <Banner className={className} tone="warning">
          <IconV2DuotoneAlertTriangle />
          <span>
            Employee is below equity level.
            {!hideActionButtons && missingShares !== null && (
              <>
                {" "}
                <button
                  className="font-medium text-primary-04"
                  onClick={onCreateTopUpClick}
                  type="button"
                >
                  {hasExistingGrant ? "Make a top-up grant" : "Make a grant"}
                </button>{" "}
                of <FormattedNumber value={missingShares} /> shares{" "}
                {missingValue !== null && (
                  <>
                    (
                    <FormattedCurrency
                      organizationFragment={organization}
                      value={missingValue}
                    />
                    ){""}
                  </>
                )}
                to match the grid level.
              </>
            )}
          </span>
        </Banner>
      );
    case "NO_LEVEL":
      return (
        <Banner className={className} tone="warning">
          <IconV2DuotoneAlertTriangle />
          <span>
            Employee does not have an equity level.
            {!hideActionButtons && onAddEquityLevelClick ? (
              <>
                &nbsp;
                <button
                  onClick={() => {
                    onAddEquityLevelClick(grantee);
                  }}
                  type="button"
                >
                  Add an equity level
                </button>
              </>
            ) : null}
          </span>
        </Banner>
      );
    case "OFF_GRID":
      return (
        <Banner className={className} tone="muted">
          <IconV2DuotoneUserX />
          Employee is marked as off grid
        </Banner>
      );
    case "RIGHT_ON_LEVEL":
      return (
        <Banner className={className} tone="positive">
          <IconV2DuotoneThumbsUp />
          Employee is right on equity level
        </Banner>
      );
  }
};

const bannerStyle = cva({
  base: "flex items-center gap-2 rounded-remote-sm p-4 text-XS text-grey-600 [&_a]:font-medium [&_a]:text-primary-600 [&_button]:font-medium [&_button]:text-primary-600 [&_svg]:size-5",
  defaultVariants: {
    tone: "muted",
  },
  variants: {
    tone: {
      muted: "bg-grey-100 [&_svg]:text-grey-600",
      positive: "bg-greenLight-100 [&_svg]:text-greenLight-600",
      warning: "bg-orange-100 [&_svg]:text-orange-600",
    },
  },
});

function Banner({
  className,
  tone,
  ...props
}: React.PropsWithChildren<
  VariantProps<typeof bannerStyle> & {
    className?: string;
  }
>) {
  return <div className={cx(bannerStyle({ tone }), className)} {...props} />;
}
