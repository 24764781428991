import { ReactNode } from "react";

import { Typography } from "./ui/Typography";

export const VestingColumn: React.FC<{
  fullyVestedValue?: ReactNode;
  label: ReactNode;
  vestedValue?: ReactNode;
}> = ({ fullyVestedValue, label, vestedValue }) => (
  <div className="flex flex-col gap-4 text-black-05" data-cy={label}>
    <Typography variant="Medium/Extra Small">{label}</Typography>
    {vestedValue ? (
      <Typography
        as="div"
        className="flex flex-col gap-1"
        variant="Medium/Default"
      >
        <Typography variant="Regular/Caption">Vested</Typography>
        <span className="text-primary">{vestedValue}</span>
      </Typography>
    ) : null}

    {fullyVestedValue ? (
      <Typography
        as="div"
        className="flex flex-col gap-1"
        variant="Medium/Default"
      >
        <Typography variant="Regular/Caption">Fully vested</Typography>
        <span className="text-black-07">{fullyVestedValue}</span>
      </Typography>
    ) : null}
  </div>
);
