/**
 * @generated SignedSource<<4fb1d5d89c9623d6d21cf0c7ae3b09e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LargestGain_Organization$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"CurrencySymbol_Organization" | "LargestGainGraph_Organization">;
  readonly " $fragmentType": "LargestGain_Organization";
};
export type LargestGain_Organization$key = {
  readonly " $data"?: LargestGain_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"LargestGain_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LargestGain_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LargestGainGraph_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurrencySymbol_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "855929c15641166fd243530c2560b926";

export default node;
