/**
 * @generated SignedSource<<94a699a42263b828b67402c1d737d0cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SuspensedTabList_Deferred_Organization$data = {
  readonly activeGrantees: {
    readonly totalCount: number;
  };
  readonly id: string;
  readonly terminatedGrantees: {
    readonly totalCount: number;
  };
  readonly " $fragmentType": "SuspensedTabList_Deferred_Organization";
};
export type SuspensedTabList_Deferred_Organization$key = {
  readonly " $data"?: SuspensedTabList_Deferred_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"SuspensedTabList_Deferred_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SuspensedTabList_Deferred_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": "activeGrantees",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "status": "Active"
          }
        }
      ],
      "concreteType": "GranteesConnection",
      "kind": "LinkedField",
      "name": "grantees",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": "grantees(filters:{\"status\":\"Active\"})"
    },
    {
      "alias": "terminatedGrantees",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "status": "Terminated"
          }
        }
      ],
      "concreteType": "GranteesConnection",
      "kind": "LinkedField",
      "name": "grantees",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": "grantees(filters:{\"status\":\"Terminated\"})"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "f39bee27dfece619914f159eb15e3c21";

export default node;
