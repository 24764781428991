import { InputCurrency as _RemoteInputCurrency } from "@remote-com/norma";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { graphql, useFragment } from "react-relay";

import { RemoteInputCurrency_Organization$key } from "./__generated__/RemoteInputCurrency_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment RemoteInputCurrency_Organization on IOrganization {
    defaultCurrencyCode
  }
`;

const FormInput = <TFieldValues extends FieldValues>({
  control,
  name,
  organizationFragment,
  ...props
}: Omit<
  React.ComponentProps<typeof _RemoteInputCurrency>,
  "currency" | "onValueChange"
> & {
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
  organizationFragment: RemoteInputCurrency_Organization$key;
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <_RemoteInputCurrency
          {...props}
          currency={organization.defaultCurrencyCode}
          errorText={fieldState.error?.message}
          name={name}
          onValueChange={(value) => {
            field.onChange(value);
          }}
          value={field.value}
        />
      )}
    />
  );
};

export const RemoteInputCurrency = {
  Form: FormInput,
};
