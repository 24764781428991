import { StandaloneLink } from "@remote-com/norma";
import { IconV2OutlineArrowUpRight } from "@remote-com/norma/icons/IconV2OutlineArrowUpRight";
import { useFragment } from "react-relay";
import { Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import {
  BentoCard,
  BentoCardCell,
  BentoCardRow,
  BentoCardValue,
} from "../../../components/BentoCard";
import { CurrencySymbol } from "../../../components/CurrencySymbol";
import { CurrencyUnit } from "../../../components/CurrencyUnit";
import { DeclarationNoticeMessage } from "../../../components/DeclarationNoticeMessage";
import { DeleteOrganizationButton } from "../../../components/DeleteOrganizationButton";
import { FormattedNumber } from "../../../components/Formatted/FormattedNumber";
import { Page } from "../../../components/Page";
import { LargeOneColumnLayout } from "../../../components/ui/Layout/LargeOneColumnLayout";
import { useQuery } from "../../../hooks/useQuery";
import { useOrganizationIdParam } from "../../../paths";
import CONFIGURATION from "../../../services/configuration";
import NotFoundPage from "../../NotFound/NotFound";
import { CTMSSolutionLabelMap } from "../EssentialsOnboarding/CTMSPage/CTMSPage";
import { CompanyDetailsPage_Organization$key } from "./__generated__/CompanyDetailsPage_Organization.graphql";
import { CompanyDetailsPage_Query } from "./__generated__/CompanyDetailsPage_Query.graphql";
import { CompanyDetailsPage_Viewer$key } from "./__generated__/CompanyDetailsPage_Viewer.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment CompanyDetailsPage_Organization on Organization {
    id
    name
    issuingCompany {
      name
      country {
        emoji
        name
      }
    }
    grantees {
      edges {
        __typename
      }
    }
    declaredCTMSSolution
    ...LargeOneColumnLayout_Organization
    ...DeleteOrganizationButton_Organization
    ...CurrencySymbol_Organization
    ...CurrencyUnit_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment CompanyDetailsPage_Viewer on Account {
    isSuperAdmin
  }
`;

const CompanyDetailsPage_: React.FC<{
  organizationFragment: CompanyDetailsPage_Organization$key;
  viewerFragment: CompanyDetailsPage_Viewer$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  return (
    <LargeOneColumnLayout
      maxWidth={1200}
      organizationFragment={organization}
      showFooter
      title="Company details"
      topBarActionsRender={() =>
        viewer.isSuperAdmin &&
        CONFIGURATION.ENVIRONMENT !== "production" && (
          <DeleteOrganizationButton organizationFragment={organization} />
        )
      }
    >
      <div className="space-y-6">
        <DeclarationNoticeMessage>
          Message about issuing entity
        </DeclarationNoticeMessage>
        <BentoCard>
          <BentoCardRow>
            <BentoCardCell title="Issuing Company">
              <div className="space-y-2">
                <BentoCardValue
                  main={organization.issuingCompany?.name ?? "-"}
                />
                <StandaloneLink
                  as={Link}
                  IconAfter={IconV2OutlineArrowUpRight}
                  size="sm"
                  target="_blank"
                  to={CONFIGURATION.REMOTE_APP_URL}
                >
                  Manage entities in Remote
                </StandaloneLink>
              </div>
            </BentoCardCell>
            <BentoCardCell title="Incorporation country">
              <BentoCardValue
                main={organization.issuingCompany?.country?.name ?? "-"}
                sub={organization.issuingCompany?.country?.emoji}
              />
            </BentoCardCell>
          </BentoCardRow>
          <BentoCardRow>
            <BentoCardCell title="Currency">
              <BentoCardValue
                main={
                  <>
                    <CurrencySymbol organizationFragment={organization} />{" "}
                    <CurrencyUnit organizationFragment={organization} />
                  </>
                }
              />
            </BentoCardCell>
            <BentoCardCell title="Cap table management solution">
              <BentoCardValue
                main={
                  organization.declaredCTMSSolution
                    ? CTMSSolutionLabelMap[organization.declaredCTMSSolution]
                    : "-"
                }
              />
            </BentoCardCell>
            <BentoCardCell title="Number of employees">
              <BentoCardValue
                main={
                  <FormattedNumber value={organization.grantees.edges.length} />
                }
              />
            </BentoCardCell>
          </BentoCardRow>
        </BentoCard>
      </div>
    </LargeOneColumnLayout>
  );
};

const QUERY = graphql`
  query CompanyDetailsPage_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...CompanyDetailsPage_Organization
    }
    me {
      ...CompanyDetailsPage_Viewer
    }
  }
`;

export const CompanyDetailsPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();

  const {
    query: { me, organization },
  } = useQuery<CompanyDetailsPage_Query>(QUERY, {
    organizationId,
  });

  if (!organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Admin Company Settings"
      analyticsName="Admin - Company Settings - Company details"
      organizationId={organization.id}
      title={`Admin | ${organization.name} company details`}
    >
      <CompanyDetailsPage_
        organizationFragment={organization}
        viewerFragment={me}
      />
    </Page>
  );
};
