import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { isEmpty, isNull } from "lodash";
import { useCallback, useState } from "react";
import { usePaginationFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import { FormattedCurrency } from "../../../../components/Formatted/FormattedCurrency";
import {
  LoadMoreGrantsSlideOver,
  LoadMoreGrantsSlideOverState,
} from "../../../../components/LoadMoreGrantsSlideOver";
import { MissingInformationTag } from "../../../../components/MissingInformationTag";
import { Tag } from "../../../../components/ui/Tag";
import { Typography } from "../../../../components/ui/Typography";
import { useQuery } from "../../../../hooks/useQuery";
import { APPLICATION_ROUTES } from "../../../../paths";
import { UnderwaterGrantsSlideOver_OrganizationCTMSGrantsPagination_Query } from "./__generated__/UnderwaterGrantsSlideOver_OrganizationCTMSGrantsPagination_Query.graphql";
import { UnderwaterGrantsSlideOver_Query } from "./__generated__/UnderwaterGrantsSlideOver_Query.graphql";
import {
  UnderwaterGrantsSlideOver_Refetchable_Organization$data,
  UnderwaterGrantsSlideOver_Refetchable_Organization$key,
} from "./__generated__/UnderwaterGrantsSlideOver_Refetchable_Organization.graphql";

const ORGANIZATION_REFETCHABLE_FRAGMENT = graphql`
  fragment UnderwaterGrantsSlideOver_Refetchable_Organization on Organization
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 20 }
    filters: { type: "OrganizationCTMSGrantsFilters" }
  )
  @refetchable(
    queryName: "UnderwaterGrantsSlideOver_OrganizationCTMSGrantsPagination_Query"
  ) {
    ctmsGrants(first: $count, after: $cursor, filters: $filters)
      @connection(key: "CTMSGrants_Organization_ctmsGrants") {
      totalCount
      edges {
        node {
          id
          label
          exercisePrice
          grantee {
            name
            email
          }
        }
      }
    }
  }
`;

const QUERY = graphql`
  query UnderwaterGrantsSlideOver_Query(
    $organizationId: OrganizationId!
    $filters: OrganizationCTMSGrantsFilters
  ) {
    organization(id: $organizationId) @required(action: THROW) {
      id
      latestFairMarketValue {
        value
      }

      ...UnderwaterGrantsSlideOver_Refetchable_Organization
        @arguments(filters: $filters)
      ...FormattedCurrency_Organization
    }
  }
`;

type State =
  | {
      ctmsGrantsIds: null | string[];
      show: false;
      title: null | React.ReactNode;
    }
  | {
      ctmsGrantsIds: string[];
      show: true;
      title: React.ReactNode;
    };

export function useUnderwaterGrantsSlideOverState() {
  const [state, setState] = useState<State>({
    ctmsGrantsIds: [],
    show: false,
    title: null,
  });

  const open = ({
    ctmsGrantsIds,
    title,
  }: {
    ctmsGrantsIds: string[];
    title: React.ReactNode;
  }) => {
    setState({
      ctmsGrantsIds,
      show: true,
      title,
    });
  };

  const close = () => {
    setState((previousState) => ({
      ...previousState,
      show: false,
    }));
  };

  return {
    close,
    open,
    state,
  };
}

const Cell: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div
      className={classNames(
        "table-cell align-middle",
        "py-2 first:pl-2 last:pr-2",
        "border-y-[0.5px] first:border-l-[0.5px] last:border-r-[0.5px]",
        "border-transparent transition-all group-hover:border-gray-04",
        "first:rounded-l last:rounded-r",
      )}
    >
      {children}
    </div>
  );
};

const Row: React.FC<{
  ctmsGrant: UnderwaterGrantsSlideOver_Refetchable_Organization$data["ctmsGrants"]["edges"][number]["node"];
  organization: UnderwaterGrantsSlideOver_Query["response"]["organization"];
}> = ({ ctmsGrant, organization }) => {
  return (
    <Link
      className="group table-row cursor-pointer"
      to={generatePath(APPLICATION_ROUTES.organizationEquityCtmsGrant, {
        ctmsGrantId: ctmsGrant.id,
        organizationId: organization.id,
      })}
    >
      <Cell>
        <Tag color="gray" size="Small">
          {ctmsGrant.label}
        </Tag>
      </Cell>
      <Cell>
        <div className="space-y-1">
          <Typography as="div" variant="Regular/Extra Small">
            {ctmsGrant.grantee.name}
          </Typography>
          <Typography
            as="div"
            className="text-black-05"
            variant="Regular/Caption"
          >
            {ctmsGrant.grantee.email}
          </Typography>
        </div>
      </Cell>
      {organization.latestFairMarketValue && (
        <Cell>
          <div className="space-y-1">
            <Typography
              as="div"
              className="text-black-05"
              variant="Regular/Caption"
            >
              FMV today
            </Typography>
            <Typography as="div" variant="Medium/Caption">
              <FormattedCurrency
                organizationFragment={organization}
                value={organization.latestFairMarketValue.value}
              />
            </Typography>
          </div>
        </Cell>
      )}
      <Cell>
        <div className="space-y-1">
          <Typography
            as="div"
            className="text-black-05"
            variant="Regular/Caption"
          >
            Strike price today
          </Typography>
          {!isNull(ctmsGrant.exercisePrice) ? (
            <Typography as="div" variant="Medium/Caption">
              <FormattedCurrency
                organizationFragment={organization}
                value={ctmsGrant.exercisePrice}
              />
            </Typography>
          ) : (
            <MissingInformationTag />
          )}
        </div>
      </Cell>
      {organization.latestFairMarketValue &&
        ctmsGrant.exercisePrice !== null && (
          <Cell>
            <Tag
              color={
                ctmsGrant.exercisePrice >
                organization.latestFairMarketValue.value
                  ? "red"
                  : ctmsGrant.exercisePrice <
                      organization.latestFairMarketValue.value
                    ? "green"
                    : "gray"
              }
              size="Small"
            >
              <FormattedCurrency
                organizationFragment={organization}
                signDisplay="exceptZero"
                value={
                  organization.latestFairMarketValue.value -
                  ctmsGrant.exercisePrice
                }
              />
            </Tag>
          </Cell>
        )}
      <Cell>
        <div className="w-5 shrink-0 px-4">
          <ArrowTopRightOnSquareIcon className="h-5 text-primary opacity-0 transition-all group-hover:opacity-100" />
        </div>
      </Cell>
    </Link>
  );
};

const UnderwaterGrantsSlideOverContent: React.FC<{
  organizationId: string;
  state: LoadMoreGrantsSlideOverState;
}> = ({ organizationId, state }) => {
  const { query } = useQuery<UnderwaterGrantsSlideOver_Query>(QUERY, {
    filters: {
      idIn: state.ctmsGrantsIds,
    },
    organizationId,
  });

  const {
    data: { ctmsGrants },
    hasNext,
    isLoadingNext,
    loadNext,
    refetch,
  } = usePaginationFragment<
    UnderwaterGrantsSlideOver_OrganizationCTMSGrantsPagination_Query,
    UnderwaterGrantsSlideOver_Refetchable_Organization$key
  >(ORGANIZATION_REFETCHABLE_FRAGMENT, query.organization);

  const handleSearchChange = useCallback(
    (search: string) => {
      refetch({
        filters: {
          idIn: state.ctmsGrantsIds,
          search: isEmpty(search) ? null : search,
        },
      });
    },
    [refetch, state.ctmsGrantsIds],
  );

  return (
    <LoadMoreGrantsSlideOver.Layout
      onSearchChange={handleSearchChange}
      state={state}
    >
      <LoadMoreGrantsSlideOver.GrantsList
        hasNext={hasNext}
        loading={isLoadingNext}
        noResult={ctmsGrants.totalCount === 0}
        onLoadMoreClick={() => loadNext(20)}
      >
        <div className="table w-full border-separate border-spacing-0">
          <div className="table-row-group">
            {ctmsGrants.edges.map(({ node: grant }) => (
              <Row
                ctmsGrant={grant}
                key={grant.id}
                organization={query.organization}
              />
            ))}
          </div>
        </div>
      </LoadMoreGrantsSlideOver.GrantsList>
    </LoadMoreGrantsSlideOver.Layout>
  );
};

export const UnderwaterGrantsSlideOver: React.FC<{
  onClose: () => void;
  organizationId: string;
  state: LoadMoreGrantsSlideOverState;
}> = ({ onClose, organizationId, state }) => {
  return (
    <LoadMoreGrantsSlideOver onClose={onClose} state={state}>
      <UnderwaterGrantsSlideOverContent
        organizationId={organizationId}
        state={state}
      />
    </LoadMoreGrantsSlideOver>
  );
};
