import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import classNames from "classnames";
import { formatISO } from "date-fns";
import { isEmpty, isNil, noop } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Controller,
  DefaultValues,
  useForm,
  useFormState,
  useWatch,
} from "react-hook-form";
import { useIntl } from "react-intl";
import { useFragment } from "react-relay";
import { generatePath } from "react-router";
import { graphql } from "relay-runtime";
import * as z from "zod";

import { US_STATES } from "../helpers/USStates";
import { useManualQuery } from "../hooks/useManualQuery";
import { useSafeMutation } from "../hooks/useSafeMutation";
import { CheckboxFormRow } from "../pages/Admin/EquityOffer/CheckboxFormRow";
import { APPLICATION_ROUTES } from "../paths";
import { countries, US_COUNTRY_CODE } from "../services/Country";
import {
  WORK_RELATIONSHIP_TYPES,
  WORK_RELATIONSHIPS,
} from "../services/workRelationship";
import { GranteeForm_CreateEmployerOfRecord_Mutation } from "./__generated__/GranteeForm_CreateEmployerOfRecord_Mutation.graphql";
import {
  GranteeForm_CreateGrantee_Mutation,
  HRISProvider,
} from "./__generated__/GranteeForm_CreateGrantee_Mutation.graphql";
import { GranteeForm_DefaultHRISProviderEmployee$key } from "./__generated__/GranteeForm_DefaultHRISProviderEmployee.graphql";
import { GranteeForm_FindGranteeByEmail_Query } from "./__generated__/GranteeForm_FindGranteeByEmail_Query.graphql";
import { GranteeForm_Grantee$key } from "./__generated__/GranteeForm_Grantee.graphql";
import { GranteeForm_HRISProviderEmployee$key } from "./__generated__/GranteeForm_HRISProviderEmployee.graphql";
import { GranteeForm_Organization$key } from "./__generated__/GranteeForm_Organization.graphql";
import {
  GranteeForm_UpdateGrantee_Mutation,
  GranteeForm_UpdateGrantee_Mutation$variables,
  UpdateGranteeFailureReason,
} from "./__generated__/GranteeForm_UpdateGrantee_Mutation.graphql";
import { ConnectHRISProviderMessage } from "./ConnectHRISProviderMessage";
import {
  GranteeDeletionConfirmationModal,
  useGranteeDeletionConfirmationModalState,
} from "./GranteeDeletionConfirmationModal";
import { MissingInformationFromHRISProviderTag } from "./MissingInformationFromHRISProviderTag";
import { SelectCompanies } from "./SelectCompanies";
import { useToaster } from "./Toaster";
import { Button, LinkButton } from "./ui/Button";
import { DeelLogo } from "./ui/DeelLogo";
import { Checkbox } from "./ui/Form/Checkbox";
import { FormRow } from "./ui/Form/FormRow";
import { DatePicker } from "./ui/Form/Inputs/DatePicker";
import { Input } from "./ui/Form/Inputs/Input";
import { SelectAutocomplete } from "./ui/Form/Inputs/Select/SelectAutocomplete";
import { SelectRow } from "./ui/Form/Inputs/Select/SelectRow";
import { RadioGroup } from "./ui/Form/RadioGroup";
import { NoticeMessage } from "./ui/NoticeMessage";
import { RemoteLogo } from "./ui/RemoteLogo";
import { ReplyIcon } from "./ui/ReplyIcon";
import { Toast } from "./ui/Toast";
import { Typography } from "./ui/Typography";

const GranteeFormSchema = z
  .object({
    companyId: z.string().nullable().default(null),
    contractStartDate: z.string(),
    countryCode: z.string(),
    email: z.string().trim().email(),
    employerOfRecordId: z.string().nullable().default(null),
    isUSCitizen: z.boolean().nullable().default(null),
    managementCompanyName: z.string().trim().nullable().default(null),
    name: z.string().trim().min(1),
    USStateOfResidenceCode: z.string().nullable().default(null),
    workRelationship: z.enum(WORK_RELATIONSHIP_TYPES),

    equityGridLevelId: z.string().nullable().default(null),
    jobTitle: z.string().trim().nullable().default(null),
  })
  .refine(
    ({ companyId, workRelationship }) =>
      workRelationship !== "DirectEmployee" || !isNil(companyId),
    { message: "Required", path: ["companyId"] },
  )
  .refine(
    ({ managementCompanyName, workRelationship }) =>
      workRelationship !== "ContractorManagementCompany" ||
      !isEmpty(managementCompanyName?.trim()),
    { message: "Required", path: ["managementCompanyName"] },
  )
  .refine(
    ({ employerOfRecordId, workRelationship }) =>
      workRelationship !== "EoREmployee" || !isNil(employerOfRecordId),
    {
      message:
        "We need the name of your Employer or Record for future reporting",
      path: ["employerOfRecordId"],
    },
  )
  .refine(
    ({ countryCode, USStateOfResidenceCode }) =>
      countryCode !== "US" || USStateOfResidenceCode,
    { message: "Required", path: ["USStateOfResidenceCode"] },
  );

export type GranteeFormInputs = z.infer<typeof GranteeFormSchema>;

const CREATE_EASOP_GRANTEE_MUTATION = graphql`
  mutation GranteeForm_CreateGrantee_Mutation(
    $granteeAttributes: GranteeAttributes!
    $hRISProviderEmployee: HRISProviderEmployeeInput
    $connections: [ID!]!
    $organizationId: OrganizationId!
  ) {
    createGrantee(
      granteeAttributes: $granteeAttributes
      hRISProviderEmployee: $hRISProviderEmployee
    ) {
      grantee @appendEdge(connections: $connections) {
        node {
          id
          ...GrantFormSlide_Grantees
          ...GrantForm_Grantees @arguments(organizationId: $organizationId)
          ...GranteeDetails_Grantee @arguments(organizationId: $organizationId)
          ...Grantee_Grantee
        }
      }
    }
  }
`;

const CREATE_EMPLOYER_OF_RECORD_MUTATION = graphql`
  mutation GranteeForm_CreateEmployerOfRecord_Mutation(
    $attributes: EmployerOfRecordAttributes!
    $organizationId: OrganizationId!
  ) {
    createEmployerOfRecord(
      attributes: $attributes
      organizationId: $organizationId
    ) {
      employersOfRecord {
        id
        name
      }
    }
  }
`;

const UPDATE_GRANTEE_MUTATION = graphql`
  mutation GranteeForm_UpdateGrantee_Mutation(
    $id: GranteeId!
    $granteeAttributes: GranteeAttributes!
    $organizationId: OrganizationId!
  ) {
    updateGrantee(id: $id, granteeAttributes: $granteeAttributes) {
      __typename
      ... on UpdateGranteeSuccess {
        grantee {
          cannotBeInvitedReason
          ...GrantFormSlide_Grantees
          ...GrantForm_Grantees @arguments(organizationId: $organizationId)
          ...GranteeDetails_Grantee @arguments(organizationId: $organizationId)
          ...EmployeeDetails_Grantee @arguments(organizationId: $organizationId)
          ...Grantee_Grantee
        }
      }
      ... on UpdateGranteeFailure {
        reason
      }
    }
  }
`;

const UPDATE_GRANTEE_ERRORS: Record<UpdateGranteeFailureReason, string> = {
  CANNOT_MODIFY_CONTRACT_START_DATE_IF_DRAFTS_EXIST:
    "Contract start date cannot be modified. You first have to delete existing drafts for this grantee.",
  CANNOT_MODIFY_COUNTRY_IF_DRAFTS_EXIST:
    "Tax residence cannot be modified. You first have to delete existing drafts for this grantee.",
  CANNOT_MODIFY_WORK_RELATIONSHIP_IF_DRAFTS_EXIST:
    "Work relationship cannot be modified. You first have to delete existing drafts for this grantee.",
};

const HRIS_PROVIDER_EMPLOYEE_FRAGMENT = graphql`
  fragment GranteeForm_HRISProviderEmployee on HRISProviderEmployee
  @relay(plural: true) {
    id
    hRISProvider
    hRISProviderEmployeeId
    email
    name
    workRelationship
    jobTitle
    taxResidenceCountry {
      code
    }
    USStateOfResidence {
      code
    }
    startDate
  }
`;

const DEFAULT_HRIS_PROVIDER_EMPLOYEE_FRAGMENT = graphql`
  fragment GranteeForm_DefaultHRISProviderEmployee on HRISProviderEmployee {
    id
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment GranteeForm_Organization on Organization {
    id
    companies {
      name
      completeName
      ...SelectCompanies_Companies
    }
    equityGrid {
      activated
      levels {
        id
        name
      }
    }
    HRISProviderEmployeesSuggestedAsNewGrantee {
      ...GranteeForm_HRISProviderEmployee
    }
    employersOfRecord {
      id
      name
      hRISProvider
    }
    ...ConnectHRISProviderMessage_Organization
  }
`;

type SimplifiedWorkRelationshipType =
  | "Advisor"
  | "Contractor"
  | "DirectEmployee"
  | "EoREmployee";

const SIMPLIFIED_WORK_RELATIONSHIP_TO_LABEL_HELPER: Record<
  SimplifiedWorkRelationshipType,
  {
    help: string;
    id: SimplifiedWorkRelationshipType;
    pluralLabel: string;
    singularLabel: string;
  }
> = {
  Advisor: {
    help: "i.e. person providing occasional advisory services",
    id: "Advisor",
    pluralLabel: "Advisors",
    singularLabel: "Advisor",
  },
  Contractor: {
    help: "i.e. as a natural person or via a management company",
    id: "Contractor",
    pluralLabel: "Contractors",
    singularLabel: "Contractor",
  },
  DirectEmployee: {
    help: "i.e. via your own holding or subsidiary",
    id: "DirectEmployee",
    pluralLabel: "Direct employees",
    singularLabel: "Direct employee",
  },
  EoREmployee: {
    help: "i.e. via Remote, Deel, Oyster, etc",
    id: "EoREmployee",
    pluralLabel: "EoR employees",
    singularLabel: "EoR employee",
  },
};

export const SIMPLIFIED_WORK_RELATIONSHIPS = Object.values(
  SIMPLIFIED_WORK_RELATIONSHIP_TO_LABEL_HELPER,
);

export const OFF_GRID_LEVEL_ID = "OFF_GRID_LEVEL_ID";

type UseGranteeForm = ReturnType<typeof useGranteeForm>;

export const GranteeForm: React.FC<{
  className?: string;
  control: UseGranteeForm["control"];
  defaultGranteeFragment?: GranteeForm_Grantee$key | null;
  defaultHRISProviderEmployeeFragment?: GranteeForm_DefaultHRISProviderEmployee$key | null;
  formReset: UseGranteeForm["reset"];
  granteesRelayConnectionIds?: string[];
  handleSubmit: UseGranteeForm["handleSubmit"];
  id: string;
  ignoredHRISProviderEmployeesFragment?: GranteeForm_HRISProviderEmployee$key | null;
  onCancel: () => void;
  onGranteeCreated?: (createdGranteeId: string) => void;
  onGranteeDeleted?: (deletedGranteeId: string) => void;
  onGranteeUpdated?: (updatedGranteeId: string) => void;
  organizationFragment: GranteeForm_Organization$key;
  setValue: UseGranteeForm["setValue"];
  submissionInProgress: boolean;
  watch: UseGranteeForm["watch"];
}> = ({
  className,
  control,
  defaultGranteeFragment = null,
  defaultHRISProviderEmployeeFragment = null,
  formReset,
  granteesRelayConnectionIds,
  handleSubmit,
  id,
  ignoredHRISProviderEmployeesFragment = null,
  onCancel,
  onGranteeCreated = noop,
  onGranteeDeleted = noop,
  onGranteeUpdated = noop,
  organizationFragment,
  setValue,
  submissionInProgress,
  watch,
}) => {
  const defaultGrantee = useFragment(GRANTEE_FRAGMENT, defaultGranteeFragment);
  const defaultHRISProviderEmployee = useFragment(
    DEFAULT_HRIS_PROVIDER_EMPLOYEE_FRAGMENT,
    defaultHRISProviderEmployeeFragment,
  );
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const notIgnoredHRISProviderEmployees =
    useFragment<GranteeForm_HRISProviderEmployee$key>(
      HRIS_PROVIDER_EMPLOYEE_FRAGMENT,
      organization.HRISProviderEmployeesSuggestedAsNewGrantee,
    );
  const ignoredHRISProviderEmployees = useFragment(
    HRIS_PROVIDER_EMPLOYEE_FRAGMENT,
    ignoredHRISProviderEmployeesFragment,
  );

  const hRISProviderEmployeesSuggestedAsNewGrantee = useMemo(
    () => [
      ...new Set([
        ...(ignoredHRISProviderEmployees ?? []),
        ...notIgnoredHRISProviderEmployees,
      ]),
    ],
    [notIgnoredHRISProviderEmployees, ignoredHRISProviderEmployees],
  );

  const workRelationship = useWatch({ control, name: "workRelationship" });

  const employersOfRecord = useMemo(
    () =>
      organization.employersOfRecord.map((employerOfRecord) => ({
        label: employerOfRecord.name,
        value: employerOfRecord.id,
      })),
    [organization.employersOfRecord],
  );

  const toaster = useToaster();

  const [createGrantee] = useSafeMutation<GranteeForm_CreateGrantee_Mutation>(
    CREATE_EASOP_GRANTEE_MUTATION,
  );

  const [editGrantee] = useSafeMutation<GranteeForm_UpdateGrantee_Mutation>(
    UPDATE_GRANTEE_MUTATION,
  );

  const { errors } = useFormState({ control });

  const creatingGrantee = !defaultGrantee?.id;

  const countryCode = watch("countryCode");
  const employerOfRecordId = watch("employerOfRecordId");

  const employerOfRecord = useMemo(
    () =>
      organization.employersOfRecord.find(
        (employerOfRecord) => employerOfRecord.id === employerOfRecordId,
      ),
    [employerOfRecordId, organization.employersOfRecord],
  );

  const intl = useIntl();

  const showEquityGridFeature = organization.equityGrid.activated;

  const equityGridLevels = [
    ...organization.equityGrid.levels,
    { id: OFF_GRID_LEVEL_ID, name: "Off Grid" },
  ];

  const handleGranteeDeletion = useCallback(
    (granteeId: string) => {
      toaster.push(<Toast title="Nice!">Grantee deleted!</Toast>);
      onGranteeDeleted(granteeId);
    },
    [onGranteeDeleted, toaster],
  );

  const defaultGranteeId = defaultGrantee?.id;

  const {
    closeGranteeDeletionConfirmationModal,
    granteeDeletionConfirmationModalState,
    showGranteeDeletionConfirmationModal,
  } = useGranteeDeletionConfirmationModalState();

  const [selectedHRISProviderEmployeeId, setSelectedHRISProviderEmployeeId] =
    useState<null | string>(
      defaultHRISProviderEmployee ? defaultHRISProviderEmployee.id : null,
    );

  const granteeName = watch("name");
  const [createdName, setCreatedName] = useState<null | string>(
    granteeName ?? null,
  );

  const hrisProviderEmployees = useMemo(() => {
    const employees = hRISProviderEmployeesSuggestedAsNewGrantee.map(
      (employee) => ({
        hRISProvider: employee.hRISProvider,
        label: employee.name,
        value: employee.id,
      }),
    );
    const createdNameItem = createdName
      ? {
          hRISProvider: null,
          label: createdName,
          value: createdName,
        }
      : null;
    return createdNameItem ? [createdNameItem, ...employees] : employees;
  }, [hRISProviderEmployeesSuggestedAsNewGrantee, createdName]);

  const selectedHRISProviderEmployee = useMemo(() => {
    if (!selectedHRISProviderEmployeeId) return null;

    const employee = hRISProviderEmployeesSuggestedAsNewGrantee.find(
      (employee) => employee.id === selectedHRISProviderEmployeeId,
    );

    return employee ?? null;
  }, [
    selectedHRISProviderEmployeeId,
    hRISProviderEmployeesSuggestedAsNewGrantee,
  ]);

  const getEmployerOfRecordId = useCallback(
    (hRISProvider: HRISProvider) => {
      const employerOfRecord = organization.employersOfRecord.find(
        (employerOfRecord) => employerOfRecord.hRISProvider === hRISProvider,
      );

      if (!employerOfRecord) {
        throw new Error(`Could not find Employer Of Record ${hRISProvider}`);
      }

      return employerOfRecord.id;
    },
    [organization.employersOfRecord],
  );

  useEffect(() => {
    if (defaultGrantee) return;

    if (selectedHRISProviderEmployee) {
      formReset({
        companyId: undefined,
        contractStartDate: selectedHRISProviderEmployee.startDate ?? undefined,
        countryCode: selectedHRISProviderEmployee.taxResidenceCountry?.code,
        email: selectedHRISProviderEmployee.email,
        employerOfRecordId:
          selectedHRISProviderEmployee.workRelationship === "EoREmployee"
            ? getEmployerOfRecordId(selectedHRISProviderEmployee.hRISProvider)
            : undefined,
        equityGridLevelId: undefined,
        isUSCitizen: null,
        jobTitle: selectedHRISProviderEmployee.jobTitle,
        managementCompanyName: undefined,
        name: selectedHRISProviderEmployee.name,
        USStateOfResidenceCode:
          selectedHRISProviderEmployee.USStateOfResidence?.code,
        workRelationship: selectedHRISProviderEmployee.workRelationship,
      });
    } else {
      setValue("name", createdName ?? undefined);
    }
  }, [
    selectedHRISProviderEmployee,
    createdName,
    setValue,
    formReset,
    defaultGrantee,
    getEmployerOfRecordId,
  ]);

  const onSubmit = handleSubmit(async (_data) => {
    const data = _data as GranteeFormInputs;
    const offGrid = data.equityGridLevelId === OFF_GRID_LEVEL_ID;
    const granteeAttributes: GranteeForm_UpdateGrantee_Mutation$variables["granteeAttributes"] =
      {
        companyId:
          data.workRelationship === "DirectEmployee" ? data.companyId : null,
        contractStartDate: data.contractStartDate,
        email: data.email,
        employerOfRecordId:
          data.workRelationship === "EoREmployee"
            ? data.employerOfRecordId
            : null,
        equityGridLevelId: offGrid ? null : data.equityGridLevelId,
        isUSCitizen: data.isUSCitizen,
        jobTitle: !isEmpty(data.jobTitle) ? data.jobTitle : null,
        managementCompanyName:
          data.workRelationship === "ContractorManagementCompany"
            ? data.managementCompanyName
            : null,
        name: data.name,
        offGrid,
        organizationId: organization.id,
        taxResidenceCountryCode: data.countryCode,
        USStateOfResidenceCode:
          data.countryCode === US_COUNTRY_CODE
            ? data.USStateOfResidenceCode
            : null,
        workRelationship: data.workRelationship,
      };

    if (defaultGrantee?.id) {
      const granteeId = defaultGrantee.id;
      const result = await editGrantee({
        variables: {
          granteeAttributes,
          id: granteeId,
          organizationId: organization.id,
        },
      });

      if (result.updateGrantee.__typename === "UpdateGranteeFailure") {
        toaster.push(
          <Toast title="Oops!" variant="error">
            {UPDATE_GRANTEE_ERRORS[result.updateGrantee.reason]}
          </Toast>,
        );
        return;
      }
      toaster.push(
        <Toast title="Amazing!">Grantee successfully updated!</Toast>,
      );

      onGranteeUpdated(granteeId);
    } else {
      const createdGrantee = await createGrantee({
        variables: {
          connections: granteesRelayConnectionIds ?? [],
          granteeAttributes,
          hRISProviderEmployee: selectedHRISProviderEmployee
            ? {
                hRISProvider: selectedHRISProviderEmployee.hRISProvider,
                hRISProviderEmployeeId:
                  selectedHRISProviderEmployee.hRISProviderEmployeeId,
              }
            : null,
          organizationId: organization.id,
        },
      });

      toaster.push(<Toast title="Amazing!">Grantee created!</Toast>);
      onGranteeCreated(createdGrantee.createGrantee.grantee.node.id);
    }
  });

  const [createEmployerOfRecord] =
    useSafeMutation<GranteeForm_CreateEmployerOfRecord_Mutation>(
      CREATE_EMPLOYER_OF_RECORD_MUTATION,
    );

  const addEmployerOfRecord = useCallback(
    async (name: string) => {
      const result = await createEmployerOfRecord({
        variables: {
          attributes: {
            name,
          },
          organizationId: organization.id,
        },
      });

      const addedEmployerOfRecord =
        result.createEmployerOfRecord.employersOfRecord.find(
          (employerOfRecord) => employerOfRecord.name === name,
        );

      if (!addedEmployerOfRecord) {
        throw new Error(
          "Could not find the Employer of Record that was just created",
        );
      }

      setValue("employerOfRecordId", addedEmployerOfRecord.id);
    },
    [createEmployerOfRecord, organization.id, setValue],
  );

  return (
    <>
      {defaultGranteeId ? (
        <GranteeDeletionConfirmationModal
          granteesRelayConnectionIds={granteesRelayConnectionIds}
          onClose={closeGranteeDeletionConfirmationModal}
          onGranteeDeleted={handleGranteeDeletion}
          state={granteeDeletionConfirmationModalState}
        />
      ) : null}
      <form
        className={classNames(className, "space-y-10 @container")}
        id={id}
        onSubmit={(event) => {
          // Needed to prevent the form in the Grantee step (assisted grant flow) to be submitted
          event.preventDefault();
          event.stopPropagation();
          return onSubmit(event);
        }}
      >
        <div className="space-y-6">
          <FormRow
            className="w-full"
            error={errors.name?.message}
            id="name"
            label="Full name"
          >
            {!creatingGrantee || isEmpty(hrisProviderEmployees) ? (
              <Input
                {...control.register("name")}
                placeholder="E.g. John DOE"
              />
            ) : (
              <SelectAutocomplete
                formatOptionLabel={(option) => {
                  if (!option.hRISProvider) return option.label;

                  return (
                    <div className="flex items-center gap-2">
                      <span>{option.label}</span>
                      <ArrowPathIcon className="h-3 w-3 text-primary" />
                      <Typography
                        className="text-black-05"
                        variant="Regular/Caption"
                      >
                        Sync from
                      </Typography>
                      {option.hRISProvider === "DEEL" && (
                        <DeelLogo className="h-2.5 object-contain" />
                      )}
                      {option.hRISProvider === "REMOTE" && (
                        <RemoteLogo className="h-2.5 object-contain" />
                      )}
                    </div>
                  );
                }}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                onChange={(option) => {
                  setCreatedName(null);
                  setSelectedHRISProviderEmployeeId(option?.value ?? null);
                }}
                onCreateOption={(value) => {
                  setCreatedName(value);
                  setSelectedHRISProviderEmployeeId(null);
                }}
                options={hrisProviderEmployees}
                placeholder="E.g. John DOE"
                value={hrisProviderEmployees.find(
                  (option) =>
                    option.value ===
                    (selectedHRISProviderEmployeeId ?? createdName),
                )}
                // formatCreateLabel={(value) => `Use "${value}"`}
              />
            )}
          </FormRow>

          <FormRow
            context="Why personal? Because in some cases your team members will still benefit from equity incentives after the relationship has ended so it's important you can still contact them at that time. Don’t worry, we won’t be sending any emails to this address until you decide to do so once the grant is final."
            error={errors.email?.message}
            id="email"
            label="Personal email address"
          >
            <Input
              {...control.register("email")}
              placeholder="E.g. john.doe@personal.com"
            />
          </FormRow>
          <div className="space-y-2">
            <Controller
              control={control}
              name="countryCode"
              render={({ field, fieldState }) => (
                <FormRow
                  context="We need to know where the grantee is paying taxes."
                  error={fieldState.error?.message}
                  id="country"
                  label="Grantee tax residence"
                  warning={
                    selectedHRISProviderEmployee?.hRISProvider &&
                    !selectedHRISProviderEmployee.taxResidenceCountry && (
                      <MissingInformationFromHRISProviderTag
                        hRISProvider={selectedHRISProviderEmployee.hRISProvider}
                      />
                    )
                  }
                >
                  <SelectAutocomplete
                    getOptionLabel={(country) =>
                      `${country.emoji} ${country.name}`
                    }
                    getOptionValue={(country) => country.code}
                    onChange={(newValue) => {
                      field.onChange(newValue?.code);
                    }}
                    options={countries}
                    placeholder="E.g. United Kingdom"
                    value={
                      countries.find(
                        (country) => country.code === field.value,
                      ) ?? null
                    }
                  />
                </FormRow>
              )}
            />
            <Controller
              control={control}
              name="isUSCitizen"
              render={({ field: { value, ...field } }) => (
                <CheckboxFormRow
                  checkbox={<Checkbox checked={value ?? false} {...field} />}
                  label="The grantee is a US citizen 🇺🇸"
                />
              )}
            />
          </div>

          {countryCode === US_COUNTRY_CODE ? (
            <Controller
              control={control}
              name="USStateOfResidenceCode"
              render={({ field, fieldState }) => (
                <FormRow
                  error={fieldState.error?.message}
                  id="USStateOfResidenceCode"
                  label="State of residence"
                  warning={
                    selectedHRISProviderEmployee?.hRISProvider &&
                    !selectedHRISProviderEmployee.USStateOfResidence && (
                      <MissingInformationFromHRISProviderTag
                        hRISProvider={selectedHRISProviderEmployee.hRISProvider}
                      />
                    )
                  }
                >
                  <SelectAutocomplete
                    getOptionLabel={(country) => country.name}
                    getOptionValue={(country) => country.code}
                    onChange={(newValue) => {
                      field.onChange(newValue?.code);
                    }}
                    options={US_STATES}
                    placeholder="Select a state"
                    value={
                      US_STATES.find(
                        (country) => country.code === field.value,
                      ) ?? null
                    }
                  />
                </FormRow>
              )}
            />
          ) : null}
        </div>
        <div className="space-y-6">
          <Typography variant="Medium/Small">Work relationship</Typography>

          <div className="space-y-4">
            <Controller
              control={control}
              name="workRelationship"
              render={({ field, fieldState }) => {
                const simplifiedValue = field.value
                  ? SIMPLIFIED_WORK_RELATIONSHIP_TO_LABEL_HELPER[
                      field.value === "ContractorManagementCompany" ||
                      field.value === "ContractorNaturalPerson"
                        ? "Contractor"
                        : field.value
                    ]
                  : null;

                return (
                  <FormRow
                    className="w-full"
                    error={fieldState.error?.message}
                    id="workRelationship"
                    label="Your work relationship with the grantee"
                  >
                    <SelectAutocomplete
                      formatOptionLabel={(data, { context }) => {
                        return (
                          <SelectRow
                            help={context === "menu" ? data.help : undefined}
                            label={data.singularLabel}
                          />
                        );
                      }}
                      getOptionLabel={(workRelationship) =>
                        workRelationship.singularLabel
                      }
                      getOptionValue={(workRelationship) => workRelationship.id}
                      onChange={(newValue) => {
                        field.onChange(
                          newValue?.id === "Contractor"
                            ? "ContractorNaturalPerson"
                            : newValue?.id,
                        );
                      }}
                      options={SIMPLIFIED_WORK_RELATIONSHIPS}
                      placeholder={`E.g. ${
                        WORK_RELATIONSHIPS[0]?.singularLabel ??
                        "Direct Employee"
                      }`}
                      usePortal
                      value={simplifiedValue}
                    />
                  </FormRow>
                );
              }}
            />
            {(workRelationship === "ContractorManagementCompany" ||
              workRelationship === "ContractorNaturalPerson") && (
              <Controller
                control={control}
                name="workRelationship"
                render={({ field }) => (
                  <div>
                    <RadioGroup
                      className="grid gap-2 @2xl:grid-cols-2 @2xl:gap-4"
                      onChange={field.onChange}
                      value={field.value}
                    >
                      <RadioGroup.Card
                        description="Contractor is self-employed and working as a natural person"
                        tag="Most common"
                        value="ContractorNaturalPerson"
                      >
                        Individual
                      </RadioGroup.Card>
                      <RadioGroup.Card
                        description="Contractor works via their own corporation (e.g. LLC, Inc, Ltd, GmbH, SRL, etc.)"
                        value="ContractorManagementCompany"
                      >
                        Business entity
                      </RadioGroup.Card>
                    </RadioGroup>
                  </div>
                )}
              />
            )}
            {workRelationship === "DirectEmployee" && (
              <div className="flex items-center gap-2">
                <ReplyIcon className="m-1.5 h-[15px] text-gray-09" />
                <Controller
                  control={control}
                  name="companyId"
                  render={({ field, fieldState }) => (
                    <FormRow
                      className="w-full"
                      error={fieldState.error?.message}
                      id="company"
                      label="Select the entity"
                    >
                      <SelectCompanies
                        companies={organization.companies}
                        onChange={(id) => {
                          field.onChange(id);
                        }}
                        placeholder={`E.g. ${
                          organization.companies[0]
                            ? organization.companies[0].completeName
                            : "Acme INC"
                        }`}
                        selectedCompanyId={field.value}
                      />
                    </FormRow>
                  )}
                />
              </div>
            )}
            {workRelationship === "EoREmployee" && (
              <>
                <div className="flex items-center gap-2">
                  <ReplyIcon className="m-1.5 h-[15px] text-gray-09" />
                  <Controller
                    control={control}
                    name="employerOfRecordId"
                    render={({ field, fieldState }) => (
                      <FormRow
                        className="w-full"
                        error={fieldState.error?.message}
                        id="employerOfRecord"
                        label="Employer of record"
                      >
                        <SelectAutocomplete
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                          onChange={(newValue) => {
                            field.onChange(newValue?.value);
                          }}
                          onCreateOption={(value) => {
                            void addEmployerOfRecord(value);
                          }}
                          options={employersOfRecord}
                          placeholder="E.g. Remote"
                          usePortal
                          value={employersOfRecord.find(
                            (option) => option.value === field.value,
                          )}
                        />
                      </FormRow>
                    )}
                  />
                </div>
                {employerOfRecord?.hRISProvider && (
                  <ConnectHRISProviderMessage
                    hRISProvider={employerOfRecord.hRISProvider}
                    organizationFragment={organization}
                  />
                )}
              </>
            )}
            {workRelationship === "ContractorManagementCompany" && (
              <div className="flex items-center gap-2">
                <ReplyIcon className="m-1.5 h-[15px] text-gray-09" />
                <FormRow
                  className="w-full"
                  error={errors.managementCompanyName?.message}
                  id="managementCompanyName"
                  label="Business entity name"
                >
                  <Input
                    {...control.register("managementCompanyName")}
                    placeholder="e.g. My company name"
                  />
                </FormRow>
              </div>
            )}
          </div>
          <Controller
            control={control}
            name="contractStartDate"
            render={({ field, fieldState }) => (
              <FormRow
                error={fieldState.error?.message}
                id="contract-start-date"
                label="Contract start date"
              >
                <DatePicker
                  className="w-full"
                  onChange={field.onChange}
                  placeholder={`E.g. ${intl.formatDate(new Date(), {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  })}`}
                  prefixDate="Started - "
                  value={field.value}
                />
              </FormRow>
            )}
          />
          <div>
            <div className="flex flex-col gap-6 sm:flex-row">
              <FormRow
                className="w-full"
                error={errors.jobTitle?.message}
                id="jobTitle"
                label="Job title"
                warning={
                  selectedHRISProviderEmployee?.hRISProvider &&
                  !selectedHRISProviderEmployee.jobTitle && (
                    <MissingInformationFromHRISProviderTag
                      hRISProvider={selectedHRISProviderEmployee.hRISProvider}
                    />
                  )
                }
              >
                <Input
                  {...control.register("jobTitle")}
                  placeholder="E.g. Developer"
                />
              </FormRow>
              {showEquityGridFeature && (
                <Controller
                  control={control}
                  name="equityGridLevelId"
                  render={({ field, fieldState }) => {
                    return (
                      <FormRow
                        className="w-full"
                        error={fieldState.error?.message}
                        id="equityGridLevel"
                        label="Equity grid level"
                      >
                        <SelectAutocomplete
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          noOptionsMessage={() =>
                            isEmpty(equityGridLevels)
                              ? "Your equity grid is empty"
                              : "No matching level"
                          }
                          onChange={(newValue) => {
                            field.onChange(newValue?.id);
                          }}
                          options={equityGridLevels}
                          placeholder={`E.g. ${
                            equityGridLevels[0]?.name || "IC1"
                          }`}
                          usePortal
                          value={
                            equityGridLevels.find(
                              ({ id }) => id === field.value,
                            ) ?? null
                          }
                        />
                      </FormRow>
                    );
                  }}
                />
              )}
            </div>
            <NoticeMessage className="mt-2" size="Small">
              A job title will give more insights on the grantee to your board
              members.
            </NoticeMessage>
          </div>
        </div>
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <div className="flex gap-4">
              <Button
                onClick={onCancel}
                size="small"
                type="button"
                variant="Secondary Full"
              >
                Cancel
              </Button>
              {defaultGrantee?.isDeletable && (
                <Button
                  onClick={() => {
                    showGranteeDeletionConfirmationModal(defaultGrantee.id);
                  }}
                  size="small"
                  type="button"
                  variant="Danger Outline"
                >
                  Delete
                </Button>
              )}
              {defaultGrantee?.isTerminable && (
                <LinkButton
                  size="small"
                  to={generatePath(
                    APPLICATION_ROUTES.organizationTerminateGrantee,
                    {
                      granteeId: defaultGrantee.id,
                      organizationId: organization.id,
                    },
                  )}
                  variant="Danger Outline"
                >
                  Terminate grantee
                </LinkButton>
              )}
            </div>
            <Button loading={submissionInProgress} size="small" type="submit">
              {creatingGrantee ? "Create grantee" : "Update"}
            </Button>
          </div>
          {creatingGrantee ? (
            <Typography
              as="div"
              className="text-right text-gray-09"
              variant="Regular/Caption"
            >
              {`We won't be sending any emails to the grantee until you decide to do
            so once the grant is final.`}
            </Typography>
          ) : null}
        </div>
      </form>
    </>
  );
};

const FIND_GRANTEE_BY_EMAIL_QUERY = graphql`
  query GranteeForm_FindGranteeByEmail_Query(
    $organizationId: OrganizationId!
    $email: String!
  ) {
    granteeByOrganizationAndEmail(
      organizationId: $organizationId
      email: $email
    ) {
      id
    }
  }
`;

const GRANTEE_FRAGMENT = graphql`
  fragment GranteeForm_Grantee on Grantee {
    id
    name
    email
    companyId
    equityGridLevel {
      id
    }
    employerOfRecord {
      id
    }
    offGrid
    taxResidenceCountryCode
    USStateOfResidence {
      code
    }
    isUSCitizen
    workRelationship
    contractStartDate
    managementCompanyName
    jobTitle
    isDeletable
    isTerminable
  }
`;

export const useGranteeForm = ({
  defaultValues,
  granteeFragment = null,
  organizationId,
}: {
  defaultValues?: DefaultValues<z.input<typeof GranteeFormSchema>> | null;
  granteeFragment?: GranteeForm_Grantee$key | null;
  organizationId: string;
}) => {
  const [findGranteeByEmail] =
    useManualQuery<GranteeForm_FindGranteeByEmail_Query>(
      FIND_GRANTEE_BY_EMAIL_QUERY,
    );
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      companyId: grantee?.companyId,
      contractStartDate:
        grantee?.contractStartDate ||
        formatISO(new Date(), { representation: "date" }),
      countryCode: grantee?.taxResidenceCountryCode,
      email: grantee?.email,
      employerOfRecordId: grantee?.employerOfRecord?.id,
      equityGridLevelId: grantee?.offGrid
        ? OFF_GRID_LEVEL_ID
        : grantee?.equityGridLevel?.id,
      isUSCitizen: grantee?.isUSCitizen,
      jobTitle: grantee?.jobTitle,
      managementCompanyName: grantee?.managementCompanyName,
      name: grantee?.name,
      USStateOfResidenceCode: grantee?.USStateOfResidence?.code,
      workRelationship: grantee?.workRelationship,
      ...defaultValues,
    },
    resolver: async (data, context, options) => {
      const { errors, values } = await zodResolver(GranteeFormSchema)(
        data,
        context,
        options,
      );
      if (!isEmpty(errors)) {
        return { errors, values };
      }

      if (data.email && grantee?.email !== data.email) {
        const newEmail = data.email;
        const findGranteeByEmailResponse = await new Promise<
          GranteeForm_FindGranteeByEmail_Query["response"]
        >((resolve, reject) =>
          findGranteeByEmail({
            // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
            onError: (error) => {
              reject(error);
            },
            onResponse: (response) => {
              resolve(response);
            },
            variables: {
              email: newEmail,
              organizationId,
            },
          }),
        );

        const granteeWithSameEmailId =
          findGranteeByEmailResponse.granteeByOrganizationAndEmail?.id;

        if (granteeWithSameEmailId && granteeWithSameEmailId !== grantee?.id) {
          return {
            errors: {
              email: {
                message:
                  "A grantee with the same email address already exists in this organization",
                type: "validation",
              },
            },
            values,
          };
        }
      }

      return { errors, values };
    },
  });

  return { control, handleSubmit, isSubmitting, reset, setValue, watch };
};
