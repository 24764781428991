import { Toggle } from "@remote-com/norma";
import { noop } from "lodash";
import { AnimatePresence, motion } from "motion/react";
import { Suspense, useCallback, useMemo } from "react";
import { graphql, useFragment, useMutation } from "react-relay";

import {
  BentoCard,
  BentoCardCell,
  BentoCardRow,
  BentoCardValue,
} from "../../../components/BentoCard";
import { CurrencySymbol } from "../../../components/CurrencySymbol";
import { CurrencyUnit } from "../../../components/CurrencyUnit";
import { FormattedCurrency } from "../../../components/Formatted/FormattedCurrency";
import { FormattedNumber } from "../../../components/Formatted/FormattedNumber";
import { SkeletonWrapper } from "../../../components/ui/SkeletonWrapper";
import {
  EmployeePortalTopSection_Deferred_Organization$data,
  EmployeePortalTopSection_Deferred_Organization$key,
} from "./__generated__/EmployeePortalTopSection_Deferred_Organization.graphql";
import {
  EmployeePortalTopSection_Organization$data,
  EmployeePortalTopSection_Organization$key,
} from "./__generated__/EmployeePortalTopSection_Organization.graphql";
import { EmployeePortalTopSection_UpdateGranteePortalSettingsMutation } from "./__generated__/EmployeePortalTopSection_UpdateGranteePortalSettingsMutation.graphql";
import { ColoredBox } from "./ColoredBox";

const UPDATE_EMPLOYEE_PORTAL_SETTINGS_MUTATION = graphql`
  mutation EmployeePortalTopSection_UpdateGranteePortalSettingsMutation(
    $attributes: UpdateGranteePortalSettingsAttributes!
    $organizationId: OrganizationId!
  ) @raw_response_type {
    updateGranteePortalSettings(
      attributes: $attributes
      organizationId: $organizationId
    ) {
      granteePortalSettings {
        displayFullyDilutedValues
      }
    }
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment EmployeePortalTopSection_Organization on Organization {
    ...EmployeePortalTopSection_Deferred_Organization @defer
    ...FormattedCurrency_Organization
    ...CurrencySymbol_Organization
    ...CurrencyUnit_Organization
  }
`;

const DEFERRED_ORGANIZATION_FRAGMENT = graphql`
  fragment EmployeePortalTopSection_Deferred_Organization on Organization {
    id
    granteePortalSettings {
      displayFullyDilutedValues
      showProjectionScenarios
    }
    employees: grantees {
      edges {
        node {
          hasPortalAccess
          cannotBeInvitedReason
          lastSeenAt
        }
      }
    }
    ...FormattedCurrency_Organization
    ...CurrencySymbol_Organization
    ...CurrencyUnit_Organization
  }
`;

export function DeferredEmployeePortalTopSection({
  organizationFragment,
}: {
  organizationFragment: EmployeePortalTopSection_Deferred_Organization$key;
}) {
  const organization = useFragment(
    DEFERRED_ORGANIZATION_FRAGMENT,
    organizationFragment,
  );
  const [
    commitUpdateGranteePortalSettingsMutation,
    updateGranteePortalSettingsMutationIsInFlight,
  ] = useMutation<EmployeePortalTopSection_UpdateGranteePortalSettingsMutation>(
    UPDATE_EMPLOYEE_PORTAL_SETTINGS_MUTATION,
  );

  const handleToggleFullyDilutedValues = useCallback(() => {
    commitUpdateGranteePortalSettingsMutation({
      optimisticResponse: {
        updateGranteePortalSettings: {
          granteePortalSettings: {
            displayFullyDilutedValues:
              !organization.granteePortalSettings.displayFullyDilutedValues,
          },
          id: organization.id,
        },
      },
      variables: {
        attributes: {
          displayFullyDilutedValues:
            !organization.granteePortalSettings.displayFullyDilutedValues,
          showProjectionScenarios:
            organization.granteePortalSettings.showProjectionScenarios,
        },
        organizationId: organization.id,
      },
    });
  }, [
    commitUpdateGranteePortalSettingsMutation,
    organization.granteePortalSettings.displayFullyDilutedValues,
    organization.granteePortalSettings.showProjectionScenarios,
    organization.id,
  ]);

  const invitedEmployees = useMemo(() => {
    return organization.employees.edges.filter(
      ({ node }) => node.hasPortalAccess,
    );
  }, [organization.employees.edges]);

  const invitableEmployees = useMemo(() => {
    return organization.employees.edges.filter(
      ({ node }) => !node.cannotBeInvitedReason,
    );
  }, [organization.employees.edges]);

  const activeEmployees = useMemo(() => {
    return organization.employees.edges.filter(
      ({ node }) => node.hasPortalAccess && node.lastSeenAt,
    );
  }, [organization.employees.edges]);

  return (
    <EmployeePortalTopSectionUI
      activeEmployeesCount={activeEmployees.length}
      displayFullyDilutedValues={
        organization.granteePortalSettings.displayFullyDilutedValues
      }
      invitableEmployeesCount={invitableEmployees.length}
      invitedEmployeesCount={invitedEmployees.length}
      loading={updateGranteePortalSettingsMutationIsInFlight}
      onToggleFullyDilutedValues={handleToggleFullyDilutedValues}
      organization={organization}
    />
  );
}

export function EmployeePortalTopSection({
  organizationFragment,
}: {
  organizationFragment: EmployeePortalTopSection_Organization$key;
}) {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <Suspense
      fallback={
        <EmployeePortalTopSectionUI
          activeEmployeesCount={9_999}
          displayFullyDilutedValues={false}
          invitableEmployeesCount={9_999}
          invitedEmployeesCount={9_999}
          organization={organization}
          skeleton
        />
      }
    >
      <DeferredEmployeePortalTopSection organizationFragment={organization} />
    </Suspense>
  );
}

function EmployeePortalTopSectionUI({
  activeEmployeesCount,
  displayFullyDilutedValues,
  invitableEmployeesCount,
  invitedEmployeesCount,
  loading,
  onToggleFullyDilutedValues = noop,
  organization,
  skeleton,
}: {
  activeEmployeesCount: number;
  displayFullyDilutedValues: boolean;
  invitableEmployeesCount: number;
  invitedEmployeesCount: number;
  loading?: boolean;
  onToggleFullyDilutedValues?: () => void;
  organization:
    | EmployeePortalTopSection_Deferred_Organization$data
    | EmployeePortalTopSection_Organization$data;
  skeleton?: boolean;
}) {
  return (
    <BentoCard>
      <BentoCardRow>
        <BentoCardCell title="Employees with access to their portal">
          <BentoCardValue
            main={
              <FormattedNumber
                animated
                skeleton={skeleton}
                value={invitedEmployeesCount}
              />
            }
            sub={
              <>
                /
                <FormattedNumber
                  animated
                  skeleton={skeleton}
                  value={invitableEmployeesCount + invitedEmployeesCount}
                />
              </>
            }
          />
        </BentoCardCell>
        <BentoCardCell title="Employees who viewed their portal">
          <div className="flex items-center gap-1">
            <div className="font-brand text-XL/Medium">
              <FormattedNumber
                animated
                skeleton={skeleton}
                value={activeEmployeesCount}
              />
            </div>
            <div className="text-SM text-grey-600">
              /
              <FormattedNumber
                animated
                skeleton={skeleton}
                value={invitedEmployeesCount}
              />
            </div>
          </div>
        </BentoCardCell>
      </BentoCardRow>
      <BentoCardRow>
        <BentoCardCell>
          <div className="flex flex-auto items-center gap-4">
            <SkeletonWrapper reveal={!skeleton}>
              <Toggle
                checked={displayFullyDilutedValues}
                disabled={loading}
                label="Make value and company valuation visible to grantees"
                onClick={onToggleFullyDilutedValues}
                size="sm"
              />
            </SkeletonWrapper>
            <div className="space-y-2">
              <div className="text-XS/SemiBold uppercase text-grey-600">
                <CurrencySymbol organizationFragment={organization} /> value and
                company valuation
              </div>
              <div className="font-brand text-XL/Medium">Visible</div>
            </div>
          </div>
          <div className="basis-[632px] space-y-4 rounded-remote-xl bg-grey-50 p-4">
            <SkeletonWrapper reveal={!skeleton}>
              <motion.div className="flex flex-wrap gap-4" layout>
                <AnimatePresence mode="popLayout">
                  <ColoredBox
                    className="flex-1"
                    color="blueLight"
                    key="compensation"
                    title="Compensation"
                    unit="grants"
                    value={<FormattedNumber value={1} />}
                  />
                  <ColoredBox
                    className="flex-1"
                    color="green"
                    key="ownership"
                    title="Ownership"
                    unit="shares"
                    value={<FormattedNumber value={10_000} />}
                  />
                  {displayFullyDilutedValues && (
                    <ColoredBox
                      className="flex-1"
                      color="purple"
                      key="net-equity-value"
                      title="Net Equity Value"
                      unit={
                        <CurrencyUnit organizationFragment={organization} />
                      }
                      value={
                        <FormattedCurrency
                          minimumFractionDigits={0}
                          organizationFragment={organization}
                          value={50_000}
                        />
                      }
                    />
                  )}
                </AnimatePresence>
              </motion.div>
            </SkeletonWrapper>
            <div className="text-center text-2XS text-grey-600">
              Preview from the “Ownership” part of the employee portal
            </div>
          </div>
        </BentoCardCell>
      </BentoCardRow>
    </BentoCard>
  );
}
