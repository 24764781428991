/**
 * @generated SignedSource<<8aa1049ac6cc65c6933bea5733959128>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoteEquityOrganizationSettingsBilling_Organization$data = {
  readonly billableGrantees: ReadonlyArray<{
    readonly __typename: "Grantee";
  }>;
  readonly billableOrganizationGeographies: ReadonlyArray<{
    readonly __typename: "OrganizationGeography";
  }>;
  readonly id: string;
  readonly name: string;
  readonly remoteEquityPlanCountryThreshold: number | null;
  readonly remoteEquityPlanDiscountPercentage: number | null;
  readonly remoteEquityPlanEmployeeThreshold: number | null;
  readonly remoteEquityPlanPrice: number | null;
  readonly remoteEquityPlanRenewalDate: string | null;
  readonly remoteEquityPlanThresholdsExceeded: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"FormattedCurrency_Organization" | "RemoteEquityPlanCountryThresholdProgressBar_Organization" | "RemoteEquityPlanEmployeeThresholdProgressBar_Organization">;
  readonly " $fragmentType": "RemoteEquityOrganizationSettingsBilling_Organization";
};
export type RemoteEquityOrganizationSettingsBilling_Organization$key = {
  readonly " $data"?: RemoteEquityOrganizationSettingsBilling_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteEquityOrganizationSettingsBilling_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteEquityOrganizationSettingsBilling_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remoteEquityPlanPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remoteEquityPlanRenewalDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remoteEquityPlanCountryThreshold",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remoteEquityPlanEmployeeThreshold",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remoteEquityPlanThresholdsExceeded",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remoteEquityPlanDiscountPercentage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "billableGrantees",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationGeography",
      "kind": "LinkedField",
      "name": "billableOrganizationGeographies",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemoteEquityPlanEmployeeThresholdProgressBar_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemoteEquityPlanCountryThresholdProgressBar_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedCurrency_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "8a5675db68fed4f96072691f5f3da40f";

export default node;
