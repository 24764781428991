import classNames from "classnames";
import React from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { ProjectionCard } from "../../../components/ProjectionCard";
import { Divider } from "../../../components/ui/Divider";
import { NoticeMessage } from "../../../components/ui/NoticeMessage";
import { Scenario } from "../../../components/ui/Scenario";
import { Typography } from "../../../components/ui/Typography";
import { useTailwindActiveBreakpoints } from "../../../hooks/useTailwindActiveBreakpoints";
import { Projection_EquityOffer$key } from "./__generated__/Projection_EquityOffer.graphql";

const EQUITY_OFFER_FRAGMENT = graphql`
  fragment Projection_EquityOffer on IEquityOffer {
    shares
    organizationLatestPricePerShare {
      value
    }
    instrument {
      isVirtual
    }
    projectionScenarios {
      ...ProjectionCard_GranteePortalProjectionScenarios
    }
    organization {
      ...ProjectionCard_Organization
    }
  }
`;

export const EquityOfferProjection: React.FC<{
  equityOfferFragment: Projection_EquityOffer$key;
  forceProjectionChartView?: boolean;
  forceTabletView?: boolean;
}> = ({
  equityOfferFragment,
  forceProjectionChartView = false,
  forceTabletView,
}) => {
  const equityOffer = useFragment<Projection_EquityOffer$key>(
    EQUITY_OFFER_FRAGMENT,
    equityOfferFragment,
  );

  const { isMdOrSmaller, isXs } = useTailwindActiveBreakpoints();

  if (!equityOffer.organizationLatestPricePerShare) return null;

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <Typography as="div" variant="Medium/Large">
          {equityOffer.instrument?.isVirtual
            ? "What your SAR could be worth in the future"
            : "What your equity could be worth in the future"}
        </Typography>
        <Typography as="div" variant="Regular/Small">
          {equityOffer.instrument?.isVirtual
            ? "As your SAR correspond to Common Stock of the company, if the company is worth more, so will your SAR!"
            : "As your options correspond to a slice of the company, if the company is worth more, so will your slice!"}
        </Typography>
      </div>

      {equityOffer.shares ? (
        <ProjectionCard
          chartViewOnly={forceProjectionChartView || isXs}
          latestPricePerShareValue={
            equityOffer.organizationLatestPricePerShare.value
          }
          organizationFragment={equityOffer.organization}
          projectionScenariosFragment={equityOffer.projectionScenarios}
          shares={equityOffer.shares}
          valueAtGrant={null}
        />
      ) : (
        <NoticeMessage size="Large" variant="Idea">
          You need to enter the number of shares to see the projection graph
        </NoticeMessage>
      )}

      {!equityOffer.instrument?.isVirtual && (
        <>
          <Divider />

          <div className="flex flex-col gap-2">
            <Typography as="div" variant="Medium/Large">
              How to convert these options into cash?
            </Typography>
            <Typography as="div" variant="Regular/Small">
              You will often be told about a number of shares, but what does it
              mean, how do you get it, what is the outcome?
            </Typography>
          </div>

          <div
            className={classNames(
              "flex",
              isMdOrSmaller || forceTabletView ? "flex-col gap-4" : "gap-10",
            )}
          >
            <Scenario
              className="flex-1"
              frequency="Most frequent"
              title="Acquisition (exit)"
            >
              If your company is sold to another company, meaning the other
              company will buy all the shares from all shareholders, including
              you
            </Scenario>
            <Scenario
              className="flex-1"
              frequency="Second most frequent"
              title="Secondary sale"
            >
              If some other companies or people are interested in buying a share
              of your company and your company has authorized you to sell your
              shares
            </Scenario>
            <Scenario className="flex-1" frequency="Less frequent" title="IPO">
              If your company goes public, meaning that people can publicly buy
              and sell its shares on a stock market (e.g. NYSE, Nasdaq)
            </Scenario>
          </div>
        </>
      )}
    </div>
  );
};
