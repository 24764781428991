/**
 * @generated SignedSource<<776182537a815f6497e042acdbbcc93d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmployeeDetails_Viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EmployeeDetailsTopBarActions_Viewer" | "OwnershipSection_Viewer">;
  readonly " $fragmentType": "EmployeeDetails_Viewer";
};
export type EmployeeDetails_Viewer$key = {
  readonly " $data"?: EmployeeDetails_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeeDetails_Viewer">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmployeeDetails_Viewer",
  "selections": [
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "EmployeeDetailsTopBarActions_Viewer"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "OwnershipSection_Viewer"
    }
  ],
  "type": "Account",
  "abstractKey": null
};
})();

(node as any).hash = "cea23306fbc9f20bb41d02e732a7711b";

export default node;
