/**
 * @generated SignedSource<<1968ee158e94231fa0cf4036d7be348b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EssentialsOverview_Admin_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AsideSection_EssentialsOverview_Organization" | "Footer_Organization" | "Header_EssentialsOverview_Organization" | "MainSection_EssentialsOverview_Organization">;
  readonly " $fragmentType": "EssentialsOverview_Admin_Organization";
};
export type EssentialsOverview_Admin_Organization$key = {
  readonly " $data"?: EssentialsOverview_Admin_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"EssentialsOverview_Admin_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EssentialsOverview_Admin_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Header_EssentialsOverview_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MainSection_EssentialsOverview_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AsideSection_EssentialsOverview_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Footer_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "5f2390d3700907e0a34d894fa24c80ed";

export default node;
