import React from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { LabelValue } from "../../../components/ui/LabelValue";
import { NoticeMessage } from "../../../components/ui/NoticeMessage";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { Typography } from "../../../components/ui/Typography";
import { VestingScheduleGraph } from "../../../components/VestingScheduleGraph";
import { Vesting_EquityOffer$key } from "./__generated__/Vesting_EquityOffer.graphql";

const EQUITY_OFFER_FRAGMENT = graphql`
  fragment Vesting_EquityOffer on IEquityOffer {
    shares
    vestingSchedule {
      id
      name
    }
    postTerminationExercisePeriod {
      displayName
    }
    contractStartDate
    organization {
      name
      ...VestingScheduleGraph_Organization
    }
    instrument {
      isVirtual
    }
  }
`;

export const EquityOfferVesting: React.FC<{
  equityOfferFragment: Vesting_EquityOffer$key;
  hideNoticeMessages?: boolean;
}> = ({ equityOfferFragment, hideNoticeMessages = false }) => {
  const equityOffer = useFragment<Vesting_EquityOffer$key>(
    EQUITY_OFFER_FRAGMENT,
    equityOfferFragment,
  );

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <Typography as="div" variant="Medium/Large">
          {equityOffer.instrument?.isVirtual
            ? "Vesting and Post-termination settlement period"
            : "Vesting and exercising your stock options"}
        </Typography>
        <Typography as="div" variant="Medium/Default">
          Here is how your commitment in the company is rewarded
        </Typography>
      </div>
      {equityOffer.vestingSchedule ? (
        <>
          <RoundedBox className="space-y-4 p-4" withBorder withShadow>
            <LabelValue label="Vesting">
              {equityOffer.vestingSchedule.name}
            </LabelValue>

            {!hideNoticeMessages && (
              <NoticeMessage size="Large" variant="Idea">
                {equityOffer.instrument?.isVirtual ? (
                  <>
                    Also called vesting period, your vesting schedule determine
                    the proportion of your SAR that you&apos;re entitled to in
                    case a liquidity event takes place. You get it, the idea is
                    to make you committed for the long run! You&apos;ll
                    progressively vest (unlock) your SAR, as long as you stay
                    with the company, until you can unlock 100% of them. A
                    common type of vesting schedules for startups is 4 years,
                    with a 1 year cliff and a monthly vesting → 25% of the SAR
                    vest at the end of the 1st year, and the remaining SARs vest
                    on a monthly basis throughout the next 3 years.
                  </>
                ) : (
                  <>
                    Also called vesting period, the vesting schedule determines
                    when you may exercise your options. You get it, the idea is
                    to make you committed for the long run! You&apos;ll
                    progressively vest (unlock) your options, as long as you
                    stay with the company, until you can unlock 100% of them. A
                    common type of vesting schedules for startups is 4 years,
                    with a 1 year cliff and a monthly vesting → 25% of the
                    options vest at the end of the 1st year, and the remaining
                    options vest on a monthly basis throughout the next 3 years.
                  </>
                )}
              </NoticeMessage>
            )}
          </RoundedBox>
          <RoundedBox className="space-y-4 p-4" withBorder withShadow>
            <Typography variant="Medium/Default">Vesting schedule</Typography>
            <VestingScheduleGraph
              dateDisplayMode={
                equityOffer.contractStartDate ? "absolute" : "relative"
              }
              organizationFragment={equityOffer.organization}
              quantityGranted={equityOffer.shares ?? 10_000}
              vestingScheduleId={equityOffer.vestingSchedule.id}
              vestingStartDate={
                equityOffer.contractStartDate
                  ? new Date(equityOffer.contractStartDate)
                  : new Date()
              }
            />
          </RoundedBox>
        </>
      ) : null}
      {equityOffer.postTerminationExercisePeriod ? (
        <>
          <Typography as="div" variant="Medium/Large">
            {equityOffer.instrument?.isVirtual
              ? "Post-termination settlement period"
              : "Post-termination exercise period"}
          </Typography>
          <RoundedBox className="space-y-4 p-4" withBorder withShadow>
            <LabelValue
              label={
                equityOffer.instrument?.isVirtual
                  ? "Post-termination settlement period"
                  : "Post-termination exercise period"
              }
            >
              {equityOffer.postTerminationExercisePeriod.displayName}
            </LabelValue>

            {!hideNoticeMessages && (
              <NoticeMessage size="Large" variant="Idea">
                {equityOffer.instrument?.isVirtual ? (
                  <>
                    The “post-termination settlement period” (PTSP) is the time
                    window following the time at which you leave during which,
                    if a liquidity event takes place, you will receive the
                    vested part.
                  </>
                ) : (
                  <>
                    Post Termination Exercise Period is the period during which
                    you can exercise your options after you&apos;ve stopped
                    working for &nbsp;{equityOffer.organization.name}
                  </>
                )}
              </NoticeMessage>
            )}
          </RoundedBox>
        </>
      ) : null}
    </div>
  );
};
