import { Portal } from "@headlessui/react";
import { Text } from "@remote-com/norma";
import classNames from "classnames";
import React, { useMemo, useState } from "react";
import { usePopper } from "react-popper";

import { useBoolean } from "../../hooks/useBoolean";
import { Typography } from "./Typography";

interface Props extends React.ComponentProps<"div"> {
  children: React.ReactElement;
  defaultStyle?: boolean;
  disabled?: boolean;
  position?: "bottom" | "left" | "right" | "top";
  tooltipClassName?: React.ComponentProps<"div">["className"];
  tooltipContent?: React.ReactNode;
}

export const TooltipVerticallyLabeledValue: React.FC<{
  children: React.ReactNode;
  className?: string;
  label: string;
}> = ({ children, className, label }) => {
  return (
    <div className={classNames("flex flex-col", className)}>
      <Typography className="text-gray-09" variant="Medium/Caption">
        {label}
      </Typography>
      <Typography variant="Regular/Caption">{children}</Typography>
    </div>
  );
};

export const TooltipContainer = ({
  children: _children,
  defaultStyle = true,
  disabled = false,
  position = "top",
  tooltipClassName,
  tooltipContent,
  ...props
}: Props) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null,
  );

  const children = useMemo(() => {
    return React.Children.map(_children, (child) => {
      return React.cloneElement(child, {
        ref: (element: HTMLElement) => {
          setReferenceElement(element);
        },
      });
    });
  }, [_children]);

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const {
    setFalse: setContainerIsNotHovered,
    setTrue: setContainerIsHovered,
    value: containerIsHovered,
  } = useBoolean(false);
  const popper = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
    placement: position,
  });

  return (
    <div
      {...props}
      onMouseEnter={setContainerIsHovered}
      onMouseLeave={setContainerIsNotHovered}
    >
      {children}
      {!disabled && tooltipContent && containerIsHovered && (
        <Portal>
          <div
            className={classNames(
              "absolute z-20 inline-block",
              {
                ["max-w-[300px] rounded border border-grey-200 bg-white px-3 py-2"]:
                  defaultStyle,
              },
              tooltipClassName,
            )}
            ref={setPopperElement}
            style={popper.styles.popper}
            {...popper.attributes.popper}
          >
            <Text as="div" className="relative text-grey-800" variant="sm">
              {tooltipContent}
            </Text>
          </div>
        </Portal>
      )}
    </div>
  );
};
