/**
 * @generated SignedSource<<1b268a33cb135c72dfac3694d24e84ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AwardSuperType = "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type Incentive_EquityOffer$data = {
  readonly benefits: ReadonlyArray<string>;
  readonly displayBenefits: boolean;
  readonly displayEquityAsDollarValue: boolean;
  readonly displayEquityAsPercentage: boolean;
  readonly displaySalary: boolean;
  readonly dollarValue: number | null;
  readonly instrument: {
    readonly awardSuperType: AwardSuperType;
    readonly isVirtual: boolean;
    readonly workRelationship: WorkRelationship;
  } | null;
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"FormattedCurrency_Organization">;
  };
  readonly ownershipPercentage: number | null;
  readonly salary: number | null;
  readonly salaryCurrencyCode: string;
  readonly shares: number | null;
  readonly " $fragmentType": "Incentive_EquityOffer";
};
export type Incentive_EquityOffer$key = {
  readonly " $data"?: Incentive_EquityOffer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Incentive_EquityOffer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Incentive_EquityOffer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dollarValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ownershipPercentage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayEquityAsDollarValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayEquityAsPercentage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayBenefits",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "benefits",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displaySalary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salaryCurrencyCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "instrument",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workRelationship",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "awardSuperType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isVirtual",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FormattedCurrency_Organization"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "IEquityOffer",
  "abstractKey": "__isIEquityOffer"
};

(node as any).hash = "511b74f65d0b4b2ab6678576c351ed38";

export default node;
