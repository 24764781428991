import { Pill, Tooltip } from "@remote-com/norma";
import React from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { PortalAccessTag_Grantee$key } from "./__generated__/PortalAccessTag_Grantee.graphql";

const GRANTEE_FRAGMENT = graphql`
  fragment PortalAccessTag_Grantee on Grantee {
    cannotBeInvitedReason
    hasPortalAccess
    hasVisitedTheirPortal
  }
`;

const _NotInvitedTag = (_: unknown, ref: React.Ref<HTMLDivElement>) => {
  return (
    <Pill ref={ref} tone="neutralLight">
      Not invited
    </Pill>
  );
};
const NotInvitedTag = React.forwardRef(_NotInvitedTag);
const NotInvitedTagWithTooltip: React.FC = () => {
  return (
    <Tooltip label="Employee hasn’t been invited to their portal yet.">
      <NotInvitedTag />
    </Tooltip>
  );
};

const _InvitedAndVisitedTag = (_: unknown, ref: React.Ref<HTMLDivElement>) => {
  return (
    <Pill ref={ref} tone="success">
      Invited
    </Pill>
  );
};
const InvitedAndVisitedTag = React.forwardRef(_InvitedAndVisitedTag);
const InvitedAndVisitedTagWithTooltip: React.FC = () => {
  return (
    <Tooltip label="Employee has been invited">
      <InvitedAndVisitedTag />
    </Tooltip>
  );
};

const _InvitedButNotVisitedTag = (
  _: unknown,
  ref: React.Ref<HTMLDivElement>,
) => {
  return (
    <Pill ref={ref} tone="warning">
      Pending
    </Pill>
  );
};
const InvitedButNotVisitedTag = React.forwardRef(_InvitedButNotVisitedTag);
const InvitedButNotVisitedTagWithTooltip: React.FC = () => {
  return (
    <Tooltip label="Employee has been invited but has not visited their portal yet.">
      <InvitedButNotVisitedTag />
    </Tooltip>
  );
};

const NotInvitableTagWithTooltip: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <Tooltip label={children}>
      <NotInvitedTag />
    </Tooltip>
  );
};

const PortalAccessTagWithToolTip: React.FC<{
  granteeFragment: PortalAccessTag_Grantee$key;
}> = ({ granteeFragment }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  if (grantee.hasPortalAccess) {
    if (grantee.hasVisitedTheirPortal) {
      return <InvitedAndVisitedTagWithTooltip />;
    }
    return <InvitedButNotVisitedTagWithTooltip />;
  }

  if (grantee.cannotBeInvitedReason) {
    switch (grantee.cannotBeInvitedReason) {
      case "ALREADY_INVITED":
        return (
          <NotInvitableTagWithTooltip>
            This employee has already been invited.
          </NotInvitableTagWithTooltip>
        );
      case "MISSING_INFORMATION":
        return (
          <NotInvitableTagWithTooltip>
            Missing mandatory information prevents the creation of the portal.
          </NotInvitableTagWithTooltip>
        );
      case "NO_GRANTS":
        return (
          <NotInvitableTagWithTooltip>
            Employees without equity cannot be invited.
          </NotInvitableTagWithTooltip>
        );
      case "REMOTE_EMPLOYEE":
        return (
          <NotInvitableTagWithTooltip>
            Remote employees can&apos;t be invited to their portal.
          </NotInvitableTagWithTooltip>
        );
      case "SETTLED":
        return (
          <NotInvitableTagWithTooltip>
            Terminated and settled employees can no longer access their portal.
          </NotInvitableTagWithTooltip>
        );
    }
  }
  return <NotInvitedTagWithTooltip />;
};

export const PortalAccessTag = {
  InvitedAndVisited: InvitedAndVisitedTag,
  InvitedButNotVisited: InvitedButNotVisitedTag,
  NotInvited: NotInvitedTag,
  WithToolTip: PortalAccessTagWithToolTip,
};
