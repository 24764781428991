import { zodResolver } from "@hookform/resolvers/zod";
import { Button, StandaloneLink } from "@remote-com/norma";
import { IconV2OutlineArrowUpRight } from "@remote-com/norma/icons/IconV2OutlineArrowUpRight";
import { isPast } from "date-fns";
import { ArrowRight } from "iconsax-react";
import { useCallback, useId } from "react";
import { DeepPartial, useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { FairMarketValueExplanationDrawer } from "../../../../components/FairMarketValueExplanationDrawer";
import { RemoteInputCurrency } from "../../../../components/ui/Form/Inputs/RemoteInputCurrency";
import { RemoteInputDatePicker } from "../../../../components/ui/Form/Inputs/RemoteInputDatePicker";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import {
  EssentialsOnboardingTemplate,
  EssentialsOnboardingTemplateFooter,
} from "../EssentialsOnboardingTemplate";
import { IssuingCompanyCard } from "../IssuingCompanyCard";
import { FairMarketValuePage_Organization$key } from "./__generated__/FairMarketValuePage_Organization.graphql";
import { FairMarketValuePage_SetEssentialsFairMarketValueOnboardingStepAsCompleted_Mutation } from "./__generated__/FairMarketValuePage_SetEssentialsFairMarketValueOnboardingStepAsCompleted_Mutation.graphql";
import illustration from "./illustration.png";

const ORGANIZATION_FRAGMENT = graphql`
  fragment FairMarketValuePage_Organization on Organization {
    id
    latestFairMarketValue {
      value
      date
    }
    issuingCompany {
      city
      country {
        name
        emoji
      }
      name
    }
    ...IssuingCompanyCard_Organization
    ...RemoteInputCurrency_Organization
  }
`;

const SET_ESSENTIALS_FAR_MARKET_VALUE_ONBOARDING_STEP_AS_COMPLETED_MUTATION = graphql`
  mutation FairMarketValuePage_SetEssentialsFairMarketValueOnboardingStepAsCompleted_Mutation(
    $fairMarketValue: Float
    $organizationId: OrganizationId!
    $valuationDate: Date
  ) {
    setEssentialsFairMarketValueOnboardingStepAsCompleted(
      fairMarketValue: $fairMarketValue
      organizationId: $organizationId
      valuationDate: $valuationDate
    ) {
      currentRemoteOnboardingStep
      latestFairMarketValue {
        value
      }
    }
  }
`;

const FORM_SCHEMA = z.object({
  fairMarketValue: z.coerce
    .number({
      invalid_type_error: "This is required",
    })
    .positive(),
  validityDate: z.coerce
    .string()
    .date()
    .refine(
      (date) => {
        return isPast(new Date(date));
      },
      {
        message: "This date cannot be in the future",
      },
    ),
});

type FormInput = DeepPartial<z.input<typeof FORM_SCHEMA>>;
type FormOutput = z.output<typeof FORM_SCHEMA>;

export default function FairMarketValuePage({
  onNextStep,
  onPreviousStep,
  organizationFragment,
  previousStepIndex,
}: {
  onNextStep: () => void;
  onPreviousStep: () => void;
  organizationFragment: FairMarketValuePage_Organization$key;
  previousStepIndex?: number;
}) {
  const formId = useId();
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const form = useForm<FormInput, unknown, FormOutput>({
    defaultValues: {
      fairMarketValue: organization.latestFairMarketValue?.value,
      validityDate: organization.latestFairMarketValue?.date,
    },
    resolver: zodResolver(FORM_SCHEMA),
  });

  const [
    setEssentialsFairMarketValueOnboardingStepAsCompleted,
    mutationIsInFlight,
  ] =
    useSafeMutation<FairMarketValuePage_SetEssentialsFairMarketValueOnboardingStepAsCompleted_Mutation>(
      SET_ESSENTIALS_FAR_MARKET_VALUE_ONBOARDING_STEP_AS_COMPLETED_MUTATION,
    );

  const handleFormSubmit = form.handleSubmit(
    async ({ fairMarketValue, validityDate }) => {
      await setEssentialsFairMarketValueOnboardingStepAsCompleted({
        variables: {
          fairMarketValue,
          organizationId: organization.id,
          valuationDate: validityDate,
        },
      });

      onNextStep();
    },
  );

  const handleSkipButtonClicked = useCallback(() => {
    void setEssentialsFairMarketValueOnboardingStepAsCompleted({
      variables: {
        organizationId: organization.id,
      },
    }).then(() => {
      onNextStep();
    });
  }, [
    organization.id,
    onNextStep,
    setEssentialsFairMarketValueOnboardingStepAsCompleted,
  ]);

  const [fairMarketValue] = form.watch(["fairMarketValue"]);

  return (
    <EssentialsOnboardingTemplate
      aside={
        <IssuingCompanyCard
          companyCity={organization.issuingCompany?.city}
          companyCountry={organization.issuingCompany?.country?.name}
          companyCountryEmoji={organization.issuingCompany?.country?.emoji}
          companyName={organization.issuingCompany?.name}
          fairMarketValue={fairMarketValue}
          organizationFragment={organization}
        />
      }
      footer={
        <EssentialsOnboardingTemplateFooter
          onPreviousClick={onPreviousStep}
          previousStepIndex={previousStepIndex}
          stepIndex={3}
          stepName="Fair market value"
          submitButton={
            <Button
              disabled={mutationIsInFlight}
              form={formId}
              IconAfter={ArrowRight}
              isLoading={mutationIsInFlight}
              type="submit"
            >
              Continue
            </Button>
          }
        />
      }
      illustration={illustration}
      subtitle="This is needed for taxes, security, and compliance. Make sure these details match your company’s legal documents."
      title="What is the current fair market value of one share?"
    >
      <form className="space-y-14" id={formId} onSubmit={handleFormSubmit}>
        <div className="space-y-4">
          <RemoteInputCurrency.Form
            control={form.control}
            label="Fair market value of one share"
            name="fairMarketValue"
            organizationFragment={organization}
          />
          <RemoteInputDatePicker.Form
            control={form.control}
            label="Validity date"
            name="validityDate"
          />
          <div className="px-4">
            <FairMarketValueExplanationDrawer>
              <StandaloneLink IconAfter={IconV2OutlineArrowUpRight} size="sm">
                What is a fair market value and how is it determined?
              </StandaloneLink>
            </FairMarketValueExplanationDrawer>
          </div>
        </div>
        <Button
          IconAfter={ArrowRight}
          onClick={handleSkipButtonClicked}
          tone="secondary"
          type="button"
        >
          I’ll answer later
        </Button>
      </form>
    </EssentialsOnboardingTemplate>
  );
}
