import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Pill, Text } from "@remote-com/norma";
import classNames from "classnames";
import React, { useMemo } from "react";
import {
  Control,
  FieldPath,
  FieldValues,
  useController,
} from "react-hook-form";

import { TooltipContainer } from "../TooltipContainer";
import { Typography } from "../Typography";
import { Input } from "./Inputs/Input";

const Label: React.FC<React.ComponentProps<"label">> = ({
  children,
  className,
  ...props
}) => {
  return (
    <label className={className} {...props}>
      <Typography
        as="div"
        className="text-black-07"
        variant="Medium/Extra Small"
      >
        {children}
      </Typography>
    </label>
  );
};

const FormRow_: React.FC<{
  _forwardErrorToChildren?: boolean;
  children: React.ReactElement<React.ComponentProps<typeof Input>>;
  className?: string;
  context?: React.ReactNode;
  dataCy?: string;
  error?: React.ReactNode;
  help?: React.ReactNode;
  id?: string;
  label?: React.ReactNode;
  subLabel?: React.ReactNode;
  warning?: React.ReactNode;
}> = ({
  _forwardErrorToChildren = true,
  children,
  className,
  context,
  dataCy,
  error,
  help,
  id,
  label,
  subLabel,
  warning,
}) => {
  const childrenProps = useMemo(() => {
    if (!_forwardErrorToChildren) {
      return children.props;
    }

    return {
      invalid: error ? true : undefined,
      ...children.props,
    };
  }, [_forwardErrorToChildren, children.props, error]);

  return (
    <div
      className={classNames("w-full space-y-2", className)}
      data-cy={dataCy ?? label}
    >
      {label && (
        <div className="flex flex-1 items-center justify-between gap-2">
          <div className="flex gap-2">
            <Label className="block" htmlFor={id}>
              {label}
            </Label>
            {help && (
              <TooltipContainer
                tooltipContent={<div className="text-center">{help}</div>}
              >
                <InformationCircleIcon className="w-5 text-primary" />
              </TooltipContainer>
            )}
          </div>
          {warning}
        </div>
      )}

      {subLabel && (
        <Typography
          as="div"
          className="text-black-05"
          variant="Regular/Extra Small"
        >
          {subLabel}
        </Typography>
      )}

      {React.cloneElement(children, childrenProps)}

      {error && <Pill tone="error">{error}</Pill>}

      {context ? (
        <Text className="pt-2 text-grey-500" variant="xs">
          {context}
        </Text>
      ) : null}
    </div>
  );
};

const FormFormRow = <TFieldValues extends FieldValues>({
  control,
  name,
  ...props
}: Omit<
  React.ComponentProps<typeof FormRow_>,
  "_forwardErrorToChildren" | "error"
> & {
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
}) => {
  const { field, fieldState } = useController({ control, name });

  return (
    <FormRow_
      id={field.name}
      {...props}
      _forwardErrorToChildren={false}
      error={fieldState.error?.message}
    />
  );
};

export const FormRow = Object.assign(FormRow_, { Form: FormFormRow, Label });
