import classNames from "classnames";
import { useMemo } from "react";
import { FormattedRelativeTime } from "react-intl";
import { graphql } from "relay-runtime";

import { Button, LinkButton } from "../components/ui/Button";
import { Typography } from "../components/ui/Typography";
import useClientHealth from "../hooks/useClientHealth";
import { useFeatureFlags } from "../hooks/useIsFeatureEnabled";
import { useSafeMutation } from "../hooks/useSafeMutation";
import useServerHealth from "../hooks/useServerHealth";
import { useSubscriptionLatestResponse } from "../hooks/useSubscriptionLatestResponse";
import { Debug_Error_Mutation } from "./__generated__/Debug_Error_Mutation.graphql";
import { DebugSubscription } from "./__generated__/DebugSubscription.graphql";

const DEBUG_SUBSCRIPTION = graphql`
  subscription DebugSubscription {
    countdown(from: 1000)
  }
`;

const ERROR_MUTATION = graphql`
  mutation Debug_Error_Mutation {
    throwError
  }
`;

export const ClientDebug = () => {
  const featureFlags = useFeatureFlags();
  const clientHealth = useClientHealth();
  const subscriptionConfig = useMemo(
    () => ({
      subscription: DEBUG_SUBSCRIPTION,
      variables: {},
    }),
    [],
  );

  const debugSubscriptionState =
    useSubscriptionLatestResponse<DebugSubscription>(subscriptionConfig);

  const [triggerCrashingMutation] =
    useSafeMutation<Debug_Error_Mutation>(ERROR_MUTATION);

  const throwCrashTestEvent = () => {
    throw new Error("Crash test event");
  };

  if (clientHealth.state === "loading") {
    return <>Loading...</>;
  }

  if (clientHealth.state === "failed") {
    return <>Failed to check health, waiting for next tick...</>;
  }

  return (
    <>
      <Typography className="text-black-05" variant="Regular/Extra Small">
        Updated:{" "}
        <FormattedRelativeTime
          updateIntervalInSeconds={1}
          value={
            clientHealth.value.updatedAt.getTime() / 1000 -
            new Date().getTime() / 1000
          }
        />
      </Typography>
      <ul className="list-inside list-disc">
        <li>Latest version: {clientHealth.value.latestVersion}</li>
        <li>
          Active version:{" "}
          <span
            className={classNames({
              "text-green":
                clientHealth.value.latestVersion ===
                clientHealth.value.activeVersion,
              "text-red":
                clientHealth.value.latestVersion !==
                clientHealth.value.activeVersion,
            })}
          >
            {clientHealth.value.activeVersion}
          </span>
        </li>
        <li>Environment: {clientHealth.value.environment}</li>
        <li>
          Feature flags:
          <ul className="ml-2 list-inside list-disc">
            {featureFlags.map((featureFlag) => (
              <li key={featureFlag.key}>
                {featureFlag.key}:{" "}
                <span
                  className={classNames({
                    "text-green": featureFlag.value,
                    "text-red": !featureFlag.value,
                  })}
                >
                  {featureFlag.value ? "true" : "false"}
                </span>
              </li>
            ))}
          </ul>
        </li>
        <li>
          Debug subscription:
          <ul className="ml-2 list-inside list-disc">
            <li>
              State:
              <span
                className={classNames({
                  "text-green": debugSubscriptionState.state === "loaded",
                  "text-orange": debugSubscriptionState.state === "loading",
                })}
              >
                {" "}
                {debugSubscriptionState.state}
              </span>
            </li>
            {debugSubscriptionState.state === "loaded" && (
              <li>
                Live countdown: {debugSubscriptionState.response.countdown}
              </li>
            )}
          </ul>
        </li>
        <li>
          <Button
            onClick={throwCrashTestEvent}
            size="extra small"
            variant="Secondary Outline"
          >
            Throw crash test event
          </Button>
        </li>
        <li>
          <LinkButton
            size="extra small"
            to="/crash-test"
            variant="Secondary Outline"
          >
            Visit crash test page
          </LinkButton>
        </li>
        <li>
          <Button
            onClick={async () => {
              await triggerCrashingMutation({
                variables: {},
              });
            }}
            size="extra small"
            variant="Secondary Outline"
          >
            Trigger crashing mutation
          </Button>
        </li>
      </ul>
    </>
  );
};

export const ServerDebug = () => {
  const serverHealth = useServerHealth();

  if (serverHealth.state === "loading") {
    return <>Loading...</>;
  }

  const updatedAt = (
    <Typography className="text-black-05" variant="Regular/Extra Small">
      Updated:{" "}
      <FormattedRelativeTime
        updateIntervalInSeconds={1}
        value={
          serverHealth.value.updatedAt.getTime() / 1000 -
          new Date().getTime() / 1000
        }
      />
    </Typography>
  );

  if (serverHealth.value.status === "down") {
    return (
      <>
        {updatedAt}
        <ul className="block list-inside list-disc">
          <li>
            Status: <span className="text-red">down</span>
          </li>
        </ul>
      </>
    );
  }

  return (
    <>
      {updatedAt}
      <ul className="block list-inside list-disc">
        <li>
          Status: <span className="text-green">up</span>
        </li>
        <li>Environment: {serverHealth.value.environment}</li>
        <li>Version: {serverHealth.value.version}</li>
      </ul>
    </>
  );
};

const DebugPage = () => {
  return (
    <div className="container mx-auto p-10">
      <Typography variant="Title 1">Debug</Typography>
      <ul>
        <li className="mt-2">
          <Typography variant="Title 3">Server</Typography>
          <ServerDebug />
        </li>
        <li className="mt-2">
          <Typography variant="Title 3">Client</Typography>
          <ClientDebug />
        </li>
      </ul>
    </div>
  );
};

export default DebugPage;
