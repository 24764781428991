import { Suspense, useMemo } from "react";
import { useFragment } from "react-relay";
import { generatePath, useSearchParams } from "react-router-dom";
import { graphql } from "relay-runtime";

import { TabNavigation } from "../../../components/ui/TabNavigation";
import { APPLICATION_ROUTES } from "../../../paths";
import { SuspensedTabList_Deferred_Organization$key } from "./__generated__/SuspensedTabList_Deferred_Organization.graphql";
import { SuspensedTabList_Organization$key } from "./__generated__/SuspensedTabList_Organization.graphql";

const DEFERRED_ORGANIZATION_FRAGMENT = graphql`
  fragment SuspensedTabList_Deferred_Organization on Organization {
    id
    activeGrantees: grantees(filters: { status: Active }) {
      totalCount
    }
    terminatedGrantees: grantees(filters: { status: Terminated }) {
      totalCount
    }
  }
`;

const useLink = ({ organizationId }: { organizationId: string }) => {
  const [searchParams] = useSearchParams();

  return useMemo(
    () => ({
      toActiveGrantees: {
        pathname: generatePath(APPLICATION_ROUTES.organizationGranteesActive, {
          organizationId,
        }),
        search: searchParams.toString(),
      },
      toTerminatedGrantees: {
        pathname: generatePath(
          APPLICATION_ROUTES.organizationGranteesTerminated,
          {
            organizationId,
          },
        ),
        search: searchParams.toString(),
      },
    }),
    [organizationId, searchParams],
  );
};

const Deferred: React.FC<{
  loading?: boolean;
  organizationFragment: SuspensedTabList_Deferred_Organization$key;
}> = ({ loading, organizationFragment }) => {
  const organization = useFragment(
    DEFERRED_ORGANIZATION_FRAGMENT,
    organizationFragment,
  );

  const { toActiveGrantees, toTerminatedGrantees } = useLink({
    organizationId: organization.id,
  });

  return (
    <TabNavigation layoutId="grantees-tab-navigation" loading={loading}>
      <TabNavigation.Link
        count={organization.activeGrantees.totalCount}
        to={toActiveGrantees}
      >
        Active
      </TabNavigation.Link>
      <TabNavigation.Link
        count={organization.terminatedGrantees.totalCount}
        to={toTerminatedGrantees}
      >
        Terminated
      </TabNavigation.Link>
    </TabNavigation>
  );
};

const ORGANIZATION_FRAGMENT = graphql`
  fragment SuspensedTabList_Organization on Organization {
    id
    ...SuspensedTabList_Deferred_Organization @defer
  }
`;

export const SuspensedTabList: React.FC<{
  loading?: boolean;
  organizationFragment: SuspensedTabList_Organization$key;
}> = ({ loading, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const { toActiveGrantees, toTerminatedGrantees } = useLink({
    organizationId: organization.id,
  });

  return (
    <Suspense
      fallback={
        <TabNavigation layoutId="grantees-tab-navigation" loading={loading}>
          <TabNavigation.Link count={99} skeleton to={toActiveGrantees}>
            Active
          </TabNavigation.Link>
          <TabNavigation.Link count={99} skeleton to={toTerminatedGrantees}>
            Terminated
          </TabNavigation.Link>
        </TabNavigation>
      }
    >
      <Deferred loading={loading} organizationFragment={organization} />
    </Suspense>
  );
};
