import { isEmpty } from "lodash";
import { useState } from "react";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";

import { CurrencySymbol } from "../../../../components/CurrencySymbol";
import { Page } from "../../../../components/Page";
import { NoticeMessage } from "../../../../components/ui/NoticeMessage";
import { Switch } from "../../../../components/ui/Switch";
import { useOrganizationIdParam } from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { InsightPage } from "../InsightPage";
import { ExercisesActivity_ExercisesActivityDataPoint$key } from "./__generated__/ExercisesActivity_ExercisesActivityDataPoint.graphql";
import { ExercisesActivity_Organization$key } from "./__generated__/ExercisesActivity_Organization.graphql";
import { ExercisesActivity_Query } from "./__generated__/ExercisesActivity_Query.graphql";
import { ExercisesActivityExercisableSharesBoxes } from "./ExercisesActivityExercisableSharesBoxes";
import {
  ExercisesActivityGraph,
  ExercisesActivityGraphMode,
  ExercisesActivityGraphModes,
} from "./ExercisesActivityGraph";

const ORGANIZATION_FRAGMENT = graphql`
  fragment ExercisesActivity_Organization on Organization {
    id
    ...ExercisesActivityGraph_Organization
    ...ExercisesActivityExercisableSharesBoxes_Organization
    ...CurrencySymbol_Organization
  }
`;

const DATA_POINTS_FRAGMENT = graphql`
  fragment ExercisesActivity_ExercisesActivityDataPoint on ExercisesActivityDataPoint
  @relay(plural: true) {
    ...ExercisesActivityGraph_ExercisesActivityDataPoints
  }
`;

const AdminExercisesActivityPage_: React.FC<{
  dataPointsFragment: ExercisesActivity_ExercisesActivityDataPoint$key;
  organizationFragment: ExercisesActivity_Organization$key;
}> = ({ dataPointsFragment, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const dataPoints = useFragment(DATA_POINTS_FRAGMENT, dataPointsFragment);

  const [selectedMode, setSelectedMode] =
    useState<ExercisesActivityGraphMode>("SHARES");

  return (
    <InsightPage
      aboveContent={
        <ExercisesActivityExercisableSharesBoxes
          organizationFragment={organization}
        />
      }
      subtitle="Number of options exercised and cash received over time"
      title="📓 Exercise activity"
    >
      {isEmpty(dataPoints) ? (
        <NoticeMessage size="Small">
          We could not find any exercise data for that period.
        </NoticeMessage>
      ) : (
        <>
          <Switch
            getOptionLabel={(option, selected) => {
              switch (option) {
                case "CASH":
                  return (
                    <Switch.Option
                      label={
                        <>
                          <CurrencySymbol organizationFragment={organization} />{" "}
                          Cash received
                        </>
                      }
                      selected={selected}
                    />
                  );
                case "SHARES":
                  return (
                    <Switch.Option
                      label="# Options exercised"
                      selected={selected}
                    />
                  );
              }
            }}
            getOptionValue={(option) => option}
            name="exercises-activity-mode"
            onChange={setSelectedMode}
            options={ExercisesActivityGraphModes}
            selectedOption={selectedMode}
          />
          <ExercisesActivityGraph
            dataPointsFragment={dataPoints}
            mode={selectedMode}
            organizationFragment={organization}
          />
        </>
      )}
    </InsightPage>
  );
};

const QUERY = graphql`
  query ExercisesActivity_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      name
      id
      ...ExercisesActivity_Organization
    }
    exercisesActivityDataPoints(organizationId: $organizationId) {
      ...ExercisesActivity_ExercisesActivityDataPoint
    }
  }
`;

const AdminExercisesActivityPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { exercisesActivityDataPoints: dataPoints, organization } =
    useLazyLoadQuery<ExercisesActivity_Query>(QUERY, {
      organizationId,
    });

  if (!organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Exercises Activity"
      analyticsName="Admin - Insights - Exercises Activity"
      organizationId={organization.id}
      title={`Admin | ${organization.name} insights | exercises activity`}
    >
      <AdminExercisesActivityPage_
        dataPointsFragment={dataPoints}
        organizationFragment={organization}
      />
    </Page>
  );
};

export default AdminExercisesActivityPage;
