/**
 * @generated SignedSource<<80d3c3319f9e772bd71a38dfbeeea8f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoteLikeApplicationLayout_Viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationRoleSwitcher_Account">;
  readonly " $fragmentType": "RemoteLikeApplicationLayout_Viewer";
};
export type RemoteLikeApplicationLayout_Viewer$key = {
  readonly " $data"?: RemoteLikeApplicationLayout_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteLikeApplicationLayout_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteLikeApplicationLayout_Viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrganizationRoleSwitcher_Account"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "e148b78603b1a802142a4dff3f539dee";

export default node;
