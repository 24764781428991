import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import { compact, trim } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { CurrencySymbol } from "../../../components/CurrencySymbol";
import {
  GrantedSharesInput,
  useGrantedSharesController,
} from "../../../components/GrantedSharesInput";
import { Page } from "../../../components/Page";
import { Button } from "../../../components/ui/Button";
import { Card } from "../../../components/ui/Card";
import { Checkbox } from "../../../components/ui/Form/Checkbox";
import { FormRow } from "../../../components/ui/Form/FormRow";
import { Input } from "../../../components/ui/Form/Inputs/Input";
import { SelectAutocomplete } from "../../../components/ui/Form/Inputs/Select/SelectAutocomplete";
import { Typography } from "../../../components/ui/Typography";
import { useQuery } from "../../../hooks/useQuery";
import { IncentiveSettings_EquityOffer$key } from "./__generated__/IncentiveSettings_EquityOffer.graphql";
import { IncentiveSettings_InstrumentsQuery } from "./__generated__/IncentiveSettings_InstrumentsQuery.graphql";
import { CheckboxFormRow } from "./CheckboxFormRow";
import { useEquityOfferContext } from "./EquityOfferContext";
import { EquityOfferPreviewLayout } from "./EquityOfferPreviewLayout";
import { EquityOfferIncentive } from "./Incentive";
import mostPopularCurrencyCodes from "./most-popular-currency-codes.json";

const EQUITY_OFFER_FRAGMENT = graphql`
  fragment IncentiveSettings_EquityOffer on EquityOffer {
    id
    displayEquityAsDollarValue
    displayEquityAsPercentage
    shares
    displaySalary
    salary
    salaryCurrencyCode
    displayBenefits
    benefits
    instrument {
      id
    }
    taxResidenceCountry {
      code
    }
    workRelationship
    organization {
      id
      name
      fullyDilutedShares
      latestPricePerShare {
        value
      }
      ...CurrencySymbol_Organization
      ...GrantedSharesInput_Organization
    }
    displayTaxationExplanation
    ...Incentive_EquityOffer
    ...EquityOfferPreviewLayout_EquityOffer
  }
`;

const INSTRUMENTS_QUERY = graphql`
  query IncentiveSettings_InstrumentsQuery(
    $countryCode: String
    $workRelationship: WorkRelationship
  ) {
    instruments(
      countryCode: $countryCode
      workRelationship: $workRelationship
    ) {
      id
      name
      isVirtual
    }
  }
`;

const schema = z.object({
  benefits: z.array(
    z.object({
      value: z.string(),
    }),
  ),
  displayBenefits: z.boolean(),
  displayEquityAsDollarValue: z.boolean(),
  displayEquityAsPercentage: z.boolean(),
  displaySalary: z.boolean(),
  displayTaxationExplanation: z.boolean(),
  instrumentId: z.string().nullable(),
  salary: z.coerce
    .number()
    .nonnegative()
    .optional()
    .transform((value) => value || null),
  salaryCurrencyCode: z.string(),
  shares: z.coerce.number().int().positive().optional(),
});

const useCurrencySymbol = (currencyCode: string) => {
  const intl = useIntl();
  return useMemo(() => {
    const parts = intl.formatNumberToParts(0, {
      currency: currencyCode,
      style: "currency",
    });

    return parts.find((part) => part.type === "currency")?.value;
  }, [currencyCode, intl]);
};

const EquityOfferIncentiveSettingsPage_: React.FC = () => {
  const equityOfferContext = useEquityOfferContext();
  const equityOffer = useFragment<IncentiveSettings_EquityOffer$key>(
    EQUITY_OFFER_FRAGMENT,
    equityOfferContext.equityOffer,
  );
  const {
    query: { instruments },
  } = useQuery<IncentiveSettings_InstrumentsQuery>(INSTRUMENTS_QUERY, {
    countryCode: equityOffer.taxResidenceCountry?.code,
    workRelationship: equityOffer.workRelationship,
  });
  const intl = useIntl();

  const defaultInstrument = useMemo(() => {
    if (
      equityOffer.instrument &&
      instruments.some(
        (instrument) => instrument.id === equityOffer.instrument?.id,
      )
    ) {
      return equityOffer.instrument;
    }
    if (equityOffer.taxResidenceCountry?.code && equityOffer.workRelationship) {
      if (instruments.length === 1) {
        return instruments[0] || null;
      }
    }
    return null;
  }, [
    instruments,
    equityOffer.taxResidenceCountry,
    equityOffer.workRelationship,
    equityOffer.instrument,
  ]);

  const grantedSharesController = useGrantedSharesController({
    fullyDilutedShares: equityOffer.organization.fullyDilutedShares,
    initialState: {
      mode: "SHARES",
      shares: equityOffer.shares ?? undefined,
    },
    latestPricePerShare:
      equityOffer.organization.latestPricePerShare?.value ?? null,
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    resetField,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      benefits:
        equityOffer.benefits.length === 0
          ? [{ value: "" }]
          : equityOffer.benefits.map((benefit) => ({ value: benefit })),
      displayBenefits: equityOffer.displayBenefits,
      displayEquityAsDollarValue: equityOffer.displayEquityAsDollarValue,
      displayEquityAsPercentage: equityOffer.displayEquityAsPercentage,
      displaySalary: equityOffer.displaySalary,
      displayTaxationExplanation: equityOffer.displayTaxationExplanation,
      instrumentId: defaultInstrument?.id ?? null,
      salary: equityOffer.salary ?? undefined,
      salaryCurrencyCode: equityOffer.salaryCurrencyCode,
      shares: equityOffer.shares ?? undefined,
    },
    resolver: zodResolver(schema),
    shouldFocusError: false,
  });

  const onSubmit = handleSubmit(async (formInputs) => {
    await equityOfferContext.updatePartialEquityOffer({
      benefits: formInputs.benefits
        .map((benefit) => benefit.value)
        .filter((benefit) => trim(benefit).length > 0),
      displayBenefits: formInputs.displayBenefits,
      displayEquityAsPercentage: formInputs.displayEquityAsPercentage,
      displayEquityAsValue: formInputs.displayEquityAsDollarValue,
      displaySalary: formInputs.displaySalary,
      displayTaxationExplanation: formInputs.displayTaxationExplanation,
      instrumentId: formInputs.instrumentId,
      salary: formInputs.salary ?? null,
      salaryCurrencyCode: formInputs.salaryCurrencyCode,
      shares: formInputs.shares ?? null,
    });
  });

  watch(() => {
    void onSubmit();
  });

  useEffect(() => {
    if (grantedSharesController.shares === null) {
      resetField("shares");
      return;
    }

    setValue("shares", grantedSharesController.shares);
  }, [setValue, grantedSharesController.shares, resetField]);

  const [displaySalary, displayBenefits, instrumentId, salaryCurrencyCode] =
    watch([
      "displaySalary",
      "displayBenefits",
      "instrumentId",
      "salaryCurrencyCode",
    ]);

  const instrument = useMemo(
    () => instruments.find((instrument) => instrument.id === instrumentId),
    [instrumentId, instruments],
  );
  const salaryCurrencySymbol = useCurrencySymbol(salaryCurrencyCode);

  const handleSalaryCardCloseButtonClick = useCallback(() => {
    setValue("displaySalary", false);
  }, [setValue]);

  const handleBenefitsCardCloseButtonClick = useCallback(() => {
    setValue("displayBenefits", false);
  }, [setValue]);

  const benefitsFieldArray = useFieldArray({
    control,
    name: "benefits",
  });

  useEffect(() => {
    equityOfferContext.onStepEntered("INCENTIVE");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      equityOfferContext.trackStepCompleted("INCENTIVE");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EquityOfferPreviewLayout
      continueButtonDataCy="equityIncentiveNextStepButton"
      description="Allow the candidate to view how they’ll be incentivized by showing the equity compensation. "
      equityOfferFragment={equityOffer}
      onBackClick={equityOfferContext.goToPreviousStep}
      onContinueClick={equityOfferContext.goToNextStep}
      Preview={
        <EquityOfferIncentive
          equityOfferFragment={equityOffer}
          hideNoticeMessages
        />
      }
      title="Equity incentive"
    >
      <form className="block space-y-6">
        <section className="space-y-2">
          <Typography className="text-black-05" variant="Regular/Extra Small">
            Display equity incentive as
          </Typography>
          <div className="grid grid-cols-3 gap-2">
            <Controller
              control={control}
              name="displayEquityAsDollarValue"
              render={({ field: { value, ...field } }) => (
                <CheckboxFormRow
                  checkbox={<Checkbox checked={value} {...field} />}
                  label={
                    <>
                      <CurrencySymbol
                        organizationFragment={equityOffer.organization}
                      />{" "}
                      value
                    </>
                  }
                />
              )}
            />
            <Controller
              control={control}
              name="displayEquityAsPercentage"
              render={({ field: { value, ...field } }) => (
                <CheckboxFormRow
                  checkbox={<Checkbox checked={value} {...field} />}
                  label="% ownership"
                />
              )}
            />
          </div>
        </section>
        <Card shadow={false} size={4} title="Equity Package 🎁">
          <FormRow dataCy="granted-shares-input" error={errors.shares?.message}>
            <GrantedSharesInput
              {...grantedSharesController.inputController}
              autoFocus={false}
              isVirtual={instrument?.isVirtual}
              name="shares"
              organizationFragment={equityOffer.organization}
            />
          </FormRow>
        </Card>

        {displaySalary ? (
          <Card
            onCloseButtonClick={handleSalaryCardCloseButtonClick}
            shadow={false}
            size={4}
            title="Salary 💪"
          >
            <div className="grid grid-cols-2 gap-4">
              <Controller
                control={control}
                name="salaryCurrencyCode"
                render={({ field, fieldState }) => (
                  <FormRow error={fieldState.error?.message} label="Currency">
                    <SelectAutocomplete
                      getOptionLabel={(currencyCode) =>
                        compact([
                          currencyCode.code,
                          intl.formatDisplayName(currencyCode.code, {
                            style: "long",
                            type: "currency",
                          }),
                        ]).join(" - ")
                      }
                      getOptionValue={(currencyCode) => currencyCode.code}
                      onChange={(currencyCode) => {
                        field.onChange(currencyCode?.code);
                      }}
                      options={mostPopularCurrencyCodes.map((currencyCode) => ({
                        code: currencyCode,
                      }))}
                      usePortal
                      value={{ code: field.value }}
                    />
                  </FormRow>
                )}
              />
              <Controller
                control={control}
                name="salary"
                render={({ field, fieldState }) => (
                  <FormRow
                    error={fieldState.error?.message}
                    label="Annual salary"
                  >
                    <Input
                      before={salaryCurrencySymbol}
                      type="number"
                      {...field}
                      data-cy="salary"
                    />
                  </FormRow>
                )}
              />
            </div>
          </Card>
        ) : (
          <div>
            <Button
              leftIcon={<PlusIcon />}
              onClick={() => {
                setValue("displaySalary", true);
              }}
              size="extra small"
              variant="Primary Outline"
            >
              Add salary
            </Button>
          </div>
        )}

        {displayBenefits ? (
          <Card
            onCloseButtonClick={handleBenefitsCardCloseButtonClick}
            shadow={false}
            size={4}
            title="Benefits 🚀"
          >
            <div className="space-y-2">
              {benefitsFieldArray.fields.map((field, index) => (
                <FormRow key={field.id}>
                  <Input
                    after={
                      <button
                        data-cy={`benefits.${index}.delete.button`}
                        onClick={() => {
                          benefitsFieldArray.remove(index);
                        }}
                        type="button"
                      >
                        <XMarkIcon className="h-4 w-4" />
                      </button>
                    }
                    {...register(`benefits.${index}.value`)}
                    data-cy={`benefits.${index}.value`}
                  />
                </FormRow>
              ))}
              <Button
                leftIcon={<PlusIcon className="h-4 w-4" />}
                onClick={() => {
                  benefitsFieldArray.append({ value: "" });
                }}
                size="extra small"
                type="button"
                variant="Secondary Full"
              >
                Add a benefit
              </Button>
            </div>
          </Card>
        ) : (
          <div>
            <Button
              leftIcon={<PlusIcon />}
              onClick={() => {
                setValue("displayBenefits", true);
              }}
              size="extra small"
              variant="Primary Outline"
            >
              Add benefits
            </Button>
          </div>
        )}

        <Controller
          control={control}
          name="instrumentId"
          render={({ field, fieldState }) => (
            <FormRow error={fieldState.error?.message} label="Equity type">
              <SelectAutocomplete
                dataCy="instrument"
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                onChange={(option) => {
                  field.onChange(option?.id);
                }}
                options={instruments}
                value={instruments.find(
                  (instrument) => instrument.id === field.value,
                )}
              />
            </FormRow>
          )}
        />
      </form>
    </EquityOfferPreviewLayout>
  );
};

const EquityOfferIncentiveSettingsPage: React.FC = () => {
  const equityOfferContext = useEquityOfferContext();
  const equityOffer = useFragment<IncentiveSettings_EquityOffer$key>(
    EQUITY_OFFER_FRAGMENT,
    equityOfferContext.equityOffer,
  );
  const { organization } = equityOffer;

  return (
    <Page
      analyticsCategory="Equity Offer Configuration"
      analyticsName="Admin - Equity offer configuration - Incentive"
      organizationId={organization.id}
      title={`Admin | ${organization.name} equity offer incentive configuration`}
    >
      <EquityOfferIncentiveSettingsPage_ />
    </Page>
  );
};

export default EquityOfferIncentiveSettingsPage;
