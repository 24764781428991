import { IconV2DuotoneBookOpen } from "@remote-com/norma/icons/IconV2DuotoneBookOpen";
import { IconV2DuotoneCalculator } from "@remote-com/norma/icons/IconV2DuotoneCalculator";
import { IconV2DuotoneColumns2 } from "@remote-com/norma/icons/IconV2DuotoneColumns2";
import { IconV2DuotoneFilesText } from "@remote-com/norma/icons/IconV2DuotoneFilesText";
import { IconV2DuotoneFolderOpen } from "@remote-com/norma/icons/IconV2DuotoneFolderOpen";
import { IconV2DuotoneHome } from "@remote-com/norma/icons/IconV2DuotoneHome";
import { IconV2DuotoneNoteText } from "@remote-com/norma/icons/IconV2DuotoneNoteText";
import { IconV2DuotonePieChart } from "@remote-com/norma/icons/IconV2DuotonePieChart";
import { IconV2DuotoneSlidersVertical } from "@remote-com/norma/icons/IconV2DuotoneSlidersVertical";
import { IconV2DuotoneUmbrella } from "@remote-com/norma/icons/IconV2DuotoneUmbrella";
import { IconV2DuotoneUsers } from "@remote-com/norma/icons/IconV2DuotoneUsers";
import { IconV2OutlineBookOpen } from "@remote-com/norma/icons/IconV2OutlineBookOpen";
import { IconV2OutlineCalculator } from "@remote-com/norma/icons/IconV2OutlineCalculator";
import { IconV2OutlineColumns2 } from "@remote-com/norma/icons/IconV2OutlineColumns2";
import { IconV2OutlineFilesText } from "@remote-com/norma/icons/IconV2OutlineFilesText";
import { IconV2OutlineFolderOpen } from "@remote-com/norma/icons/IconV2OutlineFolderOpen";
import { IconV2OutlineHome } from "@remote-com/norma/icons/IconV2OutlineHome";
import { IconV2OutlineNoteText } from "@remote-com/norma/icons/IconV2OutlineNoteText";
import { IconV2OutlinePieChart } from "@remote-com/norma/icons/IconV2OutlinePieChart";
import { IconV2OutlineSlidersVertical } from "@remote-com/norma/icons/IconV2OutlineSlidersVertical";
import { IconV2OutlineUmbrella } from "@remote-com/norma/icons/IconV2OutlineUmbrella";
import { IconV2OutlineUsers } from "@remote-com/norma/icons/IconV2OutlineUsers";
import { useEffect, useState } from "react";
import { graphql, useFragment } from "react-relay";
import { generatePath, useMatch } from "react-router-dom";

import { APPLICATION_ROUTES } from "../../paths";
import { ApplicationSideBar } from "../ui/ApplicationSideBar/ApplicationSideBar";
import { PoweredByCarta } from "../ui/PoweredByCarta/PoweredByCarta";
import { EssentialsApplicationSidebar_Organization$key } from "./__generated__/EssentialsApplicationSidebar_Organization.graphql";
import { EssentialsApplicationSidebar_Viewer$key } from "./__generated__/EssentialsApplicationSidebar_Viewer.graphql";

const VIEWER_FRAGMENT = graphql`
  fragment EssentialsApplicationSidebar_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    ...ApplicationSideBar_Viewer
    isAllowedToManageOrganization(organizationId: $organizationId)
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment EssentialsApplicationSidebar_Organization on Organization {
    id
    isConnectedToCarta
    ...ApplicationSideBar_Organization
  }
`;

export const EssentialsApplicationSidebar = ({
  organizationFragment,
  viewerFragment,
}: {
  organizationFragment: EssentialsApplicationSidebar_Organization$key;
  viewerFragment: EssentialsApplicationSidebar_Viewer$key;
}) => {
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const isOnCompanySettingsSection = !!useMatch(
    `/organizations/:organizationId/company-settings/*`,
  );

  const [unfoldedSection, setUnfoldedSection] = useState<
    "company_settings" | null
  >(null);

  useEffect(() => {
    if (isOnCompanySettingsSection) {
      setUnfoldedSection("company_settings");
      return;
    }
    setUnfoldedSection(null);
  }, [isOnCompanySettingsSection]);

  return (
    <ApplicationSideBar
      activeRole="Admin"
      bottomFixedContent={
        organization.isConnectedToCarta && (
          <PoweredByCarta className="m-auto h-full" />
        )
      }
      homePath={generatePath(APPLICATION_ROUTES.organizationHome, {
        organizationId: organization.id,
      })}
      organizationFragment={organization}
      viewerFragment={viewer}
    >
      <ApplicationSideBar.NavLink
        end
        icons={{
          ActiveIcon: <IconV2DuotoneColumns2 />,
          Icon: <IconV2OutlineColumns2 />,
        }}
        to={generatePath(APPLICATION_ROUTES.organizationHome, {
          organizationId: organization.id,
        })}
      >
        Dashboard
      </ApplicationSideBar.NavLink>

      <ApplicationSideBar.Section title="Equity">
        <ApplicationSideBar.NavLink
          icons={{
            ActiveIcon: <IconV2DuotoneUsers />,
            Icon: <IconV2OutlineUsers />,
          }}
          to={generatePath(APPLICATION_ROUTES.organizationGrantees, {
            organizationId: organization.id,
          })}
        >
          Team overview
        </ApplicationSideBar.NavLink>
        <ApplicationSideBar.NavLink
          icons={{
            ActiveIcon: <IconV2DuotonePieChart />,
            Icon: <IconV2OutlinePieChart />,
          }}
          to={generatePath(APPLICATION_ROUTES.organizationEquityGrants, {
            organizationId: organization.id,
          })}
        >
          Equity grants
        </ApplicationSideBar.NavLink>
        <ApplicationSideBar.NavLink
          icons={{
            ActiveIcon: <IconV2DuotoneUmbrella />,
            Icon: <IconV2OutlineUmbrella />,
          }}
          to={generatePath(
            APPLICATION_ROUTES.organizationEmployeePortalsSettings,
            {
              organizationId: organization.id,
            },
          )}
        >
          Employee portals
        </ApplicationSideBar.NavLink>
        <ApplicationSideBar.NavLink
          icons={{
            ActiveIcon: <IconV2DuotoneSlidersVertical />,
            Icon: <IconV2OutlineSlidersVertical />,
          }}
          to={generatePath(APPLICATION_ROUTES.organizationEquityConfigure, {
            organizationId: organization.id,
          })}
        >
          Configure equity
        </ApplicationSideBar.NavLink>
      </ApplicationSideBar.Section>

      <ApplicationSideBar.Section title="Tax handling">
        <ApplicationSideBar.NavLink
          icons={{
            ActiveIcon: <IconV2DuotoneFilesText />,
            Icon: <IconV2OutlineFilesText />,
          }}
          to={generatePath(APPLICATION_ROUTES.organizationExerciseRequests, {
            organizationId: organization.id,
          })}
        >
          Option exercises
        </ApplicationSideBar.NavLink>
        <ApplicationSideBar.NavLink
          icons={{
            ActiveIcon: <IconV2DuotoneNoteText />,
            Icon: <IconV2OutlineNoteText />,
          }}
          to={generatePath(APPLICATION_ROUTES.organizationRSUVesting, {
            organizationId: organization.id,
          })}
        >
          RSU vesting
        </ApplicationSideBar.NavLink>
      </ApplicationSideBar.Section>

      <ApplicationSideBar.Section title="Tools">
        <ApplicationSideBar.NavLink
          icons={{
            ActiveIcon: <IconV2DuotoneCalculator />,
            Icon: <IconV2OutlineCalculator />,
          }}
          to={generatePath(
            APPLICATION_ROUTES.organizationSettingsSafeCalculator,
            {
              organizationId: organization.id,
            },
          )}
        >
          SAFE converter
        </ApplicationSideBar.NavLink>

        <ApplicationSideBar.NavLink
          icons={{
            ActiveIcon: <IconV2DuotoneBookOpen />,
            Icon: <IconV2OutlineBookOpen />,
          }}
          to={generatePath(APPLICATION_ROUTES.organizationEquityHandbooks, {
            organizationId: organization.id,
          })}
        >
          Equity handbooks
        </ApplicationSideBar.NavLink>
      </ApplicationSideBar.Section>

      <ApplicationSideBar.Section title="Company">
        <ApplicationSideBar.NavLink
          icons={{
            ActiveIcon: <IconV2DuotoneFolderOpen />,
            Icon: <IconV2OutlineFolderOpen />,
          }}
          to={generatePath(APPLICATION_ROUTES.organizationDocuments, {
            organizationId: organization.id,
          })}
        >
          Documents
        </ApplicationSideBar.NavLink>
        {viewer.isAllowedToManageOrganization && (
          <ApplicationSideBar.FoldableItem
            icons={{
              ActiveIcon: <IconV2DuotoneHome />,
              Icon: <IconV2OutlineHome />,
            }}
            isOpen={unfoldedSection === "company_settings"}
            label="Company settings"
            onToggle={() => {
              setUnfoldedSection(
                unfoldedSection !== "company_settings"
                  ? "company_settings"
                  : null,
              );
            }}
          >
            <ApplicationSideBar.NavLink
              end
              isSubLink
              to={generatePath(
                APPLICATION_ROUTES.organizationCompanySettingsCompanyDetails,
                {
                  organizationId: organization.id,
                },
              )}
            >
              Company details
            </ApplicationSideBar.NavLink>
            <ApplicationSideBar.NavLink
              isSubLink
              to={generatePath(
                APPLICATION_ROUTES.organizationCompanySettingsFairMarketValue,
                {
                  organizationId: organization.id,
                },
              )}
            >
              Fair market value
            </ApplicationSideBar.NavLink>
            <ApplicationSideBar.NavLink
              isSubLink
              to={generatePath(
                APPLICATION_ROUTES.organizationCompanySettingsPlanParameters,
                {
                  organizationId: organization.id,
                },
              )}
            >
              Plan parameters
            </ApplicationSideBar.NavLink>
          </ApplicationSideBar.FoldableItem>
        )}
      </ApplicationSideBar.Section>
    </ApplicationSideBar>
  );
};
