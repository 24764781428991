import { Pill } from "@remote-com/norma";
import { compact } from "lodash";
import React, { startTransition } from "react";
import { useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { Page } from "../../../../components/Page";
import { LargeCenteredDetailsLayout } from "../../../../components/ui/Layout/LargeCenteredDetailsLayout";
import { useQuery } from "../../../../hooks/useQuery";
import {
  APPLICATION_ROUTES,
  useGranteeIdParam,
  useOrganizationIdParam,
} from "../../../../paths";
import { WORK_RELATIONSHIP_TO_LABEL_HELPER } from "../../../../services/workRelationship";
import NotFoundPage from "../../../NotFound/NotFound";
import { EmployeeDetails_Grantee$key } from "./__generated__/EmployeeDetails_Grantee.graphql";
import { EmployeeDetails_Organization$key } from "./__generated__/EmployeeDetails_Organization.graphql";
import { EmployeeDetails_Query } from "./__generated__/EmployeeDetails_Query.graphql";
import { EmployeeDetails_Viewer$key } from "./__generated__/EmployeeDetails_Viewer.graphql";
import { EmployeeDetailsSection } from "./EmployeeDetailsSection";
import { EmployeeDetailsTopBarActions } from "./EmployeeDetailsTopBarActions";
import { EquityDetailsSection } from "./EquityDetailsSection";
import { OwnershipSection } from "./OwnershipSection";
import { PortalSection } from "./PortalSection";
import { VestingScheduleSection } from "./VestingScheduleSection";

const VIEWER_FRAGMENT = graphql`
  fragment EmployeeDetails_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    ...EmployeeDetailsTopBarActions_Viewer
      @arguments(organizationId: $organizationId)
    ...OwnershipSection_Viewer @arguments(organizationId: $organizationId)
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment EmployeeDetails_Organization on Organization {
    id
    name
    ...LargeCenteredDetailsLayout_Organization
    ...OwnershipSection_Organization
    ...VestingScheduleSection_Organization
    ...EquityDetailsSection_Organization
    ...EmployeeDetailsTopBarActions_Organization
  }
`;

const GRANTEE_FRAGMENT = graphql`
  fragment EmployeeDetails_Grantee on Grantee
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    id
    name

    workRelationship

    ctmsGrants {
      id
      ...EquityDetailsSection_CTMSGrants
      ...VestingScheduleSection_CTMSGrants
    }
    taxResidenceCountry {
      emoji
    }
    draftGrants: easopGrantsWithoutCtmsGrant {
      ...EquityDetailsSection_DraftGrants
      ...VestingScheduleSection_DraftGrants
    }
    ...OwnershipSection_Employee @arguments(organizationId: $organizationId)
    ...PortalSection_Employee
    ...EmployeeDetailsSection_Employee
    ...EmployeeDetailsTopBarActions_Employee
    ...EquityDetailsSection_Employee @arguments(organizationId: $organizationId)
  }
`;

const EmployeeDetails_: React.FC<{
  employeeFragment: EmployeeDetails_Grantee$key;
  onDataUpdated: () => void;
  organizationFragment: EmployeeDetails_Organization$key;
  viewerFragment: EmployeeDetails_Viewer$key;
}> = ({
  employeeFragment,
  onDataUpdated,
  organizationFragment,
  viewerFragment,
}) => {
  const organization = useFragment<EmployeeDetails_Organization$key>(
    ORGANIZATION_FRAGMENT,
    organizationFragment,
  );
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const employee = useFragment(GRANTEE_FRAGMENT, employeeFragment);
  const pageTitle = compact(
    [employee.taxResidenceCountry?.emoji, employee.name].join(" "),
  );

  return (
    <>
      <LargeCenteredDetailsLayout
        navigationTitle={pageTitle}
        organizationFragment={organization}
        parents={[
          {
            link: generatePath(APPLICATION_ROUTES.organizationGrantees, {
              organizationId: organization.id,
            }),
            title: "Grantees",
          },
        ]}
        title={
          <div className="flex items-center gap-2">
            <div className="whitespace-nowrap">{pageTitle}</div>
            {employee.workRelationship && (
              <div>
                <Pill tone="bayoux">
                  <span className="whitespace-nowrap">
                    {
                      WORK_RELATIONSHIP_TO_LABEL_HELPER[
                        employee.workRelationship
                      ].singularLabel
                    }
                  </span>
                </Pill>
              </div>
            )}
          </div>
        }
        titleActions={
          <EmployeeDetailsTopBarActions
            employeeFragment={employee}
            onDataUpdated={onDataUpdated}
            organizationFragment={organization}
            viewerFragment={viewer}
          />
        }
      >
        <div className="mt-16 space-y-16">
          <OwnershipSection
            employeeFragment={employee}
            onDataUpdated={onDataUpdated}
            organizationFragment={organization}
            viewerFragment={viewer}
          />
          <EquityDetailsSection
            ctmsGrantsFragment={employee.ctmsGrants}
            draftGrantsFragment={employee.draftGrants}
            employeeFragment={employee}
            organizationFragment={organization}
          />
          <VestingScheduleSection
            ctmsGrantsFragment={employee.ctmsGrants}
            draftGrantsFragment={employee.draftGrants}
            employeeId={employee.id}
            organizationFragment={organization}
          />
          <div className="grid grid-cols-1 items-start gap-16 lg:grid-cols-2">
            <PortalSection employeeFragment={employee} />
            <EmployeeDetailsSection employeeFragment={employee} />
          </div>
        </div>
      </LargeCenteredDetailsLayout>
    </>
  );
};

const QUERY = graphql`
  query EmployeeDetails_Query(
    $organizationId: OrganizationId!
    $employeeId: GranteeId!
  ) {
    organization(id: $organizationId) {
      id
      name
      ...EmployeeDetails_Organization
    }
    employee: grantee(id: $employeeId) {
      ...EmployeeDetails_Grantee @arguments(organizationId: $organizationId)
    }
    me {
      ...EmployeeDetails_Viewer @arguments(organizationId: $organizationId)
    }
  }
`;

const EmployeeDetailsPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const employeeId = useGranteeIdParam();

  const {
    query: { employee, me, organization },
    refreshQuery,
  } = useQuery<EmployeeDetails_Query>(QUERY, {
    employeeId,
    organizationId,
  });

  function onDataUpdated() {
    startTransition(() => {
      refreshQuery();
    });
  }

  if (!organization || !employee) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Admin Employees"
      analyticsName="Admin - Employees - Employee details"
      organizationId={organization.id}
      title={`Admin | ${organization.name} employee details`}
    >
      <EmployeeDetails_
        employeeFragment={employee}
        onDataUpdated={onDataUpdated}
        organizationFragment={organization}
        viewerFragment={me}
      />
    </Page>
  );
};

export default EmployeeDetailsPage;
