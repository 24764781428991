import classNames from "classnames";
import { graphql, useFragment } from "react-relay";

import { SafeSimulatorGraphSection_Organization$key } from "./__generated__/SafeSimulatorGraphSection_Organization.graphql";
import { CurrencySymbol } from "./CurrencySymbol";
import { FormattedCurrencyCompact } from "./Formatted/FormattedCurrencyCompact";
import { FormattedFMV } from "./FormattedFMV";
import { SafeSimulatorGraph } from "./SafeSimulatorGraph";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment SafeSimulatorGraphSection_Organization on Organization {
    ...CurrencySymbol_Organization
    ...FormattedFMV_Organization
    ...FormattedCurrencyCompact_Organization
  }
`;

export const SafeSimulatorGraphSection: React.FC<{
  className?: string;
  currentShareholdersShares: null | number;
  optionPoolShares: null | number;
  organizationFragment: SafeSimulatorGraphSection_Organization$key;
  postConversionCurrentPricePerShare: null | number;
  postConversionLatestValuation: null | number;
  postConversionSafeShares: null | number;
}> = ({
  className,
  currentShareholdersShares,
  optionPoolShares,
  organizationFragment,
  postConversionCurrentPricePerShare,
  postConversionLatestValuation,
  postConversionSafeShares,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <div
      className={classNames(
        "flex flex-col items-center gap-4 text-center",
        className,
      )}
    >
      <div className="space-y-2">
        <Typography as="div" variant="Medium/Large">
          {postConversionLatestValuation ? (
            <FormattedCurrencyCompact
              organizationFragment={organization}
              value={postConversionLatestValuation}
            />
          ) : (
            <>
              <CurrencySymbol organizationFragment={organization} />-
            </>
          )}
        </Typography>
        <Typography
          as="div"
          className="text-black-05"
          variant="Regular/Extra Small"
        >
          CURRENT VALUATION
        </Typography>
      </div>
      <SafeSimulatorGraph
        currentShareholdersShares={currentShareholdersShares}
        optionPoolShares={optionPoolShares}
        postConversionSafeShares={postConversionSafeShares}
      />
      <div className="space-y-2">
        <Typography as="div" variant="Medium/Large">
          {postConversionCurrentPricePerShare ? (
            <FormattedFMV
              organizationFragment={organization}
              value={postConversionCurrentPricePerShare}
            />
          ) : (
            <>
              <CurrencySymbol organizationFragment={organization} />-
            </>
          )}
        </Typography>
        <Typography
          as="div"
          className="text-black-05"
          variant="Regular/Extra Small"
        >
          CURRENT SAFE PRICE
        </Typography>
      </div>
    </div>
  );
};
