/**
 * @generated SignedSource<<2054543010369debd8496974735b48c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GrantDetailsVestingScheduleSection_CTMSGrant$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EmployeeVestingGraph_CTMSGrants">;
  readonly " $fragmentType": "GrantDetailsVestingScheduleSection_CTMSGrant";
};
export type GrantDetailsVestingScheduleSection_CTMSGrant$key = {
  readonly " $data"?: GrantDetailsVestingScheduleSection_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantDetailsVestingScheduleSection_CTMSGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantDetailsVestingScheduleSection_CTMSGrant",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmployeeVestingGraph_CTMSGrants"
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "845ad4301f8148b7290505a130fe9b2c";

export default node;
