/**
 * @generated SignedSource<<985ccc68b46f61607aec8bc7e012d320>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SimulateAGrantFormModal_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GrantedSharesInput_Organization">;
  readonly " $fragmentType": "SimulateAGrantFormModal_Organization";
};
export type SimulateAGrantFormModal_Organization$key = {
  readonly " $data"?: SimulateAGrantFormModal_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"SimulateAGrantFormModal_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SimulateAGrantFormModal_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantedSharesInput_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "09dfe15fd78a6fb3d8d73364b886f2c2";

export default node;
