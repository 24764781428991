/**
 * @generated SignedSource<<27e1d961ae22563aa6f2b6de508bafee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PortalSection_GrantAccessToGranteePortalMutation$variables = {
  granteeId: string;
  organizationId: string;
};
export type PortalSection_GrantAccessToGranteePortalMutation$data = {
  readonly grantAccessToGranteePortal: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"PortalSection_Employee">;
  }>;
};
export type PortalSection_GrantAccessToGranteePortalMutation = {
  response: PortalSection_GrantAccessToGranteePortalMutation$data;
  variables: PortalSection_GrantAccessToGranteePortalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "granteeId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = [
  {
    "items": [
      {
        "kind": "Variable",
        "name": "granteeIds.0",
        "variableName": "granteeId"
      }
    ],
    "kind": "ListValue",
    "name": "granteeIds"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  },
  {
    "kind": "Literal",
    "name": "source",
    "value": "Remote Equity Web App"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PortalSection_GrantAccessToGranteePortalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Grantee",
        "kind": "LinkedField",
        "name": "grantAccessToGranteePortal",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PortalSection_Employee"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PortalSection_GrantAccessToGranteePortalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Grantee",
        "kind": "LinkedField",
        "name": "grantAccessToGranteePortal",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasPortalAccess",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cannotBeInvitedReason",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organizationId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5afdceda5b1376464357328358d8abd1",
    "id": null,
    "metadata": {},
    "name": "PortalSection_GrantAccessToGranteePortalMutation",
    "operationKind": "mutation",
    "text": "mutation PortalSection_GrantAccessToGranteePortalMutation(\n  $organizationId: OrganizationId!\n  $granteeId: GranteeId!\n) {\n  grantAccessToGranteePortal(granteeIds: [$granteeId], organizationId: $organizationId, source: \"Remote Equity Web App\") {\n    ...PortalSection_Employee\n    id\n  }\n}\n\nfragment PortalSection_Employee on Grantee {\n  id\n  hasPortalAccess\n  cannotBeInvitedReason\n  organizationId\n}\n"
  }
};
})();

(node as any).hash = "99a83d2007b4b1b53a280e3a60c74b1d";

export default node;
