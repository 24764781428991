/**
 * @generated SignedSource<<8668bf876c7e6f1977ef1e65db8db742>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquityTypeName = "BSPCE" | "EMI" | "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
export type EquityTypePage_SuperAdminEquityTypeHandbooks_Query$variables = {
  countryCode: string;
  equityTypeId: string;
};
export type EquityTypePage_SuperAdminEquityTypeHandbooks_Query$data = {
  readonly equityType: {
    readonly name: EquityTypeName;
    readonly " $fragmentSpreads": FragmentRefs<"EquityTypePage_SuperAdminEquityTypeHandbooks_EquityType">;
  } | null;
  readonly geography: {
    readonly country: {
      readonly name: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"EquityTypePage_SuperAdminEquityTypeHandbooks_Geography">;
  };
};
export type EquityTypePage_SuperAdminEquityTypeHandbooks_Query = {
  response: EquityTypePage_SuperAdminEquityTypeHandbooks_Query$data;
  variables: EquityTypePage_SuperAdminEquityTypeHandbooks_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "countryCode"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "equityTypeId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "countryCode",
    "variableName": "countryCode"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "equityTypeId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "enabled",
  "storageKey": null
},
v6 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "publishedAt",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "published",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastModifiedAt",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "EquityTypeHandbookSectionEvent",
    "kind": "LinkedField",
    "name": "sectionEvents",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "section",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "event",
        "storageKey": null
      },
      (v5/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nothingToDeclare",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "EquityTypeHandbookSectionEventItem",
        "kind": "LinkedField",
        "name": "items",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "category",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "content",
            "storageKey": null
          },
          (v5/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      (v4/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EquityTypePage_SuperAdminEquityTypeHandbooks_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Geography",
        "kind": "LinkedField",
        "name": "geography",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Country",
            "kind": "LinkedField",
            "name": "country",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EquityTypePage_SuperAdminEquityTypeHandbooks_Geography"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "EquityType",
        "kind": "LinkedField",
        "name": "equityType",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EquityTypePage_SuperAdminEquityTypeHandbooks_EquityType"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EquityTypePage_SuperAdminEquityTypeHandbooks_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Geography",
        "kind": "LinkedField",
        "name": "geography",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Country",
            "kind": "LinkedField",
            "name": "country",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "EquityType",
        "kind": "LinkedField",
        "name": "equityType",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "EquityTypeHandbook",
            "kind": "LinkedField",
            "name": "directEmployeeHandbook",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EquityTypeHandbook",
            "kind": "LinkedField",
            "name": "eoREmployeeHandbook",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EquityTypeHandbook",
            "kind": "LinkedField",
            "name": "contractorHandbook",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fe342ec6d2746cc4db2388506783c0b4",
    "id": null,
    "metadata": {},
    "name": "EquityTypePage_SuperAdminEquityTypeHandbooks_Query",
    "operationKind": "query",
    "text": "query EquityTypePage_SuperAdminEquityTypeHandbooks_Query(\n  $countryCode: String!\n  $equityTypeId: UUID!\n) {\n  geography(countryCode: $countryCode) {\n    country {\n      name\n    }\n    ...EquityTypePage_SuperAdminEquityTypeHandbooks_Geography\n  }\n  equityType(id: $equityTypeId) {\n    name\n    ...EquityTypePage_SuperAdminEquityTypeHandbooks_EquityType\n    id\n  }\n}\n\nfragment EquityTypeHandbookEdition_EquityType on EquityType {\n  id\n}\n\nfragment EquityTypeHandbookEdition_EquityTypeHandbook on EquityTypeHandbook {\n  id\n  publishedAt\n  published\n  lastModifiedAt\n  sectionEvents {\n    section\n    event\n    enabled\n    nothingToDeclare\n    items {\n      category\n      content\n      enabled\n      id\n    }\n    id\n  }\n}\n\nfragment EquityTypePage_SuperAdminEquityTypeHandbooks_EquityType on EquityType {\n  id\n  name\n  directEmployeeHandbook {\n    ...EquityTypeHandbookEdition_EquityTypeHandbook\n    id\n  }\n  eoREmployeeHandbook {\n    ...EquityTypeHandbookEdition_EquityTypeHandbook\n    id\n  }\n  contractorHandbook {\n    ...EquityTypeHandbookEdition_EquityTypeHandbook\n    id\n  }\n  ...EquityTypeHandbookEdition_EquityType\n}\n\nfragment EquityTypePage_SuperAdminEquityTypeHandbooks_Geography on Geography {\n  country {\n    displayName\n    code\n  }\n}\n"
  }
};
})();

(node as any).hash = "04a39773263eedebcbab9484f7121afc";

export default node;
