/**
 * @generated SignedSource<<28552af64e221a198f7312c8b68da097>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BookADemoView_Account$data = {
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly " $fragmentSpreads": FragmentRefs<"DemoBookedView_Viewer" | "RemoteEquityOnboardingLayout_Viewer">;
  readonly " $fragmentType": "BookADemoView_Account";
};
export type BookADemoView_Account$key = {
  readonly " $data"?: BookADemoView_Account$data;
  readonly " $fragmentSpreads": FragmentRefs<"BookADemoView_Account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BookADemoView_Account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemoteEquityOnboardingLayout_Viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DemoBookedView_Viewer"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "ff99174ba30e6545d958142c652f8ff0";

export default node;
