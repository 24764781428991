/**
 * @generated SignedSource<<919ab8b962091a533cde3e4ff68f2595>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type EquityGridLevelAttributes = {
  equityGridId: string;
  equityValue: number;
  name: string;
  role?: string | null;
  yearlySalary?: number | null;
};
export type EditEquityGridLevelSlideOver_SetEquityGridLevel_Mutation$variables = {
  attributes: EquityGridLevelAttributes;
  equityGridLevelId: string;
};
export type EditEquityGridLevelSlideOver_SetEquityGridLevel_Mutation$data = {
  readonly setEquityGridLevel: {
    readonly id: string;
  };
};
export type EditEquityGridLevelSlideOver_SetEquityGridLevel_Mutation = {
  response: EditEquityGridLevelSlideOver_SetEquityGridLevel_Mutation$data;
  variables: EditEquityGridLevelSlideOver_SetEquityGridLevel_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "attributes"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "equityGridLevelId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "attributes",
        "variableName": "attributes"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "equityGridLevelId"
      }
    ],
    "concreteType": "EquityGridLevel",
    "kind": "LinkedField",
    "name": "setEquityGridLevel",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditEquityGridLevelSlideOver_SetEquityGridLevel_Mutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EditEquityGridLevelSlideOver_SetEquityGridLevel_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "d13d627926a35f8302b4e54eeead1a61",
    "id": null,
    "metadata": {},
    "name": "EditEquityGridLevelSlideOver_SetEquityGridLevel_Mutation",
    "operationKind": "mutation",
    "text": "mutation EditEquityGridLevelSlideOver_SetEquityGridLevel_Mutation(\n  $equityGridLevelId: EquityGridLevelId!\n  $attributes: EquityGridLevelAttributes!\n) {\n  setEquityGridLevel(id: $equityGridLevelId, attributes: $attributes) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "5fa6bae55fbc7aeb72554b9bb9883832";

export default node;
