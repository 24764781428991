import { ArrowRightIcon } from "@heroicons/react/24/outline";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { graphql, useFragment } from "react-relay";
import { generatePath } from "react-router-dom";

import { APPLICATION_ROUTES } from "../../paths";
import { LinkButton } from "../ui/Button";
import { Card } from "../ui/Card";
import { NoticeMessage } from "../ui/NoticeMessage";
import { BoardMemberTasksCard_BoardMember$key } from "./__generated__/BoardMemberTasksCard_BoardMember.graphql";
import { TasksSection, TasksSectionTask } from "./TasksSection";

const BOARD_MEMBER_FRAGMENT = graphql`
  fragment BoardMemberTasksCard_BoardMember on BoardMember {
    pendingSignatureRequestsCount
    id
  }
`;

export const BoardMemberTasksCard: React.FC<{
  boardMemberFragment: BoardMemberTasksCard_BoardMember$key;
}> = ({ boardMemberFragment }) => {
  const boardMember = useFragment(BOARD_MEMBER_FRAGMENT, boardMemberFragment);

  const tasks = useMemo(() => {
    const tasks: TasksSectionTask[] = [];

    if (boardMember.pendingSignatureRequestsCount > 0) {
      tasks.push({
        action: (
          <LinkButton
            rightIcon={<ArrowRightIcon />}
            size="small"
            to={generatePath(APPLICATION_ROUTES.boardMemberApprovals, {
              boardMemberId: boardMember.id,
            })}
            variant="Secondary Full"
          >
            <FormattedMessage
              defaultMessage={`{boardConsentCount, plural,
                one {Review board consent}
                other {Review board consents}
              }`}
              values={{
                boardConsentCount: boardMember.pendingSignatureRequestsCount,
              }}
            />
          </LinkButton>
        ),
        key: "sign_board_consent",
        label: "Board consents are ready and pending your signature",
      });
    }

    return tasks;
  }, [boardMember]);

  const actionCount = useMemo(
    () => boardMember.pendingSignatureRequestsCount,
    [boardMember.pendingSignatureRequestsCount],
  );

  return (
    <Card title="Tasks">
      <div className="space-y-4">
        {tasks.length > 0 ? (
          <TasksSection actionCount={actionCount} tasks={tasks} />
        ) : (
          <NoticeMessage hasColor={false} size="Large" variant="Info">
            You have nothing requiring your attention.
          </NoticeMessage>
        )}
      </div>
    </Card>
  );
};
