import _, { compact, isEmpty, sumBy } from "lodash";
import React, {
  startTransition,
  useCallback,
  useMemo,
  useState,
  useTransition,
} from "react";
import { FormattedMessage } from "react-intl";
import { useFragment, useRefetchableFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { DraftGrantButton } from "../../../../components/DraftGrantButton";
import { EmptyListPlaceholder } from "../../../../components/EmptyListPlaceholder/EmptyListPlaceholder";
import { Page } from "../../../../components/Page";
import { SelectedItemsActionCardWithNumbers } from "../../../../components/SelectedItemsActionCard";
import { useToaster } from "../../../../components/Toaster";
import { Button } from "../../../../components/ui/Button";
import { ConfirmationModalRemote } from "../../../../components/ui/ConfirmationModal";
import { NoticeMessage } from "../../../../components/ui/NoticeMessage";
import { Toast } from "../../../../components/ui/Toast";
import { ValuationWarningMessage } from "../../../../components/ValuationWarningMessage";
import { useDebounced } from "../../../../hooks/useDebounced";
import { useQuery } from "../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import { LegalWorkflowLayout } from "../../../../layouts/LegalWorkflowLayout";
import { useOrganizationIdParam } from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { Drafts_DeleteDrafts_Mutation } from "./__generated__/Drafts_DeleteDrafts_Mutation.graphql";
import {
  Drafts_Organization$data,
  Drafts_Organization$key,
} from "./__generated__/Drafts_Organization.graphql";
import { Drafts_Query } from "./__generated__/Drafts_Query.graphql";
import { Drafts_SendDraftsForReview_Mutation } from "./__generated__/Drafts_SendDraftsForReview_Mutation.graphql";
import { Drafts_Viewer$key } from "./__generated__/Drafts_Viewer.graphql";
import { GrantsTable } from "./GrantsTable";
import { WarningTaxFavoredSubplanExpired } from "./WarningTaxFavoredSubplanExpired";

const VIEWER_FRAGMENT = graphql`
  fragment Drafts_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    isAllowedToManageOrganization(organizationId: $organizationId)
    ...GrantsTable_Account
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment Drafts_Organization on Organization
  @refetchable(queryName: "Drafts_Organization_RefetchQuery")
  @argumentDefinitions(
    organizationId: { type: "OrganizationId!" }
    easopGrantsSearch: { type: "String", defaultValue: "" }
  ) {
    id
    name
    draftEasopGrants: easopGrants(filters: { statusIn: [Draft] }) {
      id
      label
      quantityGranted
      grantee {
        id
      }
      ...WarningTaxFavoredSubplanExpired_EasopGrants
    }
    searchedDraftEasopGrants: easopGrants(
      filters: { statusIn: [Draft], search: $easopGrantsSearch }
    ) {
      ...GrantsTable_EasopGrant @arguments(organizationId: $organizationId)
    }
    valuationWarnings {
      reason
      valuationType
      ...ValuationWarningMessage_ValuationWarning
    }
    ...GrantsTable_Organization
    ...DraftGrantButton_Organization
    ...ValuationWarningMessage_Organization
    ...ValuationWarningMessage_Organization
    ...LegalWorkflowLayout_Organization
    ...WarningTaxFavoredSubplanExpired_Organization
    ...SelectedItemsActionCardWithNumbers_Organization
  }
`;

const SEND_DRAFTS_FOR_REVIEW_MUTATION = graphql`
  mutation Drafts_SendDraftsForReview_Mutation(
    $easopGrantsIds: [UUID!]!
    $organizationId: OrganizationId!
  ) {
    submitEasopGrantsForReview(
      easopGrantsIds: $easopGrantsIds
      organizationId: $organizationId
    ) {
      grantStatus
    }
  }
`;

const DELETE_DRAFTS_MUTATION = graphql`
  mutation Drafts_DeleteDrafts_Mutation(
    $easopGrantsIds: [UUID!]!
    $organizationId: OrganizationId!
  ) {
    deleteEasopGrants(
      easopGrantsIds: $easopGrantsIds
      organizationId: $organizationId
    ) {
      __typename
    }
  }
`;

function DuplicatedDraftsWarningNoticeMessage({
  draftEasopGrants,
}: {
  draftEasopGrants: Drafts_Organization$data["draftEasopGrants"];
}) {
  const potentialDuplicatedDraftsGroupByDuplicates = useMemo(
    () =>
      _(draftEasopGrants)
        .groupBy((draft) => `${draft.grantee.id}-${draft.quantityGranted}`)
        .values()
        .filter((drafts) => drafts.length > 1)
        .value(),
    [draftEasopGrants],
  );

  if (isEmpty(potentialDuplicatedDraftsGroupByDuplicates)) {
    return null;
  }

  return (
    <NoticeMessage size="Large" variant="Warning">
      Check that following drafts are not unwanted duplicates:
      <ul className="list-inside list-disc pl-2">
        {potentialDuplicatedDraftsGroupByDuplicates.map((drafts, index) => (
          <li key={`duplicates-${index}`}>
            {drafts.map((draft) => draft.label).join(", ")}
          </li>
        ))}
      </ul>
    </NoticeMessage>
  );
}

const AdminEquityPrepareYourGrantsDraftsPage_: React.FC<{
  organizationFragment: Drafts_Organization$key;
  viewerFragment: Drafts_Viewer$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const [organization, refetchOrganization] = useRefetchableFragment(
    ORGANIZATION_FRAGMENT,
    organizationFragment,
  );
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  const [grantSelection, setGrantSelection] = useState<Set<string>>(new Set());
  const toaster = useToaster();
  const selectedGrants = useMemo(
    () =>
      organization.draftEasopGrants.filter((grant) =>
        grantSelection.has(grant.id),
      ),
    [grantSelection, organization.draftEasopGrants],
  );

  const [sendDraftsForReview, sendDraftsForReviewIsInFlight] =
    useSafeMutation<Drafts_SendDraftsForReview_Mutation>(
      SEND_DRAFTS_FOR_REVIEW_MUTATION,
    );
  const [deleteDrafts, deleteDraftsIsInFlight] =
    useSafeMutation<Drafts_DeleteDrafts_Mutation>(DELETE_DRAFTS_MUTATION);

  const [tableDataTransitionIsInProgress, startTableDataTransition] =
    useTransition();

  const {
    isDebouncing: searchIsDebouncing,
    liveState: searchInputValue,
    setState: setSearchInputValue,
  } = useDebounced<string>({
    delay: 1000,
    initialState: "",
    onDebounce: useCallback(
      (search: string) => {
        startTableDataTransition(() => {
          refetchOrganization({
            easopGrantsSearch: search,
          });
        });
      },
      [refetchOrganization],
    ),
  });

  const handleDataUpdated = useCallback(() => {
    startTransition(() => {
      refetchOrganization({
        easopGrantsSearch: searchInputValue,
      });
    });
  }, [refetchOrganization, searchInputValue]);

  const handleSendDraftsForReviewButtonClick = useCallback(async () => {
    const easopGrantsIds = selectedGrants.map((grant) => grant.id);
    await sendDraftsForReview({
      variables: {
        easopGrantsIds,
        organizationId: organization.id,
      },
    });

    handleDataUpdated();

    setGrantSelection(new Set());

    toaster.push(
      <Toast title="Wonderful!">
        <FormattedMessage
          defaultMessage={`{grantCount, plural,
            one {# draft}
            other {# drafts}
          } successfully sent for review!`}
          values={{
            grantCount: easopGrantsIds.length,
          }}
        />
      </Toast>,
    );
  }, [
    selectedGrants,
    sendDraftsForReview,
    organization.id,
    handleDataUpdated,
    toaster,
  ]);

  const handleDeleteDrafts = useCallback(async () => {
    const easopGrantsIds = selectedGrants.map((grant) => grant.id);
    await deleteDrafts({
      variables: {
        easopGrantsIds,
        organizationId: organization.id,
      },
    });

    handleDataUpdated();

    setGrantSelection(new Set());

    toaster.push(
      <Toast title="Done!">
        <FormattedMessage
          defaultMessage={`{grantCount, plural,
            one {# draft}
            other {# drafts}
          } deleted!`}
          values={{
            grantCount: easopGrantsIds.length,
          }}
        />
      </Toast>,
    );
  }, [
    selectedGrants,
    deleteDrafts,
    organization.id,
    handleDataUpdated,
    toaster,
  ]);

  const someGrantsAreSelected = !isEmpty(selectedGrants);

  const formIsReadyToBeSubmitted = someGrantsAreSelected;

  const selectedItemsShares = useMemo(() => {
    return sumBy(selectedGrants, (grant) => grant.quantityGranted);
  }, [selectedGrants]);

  return (
    <LegalWorkflowLayout
      actionButton={
        viewer.isAllowedToManageOrganization && (
          <DraftGrantButton
            onGrantCreated={handleDataUpdated}
            organizationFragment={organization}
          >
            Create new
          </DraftGrantButton>
        )
      }
      onSearchChange={setSearchInputValue}
      organizationFragment={organization}
      searchIsLoading={tableDataTransitionIsInProgress || searchIsDebouncing}
      searchPlaceholder="Search drafts..."
      searchValue={searchInputValue}
      subtitle="Overview of all your current draft option grants"
      title="Drafts"
      topBarActionsRender={({ mainContentIsScrolled }) => {
        if (!viewer.isAllowedToManageOrganization) return null;

        return (
          <SelectedItemsActionCardWithNumbers
            actions={
              <div className="flex gap-2">
                <ConfirmationModalRemote.Controller
                  render={({ open }) => (
                    <Button
                      disabled={!formIsReadyToBeSubmitted}
                      onClick={() => {
                        open({
                          data: {
                            cancelLabel: "Cancel",
                            children: (
                              <div>
                                This action will permanently delete{" "}
                                {selectedGrants.length} grants.
                                <br />
                                Are you sure you want to proceed?
                              </div>
                            ),
                            confirmationLabel: "Delete",
                            title: `Delete ${selectedGrants.length} grants`,
                          },
                          onClose: ({ confirmed }) => {
                            if (!confirmed) return;

                            return handleDeleteDrafts();
                          },
                        });
                      }}
                      size="small"
                      variant="Danger Outline"
                    >
                      {selectedGrants.length === 0 ? (
                        "Delete"
                      ) : (
                        <>Delete {selectedGrants.length} selected</>
                      )}
                    </Button>
                  )}
                />
                <Button
                  disabled={!formIsReadyToBeSubmitted}
                  loading={sendDraftsForReviewIsInFlight}
                  onClick={handleSendDraftsForReviewButtonClick}
                  size="small"
                >
                  {selectedGrants.length === 0 ? (
                    "Review"
                  ) : (
                    <>Review {selectedGrants.length} selected</>
                  )}
                </Button>
              </div>
            }
            compact={mainContentIsScrolled}
            emptyListLabel="Select drafts"
            loading={sendDraftsForReviewIsInFlight || deleteDraftsIsInFlight}
            organizationFragment={organization}
            shares={selectedItemsShares}
          />
        );
      }}
      warnings={
        <>
          <WarningTaxFavoredSubplanExpired
            easopGrantsFragment={organization.draftEasopGrants}
            organizationFragment={organization}
          />

          {organization.valuationWarnings.map((warning) => (
            <ValuationWarningMessage
              key={`${warning.valuationType}-${warning.reason}`}
              onValuationSentForReview={handleDataUpdated}
              organizationFragment={organization}
              valuationWarningFragment={warning}
            />
          ))}

          <DuplicatedDraftsWarningNoticeMessage
            draftEasopGrants={organization.draftEasopGrants}
          />
        </>
      }
    >
      {isEmpty(organization.draftEasopGrants) ? (
        <EmptyListPlaceholder
          action={
            viewer.isAllowedToManageOrganization && (
              <DraftGrantButton
                onGrantCreated={handleDataUpdated}
                organizationFragment={organization}
              >
                Create new
              </DraftGrantButton>
            )
          }
          subtitle="Feel free to create new grants or plan equity from your planning"
          title="No drafts at the moment"
        />
      ) : (
        <GrantsTable
          columns={compact([
            "Draft",
            "Employee",
            "Ownership",
            "Details",
            "Strike price",
            "Grant type",
            viewer.isAllowedToManageOrganization && "Actions",
          ])}
          easopGrantsFragment={organization.searchedDraftEasopGrants}
          emptyListPlaceholder="No drafts match your search"
          grantSelection={grantSelection}
          loading={
            sendDraftsForReviewIsInFlight ||
            deleteDraftsIsInFlight ||
            tableDataTransitionIsInProgress
          }
          onGrantSelectionChange={setGrantSelection}
          onUpdate={handleDataUpdated}
          organizationFragment={organization}
          viewerFragment={viewer}
        />
      )}
    </LegalWorkflowLayout>
  );
};

const QUERY = graphql`
  query Drafts_Query($organizationId: OrganizationId!) {
    me {
      ...Drafts_Viewer @arguments(organizationId: $organizationId)
    }
    organization(id: $organizationId) {
      id
      name
      ...Drafts_Organization @arguments(organizationId: $organizationId)
    }
  }
`;

const AdminEquityPrepareYourGrantsDraftsPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<Drafts_Query>(QUERY, {
    organizationId,
  });

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="New Equity Flow"
      analyticsName="Admin - New Equity Drafts"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} draft grants`}
    >
      <AdminEquityPrepareYourGrantsDraftsPage_
        organizationFragment={query.organization}
        viewerFragment={query.me}
      />
    </Page>
  );
};

export default AdminEquityPrepareYourGrantsDraftsPage;
