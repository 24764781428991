import { Pill } from "@remote-com/norma";
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { compact } from "lodash";
import React, { useMemo } from "react";
import { FormattedNumber } from "react-intl";
import { graphql, useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";

import { EmptyState } from "../../../components/EmptyState";
import { FormattedCurrency } from "../../../components/Formatted/FormattedCurrency";
import { GranteeNameWithCountryFlag } from "../../../components/GranteeNameWithCountryFlag";
import { LongDate } from "../../../components/LongDate";
import { ShortDate } from "../../../components/ShortDate";
import { Table } from "../../../components/ui/Table";
import { Typography } from "../../../components/ui/Typography";
import { APPLICATION_ROUTES } from "../../../paths";
import { WORK_RELATIONSHIP_TO_LABEL_HELPER } from "../../../services/workRelationship";
import {
  ExercisesTable_Exercises$data,
  ExercisesTable_Exercises$key,
} from "./__generated__/ExercisesTable_Exercises.graphql";
import { ExercisesTable_Organization$key } from "./__generated__/ExercisesTable_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment ExercisesTable_Organization on Organization {
    id
    isRemoteEquityEssentials
    ...FormattedCurrency_Organization
  }
`;

const EASOP_GRANTEES_FRAGMENT = graphql`
  fragment ExercisesTable_Exercises on CTMSExerciseRequest
  @relay(plural: true) {
    ctmsId
    ctmsGrantLabel
    status
    isInProgress
    requestSubmittedAt
    totalExercisePrice
    quantityExercised
    spread
    fundsTransferredAt

    ctmsGrant {
      grantee {
        name
        workRelationship
        ...GranteeNameWithCountryFlag_Grantee
      }
    }
  }
`;

const GRANT_STATUS_TO_LABEL_MAP: Record<
  ExercisesTable_Exercises$data[number]["status"],
  string
> = {
  Cancelled: "Canceled",
  Done: "Done",
  PendingApproval: "Pending approval",
  WaitingForCertificateSignature: "Waiting for certificate signature",
  WaitingForFunds: "Waiting for Funds",
};

const columnHelper =
  createColumnHelper<ExercisesTable_Exercises$data[number]>();

export const ExercisesTable: React.FC<{
  exercisesFragment: ExercisesTable_Exercises$key;
  exerciseStatus: "Cancelled" | "Done" | "InProgress";
  organizationFragment: ExercisesTable_Organization$key;
}> = ({ exercisesFragment, exerciseStatus, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const exercises = useFragment(EASOP_GRANTEES_FRAGMENT, exercisesFragment);

  const data = useMemo(
    () =>
      exercises.filter(
        (exercise) =>
          (exerciseStatus === "InProgress" && exercise.isInProgress) ||
          exercise.status === exerciseStatus,
      ),
    [exercises, exerciseStatus],
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.ctmsGrantLabel, {
        cell: (context) => <Pill tone="bayoux">{context.getValue()}</Pill>,
        enableGlobalFilter: true,
        enableSorting: true,
        header: () => "Grant",
        id: "grantLabel",
        sortingFn: "text",
      }),
      columnHelper.accessor((row) => row.ctmsGrant.grantee.name, {
        cell: (context) => (
          <div className="space-y-1">
            <GranteeNameWithCountryFlag
              granteeFragment={context.row.original.ctmsGrant.grantee}
            />
            {context.row.original.ctmsGrant.grantee.workRelationship && (
              <Typography
                as="div"
                className="text-grey-500"
                variant="Regular/Caption"
              >
                {
                  WORK_RELATIONSHIP_TO_LABEL_HELPER[
                    context.row.original.ctmsGrant.grantee.workRelationship
                  ].singularLabel
                }
              </Typography>
            )}
          </div>
        ),
        enableGlobalFilter: true,
        enableSorting: true,
        header: () => "Employee",
        id: "employee",
        sortingFn: "text",
      }),
      columnHelper.accessor((row) => row.requestSubmittedAt, {
        cell: (context) => <LongDate value={context.getValue()} />,
        enableGlobalFilter: true,
        enableSorting: true,
        header: () => "Request date",
        id: "requestDate",
        sortingFn: "datetime",
      }),
      columnHelper.accessor((row) => row.totalExercisePrice, {
        cell: (context) => {
          const value = context.getValue();
          return (
            <>
              {typeof value === "number" ? (
                <FormattedCurrency
                  organizationFragment={organization}
                  value={value}
                />
              ) : (
                "-"
              )}
            </>
          );
        },
        enableGlobalFilter: true,
        enableSorting: true,
        header: () => "Exercise price",
        id: "exercisePrice",
        sortingFn: "basic",
      }),
      columnHelper.accessor((row) => row.quantityExercised, {
        cell: (context) => <FormattedNumber value={context.getValue()} />,
        enableGlobalFilter: true,
        enableSorting: true,
        header: () => "Quantity exercised",
        id: "quantityExercised",
        sortingFn: "basic",
      }),
      columnHelper.accessor((row) => row.spread, {
        cell: (context) => {
          const value = context.getValue();
          return (
            <>
              {typeof value === "number" ? (
                <>
                  <FormattedCurrency
                    organizationFragment={organization}
                    value={value}
                  />
                </>
              ) : (
                "-"
              )}
            </>
          );
        },
        enableGlobalFilter: true,
        enableSorting: true,
        header: () => "Spread",
        id: "spread",
        sortingFn: "basic",
      }),
      exerciseStatus === "InProgress" &&
        columnHelper.accessor((row) => row.status, {
          cell: (context) => {
            const value = context.getValue();
            return (
              <div className="text-right">
                <Pill tone={value === "WaitingForFunds" ? "warning" : "bayoux"}>
                  {GRANT_STATUS_TO_LABEL_MAP[value]}
                </Pill>
              </div>
            );
          },
          enableGlobalFilter: true,
          enableSorting: true,
          header: () => "Status",
          id: "status",
          meta: {
            alignRight: true,
          },
          sortingFn: "basic",
        }),
      exerciseStatus === "Done" &&
        columnHelper.accessor((row) => row.fundsTransferredAt, {
          cell: (context) => {
            const value = context.getValue();
            return (
              <div className="text-right">
                {value ? <ShortDate value={value} /> : "-"}
              </div>
            );
          },
          enableGlobalFilter: true,
          enableSorting: true,
          header: () => "Funds transferred at",
          id: "fundsTransferredAt",
          meta: {
            alignRight: true,
          },
          sortingFn: "datetime",
        }),
    ],
    [exerciseStatus, organization],
  );

  const table = useReactTable({
    columns: compact(columns),
    data,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (data.length === 0) {
    switch (exerciseStatus) {
      case "Cancelled":
        return (
          <EmptyState>
            There are no canceled option exercises declarations
          </EmptyState>
        );
      case "Done":
        return (
          <EmptyState>
            There are no completed option exercises declarations
          </EmptyState>
        );
      case "InProgress":
        return (
          <EmptyState
            callToAction={
              organization.isRemoteEquityEssentials ? (
                <Link
                  to={generatePath(
                    APPLICATION_ROUTES.organizationDeclareNewExerciseRequests,
                    {
                      organizationId: organization.id,
                    },
                  )}
                >
                  Declare a new exercise
                </Link>
              ) : null
            }
          >
            There are no active option exercises declarations
          </EmptyState>
        );
    }
  }

  return (
    <Table.Smart
      rowRenderer={({ cells, rowData }) => {
        if (organization.isRemoteEquityEssentials) {
          return <Table.Row>{cells}</Table.Row>;
        }

        return (
          <Table.LinkRow
            to={generatePath(
              APPLICATION_ROUTES.organizationExerciseRequestDetails,
              {
                ctmsExerciseRequestCTMSId: rowData.original.ctmsId,
                organizationId: organization.id,
              },
            )}
          >
            {cells}
          </Table.LinkRow>
        );
      }}
      table={table}
      tableLayout="fixed"
    />
  );
};
