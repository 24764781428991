/**
 * @generated SignedSource<<ce3e67812a8f6906e5cfd2547817d7ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VestingScheduleLegend_CTMSGrants$data = ReadonlyArray<{
  readonly label: string;
  readonly " $fragmentType": "VestingScheduleLegend_CTMSGrants";
}>;
export type VestingScheduleLegend_CTMSGrants$key = ReadonlyArray<{
  readonly " $data"?: VestingScheduleLegend_CTMSGrants$data;
  readonly " $fragmentSpreads": FragmentRefs<"VestingScheduleLegend_CTMSGrants">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "VestingScheduleLegend_CTMSGrants",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "bad01efe3f75ac9d7f92ace62265bac7";

export default node;
