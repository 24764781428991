import { CalendarIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { CtmsGrantActiveOrTerminatedTag } from "../../../../components/CtmsGrantActiveOrTerminatedTag";
import { CtmsGrantVestingTag } from "../../../../components/CtmsGrantVestingTag";
import { Deprecated_VestingGraph } from "../../../../components/Deprecated_VestingGraph";
import { DetailsBox } from "../../../../components/DetailsBox";
import { MissingCTMSInformationTag } from "../../../../components/MissingInformationTag";
import { ShortDate } from "../../../../components/ShortDate";
import { InformationRows } from "../../../../components/ui/InformationRows";
import { NoticeMessage } from "../../../../components/ui/NoticeMessage";
import { Typography } from "../../../../components/ui/Typography";
import { VestingOverview } from "../../../../components/VestingOverview";
import { ACCELERATION_CLAUSE_LABELS } from "../../../../services/AccelerationClause";
import { CtmsGrantDetailsVestingSchedule_CTMSGrant$key } from "./__generated__/CtmsGrantDetailsVestingSchedule_CTMSGrant.graphql";
import { CtmsGrantDetailsVestingSchedule_Organization$key } from "./__generated__/CtmsGrantDetailsVestingSchedule_Organization.graphql";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment CtmsGrantDetailsVestingSchedule_CTMSGrant on CTMSGrant {
    label
    vestingStartDate
    isActive
    vestingScheduleName
    accelerationClause
    vestingDataPoints {
      cumulativeVested
      date
    }
    ...CtmsGrantVestingTag_CtmsGrant
    ...CtmsGrantActiveOrTerminatedTag_CtmsGrant
    ...VestingOverview_CTMSGrant
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment CtmsGrantDetailsVestingSchedule_Organization on Organization {
    ...MissingInformationTag_MissingCTMSInformationTag_Organization
  }
`;

export const CtmsGrantDetailsVestingSchedule: React.FC<{
  ctmsGrantFragment: CtmsGrantDetailsVestingSchedule_CTMSGrant$key;
  organizationFragment: CtmsGrantDetailsVestingSchedule_Organization$key;
}> = ({ ctmsGrantFragment, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);

  return (
    <DetailsBox Icon={CalendarIcon} title="Vesting schedule">
      <Typography variant="Medium/Small">Vesting schedule</Typography>
      {ctmsGrant.isActive ? (
        <div className="py-4">
          {ctmsGrant.vestingDataPoints[0] &&
          ctmsGrant.vestingDataPoints.some(
            (vestingDataPoint) => vestingDataPoint.cumulativeVested > 0,
          ) ? (
            <Deprecated_VestingGraph
              showLegend={false}
              vestingsDataPoints={[
                {
                  dataPoints: ctmsGrant.vestingDataPoints.map(
                    ({ cumulativeVested, date }) => ({
                      cumulativeVested,
                      date: new Date(date),
                    }),
                  ),
                  key: ctmsGrant.label,
                  label: ctmsGrant.label,
                },
              ]}
              vestingStartDate={ctmsGrant.vestingDataPoints[0].date}
            />
          ) : (
            <div className="flex">
              <MissingCTMSInformationTag
                className="m-auto block"
                organizationFragment={organization}
              />
            </div>
          )}
        </div>
      ) : (
        <NoticeMessage className="mt-4" size="Small">
          Grantee is no longer vesting shares
        </NoticeMessage>
      )}
      <InformationRows>
        <InformationRows.Row label="Vesting status">
          {!ctmsGrant.isActive ? (
            <CtmsGrantActiveOrTerminatedTag ctmsGrantFragment={ctmsGrant} />
          ) : (
            <CtmsGrantVestingTag ctmsGrantFragment={ctmsGrant} />
          )}
        </InformationRows.Row>
        <InformationRows.Row label="Vesting schedule">
          {ctmsGrant.vestingScheduleName ? (
            ctmsGrant.vestingScheduleName
          ) : (
            <MissingCTMSInformationTag organizationFragment={organization} />
          )}
        </InformationRows.Row>
        <InformationRows.Row label="Vesting overview">
          <VestingOverview ctmsGrantFragment={ctmsGrant} />
        </InformationRows.Row>
        <InformationRows.Row label="Start date">
          {ctmsGrant.vestingStartDate ? (
            <ShortDate value={ctmsGrant.vestingStartDate} />
          ) : (
            <MissingCTMSInformationTag organizationFragment={organization} />
          )}
        </InformationRows.Row>
        <InformationRows.Row label="Acceleration clause">
          {ctmsGrant.accelerationClause ? (
            <>
              {ACCELERATION_CLAUSE_LABELS[ctmsGrant.accelerationClause]}{" "}
              acceleration
            </>
          ) : (
            <>No acceleration</>
          )}
        </InformationRows.Row>
      </InformationRows>
    </DetailsBox>
  );
};
