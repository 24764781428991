/**
 * @generated SignedSource<<c269895d38210044d2c3e5575d289d51>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VestingGraph_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FormattedCurrency_Organization" | "useComputeSharesNetEquityValue_Organization" | "useCurrencyFormatter_Organization">;
  readonly " $fragmentType": "VestingGraph_Organization";
};
export type VestingGraph_Organization$key = {
  readonly " $data"?: VestingGraph_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"VestingGraph_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VestingGraph_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useComputeSharesNetEquityValue_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedCurrency_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useCurrencyFormatter_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "a7da71a26b4ebe72d774a5e88cbd96bc";

export default node;
