import { Button, DropdownMenu } from "@remote-com/norma";
import { IconV2OutlineCheck } from "@remote-com/norma/icons/IconV2OutlineCheck";
import { IconV2OutlineChevronDown } from "@remote-com/norma/icons/IconV2OutlineChevronDown";
import { useCallback, useState } from "react";
import { useFragment } from "react-relay";
import { generatePath, Outlet } from "react-router-dom";
import { graphql } from "relay-runtime";

import { CreateGranteeButton } from "../../../components/CreateGranteeButton";
import { LargeOneColumnLayout } from "../../../components/ui/Layout/LargeOneColumnLayout";
import { useQuery } from "../../../hooks/useQuery";
import { useSafeMutation } from "../../../hooks/useSafeMutation";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import {
  AdminGranteesLayout_Organization$data,
  AdminGranteesLayout_Organization$key,
} from "./__generated__/AdminGranteesLayout_Organization.graphql";
import { AdminGranteesLayout_Query } from "./__generated__/AdminGranteesLayout_Query.graphql";
import {
  AdminGranteesLayout_Viewer$data,
  AdminGranteesLayout_Viewer$key,
} from "./__generated__/AdminGranteesLayout_Viewer.graphql";
import { HRISIntegrationNotice } from "./HRISIntegrationNotice";
import { SuspensedTabList } from "./SuspensedTabList";

const ORGANIZATION_FRAGMENT = graphql`
  fragment AdminGranteesLayout_Organization on Organization {
    id
    isConnectedToHRISProvider
    isOriginatingFromRemoteEquity
    isRemoteEquityEssentials
    ...CreateGranteeButton_Organization
    ...SuspensedTabList_Organization @defer
    ...LargeOneColumnLayout_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment AdminGranteesLayout_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    isAllowedToManageOrganization(organizationId: $organizationId)
  }
`;

const REFRESH_ORGANIZATION_HRIS_PROVIDERS_DATA_MUTATION = graphql`
  mutation AdminGranteesLayout_RefreshOrganizationHRISProvidersDataMutation(
    $organizationId: OrganizationId!
  ) {
    refreshOrganizationHRISProvidersData(organizationId: $organizationId)
  }
`;

const TopBarActions: React.FC<{
  employeesSynced: boolean;
  onSyncEmployeesClicked: () => void;
  organization: AdminGranteesLayout_Organization$data;
  syncingEmployees: boolean;
  viewer: AdminGranteesLayout_Viewer$data;
}> = ({
  employeesSynced,
  onSyncEmployeesClicked,
  organization,
  syncingEmployees,
  viewer,
}) => {
  if (!viewer.isAllowedToManageOrganization) return null;

  if (organization.isRemoteEquityEssentials) {
    return (
      <>
        <Button
          disabled={employeesSynced}
          IconBefore={employeesSynced ? IconV2OutlineCheck : undefined}
          isLoading={syncingEmployees}
          onClick={onSyncEmployeesClicked}
          size="md"
          tone="secondary"
        >
          {employeesSynced ? "Employees synced" : "Sync employees"}
        </Button>
        <DropdownMenu
          // @ts-expect-error className and trigger not defined on DropdownMenu props
          className="z-10"
          trigger={
            <Button IconAfter={IconV2OutlineChevronDown} size="md">
              Declare new
            </Button>
          }
        >
          <DropdownMenu.Item
            href={generatePath(APPLICATION_ROUTES.organizationDeclareNewGrant, {
              organizationId: organization.id,
            })}
          >
            Declare grant
          </DropdownMenu.Item>
          <DropdownMenu.Item
            href={generatePath(
              APPLICATION_ROUTES.organizationDeclareNewExerciseRequests,
              {
                organizationId: organization.id,
              },
            )}
          >
            Declare exercise
          </DropdownMenu.Item>
        </DropdownMenu>
      </>
    );
  }

  return (
    <CreateGranteeButton mode="new-page" organizationFragment={organization} />
  );
};

const AdminGranteesLayout_: React.FC<{
  organizationFragment: AdminGranteesLayout_Organization$key;
  viewerFragment: AdminGranteesLayout_Viewer$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  const [employeesSynced, setEmployeesSynced] = useState(false);
  const [refreshOrganizationHRISProvidersData, syncingEmployees] =
    useSafeMutation(REFRESH_ORGANIZATION_HRIS_PROVIDERS_DATA_MUTATION);

  const onSyncEmployeesClicked = useCallback(async () => {
    await refreshOrganizationHRISProvidersData({
      variables: {
        organizationId: organization.id,
      },
    });
    setEmployeesSynced(true);
  }, [refreshOrganizationHRISProvidersData, organization.id]);

  return (
    <LargeOneColumnLayout
      organizationFragment={organization}
      title="Team overview"
      topBarActionsRender={() => (
        <TopBarActions
          employeesSynced={employeesSynced}
          onSyncEmployeesClicked={onSyncEmployeesClicked}
          organization={organization}
          syncingEmployees={syncingEmployees}
          viewer={viewer}
        />
      )}
    >
      <div className="space-y-6">
        <SuspensedTabList organizationFragment={organization} />
        {viewer.isAllowedToManageOrganization &&
          !organization.isOriginatingFromRemoteEquity &&
          !organization.isConnectedToHRISProvider && (
            <HRISIntegrationNotice organizationId={organization.id} />
          )}
        <Outlet />
      </div>
    </LargeOneColumnLayout>
  );
};

const QUERY = graphql`
  query AdminGranteesLayout_Query($organizationId: OrganizationId!) {
    me {
      ...AdminGranteesLayout_Viewer @arguments(organizationId: $organizationId)
    }
    organization(id: $organizationId) {
      ...AdminGranteesLayout_Organization
    }
  }
`;

const AdminGranteesLayout: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<AdminGranteesLayout_Query>(QUERY, {
    organizationId,
  });

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <AdminGranteesLayout_
      organizationFragment={query.organization}
      viewerFragment={query.me}
    />
  );
};

export default AdminGranteesLayout;
