/**
 * @generated SignedSource<<d7df2bfb90329f33e72ab79ed5154818>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Projection_EquityOffer$data = {
  readonly instrument: {
    readonly isVirtual: boolean;
  } | null;
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"ProjectionCard_Organization">;
  };
  readonly organizationLatestPricePerShare: {
    readonly value: number;
  } | null;
  readonly projectionScenarios: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"ProjectionCard_GranteePortalProjectionScenarios">;
  }>;
  readonly shares: number | null;
  readonly " $fragmentType": "Projection_EquityOffer";
};
export type Projection_EquityOffer$key = {
  readonly " $data"?: Projection_EquityOffer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Projection_EquityOffer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Projection_EquityOffer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PricePerShare",
      "kind": "LinkedField",
      "name": "organizationLatestPricePerShare",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "instrument",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isVirtual",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GranteePortalProjectionScenario",
      "kind": "LinkedField",
      "name": "projectionScenarios",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectionCard_GranteePortalProjectionScenarios"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectionCard_Organization"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "IEquityOffer",
  "abstractKey": "__isIEquityOffer"
};

(node as any).hash = "420d022e485c23bdecb9bbf9686ddcbe";

export default node;
