import {
  Drawer,
  DrawerHeader,
  DrawerLayout,
  DrawerPane,
  DrawerTitle,
  ListDisc,
  Text,
} from "@remote-com/norma";

export const FairMarketValueExplanationDrawer: React.FC<{
  children: React.ReactElement;
}> = ({ children }) => {
  return (
    <Drawer
      $layout="fullWidth"
      aria-labelledby="fair-market-value-explanation-drawer-title"
      Trigger={children}
    >
      <DrawerLayout>
        <DrawerHeader>
          <DrawerTitle id="fair-market-value-explanation-drawer-title">
            Fair market value of one share
          </DrawerTitle>
        </DrawerHeader>
        <DrawerPane>
          <div className="space-y-10">
            <Text className="text-grey-600" variant="sm">
              There is no universal method to determine the fair market value of
              a private company, because there is no trading price.
            </Text>
            <div className="space-y-3 text-grey-600">
              <Text className="uppercase" variant="xsSemiBold">
                Consider the following:
              </Text>
              <ListDisc>
                <li>
                  <Text className="text-grey-900" variant="smMedium">
                    If your company is based in the U.S.
                  </Text>
                  <ListDisc.Nest>
                    <ListDisc>
                      <li>
                        You can use the per share valuation set out in your
                        company&apos;s latest 409A valuation.
                      </li>
                    </ListDisc>
                  </ListDisc.Nest>
                </li>
                <li>
                  <Text className="text-grey-900" variant="smMedium">
                    If your company is not based in the U.S.
                  </Text>
                  <ListDisc.Nest>
                    <ListDisc>
                      <li>
                        You can choose the per share valuation that you would
                        use for tax reporting of gains resulting from equity
                        incentives. Those valuations include valuations based on
                        a recent auditor report, on a transaction between
                        shareholders or on the price of a recent financing
                        round.
                      </li>
                    </ListDisc>
                  </ListDisc.Nest>
                </li>
                <li>
                  <Text className="text-grey-900" variant="smMedium">
                    If your company issued both preferred shares and common
                    shares
                  </Text>
                  <ListDisc.Nest>
                    <ListDisc>
                      <li>
                        You should use here the valuation of the common shares.
                      </li>
                    </ListDisc>
                  </ListDisc.Nest>
                </li>
              </ListDisc>
            </div>
          </div>
        </DrawerPane>
      </DrawerLayout>
    </Drawer>
  );
};
