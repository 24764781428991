import React, { startTransition, useCallback, useState } from "react";
import { useFragment, useRefetchableFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { Page } from "../../../components/Page";
import { useQuery } from "../../../hooks/useQuery";
import { useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import CTMSPage from "../EssentialsOnboarding/CTMSPage/CTMSPage";
import ExpectationPage from "../EssentialsOnboarding/ExpectationPage";
import FairMarketValuePage from "../EssentialsOnboarding/FairMarketValuePage/FairMarketValuePage";
import IssuingCompanyPage from "../EssentialsOnboarding/IssuingCompanyPage/IssuingCompanyPage";
import KeyEquityPlansParametersPage from "../EssentialsOnboarding/KeyEquityPlansParametersPage/KeyEquityPlansParametersPage";
import LastStepPage from "../EssentialsOnboarding/LastStepPage/LastStepPage";
import { FinishRemoteEquityOnboardingPage_Organization$key } from "./__generated__/FinishRemoteEquityOnboardingPage_Organization.graphql";
import {
  FinishRemoteEquityOnboardingPage_Query,
  RemoteOnboardingStep,
} from "./__generated__/FinishRemoteEquityOnboardingPage_Query.graphql";
import { FinishRemoteEquityOnboardingPage_Viewer$key } from "./__generated__/FinishRemoteEquityOnboardingPage_Viewer.graphql";
import { ConnectCTMSView } from "./ConnectCTMSView";
import { MatchGranteeView } from "./MatchGranteeView";
import { SubmitPricePerShareView } from "./SubmitPricePerShareView";
import { TestLawFirmCartaConnectionView } from "./TestLawFirmCartaConnectionView";

const ORGANIZATION_FRAGMENT = graphql`
  fragment FinishRemoteEquityOnboardingPage_Organization on Organization
  @refetchable(
    queryName: "FinishRemoteEquityOnboardingPage_Organization_RefetchQuery"
  ) {
    currentRemoteOnboardingStep
    ...ConnectCTMSView_Organization
    ...SubmitPricePerShareView_Organization
    ...MatchGranteeView_Organization
    ...TestLawFirmCartaConnectionView_Organization
    ...ExpectationPage_Organization
    ...CTMSPage_Organization
    ...LastStepPage_Organization
    ...IssuingCompanyPage_Organization
    ...KeyEquityPlansParametersPage_Organization
    ...FairMarketValuePage_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment FinishRemoteEquityOnboardingPage_Viewer on Account {
    ...ConnectCTMSView_Viewer
    ...SubmitPricePerShareView_Viewer
    ...TestLawFirmCartaConnectionView_Viewer
    ...MatchGranteeView_Viewer
  }
`;

export const ADVANCED_ONBOARDING_STEPS = [
  "CONNECT_CTMS",
  "SET_PRICE_PER_SHARE",
  "TEST_LAW_FIRM_CARTA_CONNECTION",
  "CLEAN_UP_GRANTEE_DATA",
] as const;

type AdvancedOnboardingStep = (typeof ADVANCED_ONBOARDING_STEPS)[number];

function isAdvancedOnboardingStep(
  step: RemoteOnboardingStep,
): step is AdvancedOnboardingStep {
  return ADVANCED_ONBOARDING_STEPS.includes(step as AdvancedOnboardingStep);
}

const AdvancedOnboardingStep_: React.FC<{
  organizationFragment: FinishRemoteEquityOnboardingPage_Organization$key;
  step: RemoteOnboardingStep;
  viewerFragment: FinishRemoteEquityOnboardingPage_Viewer$key;
}> = ({ organizationFragment, step, viewerFragment }) => {
  const [organization, refetchOrganization] = useRefetchableFragment(
    ORGANIZATION_FRAGMENT,
    organizationFragment,
  );
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  if (!isAdvancedOnboardingStep(step)) {
    throw new Error("Organization is not advanced");
  }

  const onStepCompleted = useCallback(() => {
    startTransition(() => {
      refetchOrganization({});
    });
  }, [refetchOrganization]);

  switch (step) {
    case "CLEAN_UP_GRANTEE_DATA":
      return (
        <MatchGranteeView
          organizationFragment={organization}
          viewerFragment={viewer}
        />
      );
    case "CONNECT_CTMS":
      return (
        <ConnectCTMSView
          organizationFragment={organization}
          viewerFragment={viewer}
        />
      );
    case "SET_PRICE_PER_SHARE":
      return (
        <SubmitPricePerShareView
          onStepCompleted={onStepCompleted}
          organizationFragment={organization}
          viewerFragment={viewer}
        />
      );
    case "TEST_LAW_FIRM_CARTA_CONNECTION":
      return (
        <TestLawFirmCartaConnectionView
          organizationFragment={organization}
          skippable
          viewerFragment={viewer}
        />
      );
  }
};

const AdvancedOnboardingStep: React.FC<{
  organizationFragment: FinishRemoteEquityOnboardingPage_Organization$key;
  step: null | RemoteOnboardingStep;
  viewerFragment: FinishRemoteEquityOnboardingPage_Viewer$key;
}> = ({ organizationFragment, step, viewerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  if (!step) {
    return (
      <MatchGranteeView
        organizationFragment={organization}
        viewerFragment={viewer}
      />
    );
  }
  return (
    <AdvancedOnboardingStep_
      organizationFragment={organizationFragment}
      step={step}
      viewerFragment={viewerFragment}
    />
  );
};

const ORDERED_ESSENTIAL_ONBOARDING_STEPS = [
  "ESSENTIALS_EXPECTATION",
  "ESSENTIALS_ISSUING_COMPANY",
  "ESSENTIALS_CTMS",
  "ESSENTIALS_FAIR_MARKET_VALUE",
  "ESSENTIALS_EQUITY_PLAN_PARAMETERS",
  "ESSENTIALS_LAST_STEP",
] as const;

type EssentialOnboardingStep =
  (typeof ORDERED_ESSENTIAL_ONBOARDING_STEPS)[number];

function getEssentialOnboardingNextStep(
  step: EssentialOnboardingStep,
): EssentialOnboardingStep | undefined {
  return ORDERED_ESSENTIAL_ONBOARDING_STEPS[
    getEssentialOnboardingStepIndex(step) + 1
  ];
}

function getEssentialOnboardingPreviousStep(
  step: EssentialOnboardingStep,
): EssentialOnboardingStep | undefined {
  return ORDERED_ESSENTIAL_ONBOARDING_STEPS[
    getEssentialOnboardingStepIndex(step) - 1
  ];
}

function getEssentialOnboardingStepIndex(
  step: EssentialOnboardingStep,
): number {
  return ORDERED_ESSENTIAL_ONBOARDING_STEPS.indexOf(step);
}

function isEssentialOnboardingStep(
  step: RemoteOnboardingStep,
): step is EssentialOnboardingStep {
  return ORDERED_ESSENTIAL_ONBOARDING_STEPS.includes(
    step as EssentialOnboardingStep,
  );
}

function useSessionStep(initialStep: EssentialOnboardingStep) {
  const [step, setStep] = useState<{
    current: EssentialOnboardingStep;
    previousIndex: number | undefined;
  }>({
    current: initialStep,
    previousIndex: undefined,
  });

  const goToNextStep = useCallback(() => {
    setStep((activeStep) => {
      const nextStep = getEssentialOnboardingNextStep(activeStep.current);
      if (!nextStep) {
        throw new Error("No next step");
      }
      return {
        current: nextStep,
        previousIndex: getEssentialOnboardingStepIndex(activeStep.current),
      };
    });
  }, []);

  const goToPreviousStep = useCallback(() => {
    setStep((activeStep) => {
      const previousStep = getEssentialOnboardingPreviousStep(
        activeStep.current,
      );
      if (!previousStep) {
        throw new Error("No previous step");
      }
      return {
        current: previousStep,
        previousIndex: getEssentialOnboardingStepIndex(activeStep.current),
      };
    });
  }, []);

  return {
    goToNextStep,
    goToPreviousStep,
    step,
  };
}

const EssentialOnboardingStep_: React.FC<{
  initialStep: RemoteOnboardingStep;
  organizationFragment: FinishRemoteEquityOnboardingPage_Organization$key;
}> = ({ initialStep, organizationFragment }) => {
  const [organization] = useRefetchableFragment(
    ORGANIZATION_FRAGMENT,
    organizationFragment,
  );

  if (!isEssentialOnboardingStep(initialStep)) {
    throw new Error("Organization is not essential");
  }

  const { goToNextStep, goToPreviousStep, step } = useSessionStep(initialStep);

  switch (step.current) {
    case "ESSENTIALS_CTMS":
      return (
        <CTMSPage
          onNextStep={goToNextStep}
          onPreviousStep={goToPreviousStep}
          organizationFragment={organization}
          previousStepIndex={step.previousIndex}
        />
      );
    case "ESSENTIALS_EQUITY_PLAN_PARAMETERS":
      return (
        <KeyEquityPlansParametersPage
          onNextStep={goToNextStep}
          onPreviousStep={goToPreviousStep}
          organizationFragment={organization}
          previousStepIndex={step.previousIndex}
        />
      );
    case "ESSENTIALS_EXPECTATION":
      return (
        <ExpectationPage
          onNextStep={goToNextStep}
          organizationFragment={organization}
        />
      );
    case "ESSENTIALS_FAIR_MARKET_VALUE":
      return (
        <FairMarketValuePage
          onNextStep={goToNextStep}
          onPreviousStep={goToPreviousStep}
          organizationFragment={organization}
          previousStepIndex={step.previousIndex}
        />
      );
    case "ESSENTIALS_ISSUING_COMPANY":
      return (
        <IssuingCompanyPage
          onNextStep={goToNextStep}
          onPreviousStep={goToPreviousStep}
          organizationFragment={organization}
          previousStepIndex={step.previousIndex}
        />
      );
    case "ESSENTIALS_LAST_STEP":
      return <LastStepPage organizationFragment={organization} />;
  }
};

const EssentialOnboardingStep: React.FC<{
  initialStep: null | RemoteOnboardingStep;
  organizationFragment: FinishRemoteEquityOnboardingPage_Organization$key;
}> = ({ initialStep, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  if (!initialStep) {
    return <LastStepPage organizationFragment={organization} />;
  }

  return (
    <EssentialOnboardingStep_
      initialStep={initialStep}
      organizationFragment={organizationFragment}
    />
  );
};

const QUERY = graphql`
  query FinishRemoteEquityOnboardingPage_Query(
    $organizationId: OrganizationId!
  ) {
    organization(id: $organizationId) {
      id
      name
      currentRemoteOnboardingStep
      isRemoteEquityEssentials
      ...FinishRemoteEquityOnboardingPage_Organization
    }
    me {
      ...FinishRemoteEquityOnboardingPage_Viewer
    }
  }
`;

const FinishRemoteEquityOnboardingPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();

  const {
    query: { me: viewer, organization },
  } = useQuery<FinishRemoteEquityOnboardingPage_Query>(QUERY, {
    organizationId,
  });

  if (!organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Admin Onboarding"
      analyticsName="Admin - Finish onboarding"
      organizationId={organization.id}
      title={`Admin | ${organization.name} Finish onboarding`}
    >
      {organization.isRemoteEquityEssentials ? (
        <EssentialOnboardingStep
          initialStep={organization.currentRemoteOnboardingStep}
          organizationFragment={organization}
        />
      ) : (
        <AdvancedOnboardingStep
          organizationFragment={organization}
          step={organization.currentRemoteOnboardingStep}
          viewerFragment={viewer}
        />
      )}
    </Page>
  );
};

export default FinishRemoteEquityOnboardingPage;
