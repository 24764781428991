/**
 * @generated SignedSource<<c662e0dae2291fcbc8832c034459818f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminGranteesLayout_Viewer$data = {
  readonly isAllowedToManageOrganization: boolean;
  readonly " $fragmentType": "AdminGranteesLayout_Viewer";
};
export type AdminGranteesLayout_Viewer$key = {
  readonly " $data"?: AdminGranteesLayout_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminGranteesLayout_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminGranteesLayout_Viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "organizationId",
          "variableName": "organizationId"
        }
      ],
      "kind": "ScalarField",
      "name": "isAllowedToManageOrganization",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "e9a53f0d8e2ee8207c02d11df172d9d3";

export default node;
