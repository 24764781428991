/**
 * @generated SignedSource<<c79655b18e15a0be356338d539d8f27b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AdminGranteesLayout_RefreshOrganizationHRISProvidersDataMutation$variables = {
  organizationId: string;
};
export type AdminGranteesLayout_RefreshOrganizationHRISProvidersDataMutation$data = {
  readonly refreshOrganizationHRISProvidersData: boolean;
};
export type AdminGranteesLayout_RefreshOrganizationHRISProvidersDataMutation = {
  response: AdminGranteesLayout_RefreshOrganizationHRISProvidersDataMutation$data;
  variables: AdminGranteesLayout_RefreshOrganizationHRISProvidersDataMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "organizationId",
        "variableName": "organizationId"
      }
    ],
    "kind": "ScalarField",
    "name": "refreshOrganizationHRISProvidersData",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminGranteesLayout_RefreshOrganizationHRISProvidersDataMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdminGranteesLayout_RefreshOrganizationHRISProvidersDataMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9977af2eb4242e283277167bd42185dc",
    "id": null,
    "metadata": {},
    "name": "AdminGranteesLayout_RefreshOrganizationHRISProvidersDataMutation",
    "operationKind": "mutation",
    "text": "mutation AdminGranteesLayout_RefreshOrganizationHRISProvidersDataMutation(\n  $organizationId: OrganizationId!\n) {\n  refreshOrganizationHRISProvidersData(organizationId: $organizationId)\n}\n"
  }
};
})();

(node as any).hash = "ca803fb3ef7a971e710582cf5da36d8a";

export default node;
