import { graphql, useFragment } from "react-relay";

import {
  BentoCard,
  BentoCardCell,
  BentoCardRow,
} from "../../../../components/BentoCard";
import { EmployeeVestingGraph } from "../../../../components/EmployeeVestingGraph";
import { VestingScheduleLegend } from "../../../../components/VestingScheduleLegend";
import {
  VestingScheduleSection_CTMSGrants$data,
  VestingScheduleSection_CTMSGrants$key,
} from "./__generated__/VestingScheduleSection_CTMSGrants.graphql";
import {
  VestingScheduleSection_DraftGrants$data,
  VestingScheduleSection_DraftGrants$key,
} from "./__generated__/VestingScheduleSection_DraftGrants.graphql";
import {
  VestingScheduleSection_Organization$data,
  VestingScheduleSection_Organization$key,
} from "./__generated__/VestingScheduleSection_Organization.graphql";
import { EmptyStatePlaceholder } from "./EmptyStatePlaceholder";
import { Section } from "./Section";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment VestingScheduleSection_CTMSGrants on CTMSGrant @relay(plural: true) {
    vestingDataPoints {
      __typename
    }
    ...EmployeeVestingGraph_CTMSGrants
    ...VestingScheduleLegend_CTMSGrants
  }
`;

const DRAFT_GRANT_FRAGMENT = graphql`
  fragment VestingScheduleSection_DraftGrants on EasopGrant
  @relay(plural: true) {
    vestingDataPoints {
      __typename
    }
    ...EmployeeVestingGraph_DraftGrants
    ...VestingScheduleLegend_DraftGrants
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment VestingScheduleSection_Organization on Organization {
    ...EmployeeVestingGraph_Organization
  }
`;

export function VestingScheduleSection({
  ctmsGrantsFragment,
  draftGrantsFragment,
  employeeId,
  organizationFragment,
}: {
  ctmsGrantsFragment: VestingScheduleSection_CTMSGrants$key;
  draftGrantsFragment: VestingScheduleSection_DraftGrants$key;
  employeeId: string;
  organizationFragment: VestingScheduleSection_Organization$key;
}) {
  const ctmsGrants = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantsFragment);
  const draftGrants = useFragment(DRAFT_GRANT_FRAGMENT, draftGrantsFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <Section title="Vesting schedule">
      <BentoCard>
        <BentoCardRow>
          <BentoCardCell>
            <VestingScheduleSectionContent
              ctmsGrants={ctmsGrants}
              draftGrants={draftGrants}
              employeeId={employeeId}
              organization={organization}
            />
          </BentoCardCell>
        </BentoCardRow>
      </BentoCard>
    </Section>
  );
}

function NoVestingDataPointsPlaceholder() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="space-y-8">
      <EmptyStatePlaceholder textCenter>
        No vesting schedule to display
      </EmptyStatePlaceholder>
      <div className="flex items-center justify-around text-SM text-grey-500">
        <div>{currentYear - 1}</div>
        <div>{currentYear}</div>
        <div>{currentYear + 1}</div>
        <div>{currentYear + 2}</div>
        <div>{currentYear + 3}</div>
        <div>{currentYear + 4}</div>
      </div>
    </div>
  );
}

function VestingScheduleSectionContent({
  ctmsGrants,
  draftGrants,
  employeeId,
  organization,
}: {
  ctmsGrants: VestingScheduleSection_CTMSGrants$data;
  draftGrants: VestingScheduleSection_DraftGrants$data;
  employeeId: string;
  organization: VestingScheduleSection_Organization$data;
}) {
  const ctmsGrantsWithVestingDataPoints = ctmsGrants.filter(
    (ctmsGrant) => ctmsGrant.vestingDataPoints.length > 0,
  );
  const draftGrantsWithVestingDataPoints = draftGrants.filter(
    (draftGrant) => draftGrant.vestingDataPoints.length > 0,
  );

  if (
    !ctmsGrantsWithVestingDataPoints.length &&
    !draftGrantsWithVestingDataPoints.length
  ) {
    return <NoVestingDataPointsPlaceholder />;
  }

  return (
    <BentoCard>
      <BentoCardRow>
        <BentoCardCell>
          <div className="space-y-8">
            <div className="h-[348px] w-full">
              <EmployeeVestingGraph
                ctmsGrantsFragment={ctmsGrantsWithVestingDataPoints}
                draftGrantsFragment={draftGrantsWithVestingDataPoints}
                groupBy="month"
                organizationFragment={organization}
                randomColorSeed={employeeId}
                view="split"
              />
            </div>
            <div className="flex justify-center gap-6">
              <VestingScheduleLegend
                ctmsGrantsFragment={ctmsGrantsWithVestingDataPoints}
                draftGrantsFragment={draftGrantsWithVestingDataPoints}
                randomColorSeed={employeeId}
                vestingScheduleView="split"
              />
            </div>
          </div>
        </BentoCardCell>
      </BentoCardRow>
    </BentoCard>
  );
}
