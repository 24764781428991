import { graphql, useFragment } from "react-relay";

import { CtmsGrantExerciseTag_CtmsGrant$key } from "./__generated__/CtmsGrantExerciseTag_CtmsGrant.graphql";
import { CtmsGrantStatusTag } from "./CtmsGrantStatusTag";
import { ExerciseStatusTag } from "./ExerciseStatusTag";

const GRANT_FRAGMENT = graphql`
  fragment CtmsGrantExerciseTag_CtmsGrant on CTMSGrant {
    settled
    ...CtmsGrantStatusTag_CtmsGrant
    ...ExerciseStatusTag_CTMSGrant
  }
`;

export const CtmsGrantExerciseTag: React.FC<{
  ctmsGrantFragment: CtmsGrantExerciseTag_CtmsGrant$key;
}> = ({ ctmsGrantFragment }) => {
  const ctmsGrant = useFragment(GRANT_FRAGMENT, ctmsGrantFragment);

  if (ctmsGrant.settled) {
    return <CtmsGrantStatusTag ctmsGrantFragment={ctmsGrant} />;
  }

  return <ExerciseStatusTag ctmsGrantFragment={ctmsGrant} />;
};
