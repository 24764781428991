import { useCallback, useMemo, useState } from "react";
import { useInterval } from "react-use";
import { graphql } from "relay-runtime";

import {
  usePollJobState_JobState_Query,
  usePollJobState_JobState_Query$data,
  usePollJobState_JobState_Query$variables,
} from "./__generated__/usePollJobState_JobState_Query.graphql";
import { useManualQuery } from "./useManualQuery";

const JOB_STATE_QUERY = graphql`
  query usePollJobState_JobState_Query($jobId: ID!, $jobQueue: JobQueue!) {
    job(jobId: $jobId, jobQueue: $jobQueue) @required(action: THROW) {
      state
    }
  }
`;

type HookState =
  | { jobId: null | string; jobQueue: JobQueue | null; polling: false }
  | { jobId: string; jobQueue: JobQueue; polling: true };
type JobQueue = usePollJobState_JobState_Query$variables["jobQueue"];

type JobState = usePollJobState_JobState_Query$data["job"]["state"];

export const usePollJobStateHookState = () => {
  const [state, setState] = useState<HookState>({
    jobId: null,
    jobQueue: null,
    polling: false,
  });

  const startPolling = useCallback(
    ({ jobId, jobQueue }: { jobId: string; jobQueue: JobQueue }) => {
      setState({ jobId, jobQueue, polling: true });
    },
    [],
  );

  const stopPolling = useCallback(() => {
    setState((previousState) => ({
      ...previousState,
      polling: false,
    }));
  }, []);

  return { hookState: state, startPolling, stopPolling };
};

export const usePollJobState = ({
  hookState,
  onJobDone,
  onJobFailed,
}: {
  hookState: HookState;
  onJobDone?: () => void;
  onJobFailed?: () => void;
}) => {
  const [jobState, setJobState] = useState<JobState | null>(null);

  const jobIsDoneOrFailed = useMemo(() => {
    if (!jobState) return false;
    switch (jobState) {
      case "Active":
        return false;
      case "Done":
      case "Failed":
        return true;
    }
  }, [jobState]);

  const [_refreshJobState] =
    useManualQuery<usePollJobState_JobState_Query>(JOB_STATE_QUERY);

  const pollJobState = useCallback(
    async ({
      jobId,
      jobQueue,
    }: {
      jobId: string;
      jobQueue: usePollJobState_JobState_Query$variables["jobQueue"];
    }) => {
      const { job } = await new Promise<
        usePollJobState_JobState_Query["response"]
      >((resolve, reject) =>
        _refreshJobState({
          // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
          onError: (error) => reject(error),
          onResponse: (response) => resolve(response),
          variables: {
            jobId,
            jobQueue,
          },
        }),
      );

      setJobState(job.state);

      switch (job.state) {
        case "Active":
          return;
        case "Done":
          return onJobDone?.();
        case "Failed":
          return onJobFailed?.();
      }
    },
    [_refreshJobState, onJobDone, onJobFailed],
  );

  useInterval(
    async () => {
      if (!hookState.polling) return;

      await pollJobState({
        jobId: hookState.jobId,
        jobQueue: hookState.jobQueue,
      });
    },
    hookState.polling && !jobIsDoneOrFailed ? 1000 : null,
  );

  return { jobState };
};
