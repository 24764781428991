import React from "react";
import { graphql, useFragment } from "react-relay";

import { PricePerShareHistoryCard_Organization$key } from "./__generated__/PricePerShareHistoryCard_Organization.graphql";
import { FormattedFMV } from "./FormattedFMV";
import { Card } from "./ui/Card";
import { Tag } from "./ui/Tag";
import { Typography } from "./ui/Typography";
import { ValuationHistoryChart } from "./ValuationHistoryChart";

const ORGANIZATION_FRAGMENT = graphql`
  fragment PricePerShareHistoryCard_Organization on Organization {
    latestPricePerShare {
      value
      boardDetermined
      tag
    }
    ...FormattedFMV_Organization
  }
`;

export const PricePerShareHistoryCard: React.FC<{
  children: React.ReactElement<
    React.ComponentProps<typeof ValuationHistoryChart>
  >;
  className?: string;
  organizationFragment: PricePerShareHistoryCard_Organization$key;
}> = ({ children, className, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  if (!organization.latestPricePerShare) {
    return null;
  }

  return (
    <Card className={className}>
      <div className="flex flex-col justify-between gap-6">
        <div className="flex flex-col gap-2">
          <Typography variant="Medium/Default">Price Per Share</Typography>
          <div className="flex flex-col gap-1">
            <Typography variant="Medium/Default">
              <FormattedFMV
                organizationFragment={organization}
                value={organization.latestPricePerShare.value}
              />
            </Typography>
            <Typography
              as="div"
              className="text-black-05"
              variant="Regular/Extra Small"
            >
              Price Per Share
            </Typography>
          </div>
          <div className="flex gap-2">
            {organization.latestPricePerShare.tag && (
              <Tag color="primary">
                {organization.latestPricePerShare.tag.toUpperCase()}
              </Tag>
            )}
            {organization.latestPricePerShare.boardDetermined && (
              <Tag color="gray">BOARD DETERMINED</Tag>
            )}
          </div>
        </div>

        {React.cloneElement(children, {
          height: 230,
        })}
      </div>
    </Card>
  );
};
