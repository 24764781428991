import { InformationCircleIcon } from "@heroicons/react/24/outline";
import React, { useCallback } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { CurrencySymbol } from "../../../../components/CurrencySymbol";
import { useToaster } from "../../../../components/Toaster";
import { Toggle } from "../../../../components/ui/Form/Toggle";
import { Toast } from "../../../../components/ui/Toast";
import { TooltipContainer } from "../../../../components/ui/TooltipContainer";
import { Typography } from "../../../../components/ui/Typography";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import { GranteePortalSettingsDisplayFullyDilutedValuesSection_Organization$key } from "./__generated__/GranteePortalSettingsDisplayFullyDilutedValuesSection_Organization.graphql";
import { GranteePortalSettingsDisplayFullyDilutedValuesSection_SetDisplayFullyDilutedValuesInGranteePortal_Mutation } from "./__generated__/GranteePortalSettingsDisplayFullyDilutedValuesSection_SetDisplayFullyDilutedValuesInGranteePortal_Mutation.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment GranteePortalSettingsDisplayFullyDilutedValuesSection_Organization on Organization {
    id
    granteePortalSettings {
      displayFullyDilutedValues
    }
    ...CurrencySymbol_Organization
  }
`;

const SET_DISPLAY_FULLY_DILUTED_VALUES_IN_GRANTEE_PORTAL_MUTATION = graphql`
  mutation GranteePortalSettingsDisplayFullyDilutedValuesSection_SetDisplayFullyDilutedValuesInGranteePortal_Mutation(
    $organizationId: OrganizationId!
    $displayFullyDilutedValues: Boolean!
  ) {
    setDisplayFullyDilutedValuesInGranteePortal(
      organizationId: $organizationId
      displayFullyDilutedValues: $displayFullyDilutedValues
    ) {
      granteePortalSettings {
        displayFullyDilutedValues
      }
    }
  }
`;

export const GranteePortalSettingsDisplayFullyDilutedValuesSection: React.FC<{
  organizationFragment: GranteePortalSettingsDisplayFullyDilutedValuesSection_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const toaster = useToaster();

  const [_setDisplayFullyDilutedValuesInGranteePortal] =
    useSafeMutation<GranteePortalSettingsDisplayFullyDilutedValuesSection_SetDisplayFullyDilutedValuesInGranteePortal_Mutation>(
      SET_DISPLAY_FULLY_DILUTED_VALUES_IN_GRANTEE_PORTAL_MUTATION,
    );

  const setDisplayFullyDilutedValuesInGranteePortal = useCallback(
    async (displayFullyDilutedValues: boolean) => {
      await _setDisplayFullyDilutedValuesInGranteePortal({
        variables: {
          displayFullyDilutedValues,
          organizationId: organization.id,
        },
      });
      toaster.push(
        <Toast title="Great!">Grantee portal successfully updated!</Toast>,
      );
    },
    [_setDisplayFullyDilutedValuesInGranteePortal, toaster, organization.id],
  );

  return (
    <div className="space-y-4 p-10 pt-6">
      <Typography variant="Medium/Small">
        Display dollar value and ownership to employees
      </Typography>
      <div className="flex items-center gap-4 rounded border-[0.5px] border-gray-07 px-3 py-2">
        <Toggle
          enabled={organization.granteePortalSettings.displayFullyDilutedValues}
          onChange={(value) =>
            void setDisplayFullyDilutedValuesInGranteePortal(value)
          }
          size="small"
        />
        <div className="flex-grow space-y-1">
          <Typography as="div" variant="Medium/Extra Small">
            <CurrencySymbol organizationFragment={organization} /> value and %
            ownership
          </Typography>
          <Typography
            as="div"
            className="text-black-05"
            variant="Regular/Extra Small"
          >
            By toggling this off, your employees won&apos;t see anything related
            to the company valuation in their portal.
          </Typography>
        </div>
        <TooltipContainer
          position="bottom"
          tooltipContent={
            <div className="space-y-2">
              <Typography
                as="div"
                className="text-gray-07"
                variant="Regular/Caption"
              >
                Toggling this off gives your active grantees the same experience
                as the terminated ones past PTEP. They also won’t be able to see
                taxation examples as we won’t be able to display what they
                actually have.
              </Typography>
            </div>
          }
        >
          <InformationCircleIcon className="h-4 w-4 shrink-0 text-primary" />
        </TooltipContainer>
      </div>
    </div>
  );
};
