/**
 * @generated SignedSource<<412f2411f73c1146806300f6789d92cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AccelerationClause = "DoubleTrigger100" | "DoubleTrigger25" | "DoubleTrigger35" | "DoubleTrigger50" | "SingleTrigger";
export type CTMSGrantTaxation = "UPON_LIQUIDITY_EVENT" | "UPON_VESTING";
export type EquityTypeName = "BSPCE" | "EMI" | "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
export type DeclareGrantAttributes = {
  accelerationClause?: AccelerationClause | null;
  documentIds: ReadonlyArray<string>;
  earlyExercise?: boolean | null;
  equityType: EquityTypeName;
  exercisePrice?: number | null;
  granteeId: string;
  label: string;
  postTerminationExercisePeriodId?: any | null;
  quantityIssued: number;
  signatureDate: string;
  taxation?: CTMSGrantTaxation | null;
  vestingScheduleId: any;
  vestingStartDate: string;
};
export type GrantDeclareNew_DeclareGrant_Mutation$variables = {
  attributes: DeclareGrantAttributes;
  organizationId: string;
};
export type GrantDeclareNew_DeclareGrant_Mutation$data = {
  readonly declareGrant: {
    readonly id: string;
  };
};
export type GrantDeclareNew_DeclareGrant_Mutation = {
  response: GrantDeclareNew_DeclareGrant_Mutation$data;
  variables: GrantDeclareNew_DeclareGrant_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "attributes"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "attributes",
        "variableName": "attributes"
      },
      {
        "kind": "Variable",
        "name": "organizationId",
        "variableName": "organizationId"
      }
    ],
    "concreteType": "CTMSGrant",
    "kind": "LinkedField",
    "name": "declareGrant",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GrantDeclareNew_DeclareGrant_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GrantDeclareNew_DeclareGrant_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "36e6c1a6f60a7094f9248a7d55bd92ee",
    "id": null,
    "metadata": {},
    "name": "GrantDeclareNew_DeclareGrant_Mutation",
    "operationKind": "mutation",
    "text": "mutation GrantDeclareNew_DeclareGrant_Mutation(\n  $attributes: DeclareGrantAttributes!\n  $organizationId: OrganizationId!\n) {\n  declareGrant(attributes: $attributes, organizationId: $organizationId) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e9cc09cab79b3b7fe317684ab8a8aeca";

export default node;
