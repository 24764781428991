/**
 * @generated SignedSource<<4cab99ba4d55d6ec13aa274104d0653f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProjectionCard_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProjectionGraph_Organization" | "ProjectionTable_Organization">;
  readonly " $fragmentType": "ProjectionCard_Organization";
};
export type ProjectionCard_Organization$key = {
  readonly " $data"?: ProjectionCard_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectionCard_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProjectionCard_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectionGraph_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectionTable_Organization"
    }
  ],
  "type": "IOrganization",
  "abstractKey": "__isIOrganization"
};

(node as any).hash = "c964b7032f62b2ff190ab1aa6b04da2f";

export default node;
