/**
 * @generated SignedSource<<ef44743cb40be5ed9654191b19354176>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquityPlanParametersCard_EssentialsOverview_Organization$data = {
  readonly fullyDilutedShares: number | null;
  readonly id: string;
  readonly issuingCompany: {
    readonly name: string | null;
  } | null;
  readonly latestFairMarketValue: {
    readonly value: number;
  } | null;
  readonly latestPricePerShare: {
    readonly value: number;
  } | null;
  readonly latestValuation: number | null;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"FormattedCurrencyCompact_Organization" | "FormattedFMV_Organization">;
  readonly " $fragmentType": "EquityPlanParametersCard_EssentialsOverview_Organization";
};
export type EquityPlanParametersCard_EssentialsOverview_Organization$key = {
  readonly " $data"?: EquityPlanParametersCard_EssentialsOverview_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquityPlanParametersCard_EssentialsOverview_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquityPlanParametersCard_EssentialsOverview_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "IssuingCompany",
      "kind": "LinkedField",
      "name": "issuingCompany",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FairMarketValue",
      "kind": "LinkedField",
      "name": "latestFairMarketValue",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestValuation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullyDilutedShares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PricePerShare",
      "kind": "LinkedField",
      "name": "latestPricePerShare",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedCurrencyCompact_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedFMV_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "871236b3e86a2d57219accdc36b42bd6";

export default node;
