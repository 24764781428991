import * as Sentry from "@sentry/react";
import React, { useCallback } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useAlerter } from "../../../../components/Alerter";
import { Alert } from "../../../../components/ui/Alert";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import { useMismatchBetweenCtmsAndEasopCapTableGuard_Organization$key } from "./__generated__/useMismatchBetweenCtmsAndEasopCapTableGuard_Organization.graphql";
import { useMismatchBetweenCtmsAndEasopCapTableGuard_SendAlertThatAdminTriedToCreateDraftsButWasBlockedDueToCapTableChanges_Mutation } from "./__generated__/useMismatchBetweenCtmsAndEasopCapTableGuard_SendAlertThatAdminTriedToCreateDraftsButWasBlockedDueToCapTableChanges_Mutation.graphql";
import { GuardWarning } from "./useInstrumentValuationGuard";

const WarningMismatchBetweenCtmsAndEasopCapTableTitle: React.FC = () => (
  <>We cannot let you move forward with those drafts 😔</>
);
export const WarningMismatchBetweenCtmsAndEasopCapTableMessage: React.FC =
  () => (
    <>
      We’ve noticed new activity on your cap table.
      <br />
      This might prevent you from issuing new grants compliantly. We’ll get back
      to you as soon as possible.
    </>
  );

export const WarningMismatchBetweenCtmsAndEasopCapTable: React.FC = () => (
  <GuardWarning title={<WarningMismatchBetweenCtmsAndEasopCapTableTitle />}>
    {<WarningMismatchBetweenCtmsAndEasopCapTableMessage />}
  </GuardWarning>
);

const ORGANIZATION_FRAGMENT = graphql`
  fragment useMismatchBetweenCtmsAndEasopCapTableGuard_Organization on Organization {
    id
  }
`;

const MUTATION = graphql`
  mutation useMismatchBetweenCtmsAndEasopCapTableGuard_SendAlertThatAdminTriedToCreateDraftsButWasBlockedDueToCapTableChanges_Mutation(
    $organizationId: OrganizationId!
  ) {
    sendAlertThatAdminTriedToCreateDraftsButWasBlockedDueToCapTableChanges(
      organizationId: $organizationId
    )
  }
`;

export const useMismatchBetweenCtmsAndEasopCapTableGuard = ({
  organizationFragment,
}: {
  organizationFragment: useMismatchBetweenCtmsAndEasopCapTableGuard_Organization$key;
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const [
    sendAlertThatAdminTriedToCreateDraftsButWasBlockedDueToCapTableChanges,
  ] =
    useSafeMutation<useMismatchBetweenCtmsAndEasopCapTableGuard_SendAlertThatAdminTriedToCreateDraftsButWasBlockedDueToCapTableChanges_Mutation>(
      MUTATION,
    );

  const alerter = useAlerter();

  const showMismatchBetweenCtmsAndEasopCapTableGuard = useCallback(() => {
    alerter.push(
      <Alert
        buttonLabel="Get back to drafting"
        title={<WarningMismatchBetweenCtmsAndEasopCapTableTitle />}
      >
        {<WarningMismatchBetweenCtmsAndEasopCapTableMessage />}
      </Alert>,
    );

    sendAlertThatAdminTriedToCreateDraftsButWasBlockedDueToCapTableChanges({
      variables: {
        organizationId: organization.id,
      },
    }).catch((error) => {
      Sentry.captureException(error);
    });
  }, [
    organization,
    alerter,
    sendAlertThatAdminTriedToCreateDraftsButWasBlockedDueToCapTableChanges,
  ]);

  return {
    showMismatchBetweenCtmsAndEasopCapTableGuard,
  };
};
