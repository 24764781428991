import { FormattedNumberParts } from "react-intl";
import { graphql, useFragment } from "react-relay";

import { FormattedCurrencyParts_Organization$key } from "./__generated__/FormattedCurrencyParts_Organization.graphql";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment FormattedCurrencyParts_Organization on Organization {
    defaultCurrencyCode
  }
`;

export const FormattedCurrencyParts: React.FC<{
  className?: string;
  defaultVariant?: React.ComponentProps<typeof Typography>["variant"];
  fractionVariant?: React.ComponentProps<typeof Typography>["variant"];
  organizationFragment: FormattedCurrencyParts_Organization$key;
  signDisplay?: "always" | "auto" | "never";
  value: number;
}> = ({
  className,
  defaultVariant = "Medium/Extra Large",
  fractionVariant = "Medium/Default",
  organizationFragment,
  signDisplay,
  value,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <FormattedNumberParts
      currency={organization.defaultCurrencyCode}
      signDisplay={signDisplay}
      style="currency"
      value={value}
    >
      {(parts) => (
        <>
          {parts.map((part, index) => {
            switch (part.type) {
              case "decimal":
              case "fraction":
                return (
                  <Typography
                    as="span"
                    className={className}
                    key={`${index}`}
                    variant={fractionVariant}
                  >
                    {part.value}
                  </Typography>
                );
              default:
                return (
                  <Typography
                    as="span"
                    className={className}
                    key={`${index}`}
                    variant={defaultVariant}
                  >
                    {part.value}
                  </Typography>
                );
            }
          })}
        </>
      )}
    </FormattedNumberParts>
  );
};
