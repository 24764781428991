/**
 * @generated SignedSource<<407e560fd42a067fbed6585c80727ba6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoteSelectVestingScheduleInput_Organization$data = {
  readonly id: string;
  readonly vestingSchedules: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"CreateSlideOver_VestingSchedule_Organization">;
  readonly " $fragmentType": "RemoteSelectVestingScheduleInput_Organization";
};
export type RemoteSelectVestingScheduleInput_Organization$key = {
  readonly " $data"?: RemoteSelectVestingScheduleInput_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteSelectVestingScheduleInput_Organization">;
};

import RemoteSelectVestingScheduleInput_Organization_RefetchQuery_graphql from './RemoteSelectVestingScheduleInput_Organization_RefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": RemoteSelectVestingScheduleInput_Organization_RefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "RemoteSelectVestingScheduleInput_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "vestingSchedules",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateSlideOver_VestingSchedule_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "7128aee17826037e5e8e2ba4f170ffd6";

export default node;
