import { BoxedIcon, Text } from "@remote-com/norma";
import classNames from "classnames";
import React from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import {
  CATEGORIES_MAP,
  Category,
  Event,
  EVENTS,
  EVENTS_MAP,
  Section,
  SECTIONS,
  SECTIONS_MAP,
} from "../services/EquityHandbookService";
import {
  EquityHandbooksCountryEquityTypeContent_EquityTypeHandbook$data,
  EquityHandbooksCountryEquityTypeContent_EquityTypeHandbook$key,
} from "./__generated__/EquityHandbooksCountryEquityTypeContent_EquityTypeHandbook.graphql";
import { EquityHandbookNothingToDeclareTag } from "./EquityHandbookNothingToDeclareTag";
import { RoundedBox } from "./ui/RoundedBox";

const EQUITY_TYPE_HANDBOOK_FRAGMENT = graphql`
  fragment EquityHandbooksCountryEquityTypeContent_EquityTypeHandbook on EquityTypeHandbook {
    sectionEvents(enabled: true) {
      section
      event
      nothingToDeclare
      items(enabled: true) {
        category
        content
      }
    }
  }
`;

type EquityTypeHandbook =
  EquityHandbooksCountryEquityTypeContent_EquityTypeHandbook$data;
type SectionEvent = EquityTypeHandbook["sectionEvents"][number];

const CATEGORY_TONE_MAP: Record<
  "brand" | "green" | "red",
  { categoryClassName: string }
> = {
  brand: { categoryClassName: "text-brand-600" },
  green: { categoryClassName: "text-green-600" },
  red: { categoryClassName: "text-red-600" },
};

const SectionEventLabel: React.FC<{
  category?: Category;
  event: Event;
}> = ({ category, event }) => {
  return (
    <div className="flex items-center gap-4">
      <BoxedIcon
        Icon={EVENTS_MAP[event].icon}
        size="sm"
        tone={category ? CATEGORIES_MAP[category].tone : "brand"}
      />
      <Text variant="baseMedium">{EVENTS_MAP[event].label}</Text>
      {category && (
        <div
          className={classNames(
            "flex items-center gap-2",
            CATEGORY_TONE_MAP[CATEGORIES_MAP[category].tone].categoryClassName,
          )}
        >
          {React.createElement(CATEGORIES_MAP[category].icons.activeIcon, {
            className: "h-4 w-4",
          })}
          <Text variant="smMedium">{CATEGORIES_MAP[category].label}</Text>
        </div>
      )}
    </div>
  );
};

const SectionBlock: React.FC<{
  section: Section;
  sectionEvents: SectionEvent[];
}> = ({ section, sectionEvents }) => {
  return (
    <RoundedBox background="white" className="space-y-6 px-14 py-10" withBorder>
      <Text variant="lgMedium">{SECTIONS_MAP[section].title}</Text>
      <div className="flex flex-col items-start gap-2">
        {EVENTS.map((event) => {
          const sectionEvent = sectionEvents.find(
            (sectionEvent) => sectionEvent.event === event,
          );
          if (!sectionEvent) {
            return null;
          }

          if (sectionEvent.nothingToDeclare) {
            return (
              <React.Fragment key={event}>
                <SectionEventLabel event={event} />
                <div className="relative pl-[50px] before:absolute before:inset-0 before:bottom-3 before:left-4 before:top-3 before:w-0.5 before:bg-grey-400">
                  <EquityHandbookNothingToDeclareTag section={section} />
                </div>
              </React.Fragment>
            );
          }

          return (
            <React.Fragment key={event}>
              {SECTIONS_MAP[section].itemCategories.map((category) => {
                const item = sectionEvent.items.find(
                  (item) => item.category === category,
                );
                if (!item) {
                  return null;
                }
                return (
                  <React.Fragment key={category}>
                    <SectionEventLabel category={category} event={event} />
                    <div className="relative pl-[50px] before:absolute before:inset-0 before:bottom-0 before:left-4 before:top-0 before:w-0.5 before:bg-grey-400">
                      <Text
                        className="whitespace-pre-wrap text-grey-600"
                        variant="sm"
                      >
                        {item.content}
                      </Text>
                    </div>
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          );
        })}
      </div>
    </RoundedBox>
  );
};

export const EquityHandbooksCountryEquityTypeContent: React.FC<{
  equityTypeHandbookFragment: EquityHandbooksCountryEquityTypeContent_EquityTypeHandbook$key;
}> = ({ equityTypeHandbookFragment }) => {
  const equityTypeHandbook = useFragment(
    EQUITY_TYPE_HANDBOOK_FRAGMENT,
    equityTypeHandbookFragment,
  );

  return (
    <div className="space-y-10">
      {SECTIONS.map((section) => {
        const sectionEvents = equityTypeHandbook.sectionEvents.filter(
          (sectionEvent) => sectionEvent.section === section,
        );
        if (sectionEvents.length === 0) {
          return null;
        }
        return (
          <SectionBlock
            key={section}
            section={section}
            sectionEvents={sectionEvents}
          />
        );
      })}
    </div>
  );
};
