import { Button, Text } from "@remote-com/norma";
import { IconV2OutlineArrowRight } from "@remote-com/norma/icons/IconV2OutlineArrowRight";
import { useCallback } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useSafeMutation } from "../../../hooks/useSafeMutation";
import { ExpectationPage_Organization$key } from "./__generated__/ExpectationPage_Organization.graphql";
import { ExpectationPage_SetEssentialsExpectationOnboardingStepAsCompleted_Mutation } from "./__generated__/ExpectationPage_SetEssentialsExpectationOnboardingStepAsCompleted_Mutation.graphql";
import { EssentialsOnboardingTemplate } from "./EssentialsOnboardingTemplate";
import RemoteEquityEssentialsIllustration from "./remote-equity-essentials-illustration.png";

const ORGANIZATION_FRAGMENT = graphql`
  fragment ExpectationPage_Organization on Organization {
    id
  }
`;

const SET_ESSENTIALS_EXPECTATION_ONBOARDING_AS_COMPLETED_MUTATION = graphql`
  mutation ExpectationPage_SetEssentialsExpectationOnboardingStepAsCompleted_Mutation(
    $organizationId: OrganizationId!
  ) {
    setEssentialsExpectationOnboardingStepAsCompleted(
      organizationId: $organizationId
    ) {
      currentRemoteOnboardingStep
    }
  }
`;

const ExpectationPage: React.FC<{
  onNextStep: () => void;
  organizationFragment: ExpectationPage_Organization$key;
}> = ({ onNextStep, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const [
    setEssentialsExpectationOnboardingStepAsCompleted,
    mutationIsInFlight,
  ] =
    useSafeMutation<ExpectationPage_SetEssentialsExpectationOnboardingStepAsCompleted_Mutation>(
      SET_ESSENTIALS_EXPECTATION_ONBOARDING_AS_COMPLETED_MUTATION,
    );

  const onStartSettingUpClick = useCallback(async () => {
    await setEssentialsExpectationOnboardingStepAsCompleted({
      variables: { organizationId: organization.id },
    });
    onNextStep();
  }, [
    organization.id,
    onNextStep,
    setEssentialsExpectationOnboardingStepAsCompleted,
  ]);

  return (
    <EssentialsOnboardingTemplate
      illustration={RemoteEquityEssentialsIllustration}
      title={<>Get started with Remote&nbsp;Equity&nbsp;Essentials</>}
      tone="blue"
    >
      <div className="space-y-8">
        <div className="space-y-2">
          <Text variant="2xlMedium">Declare equity grants and exercises</Text>
          <Text variant="base">
            Keep track of vesting equity benefits and local reporting
            requirements for company and employees alike. Unravel the
            complexities of global equity compensation with ease.
          </Text>
        </div>
        <div className="space-y-2">
          <Text variant="2xlMedium">Report taxable events</Text>
          <Text variant="base">
            Protect your company from the risk of fines and late interest by
            ensuring timely and accurate reporting of equity-related benefits to
            local authorities.
          </Text>
        </div>
        <Button
          IconAfter={IconV2OutlineArrowRight}
          isLoading={mutationIsInFlight}
          onClick={onStartSettingUpClick}
          variant="outline"
        >
          Start setting up
        </Button>
      </div>
    </EssentialsOnboardingTemplate>
  );
};

export default ExpectationPage;
