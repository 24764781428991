import { IconV2DuotoneColumns2 } from "@remote-com/norma/icons/IconV2DuotoneColumns2";
import { IconV2DuotoneStructurePlus } from "@remote-com/norma/icons/IconV2DuotoneStructurePlus";
import { IconV2DuotoneUsers } from "@remote-com/norma/icons/IconV2DuotoneUsers";
import { IconV2OutlineColumns2 } from "@remote-com/norma/icons/IconV2OutlineColumns2";
import { IconV2OutlineStructurePlus } from "@remote-com/norma/icons/IconV2OutlineStructurePlus";
import { IconV2OutlineUsers } from "@remote-com/norma/icons/IconV2OutlineUsers";
import { Suspense, useRef } from "react";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { generatePath, Outlet } from "react-router-dom";
import { graphql } from "relay-runtime";

import { LoadingPlaceholder } from "../../components/LoadingPlaceholder";
import { ApplicationSideBar } from "../../components/ui/ApplicationSideBar/ApplicationSideBar";
import { AnalyticsContextProvider } from "../../hooks/useAnalytics";
import { ApplicationThemeProvider } from "../../hooks/useApplicationTheme";
import { FeatureFlagProvider } from "../../hooks/useIsFeatureEnabled";
import { useScrollToTopOnNavigation } from "../../hooks/useScrollToTopOnNavigation";
import NotFoundPage from "../../pages/NotFound/NotFound";
import { APPLICATION_ROUTES, useBoardMemberIdParam } from "../../paths";
import { useAuthentication } from "../../services/AuthenticationProvider";
import { BoardMemberLayout_BoardMember$key } from "./__generated__/BoardMemberLayout_BoardMember.graphql";
import { BoardMemberLayout_Organization_Query } from "./__generated__/BoardMemberLayout_Organization_Query.graphql";
import { BoardMemberLayout_Viewer$key } from "./__generated__/BoardMemberLayout_Viewer.graphql";

const VIEWER_FRAGMENT = graphql`
  fragment BoardMemberLayout_Viewer on Account {
    ...ApplicationSideBar_Viewer
  }
`;

const BOARD_MEMBER_FRAGMENT = graphql`
  fragment BoardMemberLayout_BoardMember on BoardMember {
    id
    organization {
      equityGrid {
        activated
      }
      ...ApplicationSideBar_Organization
    }
  }
`;

const BoardMemberLayout_: React.FC<{
  boardMemberFragment: BoardMemberLayout_BoardMember$key;
  viewerFragment: BoardMemberLayout_Viewer$key;
}> = ({ boardMemberFragment, viewerFragment }) => {
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const boardMember = useFragment(BOARD_MEMBER_FRAGMENT, boardMemberFragment);
  const showEquityGridFeature = boardMember.organization.equityGrid.activated;
  const mainContentRef = useRef<HTMLDivElement | null>(null);
  useScrollToTopOnNavigation(mainContentRef);

  return (
    <ApplicationSideBar.Layout>
      <ApplicationSideBar
        activeRole="Board Member"
        className="flex-shrink-0"
        homePath={generatePath(APPLICATION_ROUTES.boardMemberOverview, {
          boardMemberId: boardMember.id,
        })}
        organizationFragment={boardMember.organization}
        viewerFragment={viewer}
      >
        <div className="flex flex-col gap-[1px]">
          <ApplicationSideBar.NavLink
            end
            icons={{
              ActiveIcon: <IconV2DuotoneColumns2 />,
              Icon: <IconV2OutlineColumns2 />,
            }}
            to={generatePath(
              generatePath(APPLICATION_ROUTES.boardMemberOverview, {
                boardMemberId: boardMember.id,
              }),
            )}
          >
            Overview
          </ApplicationSideBar.NavLink>

          <ApplicationSideBar.NavLink
            icons={{
              ActiveIcon: <IconV2DuotoneUsers />,
              Icon: <IconV2OutlineUsers />,
            }}
            to={generatePath(APPLICATION_ROUTES.boardMemberApprovals, {
              boardMemberId: boardMember.id,
            })}
          >
            Approvals
          </ApplicationSideBar.NavLink>
          {showEquityGridFeature && (
            <ApplicationSideBar.NavLink
              icons={{
                ActiveIcon: <IconV2DuotoneStructurePlus />,
                Icon: <IconV2OutlineStructurePlus />,
              }}
              to={generatePath(APPLICATION_ROUTES.boardMemberEquityGrid, {
                boardMemberId: boardMember.id,
              })}
            >
              Equity grid
            </ApplicationSideBar.NavLink>
          )}
        </div>
      </ApplicationSideBar>
      <div
        className="h-full flex-auto overflow-auto overflow-x-hidden bg-white px-4 sm:overflow-x-auto sm:px-6 sm:py-6"
        ref={mainContentRef}
      >
        <Suspense fallback={<LoadingPlaceholder />}>
          <Outlet />
        </Suspense>
      </div>
    </ApplicationSideBar.Layout>
  );
};

const ORGANIZATION_QUERY = graphql`
  query BoardMemberLayout_Organization_Query($boardMemberId: UUID!) {
    boardMember(id: $boardMemberId) {
      organization {
        id
        name
        ...useApplicationTheme_Organization
      }
      ...BoardMemberLayout_BoardMember
    }
    me {
      ...BoardMemberLayout_Viewer
    }
  }
`;

const BoardMemberLayout: React.FC = () => {
  const boardMemberId = useBoardMemberIdParam();
  const query = useLazyLoadQuery<BoardMemberLayout_Organization_Query>(
    ORGANIZATION_QUERY,
    {
      boardMemberId,
    },
  );
  const { account } = useAuthentication();

  if (!query.boardMember) {
    return <NotFoundPage />;
  }

  return (
    <ApplicationThemeProvider
      organizationFragment={query.boardMember.organization}
    >
      <FeatureFlagProvider
        account={account}
        organizationName={query.boardMember.organization.name}
      >
        <AnalyticsContextProvider
          organizationId={query.boardMember.organization.id}
        >
          <BoardMemberLayout_
            boardMemberFragment={query.boardMember}
            viewerFragment={query.me}
          />
        </AnalyticsContextProvider>
      </FeatureFlagProvider>
    </ApplicationThemeProvider>
  );
};

export default BoardMemberLayout;
