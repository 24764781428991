/**
 * @generated SignedSource<<8fb163cc196a1198eafe730b542180b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteePendingTerminationTag_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useOrganizationCTMS_Organization">;
  readonly " $fragmentType": "GranteePendingTerminationTag_Organization";
};
export type GranteePendingTerminationTag_Organization$key = {
  readonly " $data"?: GranteePendingTerminationTag_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteePendingTerminationTag_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteePendingTerminationTag_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationCTMS_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "15735aae3e59cee15aa4312488649a08";

export default node;
