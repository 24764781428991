/**
 * @generated SignedSource<<e71942b764feee8ebcf353a640a71526>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquityDetailsSection_DraftGrants$data = ReadonlyArray<{
  readonly id: string;
  readonly label: string;
  readonly quantityGranted: number;
  readonly " $fragmentType": "EquityDetailsSection_DraftGrants";
}>;
export type EquityDetailsSection_DraftGrants$key = ReadonlyArray<{
  readonly " $data"?: EquityDetailsSection_DraftGrants$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquityDetailsSection_DraftGrants">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "EquityDetailsSection_DraftGrants",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantityGranted",
      "storageKey": null
    }
  ],
  "type": "EasopGrant",
  "abstractKey": null
};

(node as any).hash = "8338e83e379f5a6cee8071d3be2a1da8";

export default node;
