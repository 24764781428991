/**
 * @generated SignedSource<<6cbd9ad8255519666e7a2895c8776cba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useCurrencyFormatter_Organization$data = {
  readonly defaultCurrencyCode: string;
  readonly " $fragmentType": "useCurrencyFormatter_Organization";
};
export type useCurrencyFormatter_Organization$key = {
  readonly " $data"?: useCurrencyFormatter_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"useCurrencyFormatter_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useCurrencyFormatter_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultCurrencyCode",
      "storageKey": null
    }
  ],
  "type": "IOrganization",
  "abstractKey": "__isIOrganization"
};

(node as any).hash = "bb44f37d1f37f9efa0d0036b2baacfeb";

export default node;
