import chroma from "chroma-js";

export function getShadesOfColor({
  baseColor,
  shadesCount,
}: {
  baseColor: string;
  shadesCount: number;
}) {
  const numbers = getEvenlySpacedNumbers(shadesCount);

  return numbers.map((number) => chroma(baseColor).tint(number).hex());
}

function getEvenlySpacedNumbers(count: number) {
  if (count === 1) {
    return [0];
  }

  return Array.from({ length: count }, (_, i) => i / (count - 1)).map(
    (element) => (element - 0.5) * -1,
  );
}
