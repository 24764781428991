import {
  EllipsisHorizontalIcon,
  PencilIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { Pill } from "@remote-com/norma";
import { isNil } from "lodash";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import {
  useApplicationName,
  useApplicationSupportEmailAddress,
} from "../hooks/useApplicationTheme";
import {
  OrganizationCTMSGrantsFiltersOrderBy,
  OrganizationCTMSGrantsFiltersOrderByField,
} from "../pages/Admin/Grants/__generated__/Grants_OrganizationCtmsGrantsPagination_Query.graphql";
import { APPLICATION_ROUTES, useGrantsTableSearchParams } from "../paths";
import {
  CtmsGrantsTableRow_CTMSGrant$data,
  CtmsGrantsTableRow_CTMSGrant$key,
} from "./__generated__/CtmsGrantsTableRow_CTMSGrant.graphql";
import {
  CtmsGrantsTableRow_Organization$data,
  CtmsGrantsTableRow_Organization$key,
} from "./__generated__/CtmsGrantsTableRow_Organization.graphql";
import { useAlerter } from "./Alerter";
import { CtmsGrantsTableRowGranteeCell } from "./CtmsGrantsTableRowGranteeCell";
import { CtmsGrantStatusTag } from "./CtmsGrantStatusTag";
import { FormattedFMV } from "./FormattedFMV";
import { MissingCTMSInformationTag } from "./MissingInformationTag";
import { ShortDate } from "./ShortDate";
import { StopClickPropagationWrapper } from "./StopClickPropagationWrapper";
import { Alert } from "./ui/Alert";
import { Button } from "./ui/Button";
import { MenuButton } from "./ui/MenuButton";
import { SingleValueProgress } from "./ui/SingleValueProgress";
import { OrderBy, Table } from "./ui/Table";
import { TableCellExercisedProgress } from "./ui/Table/TableCellExercisedProgress";
import { TableCellSharesOverview } from "./ui/Table/TableCellSharesOverview";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment CtmsGrantsTableRow_Organization on Organization {
    id
    name
    isRemoteEquityEssentials
    ...TableCellSharesOverview_Organization
    ...MissingInformationTag_MissingCTMSInformationTag_Organization
    ...FormattedFMV_Organization
  }
`;

const CTMS_GRANT_FRAGMENT = graphql`
  fragment CtmsGrantsTableRow_CTMSGrant on CTMSGrant {
    id
    label
    grantee {
      id
      name
      ...CtmsGrantsTableRowGranteeCell_Grantee
    }
    equityTypeAwardName
    quantityIssued
    cumulativeVested
    quantityExercised
    exercisableShares
    terminationDate
    remainingDaysToExercise
    exercisePrice
    stakeholderSignatureDate
    settled
    isMissingInformationInOrderToBeModified
    ...CtmsGrantStatusTag_CtmsGrant
  }
`;

const Header: React.FC<{
  onOrderByChange: (
    orderBy: null | OrganizationCTMSGrantsFiltersOrderBy,
  ) => void;
  orderBy: null | OrganizationCTMSGrantsFiltersOrderBy;
  organizationFragment: CtmsGrantsTableRow_Organization$key;
  variant: "Active" | "Terminated";
}> = ({
  onOrderByChange: _onOrderByChange,
  orderBy,
  organizationFragment,
  variant,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const { setParams } = useGrantsTableSearchParams();
  const onOrderByChange = (
    orderBy: null | OrderBy<OrganizationCTMSGrantsFiltersOrderByField>,
  ) => {
    setParams(orderBy);
    _onOrderByChange(orderBy);
  };
  return (
    <Table.Row>
      <Table.SortableHeaderCellForPaginatedTable
        field="label"
        onOrderByChange={onOrderByChange}
        orderBy={orderBy}
      >
        Label
      </Table.SortableHeaderCellForPaginatedTable>

      <Table.SortableHeaderCellForPaginatedTable
        field="grantee"
        onOrderByChange={onOrderByChange}
        orderBy={orderBy}
      >
        Employee
      </Table.SortableHeaderCellForPaginatedTable>

      {variant === "Active" && (
        <>
          <Table.SortableHeaderCellForPaginatedTable
            alignRight
            className="text-right"
            field="quantityIssued"
            onOrderByChange={onOrderByChange}
            orderBy={orderBy}
          >
            Grant size
          </Table.SortableHeaderCellForPaginatedTable>
          <Table.SortableHeaderCellForPaginatedTable
            field="cumulativeVested"
            onOrderByChange={onOrderByChange}
            orderBy={orderBy}
          >
            Vesting
          </Table.SortableHeaderCellForPaginatedTable>
          <Table.SortableHeaderCellForPaginatedTable
            field="equityType"
            onOrderByChange={onOrderByChange}
            orderBy={orderBy}
          >
            Type
          </Table.SortableHeaderCellForPaginatedTable>
          {organization.isRemoteEquityEssentials ? (
            <>
              <Table.HeaderCell className="text-right">Status</Table.HeaderCell>
              <Table.HeaderCell className="text-right">
                Actions
              </Table.HeaderCell>
            </>
          ) : (
            <>
              <Table.SortableHeaderCellForPaginatedTable
                field="exercisePrice"
                onOrderByChange={onOrderByChange}
                orderBy={orderBy}
              >
                Strike price
              </Table.SortableHeaderCellForPaginatedTable>
              <Table.HeaderCell className="text-right">
                Signature
              </Table.HeaderCell>
            </>
          )}
        </>
      )}

      {variant === "Terminated" && (
        <>
          <Table.SortableHeaderCellForPaginatedTable
            field="terminationDate"
            onOrderByChange={onOrderByChange}
            orderBy={orderBy}
          >
            Termination date
          </Table.SortableHeaderCellForPaginatedTable>
          <Table.SortableHeaderCellForPaginatedTable
            field="quantityExercised"
            onOrderByChange={onOrderByChange}
            orderBy={orderBy}
          >
            Total exercised
          </Table.SortableHeaderCellForPaginatedTable>

          <Table.SortableHeaderCellForPaginatedTable
            field="remainingDaysToExercise"
            onOrderByChange={onOrderByChange}
            orderBy={orderBy}
          >
            Days remaining to exercise
          </Table.SortableHeaderCellForPaginatedTable>

          <Table.SortableHeaderCellForPaginatedTable
            field="settled"
            onOrderByChange={onOrderByChange}
            orderBy={orderBy}
          >
            Status
          </Table.SortableHeaderCellForPaginatedTable>
        </>
      )}
    </Table.Row>
  );
};

const CtmsGrantsTableRow_: React.FC<{
  ctmsGrantFragment: CtmsGrantsTableRow_CTMSGrant$key;
  organizationFragment: CtmsGrantsTableRow_Organization$key;
  variant: "Active" | "Terminated";
}> = ({ ctmsGrantFragment, organizationFragment, variant }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);

  return (
    <Table.LinkRow
      to={generatePath(APPLICATION_ROUTES.organizationEquityCtmsGrant, {
        ctmsGrantId: ctmsGrant.id,
        organizationId: organization.id,
      })}
    >
      <LabelCell ctmsGrant={ctmsGrant} />

      <GranteeCell ctmsGrant={ctmsGrant} />

      {variant === "Active" && (
        <>
          <OwnershipCell ctmsGrant={ctmsGrant} organization={organization} />

          <OwnershipVestedCell ctmsGrant={ctmsGrant} />

          <EquityTypeCell ctmsGrant={ctmsGrant} />

          {organization.isRemoteEquityEssentials ? (
            <>
              <EssentialsStatusCell />

              <EssentialsActionCell
                ctmsGrant={ctmsGrant}
                organization={organization}
              />
            </>
          ) : (
            <>
              <StrikePriceCell
                ctmsGrant={ctmsGrant}
                organization={organization}
              />

              <SignatureAndActionCell
                ctmsGrant={ctmsGrant}
                organization={organization}
              />
            </>
          )}
        </>
      )}

      {variant === "Terminated" && (
        <>
          <TerminationDateCell
            ctmsGrant={ctmsGrant}
            organization={organization}
          />
          <ExercisedCell ctmsGrant={ctmsGrant} />

          <DaysRemainingToExerciseCell
            ctmsGrant={ctmsGrant}
            organization={organization}
          />

          <StatusCell ctmsGrant={ctmsGrant} />
        </>
      )}
    </Table.LinkRow>
  );
};

const DaysRemainingToExerciseCell: React.FC<{
  ctmsGrant: CtmsGrantsTableRow_CTMSGrant$data;
  organization: CtmsGrantsTableRow_Organization$data;
}> = ({ ctmsGrant, organization }) => {
  return (
    <Table.Cell>
      {ctmsGrant.settled ? (
        "-"
      ) : !ctmsGrant.cumulativeVested ? (
        <>
          <Typography className="text-black-05" variant="Regular/Caption">
            No shares vested
          </Typography>
        </>
      ) : ctmsGrant.remainingDaysToExercise !== null ? (
        <FormattedNumber
          style="unit"
          unit="day"
          value={ctmsGrant.remainingDaysToExercise}
        />
      ) : (
        <MissingCTMSInformationTag organizationFragment={organization} />
      )}
    </Table.Cell>
  );
};

const TerminationDateCell: React.FC<{
  ctmsGrant: CtmsGrantsTableRow_CTMSGrant$data;
  organization: CtmsGrantsTableRow_Organization$data;
}> = ({ ctmsGrant, organization }) => {
  return (
    <Table.Cell>
      {ctmsGrant.terminationDate ? (
        <Typography variant="Regular/Caption">
          <ShortDate value={ctmsGrant.terminationDate} />
        </Typography>
      ) : (
        <MissingCTMSInformationTag organizationFragment={organization} />
      )}
    </Table.Cell>
  );
};

const SignatureAndActionCell: React.FC<{
  ctmsGrant: CtmsGrantsTableRow_CTMSGrant$data;
  organization: CtmsGrantsTableRow_Organization$data;
}> = ({ ctmsGrant, organization }) => {
  const applicationName = useApplicationName();
  const supportEmailAddress = useApplicationSupportEmailAddress();

  const alerter = useAlerter();
  return (
    <Table.Cell>
      <div className="flex items-center justify-end gap-2">
        {ctmsGrant.stakeholderSignatureDate ? (
          <Pill tone="success">Signed</Pill>
        ) : (
          <Pill tone="warning">Pending</Pill>
        )}

        <StopClickPropagationWrapper>
          <MenuButton
            button={
              <Button
                leftIcon={<EllipsisHorizontalIcon />}
                size="small"
                variant="Secondary Full"
              />
            }
          >
            {ctmsGrant.isMissingInformationInOrderToBeModified ? (
              <MenuButton.Item
                leftIcon={<PencilSquareIcon />}
                onClick={() => {
                  const mailtoSubjectMissingInformationInOrderToBeModified =
                    encodeURI(
                      `[${organization.name}] Why can't I modify grant ${ctmsGrant.label}?`,
                    );
                  alerter.push(
                    <Alert
                      title={
                        <>
                          You requested to amend one (or several) grant(s) that
                          have not been generated on {applicationName}
                        </>
                      }
                    >
                      We are therefore missing initial information about the
                      grant(s) required for the grant amendment(s). Please{" "}
                      <a
                        className="font-medium text-primary"
                        href={`mailto:${supportEmailAddress}?subject=${mailtoSubjectMissingInformationInOrderToBeModified}`}
                      >
                        contact us
                      </a>{" "}
                      to provide us the relevant information.
                    </Alert>,
                  );
                }}
              >
                Modify
              </MenuButton.Item>
            ) : (
              <MenuButton.LinkItem
                leftIcon={<PencilSquareIcon />}
                to={generatePath(
                  APPLICATION_ROUTES.organizationEquityCtmsGrantModify,
                  {
                    ctmsGrantId: ctmsGrant.id,
                    organizationId: organization.id,
                  },
                )}
              >
                Modify
              </MenuButton.LinkItem>
            )}
          </MenuButton>
        </StopClickPropagationWrapper>
      </div>
    </Table.Cell>
  );
};

const EssentialsStatusCell: React.FC = () => {
  return (
    <Table.Cell className="text-right">
      <Pill tone="success">Issued</Pill>
    </Table.Cell>
  );
};

const EssentialsActionCell: React.FC<{
  ctmsGrant: CtmsGrantsTableRow_CTMSGrant$data;
  organization: CtmsGrantsTableRow_Organization$data;
}> = ({ ctmsGrant, organization }) => {
  return (
    <Table.Cell className="justify-end text-right">
      <StopClickPropagationWrapper>
        <MenuButton
          button={
            <Button
              leftIcon={<EllipsisHorizontalIcon />}
              size="small"
              variant="Secondary Full"
            />
          }
        >
          <MenuButton.LinkItem
            leftIcon={<PencilIcon />}
            to={generatePath(
              APPLICATION_ROUTES.organizationDeclareNewExerciseRequestsForCTMSGrant,
              {
                organizationId: organization.id,
                selectedCTMSGrantLabel: ctmsGrant.label,
                selectedGranteeId: ctmsGrant.grantee.id,
              },
            )}
          >
            Declare new exercise
          </MenuButton.LinkItem>
        </MenuButton>
      </StopClickPropagationWrapper>
    </Table.Cell>
  );
};

const LabelCell: React.FC<{
  ctmsGrant: CtmsGrantsTableRow_CTMSGrant$data;
}> = ({ ctmsGrant }) => {
  return (
    <Table.Cell>
      <Pill tone="neutralLight">{ctmsGrant.label}</Pill>
    </Table.Cell>
  );
};

const GranteeCell: React.FC<{
  ctmsGrant: CtmsGrantsTableRow_CTMSGrant$data;
}> = ({ ctmsGrant }) => {
  return (
    <Table.Cell truncate>
      <CtmsGrantsTableRowGranteeCell granteeFragment={ctmsGrant.grantee} />
    </Table.Cell>
  );
};

const EquityTypeCell: React.FC<{
  ctmsGrant: CtmsGrantsTableRow_CTMSGrant$data;
}> = ({ ctmsGrant }) => {
  return (
    <Table.Cell>
      <Pill tone="neutralLight">{ctmsGrant.equityTypeAwardName ?? "-"}</Pill>
    </Table.Cell>
  );
};

const StatusCell: React.FC<{
  ctmsGrant: CtmsGrantsTableRow_CTMSGrant$data;
}> = ({ ctmsGrant }) => {
  return (
    <Table.Cell className="w-0 text-right">
      <CtmsGrantStatusTag ctmsGrantFragment={ctmsGrant} />
    </Table.Cell>
  );
};

const StrikePriceCell: React.FC<{
  ctmsGrant: CtmsGrantsTableRow_CTMSGrant$data;
  organization: CtmsGrantsTableRow_Organization$data;
}> = ({ ctmsGrant, organization }) => {
  return (
    <Table.Cell>
      {!isNil(ctmsGrant.exercisePrice) ? (
        <FormattedFMV
          organizationFragment={organization}
          value={ctmsGrant.exercisePrice}
        />
      ) : (
        "-"
      )}
    </Table.Cell>
  );
};

const ExercisedCell: React.FC<{
  ctmsGrant: CtmsGrantsTableRow_CTMSGrant$data;
}> = ({ ctmsGrant }) => {
  return (
    <Table.Cell>
      <TableCellExercisedProgress
        exercisableShares={ctmsGrant.exercisableShares}
        exercisedShares={ctmsGrant.quantityExercised}
      />
    </Table.Cell>
  );
};

const OwnershipVestedCell: React.FC<{
  ctmsGrant: CtmsGrantsTableRow_CTMSGrant$data;
}> = ({ ctmsGrant }) => {
  if (ctmsGrant.cumulativeVested === 0) {
    return (
      <Table.Cell>
        <Typography variant="Regular/Caption">Cliff not passed</Typography>
      </Table.Cell>
    );
  }

  return (
    <Table.Cell>
      <div className="flex flex-col gap-2">
        <Typography as="div" variant="Regular/Extra Small">
          <FormattedNumber value={ctmsGrant.cumulativeVested} />{" "}
          <span className="text-grey-500">
            / <FormattedNumber value={ctmsGrant.quantityIssued} />
          </span>
        </Typography>
        <SingleValueProgress
          className="relative h-2 w-[150px]"
          current={ctmsGrant.cumulativeVested}
          max={ctmsGrant.quantityIssued}
        />
      </div>
    </Table.Cell>
  );
};

const OwnershipCell: React.FC<{
  ctmsGrant: CtmsGrantsTableRow_CTMSGrant$data;
  organization: CtmsGrantsTableRow_Organization$data;
}> = ({ ctmsGrant, organization }) => {
  return (
    <Table.Cell className="text-right">
      <TableCellSharesOverview
        className="justify-end"
        organizationFragment={organization}
        shares={ctmsGrant.quantityIssued}
      />
    </Table.Cell>
  );
};

export const CtmsGrantsTableRow = Object.assign(CtmsGrantsTableRow_, {
  Header,
});
