import { ForwardIcon } from "@heroicons/react/24/outline";
import { differenceInDays } from "date-fns";
import { now } from "lodash";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { CtmsGrantActivePTEPValue } from "../../../../components/CtmsGrantActivePTEPValue";
import { CtmsGrantRemainingDaysToExerciseMessage } from "../../../../components/CtmsGrantRemainingDaysToExerciseMessage";
import { DetailsBox } from "../../../../components/DetailsBox";
import { Duration } from "../../../../components/Duration";
import { MissingCTMSInformationTag } from "../../../../components/MissingInformationTag";
import { ShortDate } from "../../../../components/ShortDate";
import { InformationRows } from "../../../../components/ui/InformationRows";
import { SingleValueProgress } from "../../../../components/ui/SingleValueProgress";
import { CtmsGrantDetailsPostTerminationExercisePeriod_CTMSGrant$key } from "./__generated__/CtmsGrantDetailsPostTerminationExercisePeriod_CTMSGrant.graphql";
import { CtmsGrantDetailsPostTerminationExercisePeriod_Organization$key } from "./__generated__/CtmsGrantDetailsPostTerminationExercisePeriod_Organization.graphql";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment CtmsGrantDetailsPostTerminationExercisePeriod_CTMSGrant on CTMSGrant {
    terminationDate
    lastDayToExerciseBasedOnTerminationDate
    isVirtual
    activePostTerminationExercisePeriod {
      ...CtmsGrantActivePTEPValue_ActivePostTerminationExercisePeriod
    }
    postTerminationExercisePeriod {
      __typename
      ... on OrganizationVariablePostTerminationExercisePeriod {
        extensionDuration
        extensionDurationUnit
        thresholdForExtensionInYears
      }
    }
    ...CtmsGrantRemainingDaysToExerciseMessage_CtmsGrant
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment CtmsGrantDetailsPostTerminationExercisePeriod_Organization on Organization {
    ...MissingInformationTag_MissingCTMSInformationTag_Organization
  }
`;

export const CtmsGrantDetailsPostTerminationExercisePeriod: React.FC<{
  ctmsGrantFragment: CtmsGrantDetailsPostTerminationExercisePeriod_CTMSGrant$key;
  organizationFragment: CtmsGrantDetailsPostTerminationExercisePeriod_Organization$key;
}> = ({ ctmsGrantFragment, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);

  const { daysSinceTermination, daysToExerciseSinceTermination } = useMemo(
    () =>
      ctmsGrant.terminationDate &&
      ctmsGrant.lastDayToExerciseBasedOnTerminationDate
        ? {
            daysSinceTermination: differenceInDays(
              ctmsGrant.terminationDate,
              now(),
            ),
            daysToExerciseSinceTermination: differenceInDays(
              ctmsGrant.terminationDate,
              ctmsGrant.lastDayToExerciseBasedOnTerminationDate,
            ),
          }
        : {
            daysSinceTermination: null,
            daysToExerciseSinceTermination: null,
          },
    [
      ctmsGrant.lastDayToExerciseBasedOnTerminationDate,
      ctmsGrant.terminationDate,
    ],
  );

  const ptepLabel = ctmsGrant.isVirtual
    ? "Post-termination settlement period"
    : "Post-termination exercise period";

  return (
    <DetailsBox Icon={ForwardIcon} title={ptepLabel}>
      <div className="space-y-4">
        {ctmsGrant.terminationDate &&
        ctmsGrant.lastDayToExerciseBasedOnTerminationDate &&
        daysSinceTermination &&
        daysToExerciseSinceTermination ? (
          <InformationRows>
            <InformationRows.Row label="Start date">
              <ShortDate value={ctmsGrant.terminationDate} />
            </InformationRows.Row>
            <InformationRows.Row label={`${ptepLabel} status`}>
              <div className="flex flex-col items-end">
                <CtmsGrantRemainingDaysToExerciseMessage
                  ctmsGrantFragment={ctmsGrant}
                />
                <SingleValueProgress
                  className="relative mt-2 h-2 w-[280px]"
                  current={daysSinceTermination}
                  max={daysToExerciseSinceTermination}
                  variant="primary"
                />
              </div>
            </InformationRows.Row>
            <InformationRows.Row label="Last day to exercise">
              <ShortDate
                value={ctmsGrant.lastDayToExerciseBasedOnTerminationDate}
              />
            </InformationRows.Row>
          </InformationRows>
        ) : (
          <InformationRows>
            <InformationRows.Row label={ptepLabel}>
              {ctmsGrant.activePostTerminationExercisePeriod ? (
                <CtmsGrantActivePTEPValue
                  activePostTerminationExercisePeriodFragment={
                    ctmsGrant.activePostTerminationExercisePeriod
                  }
                />
              ) : (
                <MissingCTMSInformationTag
                  organizationFragment={organization}
                />
              )}
            </InformationRows.Row>
            {ctmsGrant.postTerminationExercisePeriod &&
              (ctmsGrant.postTerminationExercisePeriod.__typename ===
              "OrganizationVariablePostTerminationExercisePeriod" ? (
                <>
                  <InformationRows.Row
                    label={`Variable ${ptepLabel.toLowerCase()}`}
                  >
                    Yes
                  </InformationRows.Row>
                  <InformationRows.Row label="Activate extended period after">
                    <FormattedMessage
                      defaultMessage={`{duration} {duration, plural,
                        =0 {year}
                        one {year}
                        other {years}
                    } of service`}
                      values={{
                        duration:
                          ctmsGrant.postTerminationExercisePeriod
                            .thresholdForExtensionInYears,
                      }}
                    />
                  </InformationRows.Row>
                  <InformationRows.Row label="Period after activation">
                    <Duration
                      duration={
                        ctmsGrant.postTerminationExercisePeriod
                          .extensionDuration
                      }
                      durationUnit={
                        ctmsGrant.postTerminationExercisePeriod
                          .extensionDurationUnit
                      }
                    />
                  </InformationRows.Row>
                </>
              ) : (
                <InformationRows.Row
                  label={`Variable ${ptepLabel.toLowerCase()}`}
                >
                  No
                </InformationRows.Row>
              ))}
          </InformationRows>
        )}
      </div>
    </DetailsBox>
  );
};
