import { EmptyState } from "../../../components/EmptyState";
import { useApplicationSupportEmailAddress } from "../../../hooks/useApplicationTheme";

export function EquityHandbooksCountryEquityTypeEmptyState() {
  const mailtoSubject = encodeURI(`Questions regarding equity handbook `);
  const supportEmailAddress = useApplicationSupportEmailAddress();

  return (
    <EmptyState
      callToAction={
        <a href={`mailto:${supportEmailAddress}?subject=${mailtoSubject}`}>
          Contact us
        </a>
      }
    >
      The guide is temporarily unavailable
    </EmptyState>
  );
}
