import { StandaloneLink, Text } from "@remote-com/norma";
import { IconV2OutlineArrowRight } from "@remote-com/norma/icons/IconV2OutlineArrowRight";
import { IconV2OutlineCheck } from "@remote-com/norma/icons/IconV2OutlineCheck";
import { IconV2OutlineChevronDown } from "@remote-com/norma/icons/IconV2OutlineChevronDown";
import { AnimatePresence, motion } from "motion/react";
import { useState } from "react";
import { graphql, useFragment } from "react-relay";
import { generatePath, Link, To } from "react-router-dom";

import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { APPLICATION_ROUTES } from "../../../../paths";
import { FinishSetupCard_EssentialsOverview_Organization$key } from "./__generated__/FinishSetupCard_EssentialsOverview_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment FinishSetupCard_EssentialsOverview_Organization on Organization {
    id
    latestFairMarketValue {
      __typename
    }
    fullyDilutedShares
    esopPoolShares
    latestPricePerShare {
      __typename
    }
  }
`;

export const FinishSetupCard: React.FC<{
  organizationFragment: FinishSetupCard_EssentialsOverview_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const [isOpen, setIsOpen] = useState(false);
  const isFairMarketValueCompleted = !!organization.latestFairMarketValue;
  const isPlanParametersCompleted =
    !!organization.fullyDilutedShares &&
    !!organization.latestPricePerShare &&
    !!organization.esopPoolShares;

  if (isFairMarketValueCompleted && isPlanParametersCompleted) {
    return null;
  }

  return (
    <RoundedBox className="divide-y-[0.5px] divide-grey-300" withBorder>
      <motion.header
        initial={false}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div className="flex items-center justify-between px-6 py-4">
          <Text variant="lgMedium">
            Finish setting up Remote Equity Essentials
          </Text>

          <StandaloneLink size="md">
            <div className="flex items-center gap-2">
              <span>Collapse</span>
              <motion.div animate={{ rotate: isOpen ? 180 : 0 }}>
                <IconV2OutlineChevronDown className="h-4 w-4 text-brand-600" />
              </motion.div>
            </div>
          </StandaloneLink>
        </div>
      </motion.header>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            animate="open"
            exit="collapsed"
            initial="collapsed"
            key="content"
            variants={{
              collapsed: { height: 0, opacity: 0 },
              open: { height: "auto", opacity: 1 },
            }}
          >
            <div className="divide-y-[0.5px] divide-grey-300">
              <FinishSetupCardRow
                completed={isFairMarketValueCompleted}
                to={generatePath(
                  APPLICATION_ROUTES.organizationCompanySettingsAddNewFairMarketValue,
                  {
                    organizationId: organization.id,
                  },
                )}
              >
                Add fair market value
              </FinishSetupCardRow>
              <FinishSetupCardRow
                completed={isPlanParametersCompleted}
                to={generatePath(
                  APPLICATION_ROUTES.organizationCompanySettingsPlanParameters,
                  {
                    organizationId: organization.id,
                  },
                )}
              >
                Add key plan parameters
              </FinishSetupCardRow>
            </div>
          </motion.section>
        )}
      </AnimatePresence>
    </RoundedBox>
  );
};

const FinishSetupCardRowWrapper: React.FC<{
  children: React.ReactNode;
  completed: boolean;
  to: To;
}> = ({ children, completed, to }) => {
  if (completed) {
    return children;
  }

  return (
    <div>
      <Link to={to}>{children}</Link>
    </div>
  );
};

const FinishSetupCardRow: React.FC<{
  children: React.ReactNode;
  completed: boolean;
  to: To;
}> = ({ children, completed, to }) => {
  return (
    <FinishSetupCardRowWrapper completed={completed} to={to}>
      <div className="flex items-center gap-4 p-6">
        {completed ? (
          <div className="h-5 w-5 rounded-full bg-grey-900 p-0.5">
            <IconV2OutlineCheck className="h-full w-full text-white" />
          </div>
        ) : (
          <div className="h-5 w-5 rounded-full border-2 border-grey-300"></div>
        )}
        <Text className="flex-1" variant="smMedium">
          {children}
        </Text>
        {!completed && (
          <IconV2OutlineArrowRight className="h-6 w-6 text-grey-600" />
        )}
      </div>
    </FinishSetupCardRowWrapper>
  );
};
