/**
 * @generated SignedSource<<fd12709fa138697a2f372534f92387d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GranteePortalProjectionScenarioKey = "CURRENT_VALUE" | "FIRST_ROUND" | "SECOND_ROUND" | "THIRD_ROUND";
import { FragmentRefs } from "relay-runtime";
export type GranteePortalSettingsProjectionSection_Organization$data = {
  readonly granteePortalProjectionScenarios: ReadonlyArray<{
    readonly additionalInformation: string | null;
    readonly displayed: boolean;
    readonly key: GranteePortalProjectionScenarioKey;
    readonly multiple: number;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"GranteePortalSettingsProjectionSection_GranteePortalProjectionScenario" | "ProjectionTable_GranteePortalProjectionScenarios">;
  }>;
  readonly granteePortalSettings: {
    readonly showProjectionScenarios: boolean;
  };
  readonly id: string;
  readonly latestPricePerShare: {
    readonly value: number;
  } | null;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"CurrencyUnit_Organization" | "FormattedCurrency_Organization" | "ProjectionGraph_Organization" | "ProjectionTable_Organization" | "useCurrencyFormatter_Organization" | "useOrganizationSharesUtil_Organization">;
  readonly " $fragmentType": "GranteePortalSettingsProjectionSection_Organization";
};
export type GranteePortalSettingsProjectionSection_Organization$key = {
  readonly " $data"?: GranteePortalSettingsProjectionSection_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteePortalSettingsProjectionSection_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteePortalSettingsProjectionSection_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "GranteePortalSettings",
      "kind": "LinkedField",
      "name": "granteePortalSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showProjectionScenarios",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PricePerShare",
      "kind": "LinkedField",
      "name": "latestPricePerShare",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GranteePortalProjectionScenario",
      "kind": "LinkedField",
      "name": "granteePortalProjectionScenarios",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "multiple",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayed",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "additionalInformation",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GranteePortalSettingsProjectionSection_GranteePortalProjectionScenario"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectionTable_GranteePortalProjectionScenarios"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationSharesUtil_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurrencyUnit_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedCurrency_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useCurrencyFormatter_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectionGraph_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectionTable_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "c05635f6df0b15d503ed04bb840a5d88";

export default node;
