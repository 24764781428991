import { BoxedIcon, Text } from "@remote-com/norma";
import { IconV2DuotoneBookOpen } from "@remote-com/norma/icons/IconV2DuotoneBookOpen";
import { first } from "lodash";
import { useState } from "react";
import { graphql, useFragment } from "react-relay";

import { EquityHandbooksCountryEquityTypeContent } from "../../../components/EquityHandbooksCountryEquityTypeContent";
import { GrantRelatedHydratedContent } from "../../../components/GrantRelatedHydratedContent";
import { SectionHero } from "../../../components/SectionHero";
import { Switch } from "../../../components/ui/Switch";
import { EmployeePortalReportingAndGeneralTaxationDetailsSection_Grantee$key } from "./__generated__/EmployeePortalReportingAndGeneralTaxationDetailsSection_Grantee.graphql";

const GRANTEE_FRAGMENT = graphql`
  fragment EmployeePortalReportingAndGeneralTaxationDetailsSection_Grantee on Grantee {
    ctmsGrants(
      grantStatusIn: [Active, Terminated]
      orderBy: { field: vestingStartDate, direction: ASC }
    ) {
      label
      matchingEquityTypeHandbook {
        ...EquityHandbooksCountryEquityTypeContent_EquityTypeHandbook
      }
      ...GrantRelatedHydratedContent_CTMSGrant
      taxationGranteePortalContent {
        ...GrantRelatedHydratedContent_AdminPortalContent
      }
      reportingGranteePortalContent {
        ...GrantRelatedHydratedContent_AdminPortalContent
      }
    }
  }
`;

export function EmployeePortalReportingAndGeneralTaxationDetailsSection({
  granteeFragment,
}: {
  granteeFragment: EmployeePortalReportingAndGeneralTaxationDetailsSection_Grantee$key;
}) {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  const firstCTMSGrant = first(grantee.ctmsGrants);

  if (!firstCTMSGrant) {
    throw new Error("Unexpected: grantee has no grants");
  }

  const [selectedGrant, setSelectedGrant] = useState(firstCTMSGrant);

  return (
    <SectionHero id="reporting-and-general-taxation-details" withBorder>
      <div className="space-y-6">
        <div className="flex items-center gap-3">
          <BoxedIcon Icon={IconV2DuotoneBookOpen} size="sm" tone="yellow" />
          <Text variant="lgMedium">
            Let&apos;s look at what you need to do, and when, to comply with
            your legal obligations.
          </Text>
        </div>

        <div>
          <Switch
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.label}
            name="grant"
            onChange={setSelectedGrant}
            options={grantee.ctmsGrants}
            selectedOption={selectedGrant}
          />
        </div>

        {selectedGrant.matchingEquityTypeHandbook && (
          <EquityHandbooksCountryEquityTypeContent
            equityTypeHandbookFragment={
              selectedGrant.matchingEquityTypeHandbook
            }
          />
        )}

        {!selectedGrant.matchingEquityTypeHandbook && (
          <div className="space-y-4">
            {(!selectedGrant.taxationGranteePortalContent ||
              selectedGrant.reportingGranteePortalContent) && (
              <GrantRelatedHydratedContent
                adminPortalContentFragment={
                  selectedGrant.reportingGranteePortalContent
                }
                ctmsGrantFragment={selectedGrant}
              />
            )}
            {selectedGrant.taxationGranteePortalContent && (
              <GrantRelatedHydratedContent
                adminPortalContentFragment={
                  selectedGrant.taxationGranteePortalContent
                }
                ctmsGrantFragment={selectedGrant}
              />
            )}
          </div>
        )}
      </div>
    </SectionHero>
  );
}
