import { captureException } from "@sentry/react";
import { compact, isEmpty } from "lodash";
import React, {
  startTransition,
  useCallback,
  useMemo,
  useState,
  useTransition,
} from "react";
import { FormattedMessage } from "react-intl";
import { useFragment, useRefetchableFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useAlerter } from "../../../../../components/Alerter";
import { ConfirmSubmitBoardConsentSlide } from "../../../../../components/ConfirmSubmitBoardConsentSlide/ConfirmSubmitBoardConsentSlide";
import { EmptyListPlaceholder } from "../../../../../components/EmptyListPlaceholder/EmptyListPlaceholder";
import { Page } from "../../../../../components/Page";
import { SelectedItemsActionCard } from "../../../../../components/SelectedItemsActionCard";
import { SendGrantsBackToDraftButton } from "../../../../../components/SendGrantsBackToDraftButton";
import { useToaster } from "../../../../../components/Toaster";
import { Alert } from "../../../../../components/ui/Alert";
import { Button } from "../../../../../components/ui/Button";
import { Toast } from "../../../../../components/ui/Toast";
import { ValuationWarningMessage } from "../../../../../components/ValuationWarningMessage";
import { useApplicationSupportEmailAddress } from "../../../../../hooks/useApplicationTheme";
import { useBoolean } from "../../../../../hooks/useBoolean";
import { useDebounced } from "../../../../../hooks/useDebounced";
import { useOrganizationCTMS } from "../../../../../hooks/useOrganizationCTMS";
import { useQuery } from "../../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../../hooks/useSafeMutation";
import { LegalWorkflowLayout } from "../../../../../layouts/LegalWorkflowLayout";
import { useOrganizationIdParam } from "../../../../../paths";
import NotFoundPage from "../../../../NotFound/NotFound";
import { GrantsTable } from "../GrantsTable";
import { useInstrumentValuationGuard } from "../useInstrumentValuationGuard";
import { useMismatchBetweenCtmsAndEasopCapTableGuard } from "../useMismatchBetweenCtmsAndEasopCapTableGuard";
import { useTaxFavoredSubplanExpiredGuard } from "../useTaxFavoredSubplanExpiredGuard";
import { BoardApproval_Organization$key } from "./__generated__/BoardApproval_Organization.graphql";
import { BoardApproval_Query } from "./__generated__/BoardApproval_Query.graphql";
import {
  BatchEasopGrantsAndCreateBoardConsentResultFailureError,
  BoardApproval_SendDraftsToBoard_Mutation,
} from "./__generated__/BoardApproval_SendDraftsToBoard_Mutation.graphql";
import { BoardApproval_Viewer$key } from "./__generated__/BoardApproval_Viewer.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment BoardApproval_Organization on Organization
  @refetchable(queryName: "BoardApproval_Organization_RefetchQuery")
  @argumentDefinitions(
    organizationId: { type: "OrganizationId!" }
    easopGrantsSearch: { type: "String", defaultValue: "" }
  ) {
    id
    name
    searchedReadyForConsentEasopGrants: easopGrants(
      filters: { statusIn: [Reviewed], search: $easopGrantsSearch }
    ) {
      ...GrantsTable_EasopGrant @arguments(organizationId: $organizationId)
    }
    readyForConsentEasopGrants: easopGrants(filters: { statusIn: [Reviewed] }) {
      id
      notReadyForBoardConsentReasons
      instrumentValuationType
      ...ConfirmSubmitBoardConsentSlide_EasopGrants
    }
    latestFairMarketValue {
      valuationFirm
    }
    valuationWarnings {
      reason
      valuationType
      ...ValuationWarningMessage_ValuationWarning
    }
    ...ConfirmSubmitBoardConsentSlide_Organization
    ...GrantsTable_Organization
    ...ValuationWarningMessage_Organization
    ...LegalWorkflowLayout_Organization
    ...useOrganizationCTMS_Organization
    ...useMismatchBetweenCtmsAndEasopCapTableGuard_Organization
    ...useTaxFavoredSubplanExpiredGuard_Organization
    ...ValuationWarningMessage_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment BoardApproval_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    isAllowedToManageOrganization(organizationId: $organizationId)
    ...GrantsTable_Account
  }
`;

const SEND_DRAFTS_TO_BOARD_MUTATION = graphql`
  mutation BoardApproval_SendDraftsToBoard_Mutation(
    $easopGrantsIds: [UUID!]!
    $organizationId: OrganizationId!
    $acknowledged409ARenewalObligation: Boolean!
    $confirmedBoardMembersList: Boolean!
    $grantsRequireEligibilityAcknowledgmentChecked: Boolean!
    $boardConsentHandledOutsideEasop: Boolean!
    $boardConsentSignatureDate: Date
    $signedBoardConsentDocumentIds: [String!]
  ) {
    batchEasopGrantsAndCreateBoardConsent(
      easopGrantsIds: $easopGrantsIds
      organizationId: $organizationId
      acknowledged409ARenewalObligation: $acknowledged409ARenewalObligation
      confirmedBoardMembersList: $confirmedBoardMembersList
      grantsRequireEligibilityAcknowledgmentChecked: $grantsRequireEligibilityAcknowledgmentChecked
      boardConsentHandledOutsideEasop: $boardConsentHandledOutsideEasop
      boardConsentSignatureDate: $boardConsentSignatureDate
      signedBoardConsentDocumentIds: $signedBoardConsentDocumentIds
    ) {
      __typename
      ... on BatchEasopGrantsAndCreateBoardConsentResultFailure {
        error
      }
      ... on BatchEasopGrantsAndCreateBoardConsentResultSuccess {
        easopGrants {
          grantStatus
        }
      }
    }
  }
`;

const AdminEquityPrepareYourGrantsBoardApprovalPage_: React.FC<{
  organizationFragment: BoardApproval_Organization$key;
  viewerFragment: BoardApproval_Viewer$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const [organization, refetchOrganization] = useRefetchableFragment(
    ORGANIZATION_FRAGMENT,
    organizationFragment,
  );
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const organizationCTMS = useOrganizationCTMS({
    organizationFragment: organization,
  });
  const [grantSelection, setGrantSelection] = useState<Set<string>>(new Set());
  const selectedGrants = useMemo(
    () =>
      organization.readyForConsentEasopGrants.filter((grant) =>
        grantSelection.has(grant.id),
      ),
    [grantSelection, organization.readyForConsentEasopGrants],
  );
  const [sendDraftsToBoard, sendDraftsToBoardIsInFlight] =
    useSafeMutation<BoardApproval_SendDraftsToBoard_Mutation>(
      SEND_DRAFTS_TO_BOARD_MUTATION,
    );
  const toaster = useToaster();

  const {
    setFalse: closeConfirmSubmitBoardConsentSlide,
    setTrue: openConfirmSubmitBoardConsentSlide,
    value: isConfirmSubmitBoardConsentSlideOpen,
  } = useBoolean(false);

  const alerter = useAlerter();

  const [searchTransitionIsInProgress, startSearchTransition] = useTransition();

  const {
    isDebouncing: searchIsDebouncing,
    liveState: searchInputValue,
    setState: setSearchInputValue,
  } = useDebounced<string>({
    delay: 1000,
    initialState: "",
    onDebounce: useCallback(
      (search: string) => {
        startSearchTransition(() => {
          refetchOrganization({
            easopGrantsSearch: search,
          });
        });
      },
      [refetchOrganization],
    ),
  });

  const handleDataUpdated = useCallback(() => {
    startTransition(() => {
      refetchOrganization({
        easopGrantsSearch: searchInputValue,
      });
    });
  }, [refetchOrganization, searchInputValue]);

  const supportEmailAddress = useApplicationSupportEmailAddress();

  const handleMutationError = useCallback(
    (error: BatchEasopGrantsAndCreateBoardConsentResultFailureError) => {
      switch (error) {
        case "DIFFERENT_ACCELERATION_CLAUSE":
          alerter.push(
            <Alert title="Grants have different acceleration clauses">
              Some grants have different acceleration clauses. Unfortunately we
              cannot let you send them in the same board consent for now. Please
              send your grants in different batches or{" "}
              <a
                className="font-medium text-primary"
                href={`mailto:${supportEmailAddress}?subject=${encodeURI(
                  `[${organization.name}] Why can't I group grants with different acceleration clause in the same board consent?`,
                )}`}
              >
                contact us
              </a>
              .
            </Alert>,
          );
      }
    },
    [alerter, organization.name, supportEmailAddress],
  );

  const handleConfirmSubmitBoardConsentSlideSubmit = useCallback(
    async ({
      acknowledged409ARenewalObligation,
      boardConsentHandledOutsideEasop,
      boardConsentSignatureDate,
      confirmedBoardMembersList,
      grantsRequireEligibilityAcknowledgmentChecked,
      signedBoardConsentDocumentIds,
    }: {
      acknowledged409ARenewalObligation: boolean;
      boardConsentHandledOutsideEasop: boolean;
      boardConsentSignatureDate?: null | string;
      confirmedBoardMembersList: boolean;
      grantsRequireEligibilityAcknowledgmentChecked: boolean;
      signedBoardConsentDocumentIds?: null | string[];
    }) => {
      const easopGrantsIds = selectedGrants.map((grant) => grant.id);

      // Temporary safeguard to prevent sending grants to board consent if there is no fair market value
      // https://linear.app/remote/issue/REQ-121/temporary-safeguard-for-board-consent-on-organization-board-approved
      if (!organization.latestFairMarketValue?.valuationFirm) {
        alerter.push(
          <Alert title="We cannot let you move forward with the Board Consent">
            We will get back to you as soon as possible
          </Alert>,
        );

        captureException(
          new Error("cannot sent to BC cause no valuation firm"),
          {
            extra: {
              easopGrantsIds,
            },
          },
        );

        return;
      }

      const { batchEasopGrantsAndCreateBoardConsent: result } =
        await sendDraftsToBoard({
          variables: {
            acknowledged409ARenewalObligation,
            boardConsentHandledOutsideEasop,
            boardConsentSignatureDate: boardConsentHandledOutsideEasop
              ? boardConsentSignatureDate
              : null,
            confirmedBoardMembersList,
            easopGrantsIds,
            grantsRequireEligibilityAcknowledgmentChecked,
            organizationId: organization.id,
            signedBoardConsentDocumentIds: boardConsentHandledOutsideEasop
              ? signedBoardConsentDocumentIds
              : null,
          },
        });

      if (
        result.__typename ===
        "BatchEasopGrantsAndCreateBoardConsentResultFailure"
      ) {
        handleMutationError(result.error);
        return;
      }

      closeConfirmSubmitBoardConsentSlide();

      handleDataUpdated();

      setGrantSelection(new Set());

      if (boardConsentHandledOutsideEasop) {
        toaster.push(
          <Toast title="Wonderful!">
            <FormattedMessage
              defaultMessage={`{grantCount, plural,
                one {# grant}
                other {# grants}
              } have been successfully sent for implementation in {ctmsName}`}
              values={{
                ctmsName: organizationCTMS?.name ?? "CTMS",
                grantCount: easopGrantsIds.length,
              }}
            />
          </Toast>,
        );
      } else {
        toaster.push(
          <Toast title="Wonderful!">
            <FormattedMessage
              defaultMessage={`{grantCount, plural,
              one {# draft}
              other {# drafts}
            } successfully sent to your board!`}
              values={{
                grantCount: easopGrantsIds.length,
              }}
            />
          </Toast>,
        );
      }
    },
    [
      selectedGrants,
      sendDraftsToBoard,
      organization.id,
      closeConfirmSubmitBoardConsentSlide,
      handleDataUpdated,
      toaster,
      organizationCTMS,
      handleMutationError,
    ],
  );

  const { showMismatchBetweenCtmsAndEasopCapTableGuard } =
    useMismatchBetweenCtmsAndEasopCapTableGuard({
      organizationFragment: organization,
    });
  const { showTaxFavoredSubplanExpiredGuard } =
    useTaxFavoredSubplanExpiredGuard({
      organizationFragment: organization,
    });
  const { showEasopGrantsOutdatedGuard, showValuationExpiredGuard } =
    useInstrumentValuationGuard();

  const handleSendDraftsToBoardButtonClick = useCallback(() => {
    const firstNotReadyForBoardConsentGrant = selectedGrants.find(
      (grant) => grant.notReadyForBoardConsentReasons.length > 0,
    );

    if (firstNotReadyForBoardConsentGrant?.notReadyForBoardConsentReasons[0]) {
      switch (
        firstNotReadyForBoardConsentGrant.notReadyForBoardConsentReasons[0]
      ) {
        case "CAP_TABLE_MISMATCH":
          showMismatchBetweenCtmsAndEasopCapTableGuard();
          break;
        case "GRANTEE_CONTRACT_NOT_STARTED":
          alerter.push(
            <Alert title="You are not allowed to proceed with this draft selection.">
              Some drafts relate to grantees who haven’t started working with
              the company yet.
              <br />
              To avoid issues with the validity of the grants, we’ve temporarily
              disabled the possibility to send them to your board members for
              approval. For such grants, we’ll let you release the draft to the
              board on the grantee’s first day with the company.
            </Alert>,
          );
          break;
        case "TAX_FAVORED_SUBPLAN_EXPIRED":
          showTaxFavoredSubplanExpiredGuard();
          break;
        case "VALUATION_EXPIRED":
        case "VALUATION_MANUALLY_INVALIDATED":
        case "VALUATION_MISSING":
        case "VALUATION_NOT_BOARD_APPROVED":
        case "VALUATION_PENDING_BOARD_APPROVAL":
          showValuationExpiredGuard({
            valuationType:
              firstNotReadyForBoardConsentGrant.instrumentValuationType,
          });
          break;
        case "VALUATION_OUTDATED":
          showEasopGrantsOutdatedGuard({
            valuationType:
              firstNotReadyForBoardConsentGrant.instrumentValuationType,
          });
          break;
        case "NOT_REVIEWED":
          throw new Error("Unexpected: Invalid status");
      }
      return;
    }

    openConfirmSubmitBoardConsentSlide();
  }, [
    selectedGrants,
    openConfirmSubmitBoardConsentSlide,
    showMismatchBetweenCtmsAndEasopCapTableGuard,
    alerter,
    showTaxFavoredSubplanExpiredGuard,
    showValuationExpiredGuard,
    showEasopGrantsOutdatedGuard,
  ]);

  return (
    <LegalWorkflowLayout
      onSearchChange={setSearchInputValue}
      organizationFragment={organization}
      searchIsLoading={searchTransitionIsInProgress || searchIsDebouncing}
      searchPlaceholder="Search drafts..."
      searchValue={searchInputValue}
      subtitle="Grants ready to be sent to your board members for approval"
      title="Board approval"
      topBarActionsRender={({ mainContentIsScrolled }) => {
        if (!viewer.isAllowedToManageOrganization) return null;

        return (
          <SelectedItemsActionCard
            actions={
              <>
                <SendGrantsBackToDraftButton
                  onCompleted={() => {
                    setGrantSelection(new Set());
                  }}
                  selectedGrants={selectedGrants}
                />
                <Button
                  disabled={isEmpty(selectedGrants)}
                  fullWidth
                  loading={sendDraftsToBoardIsInFlight}
                  onClick={handleSendDraftsToBoardButtonClick}
                  size="small"
                  variant="Primary Full"
                >
                  Proceed
                </Button>
              </>
            }
            compact={mainContentIsScrolled}
            emptyListLabel="Select drafts"
            itemCount={selectedGrants.length}
            loading={sendDraftsToBoardIsInFlight}
            pluralLabel="drafts selected"
            singularLabel="draft selected"
          />
        );
      }}
      warnings={
        <>
          {organization.valuationWarnings.map((warning) => (
            <ValuationWarningMessage
              key={`${warning.reason}-${warning.valuationType}`}
              onValuationSentForReview={() => {
                handleDataUpdated();
              }}
              organizationFragment={organization}
              valuationWarningFragment={warning}
            />
          ))}
        </>
      }
    >
      <ConfirmSubmitBoardConsentSlide
        easopGrantsFragment={selectedGrants}
        onClose={closeConfirmSubmitBoardConsentSlide}
        onSubmit={handleConfirmSubmitBoardConsentSlideSubmit}
        open={isConfirmSubmitBoardConsentSlideOpen}
        organizationFragment={organization}
        selectedItemsCount={selectedGrants.length}
        submissionInProgress={sendDraftsToBoardIsInFlight}
        type="GRANT"
      />

      {isEmpty(organization.readyForConsentEasopGrants) ? (
        <EmptyListPlaceholder
          hideImage
          subtitle="Feel free to create new grants or plan equity from your planning"
          title="No drafts ready for consent"
        />
      ) : (
        <GrantsTable
          columns={compact([
            "Draft",
            "Employee",
            "Ownership",
            "Details",
            "Strike price",
            "Grant type",
            viewer.isAllowedToManageOrganization && "Actions",
          ])}
          easopGrantsFragment={organization.searchedReadyForConsentEasopGrants}
          emptyListPlaceholder="No drafts match your search"
          grantSelection={grantSelection}
          onGrantSelectionChange={setGrantSelection}
          onUpdate={handleDataUpdated}
          organizationFragment={organization}
          showBoardConsentReadinessStatus
          viewerFragment={viewer}
        />
      )}
    </LegalWorkflowLayout>
  );
};

const QUERY = graphql`
  query BoardApproval_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...BoardApproval_Organization @arguments(organizationId: $organizationId)
    }
    me {
      ...BoardApproval_Viewer @arguments(organizationId: $organizationId)
    }
  }
`;

const AdminEquityPrepareYourGrantsBoardApprovalPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<BoardApproval_Query>(QUERY, {
    organizationId,
  });

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="New Equity Flow"
      analyticsName="Admin - New Equity Board Approval"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} board approval`}
    >
      <AdminEquityPrepareYourGrantsBoardApprovalPage_
        organizationFragment={query.organization}
        viewerFragment={query.me}
      />
    </Page>
  );
};

export default AdminEquityPrepareYourGrantsBoardApprovalPage;
