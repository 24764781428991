import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { FormattedCurrency } from "../../../../components/Formatted/FormattedCurrency";
import { FormattedPercentage } from "../../../../components/Formatted/FormattedPercentage";
import { Typography } from "../../../../components/ui/Typography";
import { useOrganizationSharesUtil } from "../../../../hooks/useOrganizationSharesUtil";
import { GrantOwnershipRow_Grantee$key } from "./__generated__/GrantOwnershipRow_Grantee.graphql";
import { GrantOwnershipRow_Organization$key } from "./__generated__/GrantOwnershipRow_Organization.graphql";
import { GrantReviewRow } from "./GrantReviewRow";

const ORGANIZATION_FRAGMENT = graphql`
  fragment GrantOwnershipRow_Organization on Organization {
    ...useOrganizationSharesUtil_Organization
    ...FormattedCurrency_Organization
  }
`;

const GRANTEE_FRAGMENT = graphql`
  fragment GrantOwnershipRow_Grantee on Grantee {
    name
    workRelationship
    managementCompanyName
  }
`;

export const GrantOwnershipRow: React.FC<{
  granteeFragment: GrantOwnershipRow_Grantee$key;
  organizationFragment: GrantOwnershipRow_Organization$key;
  quantityGranted: number;
}> = ({ granteeFragment, organizationFragment, quantityGranted }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  const { sharesToFullyDilutedRatio, sharesToValue } =
    useOrganizationSharesUtil({
      organizationFragment: organization,
    });

  const grantedOwnership = sharesToFullyDilutedRatio(quantityGranted);
  const grantedValue = sharesToValue(quantityGranted);

  return (
    <GrantReviewRow>
      <div className="flex justify-center gap-16">
        <div className="space-y-2 text-center">
          <Typography as="div" variant="Medium/Caption">
            Grantee
          </Typography>
          <div>
            <Typography as="div" variant="Regular/Small">
              {grantee.name}
            </Typography>
            {grantee.workRelationship === "ContractorManagementCompany" && (
              <Typography
                as="div"
                className="text-black-05"
                variant="Regular/Caption"
              >
                represented by
                <br />
                {grantee.managementCompanyName}
              </Typography>
            )}
          </div>
        </div>

        {grantedOwnership !== null && (
          <div className="space-y-2 text-center">
            <Typography as="div" variant="Medium/Caption">
              Ownership %
            </Typography>
            <Typography as="div" variant="Regular/Small">
              <FormattedPercentage value={grantedOwnership} />
            </Typography>
          </div>
        )}

        <div className="space-y-2 text-center">
          <Typography as="div" variant="Medium/Caption">
            # Shares
          </Typography>
          <Typography as="div" variant="Regular/Small">
            <FormattedNumber value={quantityGranted} />
          </Typography>
        </div>

        {grantedValue !== null && (
          <div className="space-y-2 text-center">
            <Typography as="div" variant="Medium/Caption">
              Value today
            </Typography>
            <Typography as="div" variant="Regular/Small">
              <FormattedCurrency
                organizationFragment={organization}
                value={grantedValue}
              />
            </Typography>
          </div>
        )}
      </div>
    </GrantReviewRow>
  );
};
