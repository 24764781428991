import { isNil, sumBy } from "lodash";
import { useCallback, useMemo } from "react";
import { FormattedPlural } from "react-intl";
import { graphql, useFragment } from "react-relay";
import { generatePath, useNavigate } from "react-router-dom";

import {
  BentoCard,
  BentoCardCell,
  BentoCardRow,
  BentoCardValue,
} from "../../../../components/BentoCard";
import { CurrencyUnit } from "../../../../components/CurrencyUnit";
import { EmployeeEquityGridLevelTag } from "../../../../components/EmployeeEquityGridLevelTag";
import { FormattedCurrency } from "../../../../components/Formatted/FormattedCurrency";
import { FormattedNumber } from "../../../../components/Formatted/FormattedNumber";
import {
  GranteeFormSlide,
  useGranteeFormSlideState,
} from "../../../../components/GranteeFormSlide";
import {
  NewEquitySelectionModal,
  useNewEquitySelectionModalState,
} from "../../../../components/NewEquitySelectionModal/NewEquitySelectionModal";
import { useOrganizationSharesUtil } from "../../../../hooks/useOrganizationSharesUtil";
import { APPLICATION_ROUTES } from "../../../../paths";
import { OwnershipSection_Employee$key } from "./__generated__/OwnershipSection_Employee.graphql";
import { OwnershipSection_Organization$key } from "./__generated__/OwnershipSection_Organization.graphql";
import { OwnershipSection_Viewer$key } from "./__generated__/OwnershipSection_Viewer.graphql";
import { Section } from "./Section";

const EMPLOYEE_FRAGMENT = graphql`
  fragment OwnershipSection_Employee on Grantee
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    easopGrantsWithoutCtmsGrant {
      quantityGranted
    }
    totalVestedSharesBreakdown {
      total
    }
    totalGrantedSharesBreakdown {
      total
    }
    ...GranteeFormSlide_Grantee
    ...EmployeeEquityGridLevelTag_Grantee
    ...NewEquitySelectionModal_DefaultGrantee
      @arguments(organizationId: $organizationId)
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment OwnershipSection_Organization on Organization {
    id
    isRemoteEquityEssentials
    ...useOrganizationSharesUtil_Organization
    ...EmployeeEquityGridLevelTag_Organization
    ...GranteeFormSlide_Organization
    ...FormattedCurrency_Organization
    ...CurrencyUnit_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment OwnershipSection_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    isAllowedToManageOrganization(organizationId: $organizationId)
  }
`;

export function OwnershipSection({
  employeeFragment,
  onDataUpdated,
  organizationFragment,
  viewerFragment,
}: {
  employeeFragment: OwnershipSection_Employee$key;
  onDataUpdated: () => void;
  organizationFragment: OwnershipSection_Organization$key;
  viewerFragment: OwnershipSection_Viewer$key;
}) {
  const employee = useFragment(EMPLOYEE_FRAGMENT, employeeFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const { sharesToFullyDilutedRatio, sharesToValue } =
    useOrganizationSharesUtil({
      organizationFragment: organization,
    });

  const totalOwnership =
    employee.totalGrantedSharesBreakdown.total +
    employee.easopGrantsWithoutCtmsGrant.reduce(
      (acc, grant) => acc + grant.quantityGranted,
      0,
    );
  const totalValue = sharesToValue(totalOwnership);
  const fullyDilutedRatio = sharesToFullyDilutedRatio(totalOwnership);
  const grantedSharesInDraft = useMemo(
    () =>
      sumBy(
        employee.easopGrantsWithoutCtmsGrant,
        (grant) => grant.quantityGranted,
      ),
    [employee.easopGrantsWithoutCtmsGrant],
  );
  const {
    closeGranteeFormSlide,
    granteeFormSlideState,
    openGranteeFormSlideInEditMode,
  } = useGranteeFormSlideState();
  const {
    hideNewEquitySelectionModal,
    newEquitySelectionModalState,
    showNewEquitySelectionModal,
  } = useNewEquitySelectionModalState();
  const navigate = useNavigate();
  const goToGranteesPage = useCallback(() => {
    void navigate(
      generatePath(APPLICATION_ROUTES.organizationGrantees, {
        organizationId: organization.id,
      }),
    );
  }, [navigate, organization.id]);
  const handleGranteeUpdated = useCallback(() => {
    closeGranteeFormSlide();
    onDataUpdated();
  }, [closeGranteeFormSlide, onDataUpdated]);

  return (
    <>
      <GranteeFormSlide
        onCancel={closeGranteeFormSlide}
        onGranteeCreated={closeGranteeFormSlide}
        onGranteeDeleted={() => {
          closeGranteeFormSlide();
          goToGranteesPage();
        }}
        onGranteeUpdated={handleGranteeUpdated}
        organizationFragment={organization}
        state={granteeFormSlideState}
      />
      <NewEquitySelectionModal
        onClose={hideNewEquitySelectionModal}
        organizationId={organization.id}
        state={newEquitySelectionModalState}
      />
      <Section title="Ownership">
        <div className="space-y-4">
          <EmployeeEquityGridLevelTag
            className="w-full"
            grantedSharesInDraft={grantedSharesInDraft}
            granteeFragment={employee}
            hideActionButtons={
              !viewer.isAllowedToManageOrganization ||
              organization.isRemoteEquityEssentials
            }
            onAddEquityLevelClick={() => {
              openGranteeFormSlideInEditMode(employee);
            }}
            onCreateTopUpClick={() => {
              showNewEquitySelectionModal({ granteeFragment: employee });
            }}
            organizationFragment={organization}
          />
          <BentoCard>
            <BentoCardRow>
              <BentoCardCell title="Total ownership">
                <BentoCardValue
                  main={<FormattedNumber animated value={totalOwnership} />}
                  sub={
                    <FormattedPlural
                      one="share"
                      other="shares"
                      value={totalOwnership}
                    />
                  }
                />
              </BentoCardCell>
              {!isNil(totalValue) && (
                <BentoCardCell title="Value">
                  <BentoCardValue
                    main={
                      <FormattedCurrency
                        animated
                        organizationFragment={organization}
                        value={totalValue}
                      />
                    }
                    sub={<CurrencyUnit organizationFragment={organization} />}
                  />
                </BentoCardCell>
              )}
              {!isNil(fullyDilutedRatio) && (
                <BentoCardCell title="Percentage">
                  <BentoCardValue
                    main={
                      <FormattedNumber
                        animated
                        maximumFractionDigits={2}
                        value={fullyDilutedRatio * 100}
                      />
                    }
                    sub="%"
                  />
                </BentoCardCell>
              )}
            </BentoCardRow>
            <BentoCardRow>
              <BentoCardCell title="Total vested">
                <BentoCardValue
                  main={
                    <FormattedCurrency
                      animated
                      organizationFragment={organization}
                      value={employee.totalVestedSharesBreakdown.total}
                    />
                  }
                  sub={
                    <>
                      /{" "}
                      <FormattedPlural
                        one="share"
                        other="shares"
                        value={employee.totalGrantedSharesBreakdown.total}
                      />
                    </>
                  }
                />
              </BentoCardCell>
              <BentoCardCell title="Number of grants">
                <BentoCardValue
                  main={
                    <FormattedNumber
                      animated
                      value={employee.easopGrantsWithoutCtmsGrant.length}
                    />
                  }
                />
              </BentoCardCell>
            </BentoCardRow>
          </BentoCard>
        </div>
      </Section>
    </>
  );
}
