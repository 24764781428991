import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { FormattedCurrency } from "../../components/Formatted/FormattedCurrency";
import { Progress } from "../../components/ui/Progress";
import { RoundedBox } from "../../components/ui/RoundedBox";
import { Typography } from "../../components/ui/Typography";
import { useDisplayFullyDilutedValuesInGranteePortal } from "../../hooks/useDisplayFullyDilutedValuesInGranteePortal";
import { useGranteePortalGranteeShares } from "../../hooks/useGranteePortal";
import { useOrganizationSharesUtil } from "../../hooks/useOrganizationSharesUtil";
import { GranteeEquity_Grantee$key } from "./__generated__/GranteeEquity_Grantee.graphql";

const GRANTEE_FRAGMENT = graphql`
  fragment GranteeEquity_Grantee on Grantee {
    organization {
      ...useOrganizationSharesUtil_Organization
      ...FormattedCurrency_Organization
    }
    ...useDisplayFullyDilutedValuesInGranteePortal_Grantee
    ...useGranteePortalGranteeShares_Grantee
  }
`;

const Values: React.FC<{
  labelLeft: React.ReactNode;
  labelRight: React.ReactNode;
  valueLeft: React.ReactNode;
  valueRight: React.ReactNode;
}> = ({ labelLeft, labelRight, valueLeft, valueRight }) => {
  return (
    <div className="flex flex-col justify-between gap-4 sm:flex-row">
      <div>
        <Typography
          as="div"
          className="mt-4 flex text-black"
          variant="Regular/Extra Small"
        >
          {labelLeft}
        </Typography>
        <Typography className="mt-2 text-green-06" variant="Medium/Extra Large">
          {valueLeft}
        </Typography>
      </div>
      <div>
        <Typography
          as="div"
          className="mt-4 flex text-black"
          variant="Regular/Extra Small"
        >
          {labelRight}
        </Typography>
        <Typography className="mt-2 text-primary" variant="Medium/Extra Large">
          {valueRight}
        </Typography>
      </div>
    </div>
  );
};

export const GranteeEquity: React.FC<{
  granteeFragment: GranteeEquity_Grantee$key;
}> = ({ granteeFragment }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  const { sharesToValue } = useOrganizationSharesUtil({
    organizationFragment: grantee.organization,
  });

  const { totalGrantedShares, totalVestedShares } =
    useGranteePortalGranteeShares({
      granteeFragment: grantee,
    });

  const grantedValue = sharesToValue(totalGrantedShares);
  const vestedValue = sharesToValue(totalVestedShares);

  const displayFullyDilutedValues = useDisplayFullyDilutedValuesInGranteePortal(
    { granteeFragment: grantee },
  );

  return (
    <RoundedBox className="flex-grow p-6" withBorder withShadow>
      <Typography variant="Medium/Default">Equity value vested</Typography>
      {displayFullyDilutedValues &&
      vestedValue !== null &&
      grantedValue !== null ? (
        <Values
          labelLeft="Potential value already vested"
          labelRight="Potential value fully vested"
          valueLeft={
            <FormattedCurrency
              maximumFractionDigits={0}
              organizationFragment={grantee.organization}
              value={vestedValue}
            />
          }
          valueRight={
            <FormattedCurrency
              maximumFractionDigits={0}
              organizationFragment={grantee.organization}
              value={grantedValue}
            />
          }
        />
      ) : (
        <Values
          labelLeft="Number of shares already vested"
          labelRight="Number of shares fully vested"
          valueLeft={<FormattedNumber value={totalVestedShares} />}
          valueRight={<FormattedNumber value={totalGrantedShares} />}
        />
      )}

      <Progress className="mt-4 h-3" max={totalVestedShares}>
        <Progress.Value
          className="bg-gradient-to-r from-green-06 to-primary-05"
          value={totalGrantedShares}
        />
      </Progress>
    </RoundedBox>
  );
};
