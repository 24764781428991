import { InfoBlock, ListItem } from "@remote-com/norma";
import { compact } from "lodash";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { graphql, useFragment } from "react-relay";

import { CtmsGrantActivePTEPValue } from "../../../../components/CtmsGrantActivePTEPValue";
import { Duration } from "../../../../components/Duration";
import { ShortDate } from "../../../../components/ShortDate";
import { ACCELERATION_CLAUSE_LABELS } from "../../../../services/AccelerationClause";
import { Section } from "../../Grantee/GranteeDetails/Section";
import {
  CTMSGrantTaxation,
  GrantDetailsInformationSection_CTMSGrant$key,
} from "./__generated__/GrantDetailsInformationSection_CTMSGrant.graphql";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment GrantDetailsInformationSection_CTMSGrant on CTMSGrant {
    equityTypeAwardName
    vestingStartDate
    stakeholderSignatureDate
    boardApprovalDate
    isVirtual
    terminationDate
    accelerationClause
    taxation
    activePostTerminationExercisePeriod {
      ...CtmsGrantActivePTEPValue_ActivePostTerminationExercisePeriod
    }
    matchingInstrument {
      equityType {
        exercisable
      }
    }
    postTerminationExercisePeriod {
      __typename
      ... on OrganizationVariablePostTerminationExercisePeriod {
        extensionDuration
        extensionDurationUnit
        thresholdForExtensionInYears
      }
    }
  }
`;

export const TAXATION_LABELS: Record<CTMSGrantTaxation, string> = {
  UPON_LIQUIDITY_EVENT: "Taxed upon liquidity event",
  UPON_VESTING: "Taxed upon vesting",
};

export function GrantDetailsInformationSection({
  className,
  ctmsGrantFragment,
}: {
  className?: string;
  ctmsGrantFragment: GrantDetailsInformationSection_CTMSGrant$key;
}) {
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);

  const variablePTEPFields = useMemo((): ListItem[] => {
    if (
      ctmsGrant.postTerminationExercisePeriod?.__typename !==
      "OrganizationVariablePostTerminationExercisePeriod"
    ) {
      return [
        {
          title: "Variable",
          value: "No",
        },
      ];
    }

    return compact([
      {
        title: "Variable",
        value: "Yes",
      },
      {
        title: "Activate extended period after",
        value: (
          <FormattedMessage
            defaultMessage={`{duration} {duration, plural,
                        =0 {year}
                        one {year}
                        other {years}
                    } of service`}
            values={{
              duration:
                ctmsGrant.postTerminationExercisePeriod
                  .thresholdForExtensionInYears,
            }}
          />
        ),
      },
      {
        title: "Period after activation",
        value: (
          <Duration
            duration={ctmsGrant.postTerminationExercisePeriod.extensionDuration}
            durationUnit={
              ctmsGrant.postTerminationExercisePeriod.extensionDurationUnit
            }
          />
        ),
      },
    ]);
  }, [ctmsGrant]);

  const exercisableFields = useMemo((): ListItem[] => {
    if (ctmsGrant.matchingInstrument?.equityType?.exercisable === false) {
      return [];
    }

    return compact([
      {
        title: ctmsGrant.isVirtual
          ? "Post-termination settlement period"
          : "Post-termination exercise period",
        value: ctmsGrant.activePostTerminationExercisePeriod ? (
          <CtmsGrantActivePTEPValue
            activePostTerminationExercisePeriodFragment={
              ctmsGrant.activePostTerminationExercisePeriod
            }
          />
        ) : undefined,
      },
      ...variablePTEPFields,
      {
        title: "Acceleration clause",
        value: ctmsGrant.accelerationClause ? (
          <>
            {ACCELERATION_CLAUSE_LABELS[ctmsGrant.accelerationClause]}{" "}
            acceleration
          </>
        ) : (
          <>No acceleration</>
        ),
      },
    ]);
  }, [ctmsGrant, variablePTEPFields]);

  const infoBlockList = useMemo((): ListItem[] => {
    return compact([
      {
        title: "Equity type",
        value: ctmsGrant.equityTypeAwardName ?? undefined,
      },
      {
        title: "Vesting start date",
        value: ctmsGrant.vestingStartDate ? (
          <ShortDate value={ctmsGrant.vestingStartDate} />
        ) : undefined,
      },
      {
        title: "Employee signature date",
        value: ctmsGrant.stakeholderSignatureDate ? (
          <ShortDate value={ctmsGrant.stakeholderSignatureDate} />
        ) : undefined,
      },
      {
        title: "Board approval date",
        value: ctmsGrant.boardApprovalDate ? (
          <ShortDate value={ctmsGrant.boardApprovalDate} />
        ) : undefined,
      },
      !!ctmsGrant.terminationDate && {
        title: "Termination date",
        value: <ShortDate value={ctmsGrant.terminationDate} />,
      },
      ...exercisableFields,
      ctmsGrant.taxation !== null && {
        title: "Grant taxation",
        value: TAXATION_LABELS[ctmsGrant.taxation],
      },
    ]);
  }, [ctmsGrant, exercisableFields]);

  return (
    <Section className={className} title="Grant details">
      <InfoBlock list={infoBlockList} />
    </Section>
  );
}
