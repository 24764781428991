import { graphql, useFragment } from "react-relay";

import { FormattedCurrency_Organization$key } from "./__generated__/FormattedCurrency_Organization.graphql";
import { FormattedNumber } from "./FormattedNumber";

const ORGANIZATION_FRAGMENT = graphql`
  fragment FormattedCurrency_Organization on IOrganization {
    defaultCurrencyCode
  }
`;

export const FormattedCurrency: React.FC<{
  animated?: boolean;
  compactDisplay?: "long" | "short";
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
  notation?: "compact" | "standard";
  organizationFragment: FormattedCurrency_Organization$key;
  signDisplay?: "always" | "auto" | "exceptZero" | "negative" | "never";
  value: number;
}> = ({ organizationFragment, value, ...props }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <FormattedNumber
      currency={organization.defaultCurrencyCode}
      style="currency"
      value={value}
      {...props}
    />
  );
};

export const FormattedCustomCurrency: React.FC<
  Omit<
    React.ComponentProps<typeof FormattedCurrency>,
    "organizationFragment"
  > & {
    currency: string;
  }
> = ({ currency, ...props }) => {
  return <FormattedNumber currency={currency} style="currency" {...props} />;
};

export const FormattedUSD: React.FC<
  Omit<React.ComponentProps<typeof FormattedCustomCurrency>, "currency">
> = ({ ...props }) => {
  return <FormattedCustomCurrency currency="USD" {...props} />;
};
