/**
 * @generated SignedSource<<f0d37b580d01934a81ac966a189cd631>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddNew_VestingSchedules_Query$variables = {
  organizationId: string;
};
export type AddNew_VestingSchedules_Query$data = {
  readonly organization: {
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"LargeCenteredDetailsLayout_Organization" | "VestingSchedulePreview_Organization" | "useVestingScheduleForm_Organization">;
  };
};
export type AddNew_VestingSchedules_Query = {
  response: AddNew_VestingSchedules_Query$data;
  variables: AddNew_VestingSchedules_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddNew_VestingSchedules_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "LargeCenteredDetailsLayout_Organization"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "useVestingScheduleForm_Organization"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "VestingSchedulePreview_Organization"
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddNew_VestingSchedules_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToCarta",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "vestingSchedules",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a8124b57e59664b3d8323f92d45297a1",
    "id": null,
    "metadata": {},
    "name": "AddNew_VestingSchedules_Query",
    "operationKind": "query",
    "text": "query AddNew_VestingSchedules_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    name\n    ...LargeCenteredDetailsLayout_Organization\n    ...useVestingScheduleForm_Organization\n    ...VestingSchedulePreview_Organization\n    id\n  }\n}\n\nfragment Footer_Organization on Organization {\n  isConnectedToCarta\n}\n\nfragment LargeCenteredDetailsLayout_Organization on Organization {\n  ...Footer_Organization\n}\n\nfragment VestingSchedulePreview_Organization on Organization {\n  id\n}\n\nfragment useFormattedVestingSchedule_Organization on Organization {\n  vestingSchedules {\n    __typename\n    id\n    name\n  }\n}\n\nfragment useVestingScheduleForm_Organization on Organization {\n  ...useFormattedVestingSchedule_Organization\n}\n"
  }
};
})();

(node as any).hash = "664cea466c5651ee50b552ef7386aa9c";

export default node;
