import classNames from "classnames";
import { motion } from "motion/react";
import React from "react";

const _Switch = <T,>({
  getOptionLabel,
  getOptionValue,
  loading,
  name,
  onChange,
  options,
  selectedOption,
}: {
  getOptionLabel?: (option: T, selected: boolean) => React.ReactNode;
  getOptionValue: (option: T) => string;
  loading?: boolean;
  name: string;
  onChange: (option: T) => void;
  options: readonly T[];
  selectedOption: T;
}) => {
  const selectedOptionValue = getOptionValue(selectedOption);

  return (
    <div
      className={classNames(
        "flex w-fit flex-wrap items-center justify-center rounded-[20px] bg-gray-02 p-1",
        {
          "animate-pulse": loading,
        },
      )}
    >
      {options.map((option) => {
        const optionValue = getOptionValue(option);
        const selectedOptionLabel =
          getOptionLabel?.(option, true) ?? optionValue;
        const notSelectedOptionLabel =
          getOptionLabel?.(option, false) ?? optionValue;
        const selected = selectedOptionValue === optionValue;

        return (
          <button
            className="relative flex flex-1 items-center justify-center px-4 py-1.5 transition-all"
            data-cy={`switch-${optionValue.toLowerCase().replace(/_/g, "-")}`}
            key={optionValue}
            onClick={() => {
              onChange(option);
            }}
            type="button"
          >
            {selected && (
              <motion.div
                className={classNames("absolute inset-0 bg-white shadow-100")}
                layoutId={`${name}-switch`}
                style={{ borderRadius: 24 }}
              />
            )}
            <div
              className={classNames(
                "z-[5] flex items-center justify-center whitespace-nowrap text-SM/Medium transition-all",
                {
                  "text-grey-600": !selected,
                  "text-grey-900": selected,
                },
              )}
            >
              {selected ? selectedOptionLabel : notSelectedOptionLabel}
            </div>
          </button>
        );
      })}
    </div>
  );
};

const Option: React.FC<{
  icon?: React.ReactElement<React.ComponentProps<"svg">>;
  label: React.ReactNode;
  selected: boolean;
}> = ({ icon, label, selected }) => {
  return (
    <div className="flex items-center gap-2">
      {icon &&
        React.cloneElement(icon, {
          className: classNames(
            "h-5 w-5",
            { "text-primary": selected },
            icon.props.className,
          ),
          ...icon.props,
        })}
      <div>{label}</div>
    </div>
  );
};

export const Switch = Object.assign(_Switch, {
  Option,
});
