/**
 * @generated SignedSource<<41574010bef269ea6741d299ff56995d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OwnershipSection_Employee$data = {
  readonly easopGrantsWithoutCtmsGrant: ReadonlyArray<{
    readonly quantityGranted: number;
  }>;
  readonly totalGrantedSharesBreakdown: {
    readonly total: number;
  };
  readonly totalVestedSharesBreakdown: {
    readonly total: number;
  };
  readonly " $fragmentSpreads": FragmentRefs<"EmployeeEquityGridLevelTag_Grantee" | "GranteeFormSlide_Grantee" | "NewEquitySelectionModal_DefaultGrantee">;
  readonly " $fragmentType": "OwnershipSection_Employee";
};
export type OwnershipSection_Employee$key = {
  readonly " $data"?: OwnershipSection_Employee$data;
  readonly " $fragmentSpreads": FragmentRefs<"OwnershipSection_Employee">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "total",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "OwnershipSection_Employee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrantsWithoutCtmsGrant",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quantityGranted",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SharesBreakdown",
      "kind": "LinkedField",
      "name": "totalVestedSharesBreakdown",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SharesBreakdown",
      "kind": "LinkedField",
      "name": "totalGrantedSharesBreakdown",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeFormSlide_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmployeeEquityGridLevelTag_Grantee"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "organizationId",
          "variableName": "organizationId"
        }
      ],
      "kind": "FragmentSpread",
      "name": "NewEquitySelectionModal_DefaultGrantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};
})();

(node as any).hash = "5c56f9aee865ce187790e8fa36009001";

export default node;
