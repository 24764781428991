import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import { formatISO } from "date-fns";
import { isEmpty } from "lodash";
import { Suspense } from "react";
import { Controller, useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { DraftGrantVestingPreview } from "../../../components/DraftGrantVestingPreview";
import { GrantWatchouts } from "../../../components/GrantWatchouts";
import { LoadingPlaceholder } from "../../../components/LoadingPlaceholder";
import { Page } from "../../../components/Page";
import { Button, LinkButton } from "../../../components/ui/Button";
import { FormRow } from "../../../components/ui/Form/FormRow";
import { DatePicker } from "../../../components/ui/Form/Inputs/DatePicker";
import { SelectVestingScheduleInput } from "../../../components/ui/Form/SelectVestingScheduleInput";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { Typography } from "../../../components/ui/Typography";
import { useQuery } from "../../../hooks/useQuery";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import { CreateSlideOverRemote } from "../Grants/Configure/VestingSchedules/CreateSlideOver";
import { VestingSchedule_Grantee$key } from "./__generated__/VestingSchedule_Grantee.graphql";
import { VestingSchedule_Instrument$key } from "./__generated__/VestingSchedule_Instrument.graphql";
import { VestingSchedule_Organization$key } from "./__generated__/VestingSchedule_Organization.graphql";
import { VestingSchedule_Query } from "./__generated__/VestingSchedule_Query.graphql";
import { useAssistedGrantContext } from "./Context";
import { AssistedGrantPageGridLayout, AssistedGrantPageTitle } from "./Shared";

const formSchema = z.object({
  vestingScheduleId: z.string(),
  vestingStartDate: z.string().trim(),
});

export type FieldValues = z.infer<typeof formSchema>;

const ORGANIZATION_FRAGMENT = graphql`
  fragment VestingSchedule_Organization on Organization {
    id
    vestingSchedules {
      id
      name
    }
    ...SelectVestingScheduleInput_Organization
    ...DraftGrantVestingPreview_Organization
  }
`;

const INSTRUMENT_FRAGMENT = graphql`
  fragment VestingSchedule_Instrument on Instrument {
    equityType {
      ...GrantWatchouts_Rule100KWatchout_EquityType
    }
  }
`;

const GRANTEE_FRAGMENT = graphql`
  fragment VestingSchedule_Grantee on Grantee {
    contractStartDate @required(action: THROW)
    # eslint-disable-next-line relay/unused-fields
    workRelationship
    ctmsGrants {
      __typename
    }
    # eslint-disable-next-line relay/unused-fields
    equityGridLevel {
      __typename
    }
    easopGrants {
      __typename
    }
    defaultVestingSchedule {
      id
    }
    ...DraftGrantVestingPreview_Grantee
  }
`;

const AdminAssistedGrantVestingSchedulePage_: React.FC<{
  granteeFragment: VestingSchedule_Grantee$key;
  instrumentFragment: VestingSchedule_Instrument$key;
  organizationFragment: VestingSchedule_Organization$key;
}> = ({ granteeFragment, instrumentFragment, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const instrument = useFragment(INSTRUMENT_FRAGMENT, instrumentFragment);
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  const context = useAssistedGrantContext();
  const navigate = useNavigate();

  if (!context.state.quantityGranted) {
    throw new Error("quantityGranted id not found in context");
  }

  const granteeHasAlreadyBeenGranted =
    grantee.ctmsGrants.length + grantee.easopGrants.length > 0;

  const form = useForm({
    defaultValues: {
      vestingScheduleId:
        context.state.vestingScheduleId ?? grantee.defaultVestingSchedule?.id,
      vestingStartDate:
        context.state.vestingStartDate ??
        (granteeHasAlreadyBeenGranted
          ? formatISO(new Date(), { representation: "date" })
          : grantee.contractStartDate),
    },
    mode: "onChange",
    resolver: zodResolver(formSchema),
  });

  const { vestingScheduleId, vestingStartDate } = form.watch();

  const handleSubmit = form.handleSubmit((_fieldValues) => {
    const fieldValues = _fieldValues as FieldValues;
    const vestingSchedule = organization.vestingSchedules.find(
      ({ id }) => fieldValues.vestingScheduleId === id,
    );
    context.dispatchAction({
      type: "SELECT_VESTING_SCHEDULE",
      vestingScheduleId: fieldValues.vestingScheduleId,
      vestingScheduleName: vestingSchedule?.name || "",
      vestingStartDate: fieldValues.vestingStartDate,
    });
    void navigate(
      generatePath(APPLICATION_ROUTES.organizationAssistedGrantVestingPTEP, {
        organizationId: organization.id,
      }),
    );
  });

  return (
    <>
      <CreateSlideOverRemote.Provider>
        <form onSubmit={handleSubmit}>
          <AssistedGrantPageGridLayout
            header={
              <AssistedGrantPageTitle>
                Which vesting schedule do you want to set?
              </AssistedGrantPageTitle>
            }
          >
            <div className="space-y-6">
              <FormRow
                error={form.formState.errors.vestingScheduleId?.message}
                label="Vesting schedule"
              >
                <SelectVestingScheduleInput.Form
                  control={form.control}
                  disabled={context.isParameterImmutable("vestingScheduleId")}
                  name="vestingScheduleId"
                  organizationFragment={organization}
                />
              </FormRow>

              {instrument.equityType && (
                <GrantWatchouts.Rule100KWatchout
                  action={
                    <Link
                      to={generatePath(
                        APPLICATION_ROUTES.organizationAssistedGrantShares,
                        {
                          organizationId: organization.id,
                        },
                      )}
                    >
                      <Typography
                        className="text-primary"
                        variant="Medium/Extra Small"
                      >
                        ← Back to Shares
                      </Typography>
                    </Link>
                  }
                  equityTypeFragment={instrument.equityType}
                />
              )}

              <Controller
                control={form.control}
                name="vestingStartDate"
                render={({ field, fieldState }) => (
                  <FormRow
                    context={
                      <>
                        Most of the time (and except in case of top-ups), the
                        vesting start date will coincide with the date on which
                        your team member started providing services for the
                        company.
                        <br />
                        💡 a “top-up” is a grant to a team member who had
                        already received equity incentives in the past (i.e.
                        someone who’s not a new joiner)
                      </>
                    }
                    error={fieldState.error?.message}
                    id="vesting-start-date"
                    label="Vesting start date"
                  >
                    <DatePicker
                      className="w-full"
                      onChange={field.onChange}
                      panelPosition="bottom"
                      value={field.value}
                    />
                  </FormRow>
                )}
              />
              {vestingScheduleId &&
              !isEmpty(vestingScheduleId) &&
              !isEmpty(vestingStartDate) ? (
                <RoundedBox className="p-6" withBorder withShadow>
                  <Typography variant="Medium/Extra Small">
                    Vesting preview
                  </Typography>
                  <Suspense fallback={<LoadingPlaceholder />}>
                    <div className="pt-4">
                      <DraftGrantVestingPreview
                        draftGrant={{
                          label: context.state.label,
                          quantityGranted: context.state.quantityGranted,
                          vestingScheduleId,
                          vestingStartDate: vestingStartDate,
                        }}
                        granteeFragment={grantee}
                        organizationFragment={organization}
                      />
                    </div>
                  </Suspense>
                </RoundedBox>
              ) : null}
              <div className="flex justify-between">
                <LinkButton
                  leftIcon={<ArrowLeftIcon />}
                  to={generatePath(
                    APPLICATION_ROUTES.organizationAssistedGrantShares,
                    {
                      organizationId: organization.id,
                    },
                  )}
                  variant="Tertiary Link"
                >
                  Back
                </LinkButton>
                {form.formState.isValid && (
                  <Button
                    data-cy="vestingScheduleNextStepButton"
                    rightIcon={<ArrowRightIcon />}
                    type="submit"
                  >
                    Next
                  </Button>
                )}
              </div>
            </div>
          </AssistedGrantPageGridLayout>
        </form>
      </CreateSlideOverRemote.Provider>
    </>
  );
};

const QUERY = graphql`
  query VestingSchedule_Query(
    $organizationId: OrganizationId!
    $instrumentId: UUID!
    $granteeId: GranteeId!
  ) {
    organization(id: $organizationId) @required(action: THROW) {
      id
      name
      ...VestingSchedule_Organization
    }
    instrument(id: $instrumentId) @required(action: THROW) {
      ...VestingSchedule_Instrument
    }
    grantee(id: $granteeId) @required(action: THROW) {
      ...VestingSchedule_Grantee
    }
  }
`;

const AdminAssistedGrantVestingSchedulePage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const context = useAssistedGrantContext();

  if (!context.state.granteeId) {
    throw new Error("grantee id not found in context");
  }
  if (!context.state.instrumentId) {
    throw new Error("organization instrument id not found in context");
  }
  if (!context.state.quantityGranted) {
    throw new Error("quantityGranted id not found in context");
  }

  const {
    query: { grantee, instrument, organization },
  } = useQuery<VestingSchedule_Query>(QUERY, {
    granteeId: context.state.granteeId,
    instrumentId: context.state.instrumentId,
    organizationId,
  });

  return (
    <Page
      analyticsCategory="Assisted Grant Flow"
      analyticsName="Admin - Assisted Grant Flow - Vesting schedule"
      organizationId={organization.id}
      title={`Admin | ${organization.name} assisted grant flow - vesting schedule`}
    >
      <AdminAssistedGrantVestingSchedulePage_
        granteeFragment={grantee}
        instrumentFragment={instrument}
        organizationFragment={organization}
      />
    </Page>
  );
};

export default AdminAssistedGrantVestingSchedulePage;
