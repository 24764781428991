import { graphql, useFragment } from "react-relay";

import { OrganizationRoleSwitcher } from "../../../OrganizationRoleSwitcher";
import {
  RemoteLikeApplicationLayout_Organization$data,
  RemoteLikeApplicationLayout_Organization$key,
} from "./__generated__/RemoteLikeApplicationLayout_Organization.graphql";
import {
  RemoteLikeApplicationLayout_Viewer$data,
  RemoteLikeApplicationLayout_Viewer$key,
} from "./__generated__/RemoteLikeApplicationLayout_Viewer.graphql";
import remoteEquityLogo from "./remote-equity-logo.png";

const ORGANIZATION_FRAGMENT = graphql`
  fragment RemoteLikeApplicationLayout_Organization on Organization {
    ...OrganizationRoleSwitcher_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment RemoteLikeApplicationLayout_Viewer on Account {
    ...OrganizationRoleSwitcher_Account
  }
`;

const NavBar: React.FC<{
  organization: RemoteLikeApplicationLayout_Organization$data;
  viewer: RemoteLikeApplicationLayout_Viewer$data;
}> = ({ organization, viewer }) => {
  return (
    <div className="border-b border-remote-grey-200 bg-white">
      <div className="container mx-auto flex items-center justify-between gap-4 px-10 py-5.5">
        <img
          alt="Remote Equity Logo"
          className="w-full max-w-[182px] object-contain"
          src={remoteEquityLogo}
        />
        <OrganizationRoleSwitcher
          accountFragment={viewer}
          activeRole="Admin"
          organizationFragment={organization}
        />
      </div>
    </div>
  );
};

export const RemoteLikeApplicationLayout: React.FC<{
  children: React.ReactNode;
  organizationFragment: RemoteLikeApplicationLayout_Organization$key;
  viewerFragment: RemoteLikeApplicationLayout_Viewer$key;
}> = ({ children, organizationFragment, viewerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  return (
    <div className="grid min-h-full grid-rows-[auto_1fr] bg-remote-background-base font-remote-body">
      <div className="sticky top-0 z-10">
        <NavBar organization={organization} viewer={viewer} />
      </div>
      <div className={"container mx-auto h-full px-10 py-6"}>{children}</div>
    </div>
  );
};
