import { isEmpty } from "lodash";
import { z } from "zod";

import {
  CATEGORIES,
  EquityTypeHandbook,
  Event,
  EVENTS,
  Section,
  SECTIONS,
  SECTIONS_MAP,
} from "../../services/EquityHandbookService";

const itemSchema = z.object({
  content: z.string().trim().optional(),
  enabled: z.boolean().optional(),
});

const eventSchema = z
  .object({
    Employee: itemSchema.optional(),
    Employer: itemSchema.optional(),
    enabled: z.boolean().optional(),
    Issuer: itemSchema.optional(),
    nothingToDeclare: z.boolean().optional(),
    PotentialTaxBenefit: itemSchema.optional(),
    PotentialWatchout: itemSchema.optional(),
  })
  .refine(
    (data) => {
      if (!data.enabled) {
        return true;
      }

      if (data.nothingToDeclare) {
        return true;
      }

      return CATEGORIES.some((event) => data[event]?.enabled);
    },
    {
      message: "Please select at least one item",
      path: ["selectAtLeastOneItemError"], // Specifies which field the error belongs to
    },
  )
  .superRefine((data, context) => {
    if (!data.enabled) {
      return;
    }

    if (data.nothingToDeclare) {
      return;
    }

    for (const category of CATEGORIES) {
      const item = data[category];
      if (!item) {
        continue;
      }

      if (!item.enabled) {
        continue;
      }

      if (isEmpty(item.content)) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Please fill in the details",
          path: [category, "content"],
        });
      }
    }
  });

const sectionSchema = z.object({
  Exercise: eventSchema.optional(),
  Grant: eventSchema.optional(),
  Holding: eventSchema.optional(),
  Sale: eventSchema.optional(),
  Vesting: eventSchema.optional(),
});

export const equityTypeHandbookSchema = z.object({
  Reporting: sectionSchema.optional(),
  Taxation: sectionSchema.optional(),
  TaxBenefitsAndWatchouts: sectionSchema.optional(),
});

export type FormValues = z.infer<typeof equityTypeHandbookSchema>;

const _buildDefaultSectionEvent = ({
  event,
  section,
  sectionEvents,
}: {
  event: Event;
  section: Section;
  sectionEvents: EquityTypeHandbook["sectionEvents"];
}): NonNullable<FormValues[Section]>[Event] => {
  const sectionEvent = sectionEvents.find(
    (sectionEvent) =>
      sectionEvent.event === event && sectionEvent.section === section,
  );
  return SECTIONS_MAP[section].itemCategories.reduce<
    NonNullable<NonNullable<FormValues[Section]>[Event]>
  >(
    (eventAccumulator, category) => {
      const item = sectionEvent?.items.find(
        (item) => item.category === category,
      );
      eventAccumulator[category] = {
        content: item?.content,
        enabled: item?.enabled ?? false,
      };
      return eventAccumulator;
    },
    {
      enabled: sectionEvent?.enabled ?? false,
      nothingToDeclare: sectionEvent?.nothingToDeclare ?? false,
    },
  );
};

const _buildDefaultSection = ({
  section,
  sectionEvents,
}: {
  section: Section;
  sectionEvents: EquityTypeHandbook["sectionEvents"];
}): FormValues[Section] => {
  return EVENTS.reduce<NonNullable<FormValues[Section]>>(
    (sectionAccumulator, event) => {
      sectionAccumulator[event] = _buildDefaultSectionEvent({
        event,
        section,
        sectionEvents,
      });
      return sectionAccumulator;
    },
    {},
  );
};

export const buildDefaultValuesFromEquityTypeHandbook = ({
  sectionEvents,
}: {
  sectionEvents: EquityTypeHandbook["sectionEvents"];
}): FormValues => {
  return SECTIONS.reduce<FormValues>(
    (equityTypeHandbookAccumulator, section) => {
      equityTypeHandbookAccumulator[section] = _buildDefaultSection({
        section,
        sectionEvents,
      });
      return equityTypeHandbookAccumulator;
    },
    {},
  );
};
