/**
 * @generated SignedSource<<763af27c2fd74ac030845cab8745195c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GeographiesPage_SuperAdminEquityTypeHandbooks_Query$variables = Record<PropertyKey, never>;
export type GeographiesPage_SuperAdminEquityTypeHandbooks_Query$data = {
  readonly geographies: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"GeographiesPage_SuperAdminEquityTypeHandbooks_Geography">;
  }>;
};
export type GeographiesPage_SuperAdminEquityTypeHandbooks_Query = {
  response: GeographiesPage_SuperAdminEquityTypeHandbooks_Query$data;
  variables: GeographiesPage_SuperAdminEquityTypeHandbooks_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "filters",
    "value": {
      "orderBy": {
        "direction": "ASC",
        "field": "name"
      }
    }
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GeographiesPage_SuperAdminEquityTypeHandbooks_Query",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "Geography",
        "kind": "LinkedField",
        "name": "geographies",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GeographiesPage_SuperAdminEquityTypeHandbooks_Geography"
          }
        ],
        "storageKey": "geographies(filters:{\"orderBy\":{\"direction\":\"ASC\",\"field\":\"name\"}})"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GeographiesPage_SuperAdminEquityTypeHandbooks_Query",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "Geography",
        "kind": "LinkedField",
        "name": "geographies",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Country",
            "kind": "LinkedField",
            "name": "country",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mostRecentEquityTypeHandbookPublicationDate",
            "storageKey": null
          }
        ],
        "storageKey": "geographies(filters:{\"orderBy\":{\"direction\":\"ASC\",\"field\":\"name\"}})"
      }
    ]
  },
  "params": {
    "cacheID": "6b1d96d7aa6619620a461409187473d9",
    "id": null,
    "metadata": {},
    "name": "GeographiesPage_SuperAdminEquityTypeHandbooks_Query",
    "operationKind": "query",
    "text": "query GeographiesPage_SuperAdminEquityTypeHandbooks_Query {\n  geographies(filters: {orderBy: {direction: ASC, field: name}}) {\n    ...GeographiesPage_SuperAdminEquityTypeHandbooks_Geography\n  }\n}\n\nfragment GeographiesPage_SuperAdminEquityTypeHandbooks_Geography on Geography {\n  country {\n    code\n    name\n    displayName\n  }\n  mostRecentEquityTypeHandbookPublicationDate\n}\n"
  }
};
})();

(node as any).hash = "e7d3e6e7998031c2d8d9760606511e35";

export default node;
