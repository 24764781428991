/**
 * @generated SignedSource<<c19ae28686deda224d1e286f33a1a443>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MatchGranteeView_Organization$data = {
  readonly id: string;
  readonly ignoredHRISProviderEmployees: ReadonlyArray<{
    readonly email: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"MatchGranteeTable_HRISProviderEmployee">;
  }>;
  readonly matchedHRISProviderEmployees: ReadonlyArray<{
    readonly email: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"MatchGranteeTable_HRISProviderEmployee">;
  }>;
  readonly remoteEquityCTMSSyncCompleted: boolean;
  readonly unmatchedHRISProviderEmployees: ReadonlyArray<{
    readonly email: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"MatchGranteeTable_HRISProviderEmployee">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"MatchGranteeTable_Organization" | "RemoteEquityOnboardingLayout_Organization" | "useOrganizationCTMS_Organization" | "useTrackRemoteEquityOnboardingFlowEvent_Organization">;
  readonly " $fragmentType": "MatchGranteeView_Organization";
};
export type MatchGranteeView_Organization$key = {
  readonly " $data"?: MatchGranteeView_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"MatchGranteeView_Organization">;
};

import MatchGranteeView_Organization_RefetchQuery_graphql from './MatchGranteeView_Organization_RefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "hRISProvider",
    "value": "REMOTE"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "MatchGranteeTable_HRISProviderEmployee"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": MatchGranteeView_Organization_RefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "MatchGranteeView_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remoteEquityCTMSSyncCompleted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "HRISProviderEmployee",
      "kind": "LinkedField",
      "name": "unmatchedHRISProviderEmployees",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": "unmatchedHRISProviderEmployees(hRISProvider:\"REMOTE\")"
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "HRISProviderEmployee",
      "kind": "LinkedField",
      "name": "matchedHRISProviderEmployees",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": "matchedHRISProviderEmployees(hRISProvider:\"REMOTE\")"
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "HRISProviderEmployee",
      "kind": "LinkedField",
      "name": "ignoredHRISProviderEmployees",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": "ignoredHRISProviderEmployees(hRISProvider:\"REMOTE\")"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MatchGranteeTable_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationCTMS_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useTrackRemoteEquityOnboardingFlowEvent_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemoteEquityOnboardingLayout_Organization"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "716d907d30d601f3169997fea9253cb2";

export default node;
