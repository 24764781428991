import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { CountryFlag } from "../../../../components/CountryFlag";
import { FormattedCurrency } from "../../../../components/Formatted/FormattedCurrency";
import { FormattedFMV } from "../../../../components/FormattedFMV";
import { NeedsAdditionalFormalitiesWarning } from "../../../../components/NeedsAdditionalFormalitiesWarning";
import { InformationRows } from "../../../../components/ui/InformationRows";
import { GrantDetailsRow_Instrument$key } from "./__generated__/GrantDetailsRow_Instrument.graphql";
import { GrantDetailsRow_Organization$key } from "./__generated__/GrantDetailsRow_Organization.graphql";
import { GrantReviewRow } from "./GrantReviewRow";

const ORGANIZATION_FRAGMENT = graphql`
  fragment GrantDetailsRow_Organization on Organization {
    ...FormattedCurrency_Organization
    ...FormattedFMV_Organization
  }
`;

const INSTRUMENT_FRAGMENT = graphql`
  fragment GrantDetailsRow_Instrument on Instrument {
    needsAdditionalFormalities
    equityType @required(action: THROW) {
      name
      taxResidenceCountry {
        ...CountryFlag_Country
      }
    }
    ...NeedsAdditionalFormalitiesWarning_Instrument
  }
`;

export const GrantDetailsRow: React.FC<{
  exercisePrice: number;
  instrumentFragment: GrantDetailsRow_Instrument$key;
  organizationFragment: GrantDetailsRow_Organization$key;
  quantityGranted: number;
}> = ({
  exercisePrice,
  instrumentFragment,
  organizationFragment,
  quantityGranted,
}) => {
  const instrument = useFragment(INSTRUMENT_FRAGMENT, instrumentFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <GrantReviewRow title="Grant details">
      <InformationRows>
        <InformationRows.Row label="Exercise price">
          <FormattedFMV
            organizationFragment={organization}
            value={exercisePrice}
          />
        </InformationRows.Row>
        <InformationRows.Row label="Total exercise price">
          <FormattedCurrency
            organizationFragment={organization}
            value={exercisePrice * quantityGranted}
          />
        </InformationRows.Row>
        <InformationRows.Row label="Equity type">
          {instrument.equityType.name}{" "}
          <CountryFlag
            countryFragment={instrument.equityType.taxResidenceCountry}
          />
        </InformationRows.Row>
      </InformationRows>
      {instrument.needsAdditionalFormalities && (
        <NeedsAdditionalFormalitiesWarning instrumentFragment={instrument} />
      )}
    </GrantReviewRow>
  );
};
