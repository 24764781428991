/**
 * @generated SignedSource<<b3e2b246a264fb06d3ecdf977b3b08a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteeTotalOwnershipBox_Organization$data = {
  readonly equityGrid: {
    readonly activated: boolean;
  };
  readonly fullyDilutedShares: number | null;
  readonly latestPricePerShare: {
    readonly value: number;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"CurrencySymbol_Organization" | "EmployeeEquityGridLevelTag_Organization" | "FormattedFMV_Organization" | "GranteeDetailsDraftsList_Organization" | "GranteeDetailsGrantsList_Organization" | "SharesValue_Organization">;
  readonly " $fragmentType": "GranteeTotalOwnershipBox_Organization";
};
export type GranteeTotalOwnershipBox_Organization$key = {
  readonly " $data"?: GranteeTotalOwnershipBox_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeTotalOwnershipBox_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeTotalOwnershipBox_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullyDilutedShares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PricePerShare",
      "kind": "LinkedField",
      "name": "latestPricePerShare",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityGrid",
      "kind": "LinkedField",
      "name": "equityGrid",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "activated",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmployeeEquityGridLevelTag_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeDetailsGrantsList_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeDetailsDraftsList_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharesValue_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurrencySymbol_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedFMV_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "3c32d2453de7b193978d97dc80be2375";

export default node;
