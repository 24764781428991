/**
 * @generated SignedSource<<79b2b13b7d2e1b2f78abb3ab1795c7b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LargestGainGraph_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CurrencySymbol_Organization" | "FormattedCurrencyCompact_Organization" | "GranteesListSlideOver_Organization" | "useCurrencyFormatter_Organization">;
  readonly " $fragmentType": "LargestGainGraph_Organization";
};
export type LargestGainGraph_Organization$key = {
  readonly " $data"?: LargestGainGraph_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"LargestGainGraph_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LargestGainGraph_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteesListSlideOver_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurrencySymbol_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useCurrencyFormatter_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedCurrencyCompact_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "b9d7df2139777946e65a805bf3f10789";

export default node;
