/**
 * @generated SignedSource<<bb9d0c51b0c8b896dc9d9ce46da50857>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BookADemoPage_Viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BookADemoView_Account" | "TestLawFirmCartaConnectionView_Viewer">;
  readonly " $fragmentType": "BookADemoPage_Viewer";
};
export type BookADemoPage_Viewer$key = {
  readonly " $data"?: BookADemoPage_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"BookADemoPage_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BookADemoPage_Viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BookADemoView_Account"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TestLawFirmCartaConnectionView_Viewer"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "5f9185c03f846fc458f3d2ecd13ee90a";

export default node;
