/**
 * @generated SignedSource<<d063a5599356156f8f82cf46a8751072>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExercisesFilters_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CurrencySymbol_Organization" | "FormattedCurrency_Organization">;
  readonly " $fragmentType": "ExercisesFilters_Organization";
};
export type ExercisesFilters_Organization$key = {
  readonly " $data"?: ExercisesFilters_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExercisesFilters_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExercisesFilters_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurrencySymbol_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedCurrency_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "0687a9604bd1ea5d3c1dfe5334a4113a";

export default node;
