import { PlusIcon } from "@heroicons/react/24/outline";
import { chain } from "lodash";
import { useMemo, useTransition } from "react";
import {
  Control,
  FieldPath,
  FieldValues,
  useController,
} from "react-hook-form";
import { useRefetchableFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { CreateSlideOverRemote } from "../../pages/Admin/Grants/Configure/PostTermination/CreateSlideOver";
import { Button } from "../ui/Button";
import { RemoteInputSelect } from "../ui/Form/Inputs/RemoteInputSelect";
import { RemoteSelectOrganizationPostTerminationExercisePeriod_Organization$key } from "./__generated__/RemoteSelectOrganizationPostTerminationExercisePeriod_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment RemoteSelectOrganizationPostTerminationExercisePeriod_Organization on Organization
  @refetchable(
    queryName: "RemoteSelectOrganizationPostTerminationExercisePeriod_Organization_RefetchQuery"
  ) {
    id
    postTerminationExercisePeriods {
      __typename
      ... on OrganizationFixedPostTerminationExercisePeriod {
        id
        displayName
      }
      ... on OrganizationVariablePostTerminationExercisePeriod {
        id
        displayName
      }
    }
  }
`;

export const RemoteSelectOrganizationPostTerminationExercisePeriod = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  disabled,
  doNotAllowVariablePTEP,
  isVirtual,
  label,
  name,
  organizationFragment,
}: {
  control: Control<TFieldValues>;
  disabled?: boolean;
  doNotAllowVariablePTEP?: boolean;
  isVirtual: boolean;
  label: string;
  name: TName;
  organizationFragment: RemoteSelectOrganizationPostTerminationExercisePeriod_Organization$key;
}) => {
  const [organization, refetchOrganization] = useRefetchableFragment(
    ORGANIZATION_FRAGMENT,
    organizationFragment,
  );

  const { field } = useController({
    control,
    name,
  });

  const organizationPostTerminationExercisePeriods = useMemo(
    () =>
      chain(organization.postTerminationExercisePeriods)
        .map((ptep) => {
          switch (ptep.__typename) {
            case "%other":
              return null;
            case "OrganizationFixedPostTerminationExercisePeriod":
              return ptep;
            case "OrganizationVariablePostTerminationExercisePeriod":
              return doNotAllowVariablePTEP ? null : ptep;
          }
        })
        .compact()
        .value(),
    [organization.postTerminationExercisePeriods, doNotAllowVariablePTEP],
  );

  const createSlideOverController = CreateSlideOverRemote.useController();

  const [transitionInProgress, startTransition] = useTransition();
  const onPostTerminationExercisePeriodCreated = (
    organizationPostTerminationExercisePeriodId: string,
  ) => {
    startTransition(() => {
      refetchOrganization(
        {},
        {
          fetchPolicy: "network-only",
        },
      );
      field.onChange(organizationPostTerminationExercisePeriodId);
    });
  };

  const options = useMemo(() => {
    return organizationPostTerminationExercisePeriods.map((ptep) => ({
      label: ptep.displayName,
      value: ptep.id,
    }));
  }, [organizationPostTerminationExercisePeriods]);

  return (
    <div className="space-y-4">
      <RemoteInputSelect.Form
        control={control}
        label={label}
        name={name}
        options={options}
      />

      {!disabled && (
        <Button
          leftIcon={<PlusIcon />}
          loading={
            createSlideOverController.transitionInProgress ??
            transitionInProgress
          }
          onClick={() => {
            createSlideOverController.open({
              data: { doNotAllowVariablePTEP, organizationId: organization.id },
              onClose: ({
                createdOrganizationPostTerminationExercisePeriod,
              }) => {
                if (createdOrganizationPostTerminationExercisePeriod) {
                  onPostTerminationExercisePeriodCreated(
                    createdOrganizationPostTerminationExercisePeriod.id,
                  );
                }
              },
            });
          }}
          size="extra small"
          type="button"
          variant="Tertiary Link"
        >
          {isVirtual
            ? "Add new post-termination settlement period"
            : "Add new post-termination exercise period"}
        </Button>
      )}
    </div>
  );
};
