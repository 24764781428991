import { graphql, useFragment } from "react-relay";

import { CurrencyUnit_Organization$key } from "./__generated__/CurrencyUnit_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment CurrencyUnit_Organization on Organization {
    defaultCurrencyCode
  }
`;

export const CurrencyUnit: React.FC<{
  organizationFragment: CurrencyUnit_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return <>{organization.defaultCurrencyCode}</>;
};
