/**
 * @generated SignedSource<<8663860c65ca59a13547bfe342cbdb39>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquityTypeHandbookEvent = "Exercise" | "Grant" | "Holding" | "Sale" | "Vesting";
export type EquityTypeHandbookSection = "Reporting" | "TaxBenefitsAndWatchouts" | "Taxation";
export type EquityTypeHandbookSectionEventItemCategory = "Employee" | "Employer" | "Issuer" | "PotentialTaxBenefit" | "PotentialWatchout";
export type EquityTypeWorkRelationshipCategory = "Contractor" | "DirectEmployee" | "EoREmployee";
export type EquityTypeHandbookInput = {
  sectionEvents: ReadonlyArray<EquityTypeHandbookSectionEventInput>;
};
export type EquityTypeHandbookSectionEventInput = {
  enabled: boolean;
  event: EquityTypeHandbookEvent;
  items: ReadonlyArray<EquityTypeHandbookSectionEventItemInput>;
  nothingToDeclare?: boolean | null;
  section: EquityTypeHandbookSection;
};
export type EquityTypeHandbookSectionEventItemInput = {
  category: EquityTypeHandbookSectionEventItemCategory;
  content?: string | null;
  enabled: boolean;
};
export type EquityTypeHandbookEdition_SaveEquityTypeHandbook_Mutation$variables = {
  equityTypeHandbookInput: EquityTypeHandbookInput;
  equityTypeId: string;
  equityTypeWorkRelationshipCategory: EquityTypeWorkRelationshipCategory;
  lastModifiedAt?: string | null;
};
export type EquityTypeHandbookEdition_SaveEquityTypeHandbook_Mutation$data = {
  readonly saveEquityTypeHandbook: {
    readonly " $fragmentSpreads": FragmentRefs<"EquityTypeHandbookEdition_EquityTypeHandbook">;
  };
};
export type EquityTypeHandbookEdition_SaveEquityTypeHandbook_Mutation = {
  response: EquityTypeHandbookEdition_SaveEquityTypeHandbook_Mutation$data;
  variables: EquityTypeHandbookEdition_SaveEquityTypeHandbook_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "equityTypeHandbookInput"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "equityTypeId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "equityTypeWorkRelationshipCategory"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lastModifiedAt"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "equityTypeHandbookInput",
    "variableName": "equityTypeHandbookInput"
  },
  {
    "kind": "Variable",
    "name": "equityTypeId",
    "variableName": "equityTypeId"
  },
  {
    "kind": "Variable",
    "name": "equityTypeWorkRelationshipCategory",
    "variableName": "equityTypeWorkRelationshipCategory"
  },
  {
    "kind": "Variable",
    "name": "lastModifiedAt",
    "variableName": "lastModifiedAt"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "enabled",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EquityTypeHandbookEdition_SaveEquityTypeHandbook_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EquityTypeHandbook",
        "kind": "LinkedField",
        "name": "saveEquityTypeHandbook",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EquityTypeHandbookEdition_EquityTypeHandbook"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EquityTypeHandbookEdition_SaveEquityTypeHandbook_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EquityTypeHandbook",
        "kind": "LinkedField",
        "name": "saveEquityTypeHandbook",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "publishedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "published",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastModifiedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EquityTypeHandbookSectionEvent",
            "kind": "LinkedField",
            "name": "sectionEvents",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "section",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "event",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nothingToDeclare",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EquityTypeHandbookSectionEventItem",
                "kind": "LinkedField",
                "name": "items",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "category",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "content",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d795836d9772e36650643b27b14075ef",
    "id": null,
    "metadata": {},
    "name": "EquityTypeHandbookEdition_SaveEquityTypeHandbook_Mutation",
    "operationKind": "mutation",
    "text": "mutation EquityTypeHandbookEdition_SaveEquityTypeHandbook_Mutation(\n  $equityTypeHandbookInput: EquityTypeHandbookInput!\n  $equityTypeId: String!\n  $equityTypeWorkRelationshipCategory: EquityTypeWorkRelationshipCategory!\n  $lastModifiedAt: DateTime\n) {\n  saveEquityTypeHandbook(equityTypeHandbookInput: $equityTypeHandbookInput, equityTypeId: $equityTypeId, equityTypeWorkRelationshipCategory: $equityTypeWorkRelationshipCategory, lastModifiedAt: $lastModifiedAt) {\n    ...EquityTypeHandbookEdition_EquityTypeHandbook\n    id\n  }\n}\n\nfragment EquityTypeHandbookEdition_EquityTypeHandbook on EquityTypeHandbook {\n  id\n  publishedAt\n  published\n  lastModifiedAt\n  sectionEvents {\n    section\n    event\n    enabled\n    nothingToDeclare\n    items {\n      category\n      content\n      enabled\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4c249940aae495aeb3bd83ea508d2e6e";

export default node;
