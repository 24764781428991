import classNames from "classnames";
import React from "react";
import { graphql, useFragment } from "react-relay";

import { RemoteEquityOnboardingLayout_Organization$key } from "./__generated__/RemoteEquityOnboardingLayout_Organization.graphql";
import { RemoteEquityOnboardingLayout_Viewer$key } from "./__generated__/RemoteEquityOnboardingLayout_Viewer.graphql";
import { RemoteLikeApplicationLayout } from "./RemoteLikeApplicationLayout";

const ORGANIZATION_FRAGMENT = graphql`
  fragment RemoteEquityOnboardingLayout_Organization on Organization {
    ...RemoteLikeApplicationLayout_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment RemoteEquityOnboardingLayout_Viewer on Account {
    ...RemoteLikeApplicationLayout_Viewer
  }
`;

export const RemoteEquityOnboardingLayout: React.FC<{
  children: React.ReactNode;
  className?: string;
  organizationFragment: RemoteEquityOnboardingLayout_Organization$key;
  subtitle: React.ReactNode;
  title: React.ReactNode;
  viewerFragment: RemoteEquityOnboardingLayout_Viewer$key;
}> = ({
  children,
  className,
  organizationFragment,
  subtitle,
  title,
  viewerFragment,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  return (
    <RemoteLikeApplicationLayout
      organizationFragment={organization}
      viewerFragment={viewer}
    >
      <div
        className={classNames(
          "mx-auto flex flex-col items-center gap-16 py-14",
          className,
        )}
      >
        <div className="flex flex-col items-center gap-3 text-center">
          <div className="w-full justify-items-center font-remote-brand text-remote-4XL/Medium">
            {title}
          </div>
          <div className="w-full justify-items-center text-remote-Base text-remote-grey-700">
            {subtitle}
          </div>
        </div>
        {children}
      </div>
    </RemoteLikeApplicationLayout>
  );
};
