import { compact } from "lodash";
import { graphql, useFragment } from "react-relay";

import { RemoteLikeInfoBlock } from "../../../../components/RemoteLikeInfoBlock";
import { ShortDate } from "../../../../components/ShortDate";
import { WORK_RELATIONSHIP_TO_LABEL_HELPER } from "../../../../services/workRelationship";
import { EmployeeDetailsSection_Employee$key } from "./__generated__/EmployeeDetailsSection_Employee.graphql";
import { Section } from "./Section";

const EMPLOYEE_FRAGMENT = graphql`
  fragment EmployeeDetailsSection_Employee on Grantee {
    name
    email
    taxResidenceCountry {
      displayName
    }
    contractStartDate
    jobTitle
    workRelationship
    employerOfRecord {
      name
    }
  }
`;

export function EmployeeDetailsSection({
  employeeFragment,
}: {
  employeeFragment: EmployeeDetailsSection_Employee$key;
}) {
  const employee = useFragment(EMPLOYEE_FRAGMENT, employeeFragment);

  return (
    <Section title="Employee details">
      <RemoteLikeInfoBlock
        info={compact([
          {
            label: "Full name",
            value: employee.name,
          },
          {
            label: "Email",
            value: employee.email,
          },
          employee.taxResidenceCountry && {
            label: "Country of residence",
            value: employee.taxResidenceCountry.displayName,
          },
          employee.contractStartDate && {
            label: "Contract start date",
            value: <ShortDate value={employee.contractStartDate} />,
          },
          {
            label: "Job title",
            value: employee.jobTitle,
          },
          employee.workRelationship && {
            label: "Work relationship",
            value:
              WORK_RELATIONSHIP_TO_LABEL_HELPER[employee.workRelationship]
                .singularLabel,
          },
          employee.employerOfRecord && {
            label: "EoR",
            value: employee.employerOfRecord.name,
          },
        ])}
      />
    </Section>
  );
}
