import { Pill, PillTone } from "@remote-com/norma";
import { IconV2OutlineArrowUpRight } from "@remote-com/norma/icons/IconV2OutlineArrowUpRight";
import { graphql, useFragment } from "react-relay";

import { FormattedCurrency } from "../../../components/Formatted/FormattedCurrency";
import { FormattedNumber } from "../../../components/Formatted/FormattedNumber";
import { Table } from "../../../components/ui/Table";
import {
  FundraisingEvents_Organization$data,
  FundraisingEvents_Organization$key,
} from "./__generated__/FundraisingEvents_Organization.graphql";
import { Article, Paragraph } from "./Utils";

const INITIAL_SHARES = 50_000;
const INITIAL_PRICE_PER_SHARE = 2.0;

const ORGANIZATION_FRAGMENT = graphql`
  fragment FundraisingEvents_Organization on Organization {
    ...FormattedCurrency_Organization
  }
`;

export function FundraisingEvents({
  organizationFragment,
}: {
  organizationFragment: FundraisingEvents_Organization$key;
}) {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <Article>
      <Article title="In the meantime, the value of your options might increase!">
        <Paragraph>
          As the company grows, it is likely that it will raise more money
          during what we call “fundraising events” (to keep growing). When
          fundraising events occur, your company&apos;s valuation will be
          re-evaluated, to assess what it is worth now. As your options
          correspond to a slice of the company, if the company is worth more, so
          will your slice!
        </Paragraph>
      </Article>
      <Article title="Example of value projection">
        <div className="overflow-hidden rounded-remote-sm border-[0.5px] border-grey-300">
          <Table className="w-full">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Round</Table.HeaderCell>
                <Table.HeaderCell>
                  Multiplier x Price per share
                </Table.HeaderCell>
                <Table.HeaderCell alignRight>
                  Potential value $
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Current value</Table.Cell>
                <MultiplierCell multiplier={1} organization={organization} />
                <ValueCell
                  multiplier={1}
                  organization={organization}
                  tone="blue"
                />
              </Table.Row>
              <Table.Row>
                <Table.Cell>Next fundraising event</Table.Cell>
                <MultiplierCell multiplier={3} organization={organization} />
                <ValueCell
                  multiplier={3}
                  organization={organization}
                  tone="purple"
                />
              </Table.Row>
              <Table.Row>
                <Table.Cell>Next fundraising event</Table.Cell>
                <MultiplierCell multiplier={8} organization={organization} />
                <ValueCell
                  multiplier={8}
                  organization={organization}
                  tone="error"
                />
              </Table.Row>
              <Table.Row>
                <Table.Cell>Next fundraising event</Table.Cell>
                <MultiplierCell multiplier={15} organization={organization} />
                <ValueCell
                  multiplier={15}
                  organization={organization}
                  tone="warning"
                />
              </Table.Row>
            </Table.Body>
          </Table>
          <div className="p-4 text-XS text-grey-600">
            e.g. Employee receives <FormattedNumber value={INITIAL_SHARES} />{" "}
            shares, valued at{" "}
            <FormattedCurrency
              organizationFragment={organization}
              value={INITIAL_PRICE_PER_SHARE}
            />{" "}
            at the time of grant.
          </div>
        </div>
      </Article>
    </Article>
  );
}

function MultiplierCell({
  multiplier,
  organization,
}: {
  multiplier: number;
  organization: FundraisingEvents_Organization$data;
}) {
  return (
    <Table.Cell>
      <div className="flex items-center gap-2">
        <Pill tone="bayoux">x{multiplier}</Pill>
        <span className="text-XS text-grey-600">
          <FormattedCurrency
            organizationFragment={organization}
            value={INITIAL_PRICE_PER_SHARE * multiplier}
          />
        </span>
      </div>
    </Table.Cell>
  );
}

function ValueCell({
  multiplier,
  organization,
  tone,
}: {
  multiplier: number;
  organization: FundraisingEvents_Organization$data;
  tone: PillTone;
}) {
  return (
    <Table.Cell className="text-right">
      <Pill tone={tone}>
        <div className="flex items-center gap-1">
          {multiplier > 1 && <IconV2OutlineArrowUpRight className="size-4" />}
          <span>
            <FormattedCurrency
              organizationFragment={organization}
              value={INITIAL_PRICE_PER_SHARE * INITIAL_SHARES * multiplier}
            />
          </span>
        </div>
      </Pill>
    </Table.Cell>
  );
}
