/**
 * @generated SignedSource<<30aa3b3c6cfb7b5440514d726297198c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IssuingCompanyCard_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CurrencyUnit_Organization" | "FormattedCurrency_Organization">;
  readonly " $fragmentType": "IssuingCompanyCard_Organization";
};
export type IssuingCompanyCard_Organization$key = {
  readonly " $data"?: IssuingCompanyCard_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"IssuingCompanyCard_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "IssuingCompanyCard_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurrencyUnit_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedCurrency_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "f7c9888a4563e6b5b9422c1fa498b150";

export default node;
