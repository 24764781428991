import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Transition,
} from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { BoxedIcon, Button, Pill, Text } from "@remote-com/norma";
import { IconV2DuotoneSlidersVertical } from "@remote-com/norma/icons/IconV2DuotoneSlidersVertical";
import { IconV2DuotoneStar } from "@remote-com/norma/icons/IconV2DuotoneStar";
import { compact, first, isNil } from "lodash";
import {
  forwardRef,
  PropsWithChildren,
  ReactNode,
  useMemo,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import { graphql, useFragment } from "react-relay";

import { ColoredBoxValue } from "../../components/ColoredBoxValue";
import { CTMSGrantVestingStatusPill } from "../../components/CtmsGrantVestingStatusPill";
import { FormattedCurrency } from "../../components/Formatted/FormattedCurrency";
import { FormattedNumber } from "../../components/Formatted/FormattedNumber";
import { FormattedPercentage } from "../../components/Formatted/FormattedPercentage";
import { RemoteLikeFakeInput } from "../../components/RemoteLikeFakeInput";
import { RemoteLikeInfoBlock } from "../../components/RemoteLikeInfoBlock";
import { SectionHero } from "../../components/SectionHero";
import { ShortDate } from "../../components/ShortDate";
import { StatusLight } from "../../components/StatusLight";
import { RoundedBox } from "../../components/ui/RoundedBox";
import { Switch } from "../../components/ui/Switch";
import { cx } from "../../helpers/cva.config";
import {
  CTMSGrantVestingStatus,
  EmployeePortalGrantAndValueDetailsSection_Grantee$data,
  EmployeePortalGrantAndValueDetailsSection_Grantee$key,
} from "./__generated__/EmployeePortalGrantAndValueDetailsSection_Grantee.graphql";
import { HelpLink } from "./HelpLink";
import { useGrantUnit } from "./useGrantUnit";

const GRANTEE_FRAGMENT = graphql`
  fragment EmployeePortalGrantAndValueDetailsSection_Grantee on Grantee {
    organization {
      latestPricePerShare {
        value
      }
      granteePortalSettings {
        displayFullyDilutedValues
      }
      ...FormattedCurrency_Organization
      ...HelpLink_Organization
    }
    ctmsGrants(
      grantStatusIn: [Active, Terminated]
      orderBy: { field: vestingStartDate, direction: ASC }
    ) {
      label
      quantityIssued
      grantDate
      vestingStartDate
      cliffDate
      vestingStatus
      cumulativeVested
      exercisePrice
      isVirtual
      matchingInstrument {
        equityType {
          exercisable
        }
      }
      ...CtmsGrantVestingStatusPill_CTMSGrant
      ...useGrantUnit_CTMSGrant
    }
  }
`;

const CTMS_GRANT_STATUS_LIGHT: Record<CTMSGrantVestingStatus, React.ReactNode> =
  {
    fully_vested: <StatusLight tone="success" />,
    terminated: <StatusLight tone="error" />,
    vesting: <StatusLight tone="success" />,
  };

export const EmployeePortalGrantAndValueDetailsSection = forwardRef<
  HTMLDivElement,
  {
    granteeFragment: EmployeePortalGrantAndValueDetailsSection_Grantee$key;
    id: string;
    valuationMultiple: number;
  }
>(function EmployeePortalGrantAndValueDetailsSection(
  { granteeFragment, id, valuationMultiple },
  ref,
) {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  const displayFullyDilutedValues =
    grantee.organization.granteePortalSettings.displayFullyDilutedValues;

  const firstCTMSGrant = first(grantee.ctmsGrants);

  if (!firstCTMSGrant) {
    throw new Error("Unexpected: grantee has no grants");
  }

  const [selectedGrant, setSelectedGrant] = useState(firstCTMSGrant);

  return (
    <SectionHero id={id} ref={ref} withBorder>
      <div className="space-y-6">
        <div className="flex items-center gap-3">
          <BoxedIcon
            Icon={IconV2DuotoneSlidersVertical}
            size="sm"
            tone="cyan"
          />
          <Text variant="lgMedium">Grant and value details</Text>
        </div>
        <div>
          <Switch
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.label}
            name="grant"
            onChange={setSelectedGrant}
            options={grantee.ctmsGrants}
            selectedOption={selectedGrant}
          />
        </div>
        <GrantDetails
          displayFullyDilutedValues={displayFullyDilutedValues}
          grant={selectedGrant}
          grantee={grantee}
          valuationMultiple={valuationMultiple}
        />
      </div>
    </SectionHero>
  );
});

function GrantDetails({
  displayFullyDilutedValues,
  grant,
  grantee,
  valuationMultiple,
}: {
  displayFullyDilutedValues: boolean;
  grant: EmployeePortalGrantAndValueDetailsSection_Grantee$data["ctmsGrants"][number];
  grantee: EmployeePortalGrantAndValueDetailsSection_Grantee$data;
  valuationMultiple: number;
}) {
  const grantDetailsInfo = compact([
    {
      label: "Vesting progress",
      value: (
        <div className="flex items-center gap-2">
          {CTMS_GRANT_STATUS_LIGHT[grant.vestingStatus]}
          <FormattedPercentage
            animated
            value={grant.cumulativeVested / grant.quantityIssued}
          />
        </div>
      ),
    },
    {
      label: "Grant date",
      value: <ShortDate value={grant.grantDate} />,
    },
    grant.vestingStartDate && {
      label: "Vesting start date",
      value: <ShortDate value={grant.vestingStartDate} />,
    },
    grant.cliffDate && {
      label: "Cliff date",
      value: <ShortDate value={grant.cliffDate} />,
    },
  ]);

  const grantExercisePrice = grant.exercisePrice;

  const grantUnit = useGrantUnit({ grantFragment: grant });

  const organizationLatestPricePerShare =
    grantee.organization.latestPricePerShare?.value;

  const organizationProjectedPricePerShare = useMemo(() => {
    if (isNil(organizationLatestPricePerShare)) {
      return null;
    }

    return organizationLatestPricePerShare * valuationMultiple;
  }, [organizationLatestPricePerShare, valuationMultiple]);

  const projectedTotalPrice = useMemo(() => {
    if (isNil(organizationProjectedPricePerShare)) {
      return null;
    }

    return grant.quantityIssued * organizationProjectedPricePerShare;
  }, [organizationProjectedPricePerShare, grant.quantityIssued]);

  const totalExercisePrice = useMemo(() => {
    if (isNil(grantExercisePrice)) {
      return null;
    }

    return grant.quantityIssued * grantExercisePrice;
  }, [grant.quantityIssued, grantExercisePrice]);

  const projectedNetEquityValue = useMemo(() => {
    if (isNil(projectedTotalPrice) || isNil(totalExercisePrice)) {
      return null;
    }

    return projectedTotalPrice - totalExercisePrice;
  }, [projectedTotalPrice, totalExercisePrice]);

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 gap-10 lg:grid-cols-[minmax(300px,auto)_1fr]">
        <div>
          <ColoredBoxValue
            footer={
              <div className="flex justify-end">
                <CTMSGrantVestingStatusPill ctmsGrantFragment={grant} />
              </div>
            }
            secondary={
              displayFullyDilutedValues && projectedNetEquityValue
                ? {
                    unit: "Net equity value",
                    value: (
                      <FormattedCurrency
                        animated
                        maximumFractionDigits={0}
                        organizationFragment={grantee.organization}
                        value={projectedNetEquityValue}
                      />
                    ),
                  }
                : undefined
            }
            title={
              <div className="flex items-center gap-3">
                <BoxedIcon
                  appearance="subtle"
                  Icon={IconV2DuotoneStar}
                  size="sm"
                  tone="purple"
                />
                <Text variant="lgMedium">{grant.label}</Text>
              </div>
            }
            tone="purple"
            unit={<span className="uppercase">{grantUnit.plural}</span>}
            value={
              <FormattedNumber
                animated
                maximumFractionDigits={0}
                value={grant.quantityIssued}
              />
            }
          />
        </div>
        <div className="space-y-4">
          <RemoteLikeInfoBlock info={grantDetailsInfo} />
          <HelpLink organizationFragment={grantee.organization} />
        </div>
      </div>
      {displayFullyDilutedValues &&
        !isNil(grantExercisePrice) &&
        !isNil(organizationProjectedPricePerShare) &&
        !isNil(totalExercisePrice) &&
        !isNil(projectedNetEquityValue) &&
        !isNil(projectedTotalPrice) && (
          <RoundedBox background="gray" className="space-y-6 p-6">
            <Disclosure>
              {({ open }) => (
                <>
                  <div className="flex items-center gap-2">
                    <Text className="flex-1" variant="lgMedium">
                      Details about this grant
                    </Text>
                    <DisclosureButton>
                      <Button
                        className="!bg-transparent"
                        IconAfter={open ? ChevronUpIcon : ChevronDownIcon}
                        size="sm"
                        tone="primary"
                        variant="outline"
                      >
                        {open ? <>Hide</> : <>Show</>}
                      </Button>
                    </DisclosureButton>
                  </div>

                  <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <DisclosurePanel>
                      <ValueList>
                        <ValueListItem label="How are these values calculated">
                          <div className="space-y-4">
                            <div>
                              This is the total pre-tax net value of your grant.
                              Here&apos;s the calculation:
                            </div>
                            <div
                              className={cx(
                                "grid grid-cols-1 gap-x-3 gap-y-1",
                                {
                                  "lg:grid-cols-[1fr_auto_1fr_auto_1fr]":
                                    !grant.matchingInstrument?.equityType
                                      ?.exercisable,
                                  "lg:grid-cols-[1fr_auto_1fr_auto_1fr_auto_1fr]":
                                    grant.matchingInstrument?.equityType
                                      ?.exercisable,
                                },
                              )}
                            >
                              <RemoteLikeFakeInput
                                label="Total # shares amount"
                                value={
                                  <FormattedNumber
                                    animated
                                    maximumFractionDigits={0}
                                    value={grant.quantityIssued}
                                  />
                                }
                              />
                              <div className="row-span-2 flex items-center">
                                ×
                              </div>
                              <div className="row-span-2 grid-rows-subgrid space-y-1">
                                <RemoteLikeFakeInput
                                  label="Price per share"
                                  value={
                                    <div className="flex gap-2">
                                      <FormattedCurrency
                                        animated
                                        organizationFragment={
                                          grantee.organization
                                        }
                                        value={
                                          organizationProjectedPricePerShare
                                        }
                                      />
                                      {valuationMultiple > 1 && (
                                        <Pill tone="purple">
                                          <span className="whitespace-nowrap">
                                            @
                                            <FormattedNumber
                                              animated
                                              maximumFractionDigits={0}
                                              value={valuationMultiple}
                                            />
                                            X
                                          </span>
                                        </Pill>
                                      )}
                                    </div>
                                  }
                                />
                                <div className="px-4">
                                  <Text>
                                    <FormattedCurrency
                                      animated
                                      organizationFragment={
                                        grantee.organization
                                      }
                                      value={projectedTotalPrice}
                                    />
                                  </Text>
                                </div>
                              </div>
                              {grant.matchingInstrument?.equityType
                                ?.exercisable && (
                                <>
                                  <div className="row-span-2 flex items-center">
                                    -
                                  </div>
                                  <div className="row-span-2 grid-rows-subgrid space-y-1">
                                    <RemoteLikeFakeInput
                                      label="Exercise price"
                                      value={
                                        <FormattedCurrency
                                          animated
                                          organizationFragment={
                                            grantee.organization
                                          }
                                          value={grantExercisePrice}
                                        />
                                      }
                                    />
                                    <div className="px-4">
                                      <Text>
                                        <FormattedCurrency
                                          animated
                                          organizationFragment={
                                            grantee.organization
                                          }
                                          value={totalExercisePrice}
                                        />
                                      </Text>
                                    </div>
                                  </div>
                                </>
                              )}
                              <div className="row-span-2 flex items-center">
                                =
                              </div>
                              <RemoteLikeFakeInput
                                label="Net Equity Value"
                                tone="accent"
                                value={
                                  <FormattedCurrency
                                    animated
                                    organizationFragment={grantee.organization}
                                    value={projectedNetEquityValue}
                                  />
                                }
                              />
                            </div>
                          </div>
                        </ValueListItem>
                        <ValueListItem
                          label={`Total amount of ${grantUnit.plural}`}
                        >
                          <FormattedMessage
                            defaultMessage="This grant includes a total of {options, plural, one {# {singular}} other {# {plural}}}."
                            values={{
                              options: grant.quantityIssued,
                              plural: grantUnit.plural,
                              singular: grantUnit.singular,
                            }}
                          />
                        </ValueListItem>
                        <ValueListItem label="Price per share (Estimated)">
                          This is what you&apos;re assuming each stock unit is
                          worth when you sell. You can adjust your assumed share
                          price using the slider above to match the eventuality
                          of a potential company valuation.
                        </ValueListItem>
                        {grant.matchingInstrument?.equityType?.exercisable &&
                          !grant.isVirtual && (
                            <ValueListItem label="Exercise price (Based on the Fair Market value)">
                              The exercise price is the price you’ll have to pay
                              to acquire the options and turn them into actual
                              shares. It may vary from one grant to another
                              depending of the time it has been granted.
                            </ValueListItem>
                          )}
                        {grant.matchingInstrument?.equityType?.exercisable &&
                          grant.isVirtual && (
                            <ValueListItem label="Exercise price (Based on the Fair Market value)">
                              The virtual exercise price in phantom stock or
                              virtual stock options is a notional price set at
                              the time of grant, which usually corresponds to
                              the fair market value of the shares.
                              <br />
                              <br />
                              It&apos;s used to calculate the amount the
                              employee is entitled to at the time of settlement
                              of the virtual stock options.
                              <br />
                              <br />
                              💡 The employee receives the difference between
                              the shares value at settlement, and the virtual
                              strike price, similar to how stock options work,
                              but without needing to buy actual shares. This
                              applies only to &quot;appreciation-only&quot;
                              phantom stock or virtual stock options, where
                              grantees benefit only from the stock’s increase in
                              value (and not to &quot;full-value&quot; phantom
                              stock, where grantees can get the full shares
                              value at settlement).
                            </ValueListItem>
                          )}
                      </ValueList>
                    </DisclosurePanel>
                  </Transition>
                </>
              )}
            </Disclosure>
          </RoundedBox>
        )}
    </div>
  );
}

function ValueList({ children }: PropsWithChildren) {
  return <div className="divide-y-[0.5px] divide-grey-300">{children}</div>;
}

function ValueListItem({
  children,
  label,
}: PropsWithChildren<{
  label: ReactNode;
}>) {
  return (
    <div className="flex flex-col gap-x-10 gap-y-2 py-2">
      <Text variant="baseMedium">{label}</Text>
      <Text className="text-grey-600" variant="sm">
        {children}
      </Text>
    </div>
  );
}
