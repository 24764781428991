import { useFragment, useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";

import { CurrencySymbol } from "../../../../components/CurrencySymbol";
import { Page } from "../../../../components/Page";
import { useOrganizationIdParam } from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { InsightPage } from "../InsightPage";
import { LargestGain_LargestGainDataPoint$key } from "./__generated__/LargestGain_LargestGainDataPoint.graphql";
import { LargestGain_Organization$key } from "./__generated__/LargestGain_Organization.graphql";
import { LargestGain_Query } from "./__generated__/LargestGain_Query.graphql";
import { LargestGainGraph } from "./LargestGainGraph";

const ORGANIZATION_FRAGMENT = graphql`
  fragment LargestGain_Organization on Organization {
    id
    ...LargestGainGraph_Organization
    ...CurrencySymbol_Organization
  }
`;

const DATA_POINTS_FRAGMENT = graphql`
  fragment LargestGain_LargestGainDataPoint on LargestGainDataPoint
  @relay(plural: true) {
    ...LargestGainGraph_LargestGainDataPoint
  }
`;

const AdminLargestGainPage_: React.FC<{
  dataPointsFragment: LargestGain_LargestGainDataPoint$key;
  organizationFragment: LargestGain_Organization$key;
}> = ({ dataPointsFragment, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const dataPoints = useFragment(DATA_POINTS_FRAGMENT, dataPointsFragment);

  return (
    <InsightPage
      subtitle={
        <>
          Aggregated <CurrencySymbol organizationFragment={organization} />
          -value of grants vs. aggregated{" "}
          <CurrencySymbol organizationFragment={organization} />
          -value gain realized per grantee
        </>
      }
      title="🚀 Gain realized"
    >
      <LargestGainGraph
        dataPointsFragment={dataPoints}
        organizationFragment={organization}
      />
    </InsightPage>
  );
};

const QUERY = graphql`
  query LargestGain_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      name
      id
      ...LargestGain_Organization
    }
    largestGainDataPoints(organizationId: $organizationId) {
      ...LargestGain_LargestGainDataPoint
    }
  }
`;

const AdminLargestGainPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { largestGainDataPoints: dataPoints, organization } =
    useLazyLoadQuery<LargestGain_Query>(QUERY, {
      organizationId,
    });

  if (!organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Largest Gain"
      analyticsName="Admin - Insights - Largest Gain"
      organizationId={organization.id}
      title={`Admin | ${organization.name} insights | largest gain`}
    >
      <AdminLargestGainPage_
        dataPointsFragment={dataPoints}
        organizationFragment={organization}
      />
    </Page>
  );
};

export default AdminLargestGainPage;
