import { XMarkIcon } from "@heroicons/react/24/outline";
import { Text } from "@remote-com/norma";
import { last } from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

interface CheckoutLikeLayoutAsideSectionItem {
  title: string;
  value?: null | React.ReactNode;
}

interface NavigationBreadcrumb {
  label: React.ReactNode;
  to: string;
}

export function CheckoutLikeLayout({
  aside,
  children,
  navigationBreadcrumbs,
  pageTitle,
}: {
  aside: React.ReactNode;
  children: React.ReactNode;
  navigationBreadcrumbs: readonly NavigationBreadcrumb[];
  pageTitle: React.ReactNode;
}) {
  return (
    <div className="fixed inset-0 z-20 bg-background-subtle">
      <div className="grid h-full w-full grid-rows-[auto_1fr]">
        <Header
          navigationBreadcrumbs={navigationBreadcrumbs}
          pageTitle={pageTitle}
        />
        <div className="h-full overflow-auto">
          <div className="mx-auto grid grid-cols-[minmax(auto,620px)_400px] justify-center gap-10 px-4 py-10">
            <main className="space-y-6">{children}</main>
            <aside className="sticky top-10 h-fit">{aside}</aside>
          </div>
        </div>
      </div>
    </div>
  );
}

export function CheckoutLikeLayoutAside({
  callToAction,
  children,
  title,
}: {
  callToAction?: React.ReactNode;
  children: React.ReactNode;
  title: React.ReactNode;
}) {
  return (
    <div className="space-y-4 rounded-remote-lg border-[0.5px] border-grey-300 bg-white px-8 py-6">
      <Text as="div" variant="lgMedium">
        {title}
      </Text>
      {children}
      {callToAction}
    </div>
  );
}

export function CheckoutLikeLayoutAsideSection({
  children,
  title,
}: {
  children: React.ReactNode;
  title?: React.ReactNode;
}) {
  return (
    <div className="space-y-3">
      {title && (
        <Text as="div" variant="smMedium">
          {title}
        </Text>
      )}
      {children}
    </div>
  );
}

export function CheckoutLikeLayoutAsideSectionItem({
  children,
  title,
}: {
  children?: React.ReactNode;
  title: React.ReactNode;
}) {
  return (
    <div className="flex justify-between gap-2">
      <Text as="div" className="text-grey-600" variant="sm">
        {title}
      </Text>
      <Text as="div" variant="smMedium">
        {children ?? "—"}
      </Text>
    </div>
  );
}

export function CheckoutLikeLayoutMainCard({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className="space-y-10 rounded-remote-xl border-[0.5px] border-grey-300 bg-white p-10">
      {children}
    </div>
  );
}

export function CheckoutLikeLayoutMainSection({
  children,
  subtitle,
  title,
  upperTitle,
}: {
  children: React.ReactNode;
  subtitle: React.ReactNode;
  title: React.ReactNode;
  upperTitle?: React.ReactNode;
}) {
  return (
    <div className="space-y-4 border-b-[0.5px] border-grey-300 py-10 first-of-type:pt-0 last-of-type:border-b-0 last-of-type:pb-0">
      <div className="space-y-2">
        {upperTitle && (
          <Text as="div" className="font-brand" variant="2xsMedium">
            {upperTitle}
          </Text>
        )}
        <Text as="div" className="font-brand" variant="2xlMedium">
          {title}
        </Text>
        <Text as="div" className="text-grey-500" variant="sm">
          {subtitle}
        </Text>
      </div>
      <div className="space-y-4">{children}</div>
    </div>
  );
}

export function CheckoutLikeLayoutMainTitle({
  description,
  illustration,
  title,
}: {
  description: React.ReactNode;
  illustration?: React.ReactNode;
  title: React.ReactNode;
}) {
  return (
    <div className="flex flex-col items-center gap-4">
      {illustration}
      <Text className="text-grey-900" variant="4xlMedium">
        {title}
      </Text>
      <Text className="text-center text-grey-600" variant="sm">
        {description}
      </Text>
    </div>
  );
}

function Header({
  navigationBreadcrumbs,
  pageTitle,
}: {
  navigationBreadcrumbs: readonly NavigationBreadcrumb[];
  pageTitle: React.ReactNode;
}) {
  const lastBreadcrumb = last(navigationBreadcrumbs);

  return (
    <header className="sticky top-0 z-10 border-b-[0.5px] border-grey-300 bg-white">
      <div className="container mx-auto px-4 py-6">
        <div className="flex items-center gap-6">
          {lastBreadcrumb && (
            <Link className="p-1" to={lastBreadcrumb.to}>
              <XMarkIcon className="size-6" />
            </Link>
          )}
          <Text as="div" className="flex items-center gap-2" variant="smMedium">
            {navigationBreadcrumbs.map(({ label, to }) => (
              <Fragment key={to}>
                <Link to={to}>{label}</Link>
                <span>/</span>
              </Fragment>
            ))}
            <span>{pageTitle}</span>
          </Text>
        </div>
      </div>
    </header>
  );
}
