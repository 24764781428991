/**
 * @generated SignedSource<<3678db48df676815354ff385ace90871>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmployeeDetails_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeeDetailsTopBarActions_Organization" | "EquityDetailsSection_Organization" | "LargeCenteredDetailsLayout_Organization" | "OwnershipSection_Organization" | "VestingScheduleSection_Organization">;
  readonly " $fragmentType": "EmployeeDetails_Organization";
};
export type EmployeeDetails_Organization$key = {
  readonly " $data"?: EmployeeDetails_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeeDetails_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmployeeDetails_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LargeCenteredDetailsLayout_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OwnershipSection_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VestingScheduleSection_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquityDetailsSection_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmployeeDetailsTopBarActions_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "7b31203bef8679ce959d79fc8afa01d8";

export default node;
