/**
 * @generated SignedSource<<2a4897a88e6e6131180f3557d4d91320>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ExerciseValueSource = "FAIR_MARKET_VALUE" | "I_DONT_KNOW" | "OTHER" | "RECENT_FINANCING_ROUND" | "RECENT_SECONDARY_TRANSACTION" | "RECENT_THIRD_PARTY_REPORT";
export type ExerciseRequestDeclareNew_DeclareExerciseRequest_Mutation$variables = {
  certificateSigned: boolean;
  ctmsGrantLabel: string;
  documentIds: ReadonlyArray<string>;
  fundsTransferredAt?: string | null;
  organizationId: string;
  quantityExercised: number;
  valueAtExercise: number;
  valueAtExerciseSource: ExerciseValueSource;
  valueAtExerciseSourceOtherDetails?: string | null;
};
export type ExerciseRequestDeclareNew_DeclareExerciseRequest_Mutation$data = {
  readonly declareExerciseRequest: {
    readonly id: string;
  };
};
export type ExerciseRequestDeclareNew_DeclareExerciseRequest_Mutation = {
  response: ExerciseRequestDeclareNew_DeclareExerciseRequest_Mutation$data;
  variables: ExerciseRequestDeclareNew_DeclareExerciseRequest_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "certificateSigned"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ctmsGrantLabel"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "documentIds"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fundsTransferredAt"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "quantityExercised"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "valueAtExercise"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "valueAtExerciseSource"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "valueAtExerciseSourceOtherDetails"
},
v9 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "certificateSigned",
        "variableName": "certificateSigned"
      },
      {
        "kind": "Variable",
        "name": "ctmsGrantLabel",
        "variableName": "ctmsGrantLabel"
      },
      {
        "kind": "Variable",
        "name": "documentIds",
        "variableName": "documentIds"
      },
      {
        "kind": "Variable",
        "name": "fundsTransferredAt",
        "variableName": "fundsTransferredAt"
      },
      {
        "kind": "Variable",
        "name": "organizationId",
        "variableName": "organizationId"
      },
      {
        "kind": "Variable",
        "name": "quantityExercised",
        "variableName": "quantityExercised"
      },
      {
        "kind": "Variable",
        "name": "valueAtExercise",
        "variableName": "valueAtExercise"
      },
      {
        "kind": "Variable",
        "name": "valueAtExerciseSource",
        "variableName": "valueAtExerciseSource"
      },
      {
        "kind": "Variable",
        "name": "valueAtExerciseSourceOtherDetails",
        "variableName": "valueAtExerciseSourceOtherDetails"
      }
    ],
    "concreteType": "CTMSExerciseRequest",
    "kind": "LinkedField",
    "name": "declareExerciseRequest",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExerciseRequestDeclareNew_DeclareExerciseRequest_Mutation",
    "selections": (v9/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "ExerciseRequestDeclareNew_DeclareExerciseRequest_Mutation",
    "selections": (v9/*: any*/)
  },
  "params": {
    "cacheID": "f35d11a1d4e3660ceded8b9326ebaf4a",
    "id": null,
    "metadata": {},
    "name": "ExerciseRequestDeclareNew_DeclareExerciseRequest_Mutation",
    "operationKind": "mutation",
    "text": "mutation ExerciseRequestDeclareNew_DeclareExerciseRequest_Mutation(\n  $certificateSigned: Boolean!\n  $ctmsGrantLabel: String!\n  $documentIds: [UUID!]!\n  $valueAtExercise: Float!\n  $valueAtExerciseSource: ExerciseValueSource!\n  $valueAtExerciseSourceOtherDetails: String\n  $organizationId: OrganizationId!\n  $quantityExercised: Int!\n  $fundsTransferredAt: Date\n) {\n  declareExerciseRequest(certificateSigned: $certificateSigned, ctmsGrantLabel: $ctmsGrantLabel, documentIds: $documentIds, fundsTransferredAt: $fundsTransferredAt, organizationId: $organizationId, quantityExercised: $quantityExercised, valueAtExercise: $valueAtExercise, valueAtExerciseSource: $valueAtExerciseSource, valueAtExerciseSourceOtherDetails: $valueAtExerciseSourceOtherDetails) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f50d206a962f6a8aa185b390722e5940";

export default node;
