/**
 * @generated SignedSource<<b971df8ab58aff0f6f049da3b08c1deb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewHirePlanningEntryModal_Organization$data = {
  readonly equityGrid: {
    readonly activated: boolean;
    readonly levels: ReadonlyArray<{
      readonly equityInShares: number | null;
      readonly id: string;
      readonly name: string;
    }>;
  };
  readonly fullyDilutedShares: number | null;
  readonly id: string;
  readonly latestPricePerShare: {
    readonly value: number;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"GrantedSharesInput_Organization">;
  readonly " $fragmentType": "NewHirePlanningEntryModal_Organization";
};
export type NewHirePlanningEntryModal_Organization$key = {
  readonly " $data"?: NewHirePlanningEntryModal_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewHirePlanningEntryModal_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewHirePlanningEntryModal_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityGrid",
      "kind": "LinkedField",
      "name": "equityGrid",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "activated",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EquityGridLevel",
          "kind": "LinkedField",
          "name": "levels",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "equityInShares",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullyDilutedShares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PricePerShare",
      "kind": "LinkedField",
      "name": "latestPricePerShare",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantedSharesInput_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "255d55cabb6b0b406f6fc60d5ff378d1";

export default node;
