/**
 * @generated SignedSource<<c16292cb541119260c8c9fd6fa1b3ee5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RSUVestingTable_CTMSGrant$data = ReadonlyArray<{
  readonly grantee: {
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantsTableRowGranteeCell_Grantee">;
  };
  readonly label: string;
  readonly vestingDataPoints: ReadonlyArray<{
    readonly date: string;
    readonly fairMarketValueAtDate: {
      readonly value: number;
    } | null;
    readonly newlyVested: number;
  }>;
  readonly " $fragmentType": "RSUVestingTable_CTMSGrant";
}>;
export type RSUVestingTable_CTMSGrant$key = ReadonlyArray<{
  readonly " $data"?: RSUVestingTable_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"RSUVestingTable_CTMSGrant">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "vestingDataPointsFilters"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "RSUVestingTable_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "grantee",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CtmsGrantsTableRowGranteeCell_Grantee"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filters",
          "variableName": "vestingDataPointsFilters"
        }
      ],
      "concreteType": "VestingDataPoint",
      "kind": "LinkedField",
      "name": "vestingDataPoints",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "newlyVested",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FairMarketValue",
          "kind": "LinkedField",
          "name": "fairMarketValueAtDate",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "115f42a71f0cebaf03f94a28e083ab9e";

export default node;
