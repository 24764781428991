/**
 * @generated SignedSource<<247ae65586393119d71635cd583a4343>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GrantDetailsRow_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FormattedCurrency_Organization" | "FormattedFMV_Organization">;
  readonly " $fragmentType": "GrantDetailsRow_Organization";
};
export type GrantDetailsRow_Organization$key = {
  readonly " $data"?: GrantDetailsRow_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantDetailsRow_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantDetailsRow_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedCurrency_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedFMV_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "09ddcc71ae06071923006c316c7785f8";

export default node;
