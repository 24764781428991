/**
 * @generated SignedSource<<074dea0e872963b31296bcf9383afd70>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type OrganizationPlan = "freeTrial" | "freemiumDemoBooked" | "freemiumDemoToBook" | "fullAccess";
export type RemoteOnboardingStep = "CLEAN_UP_GRANTEE_DATA" | "CONNECT_CTMS" | "ESSENTIALS_CTMS" | "ESSENTIALS_EQUITY_PLAN_PARAMETERS" | "ESSENTIALS_EXPECTATION" | "ESSENTIALS_FAIR_MARKET_VALUE" | "ESSENTIALS_ISSUING_COMPANY" | "ESSENTIALS_LAST_STEP" | "SET_PRICE_PER_SHARE" | "TEST_LAW_FIRM_CARTA_CONNECTION";
import { FragmentRefs } from "relay-runtime";
export type AdminLayout_Organization$data = {
  readonly currentRemoteOnboardingStep: RemoteOnboardingStep | null;
  readonly id: string;
  readonly isRemoteEquityEssentials: boolean;
  readonly name: string;
  readonly plan: OrganizationPlan;
  readonly " $fragmentSpreads": FragmentRefs<"AdvancedApplicationSidebar_Organization" | "ApplicationSideBar_Organization" | "BookADemoModal_Organization" | "EssentialsApplicationSidebar_Organization" | "FreeTrialPlanStickyBanner_Organization">;
  readonly " $fragmentType": "AdminLayout_Organization";
};
export type AdminLayout_Organization$key = {
  readonly " $data"?: AdminLayout_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminLayout_Organization">;
};

import AdminLayout_Organization_RefetchableQuery_graphql from './AdminLayout_Organization_RefetchableQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": AdminLayout_Organization_RefetchableQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "AdminLayout_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "plan",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentRemoteOnboardingStep",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRemoteEquityEssentials",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApplicationSideBar_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FreeTrialPlanStickyBanner_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BookADemoModal_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdvancedApplicationSidebar_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EssentialsApplicationSidebar_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "2e8e507d913abcca8ef16f37bb87896e";

export default node;
