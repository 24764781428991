/**
 * @generated SignedSource<<27e0f40a63521e473ca72173e37802fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ExerciseStatus = "FullyExercised" | "NotExercised" | "PartiallyExercised";
import { FragmentRefs } from "relay-runtime";
export type ExerciseStatusTag_CTMSGrant$data = {
  readonly exerciseStatus: ExerciseStatus;
  readonly settled: boolean;
  readonly " $fragmentType": "ExerciseStatusTag_CTMSGrant";
};
export type ExerciseStatusTag_CTMSGrant$key = {
  readonly " $data"?: ExerciseStatusTag_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseStatusTag_CTMSGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExerciseStatusTag_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exerciseStatus",
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "ce44e5a1e418543639624be784170ca6";

export default node;
