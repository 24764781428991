import { Pill, Text } from "@remote-com/norma";
import { IconV2OutlinePin } from "@remote-com/norma/icons/IconV2OutlinePin";
import { compact, isNil } from "lodash";
import React, { useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { CurrencyUnit } from "../../../components/CurrencyUnit";
import { FormattedCurrency } from "../../../components/Formatted/FormattedCurrency";
import { FormattedNumber } from "../../../components/Formatted/FormattedNumber";
import { cx } from "../../../helpers/cva.config";
import {
  IssuingCompanyCard_Organization$data,
  IssuingCompanyCard_Organization$key,
} from "./__generated__/IssuingCompanyCard_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment IssuingCompanyCard_Organization on Organization {
    ...CurrencyUnit_Organization
    ...FormattedCurrency_Organization
  }
`;

export function IssuingCompanyCard({
  companyCity,
  companyCountry,
  companyCountryEmoji,
  companyName,
  fairMarketValue,
  fullyDilutedSharesCount,
  organizationFragment,
  poolSize,
  pricePerShare,
}: {
  companyCity?: null | string;
  companyCountry?: null | string;
  companyCountryEmoji?: null | string;
  companyName?: null | string;
  fairMarketValue?: null | number;
  fullyDilutedSharesCount?: null | number;
  organizationFragment: IssuingCompanyCard_Organization$key;
  poolSize?: null | number;
  pricePerShare?: null | number;
}) {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const currentValuation = useMemo(() => {
    if (isNil(fullyDilutedSharesCount) || isNil(pricePerShare)) {
      return null;
    }

    return fullyDilutedSharesCount * pricePerShare;
  }, [fullyDilutedSharesCount, pricePerShare]);

  const address = compact([
    companyCity,
    compact([companyCountry, companyCountryEmoji]).join(" "),
  ]).join(", ");

  return (
    <div className="space-y-4 rounded-remote-lg border-[0.5px] border-grey-300 bg-white px-4 py-6">
      <div className="flex flex-col items-center justify-center gap-2">
        <Pill>Issuing Company</Pill>
        {companyName && <Text variant="xlMedium">{companyName}</Text>}
        {address && (
          <div className="flex items-center gap-2 text-grey-700">
            <IconV2OutlinePin className="size-6" />
            <Text variant="sm">{address}</Text>
          </div>
        )}
      </div>
      <div className="grid w-[332px] grid-cols-[1fr_auto] gap-4 p-4">
        <IssuingCompanyCardRow
          isMoney
          label="Fair market value"
          maximumFractionDigits={2}
          organization={organization}
          value={fairMarketValue}
        />
        <IssuingCompanyCardRow
          label="Fully diluted shares count"
          maximumFractionDigits={0}
          organization={organization}
          value={fullyDilutedSharesCount}
        />
        <IssuingCompanyCardRow
          label="Pool size"
          offsetLabel
          organization={organization}
          value={poolSize}
        />
        <IssuingCompanyCardRow
          isMoney
          label="Price per share"
          maximumFractionDigits={2}
          organization={organization}
          value={pricePerShare}
        />
        <div className="col-span-full h-[1px] bg-grey-200" />
        <IssuingCompanyCardRow
          isMoney
          label="Current company valuation"
          maximumFractionDigits={0}
          organization={organization}
          strongLabel
          value={currentValuation}
        />
      </div>
    </div>
  );
}

function IssuingCompanyCardRow({
  isMoney,
  label,
  maximumFractionDigits,
  offsetLabel,
  organization,
  strongLabel,
  value,
}: {
  isMoney?: boolean;
  label: React.ReactNode;
  maximumFractionDigits?: number;
  offsetLabel?: boolean;
  organization: IssuingCompanyCard_Organization$data;
  strongLabel?: boolean;
  value?: null | number;
}) {
  const hasValue = !isNil(value);

  return (
    <div className="col-span-full grid grid-cols-subgrid">
      <Text
        className={cx({
          "pl-4": offsetLabel,
        })}
        variant={strongLabel ? "smMedium" : "sm"}
      >
        {label}
      </Text>
      <Text className="text-right" variant="sm">
        {hasValue ? (
          isMoney ? (
            <FormattedCurrency
              animated
              maximumFractionDigits={maximumFractionDigits}
              organizationFragment={organization}
              value={value}
            />
          ) : (
            <FormattedNumber
              animated
              maximumFractionDigits={maximumFractionDigits}
              style="decimal"
              value={value}
            />
          )
        ) : (
          "-"
        )}
        {isMoney && (
          <span className="text-grey-600">
            {" "}
            <CurrencyUnit organizationFragment={organization} />
          </span>
        )}
      </Text>
    </div>
  );
}
