import { useCallback } from "react";
import { FormatNumberOptions, useIntl } from "react-intl";
import { graphql, useFragment } from "react-relay";

import { useCurrencyFormatter_Organization$key } from "./__generated__/useCurrencyFormatter_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment useCurrencyFormatter_Organization on IOrganization {
    defaultCurrencyCode
  }
`;

export const useCurrencyFormatter = ({
  organizationFragment,
}: {
  organizationFragment: useCurrencyFormatter_Organization$key;
}) => {
  const intl = useIntl();
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return useCallback(
    (value: number, options: FormatNumberOptions = {}) => {
      return intl.formatNumber(value, {
        ...options,
        currency: organization.defaultCurrencyCode,
        style: "currency",
      });
    },
    [intl, organization.defaultCurrencyCode],
  );
};
