/**
 * @generated SignedSource<<296cf67aa6ca0650159d329fd32b5559>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GrantedSharesBreakdownCard_Organization$data = {
  readonly latestPricePerShare: {
    readonly value: number;
  } | null;
  readonly poolGrantedSharesBreakdown: {
    readonly real: number;
    readonly total: number;
    readonly virtual: number;
  };
  readonly " $fragmentSpreads": FragmentRefs<"FormattedCurrency_Organization" | "FormattedFMV_Organization">;
  readonly " $fragmentType": "GrantedSharesBreakdownCard_Organization";
};
export type GrantedSharesBreakdownCard_Organization$key = {
  readonly " $data"?: GrantedSharesBreakdownCard_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantedSharesBreakdownCard_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantedSharesBreakdownCard_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SharesBreakdown",
      "kind": "LinkedField",
      "name": "poolGrantedSharesBreakdown",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "real",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "virtual",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PricePerShare",
      "kind": "LinkedField",
      "name": "latestPricePerShare",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedCurrency_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedFMV_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "c2164d4d922aa887ac88dc9e28485a56";

export default node;
