import * as Sentry from "@sentry/react";
import { isNil } from "lodash";
import { useEffect } from "react";
import { generatePath, Link, useRouteError } from "react-router-dom";

import {
  useApplicationName,
  useApplicationTheme,
} from "../hooks/useApplicationTheme";
import { APPLICATION_ROUTES } from "../paths";
import ErrorPage from "./Error/Error";

const CriticalErrorPage = () => {
  const error = useRouteError();
  const theme = useApplicationTheme();
  const applicationName = useApplicationName();

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <ErrorPage
      action={
        <Link
          className="text-primary"
          to={generatePath(APPLICATION_ROUTES.roleSelection, {})}
        >
          {isNil(theme) ? "Back to home" : `Back to ${applicationName}`}
        </Link>
      }
      subtitle="Something went wrong, but our team has been advised"
      title="Whoops!"
    />
  );
};

export default CriticalErrorPage;
