/**
 * @generated SignedSource<<ecc6df9e27080e0fa183f299390b6a94>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CtmsGrantDetails_CTMSGrant$data = {
  readonly grantee: {
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantDetailsGrantee_Grantee">;
  };
  readonly label: string;
  readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantDetailsExercise_CTMSGrant" | "CtmsGrantDetailsGrant_CTMSGrant" | "CtmsGrantDetailsHeader_CTMSGrant" | "CtmsGrantDetailsPostTerminationExercisePeriod_CTMSGrant" | "CtmsGrantDetailsPriceEvolution_CTMSGrant" | "CtmsGrantDetailsVestingSchedule_CTMSGrant" | "DocumentsTable_CTMSGrant">;
  readonly " $fragmentType": "CtmsGrantDetails_CTMSGrant";
};
export type CtmsGrantDetails_CTMSGrant$key = {
  readonly " $data"?: CtmsGrantDetails_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantDetails_CTMSGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CtmsGrantDetails_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "grantee",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CtmsGrantDetailsGrantee_Grantee"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CtmsGrantDetailsHeader_CTMSGrant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CtmsGrantDetailsGrant_CTMSGrant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CtmsGrantDetailsVestingSchedule_CTMSGrant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CtmsGrantDetailsPostTerminationExercisePeriod_CTMSGrant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CtmsGrantDetailsExercise_CTMSGrant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CtmsGrantDetailsPriceEvolution_CTMSGrant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DocumentsTable_CTMSGrant"
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "e3aed2d8482fe7f8515de05268998ddb";

export default node;
