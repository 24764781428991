import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useCallback, useMemo } from "react";
import { useFragment } from "react-relay";
import { generatePath, useSearchParams } from "react-router-dom";
import { graphql } from "relay-runtime";

import { EmptyState } from "../../../components/EmptyState";
import { Page } from "../../../components/Page";
import { Button, LinkButton } from "../../../components/ui/Button";
import { HelpCard } from "../../../components/ui/HelpCard";
import { LargeOneColumnLayout } from "../../../components/ui/Layout/LargeOneColumnLayout";
import { SearchBar } from "../../../components/ui/SearchBar";
import { Table } from "../../../components/ui/Table";
import { useApplicationSupportEmailAddress } from "../../../hooks/useApplicationTheme";
import { useQuery } from "../../../hooks/useQuery";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import {
  EquityHandbooks_Organization$data,
  EquityHandbooks_Organization$key,
} from "./__generated__/EquityHandbooks_Organization.graphql";
import { EquityHandbooks_Query } from "./__generated__/EquityHandbooks_Query.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment EquityHandbooks_Organization on Organization {
    id
    name
    suggestedCountries {
      country {
        code
        name
        emoji
      }
      organizationGeography {
        unlockedAt
      }
      employeeCount
      grantCount
    }
    ...LargeOneColumnLayout_Organization
  }
`;

type SuggestedCountry =
  EquityHandbooks_Organization$data["suggestedCountries"][number];

const columnHelper = createColumnHelper<SuggestedCountry>();

const EquityHandbooksPage_: React.FC<{
  organizationFragment: EquityHandbooks_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.country.name, {
        cell: (context) => {
          const item = context.row.original;

          return (
            <div className="font-emoji text-2xl">{item.country.emoji}</div>
          );
        },
        enableGlobalFilter: false,
        enableSorting: false,
        header: () => <span className="sr-only">Flag</span>,
        id: "flag",
      }),
      columnHelper.accessor((row) => row.country.name, {
        cell: (context) => {
          const item = context.row.original;

          return <div className="text-sm">{item.country.name}</div>;
        },
        enableGlobalFilter: true,
        enableSorting: true,
        header: () => "Name",
        id: "country",
        sortingFn: "text",
      }),
      columnHelper.accessor((row) => row.employeeCount, {
        cell: (context) => context.getValue() || "-",
        enableGlobalFilter: false,
        enableSorting: true,
        header: () => "Employees",
        id: "employeeCount",
      }),
      columnHelper.accessor((row) => row.grantCount, {
        cell: (context) => context.getValue() || "-",
        enableGlobalFilter: false,
        enableSorting: true,
        header: () => "Grants",
        id: "grantCount",
      }),
      columnHelper.display({
        cell: ({ row }) => {
          const isUnlocked = !!row.original.organizationGeography?.unlockedAt;
          if (!isUnlocked) {
            return (
              <Button disabled size="small" variant="Secondary Full">
                View handbook
              </Button>
            );
          }
          return (
            <LinkButton
              size="small"
              to={generatePath(
                APPLICATION_ROUTES.organizationEquityHandbooksCountry,
                {
                  countryCode: row.original.country.code,
                  organizationId: organization.id,
                },
              )}
              variant="Secondary Full"
            >
              View handbook
            </LinkButton>
          );
        },
        id: "action",
        meta: {
          alignRight: true,
        },
      }),
    ],
    [organization.id],
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const onGlobalFilterChange = useCallback(
    (value: string) => {
      setSearchParams({ search: value });
    },
    [setSearchParams],
  );

  const handleSearch = useCallback(
    (value: string) => {
      searchParams.set("search", value);
      setSearchParams(searchParams, { replace: true });
    },
    [searchParams, setSearchParams],
  );

  const searchValue = searchParams.get("search");

  const table = useReactTable({
    columns,
    data: useMemo(
      () => [...organization.suggestedCountries],
      [organization.suggestedCountries],
    ),
    enableGlobalFilter: true,
    enableRowSelection: false,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    globalFilterFn: "includesString",
    onGlobalFilterChange,
    state: {
      globalFilter: searchValue,
    },
  });

  const listIsEmpty = organization.suggestedCountries.length === 0;

  return (
    <LargeOneColumnLayout
      maxWidth={1200}
      organizationFragment={organization}
      showFooter
      subtitle="Equity compensation guides per country"
      title="Equity handbooks"
    >
      <div className="space-y-8">
        {listIsEmpty ? (
          <EmptyStatePlaceholder />
        ) : (
          <div className="space-y-6">
            <div className="flex gap-4">
              <SearchBar
                className="w-full max-w-[260px]"
                onChange={handleSearch}
                placeholder="Search handbooks..."
                value={searchValue ?? ""}
              />
            </div>
            <div>
              <Table.Smart
                rowRenderer={({ cells, rowData }) => {
                  const isUnlocked =
                    !!rowData.original.organizationGeography?.unlockedAt;

                  if (!isUnlocked) {
                    return (
                      <Table.Row className="cursor-not-allowed">
                        {cells}
                      </Table.Row>
                    );
                  }

                  return (
                    <Table.LinkRow
                      className="cursor-pointer"
                      to={generatePath(
                        APPLICATION_ROUTES.organizationEquityHandbooksCountry,
                        {
                          countryCode: rowData.original.country.code,
                          organizationId: organization.id,
                        },
                      )}
                    >
                      {cells}
                    </Table.LinkRow>
                  );
                }}
                table={table}
                tableClassName="grid-cols-[auto_1fr_1fr_1fr_auto]"
                tableDisplay="grid"
              />
            </div>
          </div>
        )}
        <HelpCard
          buttonLabel="Contact Us"
          label="Any questions? Feel free to contact us"
        />
      </div>
    </LargeOneColumnLayout>
  );
};

const QUERY = graphql`
  query EquityHandbooks_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...EquityHandbooks_Organization
    }
  }
`;

function EmptyStatePlaceholder() {
  const supportEmailAddress = useApplicationSupportEmailAddress();
  const mailtoSubject = encodeURI(`Questions regarding equity handbook `);

  return (
    <EmptyState
      callToAction={
        <a href={`mailto:${supportEmailAddress}?subject=${mailtoSubject}`}>
          Contact us
        </a>
      }
    >
      You don’t have any EoR employees active in any country.
    </EmptyState>
  );
}

const EquityHandbooksPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<EquityHandbooks_Query>(QUERY, {
    organizationId,
  });

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="EquityHandbooks"
      analyticsName="Admin - Equity Handbooks"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} equity handbooks`}
    >
      <EquityHandbooksPage_ organizationFragment={query.organization} />
    </Page>
  );
};

export default EquityHandbooksPage;
