/**
 * @generated SignedSource<<cda29aafb00d1ff77394b9d4047ca0fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CannotBeInvitedReason = "ALREADY_INVITED" | "MISSING_INFORMATION" | "NO_GRANTS" | "REMOTE_EMPLOYEE" | "SETTLED";
import { FragmentRefs } from "relay-runtime";
export type EmployeePortalTopSection_Deferred_Organization$data = {
  readonly employees: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly cannotBeInvitedReason: CannotBeInvitedReason | null;
        readonly hasPortalAccess: boolean;
        readonly lastSeenAt: string | null;
      };
    }>;
  };
  readonly granteePortalSettings: {
    readonly displayFullyDilutedValues: boolean;
    readonly showProjectionScenarios: boolean;
  };
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"CurrencySymbol_Organization" | "CurrencyUnit_Organization" | "FormattedCurrency_Organization">;
  readonly " $fragmentType": "EmployeePortalTopSection_Deferred_Organization";
};
export type EmployeePortalTopSection_Deferred_Organization$key = {
  readonly " $data"?: EmployeePortalTopSection_Deferred_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeePortalTopSection_Deferred_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmployeePortalTopSection_Deferred_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GranteePortalSettings",
      "kind": "LinkedField",
      "name": "granteePortalSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayFullyDilutedValues",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "showProjectionScenarios",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "employees",
      "args": null,
      "concreteType": "GranteesConnection",
      "kind": "LinkedField",
      "name": "grantees",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GranteeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Grantee",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasPortalAccess",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cannotBeInvitedReason",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastSeenAt",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedCurrency_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurrencySymbol_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurrencyUnit_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "e22677d636a0d3ae09238b18374e4073";

export default node;
