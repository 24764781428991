/**
 * @generated SignedSource<<732f57fb57b9925bdc4764dc480ecafb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BackloadedVestingOccurrence = "Every3Months" | "EveryMonth";
export type BackloadedVestingSchedulePeriodAttributes = {
  durationInMonths: number;
  percentageVested: number;
};
export type VestingSchedulePreview_BackloadedVestingSchedulePreview_Query$variables = {
  cliffActivatedOnFirstPeriod: boolean;
  name: string;
  organizationId: string;
  periods: ReadonlyArray<BackloadedVestingSchedulePeriodAttributes>;
  quantityGranted: number;
  vestingOccurrence: BackloadedVestingOccurrence;
  vestingStartDate: string;
};
export type VestingSchedulePreview_BackloadedVestingSchedulePreview_Query$data = {
  readonly vestingDataPointsFromBackloadedVestingScheduleAttributes: ReadonlyArray<{
    readonly cumulativeVested: number;
    readonly date: string;
  }>;
};
export type VestingSchedulePreview_BackloadedVestingSchedulePreview_Query = {
  response: VestingSchedulePreview_BackloadedVestingSchedulePreview_Query$data;
  variables: VestingSchedulePreview_BackloadedVestingSchedulePreview_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cliffActivatedOnFirstPeriod"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "periods"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "quantityGranted"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "vestingOccurrence"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "vestingStartDate"
},
v7 = {
  "alias": null,
  "args": [
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "cliffActivatedOnFirstPeriod",
          "variableName": "cliffActivatedOnFirstPeriod"
        },
        {
          "kind": "Variable",
          "name": "name",
          "variableName": "name"
        },
        {
          "kind": "Variable",
          "name": "periods",
          "variableName": "periods"
        },
        {
          "kind": "Variable",
          "name": "vestingOccurrence",
          "variableName": "vestingOccurrence"
        }
      ],
      "kind": "ObjectValue",
      "name": "attributes"
    },
    {
      "kind": "Variable",
      "name": "organizationId",
      "variableName": "organizationId"
    },
    {
      "kind": "Variable",
      "name": "quantityGranted",
      "variableName": "quantityGranted"
    },
    {
      "kind": "Variable",
      "name": "vestingStartDate",
      "variableName": "vestingStartDate"
    }
  ],
  "concreteType": "VestingDataPoint",
  "kind": "LinkedField",
  "name": "vestingDataPointsFromBackloadedVestingScheduleAttributes",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cumulativeVested",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "VestingSchedulePreview_BackloadedVestingSchedulePreview_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": (v7/*: any*/),
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v6/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "VestingSchedulePreview_BackloadedVestingSchedulePreview_Query",
    "selections": [
      (v7/*: any*/)
    ]
  },
  "params": {
    "cacheID": "ad670ad475b4f47897a56641d6e0059d",
    "id": null,
    "metadata": {},
    "name": "VestingSchedulePreview_BackloadedVestingSchedulePreview_Query",
    "operationKind": "query",
    "text": "query VestingSchedulePreview_BackloadedVestingSchedulePreview_Query(\n  $vestingStartDate: Date!\n  $quantityGranted: Int!\n  $vestingOccurrence: BackloadedVestingOccurrence!\n  $periods: [BackloadedVestingSchedulePeriodAttributes!]!\n  $name: String!\n  $cliffActivatedOnFirstPeriod: Boolean!\n  $organizationId: OrganizationId!\n) {\n  vestingDataPointsFromBackloadedVestingScheduleAttributes(organizationId: $organizationId, vestingStartDate: $vestingStartDate, quantityGranted: $quantityGranted, attributes: {cliffActivatedOnFirstPeriod: $cliffActivatedOnFirstPeriod, name: $name, periods: $periods, vestingOccurrence: $vestingOccurrence}) {\n    cumulativeVested\n    date\n  }\n}\n"
  }
};
})();

(node as any).hash = "46ef53b4bc729bdcc2db43b2703f6e6c";

export default node;
