/**
 * @generated SignedSource<<5ad5b3a02fdb51394ceb1b156be141f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EsopPoolProgressCard_EssentialsOverview_Organization$data = {
  readonly esopPoolShares: number | null;
  readonly id: string;
  readonly poolGrantedSharesBreakdown: {
    readonly total: number;
  };
  readonly " $fragmentType": "EsopPoolProgressCard_EssentialsOverview_Organization";
};
export type EsopPoolProgressCard_EssentialsOverview_Organization$key = {
  readonly " $data"?: EsopPoolProgressCard_EssentialsOverview_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"EsopPoolProgressCard_EssentialsOverview_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EsopPoolProgressCard_EssentialsOverview_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "esopPoolShares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SharesBreakdown",
      "kind": "LinkedField",
      "name": "poolGrantedSharesBreakdown",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "dbf54fff91a976a6bcfdf275b58dad48";

export default node;
