import { RadioGroup } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import { BoxedIcon, Button, InfoBlock, Pill, Text } from "@remote-com/norma";
import { IconV2DuotoneCheckSquare } from "@remote-com/norma/icons/IconV2DuotoneCheckSquare";
import { compact, isNaN, isNil } from "lodash";
import React, { useCallback, useId, useMemo, useState } from "react";
import { Controller, DeepPartial, useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import { generatePath, Link, useNavigate, useParams } from "react-router-dom";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { CtmsGrantVestingTag } from "../../../../components/CtmsGrantVestingTag";
import { DeclarationNoticeMessage } from "../../../../components/DeclarationNoticeMessage";
import { FormattedCurrency } from "../../../../components/Formatted/FormattedCurrency";
import { FormattedNumber } from "../../../../components/Formatted/FormattedNumber";
import { Page } from "../../../../components/Page";
import { RadioCard } from "../../../../components/RadioCard";
import { RemoteLikeFakeInput } from "../../../../components/RemoteLikeFakeInput";
import { ShortDate } from "../../../../components/ShortDate";
import {
  FileInput,
  useFileInputFormUploadController,
} from "../../../../components/ui/Form/FileInput";
import { FormRow } from "../../../../components/ui/Form/FormRow";
import { RemoteInputCurrency } from "../../../../components/ui/Form/Inputs/RemoteInputCurrency";
import { RemoteInputDatePicker } from "../../../../components/ui/Form/Inputs/RemoteInputDatePicker";
import { RemoteInputSelect } from "../../../../components/ui/Form/Inputs/RemoteInputSelect";
import { RemoteInputText } from "../../../../components/ui/Form/Inputs/RemoteInputText";
import { RemoteInputTextArea } from "../../../../components/ui/Form/Inputs/RemoteInputTextArea";
import {
  CheckoutLikeLayout,
  CheckoutLikeLayoutAside,
  CheckoutLikeLayoutAsideSection,
  CheckoutLikeLayoutAsideSectionItem,
  CheckoutLikeLayoutMainCard,
  CheckoutLikeLayoutMainSection,
  CheckoutLikeLayoutMainTitle,
} from "../../../../components/ui/Layout/CheckoutLikeLayout";
import { cx } from "../../../../helpers/cva.config";
import { useQuery } from "../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { AwardSuperType } from "../../EquityOffer/__generated__/Incentive_EquityOffer.graphql";
import {
  ExerciseRequestDeclareNew_DeclareExerciseRequest_Mutation,
  ExerciseValueSource,
} from "./__generated__/ExerciseRequestDeclareNew_DeclareExerciseRequest_Mutation.graphql";
import {
  ExerciseRequestDeclareNew_Organization$data,
  ExerciseRequestDeclareNew_Organization$key,
} from "./__generated__/ExerciseRequestDeclareNew_Organization.graphql";
import { ExerciseRequestDeclareNew_Query } from "./__generated__/ExerciseRequestDeclareNew_Query.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment ExerciseRequestDeclareNew_Organization on Organization {
    id
    latestFairMarketValue {
      value
    }
    grantees {
      edges {
        node {
          id
          name
          email
          taxResidenceCountry {
            emoji
          }
          ctmsGrants(grantStatusIn: [Active, Terminated]) {
            label
            exercisableNonExercisedShares
            vestingStartDate
            exercisePrice
            quantityIssued
            matchingInstrument {
              awardSuperType
            }
            postTerminationExercisePeriod {
              displayName
            }
            cumulativeVested
            ...CtmsGrantVestingTag_CtmsGrant
          }
        }
      }
    }
    ...RemoteInputCurrency_Organization
    ...FormattedCurrency_Organization
  }
`;

type Employee =
  ExerciseRequestDeclareNew_Organization$data["grantees"]["edges"][number]["node"];
type EmployeeGrant = Employee["ctmsGrants"][number];

const EXERCISE_PRICE_SOURCE_LABEL_MAP: Record<ExerciseValueSource, string> = {
  FAIR_MARKET_VALUE: "Fair market value",
  I_DONT_KNOW: "I don't know",
  OTHER: "Other",
  RECENT_FINANCING_ROUND: "Recent financing round",
  RECENT_SECONDARY_TRANSACTION: "Recent secondary transaction",
  RECENT_THIRD_PARTY_REPORT: "Recent third party report such as 409A valuation",
};

const EXERCISE_PRICE_SOURCE_OPTIONS = Object.entries(
  EXERCISE_PRICE_SOURCE_LABEL_MAP,
).map(([value, label]) => ({
  label,
  value,
}));

const DECLARE_EXERCISE_REQUEST_MUTATION = graphql`
  mutation ExerciseRequestDeclareNew_DeclareExerciseRequest_Mutation(
    $certificateSigned: Boolean!
    $ctmsGrantLabel: String!
    $documentIds: [UUID!]!
    $valueAtExercise: Float!
    $valueAtExerciseSource: ExerciseValueSource!
    $valueAtExerciseSourceOtherDetails: String
    $organizationId: OrganizationId!
    $quantityExercised: Int!
    $fundsTransferredAt: Date
  ) {
    declareExerciseRequest(
      certificateSigned: $certificateSigned
      ctmsGrantLabel: $ctmsGrantLabel
      documentIds: $documentIds
      fundsTransferredAt: $fundsTransferredAt
      organizationId: $organizationId
      quantityExercised: $quantityExercised
      valueAtExercise: $valueAtExercise
      valueAtExerciseSource: $valueAtExerciseSource
      valueAtExerciseSourceOtherDetails: $valueAtExerciseSourceOtherDetails
    ) {
      id
    }
  }
`;

const HUMAN_READABLE_AWARD_SUPER_TYPE_MAP: Record<AwardSuperType, string> = {
  ISO: "Stock Options",
  NSO: "Stock Options",
  RSA: "Restricted Stock Award",
  RSU: "Restricted Stock Unit",
  SAR: "Stock Appreciation Rights",
};

const useFormSchema = (
  organization: ExerciseRequestDeclareNew_Organization$data,
) =>
  useMemo(
    () =>
      z
        .object({
          certificateSigned: z.coerce.boolean(),
          documentIds: z.array(z.string()).optional(),
          employeeId: z.string(),
          fundsTransferredAt: z.string().date(),
          grantLabel: z.string(),
          quantityExercised: z.coerce
            .number({
              invalid_type_error: "This is required",
            })
            .positive(),
          valueAtExercise: z.coerce.number().positive(),
          valueAtExerciseSource: z.enum([
            "FAIR_MARKET_VALUE",
            "I_DONT_KNOW",
            "OTHER",
            "RECENT_FINANCING_ROUND",
            "RECENT_SECONDARY_TRANSACTION",
            "RECENT_THIRD_PARTY_REPORT",
          ]),
          valueAtExerciseSourceOtherDetails: z.string().optional(),
        })
        .refine(
          (data) => {
            if (data.certificateSigned) {
              return !isNil(data.fundsTransferredAt);
            }

            return true;
          },
          {
            message: "This is required",
            path: ["fundsTransferredAt"],
          },
        )
        .refine(
          (data) => {
            const selectedEmployee = organization.grantees.edges.find(
              (edge) => edge.node.id === data.employeeId,
            );

            if (!selectedEmployee) {
              return true;
            }

            const selectedGrant = selectedEmployee.node.ctmsGrants.find(
              (grant) => grant.label === data.grantLabel,
            );

            if (!selectedGrant) {
              return true;
            }

            if (
              data.quantityExercised <=
              selectedGrant.exercisableNonExercisedShares
            ) {
              return true;
            }

            return false;
          },
          {
            message: "This cannot be greater than the exercisable shares",
            path: ["quantityExercised"],
          },
        ),
    [organization],
  );

type FormInput = z.input<ReturnType<typeof useFormSchema>>;
type FormOutput = z.output<ReturnType<typeof useFormSchema>>;

function DetailsCard({
  backgroundColor,
  children,
  label,
}: React.PropsWithChildren<{
  backgroundColor: "blue" | "white";
  children: React.ReactNode;
  label: React.ReactNode;
}>) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={cx(
        "space-y-4 rounded-remote-lg border-[0.5px] border-grey-300 p-4",
        {
          "bg-background-subtle": backgroundColor === "blue",
          "bg-white": backgroundColor === "white",
        },
      )}
    >
      <div className="flex items-center justify-between gap-2">
        <div className="text-SM text-grey-600">{label}</div>
        <button
          className="flex items-center gap-1 text-SM/Medium text-brand-600"
          onClick={() => {
            setIsOpen((previousValue) => !previousValue);
          }}
          type="button"
        >
          {isOpen ? "Hide details" : "Show details"}
          <ChevronUpIcon
            className={cx("size-4 transition-all", {
              "rotate-180": isOpen,
            })}
          />
        </button>
      </div>
      {isOpen && children}
    </div>
  );
}

function EstimatedExercisePrice({
  exercisePrice,
  organization,
  quantityExercised,
}: {
  exercisePrice: number;
  organization: ExerciseRequestDeclareNew_Organization$data;
  quantityExercised: number;
}) {
  return (
    <div className="space-y-4 rounded-remote-lg border-[0.5px] border-grey-300 bg-white p-4">
      <div className="text-XS text-grey-600">
        Projected exercise price for this request
      </div>
      <div className="grid grid-cols-[1fr_auto_1fr_auto_1fr] items-center gap-1">
        <RemoteLikeFakeInput
          label="Options"
          value={
            <FormattedNumber
              animated
              maximumFractionDigits={0}
              value={quantityExercised}
            />
          }
        />
        <span>*</span>
        <RemoteLikeFakeInput
          label="Strike price"
          value={
            <FormattedCurrency
              animated
              organizationFragment={organization}
              value={exercisePrice}
            />
          }
        />
        <span>=</span>
        <RemoteLikeFakeInput
          label="Exercise price"
          tone="accent"
          value={
            <FormattedCurrency
              animated
              organizationFragment={organization}
              value={exercisePrice * quantityExercised}
            />
          }
        />
      </div>
    </div>
  );
}

function ExerciseRequestDeclareNewContent({
  organizationFragment,
  selectedCTMSGrantLabel,
  selectedGranteeId,
}: {
  organizationFragment: ExerciseRequestDeclareNew_Organization$key;
  selectedCTMSGrantLabel: string | undefined;
  selectedGranteeId: string | undefined;
}) {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const formId = useId();
  const [declareExerciseRequest, declareExerciseRequestMutationIsInFlight] =
    useSafeMutation<ExerciseRequestDeclareNew_DeclareExerciseRequest_Mutation>(
      DECLARE_EXERCISE_REQUEST_MUTATION,
    );

  const employees = organization.grantees.edges.map((edge) => edge.node);
  const employeesWithAtLeastOneGrantWithExercisableNonExercisedShares =
    employees.filter((employee) =>
      employee.ctmsGrants.some(
        (grant) => grant.exercisableNonExercisedShares > 0,
      ),
    );
  const formSchema = useFormSchema(organization);

  const form = useForm<DeepPartial<FormInput>, unknown, FormOutput>({
    defaultValues: {
      employeeId: selectedGranteeId,
      grantLabel: selectedCTMSGrantLabel,
      valueAtExercise: organization.latestFairMarketValue?.value,
      valueAtExerciseSource: "FAIR_MARKET_VALUE",
    },
    disabled: declareExerciseRequestMutationIsInFlight,
    resolver: zodResolver(formSchema),
  });
  const fileInputUploadController = useFileInputFormUploadController({
    control: form.control,
    multiple: true,
    name: "documentIds",
  });
  const [
    employeeId,
    _certificateSigned,
    _quantityExercised,
    grantLabel,
    _valueAtExercise,
    valueAtExerciseSource,
  ] = form.watch([
    "employeeId",
    "certificateSigned",
    "quantityExercised",
    "grantLabel",
    "valueAtExercise",
    "valueAtExerciseSource",
  ]);

  const certificateSigned =
    (_certificateSigned as unknown as string) === "true";
  const quantityExercised = isNil(_quantityExercised)
    ? undefined
    : Number(_quantityExercised);
  const valueAtExercise = isNil(_valueAtExercise)
    ? organization.latestFairMarketValue?.value
    : Number(_valueAtExercise);

  const handleEmployeeChange = useCallback(
    (employeeId_: null | string) => {
      if (employeeId_ === employeeId) {
        return;
      }

      form.reset({
        employeeId: employeeId_ ?? undefined,
      });
      const selectedEmployee = employees.find(
        (employee) => employee.id === employeeId_,
      );
      // If the employee has only one grant, set the grant label automatically
      if (!selectedEmployee) {
        return;
      }

      const employeeGrantsWithExercisableNonExercisedShares =
        getEmployeeGrantsWithExercisableNonExercisedShares(selectedEmployee);

      if (
        employeeGrantsWithExercisableNonExercisedShares[0] &&
        employeeGrantsWithExercisableNonExercisedShares.length === 1
      ) {
        form.setValue(
          "grantLabel",
          employeeGrantsWithExercisableNonExercisedShares[0].label,
        );
      }
    },
    [employeeId, form, employees],
  );
  const selectedEmployee = employees.find(
    (employee) => employee.id === employeeId,
  );
  const selectedGrant = selectedEmployee?.ctmsGrants.find(
    (grant) => grant.label === grantLabel,
  );
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async (data: FormOutput) => {
      await declareExerciseRequest({
        variables: {
          certificateSigned: data.certificateSigned,
          ctmsGrantLabel: data.grantLabel,
          documentIds: data.documentIds ?? [],
          fundsTransferredAt: data.fundsTransferredAt,
          organizationId: organization.id,
          quantityExercised: data.quantityExercised,
          valueAtExercise: data.valueAtExercise,
          valueAtExerciseSource: data.valueAtExerciseSource,
          valueAtExerciseSourceOtherDetails:
            data.valueAtExerciseSource === "OTHER"
              ? data.valueAtExerciseSourceOtherDetails
              : null,
        },
      });

      await navigate(
        generatePath(APPLICATION_ROUTES.organizationExerciseRequests, {
          organizationId: organization.id,
        }),
        {
          replace: true,
        },
      );
    },
    [declareExerciseRequest, organization.id, navigate],
  );

  const selectedEmployeeGrantsWithExercisableNonExercisedShares =
    useMemo(() => {
      if (!selectedEmployee) {
        return null;
      }

      return getEmployeeGrantsWithExercisableNonExercisedShares(
        selectedEmployee,
      );
    }, [selectedEmployee]);

  const selectedEmployeeOptions = useMemo(() => {
    return employeesWithAtLeastOneGrantWithExercisableNonExercisedShares.map(
      (employee) => ({
        label: [employee.taxResidenceCountry?.emoji, employee.name].join(" "),
        value: employee.id,
      }),
    );
  }, [employeesWithAtLeastOneGrantWithExercisableNonExercisedShares]);

  const selectedEmployeeOption = useMemo(() => {
    if (!selectedEmployee) {
      return undefined;
    }

    return { label: selectedEmployee.name, value: selectedEmployee.id };
  }, [selectedEmployee]);

  const totalSharesValue = useMemo(() => {
    if (isNil(valueAtExercise) || isNil(quantityExercised)) {
      return null;
    }

    return valueAtExercise * quantityExercised;
  }, [valueAtExercise, quantityExercised]);

  const totalExercisePrice = useMemo(() => {
    if (isNil(selectedGrant?.exercisePrice) || isNil(quantityExercised)) {
      return null;
    }

    return selectedGrant.exercisePrice * quantityExercised;
  }, [selectedGrant?.exercisePrice, quantityExercised]);

  const spread = useMemo(() => {
    if (isNil(totalSharesValue) || isNil(totalExercisePrice)) {
      return null;
    }

    return totalSharesValue - totalExercisePrice;
  }, [totalSharesValue, totalExercisePrice]);

  return (
    <CheckoutLikeLayout
      aside={
        <CheckoutLikeLayoutAside title="Exercise details">
          <CheckoutLikeLayoutAsideSection title="Employee details">
            <CheckoutLikeLayoutAsideSectionItem title="Name">
              {selectedEmployee?.name}
            </CheckoutLikeLayoutAsideSectionItem>
            <CheckoutLikeLayoutAsideSectionItem title="Email">
              {selectedEmployee?.email}
            </CheckoutLikeLayoutAsideSectionItem>
          </CheckoutLikeLayoutAsideSection>
          <CheckoutLikeLayoutAsideSection title="Exercise request">
            <CheckoutLikeLayoutAsideSectionItem title="Grant">
              {selectedGrant?.label}
            </CheckoutLikeLayoutAsideSectionItem>
            <CheckoutLikeLayoutAsideSectionItem title="Exercise status">
              {!isNil(_certificateSigned) &&
                (certificateSigned ? "Shares issued" : "Shares not issued")}
            </CheckoutLikeLayoutAsideSectionItem>
            <CheckoutLikeLayoutAsideSectionItem title="Quantity exercised">
              {!isNaN(quantityExercised) && !isNil(quantityExercised) && (
                <>
                  <FormattedNumber animated value={Number(quantityExercised)} />{" "}
                  options
                </>
              )}
            </CheckoutLikeLayoutAsideSectionItem>
            <CheckoutLikeLayoutAsideSectionItem title="Exercise price">
              {!isNil(selectedGrant?.exercisePrice) && (
                <FormattedCurrency
                  animated
                  organizationFragment={organization}
                  value={selectedGrant.exercisePrice}
                />
              )}
            </CheckoutLikeLayoutAsideSectionItem>
            <CheckoutLikeLayoutAsideSectionItem title="Shares value">
              {!isNil(totalSharesValue) && (
                <FormattedCurrency
                  animated
                  organizationFragment={organization}
                  value={totalSharesValue}
                />
              )}
            </CheckoutLikeLayoutAsideSectionItem>
            <CheckoutLikeLayoutAsideSectionItem title="Spread">
              {!isNil(spread) && (
                <FormattedCurrency
                  animated
                  organizationFragment={organization}
                  value={spread}
                />
              )}
            </CheckoutLikeLayoutAsideSectionItem>
          </CheckoutLikeLayoutAsideSection>
        </CheckoutLikeLayoutAside>
      }
      navigationBreadcrumbs={[
        {
          label: "Option exercises",
          to: generatePath(APPLICATION_ROUTES.organizationExerciseRequests, {
            organizationId: organization.id,
          }),
        },
      ]}
      pageTitle="Declare new exercise"
    >
      <CheckoutLikeLayoutMainCard>
        <CheckoutLikeLayoutMainTitle
          description="Exercise declarations are essential in order for Remote to take care of local reporting obligations relating to equity compensation, without missing deadlines and incurring fines and other penalties."
          illustration={<Pill>option Exercise declaration</Pill>}
          title="Declare new option exercise"
        />
        <form id={formId} onSubmit={form.handleSubmit(onSubmit)}>
          <CheckoutLikeLayoutMainSection
            subtitle="Select the employee who exercised the options"
            title="Employee"
          >
            <FormRow.Form control={form.control} name="employeeId">
              <RemoteInputSelect
                disabled={!!selectedGranteeId}
                label="Employee"
                name="employeeId"
                onChange={(option) => {
                  handleEmployeeChange(option.value);
                }}
                options={selectedEmployeeOptions}
                placeholder="Search your employees..."
                value={selectedEmployeeOption}
              />
            </FormRow.Form>
          </CheckoutLikeLayoutMainSection>
          {selectedEmployeeGrantsWithExercisableNonExercisedShares && (
            <CheckoutLikeLayoutMainSection
              subtitle={
                selectedEmployeeGrantsWithExercisableNonExercisedShares.length >
                  1 && "Select the option grant being exercised"
              }
              title="Option grant"
            >
              <FormRow.Form control={form.control} name="grantLabel">
                <Controller
                  control={form.control}
                  name="grantLabel"
                  render={({ field }) => (
                    <RadioGroup
                      className="flex flex-wrap gap-2"
                      disabled={!!selectedCTMSGrantLabel}
                      onChange={field.onChange}
                      value={field.value}
                    >
                      {selectedEmployeeGrantsWithExercisableNonExercisedShares.map(
                        (grant) => (
                          <RadioCard key={grant.label} value={grant.label}>
                            <div className="flex items-center gap-2">
                              {grant.label}
                              <CtmsGrantVestingTag ctmsGrantFragment={grant} />
                            </div>
                          </RadioCard>
                        ),
                      )}
                    </RadioGroup>
                  )}
                />
              </FormRow.Form>

              {selectedGrant && <GrantDetailsCard grant={selectedGrant} />}
              <DeclarationNoticeMessage
                action={
                  <Link
                    to={generatePath(
                      APPLICATION_ROUTES.organizationDeclareNewGrant,
                      {
                        organizationId: organization.id,
                      },
                    )}
                  >
                    Declare a new grant
                  </Link>
                }
              >
                Missing a grant here?
              </DeclarationNoticeMessage>
            </CheckoutLikeLayoutMainSection>
          )}
          {selectedGrant && (
            <>
              <CheckoutLikeLayoutMainSection
                subtitle="Have the shares been issued already?"
                title="Exercise status"
              >
                <DeclarationNoticeMessage>
                  Shares are considered issued once the options exercise request
                  has been approved and the employee has officially become a
                  shareholder of the company
                </DeclarationNoticeMessage>
                <FormRow.Form control={form.control} name="certificateSigned">
                  <Controller
                    control={form.control}
                    name="certificateSigned"
                    render={({ field }) => (
                      <RadioGroup
                        className="flex flex-wrap gap-2"
                        onChange={field.onChange}
                        value={field.value}
                      >
                        <RadioCard value="true">Yes, shares issued</RadioCard>
                        <RadioCard value="false">No</RadioCard>
                      </RadioGroup>
                    )}
                  />
                </FormRow.Form>
                {!isNil(_certificateSigned) && (
                  <div>
                    <FormRow.Form
                      control={form.control}
                      name="fundsTransferredAt"
                    >
                      <RemoteInputDatePicker.Form
                        control={form.control}
                        label={
                          certificateSigned
                            ? "Issuance date"
                            : "Expected issuance date"
                        }
                        name="fundsTransferredAt"
                      />
                    </FormRow.Form>
                    {!certificateSigned && (
                      <div className="px-2 py-1 text-XS/Medium text-grey-700">
                        (Optional)
                      </div>
                    )}
                  </div>
                )}
              </CheckoutLikeLayoutMainSection>
              <CheckoutLikeLayoutMainSection
                subtitle="Please indicate the number of options that have been or will be exercised"
                title="Quantity exercised"
              >
                <FormRow.Form control={form.control} name="quantityExercised">
                  <RemoteInputText.Form
                    control={form.control}
                    label="Number of options"
                    name="quantityExercised"
                    placeholder="Enter the quantity exercised"
                    type="number"
                  />
                </FormRow.Form>

                {!isNil(selectedGrant.exercisePrice) &&
                  !isNaN(quantityExercised) &&
                  !isNil(quantityExercised) && (
                    <EstimatedExercisePrice
                      exercisePrice={selectedGrant.exercisePrice}
                      organization={organization}
                      quantityExercised={Number(quantityExercised)}
                    />
                  )}
              </CheckoutLikeLayoutMainSection>
              {!isNil(selectedGrant) && (
                <CheckoutLikeLayoutMainSection
                  subtitle="Include here the current value of each share received by the employee. (If you are a US company, you could use the Fair Market Value based on your current 409A valuation)"
                  title="Individual share value"
                >
                  <div className="space-y-4">
                    <FormRow.Form control={form.control} name="valueAtExercise">
                      <RemoteInputCurrency.Form
                        control={form.control}
                        label="Individual share value"
                        name="valueAtExercise"
                        organizationFragment={organization}
                      />
                    </FormRow.Form>
                    <FormRow.Form
                      control={form.control}
                      name="valueAtExerciseSource"
                    >
                      <RemoteInputSelect.Form
                        control={form.control}
                        label="What is the value above based on?"
                        name="valueAtExerciseSource"
                        options={EXERCISE_PRICE_SOURCE_OPTIONS}
                      />
                    </FormRow.Form>
                    {valueAtExerciseSource === "OTHER" && (
                      <div>
                        <FormRow.Form
                          control={form.control}
                          name="valueAtExerciseSourceOtherDetails"
                        >
                          <RemoteInputTextArea.Form
                            control={form.control}
                            label="Can you give more details?"
                            name="valueAtExerciseSourceOtherDetails"
                          />
                        </FormRow.Form>
                        <div className="px-2 py-1 text-right text-XS/Medium text-grey-700">
                          (Optional)
                        </div>
                      </div>
                    )}
                    {!isNil(quantityExercised) &&
                      !isNil(valueAtExercise) &&
                      !isNil(totalSharesValue) &&
                      !isNil(spread) && (
                        <SpreadCalculationCard
                          organization={organization}
                          quantityExercised={quantityExercised}
                          spread={spread}
                          totalSharesValue={totalSharesValue}
                          valueAtExercise={valueAtExercise}
                        />
                      )}
                  </div>
                </CheckoutLikeLayoutMainSection>
              )}
              <CheckoutLikeLayoutMainSection
                subtitle="Upload any third party valuation determining the valuation of the shares, the individual option exercise documentation or any helpful document for tax reporting purposes."
                title="Upload associated documents"
                upperTitle={<Pill>Optional</Pill>}
              >
                <FormRow.Form control={form.control} name="documentIds">
                  <FileInput {...fileInputUploadController.inputProps} />
                </FormRow.Form>
              </CheckoutLikeLayoutMainSection>
            </>
          )}
        </form>
      </CheckoutLikeLayoutMainCard>

      {!isNil(selectedGrant) && (
        <CheckoutLikeLayoutMainCard>
          <CheckoutLikeLayoutMainTitle
            description="Please confirm the exercise declaration"
            illustration={
              <BoxedIcon Icon={IconV2DuotoneCheckSquare} tone="cyan" />
            }
            title="Confirm exercise declaration"
          />
          <div className="space-y-4">
            <div className="space-y-2 rounded-2xl border border-grey-200 pb-6">
              <div className="flex items-center justify-between gap-2 rounded-3xl border-8 border-white bg-grey-50 p-4">
                <Text variant="lgMedium">What’s next?</Text>
              </div>
              <ul className="list-outside list-disc space-y-6 pl-8 pr-6">
                <li>
                  <span className="text-SM/Medium">
                    After you declare the exercise
                  </span>
                  <div className="text-SM">
                    The Remote tax team will review your exercise declaration
                    and make sure the declaration is complete
                  </div>
                </li>
                <li>
                  <span className="text-SM/Medium">
                    We&apos;ll get back to you with the relevant tax information
                  </span>
                  <div className="text-SM">
                    The Remote tax team will get back to you with information
                    about the tax treatment of the exercise, such as the amount
                    of tax to be prepaid by the employee (for instance via a
                    net-settlement), or withheld from the employee&apos;s
                    salary, and potential employer social security contributions
                    potential employer social security contributions
                  </div>
                </li>
              </ul>
            </div>
            <DeclarationNoticeMessage variant="warning">
              Be aware already that the spread will be used to calculate the
              taxes, which will have to be paid on top of the exercise price.
            </DeclarationNoticeMessage>
            <Button
              className="w-full"
              disabled={declareExerciseRequestMutationIsInFlight}
              form={formId}
              isLoading={declareExerciseRequestMutationIsInFlight}
              type="submit"
            >
              Declare exercise
            </Button>
            <div className="text-XS text-grey-700">
              These declarations are essential in order for Remote to take care
              of local reporting obligations relating to equity compensation,
              without missing deadlines and incurring fines and other penalties.
            </div>
          </div>
        </CheckoutLikeLayoutMainCard>
      )}
    </CheckoutLikeLayout>
  );
}

function GrantDetailGridItem({
  context,
  denominator,
  label,
  value,
}: {
  context?: React.ReactNode;
  denominator?: React.ReactNode;
  label: React.ReactNode;
  value: React.ReactNode;
}) {
  return (
    <div className="space-y-2 p-6">
      <div className="text-XS/SemiBold uppercase text-grey-600">{label}</div>
      <div className="text-XL/Medium text-grey-900">
        {value}
        {denominator && (
          <>
            {" "}
            <span className="text-SM">{denominator}</span>
          </>
        )}
      </div>
      {context && (
        <div className="text-2XS/Medium uppercase text-grey-600">{context}</div>
      )}
    </div>
  );
}

function GrantDetailsCard({ grant }: { grant: EmployeeGrant }) {
  return (
    <DetailsCard backgroundColor="blue" label={<>{grant.label} details</>}>
      <div className="grid grid-cols-2 rounded-remote-lg border-[0.5px] border-grey-300 bg-white [&>*:first-child]:border-r-[0.5px] [&>*:first-child]:border-grey-300">
        <GrantDetailGridItem
          denominator="shares"
          label="Options quantity"
          value={<FormattedNumber animated value={grant.quantityIssued} />}
        />
        <GrantDetailGridItem
          denominator={
            <>
              / <FormattedNumber value={grant.quantityIssued} />
            </>
          }
          label="Total vested"
          value={<FormattedNumber animated value={grant.cumulativeVested} />}
        />
      </div>
      <div className="px-2">
        <InfoBlock
          list={compact([
            grant.matchingInstrument?.awardSuperType && {
              title: "Equity type",
              value:
                HUMAN_READABLE_AWARD_SUPER_TYPE_MAP[
                  grant.matchingInstrument.awardSuperType
                ],
            },
            grant.vestingStartDate && {
              title: "Vesting start date",
              value: <ShortDate value={grant.vestingStartDate} />,
            },
            grant.postTerminationExercisePeriod?.displayName && {
              title: "Post-termination exercise period",
              value: grant.postTerminationExercisePeriod.displayName,
            },
          ])}
        />
      </div>
    </DetailsCard>
  );
}

function SpreadCalculationCard({
  organization,
  quantityExercised,
  spread,
  totalSharesValue,
  valueAtExercise,
}: {
  organization: ExerciseRequestDeclareNew_Organization$data;
  quantityExercised: number;
  spread: number;
  totalSharesValue: number;
  valueAtExercise: number;
}) {
  return (
    <DetailsCard backgroundColor="white" label="Estimated spread calculation">
      <DeclarationNoticeMessage variant="warning">
        Be aware already that the spread will be used to calculate the taxes,
        which will have to be paid on top of the exercise price
      </DeclarationNoticeMessage>
      <div className="grid grid-cols-2 rounded-remote-lg border-[0.5px] border-grey-300 bg-white [&>*:first-child]:border-r-[0.5px] [&>*:first-child]:border-grey-300">
        <GrantDetailGridItem
          context={
            <>
              <FormattedCurrency
                animated
                organizationFragment={organization}
                value={valueAtExercise}
              />
              {" * "}
              <FormattedNumber animated value={quantityExercised} /> shares
            </>
          }
          label="Shares value at exercise (all&nbsp;exercised&nbsp;shares)"
          value={
            <FormattedCurrency
              animated
              organizationFragment={organization}
              value={totalSharesValue}
            />
          }
        />
        <GrantDetailGridItem
          context="Shares value - Exercise price"
          label="Spread (Bargain&nbsp;element)"
          value={
            <FormattedCurrency
              animated
              organizationFragment={organization}
              value={spread}
            />
          }
        />
      </div>
    </DetailsCard>
  );
}

const QUERY = graphql`
  query ExerciseRequestDeclareNew_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...ExerciseRequestDeclareNew_Organization
    }
  }
`;

export const ExerciseRequestDeclareNew = () => {
  const organizationId = useOrganizationIdParam();
  const { selectedCTMSGrantLabel, selectedGranteeId } = useParams();

  const {
    query: { organization },
  } = useQuery<ExerciseRequestDeclareNew_Query>(QUERY, {
    organizationId,
  });

  if (!organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Declare New Grant"
      analyticsName="Admin - Declare New Grant"
      organizationId={organization.id}
      title={`Admin | ${organization.name} declare new grant`}
    >
      <ExerciseRequestDeclareNewContent
        organizationFragment={organization}
        selectedCTMSGrantLabel={selectedCTMSGrantLabel}
        selectedGranteeId={selectedGranteeId}
      />
    </Page>
  );
};

function getEmployeeGrantsWithExercisableNonExercisedShares(
  employee: Employee,
) {
  return employee.ctmsGrants.filter(
    (grant) => grant.exercisableNonExercisedShares > 0,
  );
}
