import { isEmpty } from "lodash";
import React, {
  startTransition,
  useCallback,
  useMemo,
  useState,
  useTransition,
} from "react";
import { FormattedMessage } from "react-intl";
import { useFragment, useRefetchableFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { EmptyListPlaceholder } from "../../../components/EmptyListPlaceholder/EmptyListPlaceholder";
import { Page } from "../../../components/Page";
import { SelectedItemsActionCard } from "../../../components/SelectedItemsActionCard";
import { useToaster } from "../../../components/Toaster";
import { Button } from "../../../components/ui/Button";
import { Toast } from "../../../components/ui/Toast";
import { useApplicationName } from "../../../hooks/useApplicationTheme";
import { useDebounced } from "../../../hooks/useDebounced";
import { useQuery } from "../../../hooks/useQuery";
import { useSafeMutation } from "../../../hooks/useSafeMutation";
import { LegalWorkflowLayout } from "../../../layouts/LegalWorkflowLayout";
import { useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Account$key } from "./__generated__/Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Account.graphql";
import { Implementation_GranteeTerminationCTMSGrantAmendmentRequests_MarkGrantAmendmentRequestsAsImplemented_Mutation } from "./__generated__/Implementation_GranteeTerminationCTMSGrantAmendmentRequests_MarkGrantAmendmentRequestsAsImplemented_Mutation.graphql";
import { Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Organization$key } from "./__generated__/Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Organization.graphql";
import { Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Organization_RefetchQuery } from "./__generated__/Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Organization_RefetchQuery.graphql";
import { Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Query } from "./__generated__/Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Query.graphql";
import { GranteeTerminationAmendmentRequestsTable } from "./GranteeTerminationAmendmentRequestsTable";

const ORGANIZATION_FRAGMENT = graphql`
  fragment Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Organization on Organization
  @refetchable(
    queryName: "Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Organization_RefetchQuery"
  )
  @argumentDefinitions(
    grantAmendmentRequestsSearch: { type: "String", defaultValue: "" }
  ) {
    id
    name
    implementationAmendmentRequests: granteeTerminationCTMSGrantAmendmentRequests(
      statusIn: [IMPLEMENTATION]
    ) {
      id
    }
    searchedImplementationAmendmentRequests: granteeTerminationCTMSGrantAmendmentRequests(
      statusIn: [IMPLEMENTATION]
      search: $grantAmendmentRequestsSearch
    ) {
      ...GranteeTerminationAmendmentRequestsTable_GranteeTerminationCTMSGrantAmendmentRequest
    }
    ...LegalWorkflowLayout_Organization
    ...GranteeTerminationAmendmentRequestsTable_Organization
  }
`;

const ACCOUNT_FRAGMENT = graphql`
  fragment Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Account on Account {
    isSuperAdmin
  }
`;

const MARK_AMENDMENT_REQUEST_AS_IMPLEMENTED_MUTATION = graphql`
  mutation Implementation_GranteeTerminationCTMSGrantAmendmentRequests_MarkGrantAmendmentRequestsAsImplemented_Mutation(
    $granteeTerminationCTMSGrantAmendmentRequestIds: [UUID!]!
    $organizationId: OrganizationId!
  ) {
    markGranteeTerminationCTMSGrantAmendmentRequestsAsImplemented(
      granteeTerminationCTMSGrantAmendmentRequestIds: $granteeTerminationCTMSGrantAmendmentRequestIds
      organizationId: $organizationId
    ) {
      __typename
    }
  }
`;

const AdminAmendmentRequestsImplementationPage_: React.FC<{
  organizationFragment: Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Organization$key;
  viewerFragment: Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Account$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const [organization, refetchOrganization] = useRefetchableFragment<
    Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Organization_RefetchQuery,
    Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Organization$key
  >(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(ACCOUNT_FRAGMENT, viewerFragment);
  const [amendmentRequestSelection, setAmendmentRequestSelection] = useState<
    Set<string>
  >(new Set());
  const selectedAmendmentRequests = useMemo(
    () =>
      organization.implementationAmendmentRequests.filter((grant) =>
        amendmentRequestSelection.has(grant.id),
      ),
    [amendmentRequestSelection, organization.implementationAmendmentRequests],
  );
  const [
    markGrantAmendmentRequestsAsImplemented,
    markGrantAmendmentRequestsAsImplementedMutationIsInFlight,
  ] =
    useSafeMutation<Implementation_GranteeTerminationCTMSGrantAmendmentRequests_MarkGrantAmendmentRequestsAsImplemented_Mutation>(
      MARK_AMENDMENT_REQUEST_AS_IMPLEMENTED_MUTATION,
    );

  const toaster = useToaster();

  const [searchTransitionIsInProgress, startSearchTransition] = useTransition();

  const applicationName = useApplicationName();

  const {
    isDebouncing: searchIsDebouncing,
    liveState: searchInputValue,
    setState: setSearchInputValue,
  } = useDebounced<string>({
    delay: 1000,
    initialState: "",
    onDebounce: useCallback(
      (search: string) => {
        startSearchTransition(() => {
          refetchOrganization({
            grantAmendmentRequestsSearch: search,
          });
        });
      },
      [refetchOrganization],
    ),
  });

  const handleReviewAmendmentRequestsButtonClick = useCallback(async () => {
    const granteeTerminationCTMSGrantAmendmentRequestIds =
      selectedAmendmentRequests.map((amendmentRequest) => amendmentRequest.id);

    await markGrantAmendmentRequestsAsImplemented({
      variables: {
        granteeTerminationCTMSGrantAmendmentRequestIds,
        organizationId: organization.id,
      },
    });

    setAmendmentRequestSelection(new Set());

    toaster.push(
      <Toast title="Wonderful!">
        <FormattedMessage
          defaultMessage={`{count, plural,
            one {# amendment request}
            other {# amendment requests}
          } successfully marked as implemented!`}
          values={{
            count: granteeTerminationCTMSGrantAmendmentRequestIds.length,
          }}
        />
      </Toast>,
    );

    startTransition(() => {
      refetchOrganization({
        grantAmendmentRequestsSearch: searchInputValue,
      });
    });
  }, [
    selectedAmendmentRequests,
    markGrantAmendmentRequestsAsImplemented,
    searchInputValue,
    toaster,
    refetchOrganization,
    organization.id,
  ]);

  return (
    <LegalWorkflowLayout
      onSearchChange={setSearchInputValue}
      organizationFragment={organization}
      searchIsLoading={searchIsDebouncing || searchTransitionIsInProgress}
      searchPlaceholder="Search amendments..."
      searchValue={searchInputValue}
      subtitle={`Grant amendments the ${applicationName} team is currently implementing in your Cap Table Management Solution`}
      title="Pending implementation"
      topBarActionsRender={({ mainContentIsScrolled }) => {
        if (!viewer.isSuperAdmin) return null;

        return (
          <SelectedItemsActionCard
            actions={
              <Button
                disabled={isEmpty(selectedAmendmentRequests)}
                loading={
                  markGrantAmendmentRequestsAsImplementedMutationIsInFlight
                }
                onClick={handleReviewAmendmentRequestsButtonClick}
                size="small"
              >
                Proceed
              </Button>
            }
            compact={mainContentIsScrolled}
            emptyListLabel="Select amendments"
            itemCount={selectedAmendmentRequests.length}
            loading={markGrantAmendmentRequestsAsImplementedMutationIsInFlight}
            pluralLabel="amendments selected"
            singularLabel="amendment selected"
          />
        );
      }}
    >
      {isEmpty(organization.implementationAmendmentRequests) ? (
        <EmptyListPlaceholder
          hideImage
          subtitle={`Grant amendments the ${applicationName} team is currently implementing in your Cap Table Management Solution`}
          title="Pending implementation"
        />
      ) : (
        <GranteeTerminationAmendmentRequestsTable
          amendmentRequestFragment={
            organization.searchedImplementationAmendmentRequests
          }
          disableRowSelection={!viewer.isSuperAdmin}
          emptyListPlaceholder="No amendments found"
          grantSelection={amendmentRequestSelection}
          onGrantSelectionChange={setAmendmentRequestSelection}
          organizationFragment={organization}
        />
      )}
    </LegalWorkflowLayout>
  );
};

const QUERY = graphql`
  query Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Query(
    $organizationId: OrganizationId!
  ) {
    organization(id: $organizationId) {
      id
      name
      ...Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Organization
    }
    me {
      ...Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Account
    }
  }
`;

const AdminAmendmentRequestsImplementationPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query } =
    useQuery<Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Query>(
      QUERY,
      {
        organizationId,
      },
    );

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Amendment Requests"
      analyticsName="Admin - Implementation Amendment Requests"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} implementation amendment requests`}
    >
      <AdminAmendmentRequestsImplementationPage_
        organizationFragment={query.organization}
        viewerFragment={query.me}
      />
    </Page>
  );
};

export default AdminAmendmentRequestsImplementationPage;
