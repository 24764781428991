import { BoxedIcon, Text } from "@remote-com/norma";
import { IconV2DuotoneAlertCircle } from "@remote-com/norma/icons/IconV2DuotoneAlertCircle";

import { RoundedBox } from "./ui/RoundedBox";

export const EmptyState: React.FC<{
  callToAction?: null | React.ReactNode;
  children: React.ReactNode;
}> = ({ callToAction, children }) => {
  return (
    <RoundedBox
      background="subtle"
      className="justify-items-center space-y-2 p-6 text-center"
      withBorder
    >
      <BoxedIcon Icon={IconV2DuotoneAlertCircle} size="sm" tone="brand" />
      <Text
        className="text-grey-600 [&_a]:font-medium [&_a]:text-brand-600"
        variant="sm"
      >
        {children}
      </Text>
      {callToAction && (
        <Text className="text-brand-600" variant="smMedium">
          {callToAction}
        </Text>
      )}
    </RoundedBox>
  );
};
