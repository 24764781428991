/**
 * @generated SignedSource<<2d9c9458f27adcd4999143d5a913c869>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CannotBeInvitedReason = "ALREADY_INVITED" | "MISSING_INFORMATION" | "NO_GRANTS" | "REMOTE_EMPLOYEE" | "SETTLED";
export type HRISProvider = "DEEL" | "REMOTE";
import { FragmentRefs } from "relay-runtime";
export type EmployeeDetailsTopBarActions_Employee$data = {
  readonly cannotBeInvitedReason: CannotBeInvitedReason | null;
  readonly ctmsGrants: ReadonlyArray<{
    readonly exercisePrice: number | null;
    readonly id: string;
  }>;
  readonly hRISProviderEmployee: {
    readonly hRISProvider: HRISProvider;
  } | null;
  readonly hasBeenInvitedMoreThanAWeekAgoButDidNotVisitTheirPortal: boolean;
  readonly hasPortalAccess: boolean;
  readonly id: string;
  readonly isDeletable: boolean;
  readonly isTerminable: boolean;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeFormSlide_Grantee" | "RevokeGranteeAccessModal_Grantee">;
  readonly " $fragmentType": "EmployeeDetailsTopBarActions_Employee";
};
export type EmployeeDetailsTopBarActions_Employee$key = {
  readonly " $data"?: EmployeeDetailsTopBarActions_Employee$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeeDetailsTopBarActions_Employee">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmployeeDetailsTopBarActions_Employee",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HRISProviderEmployee",
      "kind": "LinkedField",
      "name": "hRISProviderEmployee",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hRISProvider",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cannotBeInvitedReason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasBeenInvitedMoreThanAWeekAgoButDidNotVisitTheirPortal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPortalAccess",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTerminable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDeletable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "ctmsGrants",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "exercisePrice",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RevokeGranteeAccessModal_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeFormSlide_Grantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};
})();

(node as any).hash = "6418970c51b512009dcc8314f40aad07";

export default node;
