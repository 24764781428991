/**
 * @generated SignedSource<<61961af0f49d83ed4fa440336556adb8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type EmployeeDetails_Grantee$data = {
  readonly ctmsGrants: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"EquityDetailsSection_CTMSGrants" | "VestingScheduleSection_CTMSGrants">;
  }>;
  readonly draftGrants: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"EquityDetailsSection_DraftGrants" | "VestingScheduleSection_DraftGrants">;
  }>;
  readonly id: string;
  readonly name: string;
  readonly taxResidenceCountry: {
    readonly emoji: string;
  } | null;
  readonly workRelationship: WorkRelationship | null;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeeDetailsSection_Employee" | "EmployeeDetailsTopBarActions_Employee" | "EquityDetailsSection_Employee" | "OwnershipSection_Employee" | "PortalSection_Employee">;
  readonly " $fragmentType": "EmployeeDetails_Grantee";
};
export type EmployeeDetails_Grantee$key = {
  readonly " $data"?: EmployeeDetails_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeeDetails_Grantee">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmployeeDetails_Grantee",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workRelationship",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "ctmsGrants",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EquityDetailsSection_CTMSGrants"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "VestingScheduleSection_CTMSGrants"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "taxResidenceCountry",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emoji",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "draftGrants",
      "args": null,
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrantsWithoutCtmsGrant",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EquityDetailsSection_DraftGrants"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "VestingScheduleSection_DraftGrants"
        }
      ],
      "storageKey": null
    },
    {
      "args": (v1/*: any*/),
      "kind": "FragmentSpread",
      "name": "OwnershipSection_Employee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PortalSection_Employee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmployeeDetailsSection_Employee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmployeeDetailsTopBarActions_Employee"
    },
    {
      "args": (v1/*: any*/),
      "kind": "FragmentSpread",
      "name": "EquityDetailsSection_Employee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};
})();

(node as any).hash = "51d3f84f2cefee33a5e209c48048f8be";

export default node;
