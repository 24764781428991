/**
 * @generated SignedSource<<46898cf9a37af61888f091c30af85272>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FormattedCurrencyParts_Organization$data = {
  readonly defaultCurrencyCode: string;
  readonly " $fragmentType": "FormattedCurrencyParts_Organization";
};
export type FormattedCurrencyParts_Organization$key = {
  readonly " $data"?: FormattedCurrencyParts_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"FormattedCurrencyParts_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FormattedCurrencyParts_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultCurrencyCode",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "49da0868dc97320a3c8db41468cbf6c1";

export default node;
