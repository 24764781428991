/**
 * @generated SignedSource<<be5cf3f2594fe145abc9d8e8c05bc18c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type IncentiveSettings_EquityOffer$data = {
  readonly benefits: ReadonlyArray<string>;
  readonly displayBenefits: boolean;
  readonly displayEquityAsDollarValue: boolean;
  readonly displayEquityAsPercentage: boolean;
  readonly displaySalary: boolean;
  readonly displayTaxationExplanation: boolean;
  readonly id: string;
  readonly instrument: {
    readonly id: string;
  } | null;
  readonly organization: {
    readonly fullyDilutedShares: number | null;
    readonly id: string;
    readonly latestPricePerShare: {
      readonly value: number;
    } | null;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"CurrencySymbol_Organization" | "GrantedSharesInput_Organization">;
  };
  readonly salary: number | null;
  readonly salaryCurrencyCode: string;
  readonly shares: number | null;
  readonly taxResidenceCountry: {
    readonly code: string;
  } | null;
  readonly workRelationship: WorkRelationship | null;
  readonly " $fragmentSpreads": FragmentRefs<"EquityOfferPreviewLayout_EquityOffer" | "Incentive_EquityOffer">;
  readonly " $fragmentType": "IncentiveSettings_EquityOffer";
};
export type IncentiveSettings_EquityOffer$key = {
  readonly " $data"?: IncentiveSettings_EquityOffer$data;
  readonly " $fragmentSpreads": FragmentRefs<"IncentiveSettings_EquityOffer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "IncentiveSettings_EquityOffer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayEquityAsDollarValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayEquityAsPercentage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displaySalary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salaryCurrencyCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayBenefits",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "benefits",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "instrument",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "taxResidenceCountry",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workRelationship",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullyDilutedShares",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PricePerShare",
          "kind": "LinkedField",
          "name": "latestPricePerShare",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CurrencySymbol_Organization"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GrantedSharesInput_Organization"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayTaxationExplanation",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Incentive_EquityOffer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquityOfferPreviewLayout_EquityOffer"
    }
  ],
  "type": "EquityOffer",
  "abstractKey": null
};
})();

(node as any).hash = "ce09fb1a2a3e85691626ea497f9d9b02";

export default node;
