import { Pill } from "@remote-com/norma";
import { graphql, useFragment } from "react-relay";

import { CtmsGrantStatusTag_CtmsGrant$key } from "./__generated__/CtmsGrantStatusTag_CtmsGrant.graphql";
import { CtmsGrantVestingTag } from "./CtmsGrantVestingTag";

const GRANT_FRAGMENT = graphql`
  fragment CtmsGrantStatusTag_CtmsGrant on CTMSGrant {
    grantStatus
    isActive
    fullyVested
    settled
    ...CtmsGrantVestingTag_CtmsGrant
  }
`;

export const getCtmsGrantStatusLabel = (ctmsGrant: {
  fullyVested: boolean;
  isActive: boolean;
  settled: boolean;
}) => {
  if (!ctmsGrant.isActive) {
    return ctmsGrant.settled ? "Settled" : "Settling";
  } else {
    return ctmsGrant.fullyVested ? "Fully vested" : "Vesting";
  }
};

export const CtmsGrantStatusTag: React.FC<{
  ctmsGrantFragment: CtmsGrantStatusTag_CtmsGrant$key;
}> = ({ ctmsGrantFragment }) => {
  const ctmsGrant = useFragment(GRANT_FRAGMENT, ctmsGrantFragment);

  if (ctmsGrant.grantStatus === "Canceled") {
    return <Pill tone="error">Canceled</Pill>;
  }
  if (!ctmsGrant.isActive) {
    return ctmsGrant.settled ? (
      <Pill tone="error">Settled</Pill>
    ) : (
      <Pill tone="warning">Settling</Pill>
    );
  } else {
    return <CtmsGrantVestingTag ctmsGrantFragment={ctmsGrant} />;
  }
};

export const CtmsGrantStatusTagSortFunction = (
  ctmsGrantFragment1: CtmsGrantStatusTag_CtmsGrant$key,
  ctmsGrantFragment2: CtmsGrantStatusTag_CtmsGrant$key,
): number => {
  const ctmsGrant1 = useFragment(GRANT_FRAGMENT, ctmsGrantFragment1);
  const ctmsGrant2 = useFragment(GRANT_FRAGMENT, ctmsGrantFragment2);
  if (ctmsGrant1.isActive !== ctmsGrant2.isActive) {
    return !ctmsGrant1.isActive ? -1 : 1;
  }

  if (!ctmsGrant1.isActive) {
    if (ctmsGrant1.settled !== ctmsGrant2.settled) {
      return ctmsGrant1.settled ? -1 : 1;
    }
    return 0;
  } else {
    if (ctmsGrant1.fullyVested !== ctmsGrant2.fullyVested) {
      return ctmsGrant1.fullyVested ? -1 : 1;
    }
    return 0;
  }
};
