/**
 * @generated SignedSource<<8bc81919c649b5d03db12583a23450ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CTMSGrantVestingStatus = "fully_vested" | "terminated" | "vesting";
import { FragmentRefs } from "relay-runtime";
export type CtmsGrantVestingStatusPill_CTMSGrant$data = {
  readonly vestingStatus: CTMSGrantVestingStatus;
  readonly " $fragmentType": "CtmsGrantVestingStatusPill_CTMSGrant";
};
export type CtmsGrantVestingStatusPill_CTMSGrant$key = {
  readonly " $data"?: CtmsGrantVestingStatusPill_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantVestingStatusPill_CTMSGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CtmsGrantVestingStatusPill_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vestingStatus",
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "ef849e9e22ef235d8d9e3d06c30b1ce7";

export default node;
