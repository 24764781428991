/**
 * @generated SignedSource<<16300b2b1edc3683702dfd1b7cf92956>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmployeeVestingGraph_CTMSGrants$data = ReadonlyArray<{
  readonly __typename: "CTMSGrant";
  readonly exercisePrice: number | null;
  readonly label: string;
  readonly vestingDataPoints: ReadonlyArray<{
    readonly cumulativeVested: number;
    readonly date: string;
  }>;
  readonly " $fragmentType": "EmployeeVestingGraph_CTMSGrants";
}>;
export type EmployeeVestingGraph_CTMSGrants$key = ReadonlyArray<{
  readonly " $data"?: EmployeeVestingGraph_CTMSGrants$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeeVestingGraph_CTMSGrants">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "EmployeeVestingGraph_CTMSGrants",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exercisePrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VestingDataPoint",
      "kind": "LinkedField",
      "name": "vestingDataPoints",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cumulativeVested",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "e9a7186ab0dffee9f148bd8a4c2d989e";

export default node;
