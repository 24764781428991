import { Pill } from "@remote-com/norma";
import { graphql, useFragment } from "react-relay";

import {
  CTMSGrantVestingStatus,
  CtmsGrantVestingStatusPill_CTMSGrant$key,
} from "./__generated__/CtmsGrantVestingStatusPill_CTMSGrant.graphql";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment CtmsGrantVestingStatusPill_CTMSGrant on CTMSGrant {
    vestingStatus
  }
`;

const CTMS_GRANT_VESTING_STATUS_PILL: Record<
  CTMSGrantVestingStatus,
  React.ReactNode
> = {
  fully_vested: <Pill>Fully Vested</Pill>,
  terminated: <Pill tone="error">Terminated</Pill>,
  vesting: <Pill tone="success">Vesting</Pill>,
};

export const CTMSGrantVestingStatusPill: React.FC<{
  ctmsGrantFragment: CtmsGrantVestingStatusPill_CTMSGrant$key;
}> = ({ ctmsGrantFragment }) => {
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);

  return CTMS_GRANT_VESTING_STATUS_PILL[ctmsGrant.vestingStatus];
};
