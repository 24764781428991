/**
 * @generated SignedSource<<56d533471e528e48e77b8c085e09a676>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExerciseRequestsLayout_Query$variables = {
  organizationId: string;
};
export type ExerciseRequestsLayout_Query$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestsLayout_Viewer">;
  };
  readonly organization: {
    readonly id: string;
    readonly shouldShowExerciseHandler: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestsLayout_Organization">;
  } | null;
};
export type ExerciseRequestsLayout_Query = {
  response: ExerciseRequestsLayout_Query$data;
  variables: ExerciseRequestsLayout_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shouldShowExerciseHandler",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExerciseRequestsLayout_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "kind": "FragmentSpread",
            "name": "ExerciseRequestsLayout_Viewer"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ExerciseRequestsLayout_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExerciseRequestsLayout_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "kind": "ScalarField",
            "name": "isAllowedToManageOrganization",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isRemoteEquityEssentials",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToCarta",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ef194597c22d2e88e0ef8449008e9459",
    "id": null,
    "metadata": {},
    "name": "ExerciseRequestsLayout_Query",
    "operationKind": "query",
    "text": "query ExerciseRequestsLayout_Query(\n  $organizationId: OrganizationId!\n) {\n  me {\n    ...ExerciseRequestsLayout_Viewer_4xMPKw\n    id\n  }\n  organization(id: $organizationId) {\n    id\n    shouldShowExerciseHandler\n    ...ExerciseRequestsLayout_Organization\n  }\n}\n\nfragment ExerciseRequestsLayout_Organization on Organization {\n  id\n  isRemoteEquityEssentials\n  ...LargeOneColumnLayout_Organization\n}\n\nfragment ExerciseRequestsLayout_Viewer_4xMPKw on Account {\n  isAllowedToManageOrganization(organizationId: $organizationId)\n}\n\nfragment Footer_Organization on Organization {\n  isConnectedToCarta\n}\n\nfragment LargeOneColumnLayout_Organization on Organization {\n  ...Footer_Organization\n}\n"
  }
};
})();

(node as any).hash = "85e9734325a8252207a0e580135bcc39";

export default node;
