/**
 * @generated SignedSource<<0a4ace89bb123cf793a7c72ca88f6702>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SafeType = "POST_MONEY" | "POST_MONEY_MFN" | "PRE_MONEY";
export type SafeInput = {
  lastIssuanceDate: string;
  mfnDate?: string | null;
  totalAmountInvested: number;
  type: SafeType;
  valuationCap: number;
};
export type OrganizationSettingsSafeCalculatorVersionContext_AddSafeCalculatorVersionSafe_Mutation$variables = {
  input: SafeInput;
  safeCalculatorVersionId: string;
};
export type OrganizationSettingsSafeCalculatorVersionContext_AddSafeCalculatorVersionSafe_Mutation$data = {
  readonly addSafeCalculatorVersionSafe: {
    readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion">;
  };
};
export type OrganizationSettingsSafeCalculatorVersionContext_AddSafeCalculatorVersionSafe_Mutation = {
  response: OrganizationSettingsSafeCalculatorVersionContext_AddSafeCalculatorVersionSafe_Mutation$data;
  variables: OrganizationSettingsSafeCalculatorVersionContext_AddSafeCalculatorVersionSafe_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "safeCalculatorVersionId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  },
  {
    "kind": "Variable",
    "name": "safeCalculatorVersionId",
    "variableName": "safeCalculatorVersionId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "valuationCapInUSD",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalAmountInvestedInUSD",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastIssuanceDate",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "useAsValuation",
  "storageKey": null
},
v8 = [
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationSettingsSafeCalculatorVersionContext_AddSafeCalculatorVersionSafe_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "SafeCalculatorVersion",
        "kind": "LinkedField",
        "name": "addSafeCalculatorVersionSafe",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "OrganizationSettingsSafeCalculatorVersionContext_AddSafeCalculatorVersionSafe_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "SafeCalculatorVersion",
        "kind": "LinkedField",
        "name": "addSafeCalculatorVersionSafe",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "preConversionFDS",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "optionPoolSize",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "safes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": (v8/*: any*/),
                "type": "SafePostMoney",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v8/*: any*/),
                "type": "SafePreMoney",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mfnDate",
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "type": "SafePostMoneyMFN",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SafeCalculatorSimulatedGrant",
            "kind": "LinkedField",
            "name": "simulatedGrants",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "grantedSharesInputMode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shares",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c42bd7ca1ab390ee4a26b7e1c6d890d4",
    "id": null,
    "metadata": {},
    "name": "OrganizationSettingsSafeCalculatorVersionContext_AddSafeCalculatorVersionSafe_Mutation",
    "operationKind": "mutation",
    "text": "mutation OrganizationSettingsSafeCalculatorVersionContext_AddSafeCalculatorVersionSafe_Mutation(\n  $safeCalculatorVersionId: ID!\n  $input: SafeInput!\n) {\n  addSafeCalculatorVersionSafe(input: $input, safeCalculatorVersionId: $safeCalculatorVersionId) {\n    ...OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion\n    id\n  }\n}\n\nfragment OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion on SafeCalculatorVersion {\n  id\n  preConversionFDS\n  optionPoolSize\n  safes {\n    __typename\n    ... on SafePostMoney {\n      id\n      valuationCapInUSD\n      totalAmountInvestedInUSD\n      lastIssuanceDate\n      useAsValuation\n    }\n    ... on SafePreMoney {\n      id\n      valuationCapInUSD\n      totalAmountInvestedInUSD\n      lastIssuanceDate\n      useAsValuation\n    }\n    ... on SafePostMoneyMFN {\n      id\n      valuationCapInUSD\n      totalAmountInvestedInUSD\n      lastIssuanceDate\n      mfnDate\n      useAsValuation\n    }\n    id\n  }\n  simulatedGrants {\n    id\n    type\n    grantedSharesInputMode\n    shares\n  }\n}\n"
  }
};
})();

(node as any).hash = "3656b78ad902bba66e1e77e112fcc075";

export default node;
