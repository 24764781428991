import { PlusIcon } from "@heroicons/react/24/outline";
import { Button } from "@remote-com/norma";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { DraftGrantButton_DefaultGrantee$key } from "./__generated__/DraftGrantButton_DefaultGrantee.graphql";
import { DraftGrantButton_Organization$key } from "./__generated__/DraftGrantButton_Organization.graphql";
import {
  NewEquitySelectionModal,
  useNewEquitySelectionModalState,
} from "./NewEquitySelectionModal/NewEquitySelectionModal";

const ORGANIZATION_FRAGMENT = graphql`
  fragment DraftGrantButton_Organization on Organization {
    id
  }
`;

const DEFAULT_GRANTEE_FRAGMENT = graphql`
  fragment DraftGrantButton_DefaultGrantee on Grantee
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    ...NewEquitySelectionModal_DefaultGrantee
      @arguments(organizationId: $organizationId)
  }
`;

export const DraftGrantButton: React.FC<
  Pick<
    React.ComponentProps<typeof Button>,
    "IconBefore" | "size" | "variant"
  > & {
    buttonRender?: ({
      showNewEquitySelectionModal,
    }: {
      showNewEquitySelectionModal: () => void;
    }) => React.ReactNode;
    children?: React.ReactNode;
    className?: string;
    defaultGranteeFragment?: DraftGrantButton_DefaultGrantee$key;
    fullWidth?: boolean;
    onGrantCreated?: () => void;
    organizationFragment: DraftGrantButton_Organization$key;
  }
> = ({
  buttonRender,
  children,
  className,
  defaultGranteeFragment = null,
  IconBefore = PlusIcon,
  onGrantCreated,
  organizationFragment,
  size = "sm",
  variant,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const defaultGrantee =
    useFragment(DEFAULT_GRANTEE_FRAGMENT, defaultGranteeFragment) ?? null;

  const {
    hideNewEquitySelectionModal,
    newEquitySelectionModalState,
    showNewEquitySelectionModal,
  } = useNewEquitySelectionModalState();

  return (
    <>
      <NewEquitySelectionModal
        onClose={hideNewEquitySelectionModal}
        onGrantCreated={onGrantCreated}
        organizationId={organization.id}
        state={newEquitySelectionModalState}
      />

      {!buttonRender && (
        <Button
          className={className}
          IconBefore={IconBefore}
          onClick={() => {
            showNewEquitySelectionModal({ granteeFragment: defaultGrantee });
          }}
          size={size}
          type="button"
          variant={variant}
        >
          {children || "Draft new grant"}
        </Button>
      )}

      {buttonRender?.({
        showNewEquitySelectionModal: () => {
          showNewEquitySelectionModal({ granteeFragment: defaultGrantee });
        },
      })}
    </>
  );
};
