/**
 * @generated SignedSource<<52c220786326bd05cc7fc0d37c05a76a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type EquityHandbooksCountryPage_Query$variables = {
  countryCode: string;
  organizationId: string;
};
export type EquityHandbooksCountryPage_Query$data = {
  readonly organizationGeography: {
    readonly geography: {
      readonly equityTypes: ReadonlyArray<{
        readonly id: string;
      }>;
    };
  } | null;
};
export type EquityHandbooksCountryPage_Query = {
  response: EquityHandbooksCountryPage_Query$data;
  variables: EquityHandbooksCountryPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "countryCode"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "countryCode",
    "variableName": "countryCode"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Geography",
  "kind": "LinkedField",
  "name": "geography",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityType",
      "kind": "LinkedField",
      "name": "equityTypes",
      "plural": true,
      "selections": [
        (v3/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EquityHandbooksCountryPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "OrganizationGeography",
        "kind": "LinkedField",
        "name": "organizationGeography",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EquityHandbooksCountryPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "OrganizationGeography",
        "kind": "LinkedField",
        "name": "organizationGeography",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bf517c6bbea31a4138a19adafecaa147",
    "id": null,
    "metadata": {},
    "name": "EquityHandbooksCountryPage_Query",
    "operationKind": "query",
    "text": "query EquityHandbooksCountryPage_Query(\n  $organizationId: OrganizationId!\n  $countryCode: String!\n) {\n  organizationGeography(countryCode: $countryCode, organizationId: $organizationId) {\n    geography {\n      equityTypes {\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a906c9fde322e39e9f1e7f29600d06e4";

export default node;
