import React from "react";

export function BentoCard({ children }: { children: React.ReactNode }) {
  return (
    <div className="divide-y-[0.5px] divide-grey-300 overflow-hidden rounded-2xl border-[0.5px] border-grey-300 bg-grey-300">
      {children}
    </div>
  );
}

export function BentoCardCell({
  children,
  title,
}: {
  children: React.ReactNode;
  title?: React.ReactNode;
}) {
  return (
    <div className="flex-1 space-y-2 bg-white p-6">
      {title && (
        <div className="text-XS/SemiBold uppercase text-grey-600">{title}</div>
      )}
      <div>{children}</div>
    </div>
  );
}

export function BentoCardColumn({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex flex-1 flex-col items-stretch gap-[0.5px] bg-grey-300">
      {children}
    </div>
  );
}

export function BentoCardRow({ children }: { children: React.ReactNode }) {
  return <div className="flex w-full gap-[0.5px] bg-grey-300">{children}</div>;
}

export function BentoCardValue({
  main,
  sub,
}: {
  main: React.ReactNode;
  sub?: React.ReactNode;
}) {
  return (
    <div className="flex items-center gap-1">
      <div className="font-brand text-XL/Medium">{main}</div>
      {sub && <div className="text-SM text-grey-600">{sub}</div>}
    </div>
  );
}
