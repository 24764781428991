/**
 * @generated SignedSource<<d0e4240055b224d9c5200ba971281040>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useGrantUnit_CTMSGrant$data = {
  readonly isVirtual: boolean;
  readonly matchingInstrument: {
    readonly equityType: {
      readonly exercisable: boolean;
    } | null;
  } | null;
  readonly " $fragmentType": "useGrantUnit_CTMSGrant";
};
export type useGrantUnit_CTMSGrant$key = {
  readonly " $data"?: useGrantUnit_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"useGrantUnit_CTMSGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useGrantUnit_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "matchingInstrument",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EquityType",
          "kind": "LinkedField",
          "name": "equityType",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "exercisable",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVirtual",
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "3d190826a1dd12c0156da083d469bc98";

export default node;
