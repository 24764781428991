import classNames from "classnames";
import { PropsWithChildren } from "react";

export function EmptyStatePlaceholder({
  children,
  textCenter = false,
}: PropsWithChildren<{
  textCenter?: boolean;
}>) {
  return (
    <div
      className={classNames(
        "rounded-remote-lg bg-grey-100 p-4",
        "[&_a]:font-medium [&_a]:text-primary-600 [&_button]:font-medium [&_button]:text-primary-600",
        {
          "text-center": textCenter,
        },
      )}
    >
      {children}
    </div>
  );
}
