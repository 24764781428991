import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import saveAs from "file-saver";
import { useMemo } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useTrackDocumentsDownloaded } from "../hooks/useAnalytics";
import { useGrantDocuments } from "../pages/Admin/Documents/useGrantDocuments";
import { DocumentsTable_CTMSGrant$key } from "./__generated__/DocumentsTable_CTMSGrant.graphql";
import { ShortDate } from "./ShortDate";
import { Button } from "./ui/Button";
import { Table } from "./ui/Table";
import { Tag } from "./ui/Tag";
import { Typography } from "./ui/Typography";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment DocumentsTable_CTMSGrant on CTMSGrant {
    easopGrant {
      ...useGrantDocuments_EasopGrant
    }
    documents {
      downloadUrl
      updatedAt
      originalFilename
    }
  }
`;

const columnHelper =
  createColumnHelper<ReturnType<typeof useGrantDocuments>[number]>();

export const DocumentsTable = ({
  ctmsGrantFragment,
}: {
  ctmsGrantFragment: DocumentsTable_CTMSGrant$key;
}) => {
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);

  const grantDocuments = useGrantDocuments({
    easopGrantFragment: ctmsGrant.easopGrant,
  });

  const data = useMemo(
    () => [
      ...grantDocuments,
      ...ctmsGrant.documents.map((document) => ({
        downloadUrl: document.downloadUrl,
        fileName: document.originalFilename ?? "unknown",
        label: document.originalFilename ?? "Missing document info",
        type: "GRANT_DOCUMENT" as const,
        updatedAt: document.updatedAt,
      })),
    ],
    [grantDocuments, ctmsGrant.documents],
  );

  const trackDocumentsDownloaded = useTrackDocumentsDownloaded();

  const columns = [
    columnHelper.accessor((row) => row.label, {
      cell: (context) => (
        <Typography className="text-primary" variant="Medium/Extra Small">
          {context.getValue()}
        </Typography>
      ),
      header: () => "Document name",
      id: "name",
    }),
    columnHelper.accessor((row) => row.updatedAt, {
      cell: (context) => {
        const updatedAt = context.getValue();

        return updatedAt ? (
          <Typography variant="Regular/Extra Small">
            <ShortDate value={updatedAt} />
          </Typography>
        ) : (
          <Tag color="gray">Missing info</Tag>
        );
      },
      header: () => "Upload date",
      id: "uploaded_at",
    }),
    columnHelper.accessor((row) => row.downloadUrl, {
      cell: (context) => {
        const downloadUrl = context.getValue();
        if (!downloadUrl) return null;

        const document = context.row.original;

        return (
          <Button
            disabled={!downloadUrl}
            onClick={() => {
              trackDocumentsDownloaded({
                documentsTypes: [document.type],
                downloadType: "UNIQUE",
              });
              saveAs(downloadUrl, context.row.original.fileName);
            }}
            size="small"
            variant="Primary Outline"
          >
            Download
          </Button>
        );
      },
      header: () => "Action",
      id: "action",
    }),
  ];

  const table = useReactTable({
    columns,
    data,
    enableSorting: false,
    getCoreRowModel: getCoreRowModel(),
  });

  return <Table.Smart table={table} />;
};
