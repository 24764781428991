import { Avatar } from "@remote-com/norma";
import Fuse from "fuse.js";
import { isEmpty } from "lodash";
import React, { useMemo, useState } from "react";
import { useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { EmptyState } from "../../../../components/EmptyState";
import { Page } from "../../../../components/Page";
import { ShortDate } from "../../../../components/ShortDate";
import { LargeCenteredDetailsLayout } from "../../../../components/ui/Layout/LargeCenteredDetailsLayout";
import { SearchBar } from "../../../../components/ui/SearchBar";
import { Table } from "../../../../components/ui/Table";
import { TabNavigation } from "../../../../components/ui/TabNavigation";
import { useQuery } from "../../../../hooks/useQuery";
import { APPLICATION_ROUTES } from "../../../../paths";
import {
  GeographiesPage_SuperAdminEquityTypeHandbooks_Geography$data,
  GeographiesPage_SuperAdminEquityTypeHandbooks_Geography$key,
} from "./__generated__/GeographiesPage_SuperAdminEquityTypeHandbooks_Geography.graphql";
import { GeographiesPage_SuperAdminEquityTypeHandbooks_Query } from "./__generated__/GeographiesPage_SuperAdminEquityTypeHandbooks_Query.graphql";

const GEOGRAPHIES_FRAGMENT = graphql`
  fragment GeographiesPage_SuperAdminEquityTypeHandbooks_Geography on Geography
  @relay(plural: true) {
    country {
      code
      name
      displayName
    }
    mostRecentEquityTypeHandbookPublicationDate
  }
`;

type Geography =
  GeographiesPage_SuperAdminEquityTypeHandbooks_Geography$data[number];

const GeographiesTable: React.FC<{
  geographies: Geography[];
}> = ({ geographies }) => {
  if (isEmpty(geographies)) {
    return <EmptyState>No geographies to display.</EmptyState>;
  }

  return (
    <Table className="grid grid-cols-[auto_1fr_auto]" display="grid">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell>Country</Table.HeaderCell>
          <Table.HeaderCell alignRight>Last published</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {geographies.map((geography) => {
          return (
            <Table.LinkRow
              key={geography.country.code}
              to={generatePath(
                APPLICATION_ROUTES.superAdminEquityHandbooksGeography,
                {
                  countryCode: geography.country.code,
                },
              )}
            >
              <Table.Cell>
                <Avatar name={geography.country.name} size="xs" />
              </Table.Cell>
              <Table.Cell>{geography.country.displayName}</Table.Cell>
              <Table.Cell className="justify-end text-right">
                {geography.mostRecentEquityTypeHandbookPublicationDate ? (
                  <ShortDate
                    value={
                      geography.mostRecentEquityTypeHandbookPublicationDate
                    }
                  />
                ) : (
                  "-"
                )}
              </Table.Cell>
            </Table.LinkRow>
          );
        })}
      </Table.Body>
    </Table>
  );
};

type GeographiesFilter = "PUBLISHED" | "UNPUBLISHED";

const SuperAdminEquityTypeHandbooksGeographiesPage_: React.FC<{
  geographiesFilter: GeographiesFilter;
  geographiesFragment: GeographiesPage_SuperAdminEquityTypeHandbooks_Geography$key;
}> = ({ geographiesFilter, geographiesFragment }) => {
  const _geographies = useFragment(GEOGRAPHIES_FRAGMENT, geographiesFragment);

  const geographies = useMemo(() => {
    return _geographies.filter((geography) => {
      switch (geographiesFilter) {
        case "PUBLISHED":
          return !!geography.mostRecentEquityTypeHandbookPublicationDate;
        case "UNPUBLISHED":
          return !geography.mostRecentEquityTypeHandbookPublicationDate;
      }
    });
  }, [_geographies, geographiesFilter]);

  const [fullTextFilter, setFullTextFilter] = useState<string>("");

  const filteredGeographies = useMemo(() => {
    const fuse = new Fuse(geographies, {
      keys: ["country.name"],
    });

    if (isEmpty(fullTextFilter)) {
      return geographies;
    }

    return fuse.search(fullTextFilter).map((result) => result.item);
  }, [geographies, fullTextFilter]);

  return (
    <LargeCenteredDetailsLayout
      footerHelpCard={null}
      navigationTitle="Equity handbooks"
      organizationFragment={null}
      parents={[
        {
          link: generatePath(APPLICATION_ROUTES.superAdminLegalContent),
          title: "Legal content",
        },
      ]}
      title="Equity handbooks"
    >
      <div className="space-y-6">
        <TabNavigation layoutId="equity-type-handbooks-geographies-tab-navigation">
          <TabNavigation.Link
            to={generatePath(
              APPLICATION_ROUTES.superAdminEquityHandbooksPublishedGeographies,
            )}
          >
            Published
          </TabNavigation.Link>
          <TabNavigation.Link
            to={generatePath(
              APPLICATION_ROUTES.superAdminEquityHandbooksUnpublishedGeographies,
            )}
          >
            Unpublished
          </TabNavigation.Link>
        </TabNavigation>
        <SearchBar
          className="max-w-[260px]"
          onChange={setFullTextFilter}
          placeholder="Search countries..."
          value={fullTextFilter}
        />

        <GeographiesTable geographies={filteredGeographies} />
      </div>
    </LargeCenteredDetailsLayout>
  );
};

const QUERY = graphql`
  query GeographiesPage_SuperAdminEquityTypeHandbooks_Query {
    geographies(filters: { orderBy: { direction: ASC, field: name } }) {
      ...GeographiesPage_SuperAdminEquityTypeHandbooks_Geography
    }
  }
`;

const SuperAdminEquityTypeHandbooksGeographiesPage: React.FC<{
  geographiesFilter: GeographiesFilter;
}> = ({ geographiesFilter }) => {
  const {
    query: { geographies },
  } = useQuery<GeographiesPage_SuperAdminEquityTypeHandbooks_Query>(QUERY, {});
  return (
    <Page
      analyticsName="Super Admin - Equity type handbooks"
      title={`Super admin | Equity type handbooks`}
    >
      <SuperAdminEquityTypeHandbooksGeographiesPage_
        geographiesFilter={geographiesFilter}
        geographiesFragment={geographies}
      />
    </Page>
  );
};

export default SuperAdminEquityTypeHandbooksGeographiesPage;
