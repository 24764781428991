import { Text } from "@remote-com/norma";
import { IconV2OutlineAi } from "@remote-com/norma/icons/IconV2OutlineAi";

import { RoundedBox } from "../../../../components/ui/RoundedBox";

export const RemindersCard: React.FC = () => {
  return (
    <RoundedBox className="space-y-2 p-6" withBorder>
      <Text variant="lgMedium">Reminders</Text>
      <div className="flex flex-col items-center gap-2 rounded-2xl bg-grey-50 p-6">
        <IconV2OutlineAi className="h-6 w-6 text-grey-400" />
        <Text className="text-grey-400" variant="sm">
          Nothing requires your attention
        </Text>
      </div>
    </RoundedBox>
  );
};
