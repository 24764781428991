import {
  Drawer,
  DrawerHeader,
  DrawerLayout,
  DrawerPane,
  DrawerTitle,
  ListDisc,
  Text,
} from "@remote-com/norma";

export const KeyEquityPlanParameterExplanationDrawer: React.FC<{
  children: React.ReactElement;
}> = ({ children }) => {
  return (
    <Drawer
      $layout="fullWidth"
      aria-labelledby="fair-market-value-explanation-drawer-title"
      Trigger={children}
    >
      <DrawerLayout>
        <DrawerHeader>
          <DrawerTitle id="key-equity-plan-parameter-explanation-drawer-title">
            Key equity plan parameters
          </DrawerTitle>
        </DrawerHeader>
        <DrawerPane>
          <div className="space-y-10">
            <div className="space-y-5">
              <Text className="text-grey-600" variant="sm">
                What we call &quot;price per share&quot; refers to how much one
                company share is worth, typically from the perspective of
                external investors.
                <br />
                <br />
                For private companies, the &quot;price per share&quot; can
                differ from the fair market value (which you saw in the previous
                screen), especially if there&apos;s a difference between
                preferred shares and common shares (which are usually granted to
                employees as part of their equity compensation, and carry less
                rights).
              </Text>
              <Text as="div" className="text-grey-600" variant="2xs">
                Note: Once employee are granted access to their Remote Equity
                portal, price per share and fair market value will be disclosed.
                Make sure your company policy and legal documents allow it or
                disable employee access or hide price per share.
              </Text>
            </div>
            <div className="space-y-3 text-grey-600">
              <Text className="uppercase" variant="xsSemiBold">
                On Remote Equity, price per share and fair market value are both
                used but serve different purposes:
              </Text>
              <ListDisc>
                <li>
                  <Text className="text-grey-900" variant="smMedium">
                    The fair market value is used for tax calculation and
                    reporting purposes.
                  </Text>
                  <ListDisc.Nest>
                    <ListDisc>
                      <li>
                        It is typically lower that the price per share because
                        it takes into account parameters such as the lack of
                        marketability of the common shares. The fair market
                        value will generally be used to determine the amount of
                        taxes to be paid in relation to equity compensation
                        gains that Remote employees could make, for instance
                        when they exercise their stock options, or when their
                        RSUs vest.
                      </li>
                    </ListDisc>
                  </ListDisc.Nest>
                </li>
                <li>
                  <Text className="text-grey-900" variant="smMedium">
                    The price per share aims at giving a less conservative/more
                    optimistic view of the price of a company share, for HR
                    purposes.
                  </Text>
                  <ListDisc.Nest>
                    <ListDisc>
                      <li>
                        It is typically based on the price of the last financing
                        round (if any) or on transactions that have recently
                        taken place between shareholders. Since it is usually
                        higher than the fair market value, it is more motivating
                        for the employees.
                      </li>
                    </ListDisc>
                  </ListDisc.Nest>
                </li>
              </ListDisc>
            </div>
          </div>
        </DrawerPane>
      </DrawerLayout>
    </Drawer>
  );
};
