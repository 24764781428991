/**
 * @generated SignedSource<<d6a7e637a2a2290577929042b2068d70>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CtmsGrantsTableRow_CTMSGrant$data = {
  readonly cumulativeVested: number;
  readonly equityTypeAwardName: string | null;
  readonly exercisableShares: number;
  readonly exercisePrice: number | null;
  readonly grantee: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantsTableRowGranteeCell_Grantee">;
  };
  readonly id: string;
  readonly isMissingInformationInOrderToBeModified: boolean;
  readonly label: string;
  readonly quantityExercised: number;
  readonly quantityIssued: number;
  readonly remainingDaysToExercise: number | null;
  readonly settled: boolean;
  readonly stakeholderSignatureDate: string | null;
  readonly terminationDate: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantStatusTag_CtmsGrant">;
  readonly " $fragmentType": "CtmsGrantsTableRow_CTMSGrant";
};
export type CtmsGrantsTableRow_CTMSGrant$key = {
  readonly " $data"?: CtmsGrantsTableRow_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantsTableRow_CTMSGrant">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CtmsGrantsTableRow_CTMSGrant",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "grantee",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CtmsGrantsTableRowGranteeCell_Grantee"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "equityTypeAwardName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantityIssued",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cumulativeVested",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantityExercised",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exercisableShares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "terminationDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remainingDaysToExercise",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exercisePrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stakeholderSignatureDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isMissingInformationInOrderToBeModified",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CtmsGrantStatusTag_CtmsGrant"
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};
})();

(node as any).hash = "25896bea26dff466a6c6788c55822279";

export default node;
