/**
 * @generated SignedSource<<3605d474e2027d149e33905d0c6f95b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Grants_Organization$data = {
  readonly id: string;
  readonly isRemoteEquityEssentials: boolean;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"Grants_Refetchable_Organization" | "OrganizationCtmsGrantsTable_Organization">;
  readonly " $fragmentType": "Grants_Organization";
};
export type Grants_Organization$key = {
  readonly " $data"?: Grants_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"Grants_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filters"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Grants_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRemoteEquityEssentials",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrganizationCtmsGrantsTable_Organization"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "filters",
          "variableName": "filters"
        }
      ],
      "kind": "FragmentSpread",
      "name": "Grants_Refetchable_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "7e4576383aba7e6e702dd038d73a1ad1";

export default node;
