import { Button, Tab, Tabs, Text } from "@remote-com/norma";
import { IconV2OutlineArrowRight } from "@remote-com/norma/icons/IconV2OutlineArrowRight";
import Fuse from "fuse.js";
import { isEmpty } from "lodash";
import React, {
  startTransition,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import {
  graphql,
  useFragment,
  useRefetchableFragment,
  useSubscription,
} from "react-relay";

import { Divider } from "../../../components/ui/Divider";
import { RemoteEquityOnboardingLayout } from "../../../components/ui/Layout/RemoteLikeApplicationLayout/RemoteEquityOnboardingLayout";
import { SearchBar } from "../../../components/ui/SearchBar";
import { useOrganizationCTMS } from "../../../hooks/useOrganizationCTMS";
import { useTrackRemoteEquityOnboardingFlowEvent } from "../../../hooks/useTrackRemoteEquityOnboardingFlowEvent";
import CONFIGURATION from "../../../services/configuration";
import {
  MatchGranteeView_Organization$data,
  MatchGranteeView_Organization$key,
} from "./__generated__/MatchGranteeView_Organization.graphql";
import { MatchGranteeView_Organization_Subscription } from "./__generated__/MatchGranteeView_Organization_Subscription.graphql";
import { MatchGranteeView_Viewer$key } from "./__generated__/MatchGranteeView_Viewer.graphql";
import { MatchGranteeTable } from "./MatchGrantee/MatchGranteeTable";

const ORGANIZATION_FRAGMENT = graphql`
  fragment MatchGranteeView_Organization on Organization
  @refetchable(queryName: "MatchGranteeView_Organization_RefetchQuery") {
    remoteEquityCTMSSyncCompleted
    unmatchedHRISProviderEmployees(hRISProvider: REMOTE) {
      # eslint-disable-next-line relay/unused-fields
      email
      name
      ...MatchGranteeTable_HRISProviderEmployee
    }
    matchedHRISProviderEmployees(hRISProvider: REMOTE) {
      # eslint-disable-next-line relay/unused-fields
      email
      name
      ...MatchGranteeTable_HRISProviderEmployee
    }
    ignoredHRISProviderEmployees(hRISProvider: REMOTE) {
      # eslint-disable-next-line relay/unused-fields
      email
      name
      ...MatchGranteeTable_HRISProviderEmployee
    }
    ...MatchGranteeTable_Organization
    ...useOrganizationCTMS_Organization
    ...useTrackRemoteEquityOnboardingFlowEvent_Organization
    ...RemoteEquityOnboardingLayout_Organization
  }
`;

const ORGANIZATION_SUBSCRIPTION = graphql`
  subscription MatchGranteeView_Organization_Subscription(
    $id: OrganizationId!
  ) {
    organizationUpdates(id: $id) {
      ...MatchGranteeView_Organization
    }
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment MatchGranteeView_Viewer on Account {
    ...RemoteEquityOnboardingLayout_Viewer
  }
`;

const REMOTE_DASHBOARD_EQUITY_URL = CONFIGURATION.REMOTE_DASHBOARD_EQUITY_URL;
if (!REMOTE_DASHBOARD_EQUITY_URL) {
  throw new Error("REMOTE_DASHBOARD_EQUITY_URL is required");
}

const LoadingState: React.FC<{
  organization: MatchGranteeView_Organization$data;
}> = ({ organization }) => {
  const organizationCTMS = useOrganizationCTMS({
    organizationFragment: organization,
  });

  return (
    <div className="space-y-4 rounded-remote-md bg-remote-white px-8 py-6">
      <Text className="text-remote-grey-800" variant="lgMedium">
        Analyzing your {organizationCTMS?.name} data...
      </Text>
      <Button
        className="w-full"
        isLoading
        size="md"
        tone="primary"
        variant="solid"
      />
    </div>
  );
};

type Tab = "ignored" | "matched" | "unmatched";

const PluralizedAccount: React.FC<{ accountCount: number }> = ({
  accountCount,
}) => (
  <Text as="span" className="text-remote-grey-600" variant="smMedium">
    <FormattedMessage
      defaultMessage="{count, plural, one {# account} other {# accounts}}"
      values={{
        count: accountCount,
      }}
    />
  </Text>
);

const CardHeaderLabel: React.FC<{
  accountCount: number;
  ctmsName: string;
  tab: Tab;
}> = ({ accountCount, ctmsName, tab }) => {
  switch (tab) {
    case "ignored":
      return (
        <>
          <PluralizedAccount accountCount={accountCount} /> ignored
        </>
      );
    case "matched":
      return (
        <>
          <PluralizedAccount accountCount={accountCount} /> matched
        </>
      );
    case "unmatched":
      return (
        <>
          We have found <PluralizedAccount accountCount={accountCount} /> in
          Remote without a matching profile in {ctmsName}.
        </>
      );
  }
};

export const MatchGranteeView: React.FC<{
  organizationFragment: MatchGranteeView_Organization$key;
  viewerFragment: MatchGranteeView_Viewer$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const [organization, refetchOrganization] = useRefetchableFragment(
    ORGANIZATION_FRAGMENT,
    organizationFragment,
  );
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  const trackEvent = useTrackRemoteEquityOnboardingFlowEvent({
    organizationFragment: organization,
  });

  const refreshData = useCallback(() => {
    startTransition(() => {
      refetchOrganization({});
    });
  }, [refetchOrganization]);

  useEffect(() => {
    if (!organization.remoteEquityCTMSSyncCompleted) {
      refreshData();
    }
  }, [refreshData, organization.remoteEquityCTMSSyncCompleted]);

  const organizationCTMS = useOrganizationCTMS({
    organizationFragment: organization,
  });
  const ctmsName = useMemo(
    () => organizationCTMS?.name ?? "CTMS",
    [organizationCTMS?.name],
  );

  const [search, setSearch] = useState("");

  const [_tab, _setTab] = useState<Tab>("unmatched");

  const isTabEmpty = useMemo(
    () => ({
      ignored: isEmpty(organization.ignoredHRISProviderEmployees),
      matched: isEmpty(organization.matchedHRISProviderEmployees),
      unmatched: isEmpty(organization.unmatchedHRISProviderEmployees),
    }),
    [
      organization.unmatchedHRISProviderEmployees,
      organization.matchedHRISProviderEmployees,
      organization.ignoredHRISProviderEmployees,
    ],
  );

  const setTab = useCallback(
    (newTab: Tab) => {
      switch (newTab) {
        case "ignored":
        case "matched":
          if (!isTabEmpty[newTab]) {
            _setTab(newTab);
          }
          break;
        case "unmatched":
          _setTab(newTab);
          break;
      }
    },
    [_setTab, isTabEmpty],
  );

  const tab = useMemo(() => {
    switch (_tab) {
      case "ignored":
      case "matched":
        if (!isTabEmpty[_tab]) {
          return _tab;
        }
        break;
      case "unmatched":
        return _tab;
    }
    return "unmatched";
  }, [_tab, isTabEmpty]);

  useEffect(() => {
    setSearch("");
  }, [tab]);

  const hRISProviderEmployees = useMemo(() => {
    switch (tab) {
      case "ignored":
        return organization.ignoredHRISProviderEmployees;
      case "matched":
        return organization.matchedHRISProviderEmployees;
      case "unmatched":
        return organization.unmatchedHRISProviderEmployees;
    }
  }, [
    organization.unmatchedHRISProviderEmployees,
    organization.matchedHRISProviderEmployees,
    organization.ignoredHRISProviderEmployees,
    tab,
  ]);

  const hRISProviderEmployeesFuse = useMemo(
    () =>
      new Fuse(hRISProviderEmployees, {
        keys: ["email", "name"],
      }),
    [hRISProviderEmployees],
  );

  const filteredHRISProviderEmployees = useMemo(() => {
    if (!search) return hRISProviderEmployees;

    return hRISProviderEmployeesFuse
      .search(search)
      .map((result) => result.item);
  }, [hRISProviderEmployees, hRISProviderEmployeesFuse, search]);

  useSubscription<MatchGranteeView_Organization_Subscription>({
    subscription: ORGANIZATION_SUBSCRIPTION,
    variables: {
      id: organization.id,
    },
  });

  return (
    <RemoteEquityOnboardingLayout
      className="!gap-10"
      organizationFragment={organization}
      subtitle={
        <>Connect your employees on Remote to their {ctmsName} accounts.</>
      }
      title={<>Match accounts</>}
      viewerFragment={viewer}
    >
      {!organization.remoteEquityCTMSSyncCompleted ? (
        <LoadingState organization={organization} />
      ) : (
        <div className="w-full max-w-[1112px] justify-items-center space-y-8 [&>ul]:!justify-center">
          <Tabs
            onClick={(_, newTab: Tab) => {
              setTab(newTab);
            }}
            value={tab}
          >
            <Tab
              count={organization.unmatchedHRISProviderEmployees.length}
              value="unmatched"
            >
              Unmatched
            </Tab>
            <Tab
              count={organization.matchedHRISProviderEmployees.length}
              value="matched"
            >
              Matched
            </Tab>
            <Tab
              count={organization.ignoredHRISProviderEmployees.length}
              value="ignored"
            >
              Ignored
            </Tab>
          </Tabs>

          <div className="w-full overflow-hidden rounded-remote-md bg-remote-white">
            <div className="flex items-center justify-between gap-6 bg-remote-background-subtle px-6 py-3">
              <Text as="span" className="text-remote-grey-500" variant="sm">
                <CardHeaderLabel
                  accountCount={hRISProviderEmployees.length}
                  ctmsName={ctmsName}
                  tab={tab}
                />
              </Text>
              <SearchBar
                onChange={setSearch}
                placeholder="Search"
                value={search}
              />
            </div>
            <Divider />
            <MatchGranteeTable
              ctmsName={ctmsName}
              hRISProviderEmployeesFragment={filteredHRISProviderEmployees}
              organizationFragment={organization}
              refreshData={refreshData}
              showEmptyState={isEmpty(hRISProviderEmployees)}
            />
          </div>
          <Button
            as="a"
            href={REMOTE_DASHBOARD_EQUITY_URL}
            IconAfter={IconV2OutlineArrowRight}
            onClick={() => {
              trackEvent("Left Flow after matching");
            }}
            size="lg"
            tone="primary"
            variant="solid"
          >
            Go to Remote
          </Button>
        </div>
      )}
    </RemoteEquityOnboardingLayout>
  );
};
