import { Button, DropdownMenu, Text } from "@remote-com/norma";
import { IconV2OutlineChevronDown } from "@remote-com/norma/icons/IconV2OutlineChevronDown";
import { graphql, useFragment } from "react-relay";
import { generatePath } from "react-router-dom";

import { APPLICATION_ROUTES } from "../../../../paths";
import { Header_EssentialsOverview_Organization$key } from "./__generated__/Header_EssentialsOverview_Organization.graphql";
import { Header_EssentialsOverview_Viewer$key } from "./__generated__/Header_EssentialsOverview_Viewer.graphql";
import { CartaIntegrationBanner } from "./CartaIntegrationBanner";

const ORGANIZATION_FRAGMENT = graphql`
  fragment Header_EssentialsOverview_Organization on Organization {
    id
    name
    hasBeenOnboarded
    declaredCTMSSolution
    ...CartaIntegrationBanner_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment Header_EssentialsOverview_Viewer on Account {
    firstName
  }
`;

export const Header: React.FC<{
  organizationFragment: Header_EssentialsOverview_Organization$key;
  viewerFragment: Header_EssentialsOverview_Viewer$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const declaredCartaAsCTMSSolution =
    organization.declaredCTMSSolution === "CARTA";

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="space-y-1">
          <Text className="text-grey-600" variant="xs">
            {organization.name} equity workspace
          </Text>
          <Text variant="4xlMedium">
            {organization.hasBeenOnboarded
              ? `Welcome back, ${viewer.firstName} 👋`
              : `Welcome, ${viewer.firstName} 👋`}
          </Text>
        </div>
        <DropdownMenu
          // @ts-expect-error className and trigger not defined on DropdownMenu props
          className="z-10"
          trigger={
            <Button IconAfter={IconV2OutlineChevronDown} size="md">
              Declare new
            </Button>
          }
        >
          <DropdownMenu.Item
            href={generatePath(APPLICATION_ROUTES.organizationDeclareNewGrant, {
              organizationId: organization.id,
            })}
          >
            Declare grant
          </DropdownMenu.Item>
          <DropdownMenu.Item
            href={generatePath(
              APPLICATION_ROUTES.organizationDeclareNewExerciseRequests,
              {
                organizationId: organization.id,
              },
            )}
          >
            Declare exercise
          </DropdownMenu.Item>
        </DropdownMenu>
      </div>
      {declaredCartaAsCTMSSolution && (
        <CartaIntegrationBanner organizationFragment={organization} />
      )}
    </>
  );
};
