/**
 * @generated SignedSource<<3369188b5bf81c275d7c34443ef51144>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmployeePortalReportingAndGeneralTaxationDetailsSection_Grantee$data = {
  readonly ctmsGrants: ReadonlyArray<{
    readonly label: string;
    readonly matchingEquityTypeHandbook: {
      readonly " $fragmentSpreads": FragmentRefs<"EquityHandbooksCountryEquityTypeContent_EquityTypeHandbook">;
    } | null;
    readonly reportingGranteePortalContent: {
      readonly " $fragmentSpreads": FragmentRefs<"GrantRelatedHydratedContent_AdminPortalContent">;
    } | null;
    readonly taxationGranteePortalContent: {
      readonly " $fragmentSpreads": FragmentRefs<"GrantRelatedHydratedContent_AdminPortalContent">;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"GrantRelatedHydratedContent_CTMSGrant">;
  }>;
  readonly " $fragmentType": "EmployeePortalReportingAndGeneralTaxationDetailsSection_Grantee";
};
export type EmployeePortalReportingAndGeneralTaxationDetailsSection_Grantee$key = {
  readonly " $data"?: EmployeePortalReportingAndGeneralTaxationDetailsSection_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeePortalReportingAndGeneralTaxationDetailsSection_Grantee">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "GrantRelatedHydratedContent_AdminPortalContent"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmployeePortalReportingAndGeneralTaxationDetailsSection_Grantee",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "grantStatusIn",
          "value": [
            "Active",
            "Terminated"
          ]
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "direction": "ASC",
            "field": "vestingStartDate"
          }
        }
      ],
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "ctmsGrants",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EquityTypeHandbook",
          "kind": "LinkedField",
          "name": "matchingEquityTypeHandbook",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "EquityHandbooksCountryEquityTypeContent_EquityTypeHandbook"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GrantRelatedHydratedContent_CTMSGrant"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AdminPortalContent",
          "kind": "LinkedField",
          "name": "taxationGranteePortalContent",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AdminPortalContent",
          "kind": "LinkedField",
          "name": "reportingGranteePortalContent",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": "ctmsGrants(grantStatusIn:[\"Active\",\"Terminated\"],orderBy:{\"direction\":\"ASC\",\"field\":\"vestingStartDate\"})"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};
})();

(node as any).hash = "cdd733c0faa81a42da6d17aad34050dd";

export default node;
