import { Button, Text } from "@remote-com/norma";
import { ArrowLeft } from "iconsax-react";

import { RemoteEquityLogo } from "../../../components/ui/Logo/Logo";
import { Progress } from "../../../components/ui/Progress";
import { cx } from "../../../helpers/cva.config";

const GUTTER = cx("px-14");
const TOTAL_STEPS = 5;

export function EssentialsOnboardingTemplate({
  aside,
  children,
  footer,
  illustration,
  subtitle,
  title,
  tone = "white",
}: React.PropsWithChildren<{
  aside?: React.ReactNode;
  footer?: React.ReactNode;
  illustration: string;
  subtitle?: React.ReactNode;
  title: React.ReactNode;
  tone?: "blue" | "white";
}>) {
  return (
    <div
      className={cx("flex h-screen w-screen flex-col", {
        "bg-background-base": tone === "white",
        "bg-gradient-linear-onboarding text-white": tone === "blue",
      })}
    >
      <header className={cx(GUTTER, "shrink-0 py-8")}>
        <RemoteEquityLogo
          className="w-full max-w-48"
          variant={tone === "white" ? "black" : "white"}
        />
      </header>
      <main
        className={cx(
          GUTTER,
          "mx-auto grid h-[calc(100vh-8rem)] grid-cols-[minmax(auto,800px)_minmax(auto,600px)] gap-8 overflow-hidden py-14",
        )}
      >
        <div className="flex min-h-0 flex-col justify-center">
          <div className="flex flex-col gap-14 overflow-y-auto">
            <div className="space-y-2">
              <Text variant="5xlMedium">{title}</Text>
              {subtitle && (
                <Text className="text-grey-600" variant="xl">
                  {subtitle}
                </Text>
              )}
            </div>
            <div>{children}</div>
          </div>
        </div>
        <aside className="relative min-h-0">
          <div className="absolute inset-0">
            <img
              alt="Remote Equity Essentials"
              className="h-full w-full object-contain object-center"
              src={illustration}
            />
          </div>
          <div className="relative flex h-full flex-col items-center justify-center">
            <div className="overflow-y-auto">{aside}</div>
          </div>
        </aside>
      </main>
      {footer}
    </div>
  );
}

export function EssentialsOnboardingTemplateFooter({
  onPreviousClick,
  previousStepIndex = 0,
  stepIndex,
  stepName,
  submitButton,
}: {
  onPreviousClick: () => void;
  previousStepIndex?: number;
  stepIndex: number;
  stepName: React.ReactNode;
  submitButton: React.ReactNode;
}) {
  return (
    <div className="shrink-0">
      <Progress
        className="h-[5px] shrink-0 rounded-none bg-grey-200"
        max={TOTAL_STEPS}
      >
        <Progress.Value
          className="rounded-l-none bg-primary-600"
          initialValue={previousStepIndex}
          value={stepIndex}
        />
      </Progress>
      <footer
        className={cx(
          GUTTER,
          "grid shrink-0 grid-cols-[1fr_auto_1fr] gap-4 py-6",
        )}
      >
        <div className="flex items-center justify-start">
          <Button
            IconBefore={ArrowLeft}
            onClick={onPreviousClick}
            tone="secondary"
            variant="ghost"
          >
            Back
          </Button>
        </div>
        <div className="flex items-center justify-center">
          <div className="text-center">
            <Text className="uppercase text-grey-500" variant="xsSemiBold">
              Setup Remote Equity Essentials
            </Text>
            <Text variant="smMedium">{stepName}</Text>
          </div>
        </div>
        <div className="flex items-center justify-end">{submitButton}</div>
      </footer>
    </div>
  );
}
