import { Radio } from "@headlessui/react";
import React from "react";
import { ElementType } from "react";

interface Icons {
  activeIcon: ElementType;
  icon: ElementType;
}

export function RadioCard({
  children,
  icons,
  value,
}: React.PropsWithChildren<{
  icons?: Icons;
  value: string;
}>) {
  return (
    <Radio
      className="group flex flex-1 cursor-pointer items-center gap-2 rounded-remote-md border border-grey-100 bg-white p-4 text-SM/Medium text-grey-600 transition-all data-[disabled]:cursor-not-allowed data-[checked]:text-brand-600 data-[checked]:ring-2 data-[checked]:ring-brand-600"
      value={value}
    >
      <div className="box-border h-5 w-5 rounded-full border-2 border-grey-500 bg-white transition-all group-data-[checked]:border-4 group-data-[checked]:border-brand-600" />
      <div className="flex flex-1 items-center gap-2">
        {icons && (
          <>
            {React.createElement(icons.icon, {
              className: "h-4 group-data-[checked]:hidden block",
            })}
            {React.createElement(icons.activeIcon, {
              className: "h-4 group-data-[checked]:block hidden",
            })}
          </>
        )}
        <div>{children}</div>
      </div>
    </Radio>
  );
}
