/**
 * @generated SignedSource<<0a8dfaabe514fcfb2685134ff4d24307>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmployeeEquityGridLevelTag_Organization$data = {
  readonly equityGrid: {
    readonly activated: boolean;
  };
  readonly " $fragmentSpreads": FragmentRefs<"FormattedCurrency_Organization" | "useOrganizationSharesUtil_Organization">;
  readonly " $fragmentType": "EmployeeEquityGridLevelTag_Organization";
};
export type EmployeeEquityGridLevelTag_Organization$key = {
  readonly " $data"?: EmployeeEquityGridLevelTag_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeeEquityGridLevelTag_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmployeeEquityGridLevelTag_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityGrid",
      "kind": "LinkedField",
      "name": "equityGrid",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "activated",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationSharesUtil_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedCurrency_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "6b8e09fa80e58efea95a1da90d3213ac";

export default node;
