/**
 * @generated SignedSource<<53647d764d949e01d44655a038ca2669>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminGranteesLayout_Organization$data = {
  readonly id: string;
  readonly isConnectedToHRISProvider: boolean;
  readonly isOriginatingFromRemoteEquity: boolean;
  readonly isRemoteEquityEssentials: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"CreateGranteeButton_Organization" | "LargeOneColumnLayout_Organization" | "SuspensedTabList_Organization">;
  readonly " $fragmentType": "AdminGranteesLayout_Organization";
};
export type AdminGranteesLayout_Organization$key = {
  readonly " $data"?: AdminGranteesLayout_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminGranteesLayout_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminGranteesLayout_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isConnectedToHRISProvider",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOriginatingFromRemoteEquity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRemoteEquityEssentials",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateGranteeButton_Organization"
    },
    {
      "kind": "Defer",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SuspensedTabList_Organization"
        }
      ]
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LargeOneColumnLayout_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "1a6ea3295fd73baabe9cf939b82de681";

export default node;
