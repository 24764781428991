/**
 * @generated SignedSource<<c9cbe2b1e128f068b11a0e527f7bf996>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type HRISProvider = "DEEL" | "REMOTE";
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type GranteesFilters_Deferred_Organization$data = {
  readonly allGrantees: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly hRISProviderEmployee: {
          readonly hRISProvider: HRISProvider;
        } | null;
        readonly id: string;
        readonly taxResidenceCountry: {
          readonly code: string;
          readonly emoji: string;
          readonly name: string;
        } | null;
        readonly workRelationship: WorkRelationship | null;
      };
    }>;
  };
  readonly equityGrid: {
    readonly activated: boolean;
    readonly levels: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"CurrencySymbol_Organization" | "FormattedCurrency_Organization">;
  readonly " $fragmentType": "GranteesFilters_Deferred_Organization";
};
export type GranteesFilters_Deferred_Organization$key = {
  readonly " $data"?: GranteesFilters_Deferred_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteesFilters_Deferred_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteesFilters_Deferred_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityGrid",
      "kind": "LinkedField",
      "name": "equityGrid",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "activated",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EquityGridLevel",
          "kind": "LinkedField",
          "name": "levels",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "allGrantees",
      "args": null,
      "concreteType": "GranteesConnection",
      "kind": "LinkedField",
      "name": "grantees",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GranteeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Grantee",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "workRelationship",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Country",
                  "kind": "LinkedField",
                  "name": "taxResidenceCountry",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "emoji",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "code",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "HRISProviderEmployee",
                  "kind": "LinkedField",
                  "name": "hRISProviderEmployee",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hRISProvider",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurrencySymbol_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedCurrency_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "3c5657fe57984ce927ef0c4daf8e4e1f";

export default node;
