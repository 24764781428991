import React from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { Footer } from "../../../../components/Footer";
import { Page } from "../../../../components/Page";
import { useQuery } from "../../../../hooks/useQuery";
import { useOrganizationIdParam } from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { EssentialsOverview_Admin_Organization$key } from "./__generated__/EssentialsOverview_Admin_Organization.graphql";
import { EssentialsOverview_Admin_Query } from "./__generated__/EssentialsOverview_Admin_Query.graphql";
import { EssentialsOverview_Admin_Viewer$key } from "./__generated__/EssentialsOverview_Admin_Viewer.graphql";
import { AsideSection } from "./AsideSection";
import { Header } from "./Header";
import { MainSection } from "./MainSection";

const VIEWER_FRAGMENT = graphql`
  fragment EssentialsOverview_Admin_Viewer on Account {
    ...Header_EssentialsOverview_Viewer
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment EssentialsOverview_Admin_Organization on Organization {
    ...Header_EssentialsOverview_Organization
    ...MainSection_EssentialsOverview_Organization
    ...AsideSection_EssentialsOverview_Organization
    ...Footer_Organization
  }
`;

const AdminOverviewPage_: React.FC<{
  organizationFragment: EssentialsOverview_Admin_Organization$key;
  viewerFragment: EssentialsOverview_Admin_Viewer$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  return (
    <div className="inset-0 bg-background-subtle">
      <div className="container mx-auto w-full max-w-[1032px] space-y-6 px-6 py-16">
        <Header organizationFragment={organization} viewerFragment={viewer} />
        <div className="grid grid-cols-[1fr_352px] gap-10">
          <MainSection organizationFragment={organization} />
          <AsideSection organizationFragment={organization} />
        </div>
        <Footer organizationFragment={organization} />
      </div>
    </div>
  );
};

const QUERY = graphql`
  query EssentialsOverview_Admin_Query($organizationId: OrganizationId!) {
    me {
      ...EssentialsOverview_Admin_Viewer
    }
    organization(id: $organizationId) {
      id
      name
      ...EssentialsOverview_Admin_Organization
    }
  }
`;

const AdminEssentialsOverviewPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const {
    query: { me, organization },
  } = useQuery<EssentialsOverview_Admin_Query>(QUERY, {
    organizationId,
  });

  if (!organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsName="Admin - Home"
      organizationId={organization.id}
      title={`Admin | ${organization.name} overview`}
    >
      <AdminOverviewPage_
        organizationFragment={organization}
        viewerFragment={me}
      />
    </Page>
  );
};

export default AdminEssentialsOverviewPage;
