/**
 * @generated SignedSource<<57d7f3812e5fa6b124b8fa71cf2f29f0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type HRISProvider = "DEEL" | "REMOTE";
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type GrantDetailsEmployeeSection_CTMSGrant$data = {
  readonly grantee: {
    readonly contractStartDate: string | null;
    readonly email: string;
    readonly hRISProviderEmployee: {
      readonly hRISProvider: HRISProvider;
    } | null;
    readonly jobTitle: string | null;
    readonly name: string;
    readonly taxResidenceCountry: {
      readonly emoji: string;
      readonly name: string;
    } | null;
    readonly workRelationship: WorkRelationship | null;
  };
  readonly " $fragmentType": "GrantDetailsEmployeeSection_CTMSGrant";
};
export type GrantDetailsEmployeeSection_CTMSGrant$key = {
  readonly " $data"?: GrantDetailsEmployeeSection_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantDetailsEmployeeSection_CTMSGrant">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantDetailsEmployeeSection_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "grantee",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contractStartDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jobTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workRelationship",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Country",
          "kind": "LinkedField",
          "name": "taxResidenceCountry",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "emoji",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HRISProviderEmployee",
          "kind": "LinkedField",
          "name": "hRISProviderEmployee",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hRISProvider",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};
})();

(node as any).hash = "a55f3d91efbe1ca6dd900c51d6f78ad8";

export default node;
