import { graphql } from "relay-runtime";

import { GranteePortalHydratedContentTab } from "../../components/GranteePortalHydratedContentTab";
import { Page } from "../../components/Page";
import { useGranteePortalGrantType } from "../../hooks/useGranteePortal";
import { useQuery } from "../../hooks/useQuery";
import { useGranteeLayoutContext } from "../../layouts/GranteeEquityTypesGroupLayout/GranteeEquityTypesGroupLayout";
import { useGranteeIdParam } from "../../paths";
import NotFoundPage from "../NotFound/NotFound";
import { Taxation_Query } from "./__generated__/Taxation_Query.graphql";

const QUERY = graphql`
  query Taxation_Query($granteeId: GranteeId!, $grantType: GrantType!) {
    grantee(id: $granteeId) {
      ...GranteePortalHydratedContentTab_Grantee
      organization {
        id
        name
      }
      ctmsGrants(grantType: $grantType) {
        ...GranteePortalHydratedContentTab_Taxation_CTMSGrant
      }
    }
  }
`;

const GranteeTaxationPage: React.FC = () => {
  const granteeId = useGranteeIdParam();
  const grantType = useGranteePortalGrantType();
  const { query } = useQuery<Taxation_Query>(QUERY, {
    granteeId,
    grantType,
  });
  const { isExpertMode } = useGranteeLayoutContext();

  if (!query.grantee) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Grantee view"
      analyticsName="Grantee - Taxation"
      analyticsProperties={{
        expertiseMode: isExpertMode ? "expert" : "beginner",
      }}
      organizationId={query.grantee.organization.id}
      title={`Grantee | ${query.grantee.organization.name} taxation`}
    >
      <GranteePortalHydratedContentTab
        ctmsGrantsFragment={query.grantee.ctmsGrants}
        granteeFragment={query.grantee}
        section="taxation"
      />
    </Page>
  );
};

export default GranteeTaxationPage;
