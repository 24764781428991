/**
 * @generated SignedSource<<66de0eb40432ae98074702d886148f01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApprovalSlideOverGrantsCard_Organization$data = {
  readonly equityGrid: {
    readonly activated: boolean;
  };
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"CurrencySymbol_Organization" | "FormattedCurrency_Organization" | "useOrganizationSharesUtil_Organization">;
  readonly " $fragmentType": "ApprovalSlideOverGrantsCard_Organization";
};
export type ApprovalSlideOverGrantsCard_Organization$key = {
  readonly " $data"?: ApprovalSlideOverGrantsCard_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApprovalSlideOverGrantsCard_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApprovalSlideOverGrantsCard_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityGrid",
      "kind": "LinkedField",
      "name": "equityGrid",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "activated",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationSharesUtil_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurrencySymbol_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedCurrency_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "97a553d1ba8230ccb6c4feb7a1d87f76";

export default node;
