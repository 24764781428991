/**
 * @generated SignedSource<<f0cba545906f7fff166ea95c2f8875e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type VestingPreviewGraph_VestingSchedule_Query$variables = {
  organizationId: string;
  quantityGranted: number;
  vestingScheduleId: any;
  vestingStartDate: string;
};
export type VestingPreviewGraph_VestingSchedule_Query$data = {
  readonly vestingDataPointsFromVestingScheduleId: ReadonlyArray<{
    readonly cumulativeVested: number;
    readonly date: string;
  }>;
};
export type VestingPreviewGraph_VestingSchedule_Query = {
  response: VestingPreviewGraph_VestingSchedule_Query$data;
  variables: VestingPreviewGraph_VestingSchedule_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "quantityGranted"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "vestingScheduleId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "vestingStartDate"
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "organizationId",
      "variableName": "organizationId"
    },
    {
      "kind": "Variable",
      "name": "quantityGranted",
      "variableName": "quantityGranted"
    },
    {
      "kind": "Variable",
      "name": "vestingScheduleId",
      "variableName": "vestingScheduleId"
    },
    {
      "kind": "Variable",
      "name": "vestingStartDate",
      "variableName": "vestingStartDate"
    }
  ],
  "concreteType": "VestingDataPoint",
  "kind": "LinkedField",
  "name": "vestingDataPointsFromVestingScheduleId",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cumulativeVested",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "VestingPreviewGraph_VestingSchedule_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": (v4/*: any*/),
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "VestingPreviewGraph_VestingSchedule_Query",
    "selections": [
      (v4/*: any*/)
    ]
  },
  "params": {
    "cacheID": "7d8cbda434cc9a73ff195724922be6f9",
    "id": null,
    "metadata": {},
    "name": "VestingPreviewGraph_VestingSchedule_Query",
    "operationKind": "query",
    "text": "query VestingPreviewGraph_VestingSchedule_Query(\n  $organizationId: OrganizationId!\n  $vestingScheduleId: VestingScheduleId!\n  $vestingStartDate: Date!\n  $quantityGranted: Int!\n) {\n  vestingDataPointsFromVestingScheduleId(organizationId: $organizationId, vestingScheduleId: $vestingScheduleId, vestingStartDate: $vestingStartDate, quantityGranted: $quantityGranted) {\n    cumulativeVested\n    date\n  }\n}\n"
  }
};
})();

(node as any).hash = "f769887aabdbe5489adf2c3bec762e9d";

export default node;
