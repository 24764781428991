/**
 * @generated SignedSource<<514900826f89dda358f2383673e0eb28>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ValuationHistoryChart_Organization$data = {
  readonly fairMarketValueHistory: ReadonlyArray<{
    readonly boardDetermined: boolean;
    readonly date: string;
    readonly tag: string | null;
    readonly value: number;
  }>;
  readonly name: string;
  readonly pricePerShareHistory: ReadonlyArray<{
    readonly boardDetermined: boolean;
    readonly date: string;
    readonly tag: string | null;
    readonly value: number;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"FormattedFMV_Organization">;
  readonly " $fragmentType": "ValuationHistoryChart_Organization";
};
export type ValuationHistoryChart_Organization$key = {
  readonly " $data"?: ValuationHistoryChart_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ValuationHistoryChart_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "date",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "boardDetermined",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "tag",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ValuationHistoryChart_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PricePerShare",
      "kind": "LinkedField",
      "name": "pricePerShareHistory",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FairMarketValue",
      "kind": "LinkedField",
      "name": "fairMarketValueHistory",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedFMV_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "b42e677a9b04cb9871cde1c11eb7d4d2";

export default node;
