import classNames from "classnames";
import { forwardRef } from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";

export const TEXT_AREA_BASE_CLASS =
  "bg-white block w-full rounded-md border-gray-05 shadow-sm focus:border-primary-05 focus:outline-none focus:ring-1 focus:ring-primary-05 text-sm disabled:bg-gray-01 disabled:text-gray-09 disabled:border-gray-02 disabled:shadow-none";

const _TextArea = forwardRef<
  HTMLTextAreaElement,
  React.ComponentProps<"textarea"> & { invalid?: boolean }
>(function TextArea({ className, invalid, ...textAreaProps }, ref) {
  return (
    <textarea
      className={classNames(TEXT_AREA_BASE_CLASS, className, {
        "form-textarea border-red focus:border-red focus:ring-red": invalid,
      })}
      ref={ref}
      {...textAreaProps}
    />
  );
});

const FormTextArea = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  ...props
}: React.ComponentProps<typeof _TextArea> & {
  control: Control<TFieldValues>;
  name: TName;
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <_TextArea {...field} {...props} invalid={!!fieldState.error} />
      )}
    />
  );
};
export const TextArea = Object.assign(_TextArea, {
  Form: FormTextArea,
});
