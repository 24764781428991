import { sumBy } from "lodash";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { ProjectionCard } from "../../components/ProjectionCard";
import { GranteeOutcome_CTMSGrant$key } from "./__generated__/GranteeOutcome_CTMSGrant.graphql";
import { GranteeOutcome_Grantee$key } from "./__generated__/GranteeOutcome_Grantee.graphql";

const GRANTEE_FRAGMENT = graphql`
  fragment GranteeOutcome_Grantee on Grantee {
    organization {
      latestPricePerShare {
        value
      }
      granteePortalProjectionScenarios {
        ...ProjectionCard_GranteePortalProjectionScenarios
      }
      ...ProjectionCard_Organization
    }
  }
`;

const CTMS_GRANT_FRAGMENT = graphql`
  fragment GranteeOutcome_CTMSGrant on CTMSGrant @relay(plural: true) {
    valueAtGrant
    quantityIssued
  }
`;

export const GranteeOutcome: React.FC<{
  ctmsGrantsFragment: GranteeOutcome_CTMSGrant$key;
  granteeFragment: GranteeOutcome_Grantee$key;
}> = ({ ctmsGrantsFragment, granteeFragment }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const ctmsGrants = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantsFragment);
  const organization = grantee.organization;

  const valueAtGrant = sumBy(
    ctmsGrants,
    (ctmsGrant) => ctmsGrant.valueAtGrant ?? 0,
  );
  const sharesGranted = sumBy(
    ctmsGrants,
    (ctmsGrant) => ctmsGrant.quantityIssued,
  );

  if (!organization.latestPricePerShare) return null;

  return (
    <ProjectionCard
      latestPricePerShareValue={organization.latestPricePerShare.value}
      organizationFragment={organization}
      projectionScenariosFragment={
        organization.granteePortalProjectionScenarios
      }
      shares={sharesGranted}
      valueAtGrant={valueAtGrant}
    />
  );
};
