import { graphql, useFragment } from "react-relay";

import { useGrantUnit_CTMSGrant$key } from "./__generated__/useGrantUnit_CTMSGrant.graphql";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment useGrantUnit_CTMSGrant on CTMSGrant {
    matchingInstrument {
      equityType {
        exercisable
      }
    }
    isVirtual
  }
`;
export function useGrantUnit({
  grantFragment,
}: {
  grantFragment: useGrantUnit_CTMSGrant$key;
}) {
  const grant = useFragment(CTMS_GRANT_FRAGMENT, grantFragment);

  if (!grant.matchingInstrument?.equityType?.exercisable) {
    return {
      plural: "shares",
      singular: "share",
    };
  }

  if (grant.isVirtual) {
    return {
      plural: "virtual options",
      singular: "virtual option",
    };
  }

  return {
    plural: "options",
    singular: "option",
  };
}
