/**
 * @generated SignedSource<<8a998bf7e45f7be52330c4e4bde17d85>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OwnershipSection_Organization$data = {
  readonly id: string;
  readonly isRemoteEquityEssentials: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"CurrencyUnit_Organization" | "EmployeeEquityGridLevelTag_Organization" | "FormattedCurrency_Organization" | "GranteeFormSlide_Organization" | "useOrganizationSharesUtil_Organization">;
  readonly " $fragmentType": "OwnershipSection_Organization";
};
export type OwnershipSection_Organization$key = {
  readonly " $data"?: OwnershipSection_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"OwnershipSection_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OwnershipSection_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRemoteEquityEssentials",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationSharesUtil_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmployeeEquityGridLevelTag_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeFormSlide_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedCurrency_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurrencyUnit_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "62e52c17a858e3fb6ac8c89cd17c873d";

export default node;
