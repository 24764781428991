export interface RoundedBarShapeProps {
  fill: string;
  height: number;
  width: number;
  x: number;
  y: number;
}

export const RoundedBarShape = ({
  fill,
  height,
  width,
  x,
  y,
}: RoundedBarShapeProps) => {
  const radius = Math.min(height, 4);

  return (
    <path
      d={`
          m${x},${y + height}
          v${-height + radius}
          q 0,-${radius} ${radius},-${radius}
          h${width - radius * 2}
          q ${radius},0 ${radius},${radius}
          v${height - radius}
          z`}
      fill={fill}
    />
  );
};

export const FullyRoundedBarShape = ({
  fill,
  height,
  width,
  x,
  y,
}: RoundedBarShapeProps) => {
  const radius = Math.min(height / 2, 8);

  return (
    <path
      d={`
      m${x},${y + height - radius}
      v${-height + radius * 2}
      q 0,-${radius} ${radius},-${radius}
      h${width - radius * 2}
      q ${radius},0 ${radius},${radius}
      v${height - radius * 2}
      q 0,${radius} -${radius},${radius}
      h${-width + radius * 2}
      q -${radius},0 -${radius},-${radius}
        z`}
      fill={fill}
    />
  );
};
