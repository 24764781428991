import { formatISO } from "date-fns";
import { isNil } from "lodash";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useQuery } from "../hooks/useQuery";
import { VestingPreviewGraph_Organization$key } from "./__generated__/VestingPreviewGraph_Organization.graphql";
import { VestingPreviewGraph_VestingSchedule_Query } from "./__generated__/VestingPreviewGraph_VestingSchedule_Query.graphql";
import { VestingGraph } from "./VestingGraph";

const VESTING_PREVIEW_QUERY = graphql`
  query VestingPreviewGraph_VestingSchedule_Query(
    $organizationId: OrganizationId!
    $vestingScheduleId: VestingScheduleId!
    $vestingStartDate: Date!
    $quantityGranted: Int!
  ) {
    vestingDataPointsFromVestingScheduleId(
      organizationId: $organizationId
      vestingScheduleId: $vestingScheduleId
      vestingStartDate: $vestingStartDate
      quantityGranted: $quantityGranted
    ) @required(action: THROW) {
      cumulativeVested
      date
    }
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment VestingPreviewGraph_Organization on Organization {
    id
    ...VestingGraph_Organization
  }
`;

export function VestingPreviewGraph({
  exercisePrice = null,
  organizationFragment,
  quantityGranted,
  vestingScheduleId,
  vestingStartDate,
}: {
  exercisePrice?: null | number;
  organizationFragment: VestingPreviewGraph_Organization$key;
  quantityGranted: number;
  vestingScheduleId: string;
  vestingStartDate: Date;
}) {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const {
    query: { vestingDataPointsFromVestingScheduleId: vestingDataPoints },
  } = useQuery<VestingPreviewGraph_VestingSchedule_Query>(
    VESTING_PREVIEW_QUERY,
    {
      organizationId: organization.id,
      quantityGranted,
      vestingScheduleId,
      vestingStartDate: formatISO(vestingStartDate, {
        representation: "date",
      }),
    },
  );

  const exercisePriceIsDefined = !isNil(exercisePrice);

  if (vestingDataPoints.length === 0) {
    return null;
  }

  return (
    <VestingGraph
      displayValuation={exercisePriceIsDefined}
      grants={[
        {
          exercisePrice,
          label: "Vesting schedule preview",
          vestingDataPoints: vestingDataPoints.map(
            ({ cumulativeVested, date }) => ({
              cumulativeVested,
              date: new Date(date),
            }),
          ),
        },
      ]}
      groupBy="month"
      organizationFragment={organization}
      randomColorSeed={vestingScheduleId}
      view="split"
    />
  );
}
