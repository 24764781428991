import { StandaloneLink, Text } from "@remote-com/norma";
import { IconV2OutlineEditBox } from "@remote-com/norma/icons/IconV2OutlineEditBox";
import { graphql, useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";

import { FormattedNumber } from "../../../../components/Formatted/FormattedNumber";
import { Progress } from "../../../../components/ui/Progress";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { APPLICATION_ROUTES } from "../../../../paths";
import { EsopPoolProgressCard_EssentialsOverview_Organization$key } from "./__generated__/EsopPoolProgressCard_EssentialsOverview_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment EsopPoolProgressCard_EssentialsOverview_Organization on Organization {
    id
    esopPoolShares
    poolGrantedSharesBreakdown {
      total
    }
  }
`;

export const EsopPoolProgressCard: React.FC<{
  organizationFragment: EsopPoolProgressCard_EssentialsOverview_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  if (!organization.esopPoolShares) {
    return null;
  }

  return (
    <RoundedBox className="space-y-4 p-6" withBorder>
      <div className="flex items-center justify-between">
        <Text variant="lgMedium">Your plan</Text>
        <StandaloneLink
          as={Link}
          IconBefore={IconV2OutlineEditBox}
          size="sm"
          to={generatePath(
            APPLICATION_ROUTES.organizationCompanySettingsPlanParameters,
            {
              organizationId: organization.id,
            },
          )}
        >
          Edit
        </StandaloneLink>
      </div>
      <div className="space-y-2">
        <div className="flex justify-between">
          <Text as="span" variant="xlMedium">
            <FormattedNumber
              value={organization.poolGrantedSharesBreakdown.total}
            />{" "}
            <Text as="span" className="text-grey-600" variant="sm">
              shares outstanding
            </Text>
          </Text>
          <Text as="span" variant="xlMedium">
            <FormattedNumber value={organization.esopPoolShares} />{" "}
            <Text as="span" className="text-grey-600" variant="sm">
              pool size
            </Text>
          </Text>
        </div>
        <Progress
          className="h-2.5 bg-grey-200"
          max={organization.esopPoolShares}
        >
          <Progress.Value
            className="bg-purple-600"
            value={organization.poolGrantedSharesBreakdown.total}
          />
        </Progress>
      </div>
    </RoundedBox>
  );
};
