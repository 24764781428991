import React from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { LoadingPlaceholder } from "../../../components/LoadingPlaceholder";
import { RemoteEquityOnboardingLayout } from "../../../components/ui/Layout/RemoteLikeApplicationLayout/RemoteEquityOnboardingLayout";
import { useSafeMutation } from "../../../hooks/useSafeMutation";
import { BookADemoView_Account$key } from "./__generated__/BookADemoView_Account.graphql";
import { BookADemoView_Organization$key } from "./__generated__/BookADemoView_Organization.graphql";
import { BookADemoView_TriggerDemoBooked_Mutation } from "./__generated__/BookADemoView_TriggerDemoBooked_Mutation.graphql";
import { DemoBookedView } from "./DemoBookedView";

const ORGANIZATION_FRAGMENT = graphql`
  fragment BookADemoView_Organization on Organization {
    id
    name
    plan
    ...RemoteEquityOnboardingLayout_Organization
    ...DemoBookedView_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment BookADemoView_Account on Account {
    email
    firstName
    lastName
    ...RemoteEquityOnboardingLayout_Viewer
    ...DemoBookedView_Viewer
  }
`;

const TRIGGER_DEMO_BOOKED_MUTATION = graphql`
  mutation BookADemoView_TriggerDemoBooked_Mutation(
    $organizationId: OrganizationId!
  ) {
    triggerDemoBooked(organizationId: $organizationId) {
      plan
    }
  }
`;

const BookADemoView_: React.FC<{
  organizationFragment: BookADemoView_Organization$key;
  viewerFragment: BookADemoView_Account$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  const [triggerDemoBooked] =
    useSafeMutation<BookADemoView_TriggerDemoBooked_Mutation>(
      TRIGGER_DEMO_BOOKED_MUTATION,
    );

  const [iframeHeight, setIframeHeight] = React.useState<null | string>(null);
  const [eventTypeViewed, setEventTypeViewed] = React.useState(false);

  useCalendlyEventListener({
    onEventScheduled: async () => {
      await triggerDemoBooked({
        variables: { organizationId: organization.id },
      });
    },
    onEventTypeViewed: () => {
      setEventTypeViewed(true);
    },
    onPageHeightResize: (e) => {
      setIframeHeight(e.data.payload.height);
    },
  });

  return (
    <RemoteEquityOnboardingLayout
      organizationFragment={organization}
      subtitle={
        <div className="max-w-[458px]">
          Book a product demo with our experts and become an equity professional
          yourself.
        </div>
      }
      title={
        <div className="max-w-[518px]">
          Unlock the benefits of Employee Stock Ownership Plans
        </div>
      }
      viewerFragment={viewer}
    >
      <div className="w-full max-w-[510px]">
        <InlineWidget
          iframeTitle="Schedule a demo"
          LoadingSpinner={LoadingPlaceholder}
          pageSettings={{
            backgroundColor: "FFFFFF",
            hideEventTypeDetails: true,
            hideGdprBanner: true,
            hideLandingPageDetails: true,
          }}
          prefill={{
            customAnswers: {
              a1: organization.name,
            },
            email: viewer.email ?? "",
            name: viewer ? `${viewer.firstName} ${viewer.lastName}` : "",
          }}
          styles={{
            borderRadius: "12px",
            height: iframeHeight ?? "100%",
            margin: "0 auto",
            overflow: eventTypeViewed ? "auto" : undefined,
            textAlign: "center",
            width: "100%",
          }}
          url="https://calendly.com/d/cmcq-v7w-532/book-a-demo-clone"
        />
      </div>
    </RemoteEquityOnboardingLayout>
  );
};

export const BookADemoView: React.FC<{
  organizationFragment: BookADemoView_Organization$key;
  viewerFragment: BookADemoView_Account$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  if (organization.plan === "freemiumDemoBooked") {
    return (
      <DemoBookedView
        organizationFragment={organization}
        viewerFragment={viewer}
      />
    );
  }

  return (
    <BookADemoView_
      organizationFragment={organizationFragment}
      viewerFragment={viewerFragment}
    />
  );
};
