import classNames from "classnames";
import { isNil } from "lodash";
import { motion } from "motion/react";
import React, { createContext } from "react";
import { NavLink, To } from "react-router-dom";

import { Pill } from "./Pill";

const TabNavigationContext = createContext<null | { layoutId: string }>(null);

function TabNavigation_({
  children,
  layoutId,
  loading,
}: React.PropsWithChildren<{ layoutId: string; loading?: boolean }>) {
  return (
    <TabNavigationContext.Provider value={{ layoutId }}>
      <div
        className={classNames(
          "flex w-full items-end gap-6 border-b-[0.5px] border-grey-300",
          {
            "animate-pulse": loading,
          },
        )}
      >
        {children}
      </div>
    </TabNavigationContext.Provider>
  );
}

function TabNavigationLink({
  children,
  count,
  end,
  leftIcon,
  skeleton,
  to,
}: React.PropsWithChildren<{
  count?: number;
  end?: boolean;
  leftIcon?: React.ReactElement<React.ComponentProps<"svg">>;
  skeleton?: boolean;
  to: To;
}>) {
  const { layoutId } = useTabNavigationContext();

  return (
    <NavLink
      className={({ isPending, isTransitioning }) =>
        classNames("relative py-2 transition-all", {
          "animate-pulse": isTransitioning || isPending,
        })
      }
      end={end}
      to={to}
    >
      {({ isActive }) => (
        <>
          <div className="flex items-center gap-2">
            {leftIcon &&
              React.cloneElement(leftIcon, {
                "aria-hidden": true,
                className: classNames(
                  "w-5",
                  {
                    "text-grey-600": !isActive,
                    "text-grey-700": isActive,
                  },
                  leftIcon.props.className,
                ),
              })}
            <div className="relative py-0.5 transition-all">
              <div
                className={classNames(
                  "pointer-events-none invisible text-SM/Medium",
                )}
              >
                {children}
              </div>
              <div
                className={classNames("absolute inset-0 text-center", {
                  "text-SM/Medium text-grey-700": isActive,
                  "text-SM text-grey-600 hover:text-grey-700": !isActive,
                })}
              >
                {children}
              </div>
            </div>
            {!isNil(count) && <Pill skeleton={skeleton}>{count}</Pill>}
          </div>
          {isActive && (
            <motion.div
              className="absolute inset-x-0 bottom-0 h-0.5 bg-grey-900"
              layout
              layoutId={layoutId}
            />
          )}
        </>
      )}
    </NavLink>
  );
}

function useTabNavigationContext() {
  const context = React.useContext(TabNavigationContext);

  if (isNil(context)) {
    throw new Error(
      "useTabNavigationContext must be used within a TabNavigation",
    );
  }

  return context;
}

export const TabNavigation = Object.assign(TabNavigation_, {
  Link: TabNavigationLink,
});
