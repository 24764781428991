import { CheckIcon } from "@heroicons/react/24/outline";
import _, { capitalize } from "lodash";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { FormattedUSD } from "../../../../components/Formatted/FormattedCurrency";
import { LinkButton } from "../../../../components/ui/Button";
import { NoticeMessage } from "../../../../components/ui/NoticeMessage";
import { SlideOver } from "../../../../components/ui/SlideOver";
import { Typography } from "../../../../components/ui/Typography";
import { makeRemoteController } from "../../../../helpers/makeRemoteController";
import { useApplicationSupportEmailAddress } from "../../../../hooks/useApplicationTheme";
import { useQuery } from "../../../../hooks/useQuery";
import { APPLICATION_ROUTES } from "../../../../paths";
import {
  LockedGeographySlideOver_Content_GeographyWithPrice$key,
  WorkRelationship,
} from "./__generated__/LockedGeographySlideOver_Content_GeographyWithPrice.graphql";
import { LockedGeographySlideOver_Content_Organization$key } from "./__generated__/LockedGeographySlideOver_Content_Organization.graphql";
import { LockedGeographySlideOver_EquityTypeDescription_EquityType$key } from "./__generated__/LockedGeographySlideOver_EquityTypeDescription_EquityType.graphql";
import { LockedGeographySlideOver_Header_Geography$key } from "./__generated__/LockedGeographySlideOver_Header_Geography.graphql";
import { LockedGeographySlideOverWithQuery_Query } from "./__generated__/LockedGeographySlideOverWithQuery_Query.graphql";
import globeImage from "./globe.png";

const WORK_RELATIONSHIP_LABEL_MAP: Record<WorkRelationship, React.ReactNode> = {
  Advisor: <>Advisors</>,
  ContractorManagementCompany: <>Contractors via a management company</>,
  ContractorNaturalPerson: <>Contractors as a natural person</>,
  DirectEmployee: (
    <>
      Direct employee{" "}
      <span className="text-black-05">(of your local subsidiary)</span>
    </>
  ),
  EoREmployee: (
    <>
      EoR employee{" "}
      <span className="text-black-05">(e.g. Remote, Deel, etc)</span>
    </>
  ),
};

const EQUITY_TYPE_DESCRIPTION_EQUITY_TYPE_FRAGMENT = graphql`
  fragment LockedGeographySlideOver_EquityTypeDescription_EquityType on EquityType {
    id
    name
    fullname
    description
    taxFavored
    instruments {
      workRelationship
    }
  }
`;

const EquityTypeDescription: React.FC<{
  equityTypeFragment: LockedGeographySlideOver_EquityTypeDescription_EquityType$key;
}> = ({ equityTypeFragment }) => {
  const equityType = useFragment(
    EQUITY_TYPE_DESCRIPTION_EQUITY_TYPE_FRAGMENT,
    equityTypeFragment,
  );

  const workRelationships = _(equityType.instruments)
    .map((instrument) => instrument.workRelationship)
    .uniq()
    .sort()
    .value();

  return (
    <div className="space-y-4" key={equityType.id}>
      <div className="space-y-1">
        <Typography as="div" variant="Medium/Small">
          {equityType.name}
          {equityType.fullname && (
            <Typography className="text-black-05" variant="Regular/Extra Small">
              {" "}
              ({equityType.fullname})
            </Typography>
          )}
        </Typography>
        <Typography
          as="div"
          className="text-black-05"
          variant="Regular/Extra Small"
        >
          {equityType.description}
        </Typography>
      </div>
      <ul className="space-y-2 pl-4">
        {workRelationships.map((workRelationship) => (
          <li className="flex items-center gap-2" key={workRelationship}>
            <CheckIcon className="h-5 w-5 text-green" />
            <Typography as="div" variant="Regular/Extra Small">
              {WORK_RELATIONSHIP_LABEL_MAP[workRelationship]}
            </Typography>
          </li>
        ))}
      </ul>
    </div>
  );
};

const CONTENT_GEOGRAPHY_WITH_PRICE_FRAGMENT = graphql`
  fragment LockedGeographySlideOver_Content_GeographyWithPrice on GeographyWithPrice {
    geography {
      country {
        code
        name
        emoji
      }
      equityTypes {
        id
        name
        fullname
        description
        taxFavored
        instruments {
          workRelationship
        }
        ...LockedGeographySlideOver_EquityTypeDescription_EquityType
      }
    }
    standardPrice {
      priceAfterDiscount
    }
    taxFavoredPrice {
      priceAfterDiscount
    }
  }
`;

const CONTENT_ORGANIZATION_FRAGMENT = graphql`
  fragment LockedGeographySlideOver_Content_Organization on Organization {
    id
    subscriptionBillingPeriod
    name
  }
`;

const Content: React.FC<{
  geographyWithPriceFragment: LockedGeographySlideOver_Content_GeographyWithPrice$key;
  organizationFragment: LockedGeographySlideOver_Content_Organization$key;
}> = ({ geographyWithPriceFragment, organizationFragment }) => {
  const geographyWithPrice = useFragment(
    CONTENT_GEOGRAPHY_WITH_PRICE_FRAGMENT,
    geographyWithPriceFragment,
  );
  const { geography } = geographyWithPrice;
  const organization = useFragment(
    CONTENT_ORGANIZATION_FRAGMENT,
    organizationFragment,
  );

  const withoutTaxFavoredEquityTypePrice =
    geographyWithPrice.standardPrice?.priceAfterDiscount || 0;

  const withTaxFavoredEquityTypePrice =
    geographyWithPrice.taxFavoredPrice?.priceAfterDiscount || 0;

  const hasTaxFavoredEquityType = useMemo(
    () => geography.equityTypes.some((equityType) => equityType.taxFavored),
    [geography.equityTypes],
  );

  const [taxFavoredEquityTypes, nonTaxFavoredEquityTypes] = useMemo(
    () =>
      _(geography.equityTypes)
        .orderBy((equityType) => equityType.name)
        .partition((equityType) => equityType.taxFavored)
        .value(),
    [geography.equityTypes],
  );
  const mailtoSubject = encodeURIComponent(
    `[${organization.name}] unlocking ${geography.country.name} tax favored option`,
  );
  const supportEmailAddress = useApplicationSupportEmailAddress();

  return (
    <div className="flex gap-10 px-6 pb-6 pt-18">
      <div className="w-48 space-y-2">
        <LinkButton
          fullWidth
          size="small"
          to={generatePath(APPLICATION_ROUTES.organizationUnlockCountry, {
            countryCode: geography.country.code,
            organizationId: organization.id,
          })}
        >
          Unlock this geography
        </LinkButton>
        {organization.subscriptionBillingPeriod && (
          <Typography as="div" variant="Regular/Caption">
            <FormattedMessage
              defaultMessage="{billingPeriod} recurring payment of <strong>{withoutTaxFavoredEquityTypePrice, number, ::currency/USD}</strong>."
              values={{
                billingPeriod: capitalize(
                  organization.subscriptionBillingPeriod,
                ),
                strong: (chunks) => (
                  <Typography variant="Medium/Caption">{chunks}</Typography>
                ),
                withoutTaxFavoredEquityTypePrice,
              }}
            />
            {hasTaxFavoredEquityType && (
              <>
                <br />
                <br />
                -- OR --
                <br />
                <br />
                <FormattedMessage
                  defaultMessage="{billingPeriod} recurring payment of <strong>{withTaxFavoredEquityTypePrice, number, ::currency/USD}</strong> if you choose to unlock the tax efficient equity type in the geography."
                  values={{
                    billingPeriod: capitalize(
                      organization.subscriptionBillingPeriod,
                    ),
                    strong: (chunks) => (
                      <Typography variant="Medium/Caption">{chunks}</Typography>
                    ),
                    withTaxFavoredEquityTypePrice,
                  }}
                />
              </>
            )}
            {organization.subscriptionBillingPeriod === "monthly" && (
              <>
                <br />
                <br />
                Get 10% discount on yearly subscription.
                <br />
                Minimum 12 months commitment.
              </>
            )}
          </Typography>
        )}
      </div>
      <div className="flex-1 space-y-6">
        <Typography as="div" variant="Regular/Extra Small">
          Unlock this geography to benefit from the following equity incentives:
        </Typography>
        {taxFavoredEquityTypes.map((equityType) => (
          <div
            className="space-y-4 border-l border-glass-green bg-glass-green-01 p-6"
            key={equityType.id}
          >
            <EquityTypeDescription equityTypeFragment={equityType} />
            <Typography
              as="div"
              className="rounded bg-glass-green-02 p-4"
              variant="Regular/Extra Small"
            >
              This equity incentive will require a subplan and does have some
              extra costs, <FormattedUSD value={1999} />, in case we need to set
              up the subplan. If you wish to benefit from this equity incentive,
              please{" "}
              <a
                href={`mailto:${supportEmailAddress}?subject=${mailtoSubject}`}
              >
                <Typography
                  className="text-primary"
                  variant="Medium/Extra Small"
                >
                  contact us
                </Typography>
              </a>
              .
            </Typography>
          </div>
        ))}
        {nonTaxFavoredEquityTypes.map((equityType) => {
          return (
            <div className="space-y-4" key={equityType.id}>
              <EquityTypeDescription equityTypeFragment={equityType} />
              <NoticeMessage size="Small" variant="Warning">
                For direct employees, you need to have a local subsidiary in
                this geography.
              </NoticeMessage>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const HEADER_GEOGRAPHY_FRAGMENT = graphql`
  fragment LockedGeographySlideOver_Header_Geography on Geography {
    country {
      name
      emoji
    }
  }
`;

const Header: React.FC<{
  geographyFragment: LockedGeographySlideOver_Header_Geography$key;
  onClose: React.ComponentProps<typeof SlideOver.NotionHeader>["onClose"];
}> = ({ geographyFragment, onClose }) => {
  const geography = useFragment(HEADER_GEOGRAPHY_FRAGMENT, geographyFragment);

  return (
    <SlideOver.NotionHeader
      backgroundImage={globeImage}
      emoji={geography.country.emoji}
      onClose={onClose}
      subtitle={geography.country.name}
    >
      Geography
    </SlideOver.NotionHeader>
  );
};

const QUERY = graphql`
  query LockedGeographySlideOverWithQuery_Query(
    $organizationId: OrganizationId!
    $countryCode: String!
  ) {
    organization(id: $organizationId) @required(action: THROW) {
      ...LockedGeographySlideOver_Content_Organization
    }
    geographyWithOrganizationSpecificPrices(
      countryCode: $countryCode
      organizationId: $organizationId
    ) @required(action: THROW) {
      geography {
        ...LockedGeographySlideOver_Header_Geography
      }
      ...LockedGeographySlideOver_Content_GeographyWithPrice
    }
  }
`;

export function LockedGeographySlideOver({
  countryCode,
  onClose,
  organizationId,
  show,
}: {
  countryCode: string;
  onClose: () => void;
  organizationId: string;
  show: boolean;
}) {
  const { query } = useQuery<LockedGeographySlideOverWithQuery_Query>(QUERY, {
    countryCode,
    organizationId,
  });

  return (
    <SlideOver
      header={
        <Header
          geographyFragment={
            query.geographyWithOrganizationSpecificPrices.geography
          }
          onClose={onClose}
        />
      }
      onClose={onClose}
      show={show}
      width="4xl"
    >
      <Content
        geographyWithPriceFragment={
          query.geographyWithOrganizationSpecificPrices
        }
        organizationFragment={query.organization}
      />
    </SlideOver>
  );
}

export const LockedGeographySlideOverRemoteController = makeRemoteController<{
  countryCode: string;
  organizationId: string;
}>({
  render: ({ close, state }) => {
    if (!state.data) {
      return null;
    }

    return (
      <LockedGeographySlideOver
        countryCode={state.data.countryCode}
        onClose={close}
        organizationId={state.data.organizationId}
        show={state.show}
      />
    );
  },
});
