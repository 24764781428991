import { IconV2DuotoneBriefcase } from "@remote-com/norma/icons/IconV2DuotoneBriefcase";
import { IconV2DuotoneFilesText } from "@remote-com/norma/icons/IconV2DuotoneFilesText";
import { IconV2DuotoneFolderUpload } from "@remote-com/norma/icons/IconV2DuotoneFolderUpload";
import { IconV2DuotoneGlobe } from "@remote-com/norma/icons/IconV2DuotoneGlobe";
import { IconV2OutlineBriefcase } from "@remote-com/norma/icons/IconV2OutlineBriefcase";
import { IconV2OutlineFilesText } from "@remote-com/norma/icons/IconV2OutlineFilesText";
import { IconV2OutlineFolderUpload } from "@remote-com/norma/icons/IconV2OutlineFolderUpload";
import { IconV2OutlineGlobe } from "@remote-com/norma/icons/IconV2OutlineGlobe";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { generatePath, Outlet } from "react-router-dom";
import { graphql } from "relay-runtime";

import { ApplicationSideBar } from "../../components/ui/ApplicationSideBar/ApplicationSideBar";
import { AnalyticsContextProvider } from "../../hooks/useAnalytics";
import { ApplicationThemeContext } from "../../hooks/useApplicationTheme";
import { FeatureFlagProvider } from "../../hooks/useIsFeatureEnabled";
import { APPLICATION_ROUTES } from "../../paths";
import { useAuthentication } from "../../services/AuthenticationProvider";
import { SuperAdminLayout_Query } from "./__generated__/SuperAdminLayout_Query.graphql";
import { SuperAdminLayout_Viewer$key } from "./__generated__/SuperAdminLayout_Viewer.graphql";

const VIEWER_FRAGMENT = graphql`
  fragment SuperAdminLayout_Viewer on Account {
    ...ApplicationSideBar_Viewer
  }
`;

const SuperAdminLayout_: React.FC<{
  viewerFragment: SuperAdminLayout_Viewer$key;
}> = ({ viewerFragment }) => {
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  return (
    <ApplicationSideBar.Layout>
      <ApplicationSideBar
        activeRole={null}
        className="flex-shrink-0"
        homePath={generatePath(APPLICATION_ROUTES.roleSelection)}
        organizationFragment={null}
        viewerFragment={viewer}
      >
        <ApplicationSideBar.Section title="Admin & content">
          <ApplicationSideBar.NavLink
            icons={{
              ActiveIcon: <IconV2DuotoneBriefcase />,
              Icon: <IconV2OutlineBriefcase />,
            }}
            to={generatePath(APPLICATION_ROUTES.superAdminOrganizationAdmin)}
          >
            Organizations
          </ApplicationSideBar.NavLink>
          <ApplicationSideBar.NavLink
            icons={{
              ActiveIcon: <IconV2DuotoneGlobe />,
              Icon: <IconV2OutlineGlobe />,
            }}
            to={generatePath(APPLICATION_ROUTES.superAdminLegalContent)}
          >
            Legal content
          </ApplicationSideBar.NavLink>
        </ApplicationSideBar.Section>
        <ApplicationSideBar.Section title="Tools">
          <ApplicationSideBar.NavLink
            icons={{
              ActiveIcon: <IconV2DuotoneFilesText />,
              Icon: <IconV2OutlineFilesText />,
            }}
            to={generatePath(APPLICATION_ROUTES.superAdminBulkUploadDrafts)}
          >
            Draft in bulk
          </ApplicationSideBar.NavLink>
          <ApplicationSideBar.NavLink
            icons={{
              ActiveIcon: <IconV2DuotoneFolderUpload />,
              Icon: <IconV2OutlineFolderUpload />,
            }}
            to={generatePath(APPLICATION_ROUTES.superAdminDocumentsUpload)}
          >
            Upload documents
          </ApplicationSideBar.NavLink>
        </ApplicationSideBar.Section>
      </ApplicationSideBar>
      <div className="relative h-full flex-auto overflow-auto">
        <Outlet />
      </div>
    </ApplicationSideBar.Layout>
  );
};

const QUERY = graphql`
  query SuperAdminLayout_Query {
    me {
      isSuperAdmin
      ...SuperAdminLayout_Viewer
    }
  }
`;

const SuperAdminLayout: React.FC = () => {
  const { me: viewer } = useLazyLoadQuery<SuperAdminLayout_Query>(QUERY, {});
  const { account } = useAuthentication();

  if (!viewer.isSuperAdmin) {
    throw new Error("Not authorized");
  }

  return (
    <FeatureFlagProvider account={account}>
      <AnalyticsContextProvider organizationId={null}>
        <ApplicationThemeContext.Provider value={"remote equity"}>
          <SuperAdminLayout_ viewerFragment={viewer} />
        </ApplicationThemeContext.Provider>
      </AnalyticsContextProvider>
    </FeatureFlagProvider>
  );
};

export default SuperAdminLayout;
