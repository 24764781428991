/**
 * @generated SignedSource<<b67593ead9438dbbd1d02fbcb968cb92>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmployeeDetailsTopBarActions_Organization$data = {
  readonly granteePortalInvitationIsAllowed: boolean;
  readonly id: string;
  readonly isOriginatingFromRemoteEquity: boolean;
  readonly isRemoteEquityEssentials: boolean;
  readonly planIsFreemium: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"DraftGrantButton_Organization" | "GranteeFormSlide_Organization">;
  readonly " $fragmentType": "EmployeeDetailsTopBarActions_Organization";
};
export type EmployeeDetailsTopBarActions_Organization$key = {
  readonly " $data"?: EmployeeDetailsTopBarActions_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeeDetailsTopBarActions_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmployeeDetailsTopBarActions_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOriginatingFromRemoteEquity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRemoteEquityEssentials",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planIsFreemium",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "granteePortalInvitationIsAllowed",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DraftGrantButton_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeFormSlide_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "a323d52d87ebc0f397fc9358b51dbe4e";

export default node;
