import React, { startTransition, useCallback, useEffect } from "react";
import { useRefetchableFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { Page } from "../../../components/Page";
import { RefreshingExercisesRequestNoticeMessage } from "../../../components/RefreshingExercisesRequestNoticeMessage";
import {
  usePollJobState,
  usePollJobStateHookState,
} from "../../../hooks/usePollJobState";
import { useQuery } from "../../../hooks/useQuery";
import { useSafeMutation } from "../../../hooks/useSafeMutation";
import { useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { Exercises_Organization$key } from "./__generated__/Exercises_Organization.graphql";
import { Exercises_Query } from "./__generated__/Exercises_Query.graphql";
import { Exercises_RefreshOrganizationCTMSExerciseRequests_Mutation } from "./__generated__/Exercises_RefreshOrganizationCTMSExerciseRequests_Mutation.graphql";
import { ExercisesView } from "./ExercisesView";

const ORGANIZATION_FRAGMENT = graphql`
  fragment Exercises_Organization on Organization
  @refetchable(queryName: "Exercises_Organization_RefetchQuery") {
    id
    ctmsExerciseRequestsAreOutdated
    isConnectedToCTMS
    ...ExercisesView_Organization
  }
`;

const REFRESH_EXERCISES_REQUESTS_MUTATION = graphql`
  mutation Exercises_RefreshOrganizationCTMSExerciseRequests_Mutation(
    $organizationId: OrganizationId!
  ) {
    startRefreshOrganizationCTMSExerciseRequests(
      organizationId: $organizationId
    ) {
      id
      queue
      state
    }
  }
`;

const AdminExercisesPage_: React.FC<{
  exerciseStatus: "Cancelled" | "Done" | "InProgress";
  organizationFragment: Exercises_Organization$key;
}> = ({ exerciseStatus, organizationFragment }) => {
  const [organization, refetchOrganization] = useRefetchableFragment(
    ORGANIZATION_FRAGMENT,
    organizationFragment,
  );

  const [
    _refreshOrganizationCTMSExerciseRequests,
    refreshOrganizationCTMSExerciseRequestsMutationIsInFlight,
  ] =
    useSafeMutation<Exercises_RefreshOrganizationCTMSExerciseRequests_Mutation>(
      REFRESH_EXERCISES_REQUESTS_MUTATION,
    );

  const { hookState, startPolling } = usePollJobStateHookState();

  const { jobState } = usePollJobState({
    hookState,
    onJobDone: () => {
      startTransition(() => {
        refetchOrganization({});
      });
    },
  });

  const refreshOrganizationCTMSExerciseRequests = useCallback(async () => {
    const { startRefreshOrganizationCTMSExerciseRequests: job } =
      await _refreshOrganizationCTMSExerciseRequests({
        variables: { organizationId: organization.id },
      });

    startPolling({
      jobId: job.id,
      jobQueue: job.queue,
    });
  }, [organization.id, _refreshOrganizationCTMSExerciseRequests, startPolling]);

  useEffect(() => {
    if (
      organization.isConnectedToCTMS &&
      organization.ctmsExerciseRequestsAreOutdated
    ) {
      void refreshOrganizationCTMSExerciseRequests();
    }
  }, [
    refreshOrganizationCTMSExerciseRequests,
    organization.ctmsExerciseRequestsAreOutdated,
    organization.isConnectedToCTMS,
  ]);

  return (
    <div className="space-y-6">
      {hookState.polling ||
      refreshOrganizationCTMSExerciseRequestsMutationIsInFlight ||
      jobState ? (
        <RefreshingExercisesRequestNoticeMessage
          refreshingExercisesRequestJobState={jobState ?? "Active"}
        />
      ) : null}
      <ExercisesView
        exerciseStatus={exerciseStatus}
        organizationFragment={organization}
      />
    </div>
  );
};

const QUERY = graphql`
  query Exercises_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...Exercises_Organization
    }
  }
`;

const AdminExercisesPage: React.FC<{
  exerciseStatus: "Cancelled" | "Done" | "InProgress";
}> = ({ exerciseStatus }) => {
  const organizationId = useOrganizationIdParam();
  const {
    query: { organization },
  } = useQuery<Exercises_Query>(QUERY, {
    organizationId,
  });

  if (!organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Exercises"
      analyticsName="Admin - Exercises"
      organizationId={organization.id}
      title={`Admin | ${organization.name} exercises`}
    >
      <AdminExercisesPage_
        exerciseStatus={exerciseStatus}
        organizationFragment={organization}
      />
    </Page>
  );
};

export default AdminExercisesPage;
