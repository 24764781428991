import { REMOTE_COLORS } from "../constants/colors";

const RAW_REMOTE_COLORS = {
  blueLight: REMOTE_COLORS.blueLight[600],
  brand: REMOTE_COLORS.brand[400],
  cyan: REMOTE_COLORS.cyan[600],
  fuchsia: REMOTE_COLORS.fuchsia[400],
  green: REMOTE_COLORS.green[600],
  greenLight: REMOTE_COLORS.greenLight[600],
  orange: REMOTE_COLORS.orange[400],
  pink: REMOTE_COLORS.pink[400],
  purple: REMOTE_COLORS.purple[500],
  red: REMOTE_COLORS.red[400],
  yellow: REMOTE_COLORS.yellow[600],
};

export function getRandomRemoteColor({ seed }: { seed: string }) {
  const colors = Object.values(RAW_REMOTE_COLORS);

  const index =
    Math.abs(
      seed.split("").reduce((acc, char) => {
        return acc + char.charCodeAt(0);
      }, 0),
    ) % colors.length;

  const color = colors[index];

  if (!color) {
    throw new Error(`Unexpectedly found no color for index ${index}`);
  }

  return color;
}
