/**
 * @generated SignedSource<<51d1d84315c47f89a2d14ec044a280b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CTMSSolution = "ANGELLIST" | "ASTRELLA" | "CAPDESK" | "CARTA" | "CERTENT" | "COMPTRACK" | "EQUIFY" | "GLOBAL_SHARES" | "LEDGY" | "LTSE_EQUITY" | "NO_CTMS" | "OTHER" | "PULLEY" | "SHAREWORKS" | "SPREADSHEETS" | "VESTD";
import { FragmentRefs } from "relay-runtime";
export type Header_EssentialsOverview_Organization$data = {
  readonly declaredCTMSSolution: CTMSSolution | null;
  readonly hasBeenOnboarded: boolean;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"CartaIntegrationBanner_Organization">;
  readonly " $fragmentType": "Header_EssentialsOverview_Organization";
};
export type Header_EssentialsOverview_Organization$key = {
  readonly " $data"?: Header_EssentialsOverview_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"Header_EssentialsOverview_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Header_EssentialsOverview_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasBeenOnboarded",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "declaredCTMSSolution",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CartaIntegrationBanner_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "a90f6a6947198c76e05367b70e5a4808";

export default node;
