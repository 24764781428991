/**
 * @generated SignedSource<<0bfc17cdb358391c209a5b529596ee43>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PortalSection_RevokeAccessToGranteePortalMutation$variables = {
  granteeId: string;
};
export type PortalSection_RevokeAccessToGranteePortalMutation$data = {
  readonly revokeAccessToGranteePortal: {
    readonly " $fragmentSpreads": FragmentRefs<"PortalSection_Employee">;
  };
};
export type PortalSection_RevokeAccessToGranteePortalMutation = {
  response: PortalSection_RevokeAccessToGranteePortalMutation$data;
  variables: PortalSection_RevokeAccessToGranteePortalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "granteeId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "granteeId",
    "variableName": "granteeId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PortalSection_RevokeAccessToGranteePortalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Grantee",
        "kind": "LinkedField",
        "name": "revokeAccessToGranteePortal",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PortalSection_Employee"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PortalSection_RevokeAccessToGranteePortalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Grantee",
        "kind": "LinkedField",
        "name": "revokeAccessToGranteePortal",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasPortalAccess",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cannotBeInvitedReason",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "organizationId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d18877de87c4fe70e7173adea5103eef",
    "id": null,
    "metadata": {},
    "name": "PortalSection_RevokeAccessToGranteePortalMutation",
    "operationKind": "mutation",
    "text": "mutation PortalSection_RevokeAccessToGranteePortalMutation(\n  $granteeId: GranteeId!\n) {\n  revokeAccessToGranteePortal(granteeId: $granteeId) {\n    ...PortalSection_Employee\n    id\n  }\n}\n\nfragment PortalSection_Employee on Grantee {\n  id\n  hasPortalAccess\n  cannotBeInvitedReason\n  organizationId\n}\n"
  }
};
})();

(node as any).hash = "a737dbeb005c946af75b103db45e4fc0";

export default node;
