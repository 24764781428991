import classNames from "classnames";
import { differenceInDays } from "date-fns";
import { chain, isEmpty } from "lodash";
import React, { useCallback, useMemo } from "react";
import { FormattedNumber } from "react-intl";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";

import { BackButton } from "../../../../components/BackButton";
import { FormattedCurrency } from "../../../../components/Formatted/FormattedCurrency";
import { FormattedFMV } from "../../../../components/FormattedFMV";
import { Page } from "../../../../components/Page";
import { ShortDate } from "../../../../components/ShortDate";
import { BreadCrumb } from "../../../../components/ui/BreadCrumb";
import { Button } from "../../../../components/ui/Button";
import { OneColumnLayout } from "../../../../components/ui/Layout/OneColumnLayout";
import { NoticeMessage } from "../../../../components/ui/NoticeMessage";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { Tab } from "../../../../components/ui/Tab";
import { Table } from "../../../../components/ui/Table";
import { Typography } from "../../../../components/ui/Typography";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import {
  EquityAlertingPage_Organization$data,
  EquityAlertingPage_Organization$key,
} from "./__generated__/EquityAlertingPage_Organization.graphql";
import { EquityAlertingPage_Query } from "./__generated__/EquityAlertingPage_Query.graphql";
import { EquityAlertingPage_SetEquityAlertingMuted_Mutation } from "./__generated__/EquityAlertingPage_SetEquityAlertingMuted_Mutation.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment EquityAlertingPage_Organization on Organization
  @relay(plural: true) {
    id
    name
    terminated
    mismatchBetweenCtmsAndEasopCapTable
    fullyDilutedShares
    postSafeConversionFullyDilutedSharesDelta
    ctmsFullyDilutedShares
    poolAvailableShares(includingPendingShares: false)
    ctmsReportedAvailableEsopPoolShares
    easopSafeCount
    ctmsSafeCount
    easopSafeTotalAmount
    ctmsSafeTotalAmount
    easopWarrantTotalAmount
    ctmsWarrantTotalAmount
    equityAlertingMuted
    ctmsLatestFMVEffectiveDate
    ctmsLatestFMVValue
    latestFairMarketValue {
      date
      value
    }
    ...FormattedCurrency_Organization
    ...FormattedFMV_Organization
  }
`;

const SET_EQUITY_ALERTING_MUTED_MUTATION = graphql`
  mutation EquityAlertingPage_SetEquityAlertingMuted_Mutation(
    $muted: Boolean!
    $organizationId: OrganizationId!
  ) {
    setOrganizationEquityAlertingMuted(
      muted: $muted
      organizationId: $organizationId
    ) {
      equityAlertingMuted
    }
  }
`;

const EasopCartaValueDiffCell: React.FC<{
  cartaValue: null | number;
  currency?: boolean;
  easopValue: null | number;
  organization: EquityAlertingPage_Organization$data[number];
}> = ({ cartaValue, currency, easopValue, organization }) => {
  const formattedValue = useCallback(
    (value: number, { signDisplay }: { signDisplay?: "always" } = {}) => {
      if (currency) {
        return (
          <FormattedCurrency
            organizationFragment={organization}
            signDisplay={signDisplay}
            value={value}
          />
        );
      } else {
        return <FormattedNumber signDisplay={signDisplay} value={value} />;
      }
    },
    [currency, organization],
  );

  return (
    <Table.Cell>
      <div>
        <div>
          <span
            className={classNames({
              "font-medium text-red":
                cartaValue !== null && easopValue !== cartaValue,
            })}
          >
            {easopValue !== null ? formattedValue(easopValue) : "N/A"}
          </span>
          <span className="text-gray-07">
            {" "}
            / {cartaValue !== null ? formattedValue(cartaValue) : "N/A"}
          </span>
        </div>
        <div>
          {easopValue !== null &&
            cartaValue !== null &&
            easopValue !== cartaValue && (
              <span>
                {" "}
                (
                {formattedValue(easopValue - cartaValue, {
                  signDisplay: "always",
                })}
                )
              </span>
            )}
        </div>
      </div>
    </Table.Cell>
  );
};

const EasopCartaFMVDiffCell: React.FC<{
  organization: EquityAlertingPage_Organization$data[number];
}> = ({ organization }) => {
  const easopLatestFairMarketValueIsOutdated =
    organization.ctmsLatestFMVEffectiveDate &&
    (!organization.latestFairMarketValue ||
      differenceInDays(
        new Date(organization.ctmsLatestFMVEffectiveDate),
        new Date(organization.latestFairMarketValue.date),
      ) > 0);
  return (
    <Table.Cell>
      <span
        className={classNames({
          "font-medium text-red": easopLatestFairMarketValueIsOutdated,
        })}
      >
        {organization.latestFairMarketValue ? (
          <>
            <ShortDate value={organization.latestFairMarketValue.date} /> -{" "}
            <FormattedFMV
              organizationFragment={organization}
              value={organization.latestFairMarketValue.value}
            />
          </>
        ) : (
          "N/A"
        )}
      </span>
      <br />
      <span className="text-gray-07">
        {organization.ctmsLatestFMVEffectiveDate ? (
          organization.ctmsLatestFMVValue ? (
            <>
              <ShortDate value={organization.ctmsLatestFMVEffectiveDate} /> -{" "}
              <FormattedFMV
                organizationFragment={organization}
                value={organization.ctmsLatestFMVValue}
              />
            </>
          ) : (
            <ShortDate value={organization.ctmsLatestFMVEffectiveDate} />
          )
        ) : (
          "N/A"
        )}
      </span>
    </Table.Cell>
  );
};

const EquityAlertingTable: React.FC<{
  organizations: EquityAlertingPage_Organization$data;
}> = ({ organizations }) => {
  const [_setOrganizationEquityAlertingMuted, mutationIsInFlight] =
    useSafeMutation<EquityAlertingPage_SetEquityAlertingMuted_Mutation>(
      SET_EQUITY_ALERTING_MUTED_MUTATION,
    );

  const toggleOrganizationEquityAlertingMuted = useCallback(
    async (organization: EquityAlertingPage_Organization$data[number]) => {
      await _setOrganizationEquityAlertingMuted({
        variables: {
          muted: !organization.equityAlertingMuted,
          organizationId: organization.id,
        },
      });
    },
    [_setOrganizationEquityAlertingMuted],
  );

  return isEmpty(organizations) ? (
    <NoticeMessage size="Small" variant="Positive">
      All organizations are ok
    </NoticeMessage>
  ) : (
    <Table className="w-full">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Organization</Table.HeaderCell>
          <Table.HeaderCell>Fully diluted shares</Table.HeaderCell>
          <Table.HeaderCell>Available shares</Table.HeaderCell>
          <Table.HeaderCell>Safes count</Table.HeaderCell>
          <Table.HeaderCell>Safes amount</Table.HeaderCell>
          <Table.HeaderCell>Warrant amount</Table.HeaderCell>
          <Table.HeaderCell>409A valuation</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {organizations.map((organization) => (
          <Table.Row key={organization.id}>
            <Table.Cell>
              {organization.equityAlertingMuted ? (
                <span>🔕 {organization.name}</span>
              ) : (
                <strong>⛔️ {organization.name}</strong>
              )}
            </Table.Cell>
            <EasopCartaValueDiffCell
              cartaValue={organization.ctmsFullyDilutedShares}
              easopValue={
                organization.fullyDilutedShares !== null
                  ? organization.fullyDilutedShares -
                    (organization.postSafeConversionFullyDilutedSharesDelta ??
                      0)
                  : null
              }
              organization={organization}
            />
            <EasopCartaValueDiffCell
              cartaValue={organization.ctmsReportedAvailableEsopPoolShares}
              easopValue={organization.poolAvailableShares}
              organization={organization}
            />
            <EasopCartaValueDiffCell
              cartaValue={organization.ctmsSafeCount}
              easopValue={organization.easopSafeCount ?? 0}
              organization={organization}
            />
            <EasopCartaValueDiffCell
              cartaValue={organization.ctmsSafeTotalAmount}
              currency
              easopValue={organization.easopSafeTotalAmount ?? 0}
              organization={organization}
            />
            <EasopCartaValueDiffCell
              cartaValue={organization.ctmsWarrantTotalAmount}
              currency
              easopValue={organization.easopWarrantTotalAmount ?? 0}
              organization={organization}
            />
            <EasopCartaFMVDiffCell organization={organization} />
            <Table.Cell className="w-[100px] text-right">
              <Button
                loading={mutationIsInFlight}
                onClick={() =>
                  toggleOrganizationEquityAlertingMuted(organization)
                }
                size="extra small"
                type="button"
                variant="Secondary Outline"
              >
                {organization.equityAlertingMuted ? "Unmute" : "Mute"}
              </Button>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

const EquityAlertingPage_: React.FC<{
  organizationsFragment: EquityAlertingPage_Organization$key;
}> = ({ organizationsFragment }) => {
  const organizations = useFragment(
    ORGANIZATION_FRAGMENT,
    organizationsFragment,
  );

  const [mutedOrganizations, outOfSyncOrganizations] = useMemo(
    () =>
      chain(organizations)
        .filter(
          ({ mismatchBetweenCtmsAndEasopCapTable, terminated }) =>
            mismatchBetweenCtmsAndEasopCapTable && !terminated,
        )
        .sortBy("name")
        .partition("equityAlertingMuted")
        .value(),
    [organizations],
  );

  return (
    <OneColumnLayout
      Breadcrumb={
        <BreadCrumb>
          <BreadCrumb.Link to="..">Organization admin</BreadCrumb.Link>
          <BreadCrumb.Link to=".">Equity alerting</BreadCrumb.Link>
        </BreadCrumb>
      }
      showFooter
    >
      <RoundedBox className="space-y-4 p-6" withBorder withShadow>
        <div className="flex items-center gap-4">
          <BackButton />
          <Typography variant="Medium/Default">
            Check equity alerting
          </Typography>
        </div>
        <Tab.Group>
          <Tab.List>
            <Tab.WithCount count={outOfSyncOrganizations.length}>
              Out of sync organizations
            </Tab.WithCount>
            <Tab.WithCount count={mutedOrganizations.length}>
              Muted organizations
            </Tab.WithCount>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <EquityAlertingTable organizations={outOfSyncOrganizations} />
            </Tab.Panel>
            <Tab.Panel>
              <EquityAlertingTable organizations={mutedOrganizations} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </RoundedBox>
    </OneColumnLayout>
  );
};

const QUERY = graphql`
  query EquityAlertingPage_Query {
    organizations {
      ...EquityAlertingPage_Organization
    }
  }
`;

const EquityAlertingPage: React.FC = () => {
  const { organizations } = useLazyLoadQuery<EquityAlertingPage_Query>(
    QUERY,
    {},
  );
  return (
    <Page
      analyticsName="Super Admin - Equity Alerting"
      title={`Super admin | equity alerting`}
    >
      <EquityAlertingPage_ organizationsFragment={organizations} />
    </Page>
  );
};

export default EquityAlertingPage;
