import classNames from "classnames";
import { motion } from "motion/react";
import React, { useMemo } from "react";

import { cx } from "../../helpers/cva.config";

function getFlexBasis(value: number, max: number) {
  if (value === 0) {
    return 0;
  }

  return `${(value / max) * 100}%`;
}

function useFlexBasis(value: number, max: number) {
  return useMemo(() => getFlexBasis(value, max), [value, max]);
}

function useProgressContext() {
  const context = React.useContext(ProgressContext);

  if (!context) {
    throw new Error("Progress.Value components must be used within a Progress");
  }

  return context;
}

const Value: React.FC<{
  className?: string;
  initialValue?: number;
  value: number;
}> = ({ className, initialValue = 0, value }) => {
  const { max } = useProgressContext();
  const initialFlexBasis = useFlexBasis(initialValue, max);
  const flexBasis = useFlexBasis(value, max);

  return (
    <motion.div
      animate={{
        flexBasis,
      }}
      className={classNames(className, "h-full rounded-3xl", {
        hidden: value === 0,
      })}
      initial={{
        flexBasis: initialFlexBasis,
      }}
    />
  );
};

const ProgressContext = React.createContext<null | {
  max: number;
}>(null);

const _Progress: React.FC<
  React.PropsWithChildren<{
    className?: string;
    max: number;
    skeleton?: boolean;
  }>
> = ({ className, max, skeleton, ...props }) => (
  <ProgressContext.Provider value={{ max }}>
    <div
      className={cx(
        "flex h-2 gap-[3px] overflow-hidden rounded-3xl",
        {
          "animate-pulse": skeleton,
        },
        className,
      )}
      {...props}
    />
  </ProgressContext.Provider>
);

export const Progress = Object.assign(_Progress, { Value });
