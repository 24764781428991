import { zodResolver } from "@hookform/resolvers/zod";
import { useId } from "react";
import { DeepPartial, useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { z } from "zod";

import {
  BentoCard,
  BentoCardCell,
  BentoCardRow,
  BentoCardValue,
} from "../../../components/BentoCard";
import { CurrencyUnit } from "../../../components/CurrencyUnit";
import { FormattedCurrencyCompact } from "../../../components/Formatted/FormattedCurrencyCompact";
import { FormattedNumber } from "../../../components/Formatted/FormattedNumber";
import { FormattedFMV } from "../../../components/FormattedFMV";
import { Page } from "../../../components/Page";
import { Button } from "../../../components/ui/Button";
import { RemoteInputCurrency } from "../../../components/ui/Form/Inputs/RemoteInputCurrency";
import { RemoteInputText } from "../../../components/ui/Form/Inputs/RemoteInputText";
import { LargeOneColumnLayout } from "../../../components/ui/Layout/LargeOneColumnLayout";
import { useQuery } from "../../../hooks/useQuery";
import { useSafeMutation } from "../../../hooks/useSafeMutation";
import { useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { PlanParametersPage_Organization$key } from "./__generated__/PlanParametersPage_Organization.graphql";
import { PlanParametersPage_Query } from "./__generated__/PlanParametersPage_Query.graphql";
import { PlanParametersPage_UpdateEquityPlanParametersForEssentialsOrganization_Mutation } from "./__generated__/PlanParametersPage_UpdateEquityPlanParametersForEssentialsOrganization_Mutation.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment PlanParametersPage_Organization on Organization {
    id
    name
    issuingCompany {
      name
    }
    latestValuation
    fullyDilutedShares
    esopPoolShares
    latestPricePerShare {
      value
    }
    ...LargeOneColumnLayout_Organization
    ...CurrencyUnit_Organization
    ...FormattedFMV_Organization
    ...FormattedCurrencyCompact_Organization
    ...RemoteInputCurrency_Organization
  }
`;

const UPDATE_EQUITY_PLAN_PARAMETERS_FOR_ESSENTIALS_ORGANIZATION_MUTATION = graphql`
  mutation PlanParametersPage_UpdateEquityPlanParametersForEssentialsOrganization_Mutation(
    $attributes: UpdateEquityPlanParametersAttributes!
    $organizationId: OrganizationId!
  ) {
    updateEquityPlanParametersForEssentialsOrganization(
      attributes: $attributes
      organizationId: $organizationId
    ) {
      latestValuation
      fullyDilutedShares
      esopPoolShares
      latestPricePerShare {
        value
      }
    }
  }
`;

const FORM_SCHEMA = z.object({
  esopPoolShares: z.coerce.number().int().positive(),
  fullyDilutedShares: z.coerce.number().int().positive(),
  pricePerShareValue: z.coerce.number().positive(),
});

type FormInput = DeepPartial<z.input<typeof FORM_SCHEMA>>;
type FormOutput = z.output<typeof FORM_SCHEMA>;

const PlanParametersPage_: React.FC<{
  organizationFragment: PlanParametersPage_Organization$key;
}> = ({ organizationFragment }) => {
  const formId = useId();
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const form = useForm<FormInput, unknown, FormOutput>({
    defaultValues: {
      esopPoolShares: organization.esopPoolShares ?? undefined,
      fullyDilutedShares: organization.fullyDilutedShares ?? undefined,
      pricePerShareValue: organization.latestPricePerShare?.value ?? undefined,
    },
    resolver: zodResolver(FORM_SCHEMA),
  });

  const [
    updateEquityPlanParametersForEssentialsOrganization,
    mutationIsInFlight,
  ] =
    useSafeMutation<PlanParametersPage_UpdateEquityPlanParametersForEssentialsOrganization_Mutation>(
      UPDATE_EQUITY_PLAN_PARAMETERS_FOR_ESSENTIALS_ORGANIZATION_MUTATION,
    );

  const handleFormSubmit = form.handleSubmit(async (data) => {
    await updateEquityPlanParametersForEssentialsOrganization({
      variables: {
        attributes: data,
        organizationId: organization.id,
      },
    });
  });

  return (
    <LargeOneColumnLayout
      maxWidth={1200}
      organizationFragment={organization}
      showFooter
      title="Plan parameters"
      topBarActionsRender={() => (
        <Button form={formId} loading={mutationIsInFlight} type="submit">
          Save
        </Button>
      )}
    >
      <div className="space-y-6">
        <BentoCard>
          <BentoCardRow>
            <BentoCardCell title="Issuing Company">
              <BentoCardValue main={organization.issuingCompany?.name ?? "-"} />
            </BentoCardCell>
            <BentoCardCell title="Company valuation">
              <BentoCardValue
                main={
                  organization.latestValuation ? (
                    <FormattedCurrencyCompact
                      organizationFragment={organization}
                      value={organization.latestValuation}
                    />
                  ) : (
                    "-"
                  )
                }
                sub={<CurrencyUnit organizationFragment={organization} />}
              />
            </BentoCardCell>
          </BentoCardRow>
          <BentoCardRow>
            <BentoCardCell title="Price per share">
              <BentoCardValue
                main={
                  organization.latestPricePerShare ? (
                    <FormattedFMV
                      organizationFragment={organization}
                      value={organization.latestPricePerShare.value}
                    />
                  ) : (
                    "-"
                  )
                }
                sub={<CurrencyUnit organizationFragment={organization} />}
              />
            </BentoCardCell>
            <BentoCardCell title="Fully diluted shares count">
              <BentoCardValue
                main={
                  organization.fullyDilutedShares ? (
                    <FormattedNumber value={organization.fullyDilutedShares} />
                  ) : (
                    "-"
                  )
                }
                sub="shares"
              />
            </BentoCardCell>
            <BentoCardCell title="Pool size">
              <BentoCardValue
                main={
                  organization.esopPoolShares ? (
                    <FormattedNumber value={organization.esopPoolShares} />
                  ) : (
                    "-"
                  )
                }
                sub="shares"
              />
            </BentoCardCell>
          </BentoCardRow>
        </BentoCard>
        <form id={formId} onSubmit={handleFormSubmit}>
          <div className="flex gap-6 [&>*]:flex-1">
            <RemoteInputCurrency.Form
              control={form.control}
              label="Price per share"
              name="pricePerShareValue"
              organizationFragment={organization}
            />
            <RemoteInputText.Form
              control={form.control}
              label="Fully diluted shares count"
              name="fullyDilutedShares"
              type="number"
            />
            <RemoteInputText.Form
              control={form.control}
              label="Pool size"
              name="esopPoolShares"
              type="number"
            />
          </div>
        </form>
      </div>
    </LargeOneColumnLayout>
  );
};

const QUERY = graphql`
  query PlanParametersPage_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...PlanParametersPage_Organization
    }
  }
`;

export const PlanParametersPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();

  const {
    query: { organization },
  } = useQuery<PlanParametersPage_Query>(QUERY, {
    organizationId,
  });

  if (!organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Admin Company Settings"
      analyticsName="Admin - Company Settings - Plan parameters"
      organizationId={organization.id}
      title={`Admin | ${organization.name} plan parameters`}
    >
      <PlanParametersPage_ organizationFragment={organization} />
    </Page>
  );
};
