/**
 * @generated SignedSource<<daf3ed1647a7451bc5dedf00c1c197b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FinishRemoteEquityOnboardingPage_Viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ConnectCTMSView_Viewer" | "MatchGranteeView_Viewer" | "SubmitPricePerShareView_Viewer" | "TestLawFirmCartaConnectionView_Viewer">;
  readonly " $fragmentType": "FinishRemoteEquityOnboardingPage_Viewer";
};
export type FinishRemoteEquityOnboardingPage_Viewer$key = {
  readonly " $data"?: FinishRemoteEquityOnboardingPage_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"FinishRemoteEquityOnboardingPage_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FinishRemoteEquityOnboardingPage_Viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConnectCTMSView_Viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SubmitPricePerShareView_Viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TestLawFirmCartaConnectionView_Viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MatchGranteeView_Viewer"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "1d503ac472b3d157c30b270e9b72c3ae";

export default node;
