/**
 * @generated SignedSource<<5f3f907d3bbdfe21db82acf27c65e67a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CartaIntegrationBanner_Organization$data = {
  readonly name: string;
  readonly " $fragmentType": "CartaIntegrationBanner_Organization";
};
export type CartaIntegrationBanner_Organization$key = {
  readonly " $data"?: CartaIntegrationBanner_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"CartaIntegrationBanner_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CartaIntegrationBanner_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "0411c63163ca23b9075a828465327988";

export default node;
