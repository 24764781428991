import { generatePath, Navigate } from "react-router-dom";
import { graphql } from "relay-runtime";

import { useQuery } from "../../../hooks/useQuery";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import { useCountryCodeParam } from "../../../paths";
import { EquityHandbooksCountryPage_Query } from "./__generated__/EquityHandbooksCountryPage_Query.graphql";
import { EquityHandbooksCountryEquityTypeEmptyState } from "./EquityHandbooksCountryEquityTypeEmptyState";

const QUERY = graphql`
  query EquityHandbooksCountryPage_Query(
    $organizationId: OrganizationId!
    $countryCode: String!
  ) {
    organizationGeography(
      countryCode: $countryCode
      organizationId: $organizationId
    ) {
      geography {
        equityTypes {
          id
        }
      }
    }
  }
`;

export default function EquityHandbooksCountryPage() {
  const organizationId = useOrganizationIdParam();
  const countryCode = useCountryCodeParam();
  const {
    query: { organizationGeography },
  } = useQuery<EquityHandbooksCountryPage_Query>(QUERY, {
    countryCode,
    organizationId,
  });

  const firstEquityType = organizationGeography?.geography.equityTypes[0];

  if (!firstEquityType) {
    return <EquityHandbooksCountryEquityTypeEmptyState />;
  }

  return (
    <Navigate
      replace
      to={generatePath(
        APPLICATION_ROUTES.organizationEquityHandbooksCountryEquityType,
        {
          countryCode,
          equityTypeId: firstEquityType.id,
          organizationId,
        },
      )}
    />
  );
}
