/**
 * @generated SignedSource<<a4db264f0aac1b07e7f9b4d9191eb08e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type RemoteOnboardingStep = "CLEAN_UP_GRANTEE_DATA" | "CONNECT_CTMS" | "ESSENTIALS_CTMS" | "ESSENTIALS_EQUITY_PLAN_PARAMETERS" | "ESSENTIALS_EXPECTATION" | "ESSENTIALS_FAIR_MARKET_VALUE" | "ESSENTIALS_ISSUING_COMPANY" | "ESSENTIALS_LAST_STEP" | "SET_PRICE_PER_SHARE" | "TEST_LAW_FIRM_CARTA_CONNECTION";
export type FairMarketValuePage_SetEssentialsFairMarketValueOnboardingStepAsCompleted_Mutation$variables = {
  fairMarketValue?: number | null;
  organizationId: string;
  valuationDate?: string | null;
};
export type FairMarketValuePage_SetEssentialsFairMarketValueOnboardingStepAsCompleted_Mutation$data = {
  readonly setEssentialsFairMarketValueOnboardingStepAsCompleted: {
    readonly currentRemoteOnboardingStep: RemoteOnboardingStep | null;
    readonly latestFairMarketValue: {
      readonly value: number;
    } | null;
  };
};
export type FairMarketValuePage_SetEssentialsFairMarketValueOnboardingStepAsCompleted_Mutation = {
  response: FairMarketValuePage_SetEssentialsFairMarketValueOnboardingStepAsCompleted_Mutation$data;
  variables: FairMarketValuePage_SetEssentialsFairMarketValueOnboardingStepAsCompleted_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fairMarketValue"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "valuationDate"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "fairMarketValue",
    "variableName": "fairMarketValue"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  },
  {
    "kind": "Variable",
    "name": "valuationDate",
    "variableName": "valuationDate"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currentRemoteOnboardingStep",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FairMarketValuePage_SetEssentialsFairMarketValueOnboardingStepAsCompleted_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "setEssentialsFairMarketValueOnboardingStepAsCompleted",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FairMarketValue",
            "kind": "LinkedField",
            "name": "latestFairMarketValue",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FairMarketValuePage_SetEssentialsFairMarketValueOnboardingStepAsCompleted_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "setEssentialsFairMarketValueOnboardingStepAsCompleted",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FairMarketValue",
            "kind": "LinkedField",
            "name": "latestFairMarketValue",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dad33eb90c9a9aa3b34b333bfdef7eaf",
    "id": null,
    "metadata": {},
    "name": "FairMarketValuePage_SetEssentialsFairMarketValueOnboardingStepAsCompleted_Mutation",
    "operationKind": "mutation",
    "text": "mutation FairMarketValuePage_SetEssentialsFairMarketValueOnboardingStepAsCompleted_Mutation(\n  $fairMarketValue: Float\n  $organizationId: OrganizationId!\n  $valuationDate: Date\n) {\n  setEssentialsFairMarketValueOnboardingStepAsCompleted(fairMarketValue: $fairMarketValue, organizationId: $organizationId, valuationDate: $valuationDate) {\n    currentRemoteOnboardingStep\n    latestFairMarketValue {\n      value\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "58a4a68b7a2713f0985fa7d58592ebd5";

export default node;
