/**
 * @generated SignedSource<<6f79504a00f7ca3da34f8a18c444ccbb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoteEquityOnboardingLayout_Viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"RemoteLikeApplicationLayout_Viewer">;
  readonly " $fragmentType": "RemoteEquityOnboardingLayout_Viewer";
};
export type RemoteEquityOnboardingLayout_Viewer$key = {
  readonly " $data"?: RemoteEquityOnboardingLayout_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteEquityOnboardingLayout_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteEquityOnboardingLayout_Viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemoteLikeApplicationLayout_Viewer"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "e5cf7fe0c6cb25c040e618038e502e5a";

export default node;
