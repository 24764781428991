/* eslint-disable @typescript-eslint/no-deprecated */
import { formatISO } from "date-fns";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useQuery } from "../hooks/useQuery";
import { VestingScheduleGraph_Organization$key } from "./__generated__/VestingScheduleGraph_Organization.graphql";
import { VestingScheduleGraph_VestingSchedule_Query } from "./__generated__/VestingScheduleGraph_VestingSchedule_Query.graphql";
import { Deprecated_VestingGraph } from "./Deprecated_VestingGraph";

const ORGANIZATION_FRAGMENT = graphql`
  fragment VestingScheduleGraph_Organization on Organization {
    id
  }
`;

const VESTING_SCHEDULE_QUERY = graphql`
  query VestingScheduleGraph_VestingSchedule_Query(
    $organizationId: OrganizationId!
    $vestingScheduleId: VestingScheduleId!
    $vestingStartDate: Date!
    $quantityGranted: Int!
  ) {
    vestingDataPointsFromVestingScheduleId(
      organizationId: $organizationId
      vestingScheduleId: $vestingScheduleId
      vestingStartDate: $vestingStartDate
      quantityGranted: $quantityGranted
    ) @required(action: THROW) {
      cumulativeVested
      date
    }
  }
`;

export const VestingScheduleGraph: React.FC<
  React.ComponentProps<"div"> & {
    dateDisplayMode: "absolute" | "relative";
    organizationFragment: VestingScheduleGraph_Organization$key;
    quantityGranted: number;
    vestingScheduleId: string;
    vestingStartDate: Date;
  }
> = ({
  dateDisplayMode,
  organizationFragment,
  quantityGranted,
  vestingScheduleId,
  vestingStartDate,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const {
    query: { vestingDataPointsFromVestingScheduleId: vestingDataPoints },
  } = useQuery<VestingScheduleGraph_VestingSchedule_Query>(
    VESTING_SCHEDULE_QUERY,
    {
      organizationId: organization.id,
      quantityGranted,
      vestingScheduleId,
      vestingStartDate: formatISO(vestingStartDate, {
        representation: "date",
      }),
    },
  );

  if (vestingDataPoints.length === 0) {
    return null;
  }

  return (
    <Deprecated_VestingGraph
      dateDisplayMode={dateDisplayMode}
      displayTodayLabel={false}
      showLegend={false}
      vestingsDataPoints={[
        {
          dataPoints: vestingDataPoints.map(({ cumulativeVested, date }) => ({
            cumulativeVested,
            date: new Date(date),
          })),
          key: "vesting_schedule_overview",
          label: "Vesting schedule preview",
        },
      ]}
      vestingStartDate={vestingDataPoints[0]?.date}
    />
  );
};
