/**
 * @generated SignedSource<<906fe21086cb76d4aa098644cb06dbf6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PriceEvolutionGraphForVSOP_Organization$data = {
  readonly latestFairMarketValue: {
    readonly value: number;
  } | null;
  readonly latestPricePerShare: {
    readonly value: number;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"MissingInformationTag_MissingCTMSInformationTag_Organization" | "PriceEvolutionGraph_StackTopLabelUsingFormattedCurrency_Organization" | "useCurrencyFormatter_Organization">;
  readonly " $fragmentType": "PriceEvolutionGraphForVSOP_Organization";
};
export type PriceEvolutionGraphForVSOP_Organization$key = {
  readonly " $data"?: PriceEvolutionGraphForVSOP_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"PriceEvolutionGraphForVSOP_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PriceEvolutionGraphForVSOP_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PricePerShare",
      "kind": "LinkedField",
      "name": "latestPricePerShare",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FairMarketValue",
      "kind": "LinkedField",
      "name": "latestFairMarketValue",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MissingInformationTag_MissingCTMSInformationTag_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useCurrencyFormatter_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PriceEvolutionGraph_StackTopLabelUsingFormattedCurrency_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "e65dee3869e847a3235b48322da25cf2";

export default node;
