import { graphql, useFragment } from "react-relay";

import { FormattedFMV_Organization$key } from "./__generated__/FormattedFMV_Organization.graphql";
import { FormattedCurrency } from "./Formatted/FormattedCurrency";

const ORGANIZATION_FRAGMENT = graphql`
  fragment FormattedFMV_Organization on IOrganization {
    ...FormattedCurrency_Organization
  }
`;

export const FormattedFMV: React.FC<{
  organizationFragment: FormattedFMV_Organization$key;
  value: number;
}> = ({ organizationFragment, value }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <FormattedCurrency
      maximumFractionDigits={value < 0.1 ? 4 : 2}
      organizationFragment={organization}
      value={value}
    />
  );
};
