import { StandaloneLink, Text } from "@remote-com/norma";
import { IconV2DuotonePlus } from "@remote-com/norma/icons/IconV2DuotonePlus";
import { graphql, useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";

import { FairMarketValueHistoryChart } from "../../../../components/FairMarketValueHistoryChart";
import { FormattedFMV } from "../../../../components/FormattedFMV";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { APPLICATION_ROUTES } from "../../../../paths";
import { FairMarketValueHistoryCard_EssentialsOverview_Organization$key } from "./__generated__/FairMarketValueHistoryCard_EssentialsOverview_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment FairMarketValueHistoryCard_EssentialsOverview_Organization on Organization {
    id
    latestFairMarketValue {
      value
    }
    ...FairMarketValueHistoryChart_Organization
    ...FormattedFMV_Organization
  }
`;

export const FairMarketValueHistoryCard: React.FC<{
  organizationFragment: FairMarketValueHistoryCard_EssentialsOverview_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  if (!organization.latestFairMarketValue) {
    return null;
  }

  return (
    <RoundedBox className="space-y-4 p-6" withBorder>
      <div className="flex items-center justify-between">
        <Text variant="lgMedium">Fair Market Value</Text>
        <StandaloneLink
          as={Link}
          IconBefore={IconV2DuotonePlus}
          size="sm"
          to={generatePath(
            APPLICATION_ROUTES.organizationCompanySettingsAddNewFairMarketValue,
            {
              organizationId: organization.id,
            },
          )}
        >
          Add new
        </StandaloneLink>
      </div>
      <Text variant="xlMedium">
        <FormattedFMV
          organizationFragment={organization}
          value={organization.latestFairMarketValue.value}
        />
      </Text>
      <FairMarketValueHistoryChart
        height={230}
        organizationFragment={organization}
      />
    </RoundedBox>
  );
};
