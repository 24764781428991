import { isEmpty } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { GraphQLTaggedNode } from "relay-runtime";

import { useBoolean } from "../hooks/useBoolean";
import { useDebouncedManualQuery } from "../hooks/useDebouncedManualQuery";
import { useManualQuery } from "../hooks/useManualQuery";
import { LoadingPlaceholder } from "./LoadingPlaceholder";
import { LoadMoreButton } from "./ui/LoadMoreButton";
import { SearchBar } from "./ui/SearchBar";
import { SlideOver } from "./ui/SlideOver";
import { Typography } from "./ui/Typography";

const ITEMS_PULLED_AT_A_TIME = 20;

interface GrantsListProps<TItem> {
  hideLoadMoreButton: boolean;
  items: TItem[];
  loading?: boolean;
  noItemsMessage: React.ReactNode;
  onLoadMoreClick: () => void;
  renderItems: (items: TItem[]) => React.ReactNode;
}

type LoadMoreListSlideOver<TItem> = SlideOverContent<TItem> & {
  initialized: boolean;
  onClose: () => void;
  state: State;
};

type SlideOverContent<TItem> = GrantsListProps<TItem> & {
  onSearchChange: (search: string) => void;
  search: string;
  searchBarPlaceholder: string;
};

type State =
  | {
      show: false;
      title?: null | React.ReactNode;
    }
  | {
      show: true;
      title?: null | React.ReactNode;
    };

export function LoadMoreListSlideOver<TItem>({
  initialized,
  onClose,
  state,
  ...props
}: LoadMoreListSlideOver<TItem>) {
  return (
    <SlideOver
      header={<SlideOver.Header onClose={onClose} padding={6} />}
      onClose={onClose}
      show={state.show}
      width="2xl"
    >
      <div className="space-y-6 p-6">
        {state.title && (
          <Typography
            as="div"
            className="w-full text-center"
            variant="Medium/Small"
          >
            {state.title}
          </Typography>
        )}
        {!initialized ? (
          <LoadingPlaceholder />
        ) : state.show ? (
          <SlideOverContent {...props} />
        ) : null}
      </div>
    </SlideOver>
  );
}

export function useLoadMoreSlideOverProperties<
  TQuery extends {
    response: {
      readonly result: {
        readonly items: readonly object[];
        readonly total: number;
      };
    };
    variables: {
      search?: null | string;
      skip: number;
      take: number;
    };
  },
>({
  QUERY,
  variables,
}: {
  QUERY: GraphQLTaggedNode;
  variables: null | Omit<TQuery["variables"], "search" | "skip" | "take">;
}) {
  const [_triggerDebouncedQuery, debouncedQueryIsInFlight] =
    useDebouncedManualQuery<TQuery>(QUERY, {
      fetchPolicy: "store-or-network",
    });
  const [triggerQuery, queryIsInFlight] = useManualQuery<TQuery>(QUERY, {
    fetchPolicy: "store-or-network",
  });

  type TItem = TQuery["response"]["result"]["items"][number];
  const [items, setItems] = useState<TItem[]>([]);
  const [search, setSearch] = useState<string>("");
  const [totalItemsToLoad, setTotalItemsToLoad] = useState<null | number>(null);
  const {
    setFalse: reset,
    setTrue: onInitialized,
    value: initialized,
  } = useBoolean(false);

  const resetItems = useCallback(() => {
    setItems([]);
    setSearch("");
    setTotalItemsToLoad(null);
    reset();
  }, [setItems, setSearch, setTotalItemsToLoad, reset]);

  const allItemsLoaded = useMemo(
    () => totalItemsToLoad !== null && items.length >= totalItemsToLoad,
    [totalItemsToLoad, items.length],
  );

  const hideLoadMoreButton = useMemo(
    () => debouncedQueryIsInFlight || queryIsInFlight || allItemsLoaded,
    [debouncedQueryIsInFlight, queryIsInFlight, allItemsLoaded],
  );

  const loadMoreItems = useCallback(() => {
    if (!variables) return;
    triggerQuery({
      onResponse: ({ result }) => {
        setItems([...items, ...result.items]);
        setTotalItemsToLoad(result.total);
      },
      variables: {
        ...variables,
        search: !isEmpty(search) ? search : null,
        skip: items.length,
        take: ITEMS_PULLED_AT_A_TIME,
      },
    });
  }, [search, items, variables, triggerQuery, setTotalItemsToLoad]);

  const triggerDebouncedQuery = useCallback(
    (search?: string) => {
      if (!variables) return;
      _triggerDebouncedQuery({
        onResponse: ({ result }) => {
          setItems([...result.items]);
          setTotalItemsToLoad(result.total);
        },
        variables: {
          ...variables,
          search: !isEmpty(search) ? search : null,
          skip: 0,
          take: ITEMS_PULLED_AT_A_TIME,
        },
      });
    },
    [variables, _triggerDebouncedQuery, setItems, setTotalItemsToLoad],
  );

  const onSearchChange = useCallback(
    (search: string) => {
      setSearch(search);
      triggerDebouncedQuery(search);
    },
    [triggerDebouncedQuery],
  );

  useEffect(() => {
    resetItems();
    triggerQuery({
      onResponse: ({ result }) => {
        onInitialized();
        setItems([...result.items]);
        setTotalItemsToLoad(result.total);
      },
      variables: {
        ...variables,
        search: null,
        skip: 0,
        take: ITEMS_PULLED_AT_A_TIME,
      },
    });
  }, [
    triggerQuery,
    resetItems,
    variables,
    onInitialized,
    setItems,
    setTotalItemsToLoad,
  ]);

  return {
    hideLoadMoreButton,
    initialized,
    items,
    loading: debouncedQueryIsInFlight || queryIsInFlight,
    onLoadMoreClick: loadMoreItems,
    onSearchChange,
    search,
  };
}

function GrantsList<TItem>({
  hideLoadMoreButton,
  items,
  loading,
  noItemsMessage,
  onLoadMoreClick,
  renderItems,
}: GrantsListProps<TItem>) {
  if (isEmpty(items)) {
    return noItemsMessage;
  }

  return (
    <div className="space-y-6">
      {renderItems(items)}
      {!hideLoadMoreButton && (
        <div className="text-center">
          <LoadMoreButton
            className="mx-auto"
            loading={loading}
            onLoadMoreRequest={onLoadMoreClick}
          />
        </div>
      )}
    </div>
  );
}

function SlideOverContent<TItem>({
  onSearchChange,
  search,
  searchBarPlaceholder,
  ...props
}: SlideOverContent<TItem>) {
  return (
    <>
      <SearchBar
        onChange={onSearchChange}
        placeholder={searchBarPlaceholder}
        value={search}
      />
      <GrantsList {...props} />
    </>
  );
}
