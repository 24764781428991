import { ArrowDownIcon, ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { saveAs } from "file-saver";
import _ from "lodash";
import React, { useCallback } from "react";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { LongDate } from "../../../components/LongDate";
import { Page } from "../../../components/Page";
import { BreadCrumb } from "../../../components/ui/BreadCrumb";
import { Button } from "../../../components/ui/Button";
import { DocumentTable } from "../../../components/ui/DocumentTable";
import { OneColumnLayout } from "../../../components/ui/Layout/OneColumnLayout";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { Typography } from "../../../components/ui/Typography";
import { useTrackDocumentsDownloaded } from "../../../hooks/useAnalytics";
import {
  APPLICATION_ROUTES,
  useEasopGrantIdParam,
  useOrganizationIdParam,
} from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { GrantDocumentationDetailsPage_EasopGrant$key } from "./__generated__/GrantDocumentationDetailsPage_EasopGrant.graphql";
import { GrantDocumentationDetailsPage_Organization$key } from "./__generated__/GrantDocumentationDetailsPage_Organization.graphql";
import { GrantDocumentationDetailsPage_Query } from "./__generated__/GrantDocumentationDetailsPage_Query.graphql";
import { useGrantDocuments } from "./useGrantDocuments";

const EASOP_GRANT_FRAGMENT = graphql`
  fragment GrantDocumentationDetailsPage_EasopGrant on EasopGrant {
    label
    grantee {
      name
    }
    ...useGrantDocuments_EasopGrant
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment GrantDocumentationDetailsPage_Organization on Organization {
    id
    name
  }
`;

const QUERY = graphql`
  query GrantDocumentationDetailsPage_Query(
    $organizationId: OrganizationId!
    $easopGrantId: UUID!
  ) {
    easopGrant(id: $easopGrantId) {
      ...GrantDocumentationDetailsPage_EasopGrant
      signedBoardConsentDocument {
        __typename
      }
    }
    organization(id: $organizationId) {
      id
      name
      ...GrantDocumentationDetailsPage_Organization
    }
  }
`;

export const DocumentsGrantDocumentationDetailsPage_: React.FC<{
  easopGrantFragment: GrantDocumentationDetailsPage_EasopGrant$key;
  organizationFragment: GrantDocumentationDetailsPage_Organization$key;
}> = ({ easopGrantFragment, organizationFragment }) => {
  const organizationId = useOrganizationIdParam();
  const easopGrant = useFragment(EASOP_GRANT_FRAGMENT, easopGrantFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const grantDocuments = useGrantDocuments({
    easopGrantFragment: easopGrant,
  });

  const trackDocumentsDownloaded = useTrackDocumentsDownloaded();

  const handleDownloadAllDocumentsButtonClick = useCallback(() => {
    trackDocumentsDownloaded({
      documentsTypes: _(grantDocuments)
        .map(({ type }) => type)
        .uniq()
        .value(),
      downloadType: "MULTIPLE",
    });
    grantDocuments.forEach((item) => {
      saveAs(item.downloadUrl, item.fileName);
    });
  }, [grantDocuments, trackDocumentsDownloaded]);

  const handleDownloadDocumentButtonClick = useCallback(
    (item: (typeof grantDocuments)[number]) => () => {
      trackDocumentsDownloaded({
        documentsTypes: [item.type],
        downloadType: "UNIQUE",
      });
      saveAs(item.downloadUrl, item.fileName);
    },
    [trackDocumentsDownloaded],
  );

  return (
    <OneColumnLayout
      Breadcrumb={
        <BreadCrumb>
          <BreadCrumb.Link
            to={generatePath(APPLICATION_ROUTES.organizationHome, {
              organizationId,
            })}
          >
            {organization.name}
          </BreadCrumb.Link>
          <BreadCrumb.Link
            to={generatePath(APPLICATION_ROUTES.organizationDocuments, {
              organizationId: organization.id,
            })}
          >
            Documents
          </BreadCrumb.Link>
          <BreadCrumb.Link
            to={generatePath(
              APPLICATION_ROUTES.organizationDocumentsGrantDocumentation,
              {
                organizationId: organization.id,
              },
            )}
          >
            Grant documentation
          </BreadCrumb.Link>
          <BreadCrumb.Link to=".">{easopGrant.grantee.name}</BreadCrumb.Link>
        </BreadCrumb>
      }
      className="space-y-6"
    >
      <RoundedBox className="flex items-center justify-between p-6" withShadow>
        <Typography as="div" variant="Medium/Default">
          {easopGrant.grantee.name}
        </Typography>

        <Button
          className="block whitespace-nowrap"
          leftIcon={<ArrowDownTrayIcon />}
          onClick={handleDownloadAllDocumentsButtonClick}
          size="small"
          type="button"
          variant="Secondary Full"
        >
          Download all
        </Button>
      </RoundedBox>

      <RoundedBox className="pt-6" withShadow>
        <DocumentTable className="w-full">
          <DocumentTable.TableHeader>
            <DocumentTable.HeaderRow>
              <DocumentTable.TableHead className="w-1/2">
                Name
              </DocumentTable.TableHead>
              <DocumentTable.TableHead className="w-1/4">
                Last modified
              </DocumentTable.TableHead>
              <DocumentTable.TableHead className="w-1/4" />
            </DocumentTable.HeaderRow>
          </DocumentTable.TableHeader>
          <DocumentTable.TableBody>
            {grantDocuments.map((item) => (
              <DocumentTable.Row className="group" key={item.label}>
                <DocumentTable.TableData>{item.label}</DocumentTable.TableData>
                <DocumentTable.TableData>
                  {item.updatedAt ? (
                    <LongDate value={new Date(item.updatedAt)} />
                  ) : (
                    <>-</>
                  )}
                </DocumentTable.TableData>
                <DocumentTable.TableData className="text-right opacity-0 transition-all group-hover:opacity-100">
                  <Button
                    leftIcon={<ArrowDownIcon />}
                    onClick={handleDownloadDocumentButtonClick(item)}
                    size="small"
                    type="button"
                  >
                    Download
                  </Button>
                </DocumentTable.TableData>
              </DocumentTable.Row>
            ))}
          </DocumentTable.TableBody>
        </DocumentTable>
      </RoundedBox>
    </OneColumnLayout>
  );
};

const AdminDocumentsGrantDocumentationDetailsPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const easopGrantId = useEasopGrantIdParam();
  const { easopGrant, organization } =
    useLazyLoadQuery<GrantDocumentationDetailsPage_Query>(
      QUERY,
      {
        easopGrantId,
        organizationId,
      },
      { fetchPolicy: "store-and-network" },
    );

  if (!organization || !easopGrant?.signedBoardConsentDocument) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Documents"
      analyticsName="Admin - Documents Grants - Details"
      organizationId={organization.id}
      title={`Admin | ${organization.name} grants documentation details`}
    >
      <DocumentsGrantDocumentationDetailsPage_
        easopGrantFragment={easopGrant}
        organizationFragment={organization}
      />
    </Page>
  );
};

export default AdminDocumentsGrantDocumentationDetailsPage;
