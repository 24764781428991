/**
 * @generated SignedSource<<37a9392ec8a31d778f3cb101669a30f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CurrencyUnit_Organization$data = {
  readonly defaultCurrencyCode: string;
  readonly " $fragmentType": "CurrencyUnit_Organization";
};
export type CurrencyUnit_Organization$key = {
  readonly " $data"?: CurrencyUnit_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"CurrencyUnit_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CurrencyUnit_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultCurrencyCode",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "027c85d5010faf68410d29b3fe2cdf56";

export default node;
