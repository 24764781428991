import React, { useCallback } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { ExerciseRequestTimelineSection } from "../../../components/ExerciseRequestTimelineSection";
import { Page } from "../../../components/Page";
import { CenteredColumnLayout } from "../../../components/ui/Layout/CenteredColumnLayout";
import { useOrganizationCTMS } from "../../../hooks/useOrganizationCTMS";
import { useQuery } from "../../../hooks/useQuery";
import { useSafeMutation } from "../../../hooks/useSafeMutation";
import {
  useCTMSExerciseRequestCTMSIdParam,
  useOrganizationIdParam,
} from "../../../paths";
import { getEquityTypeWorkRelationship } from "../../../services/workRelationship";
import NotFoundPage from "../../NotFound/NotFound";
import { ExerciseRequestDetails_CTMSExerciseRequest$key } from "./__generated__/ExerciseRequestDetails_CTMSExerciseRequest.graphql";
import { ExerciseRequestDetails_Organization$key } from "./__generated__/ExerciseRequestDetails_Organization.graphql";
import { ExerciseRequestDetails_Query } from "./__generated__/ExerciseRequestDetails_Query.graphql";
import { ExerciseRequestDetails_SetExerciseRequestTaxResidenceCountryIsKnown_Mutation } from "./__generated__/ExerciseRequestDetails_SetExerciseRequestTaxResidenceCountryIsKnown_Mutation.graphql";
import { ExerciseRequestDetails_Viewer$key } from "./__generated__/ExerciseRequestDetails_Viewer.graphql";
import { ExerciseRequestApprovalSection } from "./ExerciseRequestApprovalSection";
import { ExerciseRequestApprovedSection } from "./ExerciseRequestApprovedSection";
import { ExerciseRequestCompletedSection } from "./ExerciseRequestCompletedSection";
import { ExerciseRequestGranteeSection } from "./ExerciseRequestGranteeSection";
import { ExerciseRequestNotifySection } from "./ExerciseRequestNotifySection";
import { ExerciseRequestTaxTreatmentSection } from "./ExerciseRequestTaxTreatmentSection";
import { ExerciseRequestWaitingForFundsSection } from "./ExerciseRequestWaitingForFundsSection";
import { useExerciseRequestSteps } from "./useExerciseRequestSteps";

const EXERCISE_REQUEST_FRAGMENT = graphql`
  fragment ExerciseRequestDetails_CTMSExerciseRequest on CTMSExerciseRequest {
    id
    spread
    requestSubmittedAt
    quantityExercised
    totalExercisePrice
    exercisePrice
    taxResidenceCountryIsKnown
    fundsTransferredAt
    ctmsGrant {
      grantee {
        workRelationship
      }
      ...ExerciseRequestGranteeSection_CTMSGrant
      ...ExerciseRequestTaxTreatmentSection_CTMSGrant
      ...ExerciseRequestApprovalSection_CTMSGrant
      ...ExerciseRequestNotifySection_CTMSGrant
    }
    fairMarketValueAtExercise {
      ...ExerciseRequestTaxTreatmentSection_FairMarketValueAtExercise
      ...ExerciseRequestNotifySection_FairMarketValueAtExercise
    }
    ...ExerciseRequestApprovedSection_CTMSExerciseRequest
    ...ExerciseRequestWaitingForFundsSection_CTMSExerciseRequest
    ...ExerciseRequestCompletedSection_CTMSExerciseRequest
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment ExerciseRequestDetails_Organization on Organization {
    ...ExerciseRequestTaxTreatmentSection_Organization
    ...ExerciseRequestApprovalSection_Organization
    ...ExerciseRequestNotifySection_Organization
    ...ExerciseRequestApprovedSection_Organization
    ...ExerciseRequestCompletedSection_Organization
    ...useOrganizationCTMS_Organization
    ...ExerciseRequestGranteeSection_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment ExerciseRequestDetails_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    ...ExerciseRequestTaxTreatmentSection_Viewer
      @arguments(organizationId: $organizationId)
    ...ExerciseRequestCompletedSection_Viewer
      @arguments(organizationId: $organizationId)
    ...ExerciseRequestApprovalSection_Viewer
      @arguments(organizationId: $organizationId)
    ...ExerciseRequestNotifySection_Viewer
      @arguments(organizationId: $organizationId)
  }
`;

const SET_EXERCISE_REQUEST_TAX_RESIDENCE_COUNTRY_IS_KNOWN_MUTATION = graphql`
  mutation ExerciseRequestDetails_SetExerciseRequestTaxResidenceCountryIsKnown_Mutation(
    $ctmsExerciseRequestCTMSId: ID!
    $taxResidenceCountryIsKnown: Boolean!
  ) @raw_response_type {
    setExerciseRequestTaxResidenceCountryIsKnown(
      ctmsExerciseRequestCTMSId: $ctmsExerciseRequestCTMSId
      taxResidenceCountryIsKnown: $taxResidenceCountryIsKnown
    ) {
      taxResidenceCountryIsKnown
    }
  }
`;

const AdminExerciseRequestDetailsPage_: React.FC<{
  ctmsExerciseRequestFragment: ExerciseRequestDetails_CTMSExerciseRequest$key;
  organizationFragment: ExerciseRequestDetails_Organization$key;
  viewerFragment: ExerciseRequestDetails_Viewer$key;
}> = ({
  ctmsExerciseRequestFragment,
  organizationFragment,
  viewerFragment,
}) => {
  const ctmsExerciseRequest = useFragment(
    EXERCISE_REQUEST_FRAGMENT,
    ctmsExerciseRequestFragment,
  );
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const { workRelationship } = ctmsExerciseRequest.ctmsGrant.grantee;

  const equityTypeWorkRelationshipCategory = workRelationship
    ? getEquityTypeWorkRelationship(workRelationship).slug
    : null;
  const organizationCTMS = useOrganizationCTMS({
    organizationFragment: organization,
  });

  const steps = useExerciseRequestSteps({
    ctmsName: organizationCTMS?.name as null | string,
    equityTypeWorkRelationshipCategory,
  });

  const [_setExerciseRequestTaxResidenceCountryIsKnownMutation] =
    useSafeMutation<ExerciseRequestDetails_SetExerciseRequestTaxResidenceCountryIsKnown_Mutation>(
      SET_EXERCISE_REQUEST_TAX_RESIDENCE_COUNTRY_IS_KNOWN_MUTATION,
    );

  const setTaxResidenceCountryIsKnownMutation = useCallback(
    (taxResidenceCountryIsKnown: boolean) =>
      _setExerciseRequestTaxResidenceCountryIsKnownMutation({
        optimisticResponse: {
          setExerciseRequestTaxResidenceCountryIsKnown: {
            id: ctmsExerciseRequest.id,
            taxResidenceCountryIsKnown,
          },
        },
        variables: {
          ctmsExerciseRequestCTMSId: ctmsExerciseRequest.id,
          taxResidenceCountryIsKnown,
        },
      }),
    [
      _setExerciseRequestTaxResidenceCountryIsKnownMutation,
      ctmsExerciseRequest.id,
    ],
  );

  return (
    <CenteredColumnLayout
      backButton={{ label: "Back to Exercises" }}
      maxWidth="690"
    >
      <div>
        <ExerciseRequestTimelineSection className="mt-6 h-10" />
        <ExerciseRequestGranteeSection
          ctmsGrantFragment={ctmsExerciseRequest.ctmsGrant}
          exerciseFundsReceivedAt={
            ctmsExerciseRequest.fundsTransferredAt
              ? new Date(ctmsExerciseRequest.fundsTransferredAt)
              : null
          }
          organizationFragment={organization}
          quantityExercised={ctmsExerciseRequest.quantityExercised}
          requestSubmittedAt={new Date(ctmsExerciseRequest.requestSubmittedAt)}
          simulated={false}
          spread={ctmsExerciseRequest.spread}
          totalExercisePrice={ctmsExerciseRequest.totalExercisePrice}
        />
        {steps.TaxTreatment && (
          <ExerciseRequestTaxTreatmentSection
            ctmsGrantFragment={ctmsExerciseRequest.ctmsGrant}
            currentStep={steps.TaxTreatment.current}
            exercisePrice={ctmsExerciseRequest.exercisePrice}
            exerciseRequestCTMSId={ctmsExerciseRequest.id}
            fairMarketValueAtExerciseFragment={
              ctmsExerciseRequest.fairMarketValueAtExercise
            }
            nextStep={steps.TaxTreatment.next}
            onTaxResidenceCountryIsKnownChange={
              setTaxResidenceCountryIsKnownMutation
            }
            organizationFragment={organization}
            quantityExercised={ctmsExerciseRequest.quantityExercised}
            spread={ctmsExerciseRequest.spread}
            taxResidenceCountryIsKnown={
              ctmsExerciseRequest.taxResidenceCountryIsKnown
            }
            totalExercisePrice={ctmsExerciseRequest.totalExercisePrice}
            totalSteps={steps.total}
            viewerFragment={viewer}
          />
        )}
        {steps.Approval && (
          <ExerciseRequestApprovalSection
            ctmsGrantFragment={ctmsExerciseRequest.ctmsGrant}
            currentStep={steps.Approval.current}
            nextStep={steps.Approval.next}
            organizationFragment={organization}
            spread={ctmsExerciseRequest.spread}
            taxResidenceCountryIsKnown={
              ctmsExerciseRequest.taxResidenceCountryIsKnown
            }
            totalSteps={steps.total}
            viewerFragment={viewer}
          />
        )}
        {steps.Notify && (
          <ExerciseRequestNotifySection
            ctmsGrantFragment={ctmsExerciseRequest.ctmsGrant}
            currentStep={steps.Notify.current}
            exercisePrice={ctmsExerciseRequest.exercisePrice}
            exerciseRequestCTMSId={ctmsExerciseRequest.id}
            fairMarketValueAtExerciseFragment={
              ctmsExerciseRequest.fairMarketValueAtExercise
            }
            organizationFragment={organization}
            quantityExercised={ctmsExerciseRequest.quantityExercised}
            spread={ctmsExerciseRequest.spread}
            taxResidenceCountryIsKnown={
              ctmsExerciseRequest.taxResidenceCountryIsKnown
            }
            totalExercisePrice={ctmsExerciseRequest.totalExercisePrice}
            totalSteps={steps.total}
            viewerFragment={viewer}
          />
        )}
        <ExerciseRequestApprovedSection
          ctmsExerciseRequestFragment={ctmsExerciseRequest}
          organizationFragment={organization}
        />
        <ExerciseRequestWaitingForFundsSection
          ctmsExerciseRequestFragment={ctmsExerciseRequest}
        />
        <ExerciseRequestCompletedSection
          ctmsExerciseRequestFragment={ctmsExerciseRequest}
          organizationFragment={organization}
          viewerFragment={viewer}
        />
      </div>
    </CenteredColumnLayout>
  );
};

const QUERY = graphql`
  query ExerciseRequestDetails_Query(
    $organizationId: OrganizationId!
    $ctmsExerciseRequestCTMSId: ID!
  ) {
    me {
      ...ExerciseRequestDetails_Viewer
        @arguments(organizationId: $organizationId)
    }
    organization(id: $organizationId) {
      name
      id
      ...ExerciseRequestDetails_Organization
    }
    ctmsExerciseRequest(ctmsExerciseRequestCTMSId: $ctmsExerciseRequestCTMSId) {
      ctmsGrantLabel
      ...ExerciseRequestDetails_CTMSExerciseRequest
    }
  }
`;

const AdminExerciseRequestDetailsPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const ctmsExerciseRequestCTMSId = useCTMSExerciseRequestCTMSIdParam();
  const { query } = useQuery<ExerciseRequestDetails_Query>(QUERY, {
    ctmsExerciseRequestCTMSId,
    organizationId,
  });

  if (!query.organization || !query.ctmsExerciseRequest) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Exercises"
      analyticsName="Admin - Exercise Request Details"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} exercise request details ${query.ctmsExerciseRequest.ctmsGrantLabel}`}
    >
      <AdminExerciseRequestDetailsPage_
        ctmsExerciseRequestFragment={query.ctmsExerciseRequest}
        organizationFragment={query.organization}
        viewerFragment={query.me}
      />
    </Page>
  );
};

export default AdminExerciseRequestDetailsPage;
