/**
 * @generated SignedSource<<865f495013dd14ec842c5b386f54aa88>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConnectCTMSView_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"ContactUsModal_Organization" | "RemoteEquityOnboardingLayout_Organization" | "useTrackRemoteEquityOnboardingFlowEvent_Organization">;
  readonly " $fragmentType": "ConnectCTMSView_Organization";
};
export type ConnectCTMSView_Organization$key = {
  readonly " $data"?: ConnectCTMSView_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConnectCTMSView_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConnectCTMSView_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContactUsModal_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useTrackRemoteEquityOnboardingFlowEvent_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemoteEquityOnboardingLayout_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "0c3c133bf959d3529ea11155192bdfeb";

export default node;
