/**
 * @generated SignedSource<<55c704f6504684f2aabcdc049a4a5ab0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SuperAdminLayout_Query$variables = Record<PropertyKey, never>;
export type SuperAdminLayout_Query$data = {
  readonly me: {
    readonly isSuperAdmin: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"SuperAdminLayout_Viewer">;
  };
};
export type SuperAdminLayout_Query = {
  response: SuperAdminLayout_Query$data;
  variables: SuperAdminLayout_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isSuperAdmin",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SuperAdminLayout_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SuperAdminLayout_Viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SuperAdminLayout_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organizationsAsAdmin",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardMember",
            "kind": "LinkedField",
            "name": "boardMembers",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "40240d45e1d5d06eb1a2a00a4b361485",
    "id": null,
    "metadata": {},
    "name": "SuperAdminLayout_Query",
    "operationKind": "query",
    "text": "query SuperAdminLayout_Query {\n  me {\n    isSuperAdmin\n    ...SuperAdminLayout_Viewer\n    id\n  }\n}\n\nfragment ApplicationSideBarContent_Viewer on Account {\n  fullName\n  email\n  ...OrganizationRoleSwitcher_Account\n}\n\nfragment ApplicationSideBar_Viewer on Account {\n  ...ApplicationSideBarContent_Viewer\n  ...OrganizationRoleSwitcher_Account\n}\n\nfragment OrganizationRoleSwitcher_Account on Account {\n  isSuperAdmin\n  email\n  fullName\n  ...useAccountRoles_Account\n}\n\nfragment SuperAdminLayout_Viewer on Account {\n  ...ApplicationSideBar_Viewer\n}\n\nfragment useAccountRoles_Account on Account {\n  organizationsAsAdmin {\n    id\n  }\n  boardMembers {\n    id\n    organization {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4d7341eeb6a6dbbe371591ad227d333d";

export default node;
