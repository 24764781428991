import { BoxedIcon, Pill, Text } from "@remote-com/norma";
import { IconV2DuotoneCheck } from "@remote-com/norma/icons/IconV2DuotoneCheck";
import { IconV2DuotoneRocket } from "@remote-com/norma/icons/IconV2DuotoneRocket";
import classNames from "classnames";
import { compact, first } from "lodash";
import { useMemo, useState } from "react";
import { graphql, useFragment } from "react-relay";

import { FormattedCurrency } from "../../../components/Formatted/FormattedCurrency";
import { FormattedNumber } from "../../../components/Formatted/FormattedNumber";
import { FormattedPercentage } from "../../../components/Formatted/FormattedPercentage";
import {
  RemoteLikeInfoBlock,
  RemoteLikeInfoBlockItem,
} from "../../../components/RemoteLikeInfoBlock";
import { SectionHero } from "../../../components/SectionHero";
import { ShortDate } from "../../../components/ShortDate";
import { AnchorButton } from "../../../components/ui/Button";
import { PoweredByCarta } from "../../../components/ui/PoweredByCarta/PoweredByCarta";
import { Progress } from "../../../components/ui/Progress";
import { Switch } from "../../../components/ui/Switch";
import { useOrganizationCTMS } from "../../../hooks/useOrganizationCTMS";
import { WORK_RELATIONSHIP_TO_LABEL_HELPER } from "../../../services/workRelationship";
import { Equity101DrawerRemote } from "../Equity101Drawer/Equity101Drawer";
import { HelpLink } from "../HelpLink";
import {
  EmployeePortalExerciseDetailsSection_Grantee$data,
  EmployeePortalExerciseDetailsSection_Grantee$key,
} from "./__generated__/EmployeePortalExerciseDetailsSection_Grantee.graphql";

const GRANTEE_FRAGMENT = graphql`
  fragment EmployeePortalExerciseDetailsSection_Grantee on Grantee {
    organization {
      ...useOrganizationCTMS_Organization
      ...FormattedCurrency_Organization
      ...HelpLink_Organization
      ...Equity101Drawer_Organization
    }
    nonVirtualCTMSGrants: ctmsGrants(
      grantStatusIn: [Active, Terminated]
      orderBy: { field: vestingStartDate, direction: ASC }
      grantType: real
    ) {
      label
      quantityIssued
      grantDate
      vestingStatus
      cumulativeVested
      exercisePrice
      exercisableNonExercisedAmount
      cumulativeVested
      exercisableShares
      earlyExercise
      quantityExercised
      lastDayToExerciseBasedOnTerminationDate
      vestingEndDate
      postTerminationExercisePeriod {
        displayName
      }
      matchingInstrument {
        taxResidenceCountry {
          emoji
        }
        workRelationship
        awardSuperType
      }
      matchingInstrument {
        equityType {
          exercisable
        }
      }
    }
  }
`;

export function EmployeePortalExerciseDetailsSection({
  granteeFragment,
}: {
  granteeFragment: EmployeePortalExerciseDetailsSection_Grantee$key;
}) {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  const firstCTMSGrant = first(grantee.nonVirtualCTMSGrants);

  if (!firstCTMSGrant) {
    throw new Error("Unexpected: grantee has no grants");
  }

  const [selectedGrant, setSelectedGrant] = useState(firstCTMSGrant);

  return (
    <SectionHero
      aria-labelledby="heading"
      id="exercise-details"
      role="region"
      withBorder
    >
      <div className="space-y-6">
        <div className="flex items-center gap-3">
          <BoxedIcon Icon={IconV2DuotoneRocket} size="sm" tone="fuchsia" />
          <Text id="heading" variant="lgMedium">
            Exercising your options
          </Text>
        </div>
        <Text className="text-grey-600" variant="sm">
          Congratulations 🎉, your long term commitment in the company is
          rewarded: you now have the right to buy a slice of the company at a
          pre-defined price (the “exercise price”). We call this stage the
          “exercise”.
        </Text>
        <div>
          <Switch
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.label}
            name="grant"
            onChange={setSelectedGrant}
            options={grantee.nonVirtualCTMSGrants}
            selectedOption={selectedGrant}
          />
        </div>

        <GrantDetails grant={selectedGrant} grantee={grantee} />
      </div>
    </SectionHero>
  );
}

function ExercisableGrantDetails({
  grant,
  grantee,
}: {
  grant: EmployeePortalExerciseDetailsSection_Grantee$data["nonVirtualCTMSGrants"][number];
  grantee: EmployeePortalExerciseDetailsSection_Grantee$data;
}) {
  const organizationCTMS = useOrganizationCTMS({
    organizationFragment: grantee.organization,
  });

  const grantDetailsInfo = useMemo(
    () =>
      compact([
        grant.exercisePrice && {
          label: "Exercise price (one share)",
          value: (
            <FormattedCurrency
              animated
              organizationFragment={grantee.organization}
              value={grant.exercisePrice}
            />
          ),
        },
        typeof grant.exercisableNonExercisedAmount === "number" && {
          label: "Exercise price (all exercisable shares)",
          value: (
            <FormattedCurrency
              animated
              organizationFragment={grantee.organization}
              value={grant.exercisableNonExercisedAmount}
            />
          ),
        },
        {
          label: "Grant date",
          subLabel: "Based on the board approval date",
          value: <ShortDate value={grant.grantDate} />,
        },
        grant.matchingInstrument && {
          label: "Type",
          value: `${grant.matchingInstrument.taxResidenceCountry.emoji} ${grant.matchingInstrument.awardSuperType} - ${WORK_RELATIONSHIP_TO_LABEL_HELPER[grant.matchingInstrument.workRelationship].singularLabel}`,
        },
        grant.earlyExercise && {
          label: "Early exercise",
          value: <Pill tone="success">Allowed</Pill>,
        },

        grant.lastDayToExerciseBasedOnTerminationDate
          ? {
              label: "Last day to exercise",
              subLabel: "Based on the date of termination",
              value: (
                <ShortDate
                  value={grant.lastDayToExerciseBasedOnTerminationDate}
                />
              ),
            }
          : grant.postTerminationExercisePeriod
            ? {
                label: "Post-termination exercise period",
                subLabel: "In case of employee contract termination",
                value: grant.postTerminationExercisePeriod.displayName,
              }
            : null,
      ]) satisfies RemoteLikeInfoBlockItem[],
    [
      grant.exercisePrice,
      grant.exercisableNonExercisedAmount,
      grant.grantDate,
      grant.matchingInstrument,
      grant.earlyExercise,
      grant.lastDayToExerciseBasedOnTerminationDate,
      grant.postTerminationExercisePeriod,
      grantee.organization,
    ],
  );

  return (
    <div className="grid grid-cols-1 gap-10 lg:grid-cols-[300px_1fr]">
      <div className="divide-y-[0.5px] divide-grey-300 self-start rounded-remote-lg border-[0.5px] border-grey-300 bg-white shadow md:border-none">
        <div className="p-2">
          <div className="flex flex-col items-center justify-center gap-2 rounded-t-remote-sm bg-background-subtle p-6">
            <Text variant="lgMedium">Exercise</Text>
            <div className="flex w-full flex-col items-center justify-center gap-1">
              <Text variant="4xlMedium">
                {grant.exercisableShares > 0 ? (
                  <FormattedPercentage
                    animated
                    value={grant.quantityExercised / grant.exercisableShares}
                  />
                ) : (
                  "-"
                )}
              </Text>
              <Progress
                className="w-full bg-grey-300"
                max={grant.exercisableShares}
              >
                <Progress.Value
                  className="bg-primary"
                  value={grant.quantityExercised}
                />
              </Progress>
              <Text variant="lg">
                <FormattedNumber
                  animated
                  maximumFractionDigits={0}
                  value={grant.quantityExercised}
                />
                /
                <span className="text-grey-500">
                  <FormattedNumber
                    animated
                    maximumFractionDigits={0}
                    value={grant.exercisableShares}
                  />
                </span>
              </Text>
            </div>
            {grant.postTerminationExercisePeriod && (
              <div className="space-y-0.5 text-center">
                <Text className="text-grey-500" variant="xs">
                  Post-termination exercise period
                </Text>
                <Text variant="smMedium">
                  {grant.postTerminationExercisePeriod.displayName}
                </Text>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col items-center justify-center space-y-2 p-8">
          <Text variant="lgMedium">Vesting</Text>
          <div className="flex w-full flex-col items-center justify-center gap-1">
            <Text
              className={classNames("transition-all", {
                "text-error": grant.vestingStatus === "terminated",
                "text-green-600": grant.vestingStatus === "fully_vested",
                "text-primary":
                  grant.vestingStatus === "vesting" &&
                  grant.cumulativeVested > 0,
              })}
              variant="4xlMedium"
            >
              <FormattedPercentage
                animated
                value={grant.cumulativeVested / grant.quantityIssued}
              />
            </Text>
            <Progress className="w-full bg-grey-300" max={grant.quantityIssued}>
              <Progress.Value
                className={classNames("transition-colors", {
                  "bg-error": grant.vestingStatus === "terminated",
                  "bg-green-600": grant.vestingStatus === "fully_vested",
                  "bg-primary": grant.vestingStatus === "vesting",
                })}
                value={grant.cumulativeVested}
              />
            </Progress>
            <Text variant="lg">
              <FormattedNumber
                animated
                maximumFractionDigits={0}
                value={grant.cumulativeVested}
              />
              /
              <span className="text-grey-500">
                <FormattedNumber
                  animated
                  maximumFractionDigits={0}
                  value={grant.quantityIssued}
                />
              </span>
            </Text>
          </div>
          {grant.vestingStatus !== "terminated" && grant.vestingEndDate && (
            <div className="space-y-0.5 text-center">
              <Text className="text-grey-500" variant="xs">
                Vesting end date
              </Text>
              <Text variant="smMedium">
                <ShortDate value={grant.vestingEndDate} />
              </Text>
            </div>
          )}
          {grant.vestingStatus === "terminated" && (
            <div className="flex justify-center">
              <Pill tone="error">Terminated</Pill>
            </div>
          )}
        </div>
      </div>
      <div className="space-y-6">
        <RemoteLikeInfoBlock info={grantDetailsInfo} />
        <HelpLink organizationFragment={grantee.organization} />
        {organizationCTMS && (
          <div className="space-y-6 rounded-2xl border-[0.5px] border-grey-300 bg-background-subtle p-6 md:border-none md:p-10">
            <div className="space-y-2">
              <Text variant="lgMedium">
                Ready to exercise your vested stock options?
              </Text>
              <Text variant="sm">
                Just head to{" "}
                <span className="font-medium">{organizationCTMS.name}</span>.
                You can easily exercise your options and follow the procedure.
              </Text>
            </div>
            <div className="flex flex-wrap items-center justify-between gap-2">
              <AnchorButton
                href={organizationCTMS.loginUrl}
                size="medium"
                target="_blank"
              >
                Exercise your options
              </AnchorButton>
              {organizationCTMS.name === "Carta" && (
                <PoweredByCarta className="w-[126px]" />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function GrantDetails({
  grant,
  grantee,
}: {
  grant: EmployeePortalExerciseDetailsSection_Grantee$data["nonVirtualCTMSGrants"][number];
  grantee: EmployeePortalExerciseDetailsSection_Grantee$data;
}) {
  if (!grant.matchingInstrument?.equityType?.exercisable) {
    return <NonExercisableGrantDetails organization={grantee.organization} />;
  }

  return <ExercisableGrantDetails grant={grant} grantee={grantee} />;
}

function NonExercisableGrantDetails({
  organization,
}: {
  organization: EmployeePortalExerciseDetailsSection_Grantee$data["organization"];
}) {
  return (
    <div className="flex flex-col items-center justify-center gap-2 rounded-remote-lg border-[0.5px] border-grey-300 bg-background-subtle p-6">
      <BoxedIcon Icon={IconV2DuotoneCheck} size="sm" tone="green" />
      <div className="text-SM text-grey-600">
        Nothing to exercise with RSU as the exercise price amount is directly
        deducted
      </div>
      <Equity101DrawerRemote.Controller
        render={({ open }) => (
          <button
            className="text-SM font-medium text-brand-600"
            onClick={() => {
              open({
                data: {
                  organizationFragment: organization,
                },
              });
            }}
          >
            Read the guide
          </button>
        )}
      />
    </div>
  );
}
