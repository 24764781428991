import { Pill } from "@remote-com/norma";
import { IconV2DuotoneBookOpen } from "@remote-com/norma/icons/IconV2DuotoneBookOpen";
import React from "react";
import { useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { Page } from "../../../../components/Page";
import { ShortDate } from "../../../../components/ShortDate";
import { SuperAdminBoxLink } from "../../../../components/SuperAdminBoxLink";
import { LargeCenteredDetailsLayout } from "../../../../components/ui/Layout/LargeCenteredDetailsLayout";
import { useQuery } from "../../../../hooks/useQuery";
import { APPLICATION_ROUTES, useCountryCodeParam } from "../../../../paths";
import { GeographyPage_SuperAdminEquityTypeHandbooks_Geography$key } from "./__generated__/GeographyPage_SuperAdminEquityTypeHandbooks_Geography.graphql";
import { GeographyPage_SuperAdminEquityTypeHandbooks_Query } from "./__generated__/GeographyPage_SuperAdminEquityTypeHandbooks_Query.graphql";

const GEOGRAPHY_FRAGMENT = graphql`
  fragment GeographyPage_SuperAdminEquityTypeHandbooks_Geography on Geography {
    country {
      displayName
      code
    }
    equityTypes {
      id
      name
      mostRecentEquityTypeHandbookPublicationDate
    }
  }
`;

const SuperAdminEquityTypeHandbooksGeographyPage_: React.FC<{
  geographyFragment: GeographyPage_SuperAdminEquityTypeHandbooks_Geography$key;
}> = ({ geographyFragment }) => {
  const geography = useFragment(GEOGRAPHY_FRAGMENT, geographyFragment);

  return (
    <LargeCenteredDetailsLayout
      footerHelpCard={null}
      navigationTitle={geography.country.displayName}
      organizationFragment={null}
      parents={[
        {
          link: generatePath(APPLICATION_ROUTES.superAdminLegalContent),
          title: "Legal content",
        },
        {
          link: generatePath(APPLICATION_ROUTES.superAdminEquityHandbooks),
          title: "Equity handbooks",
        },
      ]}
      title={<>{geography.country.displayName} - Equity handbooks</>}
    >
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        {geography.equityTypes.map((equityType) => (
          <SuperAdminBoxLink
            description={
              <>Add here the legal content for the {equityType.name} handbook</>
            }
            Icon={IconV2DuotoneBookOpen}
            key={equityType.id}
            tags={[
              equityType.mostRecentEquityTypeHandbookPublicationDate ? (
                <Pill key="published-on" tone="success">
                  PUBLISHED ON{" "}
                  <ShortDate
                    value={
                      equityType.mostRecentEquityTypeHandbookPublicationDate
                    }
                  />
                </Pill>
              ) : (
                <Pill key="published-on" tone="neutralLight">
                  NOT PUBLISHED
                </Pill>
              ),
            ]}
            title={<>{equityType.name} handbook</>}
            to={generatePath(
              APPLICATION_ROUTES.superAdminEquityHandbooksEquityType,
              {
                countryCode: geography.country.code,
                equityTypeId: equityType.id,
              },
            )}
            tone={
              equityType.mostRecentEquityTypeHandbookPublicationDate
                ? "green"
                : "grey"
            }
          />
        ))}
      </div>
    </LargeCenteredDetailsLayout>
  );
};

const QUERY = graphql`
  query GeographyPage_SuperAdminEquityTypeHandbooks_Query(
    $countryCode: String!
  ) {
    geography(countryCode: $countryCode) {
      country {
        name
      }
      ...GeographyPage_SuperAdminEquityTypeHandbooks_Geography
    }
  }
`;

const SuperAdminEquityTypeHandbooksGeographyPage: React.FC = () => {
  const countryCode = useCountryCodeParam();
  const {
    query: { geography },
  } = useQuery<GeographyPage_SuperAdminEquityTypeHandbooks_Query>(QUERY, {
    countryCode,
  });

  return (
    <Page
      analyticsName="Super Admin - Equity type handbooks"
      title={`Super admin | Equity type handbooks - ${geography.country.name}`}
    >
      <SuperAdminEquityTypeHandbooksGeographyPage_
        geographyFragment={geography}
      />
    </Page>
  );
};

export default SuperAdminEquityTypeHandbooksGeographyPage;
