import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { ExerciseRequestTimelineSection } from "../../../components/ExerciseRequestTimelineSection";
import { Radio } from "../../../components/ui/Form/Radio";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { Typography } from "../../../components/ui/Typography";
import {
  ExerciseRequestTaxTreatmentSection_CTMSGrant$data,
  ExerciseRequestTaxTreatmentSection_CTMSGrant$key,
} from "./__generated__/ExerciseRequestTaxTreatmentSection_CTMSGrant.graphql";
import { ExerciseRequestTaxTreatmentSection_FairMarketValueAtExercise$key } from "./__generated__/ExerciseRequestTaxTreatmentSection_FairMarketValueAtExercise.graphql";
import { ExerciseRequestTaxTreatmentSection_Organization$key } from "./__generated__/ExerciseRequestTaxTreatmentSection_Organization.graphql";
import { ExerciseRequestTaxTreatmentSection_Viewer$key } from "./__generated__/ExerciseRequestTaxTreatmentSection_Viewer.graphql";
import { MissingGranteeInformationMessage } from "./MissingGranteeInformationMessage";
import { ShareTaxInformation } from "./ShareTaxInformation";
import { TaxDetailsContent } from "./TaxDetailsContent";
import { WantToKnowMoreBox } from "./WantToKnowMoreBox";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment ExerciseRequestTaxTreatmentSection_CTMSGrant on CTMSGrant {
    grantee {
      name
      workRelationship
      taxResidenceCountry {
        name
        emoji
      }
      ...MissingGranteeInformationMessage_Grantee
    }
    ...WantToKnowMoreBox_CTMSGrant
    ...TaxDetailsContent_CTMSGrant
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment ExerciseRequestTaxTreatmentSection_Organization on Organization {
    ...WantToKnowMoreBox_Organization
    ...TaxDetailsContent_Organization
    ...MissingGranteeInformationMessage_Organization
  }
`;

const WorkRelationshipSpecificText: React.FC<{
  grantee: ExerciseRequestTaxTreatmentSection_CTMSGrant$data["grantee"];
}> = ({ grantee }) => {
  const { name, workRelationship } = grantee;
  if (!workRelationship) {
    return null;
  }
  switch (workRelationship) {
    case "Advisor":
    case "ContractorManagementCompany":
    case "ContractorNaturalPerson":
      return (
        <>
          Since {name} is a contractor, this information will help {name} to
          make the necessary declarations to the tax authorities.
        </>
      );
    case "DirectEmployee":
      return (
        <>
          {name} has requested to exercise options, but you need to make sure
          the grantee is aware of the following tax information having an impact
          on the total exercise cost before you approve the request
          <br />
          <br />
          We recommend you let {name} know about the exercise price for all
          shares, the spread and the way the spread is going to be taxed. The
          information is available below.
        </>
      );
    case "EoREmployee":
      return (
        <>
          {name} has requested to exercise their options.
          <br />
          <br />
          Make sure {name} is aware of all the exercise costs (including taxes)
          related to the exercise before you approve the request. Indeed, there
          are usually taxes to pay on top of the exercise price!
          <br />
          <br />
          Below is a recap of the information relevant to assess the overall
          exercise costs and tax implications of the envisaged exercise, which
          you can share with {name}. Since {name} is an EoR employee, you should
          also communicate that information to the EoR so they can handle their
          tax reporting and withholding obligations.
        </>
      );
  }
};

const VIEWER_FRAGMENT = graphql`
  fragment ExerciseRequestTaxTreatmentSection_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    isAllowedToManageOrganization(organizationId: $organizationId)
  }
`;

const FAIR_MARKET_VALUE_AT_EXERCISE_FRAGMENT = graphql`
  fragment ExerciseRequestTaxTreatmentSection_FairMarketValueAtExercise on FairMarketValue {
    ...TaxDetailsContent_FairMarketValueAtExercise
  }
`;

export const ExerciseRequestTaxTreatmentSection: React.FC<{
  ctmsGrantFragment: ExerciseRequestTaxTreatmentSection_CTMSGrant$key;
  currentStep: number;
  exercisePrice: number;
  exerciseRequestCTMSId?: null | string;
  fairMarketValueAtExerciseFragment: ExerciseRequestTaxTreatmentSection_FairMarketValueAtExercise$key | null;
  nextStep?: string;
  onTaxResidenceCountryIsKnownChange: (isKnown: boolean) => void;
  organizationFragment: ExerciseRequestTaxTreatmentSection_Organization$key;
  quantityExercised: number;
  spread: null | number;
  taxResidenceCountryIsKnown: boolean;
  totalExercisePrice: number;
  totalSteps: number;
  viewerFragment: ExerciseRequestTaxTreatmentSection_Viewer$key;
}> = ({
  ctmsGrantFragment,
  currentStep,
  exercisePrice,
  exerciseRequestCTMSId,
  fairMarketValueAtExerciseFragment,
  nextStep,
  onTaxResidenceCountryIsKnownChange,
  organizationFragment,
  quantityExercised,
  spread,
  taxResidenceCountryIsKnown,
  totalExercisePrice,
  totalSteps,
  viewerFragment,
}) => {
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const fairMarketValueAtExercise =
    useFragment(
      FAIR_MARKET_VALUE_AT_EXERCISE_FRAGMENT,
      fairMarketValueAtExerciseFragment,
    ) ?? null;

  const { grantee } = ctmsGrant;

  return (
    <ExerciseRequestTimelineSection
      chipContent={
        <Typography className="text-black-07" variant="Regular/Extra Small">
          {currentStep}
        </Typography>
      }
    >
      <div className="space-y-6">
        <div className="space-y-4">
          <Typography as="div" variant="Medium/Default">
            Confirm tax treatment with {grantee.name}
          </Typography>
          {grantee.workRelationship && (
            <Typography
              as="div"
              className="text-black-05"
              variant="Regular/Small"
            >
              <WorkRelationshipSpecificText grantee={grantee} />
            </Typography>
          )}
        </div>
        <RoundedBox className="space-y-6 px-10 py-6" withBorder withShadow>
          <div className="space-y-2">
            <Typography as="div" variant="Medium/Default">
              Exercise costs
            </Typography>
            <Typography
              as="div"
              className="text-black-05"
              variant="Regular/Extra Small"
            >
              Use the following data to explain the exercise costs (including
              taxes)
            </Typography>
          </div>
          {!grantee.taxResidenceCountry ? (
            <MissingGranteeInformationMessage
              granteeFragment={grantee}
              missingInformation="TaxResidenceCountry"
              organizationFragment={organization}
            />
          ) : !grantee.workRelationship ? (
            <MissingGranteeInformationMessage
              granteeFragment={grantee}
              missingInformation="WorkRelationship"
              organizationFragment={organization}
            />
          ) : (
            <div className="space-y-2">
              <Typography
                as="div"
                className="text-black-05"
                variant="Regular/Extra Small"
              >
                Please confirm the following
              </Typography>
              <RoundedBox withBorder>
                <button
                  className="flex w-full items-center gap-4 px-6 py-4 text-left disabled:cursor-not-allowed"
                  disabled={!viewer.isAllowedToManageOrganization}
                  onClick={() => {
                    onTaxResidenceCountryIsKnownChange(true);
                  }}
                  type="button"
                >
                  <Radio checked={taxResidenceCountryIsKnown} size="medium" />
                  <div className="space-y-1">
                    <Typography as="div" variant="Medium/Extra Small">
                      {grantee.name} has their tax residence in{" "}
                      {grantee.taxResidenceCountry.name}{" "}
                      {grantee.taxResidenceCountry.emoji}
                    </Typography>
                    <Typography
                      as="div"
                      className="text-black-05"
                      variant="Regular/Extra Small"
                    >
                      Make sure this info is correct, as it has an impact on the
                      taxes to be paid
                    </Typography>
                  </div>
                </button>
              </RoundedBox>
              <RoundedBox withBorder>
                <button
                  className="flex w-full items-center gap-4 px-6 py-4 text-left disabled:cursor-not-allowed"
                  disabled={!viewer.isAllowedToManageOrganization}
                  onClick={() => {
                    onTaxResidenceCountryIsKnownChange(false);
                  }}
                  type="button"
                >
                  <Radio checked={!taxResidenceCountryIsKnown} size="medium" />
                  <Typography variant="Medium/Extra Small">
                    I don’t know
                  </Typography>
                </button>
              </RoundedBox>
            </div>
          )}
          <TaxDetailsContent
            ctmsGrantFragment={ctmsGrant}
            exercisePrice={exercisePrice}
            fairMarketValueAtExerciseFragment={fairMarketValueAtExercise}
            organizationFragment={organization}
            quantityExercised={quantityExercised}
            spread={spread}
            taxResidenceCountryIsKnown={taxResidenceCountryIsKnown}
            totalExercisePrice={totalExercisePrice}
          />
          {grantee.taxResidenceCountry && (
            <div className="flex items-center gap-10">
              <Typography
                as="div"
                className="flex-grow text-right text-black-05"
                variant="Regular/Extra Small"
              >
                Step {currentStep} of {totalSteps}
                {nextStep && (
                  <>
                    <br />
                    Next: {nextStep}
                  </>
                )}
              </Typography>
              {viewer.isAllowedToManageOrganization &&
                exerciseRequestCTMSId && (
                  <ShareTaxInformation
                    exerciseRequestCTMSId={exerciseRequestCTMSId}
                  />
                )}
            </div>
          )}
        </RoundedBox>
        {viewer.isAllowedToManageOrganization && (
          <WantToKnowMoreBox
            ctmsGrantFragment={ctmsGrant}
            organizationFragment={organization}
          />
        )}
      </div>
    </ExerciseRequestTimelineSection>
  );
};
