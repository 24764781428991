/**
 * @generated SignedSource<<2972e66486886578492eb25e1bd91a1e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GeographyPage_SuperAdminEquityTypeHandbooks_Query$variables = {
  countryCode: string;
};
export type GeographyPage_SuperAdminEquityTypeHandbooks_Query$data = {
  readonly geography: {
    readonly country: {
      readonly name: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"GeographyPage_SuperAdminEquityTypeHandbooks_Geography">;
  };
};
export type GeographyPage_SuperAdminEquityTypeHandbooks_Query = {
  response: GeographyPage_SuperAdminEquityTypeHandbooks_Query$data;
  variables: GeographyPage_SuperAdminEquityTypeHandbooks_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "countryCode"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "countryCode",
    "variableName": "countryCode"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GeographyPage_SuperAdminEquityTypeHandbooks_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Geography",
        "kind": "LinkedField",
        "name": "geography",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Country",
            "kind": "LinkedField",
            "name": "country",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GeographyPage_SuperAdminEquityTypeHandbooks_Geography"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GeographyPage_SuperAdminEquityTypeHandbooks_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Geography",
        "kind": "LinkedField",
        "name": "geography",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Country",
            "kind": "LinkedField",
            "name": "country",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EquityType",
            "kind": "LinkedField",
            "name": "equityTypes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "mostRecentEquityTypeHandbookPublicationDate",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c0e428211120a9ed0d0c0504b37f68ed",
    "id": null,
    "metadata": {},
    "name": "GeographyPage_SuperAdminEquityTypeHandbooks_Query",
    "operationKind": "query",
    "text": "query GeographyPage_SuperAdminEquityTypeHandbooks_Query(\n  $countryCode: String!\n) {\n  geography(countryCode: $countryCode) {\n    country {\n      name\n    }\n    ...GeographyPage_SuperAdminEquityTypeHandbooks_Geography\n  }\n}\n\nfragment GeographyPage_SuperAdminEquityTypeHandbooks_Geography on Geography {\n  country {\n    displayName\n    code\n  }\n  equityTypes {\n    id\n    name\n    mostRecentEquityTypeHandbookPublicationDate\n  }\n}\n"
  }
};
})();

(node as any).hash = "ea7cbb77d4d4bae9107f1b46bb5b4c84";

export default node;
