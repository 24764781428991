import classNames from "classnames";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useOrganizationSharesUtil } from "../../../hooks/useOrganizationSharesUtil";
import { FormattedCurrency } from "../../Formatted/FormattedCurrency";
import { Typography } from "../Typography";
import { TableCellSharesOverview_Organization$key } from "./__generated__/TableCellSharesOverview_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment TableCellSharesOverview_Organization on Organization {
    ...useOrganizationSharesUtil_Organization
    ...FormattedCurrency_Organization
  }
`;

export const TableCellSharesOverview: React.FC<{
  align?: "left" | "right";
  className?: string;
  organizationFragment: TableCellSharesOverview_Organization$key;
  shares: number;
}> = ({ align = "left", className, organizationFragment, shares }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const { sharesToFullyDilutedRatio, sharesToValue } =
    useOrganizationSharesUtil({
      organizationFragment: organization,
    });

  const ownership = sharesToFullyDilutedRatio(shares);
  const usdValue = sharesToValue(shares);

  return (
    <Typography
      as="div"
      className={classNames(
        "flex flex-col gap-1",
        {
          "text-right": align === "right",
        },
        className,
      )}
      variant="Medium/Extra Small"
    >
      {usdValue !== null && (
        <span>
          <FormattedCurrency
            maximumFractionDigits={0}
            organizationFragment={organization}
            value={usdValue}
          />
        </span>
      )}
      <Typography className="text-black-05" variant="Regular/Extra Small">
        <FormattedNumber value={shares} /> shares
      </Typography>
      {ownership !== null && (
        <Typography className="text-black-05" variant="Regular/Extra Small">
          <FormattedNumber
            maximumFractionDigits={2}
            style="percent"
            value={ownership}
          />
        </Typography>
      )}
    </Typography>
  );
};
