import { graphql } from "relay-runtime";

import { EquityHandbooksCountryEquityTypeContent } from "../../../components/EquityHandbooksCountryEquityTypeContent";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { useQuery } from "../../../hooks/useQuery";
import { useEquityTypeIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { EquityHandbooksCountryEquityTypePage_Query } from "./__generated__/EquityHandbooksCountryEquityTypePage_Query.graphql";
import { EquityHandbooksCountryEquityTypeEmptyState } from "./EquityHandbooksCountryEquityTypeEmptyState";

const QUERY = graphql`
  query EquityHandbooksCountryEquityTypePage_Query($equityTypeId: UUID!) {
    equityType(id: $equityTypeId) {
      eoREmployeeHandbook {
        published
        ...EquityHandbooksCountryEquityTypeContent_EquityTypeHandbook
      }
    }
  }
`;

export default function EquityHandbooksCountryEquityTypePage() {
  const equityTypeId = useEquityTypeIdParam();

  const {
    query: { equityType },
  } = useQuery<EquityHandbooksCountryEquityTypePage_Query>(QUERY, {
    equityTypeId,
  });

  if (!equityType) {
    return <NotFoundPage />;
  }

  if (!equityType.eoREmployeeHandbook?.published) {
    return <EquityHandbooksCountryEquityTypeEmptyState />;
  }

  return (
    <RoundedBox background="subtle" className="p-14">
      <div className="mx-auto max-w-[824px] space-y-10">
        <EquityHandbooksCountryEquityTypeContent
          equityTypeHandbookFragment={equityType.eoREmployeeHandbook}
        />
      </div>
    </RoundedBox>
  );
}
