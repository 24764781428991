import { Menu } from "@headlessui/react";
import { ChevronUpDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { Avatar } from "@remote-com/norma";
import classNames from "classnames";
import React from "react";
import { NavLink as RouterNavLink } from "react-router-dom";

import { Typography } from "./Typography";

const LinkItem: React.FC<
  React.PropsWithChildren<
    Omit<React.ComponentProps<typeof RouterNavLink>, "children"> & {
      icon?: React.ReactElement<React.ComponentProps<"svg">>;
    }
  >
> = ({ children, icon, ...props }) => {
  return (
    <Menu.Item>
      <RouterNavLink {...props} className="block">
        {({ isActive }) => (
          <Typography
            className="relative flex cursor-pointer gap-3 bg-white p-4 last:rounded-b-lg hover:bg-gray-01"
            variant="Medium/Extra Small"
          >
            <>
              {icon &&
                React.cloneElement(icon, {
                  className: classNames(
                    /* tailwind */ `w-5`,
                    {
                      "text-gray-09": isActive,
                      "text-primary md:text-gray-09": !isActive,
                    },
                    icon.props.className,
                  ),
                })}
              {children}
            </>
          </Typography>
        )}
      </RouterNavLink>
    </Menu.Item>
  );
};

const NAVIGATION_BUTTON_VARIANTS = {
  orange: {
    className: "hover:bg-orange-01 text-orange",
  },
  red: {
    className: "hover:bg-red-01 text-red",
  },
};

const ButtonItem: React.FC<
  React.PropsWithChildren<{
    icon?: React.ReactElement<React.ComponentProps<"svg">>;
    onClick?: React.ComponentProps<"button">["onClick"];
    variant: keyof typeof NAVIGATION_BUTTON_VARIANTS;
  }>
> = ({ children, icon, onClick, variant }) => {
  return (
    <Menu.Item>
      <button
        className={classNames(
          "relative w-full bg-white p-4 text-left last:rounded-b-lg hover:bg-gray-01",
          NAVIGATION_BUTTON_VARIANTS[variant].className,
        )}
        onClick={onClick}
        type="button"
      >
        <Typography className="flex gap-3" variant="Medium/Extra Small">
          {icon &&
            React.cloneElement(icon, {
              className: classNames(
                /* tailwind */ "w-5",
                icon.props.className,
                NAVIGATION_BUTTON_VARIANTS[variant].className,
              ),
            })}
          {children}
        </Typography>
      </button>
    </Menu.Item>
  );
};

const _WideDropdown: React.FC<{
  children?: React.ReactNode;
  className?: string;
  label: string;
  subLabel?: React.ReactNode;
}> = ({ children, className, label, subLabel }) => {
  return (
    <Menu as="div" className={classNames("relative", className)}>
      {({ open }) => (
        <>
          <Menu.Button
            className={classNames(
              "space-between flex w-full items-center gap-3 rounded-lg p-2 transition-all md:border-[0.5px]",
              "hover:border-gray-01 hover:bg-gray-01",
              {
                "border-white bg-gray-01": !open,
                "rounded-b-none border-gray-04 bg-gray-02": open,
              },
            )}
          >
            <div className="flex items-center gap-3">
              <Avatar name={label} size="sm" />
              <div className="flex flex-col items-start gap-1 text-left">
                <Typography variant="Medium/Extra Small">{label}</Typography>
                {subLabel && (
                  <Typography
                    className="text-gray-09"
                    variant="Regular/Extra Small"
                  >
                    {subLabel}
                  </Typography>
                )}
              </div>
            </div>
            <ChevronUpDownIcon className="ml-auto hidden w-5 text-gray-09 md:block" />
            <ChevronUpIcon
              className={classNames(
                "ml-auto block w-5 text-gray-09 md:hidden",
                { "rotate-90": !open },
              )}
            />
          </Menu.Button>
          <Menu.Items
            className={classNames(
              "left-0 right-0 z-10 -mt-px divide-gray-03 rounded-lg rounded-t-none border-gray-04 bg-white-01 md:absolute md:divide-y-[0.5px] md:border-[0.5px] md:shadow-100",
              "md:ui-open:shadow-200",
              "bg-gray-01 md:bg-transparent",
            )}
          >
            {children}
          </Menu.Items>
        </>
      )}
    </Menu>
  );
};

export const WideDropdown = Object.assign(_WideDropdown, {
  ButtonItem,
  LinkItem,
});
