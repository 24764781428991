/**
 * @generated SignedSource<<f278b191b406ff775f53f9886935536a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type RemoteOnboardingStep = "CLEAN_UP_GRANTEE_DATA" | "CONNECT_CTMS" | "ESSENTIALS_CTMS" | "ESSENTIALS_EQUITY_PLAN_PARAMETERS" | "ESSENTIALS_EXPECTATION" | "ESSENTIALS_FAIR_MARKET_VALUE" | "ESSENTIALS_ISSUING_COMPANY" | "ESSENTIALS_LAST_STEP" | "SET_PRICE_PER_SHARE" | "TEST_LAW_FIRM_CARTA_CONNECTION";
export type IssuingCompanyPage_SetEssentialsOrganizationIssuingCompany_Mutation$variables = {
  companyCity?: string | null;
  companyCountryCode: string;
  companyDesiredCurrencyCode?: string | null;
  companyName: string;
  companyRemoteSlug: string;
  organizationId: string;
};
export type IssuingCompanyPage_SetEssentialsOrganizationIssuingCompany_Mutation$data = {
  readonly setEssentialsOrganizationIssuingCompany: {
    readonly currentRemoteOnboardingStep: RemoteOnboardingStep | null;
    readonly defaultCurrencyCode: string;
    readonly issuingCompany: {
      readonly city: string | null;
      readonly country: {
        readonly emoji: string;
        readonly name: string;
      } | null;
      readonly name: string | null;
      readonly remoteSlug: string;
    } | null;
  };
};
export type IssuingCompanyPage_SetEssentialsOrganizationIssuingCompany_Mutation = {
  response: IssuingCompanyPage_SetEssentialsOrganizationIssuingCompany_Mutation$data;
  variables: IssuingCompanyPage_SetEssentialsOrganizationIssuingCompany_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyCity"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyCountryCode"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyDesiredCurrencyCode"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyRemoteSlug"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "companyCity",
    "variableName": "companyCity"
  },
  {
    "kind": "Variable",
    "name": "companyCountryCode",
    "variableName": "companyCountryCode"
  },
  {
    "kind": "Variable",
    "name": "companyDesiredCurrencyCode",
    "variableName": "companyDesiredCurrencyCode"
  },
  {
    "kind": "Variable",
    "name": "companyName",
    "variableName": "companyName"
  },
  {
    "kind": "Variable",
    "name": "companyRemoteSlug",
    "variableName": "companyRemoteSlug"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "defaultCurrencyCode",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currentRemoteOnboardingStep",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "IssuingCompany",
  "kind": "LinkedField",
  "name": "issuingCompany",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remoteSlug",
      "storageKey": null
    },
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "country",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emoji",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "IssuingCompanyPage_SetEssentialsOrganizationIssuingCompany_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "setEssentialsOrganizationIssuingCompany",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "IssuingCompanyPage_SetEssentialsOrganizationIssuingCompany_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "setEssentialsOrganizationIssuingCompany",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a60ab29a9c18975f4304b2b79c7c694f",
    "id": null,
    "metadata": {},
    "name": "IssuingCompanyPage_SetEssentialsOrganizationIssuingCompany_Mutation",
    "operationKind": "mutation",
    "text": "mutation IssuingCompanyPage_SetEssentialsOrganizationIssuingCompany_Mutation(\n  $companyCity: String\n  $companyCountryCode: String!\n  $companyDesiredCurrencyCode: String\n  $companyName: String!\n  $companyRemoteSlug: String!\n  $organizationId: OrganizationId!\n) {\n  setEssentialsOrganizationIssuingCompany(companyCountryCode: $companyCountryCode, companyDesiredCurrencyCode: $companyDesiredCurrencyCode, companyName: $companyName, companyRemoteSlug: $companyRemoteSlug, organizationId: $organizationId, companyCity: $companyCity) {\n    defaultCurrencyCode\n    currentRemoteOnboardingStep\n    issuingCompany {\n      remoteSlug\n      name\n      city\n      country {\n        name\n        emoji\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d4a5731aa4543e4ba0562c183b5f5e43";

export default node;
