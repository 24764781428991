/**
 * @generated SignedSource<<8309f9cd1c0100ac0a5f93ea6716e662>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Company_EquityOffer$data = {
  readonly displayCompanyInformation: boolean;
  readonly exercisePrice: number | null;
  readonly organization: {
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"FormattedCurrencyCompact_Organization" | "FormattedFMV_Organization">;
  };
  readonly organizationLatestFairMarketValue: {
    readonly value: number;
  } | null;
  readonly organizationLatestPricePerShare: {
    readonly value: number;
  } | null;
  readonly organizationLatestValuation: number | null;
  readonly " $fragmentType": "Company_EquityOffer";
};
export type Company_EquityOffer$key = {
  readonly " $data"?: Company_EquityOffer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Company_EquityOffer">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Company_EquityOffer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exercisePrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayCompanyInformation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationLatestValuation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PricePerShare",
      "kind": "LinkedField",
      "name": "organizationLatestPricePerShare",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FairMarketValue",
      "kind": "LinkedField",
      "name": "organizationLatestFairMarketValue",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FormattedCurrencyCompact_Organization"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FormattedFMV_Organization"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "IEquityOffer",
  "abstractKey": "__isIEquityOffer"
};
})();

(node as any).hash = "872f8c763ba341dde27359c1253002d0";

export default node;
