/**
 * @generated SignedSource<<b3aa872b93a8244018729fd9529d6d2c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UnderReview_FairMarketValues_Query$variables = {
  organizationId: string;
};
export type UnderReview_FairMarketValues_Query$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"UnderReview_FairMarketValues_Account">;
  };
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"UnderReview_FairMarketValues_Organization">;
  } | null;
};
export type UnderReview_FairMarketValues_Query = {
  response: UnderReview_FairMarketValues_Query$data;
  variables: UnderReview_FairMarketValues_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UnderReview_FairMarketValues_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UnderReview_FairMarketValues_Organization"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UnderReview_FairMarketValues_Account"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UnderReview_FairMarketValues_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FairMarketValue",
            "kind": "LinkedField",
            "name": "fairMarketValueHistory",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "date",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "valuationFirm",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "valuationReportDocument",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "downloadUrl",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToCarta",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "defaultCurrencyCode",
                "storageKey": null
              }
            ],
            "type": "IOrganization",
            "abstractKey": "__isIOrganization"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isSuperAdmin",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "47334239d212303ab330fc9336936b14",
    "id": null,
    "metadata": {},
    "name": "UnderReview_FairMarketValues_Query",
    "operationKind": "query",
    "text": "query UnderReview_FairMarketValues_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    id\n    name\n    ...UnderReview_FairMarketValues_Organization\n  }\n  me {\n    ...UnderReview_FairMarketValues_Account\n    id\n  }\n}\n\nfragment FairMarketValueApprovalTable_FairMarketValue on FairMarketValue {\n  id\n  date\n  value\n  valuationFirm\n  status\n  valuationReportDocument {\n    downloadUrl\n    id\n  }\n}\n\nfragment FairMarketValueApprovalTable_Organization on Organization {\n  ...FormattedFMV_Organization\n}\n\nfragment Footer_Organization on Organization {\n  isConnectedToCarta\n}\n\nfragment FormattedCurrency_Organization on IOrganization {\n  __isIOrganization: __typename\n  defaultCurrencyCode\n}\n\nfragment FormattedFMV_Organization on IOrganization {\n  __isIOrganization: __typename\n  ...FormattedCurrency_Organization\n}\n\nfragment LargeOneColumnLayout_Organization on Organization {\n  ...Footer_Organization\n}\n\nfragment LegalWorkflowLayout_Organization on Organization {\n  ...LargeOneColumnLayout_Organization\n}\n\nfragment UnderReview_FairMarketValues_Account on Account {\n  isSuperAdmin\n}\n\nfragment UnderReview_FairMarketValues_Organization on Organization {\n  id\n  name\n  fairMarketValueHistory {\n    id\n    status\n    ...FairMarketValueApprovalTable_FairMarketValue\n  }\n  ...LegalWorkflowLayout_Organization\n  ...FairMarketValueApprovalTable_Organization\n}\n"
  }
};
})();

(node as any).hash = "118186417cc292f0c943cd869eec0a4d";

export default node;
