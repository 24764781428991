/**
 * @generated SignedSource<<b8af1398ae1da4befd0b061d8b6c76ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type KeyEquityPlansParametersPage_Organization$data = {
  readonly id: string;
  readonly issuingCompany: {
    readonly city: string | null;
    readonly country: {
      readonly emoji: string;
      readonly name: string;
    } | null;
    readonly name: string | null;
  } | null;
  readonly latestFairMarketValue: {
    readonly value: number;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"IssuingCompanyCard_Organization" | "RemoteInputCurrency_Organization">;
  readonly " $fragmentType": "KeyEquityPlansParametersPage_Organization";
};
export type KeyEquityPlansParametersPage_Organization$key = {
  readonly " $data"?: KeyEquityPlansParametersPage_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"KeyEquityPlansParametersPage_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "KeyEquityPlansParametersPage_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "IssuingCompany",
      "kind": "LinkedField",
      "name": "issuingCompany",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Country",
          "kind": "LinkedField",
          "name": "country",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "emoji",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FairMarketValue",
      "kind": "LinkedField",
      "name": "latestFairMarketValue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "IssuingCompanyCard_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemoteInputCurrency_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "8d1830b18ea7ae44a7c5e8fb92b28229";

export default node;
