import { Avatar } from "@remote-com/norma";
import React, { useCallback } from "react";
import { useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { InviteAdminSlideOver } from "../../../components/InviteAdminSlideOver";
import { Page } from "../../../components/Page";
import { RemoteCompanyAdminsList } from "../../../components/RemoteCompanyAdminsList";
import { useToaster } from "../../../components/Toaster";
import { BreadCrumb } from "../../../components/ui/BreadCrumb";
import { Button } from "../../../components/ui/Button";
import { CenteredColumnLayout } from "../../../components/ui/Layout/CenteredColumnLayout";
import { NoticeMessage } from "../../../components/ui/NoticeMessage";
import { Toast } from "../../../components/ui/Toast";
import { Typography } from "../../../components/ui/Typography";
import { WarningButton } from "../../../components/ui/WarningButton";
import { useBoolean } from "../../../hooks/useBoolean";
import { useQuery } from "../../../hooks/useQuery";
import { useSafeMutation } from "../../../hooks/useSafeMutation";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { OrganizationSettingsAdmins_Account$key } from "./__generated__/OrganizationSettingsAdmins_Account.graphql";
import { OrganizationSettingsAdmins_Item_Account$key } from "./__generated__/OrganizationSettingsAdmins_Item_Account.graphql";
import { OrganizationSettingsAdmins_Item_Admin$key } from "./__generated__/OrganizationSettingsAdmins_Item_Admin.graphql";
import { OrganizationSettingsAdmins_Organization$key } from "./__generated__/OrganizationSettingsAdmins_Organization.graphql";
import { OrganizationSettingsAdmins_Query } from "./__generated__/OrganizationSettingsAdmins_Query.graphql";
import { OrganizationSettingsAdmins_RemoveAdmin_Mutation } from "./__generated__/OrganizationSettingsAdmins_RemoveAdmin_Mutation.graphql";
import { SettingsContainer } from "./SettingsContainer";

const REMOVE_ADMIN_MUTATION = graphql`
  mutation OrganizationSettingsAdmins_RemoveAdmin_Mutation($adminId: UUID!) {
    removeAdmin(id: $adminId) {
      ...OrganizationSettingsAdmins_Organization
    }
  }
`;

const ITEM_ADMIN_FRAGMENT = graphql`
  fragment OrganizationSettingsAdmins_Item_Admin on Admin {
    id
    account {
      id
      firstName
      lastName
      email
    }
  }
`;

const ITEM_VIEWER_FRAGMENT = graphql`
  fragment OrganizationSettingsAdmins_Item_Account on Account {
    id
  }
`;

const Item: React.FC<{
  adminFragment: OrganizationSettingsAdmins_Item_Admin$key;
  viewerFragment: OrganizationSettingsAdmins_Item_Account$key;
}> = ({ adminFragment, viewerFragment }) => {
  const admin = useFragment(ITEM_ADMIN_FRAGMENT, adminFragment);
  const viewer = useFragment(ITEM_VIEWER_FRAGMENT, viewerFragment);
  const toaster = useToaster();
  const [removeAdmin, removeAdminMutationIsInFlight] =
    useSafeMutation<OrganizationSettingsAdmins_RemoveAdmin_Mutation>(
      REMOVE_ADMIN_MUTATION,
    );
  const handleRemoveAdminButtonClick = useCallback(async () => {
    await removeAdmin({
      variables: { adminId: admin.id },
    });

    toaster.push(<Toast title="Great!">Admin successfully removed!</Toast>);
  }, [admin.id, removeAdmin, toaster]);

  const adminIsViewer = admin.account.id === viewer.id;

  return (
    <SettingsContainer.Item.MultipleValues.Item className="group flex items-center gap-2 transition-all hover:bg-gray-01">
      <Avatar name={admin.account.firstName} size="sm" />
      <div className="flex-1 space-y-1">
        <Typography as="div" variant="Regular/Extra Small">
          {admin.account.firstName} {admin.account.lastName}
        </Typography>
        <Typography
          as="div"
          className="text-black-05"
          variant="Regular/Extra Small"
        >
          {admin.account.email}
        </Typography>
      </div>
      {!adminIsViewer && (
        <WarningButton
          className="opacity-0 group-hover:opacity-100"
          loading={removeAdminMutationIsInFlight}
          onConfirm={handleRemoveAdminButtonClick}
          size="small"
          variant="Danger Outline"
          warningContent={`This is going to remove ${admin.account.firstName}
            ${admin.account.lastName} from your Admins. Please confirm this
            action`}
          warningTitle="Remove admin"
        >
          Remove
        </WarningButton>
      )}
    </SettingsContainer.Item.MultipleValues.Item>
  );
};

const ORGANIZATION_FRAGMENT = graphql`
  fragment OrganizationSettingsAdmins_Organization on Organization {
    id
    name
    adminInvitationIsAllowed
    admins {
      id
      ...OrganizationSettingsAdmins_Item_Admin
    }
    remoteCompanyAdmins(filters: { orderBy: { direction: ASC, field: name } }) {
      __typename
    }
    ...RemoteCompanyAdminsList_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment OrganizationSettingsAdmins_Account on Account {
    ...RemoteCompanyAdminsList_Account
    ...OrganizationSettingsAdmins_Item_Account
  }
`;

const OrganizationSettingsAdminsPage_: React.FC<{
  organizationFragment: OrganizationSettingsAdmins_Organization$key;
  viewerFragment: OrganizationSettingsAdmins_Account$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const {
    setFalse: hideInviteAdminSlideOver,
    setTrue: showInviteAdminSlideOver,
    value: inviteAdminSlideOverIsShown,
  } = useBoolean(false);

  return (
    <CenteredColumnLayout
      breadcrumbs={
        <BreadCrumb>
          <BreadCrumb.Link
            to={generatePath(APPLICATION_ROUTES.organizationHome, {
              organizationId: organization.id,
            })}
          >
            {organization.name}
          </BreadCrumb.Link>
          <BreadCrumb.Link to="..">Settings</BreadCrumb.Link>
          <BreadCrumb.Link to=".">Admins</BreadCrumb.Link>
        </BreadCrumb>
      }
    >
      <InviteAdminSlideOver
        onClose={hideInviteAdminSlideOver}
        organizationId={organization.id}
        show={inviteAdminSlideOverIsShown}
      />

      <SettingsContainer
        action={
          organization.adminInvitationIsAllowed && (
            <Button onClick={showInviteAdminSlideOver} size="small">
              + Invite new
            </Button>
          )
        }
        subTitle="Here is the list of users that have admin access and can create, manage and delete grants."
        title="Admins"
      >
        <SettingsContainer.Item>
          {organization.admins.length > 0 ? (
            <SettingsContainer.Item.MultipleValues>
              {organization.admins.map((admin) => (
                <Item
                  adminFragment={admin}
                  key={admin.id}
                  viewerFragment={viewer}
                />
              ))}
            </SettingsContainer.Item.MultipleValues>
          ) : (
            <NoticeMessage size="Small">You have no admins.</NoticeMessage>
          )}
        </SettingsContainer.Item>
        {organization.remoteCompanyAdmins.length > 0 && (
          <SettingsContainer.Item
            subTitle="By toggling on, your admins will be granted access to Remote Equity"
            title="Allow Remote users to access Remote Equity"
          >
            <RemoteCompanyAdminsList
              organizationFragment={organization}
              viewerFragment={viewer}
            />
          </SettingsContainer.Item>
        )}
      </SettingsContainer>
    </CenteredColumnLayout>
  );
};

const QUERY = graphql`
  query OrganizationSettingsAdmins_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...OrganizationSettingsAdmins_Organization
    }
    me {
      ...OrganizationSettingsAdmins_Account
    }
  }
`;

const OrganizationSettingsAdminsPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<OrganizationSettingsAdmins_Query>(QUERY, {
    organizationId,
  });

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Admin Settings"
      analyticsName="Admin - Admins Settings"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} organization admins`}
    >
      <OrganizationSettingsAdminsPage_
        organizationFragment={query.organization}
        viewerFragment={query.me}
      />
    </Page>
  );
};

export default OrganizationSettingsAdminsPage;
