/**
 * @generated SignedSource<<d543327b90df83502393310129958ef7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CTMSExerciseRequestStatus = "Cancelled" | "Done" | "PendingApproval" | "WaitingForCertificateSignature" | "WaitingForFunds";
import { FragmentRefs } from "relay-runtime";
export type CtmsGrantDetailsExercise_CTMSGrant$data = {
  readonly activePostTerminationExercisePeriod: {
    readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantActivePTEPValue_ActivePostTerminationExercisePeriod">;
  } | null;
  readonly cumulativeVested: number;
  readonly earlyExercise: boolean;
  readonly exercisableNonExercisedAmount: number | null;
  readonly exercisableNonExercisedShares: number;
  readonly exercisePrice: number | null;
  readonly exerciseRequests: ReadonlyArray<{
    readonly ctmsId: string;
    readonly quantityExercised: number;
    readonly status: CTMSExerciseRequestStatus;
    readonly totalExercisePrice: number;
  }>;
  readonly isVirtual: boolean;
  readonly lastDayToExerciseBasedOnTerminationDate: string | null;
  readonly quantityExercised: number;
  readonly quantityIssued: number;
  readonly settled: boolean;
  readonly totalExerciseAmount: number;
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseOverview_CTMSGrant" | "ExerciseStatusTag_CTMSGrant">;
  readonly " $fragmentType": "CtmsGrantDetailsExercise_CTMSGrant";
};
export type CtmsGrantDetailsExercise_CTMSGrant$key = {
  readonly " $data"?: CtmsGrantDetailsExercise_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantDetailsExercise_CTMSGrant">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantityExercised",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CtmsGrantDetailsExercise_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exercisePrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cumulativeVested",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exercisableNonExercisedAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVirtual",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "earlyExercise",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantityIssued",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exercisableNonExercisedShares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalExerciseAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CTMSExerciseRequest",
      "kind": "LinkedField",
      "name": "exerciseRequests",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ctmsId",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalExercisePrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ActivePostTerminationExercisePeriod",
      "kind": "LinkedField",
      "name": "activePostTerminationExercisePeriod",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CtmsGrantActivePTEPValue_ActivePostTerminationExercisePeriod"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastDayToExerciseBasedOnTerminationDate",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExerciseOverview_CTMSGrant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExerciseStatusTag_CTMSGrant"
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};
})();

(node as any).hash = "ee570b98175516e1c0874d9b78cf5d78";

export default node;
