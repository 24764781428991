import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { Avatar } from "@remote-com/norma";
import { useMemo, useState } from "react";
import { useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import { APPLICATION_ROUTES } from "../paths";
import {
  GranteesListSlideOver_Organization$data,
  GranteesListSlideOver_Organization$key,
} from "./__generated__/GranteesListSlideOver_Organization.graphql";
import {
  GranteesListSlideOver_Query,
  GranteesListSlideOver_Query$data,
} from "./__generated__/GranteesListSlideOver_Query.graphql";
import {
  LoadMoreListSlideOver,
  useLoadMoreSlideOverProperties,
} from "./LoadMoreListSlideOver";
import { NoticeMessage } from "./ui/NoticeMessage";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment GranteesListSlideOver_Organization on Organization {
    id
  }
`;

const QUERY = graphql`
  query GranteesListSlideOver_Query(
    $organizationId: OrganizationId!
    $granteesIds: [GranteeId!]!
    $search: String
    $take: Int!
    $skip: Int!
  ) {
    result: granteesForOrganizationFilterById(
      organizationId: $organizationId
      granteesIds: $granteesIds
      search: $search
      take: $take
      skip: $skip
    ) {
      # eslint-disable-next-line relay/unused-fields
      items: grantees {
        id
        name
        email
      }
      # eslint-disable-next-line relay/unused-fields
      total
    }
  }
`;

type State =
  | {
      granteesIds: null | string[];
      show: false;
      title?: null | React.ReactNode;
    }
  | {
      granteesIds: string[];
      show: true;
      title?: null | React.ReactNode;
    };

export const useGranteesListSlideOverState = () => {
  const [state, setState] = useState<State>({
    granteesIds: null,
    show: false,
    title: null,
  });

  const open = ({
    granteesIds,
    title,
  }: {
    granteesIds: string[];
    title?: null | React.ReactNode;
  }) => {
    setState({
      granteesIds,
      show: true,
      title,
    });
  };

  const close = () => {
    setState((previousState) => ({
      ...previousState,
      show: false,
    }));
  };

  return {
    close,
    open,
    state,
  };
};

const Row: React.FC<{
  grantee: GranteesListSlideOver_Query$data["result"]["items"][number];
  organization: GranteesListSlideOver_Organization$data;
}> = ({ grantee, organization }) => {
  return (
    <Link
      to={generatePath(APPLICATION_ROUTES.organizationGrantee, {
        granteeId: grantee.id,
        organizationId: organization.id,
      })}
    >
      <div className="group flex cursor-pointer items-center rounded border-[0.5px] border-transparent px-2 transition-all hover:border-gray-04">
        <Avatar name={grantee.name} size="sm" />
        <div className="flex-grow space-y-1 px-4 py-2">
          <Typography as="div" variant="Regular/Extra Small">
            {grantee.name}
          </Typography>
          <Typography
            as="div"
            className="text-black-05"
            variant="Regular/Caption"
          >
            {grantee.email}
          </Typography>
        </div>
        <div className="shrink-0 px-4">
          <ArrowTopRightOnSquareIcon className="h-5 w-5 text-primary opacity-0 transition-all group-hover:opacity-100" />
        </div>
      </div>
    </Link>
  );
};

export const GranteesListSlideOver: React.FC<{
  onClose: () => void;
  organizationFragment: GranteesListSlideOver_Organization$key;
  state: State;
}> = ({ onClose, organizationFragment, state }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const variables = useMemo(
    () =>
      state.granteesIds
        ? { granteesIds: state.granteesIds, organizationId: organization.id }
        : null,
    [state.granteesIds, organization.id],
  );

  const loadMoreSlideOverProps =
    useLoadMoreSlideOverProperties<GranteesListSlideOver_Query>({
      QUERY,
      variables,
    });

  return (
    <LoadMoreListSlideOver
      onClose={onClose}
      state={state}
      {...loadMoreSlideOverProps}
      noItemsMessage={
        <NoticeMessage size="Small">
          No grantees match your current filters.
        </NoticeMessage>
      }
      renderItems={(grantees) => (
        <div className="space-y-0">
          {grantees.map((grantee) => (
            <Row
              grantee={grantee}
              key={grantee.id}
              organization={organization}
            />
          ))}
        </div>
      )}
      searchBarPlaceholder="Search grantees..."
    />
  );
};
