import { graphql, useFragment } from "react-relay";

import { FormattedCurrencyCompact_Organization$key } from "./__generated__/FormattedCurrencyCompact_Organization.graphql";
import { FormattedCurrency, FormattedUSD } from "./FormattedCurrency";

const ORGANIZATION_FRAGMENT = graphql`
  fragment FormattedCurrencyCompact_Organization on IOrganization {
    ...FormattedCurrency_Organization
  }
`;

export const FormattedCurrencyCompact: React.FC<{
  animated?: boolean;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
  organizationFragment: FormattedCurrencyCompact_Organization$key;
  value: number;
}> = ({
  animated,
  maximumFractionDigits = 1,
  minimumFractionDigits = 1,
  organizationFragment,
  value,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <FormattedCurrency
      animated={animated}
      compactDisplay="short"
      maximumFractionDigits={maximumFractionDigits}
      minimumFractionDigits={minimumFractionDigits}
      notation="compact"
      organizationFragment={organization}
      value={value}
    />
  );
};

export const FormattedUSDCompact: React.FC<{
  animated?: boolean;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
  value: number;
}> = ({
  animated,
  maximumFractionDigits = 1,
  minimumFractionDigits = 1,
  value,
}) => {
  return (
    <FormattedUSD
      animated={animated}
      compactDisplay="short"
      maximumFractionDigits={maximumFractionDigits}
      minimumFractionDigits={minimumFractionDigits}
      notation="compact"
      value={value}
    />
  );
};
