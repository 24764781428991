import { useMemo } from "react";
import { useCallback } from "react";

import { getRandomRemoteColor } from "./getRandomRemoteColor";
import { getShadesOfColor } from "./getShadesOfColor";

export function getRandomColorPalette({
  paletteSize,
  seed,
}: {
  paletteSize: number;
  seed: string;
}) {
  return getShadesOfColor({
    baseColor: getRandomRemoteColor({ seed }),
    shadesCount: paletteSize,
  });
}

export function useGetRandomColor({
  paletteSize,
  seed,
}: {
  paletteSize: number;
  seed: string;
}) {
  const palette = useMemo(
    () => getRandomColorPalette({ paletteSize, seed }),
    [paletteSize, seed],
  );

  return useCallback(
    (index: number) => {
      const color = palette[index % palette.length];
      if (!color) {
        throw new Error("Unexpected: color is undefined");
      }

      return color;
    },
    [palette],
  );
}
