import classNames from "classnames";
import { motion } from "motion/react";
import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { graphql, useFragment } from "react-relay";

import { useOrganizationSharesUtil } from "../hooks/useOrganizationSharesUtil";
import { SelectedItemsActionCardWithNumbers_Organization$key } from "./__generated__/SelectedItemsActionCardWithNumbers_Organization.graphql";
import { FormattedCurrency } from "./Formatted/FormattedCurrency";
import { NoticeMessage } from "./ui/NoticeMessage";
import { RoundedBox } from "./ui/RoundedBox";
import { Typography } from "./ui/Typography";

export function SelectedItemsActionCard({
  actions,
  compact,
  emptyListLabel,
  itemCount,
  loading,
  pluralLabel,
  singularLabel,
}: {
  actions: React.ReactNode;
  compact?: boolean;
  emptyListLabel: React.ReactNode;

  itemCount: number;
  loading?: boolean;
  pluralLabel: React.ReactNode;
  singularLabel: React.ReactNode;
}) {
  return (
    <UISelectedItemsActionCard
      actions={actions}
      compact={compact}
      loading={loading}
    >
      {itemCount === 0 ? (
        <NoticeMessage hasColor={false} size="Small" variant="Info">
          {emptyListLabel}
        </NoticeMessage>
      ) : (
        <div className="flex items-center gap-2">
          <Typography
            as="div"
            className="flex items-center justify-center rounded-lg bg-gray-03 px-2.5"
            variant="Medium/Default"
          >
            <FormattedNumber value={itemCount} />
          </Typography>
          <Typography variant="Regular/Extra Small">
            {itemCount === 1 ? singularLabel : pluralLabel}
          </Typography>
        </div>
      )}
    </UISelectedItemsActionCard>
  );
}

export function SelectedItemsActionCardWithNumbers({
  actions,
  compact,
  emptyListLabel,
  loading,
  organizationFragment,
  shares,
}: {
  actions: React.ReactNode;
  compact?: boolean;
  emptyListLabel: React.ReactNode;
  loading?: boolean;
  organizationFragment: SelectedItemsActionCardWithNumbers_Organization$key;
  shares: number;
}) {
  const organization = useFragment(
    graphql`
      fragment SelectedItemsActionCardWithNumbers_Organization on Organization {
        ...useOrganizationSharesUtil_Organization
        ...FormattedCurrency_Organization
      }
    `,
    organizationFragment,
  );

  const { sharesToFullyDilutedRatio, sharesToValue } =
    useOrganizationSharesUtil({ organizationFragment: organization });

  const value = sharesToValue(shares);
  const ownershipRatio = sharesToFullyDilutedRatio(shares);

  return (
    <UISelectedItemsActionCard
      actions={actions}
      compact={compact}
      loading={loading}
    >
      {shares === 0 ? (
        <NoticeMessage hasColor={false} size="Small" variant="Info">
          {emptyListLabel}
        </NoticeMessage>
      ) : (
        <div className="space-y-0.5">
          <div className="text-remote-SM/Medium text-grey-900">
            <FormattedMessage
              defaultMessage="{shares, plural, one {# share} other {# shares}}"
              values={{
                shares,
              }}
            />
          </div>
          {value && (
            <div className="text-remote-XS/Normal">
              <FormattedCurrency
                minimumFractionDigits={0}
                organizationFragment={organization}
                value={value}
              />
            </div>
          )}
          {ownershipRatio && (
            <div className="text-remote-XS/Normal">
              <FormattedNumber style="percent" value={ownershipRatio} />
            </div>
          )}
        </div>
      )}
    </UISelectedItemsActionCard>
  );
}

export function UISelectedItemsActionCard({
  actions,
  children,
  compact,
  loading,
}: React.PropsWithChildren<{
  actions: React.ReactNode;
  compact?: boolean;

  loading?: boolean;
}>) {
  return (
    <motion.div
      className={classNames({
        "animate-pulse": loading,
        "min-w-[416px]": !compact,
      })}
      layout="position"
    >
      <RoundedBox withBorder={!compact} withShadow={!compact}>
        <div className="flex items-center justify-between gap-4 px-4 py-2">
          <div className="flex items-center gap-4">{children}</div>
          <div className="flex items-center gap-2">{actions}</div>
        </div>
      </RoundedBox>
    </motion.div>
  );
}
