import { compact } from "lodash";
import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { useGrantDocuments_EasopGrant$key } from "./__generated__/useGrantDocuments_EasopGrant.graphql";

const EASOP_GRANT_FRAGMENT = graphql`
  fragment useGrantDocuments_EasopGrant on EasopGrant {
    label
    grantee {
      name
    }
    grantNoticeDocument {
      downloadUrl
      updatedAt
    }
    awardAgreementDocument {
      downloadUrl
      updatedAt
    }
    signedBoardConsentDocument {
      downloadUrl
      updatedAt
    }
    countrySpecificAddendumDocument {
      downloadUrl
      updatedAt
    }
    formOfExerciseAgreementDocument {
      downloadUrl
      updatedAt
    }
    formOfEarlyExerciseStockPurchaseAgreementDocument {
      downloadUrl
      updatedAt
    }
  }
`;

export type DocumentTemplateType =
  | "AWARD_AGREEMENT"
  | "BOARD_CONSENT"
  | "COUNTRY_SPECIFIC_ADDENDUM"
  | "FORM_OF_EARLY_EXERCISE_STOCK_PURCHASE_AGREEMENT"
  | "FORM_OF_EXERCISE_AGREEMENT"
  | "GRANT_DOCUMENT"
  | "GRANT_NOTICE";

export const useGrantDocuments = ({
  easopGrantFragment,
}: {
  easopGrantFragment: null | useGrantDocuments_EasopGrant$key;
}) => {
  const easopGrant = useFragment(EASOP_GRANT_FRAGMENT, easopGrantFragment);

  const buildItem = useMemo(
    () =>
      ({
        document,
        label,
        type,
      }: {
        document?: null | { downloadUrl: string; updatedAt?: null | string };
        label: string;
        type: DocumentTemplateType;
      }) =>
        document && easopGrant
          ? {
              downloadUrl: document.downloadUrl,
              fileName: `${easopGrant.grantee.name} - ${easopGrant.label} - ${label}.pdf`,
              label,
              type,
              updatedAt: document.updatedAt,
            }
          : null,
    [easopGrant],
  );

  return useMemo(
    () =>
      easopGrant
        ? compact([
            buildItem({
              document: easopGrant.awardAgreementDocument,
              label: "Award agreement",
              type: "AWARD_AGREEMENT",
            }),
            buildItem({
              document: easopGrant.countrySpecificAddendumDocument,
              label: "Country specific addendum",
              type: "COUNTRY_SPECIFIC_ADDENDUM",
            }),
            buildItem({
              document: easopGrant.formOfExerciseAgreementDocument,
              label: "Form of exercise agreement",
              type: "FORM_OF_EXERCISE_AGREEMENT",
            }),
            buildItem({
              document:
                easopGrant.formOfEarlyExerciseStockPurchaseAgreementDocument,
              label: "Form of early exercise stock purchase agreement",
              type: "FORM_OF_EARLY_EXERCISE_STOCK_PURCHASE_AGREEMENT",
            }),
            buildItem({
              document: easopGrant.grantNoticeDocument,
              label: "Grant notice",
              type: "GRANT_NOTICE",
            }),
            buildItem({
              document: easopGrant.signedBoardConsentDocument,
              label: "Signed board consent",
              type: "BOARD_CONSENT",
            }),
          ])
        : [],
    [buildItem, easopGrant],
  );
};
