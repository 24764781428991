import { Pill } from "@remote-com/norma";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { GranteeStatusTag_Grantee$key } from "./__generated__/GranteeStatusTag_Grantee.graphql";

const GRANTEES_FRAGMENT = graphql`
  fragment GranteeStatusTag_Grantee on Grantee {
    status
    hasSettlingGrant
  }
`;

export const GranteeStatusTag: React.FC<{
  granteeFragment: GranteeStatusTag_Grantee$key;
}> = ({ granteeFragment }) => {
  const grantee = useFragment(GRANTEES_FRAGMENT, granteeFragment);
  if (grantee.status === "Active") {
    return <Pill tone="success">Active</Pill>;
  }

  if (grantee.hasSettlingGrant) {
    return <Pill tone="warning">Settling</Pill>;
  }

  return <Pill tone="error">Settled</Pill>;
};
