/* eslint-disable relay/unused-fields */
import { IconV2DuotoneAlertTriangle } from "@remote-com/norma/icons/IconV2DuotoneAlertTriangle";
import { IconV2DuotoneBellCheck } from "@remote-com/norma/icons/IconV2DuotoneBellCheck";
import { IconV2DuotoneBilling } from "@remote-com/norma/icons/IconV2DuotoneBilling";
import { IconV2DuotoneBriefcase } from "@remote-com/norma/icons/IconV2DuotoneBriefcase";
import { IconV2DuotoneBuilding } from "@remote-com/norma/icons/IconV2DuotoneBuilding";
import { IconV2DuotoneCalendarDates } from "@remote-com/norma/icons/IconV2DuotoneCalendarDates";
import { IconV2DuotoneClock } from "@remote-com/norma/icons/IconV2DuotoneClock";
import { IconV2DuotoneDiscount } from "@remote-com/norma/icons/IconV2DuotoneDiscount";
import { IconV2DuotoneFileSnake } from "@remote-com/norma/icons/IconV2DuotoneFileSnake";
import { IconV2DuotoneGift } from "@remote-com/norma/icons/IconV2DuotoneGift";
import { IconV2DuotoneReceipt } from "@remote-com/norma/icons/IconV2DuotoneReceipt";
import { IconV2DuotoneUserHeart } from "@remote-com/norma/icons/IconV2DuotoneUserHeart";
import { IconV2OutlineAlertTriangle } from "@remote-com/norma/icons/IconV2OutlineAlertTriangle";
import { IconV2OutlineBellCheck } from "@remote-com/norma/icons/IconV2OutlineBellCheck";
import { IconV2OutlineBriefcase } from "@remote-com/norma/icons/IconV2OutlineBriefcase";
import { IconV2OutlineBuilding } from "@remote-com/norma/icons/IconV2OutlineBuilding";
import { IconV2OutlineUserHeart } from "@remote-com/norma/icons/IconV2OutlineUserHeart";
import { ElementType } from "react";
import { graphql } from "react-relay";

import { EquityHandbookService_EquityTypeHandbook$data } from "./__generated__/EquityHandbookService_EquityTypeHandbook.graphql";

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
graphql`
  fragment EquityHandbookService_EquityTypeHandbook on EquityTypeHandbook {
    id
    publishedAt
    published
    lastModifiedAt
    sectionEvents {
      section
      event
      enabled
      nothingToDeclare
      items {
        category
        content
        enabled
      }
    }
  }
`;

export type Category = Item["category"];
export type EquityTypeHandbook = EquityHandbookService_EquityTypeHandbook$data;
export type Event = EquityTypeHandbook["sectionEvents"][number]["event"];
export type Section = EquityTypeHandbook["sectionEvents"][number]["section"];

type Item = EquityTypeHandbook["sectionEvents"][number]["items"][number];

export const SECTIONS: Section[] = [
  "Taxation",
  "Reporting",
  "TaxBenefitsAndWatchouts",
];

interface Icons {
  activeIcon: ElementType;
  icon: ElementType;
}

export const SECTIONS_MAP: Record<
  Section,
  {
    icon: ElementType;
    itemCategories: Category[];
    itemsCategoriesTitle: string;
    nothingToDeclareEditionLabel: string;
    nothingToDeclareLabel: string;
    somethingToDeclareLabel: string;
    title: string;
  }
> = {
  Reporting: {
    icon: IconV2DuotoneFileSnake,
    itemCategories: ["Employee", "Employer", "Issuer"],
    itemsCategoriesTitle: "Persona",
    nothingToDeclareEditionLabel: "No reporting",
    nothingToDeclareLabel: "No reporting",
    somethingToDeclareLabel: "Reporting event",
    title: "Reporting",
  },
  Taxation: {
    icon: IconV2DuotoneDiscount,
    itemCategories: ["Employee", "Employer"],
    itemsCategoriesTitle: "Persona",
    nothingToDeclareEditionLabel: "No taxation",
    nothingToDeclareLabel: "No taxation",
    somethingToDeclareLabel: "Taxation event",
    title: "Taxation",
  },
  TaxBenefitsAndWatchouts: {
    icon: IconV2DuotoneBellCheck,
    itemCategories: ["PotentialTaxBenefit", "PotentialWatchout"],
    itemsCategoriesTitle: "Type",
    nothingToDeclareEditionLabel: "No event",
    nothingToDeclareLabel: "No tax benefit or watch out",
    somethingToDeclareLabel: "Event",
    title: "Tax Benefit and Watch Out",
  },
};

export const EVENTS_MAP: Record<
  Event,
  { editionLabel: string; icon: ElementType; label: string }
> = {
  Exercise: {
    editionLabel: "Exercise",
    icon: IconV2DuotoneReceipt,
    label: "At exercise",
  },
  Grant: {
    editionLabel: "Grant",
    icon: IconV2DuotoneGift,
    label: "At the time of grant",
  },
  Holding: {
    editionLabel: "Holding",
    icon: IconV2DuotoneClock,
    label: "While holding",
  },
  Sale: {
    editionLabel: "Sale",
    icon: IconV2DuotoneBilling,
    label: "At sale",
  },
  Vesting: {
    editionLabel: "Vesting",
    icon: IconV2DuotoneCalendarDates,
    label: "During vesting",
  },
};

export const CATEGORIES_MAP: Record<
  Category,
  { icons: Icons; label: string; tone: "brand" | "green" | "red" }
> = {
  Employee: {
    icons: {
      activeIcon: IconV2DuotoneUserHeart,
      icon: IconV2OutlineUserHeart,
    },
    label: "Employee",
    tone: "brand",
  },
  Employer: {
    icons: {
      activeIcon: IconV2DuotoneBriefcase,
      icon: IconV2OutlineBriefcase,
    },
    label: "Employer",
    tone: "brand",
  },
  Issuer: {
    icons: { activeIcon: IconV2DuotoneBuilding, icon: IconV2OutlineBuilding },
    label: "Issuer",
    tone: "brand",
  },
  PotentialTaxBenefit: {
    icons: {
      activeIcon: IconV2DuotoneBellCheck,
      icon: IconV2OutlineBellCheck,
    },
    label: "Potential tax benefit",
    tone: "green",
  },
  PotentialWatchout: {
    icons: {
      activeIcon: IconV2DuotoneAlertTriangle,
      icon: IconV2OutlineAlertTriangle,
    },
    label: "Potential watchout",
    tone: "red",
  },
};

export const EVENTS: Event[] = [
  "Grant",
  "Vesting",
  "Exercise",
  "Holding",
  "Sale",
];

export const CATEGORIES: Category[] = [
  "Employee",
  "Employer",
  "Issuer",
  "PotentialTaxBenefit",
  "PotentialWatchout",
];
