import { PlusIcon } from "@heroicons/react/24/outline";
import { useMemo, useTransition } from "react";
import {
  Control,
  FieldPath,
  FieldValues,
  useController,
} from "react-hook-form";
import { useRefetchableFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { CreateSlideOverRemote } from "../../../pages/Admin/Grants/Configure/VestingSchedules/CreateSlideOver";
import { Button } from "../Button";
import { RemoteSelectVestingScheduleInput_Organization$key } from "./__generated__/RemoteSelectVestingScheduleInput_Organization.graphql";
import { RemoteInputSelect } from "./Inputs/RemoteInputSelect";

const ORGANIZATION_FRAGMENT = graphql`
  fragment RemoteSelectVestingScheduleInput_Organization on Organization
  @refetchable(
    queryName: "RemoteSelectVestingScheduleInput_Organization_RefetchQuery"
  ) {
    id
    vestingSchedules {
      id
      name
    }
    ...CreateSlideOver_VestingSchedule_Organization
  }
`;

const FormInput = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  disabled,
  label,
  name,
  organizationFragment,
}: {
  control: Control<TFieldValues>;
  disabled?: boolean;
  label: string;
  name: TName;
  organizationFragment: RemoteSelectVestingScheduleInput_Organization$key;
}) => {
  const [organization, refetchOrganization] = useRefetchableFragment(
    ORGANIZATION_FRAGMENT,
    organizationFragment,
  );

  const createSlideOverController = CreateSlideOverRemote.useController();
  const [transitionInProgress, startTransition] = useTransition();

  const { field } = useController({ control, name });

  const handleNewButtonClicked = () => {
    createSlideOverController.open({
      data: {
        organizationFragment: organization,
      },
      onClose: ({ vestingScheduleId }) => {
        if (vestingScheduleId) {
          startTransition(() => {
            refetchOrganization(
              {},
              {
                fetchPolicy: "network-only",
              },
            );
            field.onChange(vestingScheduleId);
          });
        }
      },
    });
  };

  const options = useMemo(() => {
    return organization.vestingSchedules.map((vestingSchedule) => ({
      label: vestingSchedule.name,
      value: vestingSchedule.id,
    }));
  }, [organization.vestingSchedules]);

  return (
    <div className="space-y-4">
      <RemoteInputSelect.Form
        control={control}
        disabled={disabled}
        label={label}
        name={name}
        options={options}
      />
      {!disabled && (
        <Button
          leftIcon={<PlusIcon />}
          loading={transitionInProgress}
          onClick={handleNewButtonClicked}
          size="extra small"
          type="button"
          variant="Tertiary Link"
        >
          Add new vesting schedule
        </Button>
      )}
    </div>
  );
};

export const RemoteSelectVestingScheduleInput = {
  Form: FormInput,
};
