import { graphql } from "relay-runtime";

import { GranteePortalHydratedContentTab } from "../../components/GranteePortalHydratedContentTab";
import { Page } from "../../components/Page";
import { useGranteePortalGrantType } from "../../hooks/useGranteePortal";
import { useQuery } from "../../hooks/useQuery";
import { useGranteeLayoutContext } from "../../layouts/GranteeEquityTypesGroupLayout/GranteeEquityTypesGroupLayout";
import { useGranteeIdParam } from "../../paths";
import NotFoundPage from "../NotFound/NotFound";
import { Reporting_Grantee_Query } from "./__generated__/Reporting_Grantee_Query.graphql";

const QUERY = graphql`
  query Reporting_Grantee_Query(
    $granteeId: GranteeId!
    $grantType: GrantType!
  ) {
    grantee(id: $granteeId) {
      ...GranteePortalHydratedContentTab_Grantee
      organization {
        id
        name
      }
      ctmsGrants(grantType: $grantType) {
        ...GranteePortalHydratedContentTab_Reporting_CTMSGrant
      }
    }
  }
`;

const GranteeReportingPage: React.FC = () => {
  const granteeId = useGranteeIdParam();
  const grantType = useGranteePortalGrantType();
  const { query } = useQuery<Reporting_Grantee_Query>(QUERY, {
    granteeId,
    grantType,
  });
  const { isExpertMode } = useGranteeLayoutContext();

  if (!query.grantee) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Grantee view"
      analyticsName="Grantee - Reporting"
      analyticsProperties={{
        expertiseMode: isExpertMode ? "expert" : "beginner",
      }}
      organizationId={query.grantee.organization.id}
      title={`Grantee | ${query.grantee.organization.name} reporting`}
    >
      <GranteePortalHydratedContentTab
        ctmsGrantsFragment={query.grantee.ctmsGrants}
        granteeFragment={query.grantee}
        section="reporting"
      />
    </Page>
  );
};

export default GranteeReportingPage;
