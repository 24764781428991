/**
 * @generated SignedSource<<60f198f01c128651d81366acbf77729d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AccelerationClause = "DoubleTrigger100" | "DoubleTrigger25" | "DoubleTrigger35" | "DoubleTrigger50" | "SingleTrigger";
export type EquityTypeName = "BSPCE" | "EMI" | "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
import { FragmentRefs } from "relay-runtime";
export type GrantDeclareNew_Organization$data = {
  readonly ctmsGrants: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly label: string;
      };
    }>;
  };
  readonly esopPoolShares: number | null;
  readonly grantees: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly ctmsGrantsCount: number;
        readonly defaultAccelerationClause: AccelerationClause | null;
        readonly defaultPostTerminationExercisePeriod: {
          readonly id: string;
        } | null;
        readonly defaultVestingSchedule: {
          readonly id: string;
        } | null;
        readonly earlyExerciseIsAllowedByDefault: boolean;
        readonly equityInformationProvidedDuringRemoteOnboarding: string | null;
        readonly equityNeedsReview: boolean;
        readonly id: string;
        readonly instruments: ReadonlyArray<{
          readonly equityType: {
            readonly exercisable: boolean;
            readonly humanReadableName: string;
            readonly name: EquityTypeName;
          } | null;
        }>;
        readonly name: string;
        readonly taxResidenceCountry: {
          readonly emoji: string;
        } | null;
      };
    }>;
  };
  readonly id: string;
  readonly latestFairMarketValue: {
    readonly value: number;
  } | null;
  readonly postTerminationExercisePeriods: ReadonlyArray<{
    readonly displayName: string;
    readonly id: string;
  }>;
  readonly vestingSchedules: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"FormattedCurrency_Organization" | "GrantDeclarationBillingInformationSection_Organization" | "RemoteInputCurrency_Organization" | "RemoteSelectOrganizationPostTerminationExercisePeriod_Organization" | "RemoteSelectVestingScheduleInput_Organization" | "VestingPreviewGraph_Organization">;
  readonly " $fragmentType": "GrantDeclareNew_Organization";
};
export type GrantDeclareNew_Organization$key = {
  readonly " $data"?: GrantDeclareNew_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantDeclareNew_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantDeclareNew_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "esopPoolShares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FairMarketValue",
      "kind": "LinkedField",
      "name": "latestFairMarketValue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "vestingSchedules",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "postTerminationExercisePeriods",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GranteesConnection",
      "kind": "LinkedField",
      "name": "grantees",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GranteeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Grantee",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Country",
                  "kind": "LinkedField",
                  "name": "taxResidenceCountry",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "emoji",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "equityInformationProvidedDuringRemoteOnboarding",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "defaultPostTerminationExercisePeriod",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "defaultVestingSchedule",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "equityNeedsReview",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "defaultAccelerationClause",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "earlyExerciseIsAllowedByDefault",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "ctmsGrantsCount",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Instrument",
                  "kind": "LinkedField",
                  "name": "instruments",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "EquityType",
                      "kind": "LinkedField",
                      "name": "equityType",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "humanReadableName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "exercisable",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationCTMSGrantsConnection",
      "kind": "LinkedField",
      "name": "ctmsGrants",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationCTMSGrantsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CTMSGrant",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "label",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemoteSelectVestingScheduleInput_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemoteSelectOrganizationPostTerminationExercisePeriod_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantDeclarationBillingInformationSection_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VestingPreviewGraph_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedCurrency_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemoteInputCurrency_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "6c3f1bd57dd9a86beea45027345375d2";

export default node;
