/**
 * @generated SignedSource<<9207a95eb0444ae789b90e15599ee19e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RepricedFromCtmsGrantNoticeMessage_CtmsGrant$data = {
  readonly id: string;
  readonly label: string;
  readonly organizationId: string;
  readonly " $fragmentType": "RepricedFromCtmsGrantNoticeMessage_CtmsGrant";
};
export type RepricedFromCtmsGrantNoticeMessage_CtmsGrant$key = {
  readonly " $data"?: RepricedFromCtmsGrantNoticeMessage_CtmsGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"RepricedFromCtmsGrantNoticeMessage_CtmsGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RepricedFromCtmsGrantNoticeMessage_CtmsGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationId",
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "478448c6cc0a15a4fb12e037bf65bd69";

export default node;
