/**
 * @generated SignedSource<<02f6b42aa5d6f2aa6de75d5fc9783f8b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CtmsGrantsTableRow_Organization$data = {
  readonly id: string;
  readonly isRemoteEquityEssentials: boolean;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"FormattedFMV_Organization" | "MissingInformationTag_MissingCTMSInformationTag_Organization" | "TableCellSharesOverview_Organization">;
  readonly " $fragmentType": "CtmsGrantsTableRow_Organization";
};
export type CtmsGrantsTableRow_Organization$key = {
  readonly " $data"?: CtmsGrantsTableRow_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantsTableRow_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CtmsGrantsTableRow_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRemoteEquityEssentials",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TableCellSharesOverview_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MissingInformationTag_MissingCTMSInformationTag_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedFMV_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "a7f538b6f56ab9d3b910c4fe3977e59f";

export default node;
