/**
 * @generated SignedSource<<8ee3fcfd027ce3006aeb1229e1aaaec7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoteEquityOnboardingLayout_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"RemoteLikeApplicationLayout_Organization">;
  readonly " $fragmentType": "RemoteEquityOnboardingLayout_Organization";
};
export type RemoteEquityOnboardingLayout_Organization$key = {
  readonly " $data"?: RemoteEquityOnboardingLayout_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteEquityOnboardingLayout_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteEquityOnboardingLayout_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemoteLikeApplicationLayout_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "02e2fe7ff7a3720288ca43d10e0c856c";

export default node;
