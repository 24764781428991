import { Pill, StandaloneLink } from "@remote-com/norma";
import { IconV2OutlineArrowUpRight } from "@remote-com/norma/icons/IconV2OutlineArrowUpRight";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { isPast } from "date-fns";
import { useMemo } from "react";
import { FormattedDate } from "react-intl";
import { useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import {
  BentoCard,
  BentoCardCell,
  BentoCardColumn,
  BentoCardRow,
  BentoCardValue,
} from "../../../components/BentoCard";
import { CurrencyUnit } from "../../../components/CurrencyUnit";
import { FairMarketValueExplanationDrawer } from "../../../components/FairMarketValueExplanationDrawer";
import { FairMarketValueHistoryChart } from "../../../components/FairMarketValueHistoryChart";
import { FormattedCurrency } from "../../../components/Formatted/FormattedCurrency";
import { Page } from "../../../components/Page";
import { ShortDate } from "../../../components/ShortDate";
import { LinkButton } from "../../../components/ui/Button";
import { LargeOneColumnLayout } from "../../../components/ui/Layout/LargeOneColumnLayout";
import { Table } from "../../../components/ui/Table";
import { TabNavigation } from "../../../components/ui/TabNavigation";
import { useQuery } from "../../../hooks/useQuery";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import {
  FairMarketValuePage_CompanySettings_Organization$data,
  FairMarketValuePage_CompanySettings_Organization$key,
} from "./__generated__/FairMarketValuePage_CompanySettings_Organization.graphql";
import { FairMarketValuePage_CompanySettings_Query } from "./__generated__/FairMarketValuePage_CompanySettings_Query.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment FairMarketValuePage_CompanySettings_Organization on Organization {
    id
    name
    fairMarketValueHistory {
      value
      date
      expirationDate
      isLatest
    }
    latestFairMarketValue {
      value
      date
    }
    ...LargeOneColumnLayout_Organization
    ...FairMarketValueHistoryChart_Organization
    ...FormattedCurrency_Organization
    ...CurrencyUnit_Organization
  }
`;

type FairMarketValue =
  FairMarketValuePage_CompanySettings_Organization$data["fairMarketValueHistory"][number];

const columnHelper = createColumnHelper<FairMarketValue>();

const FairMarketValuePage_: React.FC<{
  organizationFragment: FairMarketValuePage_CompanySettings_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.value, {
        cell: (context) => (
          <FormattedCurrency
            organizationFragment={organization}
            value={context.getValue()}
          />
        ),
        header: () => "Share price",
        id: "share_price",
      }),
      columnHelper.accessor((row) => row.date, {
        cell: (context) => <ShortDate value={context.getValue()} />,
        header: () => "Valuation date",
        id: "valuation_date",
      }),
      columnHelper.display({
        cell: (context) => {
          const fairMarketValue = context.row.original;

          if (
            !fairMarketValue.isLatest ||
            isPast(fairMarketValue.expirationDate)
          ) {
            return <Pill tone="warning">Outdated</Pill>;
          }

          return <Pill tone="success">Current</Pill>;
        },
        header: () => "Status",
        id: "status",
        meta: {
          alignRight: true,
        },
      }),
    ],
    [],
  );

  const data = useMemo(
    () => [...organization.fairMarketValueHistory],
    [organization.fairMarketValueHistory],
  );

  const table = useReactTable({
    columns,
    data,
    enableGlobalFilter: false,
    enableRowSelection: false,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <LargeOneColumnLayout
      maxWidth={1200}
      organizationFragment={organization}
      showFooter
      title="Fair market value"
      topBarActionsRender={() => (
        <LinkButton
          to={generatePath(
            APPLICATION_ROUTES.organizationCompanySettingsAddNewFairMarketValue,
            {
              organizationId: organization.id,
            },
          )}
        >
          New fair market value
        </LinkButton>
      )}
    >
      <div className="space-y-6">
        <BentoCard>
          <BentoCardRow>
            <BentoCardColumn>
              <BentoCardCell title="CURRENT FAIR MARKET VALUE">
                <div className="space-y-2">
                  <BentoCardValue
                    main={
                      organization.latestFairMarketValue ? (
                        <FormattedCurrency
                          animated
                          organizationFragment={organization}
                          value={organization.latestFairMarketValue.value}
                        />
                      ) : (
                        "-"
                      )
                    }
                    sub={<CurrencyUnit organizationFragment={organization} />}
                  />
                  <FairMarketValueExplanationDrawer>
                    <StandaloneLink
                      IconAfter={IconV2OutlineArrowUpRight}
                      size="sm"
                    >
                      What is a fair market value and how is it determined?
                    </StandaloneLink>
                  </FairMarketValueExplanationDrawer>
                </div>
              </BentoCardCell>
              <BentoCardCell title="Date of valuation">
                <BentoCardValue
                  main={
                    organization.latestFairMarketValue ? (
                      <FormattedDate
                        day="2-digit"
                        month="short"
                        timeZone="UTC"
                        value={organization.latestFairMarketValue.date}
                      />
                    ) : (
                      "-"
                    )
                  }
                  sub={
                    organization.latestFairMarketValue && (
                      <FormattedDate
                        timeZone="UTC"
                        value={organization.latestFairMarketValue.date}
                        year="numeric"
                      />
                    )
                  }
                />
              </BentoCardCell>
            </BentoCardColumn>
            <BentoCardColumn>
              <BentoCardCell title="Share price Evolution">
                <FairMarketValueHistoryChart
                  organizationFragment={organization}
                />
              </BentoCardCell>
            </BentoCardColumn>
          </BentoCardRow>
        </BentoCard>
        <TabNavigation layoutId="fair-market-value-tab-navigation">
          <TabNavigation.Link
            to={generatePath(
              APPLICATION_ROUTES.organizationCompanySettingsFairMarketValue,
              {
                organizationId: organization.id,
              },
            )}
          >
            History
          </TabNavigation.Link>
        </TabNavigation>
        <Table.Smart
          table={table}
          tableClassName="grid-cols-3"
          tableDisplay="grid"
        />
      </div>
    </LargeOneColumnLayout>
  );
};

const QUERY = graphql`
  query FairMarketValuePage_CompanySettings_Query(
    $organizationId: OrganizationId!
  ) {
    organization(id: $organizationId) {
      id
      name
      ...FairMarketValuePage_CompanySettings_Organization
    }
  }
`;

export const FairMarketValuePage: React.FC = () => {
  const organizationId = useOrganizationIdParam();

  const {
    query: { organization },
  } = useQuery<FairMarketValuePage_CompanySettings_Query>(QUERY, {
    organizationId,
  });

  if (!organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Admin Company Settings"
      analyticsName="Admin - Company Settings - Fair Market Value"
      organizationId={organization.id}
      title={`Admin | ${organization.name} fair market value`}
    >
      <FairMarketValuePage_ organizationFragment={organization} />
    </Page>
  );
};
