import { InfoBlock, ListItem } from "@remote-com/norma";
import { compact } from "lodash";
import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { ShortDate } from "../../../../components/ShortDate";
import { HRIS_PROVIDER_MAP } from "../../../../hooks/useHRISProvider";
import { WORK_RELATIONSHIP_TO_LABEL_HELPER } from "../../../../services/workRelationship";
import { Section } from "../../Grantee/GranteeDetails/Section";
import { GrantDetailsEmployeeSection_CTMSGrant$key } from "./__generated__/GrantDetailsEmployeeSection_CTMSGrant.graphql";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment GrantDetailsEmployeeSection_CTMSGrant on CTMSGrant {
    grantee {
      name
      email
      contractStartDate
      jobTitle
      workRelationship
      taxResidenceCountry {
        name
        emoji
      }
      hRISProviderEmployee {
        hRISProvider
      }
    }
  }
`;

export function GrantDetailsEmployeeSection({
  className,
  ctmsGrantFragment,
}: {
  className?: string;
  ctmsGrantFragment: GrantDetailsEmployeeSection_CTMSGrant$key;
}) {
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);

  const infoBlockList = useMemo((): ListItem[] => {
    return compact([
      {
        title: "Full name",
        value: ctmsGrant.grantee.name,
      },
      {
        title: "Email",
        value: ctmsGrant.grantee.email,
      },
      {
        title: "Country of residence",
        value: ctmsGrant.grantee.taxResidenceCountry ? (
          <span>
            {ctmsGrant.grantee.taxResidenceCountry.emoji}{" "}
            {ctmsGrant.grantee.taxResidenceCountry.name}
          </span>
        ) : undefined,
      },
      {
        title: "Contract start date",
        value: ctmsGrant.grantee.contractStartDate ? (
          <ShortDate value={ctmsGrant.grantee.contractStartDate} />
        ) : undefined,
      },
      {
        title: "Job title",
        value: ctmsGrant.grantee.jobTitle ?? undefined,
      },
      {
        title: "Work relationship",
        value: ctmsGrant.grantee.workRelationship
          ? WORK_RELATIONSHIP_TO_LABEL_HELPER[
              ctmsGrant.grantee.workRelationship
            ].singularLabel
          : undefined,
      },
      ctmsGrant.grantee.hRISProviderEmployee
        ? {
            title: "HRIS provider",
            value:
              HRIS_PROVIDER_MAP[
                ctmsGrant.grantee.hRISProviderEmployee.hRISProvider
              ],
          }
        : undefined,
    ]);
  }, [ctmsGrant]);

  return (
    <Section className={className} title="Employee details">
      <InfoBlock list={infoBlockList} />
    </Section>
  );
}
