/**
 * @generated SignedSource<<a254bb52b3eed73385d4502f1413b506>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type EquityHandbooksCountryLayout_Query$variables = {
  countryCode: string;
  organizationId: string;
};
export type EquityHandbooksCountryLayout_Query$data = {
  readonly organizationGeography: {
    readonly country: {
      readonly code: string;
      readonly displayName: string;
    };
    readonly geography: {
      readonly equityTypes: ReadonlyArray<{
        readonly humanReadableName: string;
        readonly id: string;
      }>;
    };
  } | null;
};
export type EquityHandbooksCountryLayout_Query = {
  response: EquityHandbooksCountryLayout_Query$data;
  variables: EquityHandbooksCountryLayout_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "countryCode"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "countryCode",
    "variableName": "countryCode"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Country",
  "kind": "LinkedField",
  "name": "country",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Geography",
  "kind": "LinkedField",
  "name": "geography",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityType",
      "kind": "LinkedField",
      "name": "equityTypes",
      "plural": true,
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "humanReadableName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EquityHandbooksCountryLayout_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "OrganizationGeography",
        "kind": "LinkedField",
        "name": "organizationGeography",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EquityHandbooksCountryLayout_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "OrganizationGeography",
        "kind": "LinkedField",
        "name": "organizationGeography",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v5/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bc7c5b2e5fc23ff029ba601c4a527eda",
    "id": null,
    "metadata": {},
    "name": "EquityHandbooksCountryLayout_Query",
    "operationKind": "query",
    "text": "query EquityHandbooksCountryLayout_Query(\n  $organizationId: OrganizationId!\n  $countryCode: String!\n) {\n  organizationGeography(countryCode: $countryCode, organizationId: $organizationId) {\n    country {\n      code\n      displayName\n    }\n    geography {\n      equityTypes {\n        id\n        humanReadableName\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e69fc126d262518c314c70625fff7fd6";

export default node;
