/**
 * @generated SignedSource<<ae7b6a86bb2bc7b2bac680582e267e1c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CTMSExerciseRequestStatus = "Cancelled" | "Done" | "PendingApproval" | "WaitingForCertificateSignature" | "WaitingForFunds";
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type ExercisesTable_Exercises$data = ReadonlyArray<{
  readonly ctmsGrant: {
    readonly grantee: {
      readonly name: string;
      readonly workRelationship: WorkRelationship | null;
      readonly " $fragmentSpreads": FragmentRefs<"GranteeNameWithCountryFlag_Grantee">;
    };
  };
  readonly ctmsGrantLabel: string;
  readonly ctmsId: string;
  readonly fundsTransferredAt: string | null;
  readonly isInProgress: boolean;
  readonly quantityExercised: number;
  readonly requestSubmittedAt: string;
  readonly spread: number | null;
  readonly status: CTMSExerciseRequestStatus;
  readonly totalExercisePrice: number;
  readonly " $fragmentType": "ExercisesTable_Exercises";
}>;
export type ExercisesTable_Exercises$key = ReadonlyArray<{
  readonly " $data"?: ExercisesTable_Exercises$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExercisesTable_Exercises">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ExercisesTable_Exercises",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctmsId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctmsGrantLabel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isInProgress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requestSubmittedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalExercisePrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantityExercised",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "spread",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fundsTransferredAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "ctmsGrant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Grantee",
          "kind": "LinkedField",
          "name": "grantee",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "workRelationship",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "GranteeNameWithCountryFlag_Grantee"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CTMSExerciseRequest",
  "abstractKey": null
};

(node as any).hash = "b7c01e6f99974f2a76201700dbc3b975";

export default node;
