/**
 * @generated SignedSource<<85f6bcd25c2ed59c92217224343cf02d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmployeePortalLayout_Viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ApplicationSideBar_Viewer" | "EmployeePortalLayout_TopBar_Account">;
  readonly " $fragmentType": "EmployeePortalLayout_Viewer";
};
export type EmployeePortalLayout_Viewer$key = {
  readonly " $data"?: EmployeePortalLayout_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeePortalLayout_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmployeePortalLayout_Viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApplicationSideBar_Viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmployeePortalLayout_TopBar_Account"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "33a642a1c96a5b8b04a5435495111536";

export default node;
