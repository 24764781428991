import { Radio, RadioGroup } from "@headlessui/react";
import { Text } from "@remote-com/norma";

import { cx } from "../../../../helpers/cva.config";

export function EssentialsOnboardingRadioCardWithIndicatorAndSelect({
  label,
  select,
  value,
}: {
  label: string;
  select?: React.ReactNode;
  value: string;
}) {
  return (
    <Radio
      className="group block cursor-pointer space-y-4 overflow-hidden rounded-remote-xl border bg-white p-2 transition-all data-[checked]:border-brand-600"
      value={value}
    >
      {({ checked }) => (
        <>
          <div
            className={cx(
              "flex items-center gap-3 overflow-hidden rounded-remote-lg bg-grey-50 px-5 py-5 transition-all",
            )}
          >
            <div className="flex size-6 items-center justify-center rounded-full border-2 border-grey-500 group-data-[checked]:border-brand-600">
              <div className="size-2 rounded-full bg-transparent group-data-[checked]:bg-brand-600" />
            </div>
            <Text variant="lgMedium">{label}</Text>
          </div>
          {checked && select && <div className="px-4 pb-4">{select}</div>}
        </>
      )}
    </Radio>
  );
}

export function EssentialsOnboardingRadioGroup({
  children,
  onChange,
  value,
}: React.PropsWithChildren<{
  onChange: (value: string | undefined) => void;
  value: string | undefined;
}>) {
  return (
    <RadioGroup className="space-y-4" onChange={onChange} value={value}>
      {children}
    </RadioGroup>
  );
}
