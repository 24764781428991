/**
 * @generated SignedSource<<cab0987ae5f0285d4b2a7eed19f5f18b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Grantees_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"Grantees_Refetchable_Organization" | "OrganizationGranteesTable_Organization">;
  readonly " $fragmentType": "Grantees_Organization";
};
export type Grantees_Organization$key = {
  readonly " $data"?: Grantees_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"Grantees_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filters"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Grantees_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrganizationGranteesTable_Organization"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "filters",
          "variableName": "filters"
        },
        {
          "kind": "Variable",
          "name": "organizationId",
          "variableName": "organizationId"
        }
      ],
      "kind": "FragmentSpread",
      "name": "Grantees_Refetchable_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "97eca9c63e9d6ef79dfc1a375d492dd4";

export default node;
