/**
 * @generated SignedSource<<04fa9220d9d65b6a37d02d0ff993119c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquityAlertingPage_Query$variables = Record<PropertyKey, never>;
export type EquityAlertingPage_Query$data = {
  readonly organizations: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"EquityAlertingPage_Organization">;
  }>;
};
export type EquityAlertingPage_Query = {
  response: EquityAlertingPage_Query$data;
  variables: EquityAlertingPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EquityAlertingPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizations",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EquityAlertingPage_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "EquityAlertingPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizations",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "terminated",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mismatchBetweenCtmsAndEasopCapTable",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullyDilutedShares",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "postSafeConversionFullyDilutedSharesDelta",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ctmsFullyDilutedShares",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "includingPendingShares",
                "value": false
              }
            ],
            "kind": "ScalarField",
            "name": "poolAvailableShares",
            "storageKey": "poolAvailableShares(includingPendingShares:false)"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ctmsReportedAvailableEsopPoolShares",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "easopSafeCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ctmsSafeCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "easopSafeTotalAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ctmsSafeTotalAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "easopWarrantTotalAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ctmsWarrantTotalAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "equityAlertingMuted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ctmsLatestFMVEffectiveDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ctmsLatestFMVValue",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "FairMarketValue",
            "kind": "LinkedField",
            "name": "latestFairMarketValue",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "date",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "defaultCurrencyCode",
                "storageKey": null
              }
            ],
            "type": "IOrganization",
            "abstractKey": "__isIOrganization"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "310976209bdca3c0f3006831f9ad5039",
    "id": null,
    "metadata": {},
    "name": "EquityAlertingPage_Query",
    "operationKind": "query",
    "text": "query EquityAlertingPage_Query {\n  organizations {\n    ...EquityAlertingPage_Organization\n    id\n  }\n}\n\nfragment EquityAlertingPage_Organization on Organization {\n  id\n  name\n  terminated\n  mismatchBetweenCtmsAndEasopCapTable\n  fullyDilutedShares\n  postSafeConversionFullyDilutedSharesDelta\n  ctmsFullyDilutedShares\n  poolAvailableShares(includingPendingShares: false)\n  ctmsReportedAvailableEsopPoolShares\n  easopSafeCount\n  ctmsSafeCount\n  easopSafeTotalAmount\n  ctmsSafeTotalAmount\n  easopWarrantTotalAmount\n  ctmsWarrantTotalAmount\n  equityAlertingMuted\n  ctmsLatestFMVEffectiveDate\n  ctmsLatestFMVValue\n  latestFairMarketValue {\n    date\n    value\n    id\n  }\n  ...FormattedCurrency_Organization\n  ...FormattedFMV_Organization\n}\n\nfragment FormattedCurrency_Organization on IOrganization {\n  __isIOrganization: __typename\n  defaultCurrencyCode\n}\n\nfragment FormattedFMV_Organization on IOrganization {\n  __isIOrganization: __typename\n  ...FormattedCurrency_Organization\n}\n"
  }
};
})();

(node as any).hash = "417b26cd62d589b7d590823d64f03765";

export default node;
