import { PlusIcon } from "@heroicons/react/24/outline";
import { Button, DropdownMenu } from "@remote-com/norma";
import { IconV2OutlineChevronDown } from "@remote-com/norma/icons/IconV2OutlineChevronDown";
import { Suspense } from "react";
import { useFragment } from "react-relay";
import { generatePath, Outlet } from "react-router-dom";
import { graphql } from "relay-runtime";

import { DraftGrantButton } from "../../../components/DraftGrantButton";
import { LinkButton } from "../../../components/ui/Button";
import { LargeOneColumnLayout } from "../../../components/ui/Layout/LargeOneColumnLayout";
import { TabNavigation } from "../../../components/ui/TabNavigation";
import { useQuery } from "../../../hooks/useQuery";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import {
  AdminGrantsLayout_Organization$data,
  AdminGrantsLayout_Organization$key,
} from "./__generated__/AdminGrantsLayout_Organization.graphql";
import { AdminGrantsLayout_Query } from "./__generated__/AdminGrantsLayout_Query.graphql";
import {
  AdminGrantsLayout_Viewer$data,
  AdminGrantsLayout_Viewer$key,
} from "./__generated__/AdminGrantsLayout_Viewer.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment AdminGrantsLayout_Organization on Organization {
    id
    planIsFreemium
    isRemoteEquityEssentials
    activeCTMSGrants: ctmsGrants(filters: { statusIn: [Active] }) {
      totalCount
    }
    terminatedCTMSGrants: ctmsGrants(filters: { statusIn: [Terminated] }) {
      totalCount
    }
    canceledCTMSGrants: ctmsGrants(filters: { statusIn: [Canceled] }) {
      totalCount
    }
    ...DraftGrantButton_Organization @defer
    ...LargeOneColumnLayout_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment AdminGrantsLayout_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    isAllowedToManageOrganization(organizationId: $organizationId)
  }
`;

const TopBarActions: React.FC<{
  organization: AdminGrantsLayout_Organization$data;
  viewer: AdminGrantsLayout_Viewer$data;
}> = ({ organization, viewer }) => {
  if (!viewer.isAllowedToManageOrganization) return null;

  if (organization.isRemoteEquityEssentials)
    return (
      <>
        <DropdownMenu
          // @ts-expect-error className and trigger not defined on DropdownMenu props
          className="z-10"
          trigger={
            <Button IconAfter={IconV2OutlineChevronDown} size="md">
              Declare new
            </Button>
          }
        >
          <DropdownMenu.Item
            href={generatePath(APPLICATION_ROUTES.organizationDeclareNewGrant, {
              organizationId: organization.id,
            })}
          >
            Declare grant
          </DropdownMenu.Item>
          <DropdownMenu.Item
            href={generatePath(
              APPLICATION_ROUTES.organizationDeclareNewExerciseRequests,
              {
                organizationId: organization.id,
              },
            )}
          >
            Declare exercise
          </DropdownMenu.Item>
        </DropdownMenu>
      </>
    );

  if (organization.planIsFreemium) {
    return (
      <LinkButton
        leftIcon={<PlusIcon />}
        size="small"
        to={generatePath(
          APPLICATION_ROUTES.organizationPrepareYourGrantsDrafts,
          {
            organizationId: organization.id,
          },
        )}
        type="button"
      >
        Draft equity
      </LinkButton>
    );
  }

  return (
    <Suspense>
      <DraftGrantButton organizationFragment={organization} />
    </Suspense>
  );
};

const AdminGrantsLayout_: React.FC<{
  organizationFragment: AdminGrantsLayout_Organization$key;
  viewerFragment: AdminGrantsLayout_Viewer$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  return (
    <LargeOneColumnLayout
      organizationFragment={organization}
      title="Equity Grants"
      topBarActionsRender={() => (
        <TopBarActions organization={organization} viewer={viewer} />
      )}
    >
      <div className="space-y-6">
        <TabNavigation layoutId="grants-tab-navigation">
          <TabNavigation.Link
            count={organization.activeCTMSGrants.totalCount}
            to={generatePath(
              APPLICATION_ROUTES.organizationEquityGrantsActive,
              {
                organizationId: organization.id,
              },
            )}
          >
            Active
          </TabNavigation.Link>
          <TabNavigation.Link
            count={organization.terminatedCTMSGrants.totalCount}
            to={generatePath(
              APPLICATION_ROUTES.organizationEquityGrantsTerminated,
              {
                organizationId: organization.id,
              },
            )}
          >
            Terminated
          </TabNavigation.Link>
          {organization.canceledCTMSGrants.totalCount > 0 && (
            <TabNavigation.Link
              count={organization.canceledCTMSGrants.totalCount}
              to={generatePath(
                APPLICATION_ROUTES.organizationEquityGrantsCanceled,
                {
                  organizationId: organization.id,
                },
              )}
            >
              Canceled
            </TabNavigation.Link>
          )}
        </TabNavigation>
        <Outlet />
      </div>
    </LargeOneColumnLayout>
  );
};

const QUERY = graphql`
  query AdminGrantsLayout_Query($organizationId: OrganizationId!) {
    me {
      ...AdminGrantsLayout_Viewer @arguments(organizationId: $organizationId)
    }
    organization(id: $organizationId) {
      ...AdminGrantsLayout_Organization
    }
  }
`;

const AdminGrantsLayout: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<AdminGrantsLayout_Query>(QUERY, {
    organizationId,
  });

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <AdminGrantsLayout_
      organizationFragment={query.organization}
      viewerFragment={query.me}
    />
  );
};

export default AdminGrantsLayout;
