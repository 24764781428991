/**
 * @generated SignedSource<<27e935de6b0dee16cc707dbd156abe4a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GrantDetailsExerciseSection_CTMSGrant$data = {
  readonly earlyExercise: boolean;
  readonly exercisableShares: number;
  readonly exercisePrice: number | null;
  readonly lastDayToExerciseBasedOnTerminationDate: string | null;
  readonly quantityExercised: number;
  readonly terminationDate: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseStatusTag_CTMSGrant">;
  readonly " $fragmentType": "GrantDetailsExerciseSection_CTMSGrant";
};
export type GrantDetailsExerciseSection_CTMSGrant$key = {
  readonly " $data"?: GrantDetailsExerciseSection_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantDetailsExerciseSection_CTMSGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantDetailsExerciseSection_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "earlyExercise",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantityExercised",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exercisableShares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exercisePrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "terminationDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastDayToExerciseBasedOnTerminationDate",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExerciseStatusTag_CTMSGrant"
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "7bd58e81c5238e25677274341c615f66";

export default node;
