import { format } from "date-fns";
import saveAs from "file-saver";
import { useCallback } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useAlerter } from "../components/Alerter";
import { Alert } from "../components/ui/Alert";
import { useDownloadBoardConsentPreview_GenerateFairMarketValueBoardConsentPreviewURL_Mutation } from "./__generated__/useDownloadBoardConsentPreview_GenerateFairMarketValueBoardConsentPreviewURL_Mutation.graphql";
import { useDownloadBoardConsentPreview_GenerateGrantAmendmentBoardConsentPreviewURL_Mutation } from "./__generated__/useDownloadBoardConsentPreview_GenerateGrantAmendmentBoardConsentPreviewURL_Mutation.graphql";
import {
  GenerateGrantBoardConsentPreviewURLResultFailureError,
  useDownloadBoardConsentPreview_GenerateGrantBoardConsentPreviewURL_Mutation,
} from "./__generated__/useDownloadBoardConsentPreview_GenerateGrantBoardConsentPreviewURL_Mutation.graphql";
import { useDownloadBoardConsentPreview_GenerateGranteeTerminationGrantAmendmentBoardConsentPreviewURL_Mutation } from "./__generated__/useDownloadBoardConsentPreview_GenerateGranteeTerminationGrantAmendmentBoardConsentPreviewURL_Mutation.graphql";
import { useDownloadBoardConsentPreview_Organization$key } from "./__generated__/useDownloadBoardConsentPreview_Organization.graphql";
import { useApplicationSupportEmailAddress } from "./useApplicationTheme";
import { useSafeMutation } from "./useSafeMutation";

const GENERATE_GRANT_BOARD_CONSENT_PREVIEW_URL_MUTATION = graphql`
  mutation useDownloadBoardConsentPreview_GenerateGrantBoardConsentPreviewURL_Mutation(
    $easopGrantsIds: [UUID!]!
    $organizationId: OrganizationId!
  ) {
    generateGrantBoardConsentPreviewURL(
      easopGrantsIds: $easopGrantsIds
      organizationId: $organizationId
    ) {
      __typename
      ... on GenerateGrantBoardConsentPreviewURLResultFailure {
        error
      }
      ... on GenerateGrantBoardConsentPreviewURLResultSuccess {
        url
      }
    }
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment useDownloadBoardConsentPreview_Organization on Organization {
    id
    name
  }
`;

export function useDownloadGrantBoardConsentPreview({
  easopGrantsIds,
  organizationFragment,
}: {
  easopGrantsIds: string[];
  organizationFragment: useDownloadBoardConsentPreview_Organization$key;
}) {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const [
    generateGrantBoardConsentPreviewURL,
    generateGrantBoardConsentPreviewURLMutationIsInFlight,
  ] =
    useSafeMutation<useDownloadBoardConsentPreview_GenerateGrantBoardConsentPreviewURL_Mutation>(
      GENERATE_GRANT_BOARD_CONSENT_PREVIEW_URL_MUTATION,
    );

  const alerter = useAlerter();
  const supportEmailAddress = useApplicationSupportEmailAddress();

  const handleMutationError = useCallback(
    (error: GenerateGrantBoardConsentPreviewURLResultFailureError) => {
      switch (error) {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        case "DIFFERENT_ACCELERATION_CLAUSE":
          alerter.push(
            <Alert title="Grants have different acceleration clauses">
              Some grants have different acceleration clauses. Unfortunately we
              cannot let you group them in the same board consent for now.
              Please group your grants in different batches or{" "}
              <a
                className="font-medium text-primary"
                href={`mailto:${supportEmailAddress}?subject=${encodeURI(
                  `[${organization.name}] Why can't I group grants with different acceleration clause in the same board consent?`,
                )}`}
              >
                contact us
              </a>
              .
            </Alert>,
          );
      }
    },
    [alerter, organization.name, supportEmailAddress],
  );

  const downloadBoardConsentPreview = useCallback(async () => {
    const { generateGrantBoardConsentPreviewURL: result } =
      await generateGrantBoardConsentPreviewURL({
        variables: {
          easopGrantsIds,
          organizationId: organization.id,
        },
      });

    if (result.__typename === "%other") {
      throw new Error("Invalid typename");
    }

    if (
      result.__typename === "GenerateGrantBoardConsentPreviewURLResultFailure"
    ) {
      handleMutationError(result.error);
      return;
    }

    saveAs(
      result.url,
      `PREVIEW-board-consent-${format(new Date(), "yyyy-MM-dd")}.pdf`,
    );
  }, [
    easopGrantsIds,
    organization.id,
    generateGrantBoardConsentPreviewURL,
    handleMutationError,
  ]);

  return {
    downloadBoardConsentPreview,
    downloadIsInFlight: generateGrantBoardConsentPreviewURLMutationIsInFlight,
  };
}

const GENERATE_GRANTEE_TERMINATION_GRANT_AMENDMENT_BOARD_CONSENT_PREVIEW_URL_MUTATION = graphql`
  mutation useDownloadBoardConsentPreview_GenerateGranteeTerminationGrantAmendmentBoardConsentPreviewURL_Mutation(
    $granteeTerminationCTMSGrantAmendmentRequestIds: [UUID!]!
    $organizationId: OrganizationId!
  ) {
    generateGranteeTerminationGrantAmendmentBoardConsentPreviewURL(
      granteeTerminationCTMSGrantAmendmentRequestIds: $granteeTerminationCTMSGrantAmendmentRequestIds
      organizationId: $organizationId
    )
  }
`;

export function useDownloadeGranteeTerminationGrantAmendmentBoardConsentPreview({
  granteeTerminationCTMSGrantAmendmentRequestIds,
  organizationId,
}: {
  granteeTerminationCTMSGrantAmendmentRequestIds: string[];
  organizationId: string;
}) {
  const [
    generateGranteeTerminationGrantAmendmentBoardConsentPreviewURL,
    generateGranteeTerminationGrantAmendmentBoardConsentPreviewURLMutationIsInFlight,
  ] =
    useSafeMutation<useDownloadBoardConsentPreview_GenerateGranteeTerminationGrantAmendmentBoardConsentPreviewURL_Mutation>(
      GENERATE_GRANTEE_TERMINATION_GRANT_AMENDMENT_BOARD_CONSENT_PREVIEW_URL_MUTATION,
    );

  const downloadGranteeTerminationGrantAmendmentBoardConsentPreview =
    useCallback(async () => {
      const result =
        await generateGranteeTerminationGrantAmendmentBoardConsentPreviewURL({
          variables: {
            granteeTerminationCTMSGrantAmendmentRequestIds,
            organizationId,
          },
        });

      saveAs(
        result.generateGranteeTerminationGrantAmendmentBoardConsentPreviewURL,
        `PREVIEW-grant-amendment-board-consent-${format(new Date(), "yyyy-MM-dd")}.pdf`,
      );
    }, [
      granteeTerminationCTMSGrantAmendmentRequestIds,
      organizationId,
      generateGranteeTerminationGrantAmendmentBoardConsentPreviewURL,
    ]);

  return {
    downloadGranteeTerminationGrantAmendmentBoardConsentPreview,
    downloadIsInFlight:
      generateGranteeTerminationGrantAmendmentBoardConsentPreviewURLMutationIsInFlight,
  };
}

const GENERATE_GRANT_AMENDMENT_BOARD_CONSENT_PREVIEW_URL_MUTATION = graphql`
  mutation useDownloadBoardConsentPreview_GenerateGrantAmendmentBoardConsentPreviewURL_Mutation(
    $ctmsGrantAmendmentRequestIds: [UUID!]!
    $organizationId: OrganizationId!
  ) {
    generateGrantAmendmentBoardConsentPreviewURL(
      ctmsGrantAmendmentRequestIds: $ctmsGrantAmendmentRequestIds
      organizationId: $organizationId
    )
  }
`;

export function useDownloadGrantsAmendmentsBoardConsentPreview({
  ctmsGrantAmendmentRequestIds,
  organizationId,
}: {
  ctmsGrantAmendmentRequestIds: string[];
  organizationId: string;
}) {
  const [
    generateGrantAmendmentBoardConsentPreviewURL,
    generateGrantAmendmentBoardConsentPreviewURLMutationIsInFlight,
  ] =
    useSafeMutation<useDownloadBoardConsentPreview_GenerateGrantAmendmentBoardConsentPreviewURL_Mutation>(
      GENERATE_GRANT_AMENDMENT_BOARD_CONSENT_PREVIEW_URL_MUTATION,
    );

  const downloadGrantAmendmentBoardConsentPreview = useCallback(async () => {
    const result = await generateGrantAmendmentBoardConsentPreviewURL({
      variables: {
        ctmsGrantAmendmentRequestIds,
        organizationId,
      },
    });

    saveAs(
      result.generateGrantAmendmentBoardConsentPreviewURL,
      `PREVIEW-grant-amendment-board-consent-${format(new Date(), "yyyy-MM-dd")}.pdf`,
    );
  }, [
    ctmsGrantAmendmentRequestIds,
    organizationId,
    generateGrantAmendmentBoardConsentPreviewURL,
  ]);

  return {
    downloadGrantAmendmentBoardConsentPreview,
    downloadIsInFlight:
      generateGrantAmendmentBoardConsentPreviewURLMutationIsInFlight,
  };
}

const GENERATE_FAIR_MARKET_VALUE_BOARD_CONSENT_PREVIEW_URL_MUTATION = graphql`
  mutation useDownloadBoardConsentPreview_GenerateFairMarketValueBoardConsentPreviewURL_Mutation(
    $fairMarketValueId: ID!
  ) {
    generateFairMarketValueBoardConsentPreviewURL(
      fairMarketValueId: $fairMarketValueId
    )
  }
`;

export function useDownloadFairMarketValueBoardConsentPreview({
  fairMarketValueId,
}: {
  fairMarketValueId: null | string;
}) {
  const [
    generateFairMarketValueBoardConsentPreviewURL,
    generateFairMarketValueBoardConsentPreviewURLMutationIsInFlight,
  ] =
    useSafeMutation<useDownloadBoardConsentPreview_GenerateFairMarketValueBoardConsentPreviewURL_Mutation>(
      GENERATE_FAIR_MARKET_VALUE_BOARD_CONSENT_PREVIEW_URL_MUTATION,
    );

  const downloadFairMarketValueBoardConsentPreview = useCallback(async () => {
    if (!fairMarketValueId) return;

    const result = await generateFairMarketValueBoardConsentPreviewURL({
      variables: {
        fairMarketValueId,
      },
    });

    saveAs(
      result.generateFairMarketValueBoardConsentPreviewURL,
      `PREVIEW-fair-market-value-board-consent-${format(new Date(), "yyyy-MM-dd")}.pdf`,
    );
  }, [fairMarketValueId, generateFairMarketValueBoardConsentPreviewURL]);

  return {
    downloadEnabled: !!fairMarketValueId,
    downloadFairMarketValueBoardConsentPreview,
    downloadIsInFlight:
      generateFairMarketValueBoardConsentPreviewURLMutationIsInFlight,
  };
}
