import React, { useState } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { Page } from "../../../components/Page";
import { useQuery } from "../../../hooks/useQuery";
import { useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { TestLawFirmCartaConnectionView } from "../FinishRemoteEquityOnboarding/TestLawFirmCartaConnectionView";
import { BookADemoPage_Organization$key } from "./__generated__/BookADemoPage_Organization.graphql";
import { BookADemoPage_Query } from "./__generated__/BookADemoPage_Query.graphql";
import { BookADemoPage_Viewer$key } from "./__generated__/BookADemoPage_Viewer.graphql";
import { BookADemoView } from "./BookADemoView";

const ORGANIZATION_FRAGMENT = graphql`
  fragment BookADemoPage_Organization on Organization {
    remoteEquityIsOrganizationCartaLawFirm
    ...TestLawFirmCartaConnectionView_Organization
    ...BookADemoView_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment BookADemoPage_Viewer on Account {
    ...BookADemoView_Account
    ...TestLawFirmCartaConnectionView_Viewer
  }
`;

type Step = "book-a-demo" | "test-law-firm-carta-connection";

const Content: React.FC<{
  organizationFragment: BookADemoPage_Organization$key;
  viewerFragment: BookADemoPage_Viewer$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  const [step, setStep] = useState<Step>(() =>
    !organization.remoteEquityIsOrganizationCartaLawFirm
      ? "test-law-firm-carta-connection"
      : "book-a-demo",
  );

  switch (step) {
    case "book-a-demo":
      return (
        <BookADemoView
          organizationFragment={organization}
          viewerFragment={viewer}
        />
      );
    case "test-law-firm-carta-connection":
      return (
        <TestLawFirmCartaConnectionView
          onNextButtonClick={() => {
            setStep("book-a-demo");
          }}
          organizationFragment={organization}
          viewerFragment={viewer}
        />
      );
  }
};

const QUERY = graphql`
  query BookADemoPage_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...BookADemoPage_Organization
    }
    me {
      ...BookADemoPage_Viewer
    }
  }
`;

const BookADemoPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();

  const {
    query: { me: viewer, organization },
  } = useQuery<BookADemoPage_Query>(QUERY, {
    organizationId,
  });

  if (!organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Admin Onboarding"
      analyticsName="Admin - Book a demo"
      organizationId={organization.id}
      title={`Admin | ${organization.name} Book a demo`}
    >
      <Content organizationFragment={organization} viewerFragment={viewer} />
    </Page>
  );
};

export default BookADemoPage;
