import classNames from "classnames";
import React from "react";

export function Section({
  children,
  className,
  title,
}: {
  children: React.ReactNode;
  className?: string;
  title: React.ReactNode;
}) {
  return (
    <div className={classNames("space-y-4", className)}>
      <h2 className="text-remote-XL/Medium">{title}</h2>
      {children}
    </div>
  );
}
