/**
 * @generated SignedSource<<078371d37289409d77eb47ad0b0de1ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConfirmSubmitBoardConsentSlide_Organization$data = {
  readonly allowBoardConsentHandledOutsideEasop: boolean;
  readonly boardMembers: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"BoardMembersSection_ConfirmSubmitBoardConsentSlide_Organization" | "BoardNoteSection_Organization" | "ExportSelectedItemsSection_Organization" | "useDownloadBoardConsentPreview_Organization">;
  readonly " $fragmentType": "ConfirmSubmitBoardConsentSlide_Organization";
};
export type ConfirmSubmitBoardConsentSlide_Organization$key = {
  readonly " $data"?: ConfirmSubmitBoardConsentSlide_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConfirmSubmitBoardConsentSlide_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConfirmSubmitBoardConsentSlide_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "BoardMember",
      "kind": "LinkedField",
      "name": "boardMembers",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowBoardConsentHandledOutsideEasop",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BoardNoteSection_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BoardMembersSection_ConfirmSubmitBoardConsentSlide_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useDownloadBoardConsentPreview_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExportSelectedItemsSection_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "35bc196981de56e3273dda9e1e12ff7c";

export default node;
