import { RadioGroup } from "@headlessui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { BoxedIcon, Pill, Text, Toggle } from "@remote-com/norma";
import { IconV2DuotoneAlertTriangle } from "@remote-com/norma/icons/IconV2DuotoneAlertTriangle";
import { IconV2DuotoneCheckSquare } from "@remote-com/norma/icons/IconV2DuotoneCheckSquare";
import { IconV2OutlineAlertTriangle } from "@remote-com/norma/icons/IconV2OutlineAlertTriangle";
import { IconV2OutlineCheckSquare } from "@remote-com/norma/icons/IconV2OutlineCheckSquare";
import { isEmpty, isNil } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import React from "react";
import {
  Control,
  FieldPath,
  useController,
  useForm,
  useWatch,
} from "react-hook-form";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useSafeMutation } from "../../hooks/useSafeMutation";
import {
  CATEGORIES,
  CATEGORIES_MAP,
  Category,
  Event,
  EVENTS,
  EVENTS_MAP,
  Section,
  SECTIONS,
  SECTIONS_MAP,
} from "../../services/EquityHandbookService";
import { EQUITY_TYPE_WORK_RELATIONSHIP_MAP } from "../../services/workRelationship";
import { CheckboxCard } from "../CheckboxCard";
import { RadioCard } from "../RadioCard";
import { ShortDate } from "../ShortDate";
import { Button } from "../ui/Button";
import { FormRow } from "../ui/Form/FormRow";
import { TextArea } from "../ui/Form/Inputs/TextArea";
import { RoundedBox } from "../ui/RoundedBox";
import { EquityTypeHandbookEdition_EquityType$key } from "./__generated__/EquityTypeHandbookEdition_EquityType.graphql";
import { EquityTypeHandbookEdition_EquityTypeHandbook$key } from "./__generated__/EquityTypeHandbookEdition_EquityTypeHandbook.graphql";
import { EquityTypeHandbookEdition_PublishEquityTypeHandbook_Mutation } from "./__generated__/EquityTypeHandbookEdition_PublishEquityTypeHandbook_Mutation.graphql";
import { EquityTypeHandbookEdition_SaveEquityTypeHandbook_Mutation } from "./__generated__/EquityTypeHandbookEdition_SaveEquityTypeHandbook_Mutation.graphql";
import { EquityTypeHandbookEdition_UnpublishEquityTypeHandbook_Mutation } from "./__generated__/EquityTypeHandbookEdition_UnpublishEquityTypeHandbook_Mutation.graphql";
import { FloatingSaveBar } from "./FloatingSaveBar";
import {
  buildDefaultValuesFromEquityTypeHandbook,
  equityTypeHandbookSchema,
  FormValues,
} from "./FORM_SCHEMA";

const EQUITY_TYPE_FRAGMENT = graphql`
  fragment EquityTypeHandbookEdition_EquityType on EquityType {
    id
  }
`;

const EQUITY_TYPE_HANDBOOK_FRAGMENT = graphql`
  fragment EquityTypeHandbookEdition_EquityTypeHandbook on EquityTypeHandbook {
    id
    publishedAt
    published
    lastModifiedAt
    sectionEvents {
      section
      event
      enabled
      nothingToDeclare
      # eslint-disable-next-line relay/unused-fields
      items {
        category
        content
        enabled
      }
    }
  }
`;

const SAVE_EQUITY_TYPE_HANDBOOK_MUTATION = graphql`
  mutation EquityTypeHandbookEdition_SaveEquityTypeHandbook_Mutation(
    $equityTypeHandbookInput: EquityTypeHandbookInput!
    $equityTypeId: String!
    $equityTypeWorkRelationshipCategory: EquityTypeWorkRelationshipCategory!
    $lastModifiedAt: DateTime
  ) {
    saveEquityTypeHandbook(
      equityTypeHandbookInput: $equityTypeHandbookInput
      equityTypeId: $equityTypeId
      equityTypeWorkRelationshipCategory: $equityTypeWorkRelationshipCategory
      lastModifiedAt: $lastModifiedAt
    ) {
      ...EquityTypeHandbookEdition_EquityTypeHandbook
    }
  }
`;

const PUBLISH_EQUITY_TYPE_HANDBOOK_MUTATION = graphql`
  mutation EquityTypeHandbookEdition_PublishEquityTypeHandbook_Mutation(
    $equityTypeHandbookId: String!
    $lastModifiedAt: DateTime
  ) {
    publishEquityTypeHandbook(
      equityTypeHandbookId: $equityTypeHandbookId
      lastModifiedAt: $lastModifiedAt
    ) {
      ...EquityTypeHandbookEdition_EquityTypeHandbook
    }
  }
`;

const UNPUBLISH_EQUITY_TYPE_HANDBOOK_MUTATION = graphql`
  mutation EquityTypeHandbookEdition_UnpublishEquityTypeHandbook_Mutation(
    $equityTypeHandbookId: String!
  ) {
    unpublishEquityTypeHandbook(equityTypeHandbookId: $equityTypeHandbookId) {
      ...EquityTypeHandbookEdition_EquityTypeHandbook
    }
  }
`;

const ItemContentBlock: React.FC<{
  category: Category;
  control: Control<FormValues>;
  event: Event;
  section: Section;
}> = ({ category, control, event, section }) => {
  return (
    <FormRow.Form
      control={control}
      name={`${section}.${event}.${category}.content`}
    >
      <TextArea.Form
        className="min-h-[139px]"
        control={control}
        name={`${section}.${event}.${category}.content`}
        placeholder="Input content"
      />
    </FormRow.Form>
  );
};

const ItemCategoryBlock: React.FC<{
  category: Category;
  control: Control<FormValues>;
  event: Event;
  section: Section;
}> = ({ category, control, event, section }) => {
  const { field: itemEnabledField } = useController({
    control,
    name: `${section}.${event}.${category}.enabled`,
  });
  return (
    <CheckboxCard
      checked={itemEnabledField.value ?? false}
      icons={CATEGORIES_MAP[category].icons}
      onChange={(checked) => {
        itemEnabledField.onChange(checked);
      }}
    >
      {CATEGORIES_MAP[category].label}
    </CheckboxCard>
  );
};
const EventBlock: React.FC<{
  control: Control<FormValues>;
  event: Event;
  section: Section;
}> = ({ control, event, section }) => {
  const { field: eventEnabledField } = useController({
    control,
    name: `${section}.${event}.enabled`,
  });

  const { field: eventNothingToDeclareField } = useController({
    control,
    name: `${section}.${event}.nothingToDeclare`,
  });

  const eventValues = useWatch({
    control,
    name: `${section}.${event}`,
  });

  const activeCategories = useMemo(() => {
    return CATEGORIES.filter((category) => eventValues?.[category]?.enabled);
  }, [eventValues]);

  return (
    <RoundedBox className="space-y-4 p-4 transition-all" key={event} withBorder>
      <div className="flex items-center gap-4">
        <Toggle
          checked={eventEnabledField.value}
          label=""
          onClick={() => {
            eventEnabledField.onChange(!eventEnabledField.value);
          }}
          size="sm"
        />
        <Text variant="baseMedium">{EVENTS_MAP[event].editionLabel}</Text>
      </div>
      {eventEnabledField.value && (
        <>
          <Text variant="smMedium">Event</Text>
          <RadioGroup
            className="flex flex-wrap gap-2"
            onChange={(value) => {
              eventNothingToDeclareField.onChange(
                value === "NOTHING_TO_DECLARE" ? true : false,
              );
            }}
            value={
              !isNil(eventNothingToDeclareField.value)
                ? eventNothingToDeclareField.value
                  ? "NOTHING_TO_DECLARE"
                  : "SOMETHING_TO_DECLARE"
                : undefined
            }
          >
            <RadioCard
              icons={{
                activeIcon: IconV2DuotoneCheckSquare,
                icon: IconV2OutlineCheckSquare,
              }}
              value="NOTHING_TO_DECLARE"
            >
              {SECTIONS_MAP[section].nothingToDeclareEditionLabel}
            </RadioCard>
            <RadioCard
              icons={{
                activeIcon: IconV2DuotoneAlertTriangle,
                icon: IconV2OutlineAlertTriangle,
              }}
              value="SOMETHING_TO_DECLARE"
            >
              {SECTIONS_MAP[section].somethingToDeclareLabel}
            </RadioCard>
          </RadioGroup>
          {eventNothingToDeclareField.value === false && (
            <>
              <FormRow.Form
                control={control}
                name={
                  `${section}.${event}.selectAtLeastOneItemError` as FieldPath<FormValues>
                }
              >
                <Text variant="smMedium">
                  {SECTIONS_MAP[section].itemsCategoriesTitle}
                </Text>
              </FormRow.Form>
              <div className="flex flex-wrap gap-2">
                {CATEGORIES.map((category) => {
                  if (
                    !SECTIONS_MAP[section].itemCategories.includes(category)
                  ) {
                    return null;
                  }
                  return (
                    <ItemCategoryBlock
                      category={category}
                      control={control}
                      event={event}
                      key={category}
                      section={section}
                    />
                  );
                })}
              </div>
              {activeCategories.length > 0 && (
                <div className="flex flex-wrap gap-2">
                  {CATEGORIES.map((category) => {
                    if (!activeCategories.includes(category)) {
                      return null;
                    }
                    return (
                      <div className="flex-1 space-y-4" key={category}>
                        <div className="flex items-center gap-2">
                          {React.createElement(
                            CATEGORIES_MAP[category].icons.activeIcon,
                            {
                              className: "h-4 text-brand-600",
                            },
                          )}
                          <Text variant="smMedium">
                            {CATEGORIES_MAP[category].label} content
                          </Text>
                        </div>
                        <ItemContentBlock
                          category={category}
                          control={control}
                          event={event}
                          section={section}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          )}
        </>
      )}
    </RoundedBox>
  );
};

const SectionBlock: React.FC<{
  control: Control<FormValues>;
  section: Section;
}> = ({ control, section }) => {
  return (
    <div className="space-y-6" key={section}>
      <div className="flex items-center gap-4">
        <BoxedIcon Icon={SECTIONS_MAP[section].icon} size="sm" tone="brand" />
        <Text variant="lgMedium">{SECTIONS_MAP[section].title}</Text>
      </div>
      <div className="space-y-2 pl-4">
        {EVENTS.map((event) => (
          <EventBlock
            control={control}
            event={event}
            key={event}
            section={section}
          />
        ))}
      </div>
    </div>
  );
};

export const EquityTypeHandbookEdition: React.FC<{
  equityTypeFragment: EquityTypeHandbookEdition_EquityType$key;
  equityTypeHandbookFragment: EquityTypeHandbookEdition_EquityTypeHandbook$key | null;
  equityTypeWorkRelationshipCategory:
    | "Contractor"
    | "DirectEmployee"
    | "EoREmployee";
  onDataUpdated: () => void;
}> = ({
  equityTypeFragment,
  equityTypeHandbookFragment,
  equityTypeWorkRelationshipCategory,
  onDataUpdated,
}) => {
  const equityType = useFragment(EQUITY_TYPE_FRAGMENT, equityTypeFragment);
  const equityTypeHandbook =
    useFragment(EQUITY_TYPE_HANDBOOK_FRAGMENT, equityTypeHandbookFragment) ??
    null;

  const [saveEquityTypeHandbook, mutationIsInFlight] =
    useSafeMutation<EquityTypeHandbookEdition_SaveEquityTypeHandbook_Mutation>(
      SAVE_EQUITY_TYPE_HANDBOOK_MUTATION,
    );

  const defaultValues = useMemo(
    () =>
      buildDefaultValuesFromEquityTypeHandbook({
        sectionEvents: equityTypeHandbook?.sectionEvents ?? [],
      }),
    [equityTypeHandbook?.sectionEvents],
  );

  const {
    control,
    formState: { dirtyFields },
    handleSubmit,
    reset: resetForm,
  } = useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(equityTypeHandbookSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    await saveEquityTypeHandbook({
      variables: {
        equityTypeHandbookInput: {
          sectionEvents: SECTIONS.flatMap((section) => {
            return EVENTS.map((event) => {
              return {
                enabled: data[section]?.[event]?.enabled ?? false,
                event,
                items: SECTIONS_MAP[section].itemCategories.map((category) => {
                  return {
                    category,
                    content: data[section]?.[event]?.[category]?.content,
                    enabled:
                      data[section]?.[event]?.[category]?.enabled ?? false,
                  };
                }),
                nothingToDeclare: data[section]?.[event]?.nothingToDeclare,
                section,
              };
            });
          }),
        },
        equityTypeId: equityType.id,
        equityTypeWorkRelationshipCategory,
        lastModifiedAt: equityTypeHandbook?.lastModifiedAt,
      },
    });
    onDataUpdated();
  });

  useEffect(() => {
    resetForm(defaultValues);
  }, [defaultValues, equityTypeWorkRelationshipCategory, resetForm]);

  const [publishEquityTypeHandbook, publishMutationIsInFlight] =
    useSafeMutation<EquityTypeHandbookEdition_PublishEquityTypeHandbook_Mutation>(
      PUBLISH_EQUITY_TYPE_HANDBOOK_MUTATION,
    );

  const onPublishClicked = useCallback(async () => {
    if (!equityTypeHandbook) {
      return;
    }

    await publishEquityTypeHandbook({
      variables: {
        equityTypeHandbookId: equityTypeHandbook.id,
        lastModifiedAt: equityTypeHandbook.lastModifiedAt,
      },
    });
  }, [equityTypeHandbook, publishEquityTypeHandbook]);

  const [unpublishEquityTypeHandbook, unpublishMutationIsInFlight] =
    useSafeMutation<EquityTypeHandbookEdition_UnpublishEquityTypeHandbook_Mutation>(
      UNPUBLISH_EQUITY_TYPE_HANDBOOK_MUTATION,
    );

  const onUnpublishClicked = useCallback(async () => {
    if (!equityTypeHandbook) {
      return;
    }

    await unpublishEquityTypeHandbook({
      variables: {
        equityTypeHandbookId: equityTypeHandbook.id,
      },
    });
  }, [equityTypeHandbook, unpublishEquityTypeHandbook]);

  return (
    <form onSubmit={onSubmit}>
      <RoundedBox background="subtle" className="space-y-10 p-10">
        <div className="flex gap-10">
          <div className="flex flex-1 items-center gap-2">
            <Text variant="2xlMedium">
              {
                EQUITY_TYPE_WORK_RELATIONSHIP_MAP[
                  equityTypeWorkRelationshipCategory
                ].label
              }{" "}
              handbook
            </Text>
            {equityTypeHandbook?.published && equityTypeHandbook.publishedAt ? (
              <Pill tone="success">
                Published on{" "}
                <ShortDate value={equityTypeHandbook.publishedAt} />
              </Pill>
            ) : (
              <Pill tone="neutralLight">Not published</Pill>
            )}
          </div>
          {equityTypeHandbook?.published ? (
            <Button
              loading={unpublishMutationIsInFlight}
              onClick={onUnpublishClicked}
              type="button"
              variant="Secondary Outline"
            >
              Unpublish
            </Button>
          ) : (
            <Button
              disabled={!equityTypeHandbook}
              loading={publishMutationIsInFlight}
              onClick={onPublishClicked}
              type="button"
            >
              Publish
            </Button>
          )}
        </div>
        <div className="space-y-10 divide-y-[0.5px] divide-grey-300 [&>*:first-child]:pt-0 [&>*]:pt-10">
          {SECTIONS.map((section) => (
            <SectionBlock control={control} key={section} section={section} />
          ))}
        </div>
      </RoundedBox>
      <FloatingSaveBar
        loading={mutationIsInFlight}
        // I don't know why but isDirty is set to true due to defaultValues changes
        show={!isEmpty(dirtyFields)}
      />
    </form>
  );
};
