import { Button } from "@remote-com/norma";
import React from "react";
import { useFragment } from "react-relay";
import { generatePath, Link, Outlet } from "react-router-dom";
import { graphql } from "relay-runtime";

import { RedirectHandler } from "../../../components/RedirectHandler";
import { LargeOneColumnLayout } from "../../../components/ui/Layout/LargeOneColumnLayout";
import { TabNavigation } from "../../../components/ui/TabNavigation";
import { useQuery } from "../../../hooks/useQuery";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import {
  ExerciseRequestsLayout_Organization$data,
  ExerciseRequestsLayout_Organization$key,
} from "./__generated__/ExerciseRequestsLayout_Organization.graphql";
import { ExerciseRequestsLayout_Query } from "./__generated__/ExerciseRequestsLayout_Query.graphql";
import {
  ExerciseRequestsLayout_Viewer$data,
  ExerciseRequestsLayout_Viewer$key,
} from "./__generated__/ExerciseRequestsLayout_Viewer.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment ExerciseRequestsLayout_Organization on Organization {
    id
    isRemoteEquityEssentials
    ...LargeOneColumnLayout_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment ExerciseRequestsLayout_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    isAllowedToManageOrganization(organizationId: $organizationId)
  }
`;

const TopBarActions: React.FC<{
  organization: ExerciseRequestsLayout_Organization$data;
  viewer: ExerciseRequestsLayout_Viewer$data;
}> = ({ organization, viewer }) => {
  if (
    !viewer.isAllowedToManageOrganization ||
    !organization.isRemoteEquityEssentials
  )
    return null;

  return (
    <Button
      as={Link}
      size="md"
      to={generatePath(
        APPLICATION_ROUTES.organizationDeclareNewExerciseRequests,
        {
          organizationId: organization.id,
        },
      )}
      tone="primary"
    >
      Declare new
    </Button>
  );
};

const ExerciseRequestsLayout_: React.FC<{
  organizationFragment: ExerciseRequestsLayout_Organization$key;
  viewerFragment: ExerciseRequestsLayout_Viewer$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  return (
    <LargeOneColumnLayout
      isNew
      organizationFragment={organization}
      title="Option exercises"
      topBarActionsRender={() => (
        <TopBarActions organization={organization} viewer={viewer} />
      )}
    >
      <div className="space-y-6">
        <TabNavigation layoutId="exercise-requests-tab-navigation">
          <TabNavigation.Link
            to={generatePath(
              APPLICATION_ROUTES.organizationExerciseRequestsActive,
              {
                organizationId: organization.id,
              },
            )}
          >
            Active
          </TabNavigation.Link>
          <TabNavigation.Link
            to={generatePath(
              APPLICATION_ROUTES.organizationExerciseRequestsCompleted,
              {
                organizationId: organization.id,
              },
            )}
          >
            Completed
          </TabNavigation.Link>
          {!organization.isRemoteEquityEssentials && (
            <TabNavigation.Link
              to={generatePath(
                APPLICATION_ROUTES.organizationExerciseRequestsCanceled,
                {
                  organizationId: organization.id,
                },
              )}
            >
              Canceled
            </TabNavigation.Link>
          )}
        </TabNavigation>
        <Outlet />
      </div>
    </LargeOneColumnLayout>
  );
};

const QUERY = graphql`
  query ExerciseRequestsLayout_Query($organizationId: OrganizationId!) {
    me {
      ...ExerciseRequestsLayout_Viewer
        @arguments(organizationId: $organizationId)
    }
    organization(id: $organizationId) {
      id
      shouldShowExerciseHandler
      ...ExerciseRequestsLayout_Organization
    }
  }
`;

export default function ExerciseRequestsLayout() {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<ExerciseRequestsLayout_Query>(QUERY, {
    organizationId,
  });

  if (!query.organization) {
    return <NotFoundPage />;
  }

  if (!query.organization.shouldShowExerciseHandler) {
    return (
      <RedirectHandler
        to={generatePath(APPLICATION_ROUTES.organizationHome, {
          organizationId: query.organization.id,
        })}
      />
    );
  }

  return (
    <ExerciseRequestsLayout_
      organizationFragment={query.organization}
      viewerFragment={query.me}
    />
  );
}
