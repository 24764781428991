import { Text } from "@remote-com/norma";
import { IconV2DuotoneAlertTriangle } from "@remote-com/norma/icons/IconV2DuotoneAlertTriangle";
import { IconV2OutlineLightBulb } from "@remote-com/norma/icons/IconV2OutlineLightBulb";

import { cx } from "../helpers/cva.config";
import { RoundedBox } from "./ui/RoundedBox";

const ICONS = {
  info: IconV2OutlineLightBulb,
  warning: IconV2DuotoneAlertTriangle,
};

const ICON_COLORS = {
  info: "text-brand-600",
  warning: "text-orange-600",
};

export const DeclarationNoticeMessage: React.FC<{
  action?: React.ReactNode;
  children: React.ReactNode;
  variant?: "info" | "warning";
}> = ({ action, children, variant = "info" }) => {
  const Icon = ICONS[variant];

  return (
    <RoundedBox
      background={variant === "info" ? "grey" : "orange"}
      className="flex items-center gap-4 p-4"
    >
      <Icon className={cx("h-5 shrink-0", ICON_COLORS[variant])} />
      <div className="space-y-2 text-SM/Normal">{children}</div>
      {action && (
        <Text className="shrink-0 text-brand-600" variant="smMedium">
          {action}
        </Text>
      )}
    </RoundedBox>
  );
};
