import { compact, isEmpty } from "lodash";
import React, {
  startTransition,
  useCallback,
  useMemo,
  useState,
  useTransition,
} from "react";
import { FormattedMessage } from "react-intl";
import { useFragment, useRefetchableFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { EmptyListPlaceholder } from "../../../../components/EmptyListPlaceholder/EmptyListPlaceholder";
import { Page } from "../../../../components/Page";
import { RequestChangesOnEasopGrantsButton } from "../../../../components/RequestChangesOnEasopGrantsButton";
import { SelectedItemsActionCard } from "../../../../components/SelectedItemsActionCard";
import { useToaster } from "../../../../components/Toaster";
import { Button } from "../../../../components/ui/Button";
import { NoticeMessage } from "../../../../components/ui/NoticeMessage";
import { Toast } from "../../../../components/ui/Toast";
import { ValuationWarningMessage } from "../../../../components/ValuationWarningMessage";
import { useDebounced } from "../../../../hooks/useDebounced";
import { useDownloadGrantBoardConsentPreview } from "../../../../hooks/useDownloadBoardConsentPreview";
import { useQuery } from "../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import { LegalWorkflowLayout } from "../../../../layouts/LegalWorkflowLayout";
import { useOrganizationIdParam } from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { UnderReview_Organization$key } from "./__generated__/UnderReview_Organization.graphql";
import { UnderReview_Query } from "./__generated__/UnderReview_Query.graphql";
import { UnderReview_ValidateDrafts_Mutation } from "./__generated__/UnderReview_ValidateDrafts_Mutation.graphql";
import { UnderReview_Viewer$key } from "./__generated__/UnderReview_Viewer.graphql";
import { GrantsTable } from "./GrantsTable";

const ORGANIZATION_FRAGMENT = graphql`
  fragment UnderReview_Organization on Organization
  @argumentDefinitions(
    organizationId: { type: "OrganizationId!" }
    easopGrantsSearch: { type: "String", defaultValue: "" }
  )
  @refetchable(queryName: "UnderReview_Organization_RefetchQuery") {
    id
    name
    plan
    searchedPendingReviewEasopGrants: easopGrants(
      filters: { statusIn: [PendingReview], search: $easopGrantsSearch }
    ) {
      ...GrantsTable_EasopGrant @arguments(organizationId: $organizationId)
    }
    pendingReviewEasopGrants: easopGrants(
      filters: { statusIn: [PendingReview] }
    ) {
      id
      label
      isUsingExistingLabel
    }
    valuationWarnings {
      valuationType
      reason
      ...ValuationWarningMessage_ValuationWarning
    }
    ...GrantsTable_Organization
    ...ValuationWarningMessage_Organization
    ...LegalWorkflowLayout_Organization
    ...useDownloadBoardConsentPreview_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment UnderReview_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    isSuperAdmin
    isAllowedToManageOrganization(organizationId: $organizationId)
    ...GrantsTable_Account
  }
`;

const VALIDATE_DRAFTS_MUTATION = graphql`
  mutation UnderReview_ValidateDrafts_Mutation($easopGrantsIds: [UUID!]!) {
    reviewEasopGrants(easopGrantsIds: $easopGrantsIds) {
      grantStatus
    }
  }
`;

function GrantLabelWarningNoticeMessage() {
  return (
    <NoticeMessage hasColor={false} size="Large" variant="Danger">
      Update grants label starting with &quot;Draft&quot;.
    </NoticeMessage>
  );
}

const AdminEquityPrepareYourGrantsUnderReviewPage_: React.FC<{
  organizationFragment: UnderReview_Organization$key;
  viewerFragment: UnderReview_Viewer$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const [organization, refetchOrganization] = useRefetchableFragment(
    ORGANIZATION_FRAGMENT,
    organizationFragment,
  );
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const [grantSelection, setGrantSelection] = useState<Set<string>>(new Set());
  const selectedGrants = useMemo(
    () =>
      organization.pendingReviewEasopGrants.filter((grant) =>
        grantSelection.has(grant.id),
      ),
    [grantSelection, organization.pendingReviewEasopGrants],
  );
  const easopGrantsIds = useMemo(
    () => selectedGrants.map(({ id }) => id),
    [selectedGrants],
  );

  const [validateDrafts, validateDraftsMutationIsInFlight] =
    useSafeMutation<UnderReview_ValidateDrafts_Mutation>(
      VALIDATE_DRAFTS_MUTATION,
    );

  const toaster = useToaster();

  const [searchTransitionIsInProgress, startSearchTransition] = useTransition();

  const {
    isDebouncing: searchIsDebouncing,
    liveState: searchInputValue,
    setState: setSearchInputValue,
  } = useDebounced<string>({
    delay: 1000,
    initialState: "",
    onDebounce: useCallback(
      (search: string) => {
        startSearchTransition(() => {
          refetchOrganization({
            easopGrantsSearch: search,
          });
        });
      },
      [refetchOrganization],
    ),
  });

  const handleDataUpdated = useCallback(() => {
    startTransition(() => {
      refetchOrganization({
        easopGrantsSearch: searchInputValue,
      });
    });
  }, [refetchOrganization, searchInputValue]);

  const handleApproveDraftsButtonClick = useCallback(async () => {
    await validateDrafts({
      variables: {
        easopGrantsIds,
      },
    });

    handleDataUpdated();

    setGrantSelection(new Set());

    toaster.push(
      <Toast title="Wonderful!">
        <FormattedMessage
          defaultMessage={`{grantCount, plural,
            one {# draft}
            other {# drafts}
          } successfully validated!`}
          values={{
            grantCount: easopGrantsIds.length,
          }}
        />
      </Toast>,
    );
  }, [handleDataUpdated, toaster, validateDrafts, easopGrantsIds]);

  const handleRequestChangesCompleted = useCallback(() => {
    setGrantSelection(new Set());
    handleDataUpdated();
  }, [handleDataUpdated]);

  const selectedGrantsWhereLabelStartsWithDraft = useMemo(
    () => selectedGrants.filter((grant) => grant.label.startsWith("Draft")),
    [selectedGrants],
  );
  const someGrantsLabelStartsWithDraft = useMemo(
    () =>
      organization.pendingReviewEasopGrants.some((grant) =>
        grant.label.startsWith("Draft"),
      ),
    [organization.pendingReviewEasopGrants],
  );

  const selectedGrantUsingExistingLabel = useMemo(
    () =>
      selectedGrants.filter(({ isUsingExistingLabel }) => isUsingExistingLabel),
    [selectedGrants],
  );
  const selectedGrantsAreValid =
    isEmpty(selectedGrantsWhereLabelStartsWithDraft) &&
    isEmpty(selectedGrantUsingExistingLabel);

  const { downloadBoardConsentPreview, downloadIsInFlight } =
    useDownloadGrantBoardConsentPreview({
      easopGrantsIds,
      organizationFragment: organization,
    });

  return (
    <LegalWorkflowLayout
      onSearchChange={setSearchInputValue}
      organizationFragment={organization}
      searchIsLoading={searchTransitionIsInProgress || searchIsDebouncing}
      searchPlaceholder="Search drafts..."
      searchValue={searchInputValue}
      subtitle="Drafts the Easop team is currently reviewing and validating"
      title="Under review"
      topBarActionsRender={({ mainContentIsScrolled }) => {
        if (!viewer.isSuperAdmin) return null;

        return (
          <SelectedItemsActionCard
            actions={
              <>
                <Button
                  className="whitespace-nowrap"
                  disabled={isEmpty(selectedGrants)}
                  loading={downloadIsInFlight}
                  onClick={downloadBoardConsentPreview}
                  size="small"
                  variant="Secondary Full"
                >
                  Preview board consent
                </Button>
                <RequestChangesOnEasopGrantsButton
                  onCompleted={handleRequestChangesCompleted}
                  selectedGrants={selectedGrants}
                />
                <Button
                  disabled={
                    selectedGrants.length === 0 ||
                    !selectedGrantsAreValid ||
                    organization.plan === "freeTrial"
                  }
                  loading={validateDraftsMutationIsInFlight}
                  onClick={handleApproveDraftsButtonClick}
                  size="small"
                  variant="Approve Light"
                >
                  Approve
                </Button>
              </>
            }
            compact={mainContentIsScrolled}
            emptyListLabel="Select drafts"
            itemCount={selectedGrants.length}
            loading={validateDraftsMutationIsInFlight}
            pluralLabel="drafts selected"
            singularLabel="draft selected"
          />
        );
      }}
      warnings={
        <>
          {organization.valuationWarnings.map((warning) => (
            <ValuationWarningMessage
              key={`${warning.valuationType}-${warning.reason}`}
              onValuationSentForReview={handleDataUpdated}
              organizationFragment={organization}
              valuationWarningFragment={warning}
            />
          ))}
          {someGrantsLabelStartsWithDraft && <GrantLabelWarningNoticeMessage />}

          {!isEmpty(selectedGrantUsingExistingLabel) && (
            <NoticeMessage size="Large" variant="Danger">
              <FormattedMessage
                defaultMessage="{grantsLabel} {count, plural, one {is} other {are}} already used in a CTMS grant, please change before validating the {count, plural, one {grant} other {grants}}"
                values={{
                  count: selectedGrantUsingExistingLabel.length,
                  grantsLabel: (
                    <strong>
                      {selectedGrantUsingExistingLabel
                        .map(({ label }) => label)
                        .join(", ")}
                    </strong>
                  ),
                }}
              />
            </NoticeMessage>
          )}
        </>
      }
    >
      {isEmpty(organization.pendingReviewEasopGrants) ? (
        <EmptyListPlaceholder
          hideImage
          subtitle="Feel free to create new grants or plan equity from your planning"
          title="No drafts currently under review"
        />
      ) : (
        <GrantsTable
          columns={compact([
            "Draft",
            "Employee",
            "Ownership",
            "Details",
            "Strike price",
            "Grant type",
            viewer.isAllowedToManageOrganization && "Actions",
          ])}
          easopGrantsFragment={organization.searchedPendingReviewEasopGrants}
          emptyListPlaceholder="No drafts match your search"
          grantSelection={viewer.isSuperAdmin ? grantSelection : undefined}
          onGrantSelectionChange={setGrantSelection}
          onUpdate={handleDataUpdated}
          organizationFragment={organization}
          showBoardConsentReadinessStatus={viewer.isSuperAdmin}
          viewerFragment={viewer}
        />
      )}
    </LegalWorkflowLayout>
  );
};

const QUERY = graphql`
  query UnderReview_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...UnderReview_Organization @arguments(organizationId: $organizationId)
    }
    me {
      ...UnderReview_Viewer @arguments(organizationId: $organizationId)
    }
  }
`;

const AdminEquityPrepareYourGrantsUnderReviewPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<UnderReview_Query>(QUERY, {
    organizationId,
  });

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="New Equity Flow"
      analyticsName="Admin - New Equity Under Review"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} under review grants`}
    >
      <AdminEquityPrepareYourGrantsUnderReviewPage_
        organizationFragment={query.organization}
        viewerFragment={query.me}
      />
    </Page>
  );
};

export default AdminEquityPrepareYourGrantsUnderReviewPage;
