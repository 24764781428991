/**
 * @generated SignedSource<<98ac22257c617d4797a39fe5253433c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CannotBeInvitedReason = "ALREADY_INVITED" | "MISSING_INFORMATION" | "NO_GRANTS" | "REMOTE_EMPLOYEE" | "SETTLED";
import { FragmentRefs } from "relay-runtime";
export type PortalSection_Employee$data = {
  readonly cannotBeInvitedReason: CannotBeInvitedReason | null;
  readonly hasPortalAccess: boolean;
  readonly id: string;
  readonly organizationId: string;
  readonly " $fragmentType": "PortalSection_Employee";
};
export type PortalSection_Employee$key = {
  readonly " $data"?: PortalSection_Employee$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortalSection_Employee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortalSection_Employee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPortalAccess",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cannotBeInvitedReason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationId",
      "storageKey": null
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "102216eb7cf2140cc2196750812e9590";

export default node;
