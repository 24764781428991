/**
 * @generated SignedSource<<d398e2ecefa4596753d0eb242978ce98>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FairMarketValuePage_CompanySettings_Organization$data = {
  readonly fairMarketValueHistory: ReadonlyArray<{
    readonly date: string;
    readonly expirationDate: string;
    readonly isLatest: boolean;
    readonly value: number;
  }>;
  readonly id: string;
  readonly latestFairMarketValue: {
    readonly date: string;
    readonly value: number;
  } | null;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"CurrencyUnit_Organization" | "FairMarketValueHistoryChart_Organization" | "FormattedCurrency_Organization" | "LargeOneColumnLayout_Organization">;
  readonly " $fragmentType": "FairMarketValuePage_CompanySettings_Organization";
};
export type FairMarketValuePage_CompanySettings_Organization$key = {
  readonly " $data"?: FairMarketValuePage_CompanySettings_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"FairMarketValuePage_CompanySettings_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FairMarketValuePage_CompanySettings_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FairMarketValue",
      "kind": "LinkedField",
      "name": "fairMarketValueHistory",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "expirationDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isLatest",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FairMarketValue",
      "kind": "LinkedField",
      "name": "latestFairMarketValue",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LargeOneColumnLayout_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FairMarketValueHistoryChart_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedCurrency_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurrencyUnit_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "912aaf93e01a4db079b2289e92a500d0";

export default node;
