import { Avatar, DropdownMenu, Text } from "@remote-com/norma";
import { IconV2OutlineArrowCornerCw } from "@remote-com/norma/icons/IconV2OutlineArrowCornerCw";
import { IconV2OutlineExpand } from "@remote-com/norma/icons/IconV2OutlineExpand";
import { IconV2OutlineRefreshCcw } from "@remote-com/norma/icons/IconV2OutlineRefreshCcw";
import { IconV2OutlineUserCheck } from "@remote-com/norma/icons/IconV2OutlineUserCheck";
import { compact } from "lodash";
import React, { useCallback, useMemo } from "react";
import { useFragment } from "react-relay";
import { generatePath, useNavigate } from "react-router-dom";
import { graphql } from "relay-runtime";

import { useAccountRoles } from "../hooks/useAccountRoles";
import { useBoolean } from "../hooks/useBoolean";
import { useSafeMutation } from "../hooks/useSafeMutation";
import { APPLICATION_ROUTES } from "../paths";
import { useAuthentication } from "../services/AuthenticationProvider";
import CONFIGURATION from "../services/configuration";
import { OrganizationRoleSwitcher_Account$key } from "./__generated__/OrganizationRoleSwitcher_Account.graphql";
import { OrganizationRoleSwitcher_Organization$key } from "./__generated__/OrganizationRoleSwitcher_Organization.graphql";
import { OrganizationRoleSwitcher_ResetDatabase_Mutation } from "./__generated__/OrganizationRoleSwitcher_ResetDatabase_Mutation.graphql";
import { OrganizationRoleSwitcher_resetSeedOrganization_Mutation } from "./__generated__/OrganizationRoleSwitcher_resetSeedOrganization_Mutation.graphql";
import { useToaster } from "./Toaster";
import { ConfirmationModal } from "./ui/ConfirmationModal";
import { Toast } from "./ui/Toast";

export type Role = "Admin" | "Board Member" | "Grantee";

const ORGANIZATION_FRAGMENT = graphql`
  fragment OrganizationRoleSwitcher_Organization on Organization {
    ...useAccountRoles_Organization
  }
`;

const ACCOUNT_FRAGMENT = graphql`
  fragment OrganizationRoleSwitcher_Account on Account {
    isSuperAdmin
    email
    fullName
    ...useAccountRoles_Account
  }
`;

const RESET_DATABASE_MUTATION = graphql`
  mutation OrganizationRoleSwitcher_ResetDatabase_Mutation {
    resetDatabase
  }
`;
const RESET_SEED_ORGANIZATION_MUTATION = graphql`
  mutation OrganizationRoleSwitcher_resetSeedOrganization_Mutation {
    resetSeedOrganization
  }
`;

const environment = CONFIGURATION.ENVIRONMENT;

export const OrganizationRoleSwitcher: React.FC<{
  accountFragment: OrganizationRoleSwitcher_Account$key;
  activeRole: Role;
  organizationFragment: OrganizationRoleSwitcher_Organization$key;
}> = ({ accountFragment, activeRole, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const account = useFragment(ACCOUNT_FRAGMENT, accountFragment);

  const authentication = useAuthentication();
  const toaster = useToaster();

  const navigate = useNavigate();

  const handleLogOutButtonClick = useCallback(() => {
    authentication.logout();
    void navigate("/", { replace: true });
  }, [authentication, navigate]);

  const {
    setFalse: hideResetDatabaseModal,
    setTrue: showResetDatabaseModal,
    value: resetDatabaseModalIsShown,
  } = useBoolean(false);
  const {
    setFalse: hideResetAcmeModal,
    setTrue: showResetAcmeModal,
    value: resetAcmeModalIsShown,
  } = useBoolean(false);

  const {
    accountHasRoleInOtherOrganizations,
    activeOrganizationAdminRole,
    activeOrganizationBoardMemberRolePath,
    activeOrganizationHomePath,
  } = useAccountRoles({
    account,
    activeOrganization: organization,
  });

  const [resetDatabase, resetDatabaseMutationIsInFlight] =
    useSafeMutation<OrganizationRoleSwitcher_ResetDatabase_Mutation>(
      RESET_DATABASE_MUTATION,
    );
  const [_resetSeedOrganization, resetSeedOrganizationMutationIsInFlight] =
    useSafeMutation<OrganizationRoleSwitcher_resetSeedOrganization_Mutation>(
      RESET_SEED_ORGANIZATION_MUTATION,
    );
  const resetSeedOrganization = useCallback(async () => {
    await _resetSeedOrganization({
      variables: {},
    });

    toaster.push(
      <Toast title="Great!">Acme has been successfully reset!</Toast>,
    );

    hideResetAcmeModal();
  }, [_resetSeedOrganization, hideResetAcmeModal, toaster]);

  const showResetDatabaseButton =
    account.isSuperAdmin && environment === "local";
  const showResetAcmeButton =
    account.isSuperAdmin && environment !== "production";

  const availableActions = useMemo(
    () =>
      compact([
        activeRole === "Board Member" && activeOrganizationAdminRole && (
          <DropdownMenu.Item
            href={activeOrganizationHomePath}
            Icon={IconV2OutlineUserCheck}
          >
            Sign in as Admin
          </DropdownMenu.Item>
        ),
        activeRole === "Admin" && activeOrganizationBoardMemberRolePath && (
          <DropdownMenu.Item
            href={activeOrganizationBoardMemberRolePath}
            Icon={IconV2OutlineUserCheck}
          >
            Sign in as Board member
          </DropdownMenu.Item>
        ),
        accountHasRoleInOtherOrganizations && (
          <DropdownMenu.Item
            href={generatePath(APPLICATION_ROUTES.roleSelection, {})}
            Icon={IconV2OutlineExpand}
          >
            Switch organization
          </DropdownMenu.Item>
        ),
        showResetDatabaseButton && (
          <DropdownMenu.Item
            Icon={IconV2OutlineRefreshCcw}
            onClick={showResetDatabaseModal}
          >
            Reset database
          </DropdownMenu.Item>
        ),
        showResetAcmeButton && (
          <DropdownMenu.Item
            Icon={IconV2OutlineRefreshCcw}
            onClick={showResetAcmeModal}
          >
            Reset ACME
          </DropdownMenu.Item>
        ),
      ]),
    [
      activeRole,
      activeOrganizationAdminRole,
      activeOrganizationBoardMemberRolePath,
      activeOrganizationHomePath,
      accountHasRoleInOtherOrganizations,
      showResetDatabaseButton,
      showResetAcmeButton,
      showResetDatabaseModal,
      showResetAcmeModal,
    ],
  );

  return (
    <>
      <ConfirmationModal
        confirmationLabel="Reset database"
        loading={resetDatabaseMutationIsInFlight}
        onClose={hideResetDatabaseModal}
        onConfirmed={async () => {
          await resetDatabase({
            variables: {},
          });

          toaster.push(
            <Toast title="Great!">Database has been successfully reset!</Toast>,
          );

          hideResetDatabaseModal();

          void navigate(generatePath(APPLICATION_ROUTES.roleSelection));
        }}
        show={resetDatabaseModalIsShown}
        title="Reset the database"
      >
        Are you sure you want to reset the database?
      </ConfirmationModal>
      <ConfirmationModal
        confirmationLabel="Reset ACME"
        loading={resetSeedOrganizationMutationIsInFlight}
        onClose={hideResetAcmeModal}
        onConfirmed={resetSeedOrganization}
        show={resetAcmeModalIsShown}
        title="Reset ACME"
      >
        Are you sure you want to reset ACME?
      </ConfirmationModal>
      <DropdownMenu
        // @ts-expect-error className and trigger not defined on DropdownMenu props
        className="z-10"
        trigger={
          <button type="button">
            <Avatar name={account.fullName} size="sm" />
          </button>
        }
      >
        <div className="flex flex-col gap-2 p-2">
          <Text color="grey.600" variant="2xsSemiBold">
            SIGNED IN AS
          </Text>
          <div className="flex items-center gap-2">
            <Avatar name={account.fullName} size="sm" />
            <Text color="grey.600" variant="smMedium">
              {account.email}
            </Text>
          </div>
        </div>
        {availableActions.map((action, index) =>
          React.cloneElement(action, {
            hasDivider: index === 0,
          }),
        )}
        <DropdownMenu.Item
          data-cy="log-out-button"
          hasDivider
          Icon={IconV2OutlineArrowCornerCw}
          onSelect={handleLogOutButtonClick}
        >
          Log out
        </DropdownMenu.Item>
      </DropdownMenu>
    </>
  );
};
