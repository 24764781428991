/**
 * @generated SignedSource<<e6f0ebabc76716fee99b8f1f16573a06>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AddNewFairMarketValueToEssentialsOrganizationAttributes = {
  date: string;
  documentId?: string | null;
  value: number;
};
export type AddNewFairMarketValuePage_AddNewFairMarketValueToEssentialsOrganization_Mutation$variables = {
  attributes: AddNewFairMarketValueToEssentialsOrganizationAttributes;
  organizationId: string;
};
export type AddNewFairMarketValuePage_AddNewFairMarketValueToEssentialsOrganization_Mutation$data = {
  readonly addNewFairMarketValueToEssentialsOrganization: {
    readonly __typename: "FairMarketValue";
  };
};
export type AddNewFairMarketValuePage_AddNewFairMarketValueToEssentialsOrganization_Mutation = {
  response: AddNewFairMarketValuePage_AddNewFairMarketValueToEssentialsOrganization_Mutation$data;
  variables: AddNewFairMarketValuePage_AddNewFairMarketValueToEssentialsOrganization_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "attributes"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "attributes",
    "variableName": "attributes"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AddNewFairMarketValuePage_AddNewFairMarketValueToEssentialsOrganization_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "FairMarketValue",
        "kind": "LinkedField",
        "name": "addNewFairMarketValueToEssentialsOrganization",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AddNewFairMarketValuePage_AddNewFairMarketValueToEssentialsOrganization_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "FairMarketValue",
        "kind": "LinkedField",
        "name": "addNewFairMarketValueToEssentialsOrganization",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2b38121a3f7a304e2585502bf059595c",
    "id": null,
    "metadata": {},
    "name": "AddNewFairMarketValuePage_AddNewFairMarketValueToEssentialsOrganization_Mutation",
    "operationKind": "mutation",
    "text": "mutation AddNewFairMarketValuePage_AddNewFairMarketValueToEssentialsOrganization_Mutation(\n  $organizationId: OrganizationId!\n  $attributes: AddNewFairMarketValueToEssentialsOrganizationAttributes!\n) {\n  addNewFairMarketValueToEssentialsOrganization(organizationId: $organizationId, attributes: $attributes) {\n    __typename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f6c32406c892a5ff8177b0317afad588";

export default node;
