/**
 * @generated SignedSource<<32321ab25a85741472cf2c08d62f2d32>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RSUVestingTable_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FormattedCurrency_Organization">;
  readonly " $fragmentType": "RSUVestingTable_Organization";
};
export type RSUVestingTable_Organization$key = {
  readonly " $data"?: RSUVestingTable_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"RSUVestingTable_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RSUVestingTable_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedCurrency_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "01ca36faab2da9114e337eb4ffb8b99c";

export default node;
