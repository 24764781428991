import { useCallback } from "react";
import { useFragment, useMutation } from "react-relay";
import { graphql } from "relay-runtime";

import { Toggle } from "../../../../components/ui/Form/Toggle";
import {
  PortalSection_Employee$data,
  PortalSection_Employee$key,
} from "./__generated__/PortalSection_Employee.graphql";
import { EmptyStatePlaceholder } from "./EmptyStatePlaceholder";
import { Section } from "./Section";

const GRANT_ACCESS_TO_GRANTEE_PORTAL_MUTATION = graphql`
  mutation PortalSection_GrantAccessToGranteePortalMutation(
    $organizationId: OrganizationId!
    $granteeId: GranteeId!
  ) {
    grantAccessToGranteePortal(
      granteeIds: [$granteeId]
      organizationId: $organizationId
      source: "Remote Equity Web App"
    ) {
      ...PortalSection_Employee
    }
  }
`;

const REVOKE_ACCESS_TO_GRANTEE_PORTAL_MUTATION = graphql`
  mutation PortalSection_RevokeAccessToGranteePortalMutation(
    $granteeId: GranteeId!
  ) {
    revokeAccessToGranteePortal(granteeId: $granteeId) {
      ...PortalSection_Employee
    }
  }
`;

const EMPLOYEE_FRAGMENT = graphql`
  fragment PortalSection_Employee on Grantee {
    id
    hasPortalAccess
    cannotBeInvitedReason
    organizationId
  }
`;

export function PortalSection({
  employeeFragment,
}: {
  employeeFragment: PortalSection_Employee$key;
}) {
  const employee = useFragment(EMPLOYEE_FRAGMENT, employeeFragment);

  return (
    <Section title="Portal">
      <PortalSectionContent employee={employee} />
    </Section>
  );
}

function PortalSectionContent({
  employee,
}: {
  employee: PortalSection_Employee$data;
}) {
  const [grantAccessToGranteePortal, grantAccessToGranteePortalLoading] =
    useMutation(GRANT_ACCESS_TO_GRANTEE_PORTAL_MUTATION);
  const [revokeAccessToGranteePortal, revokeAccessToGranteePortalLoading] =
    useMutation(REVOKE_ACCESS_TO_GRANTEE_PORTAL_MUTATION);
  const isLoading =
    grantAccessToGranteePortalLoading || revokeAccessToGranteePortalLoading;

  const handleToggleChange = useCallback(
    (checked: boolean) => {
      if (checked) {
        grantAccessToGranteePortal({
          variables: {
            granteeId: employee.id,
            organizationId: employee.organizationId,
          },
        });
      } else {
        revokeAccessToGranteePortal({
          variables: {
            granteeId: employee.id,
          },
        });
      }
    },
    [
      employee.id,
      employee.organizationId,
      grantAccessToGranteePortal,
      revokeAccessToGranteePortal,
    ],
  );

  if (
    employee.cannotBeInvitedReason === "MISSING_INFORMATION" ||
    employee.cannotBeInvitedReason === "NO_GRANTS" ||
    employee.cannotBeInvitedReason === "SETTLED"
  ) {
    switch (employee.cannotBeInvitedReason) {
      case "MISSING_INFORMATION":
        return (
          <EmptyStatePlaceholder>
            Employee cannot get invited to their portal because there are
            missing details.
          </EmptyStatePlaceholder>
        );
      case "NO_GRANTS":
        return (
          <EmptyStatePlaceholder>
            Employee cannot get invited to their portal unless equity grants
            have been issued.
          </EmptyStatePlaceholder>
        );
      case "SETTLED":
        return (
          <EmptyStatePlaceholder>
            Employee cannot get invited to their portal because they are
            settled.
          </EmptyStatePlaceholder>
        );
    }
  }

  return (
    <div className="flex items-center justify-between gap-10 border-b-[0.5px] border-grey-300 py-2">
      <div className="space-y-2">
        <div className="text-Base/Medium">Access to portal</div>
        <div className="text-SM text-grey-500">
          Users is allowed to access their employee portal
        </div>
      </div>
      <div>
        <Toggle
          disabled={isLoading}
          enabled={employee.hasPortalAccess}
          loading={isLoading}
          onChange={handleToggleChange}
        />
      </div>
    </div>
  );
}
