import { Pill } from "@remote-com/norma";
import { isFuture } from "date-fns";
import { graphql, useFragment } from "react-relay";

import { useOrganizationCTMS } from "../hooks/useOrganizationCTMS";
import {
  GranteePendingTerminationTag_Grantee$data,
  GranteePendingTerminationTag_Grantee$key,
} from "./__generated__/GranteePendingTerminationTag_Grantee.graphql";
import {
  GranteePendingTerminationTag_Organization$data,
  GranteePendingTerminationTag_Organization$key,
} from "./__generated__/GranteePendingTerminationTag_Organization.graphql";
import { ShortDate } from "./ShortDate";
import { TooltipContainer } from "./ui/TooltipContainer";

const GRANTEE_FRAGMENT = graphql`
  fragment GranteePendingTerminationTag_Grantee on Grantee {
    terminationInformations {
      terminationDate
    }
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment GranteePendingTerminationTag_Organization on Organization {
    ...useOrganizationCTMS_Organization
  }
`;

const TooltipContent: React.FC<{
  grantee: GranteePendingTerminationTag_Grantee$data;
  organization: GranteePendingTerminationTag_Organization$data;
}> = ({ grantee, organization }) => {
  const organizationCTMS = useOrganizationCTMS({
    organizationFragment: organization,
  });

  if (!grantee.terminationInformations) {
    return null;
  }

  if (isFuture(new Date(grantee.terminationInformations.terminationDate))) {
    return (
      <>
        Will be terminated on{" "}
        <ShortDate value={grantee.terminationInformations.terminationDate} />
      </>
    );
  }

  if (organizationCTMS) {
    return <>Termination pending implementation in {organizationCTMS.name}</>;
  }

  return <>Termination pending implementation</>;
};

export const GranteePendingTerminationTag: React.FC<{
  granteeFragment: GranteePendingTerminationTag_Grantee$key;
  organizationFragment: GranteePendingTerminationTag_Organization$key;
}> = ({ granteeFragment, organizationFragment }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  if (!grantee.terminationInformations) {
    return null;
  }

  return (
    <TooltipContainer
      tooltipContent={
        <TooltipContent grantee={grantee} organization={organization} />
      }
    >
      <Pill tone="warning">Pending termination</Pill>
    </TooltipContainer>
  );
};
