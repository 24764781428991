import React from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { EquityOverviewCard } from "../../../components/EquityOverviewCard";
import { EsopPoolChartCard } from "../../../components/EsopPoolChartCard";
import { FairMarketValueHistoryCard } from "../../../components/FairMarketValueHistoryCard";
import { FairMarketValueHistoryChart } from "../../../components/FairMarketValueHistoryChart";
import { GrantedSharesBreakdownCard } from "../../../components/GrantedSharesBreakdownCard";
import { OrganizationInfoCard } from "../../../components/OrganizationInfoCard";
import { Page } from "../../../components/Page";
import { PricePerShareHistoryCard } from "../../../components/PricePerShareHistoryCard";
import { PricePerShareHistoryChart } from "../../../components/PricePerShareHistoryChart";
import { BoardMemberTasksCard } from "../../../components/Tasks/BoardMemberTasksCard";
import { CenteredColumnLayout } from "../../../components/ui/Layout/CenteredColumnLayout";
import { TwoColumnsGrid } from "../../../components/ui/TwoColumnsGrid";
import { Typography } from "../../../components/ui/Typography";
import { useQuery } from "../../../hooks/useQuery";
import { useBoardMemberIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { Overview_BoardMember$key } from "./__generated__/Overview_BoardMember.graphql";
import { Overview_Query } from "./__generated__/Overview_Query.graphql";

const BOARD_MEMBER_FRAGMENT = graphql`
  fragment Overview_BoardMember on BoardMember {
    organization {
      ...EquityOverviewCard_Organization
      ...EsopPoolChartCard_Organization
      ...PricePerShareHistoryCard_Organization
      ...PricePerShareHistoryChart_Organization
      ...FairMarketValueHistoryCard_Organization
      ...FairMarketValueHistoryChart_Organization
      ...OrganizationInfoCard_Organization
      ...GrantedSharesBreakdownCard_Organization
    }
    account {
      firstName
      lastName
    }
    ...BoardMemberTasksCard_BoardMember
  }
`;

const OverviewPage_: React.FC<{
  boardMemberFragment: Overview_BoardMember$key;
}> = ({ boardMemberFragment }) => {
  const boardMember = useFragment(BOARD_MEMBER_FRAGMENT, boardMemberFragment);
  const viewer = boardMember.account;
  const organization = boardMember.organization;

  return (
    <CenteredColumnLayout showFooter>
      <div className="space-y-6">
        <Typography as="div" variant="Medium/Large">
          Welcome back, {viewer.firstName} {viewer.lastName}!
        </Typography>

        <TwoColumnsGrid>
          <OrganizationInfoCard organizationFragment={organization} />
          <BoardMemberTasksCard boardMemberFragment={boardMember} />
          <EquityOverviewCard
            hideDraftsWatchout
            organizationFragment={organization}
          />
          <EsopPoolChartCard organizationFragment={organization} />
          <GrantedSharesBreakdownCard organizationFragment={organization} />
          <FairMarketValueHistoryCard organizationFragment={organization}>
            <FairMarketValueHistoryChart organizationFragment={organization} />
          </FairMarketValueHistoryCard>
          <PricePerShareHistoryCard organizationFragment={organization}>
            <PricePerShareHistoryChart organizationFragment={organization} />
          </PricePerShareHistoryCard>
        </TwoColumnsGrid>
      </div>
    </CenteredColumnLayout>
  );
};

const QUERY = graphql`
  query Overview_Query($boardMemberId: UUID!) {
    boardMember(id: $boardMemberId) {
      ...Overview_BoardMember
      organization {
        id
        name
      }
    }
  }
`;

const OverviewPage: React.FC = () => {
  const boardMemberId = useBoardMemberIdParam();
  const { query } = useQuery<Overview_Query>(QUERY, {
    boardMemberId,
  });

  if (!query.boardMember) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsName="Board - Home"
      organizationId={query.boardMember.organization.id}
      title={`Board member | ${query.boardMember.organization.name} overview`}
    >
      <OverviewPage_ boardMemberFragment={query.boardMember} />
    </Page>
  );
};

export default OverviewPage;
