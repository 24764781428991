/**
 * @generated SignedSource<<a25ee35ac155f116b3f747e2ab6a8d55>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmployeePortalLayout_TopBar_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FormattedCurrencyCompact_Organization" | "OrganizationRoleSwitcher_Organization">;
  readonly " $fragmentType": "EmployeePortalLayout_TopBar_Organization";
};
export type EmployeePortalLayout_TopBar_Organization$key = {
  readonly " $data"?: EmployeePortalLayout_TopBar_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeePortalLayout_TopBar_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmployeePortalLayout_TopBar_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrganizationRoleSwitcher_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedCurrencyCompact_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "ce9034ba1c76c1005ca9b68d43993e16";

export default node;
