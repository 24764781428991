import { zodResolver } from "@hookform/resolvers/zod";
import { Button, ButtonIcon, Text } from "@remote-com/norma";
import { IconV2OutlineTimes } from "@remote-com/norma/icons/IconV2OutlineTimes";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { RemoteInputSelect } from "../../../components/ui/Form/Inputs/RemoteInputSelect";
import { RemoteInputText } from "../../../components/ui/Form/Inputs/RemoteInputText";
import { Modal } from "../../../components/ui/Modal";
import { useSafeMutation } from "../../../hooks/useSafeMutation";
import { countries, US_COUNTRY_CODE } from "../../../services/Country";
import { ContactUsModal_Organization$key } from "./__generated__/ContactUsModal_Organization.graphql";
import { ContactUsModal_SendContactUsNotification_Mutation } from "./__generated__/ContactUsModal_SendContactUsNotification_Mutation.graphql";
import { ContactUsModal_Viewer$key } from "./__generated__/ContactUsModal_Viewer.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment ContactUsModal_Organization on Organization {
    id
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment ContactUsModal_Viewer on Account {
    fullName
    email
  }
`;

const SEND_CONTACT_US_NOTIFICATION_MUTATION = graphql`
  mutation ContactUsModal_SendContactUsNotification_Mutation(
    $organizationId: OrganizationId!
    $attributes: ContactUsNotificationAttributes!
  ) {
    sendContactUsNotification(
      organizationId: $organizationId
      attributes: $attributes
    )
  }
`;

const FORM_SCHEMA = z.object({
  ctmsSolution: z.enum([
    "SPREADSHEETS",
    "NO_CTMS",
    "CARTA",
    "PULLEY",
    "SHAREWORKS",
    "ANGELLIST",
    "LEDGY",
    "LTSE_EQUITY",
    "CAPDESK",
    "EQUIFY",
    "VESTD",
    "CERTENT",
    "GLOBAL_SHARES",
    "ASTRELLA",
    "COMPTRACK",
    "OTHER",
  ]),
  email: z.string().trim().email(),
  headquartersLocationCountryCode: z.string(),
  name: z.string().trim().min(1),
  reasonOfContact: z.enum([
    "USING_OTHER_CTMS",
    "WANT_TO_KNOW_MORE",
    "NEED_SUPPORT",
  ]),
});

export const ContactUsModal: React.FC<{
  onClose: () => void;
  organizationFragment: ContactUsModal_Organization$key;
  show: boolean;
  viewerFragment: ContactUsModal_Viewer$key;
}> = ({ onClose, organizationFragment, show, viewerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  const form = useForm<z.infer<typeof FORM_SCHEMA>>({
    defaultValues: {
      ctmsSolution: undefined,
      email: viewer.email,
      headquartersLocationCountryCode: US_COUNTRY_CODE,
      name: viewer.fullName,
      reasonOfContact: "USING_OTHER_CTMS",
    },
    resolver: zodResolver(FORM_SCHEMA),
  });

  const [triggerSendContactUsNotificationMutation] =
    useSafeMutation<ContactUsModal_SendContactUsNotification_Mutation>(
      SEND_CONTACT_US_NOTIFICATION_MUTATION,
    );

  const handleSubmit = form.handleSubmit(async (formData) => {
    await triggerSendContactUsNotificationMutation({
      variables: {
        attributes: {
          ctmsSolution: formData.ctmsSolution,
          email: formData.email,
          headquartersLocationCountryCode:
            formData.headquartersLocationCountryCode,
          name: formData.name,
          reasonOfContact: formData.reasonOfContact,
        },
        organizationId: organization.id,
      },
    });
    onClose();
  });

  const countriesOptions = useMemo(
    () =>
      countries.map((country) => ({
        label: `${country.emoji} ${country.name}`,
        value: country.code,
      })),
    [],
  );

  return (
    <Modal.Raw
      onClose={onClose}
      panelClassName="h-full w-full !bg-remote-background-base"
      show={show}
    >
      <div className="relative flex h-full items-center justify-center overflow-y-auto p-10">
        <div className="absolute right-10 top-10">
          <ButtonIcon
            aria-labelledby=""
            Icon={IconV2OutlineTimes}
            onClick={onClose}
            size="md"
            tone="secondary"
            variant="outline"
          />
        </div>
        <form
          className="w-[600px] max-w-full space-y-16 text-center"
          onSubmit={handleSubmit}
        >
          <div>
            <Text className="text-remote-grey-900" variant="4xlMedium">
              Contact us
            </Text>
            <Text className="text-remote-grey-600" variant="sm">
              We’ll reach out to set a time to chat soon.
            </Text>
          </div>
          <div className="space-y-6 text-start">
            <RemoteInputText.Form
              control={form.control}
              label="Full name"
              name="name"
              size="md"
              type="text"
            />
            <RemoteInputText.Form
              control={form.control}
              label="Company email"
              name="email"
              size="md"
              type="email"
            />
            <RemoteInputSelect.Form
              control={form.control}
              label="Headquarters location"
              name="headquartersLocationCountryCode"
              options={countriesOptions}
            />
            <RemoteInputSelect.Form
              control={form.control}
              description="Let us know how we can help you."
              label="Reason of contact"
              name="reasonOfContact"
              options={[
                {
                  label: "I'm using another cap table management solution",
                  value: "USING_OTHER_CTMS",
                },
                {
                  label: "I want to know more about Remote Equity",
                  value: "WANT_TO_KNOW_MORE",
                },
                {
                  label: "I need support for Remote Equity",
                  value: "NEED_SUPPORT",
                },
              ]}
            />
            <RemoteInputSelect.Form
              control={form.control}
              label="Cap table management solution"
              name="ctmsSolution"
              options={[
                {
                  label: "We use spreadsheets (Excel/Gsheet)",
                  value: "SPREADSHEETS",
                },
                {
                  label: "I don't have one",
                  value: "NO_CTMS",
                },
                {
                  label: "Carta",
                  value: "CARTA",
                },
                {
                  label: "Pulley",
                  value: "PULLEY",
                },
                {
                  label: "Shareworks",
                  value: "SHAREWORKS",
                },
                {
                  label: "AngelList",
                  value: "ANGELLIST",
                },
                {
                  label: "Ledgy",
                  value: "LEDGY",
                },
                {
                  label: "LTSE Equity",
                  value: "LTSE_EQUITY",
                },
                {
                  label: "Capdesk",
                  value: "CAPDESK",
                },
                {
                  label: "Equify",
                  value: "EQUIFY",
                },
                {
                  label: "Vestd",
                  value: "VESTD",
                },
                {
                  label: "Certent",
                  value: "CERTENT",
                },
                {
                  label: "Global Shares",
                  value: "GLOBAL_SHARES",
                },
                {
                  label: "Astrella",
                  value: "ASTRELLA",
                },
                {
                  label: "Comptrack",
                  value: "COMPTRACK",
                },
                {
                  label: "Other",
                  value: "OTHER",
                },
              ]}
            />
          </div>
          <Button size="lg" type="submit">
            Send contact request
          </Button>
        </form>
      </div>
    </Modal.Raw>
  );
};
