/**
 * @generated SignedSource<<9d51c0226559a2af9ed3312d10b04664>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EquityTypeHandbookEvent = "Exercise" | "Grant" | "Holding" | "Sale" | "Vesting";
export type EquityTypeHandbookSection = "Reporting" | "TaxBenefitsAndWatchouts" | "Taxation";
export type EquityTypeHandbookSectionEventItemCategory = "Employee" | "Employer" | "Issuer" | "PotentialTaxBenefit" | "PotentialWatchout";
import { FragmentRefs } from "relay-runtime";
export type EquityTypeHandbookEdition_EquityTypeHandbook$data = {
  readonly id: string;
  readonly lastModifiedAt: string | null;
  readonly published: boolean;
  readonly publishedAt: string | null;
  readonly sectionEvents: ReadonlyArray<{
    readonly enabled: boolean;
    readonly event: EquityTypeHandbookEvent;
    readonly items: ReadonlyArray<{
      readonly category: EquityTypeHandbookSectionEventItemCategory;
      readonly content: string;
      readonly enabled: boolean;
    }>;
    readonly nothingToDeclare: boolean;
    readonly section: EquityTypeHandbookSection;
  }>;
  readonly " $fragmentType": "EquityTypeHandbookEdition_EquityTypeHandbook";
};
export type EquityTypeHandbookEdition_EquityTypeHandbook$key = {
  readonly " $data"?: EquityTypeHandbookEdition_EquityTypeHandbook$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquityTypeHandbookEdition_EquityTypeHandbook">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "enabled",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquityTypeHandbookEdition_EquityTypeHandbook",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publishedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "published",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastModifiedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityTypeHandbookSectionEvent",
      "kind": "LinkedField",
      "name": "sectionEvents",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "section",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "event",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nothingToDeclare",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EquityTypeHandbookSectionEventItem",
          "kind": "LinkedField",
          "name": "items",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "category",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "content",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EquityTypeHandbook",
  "abstractKey": null
};
})();

(node as any).hash = "75a40372f4793680e67b10ce063b4f96";

export default node;
