/**
 * @generated SignedSource<<4fa67fcbc276489552141a2e070e43d9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VestingScheduleSection_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EmployeeVestingGraph_Organization">;
  readonly " $fragmentType": "VestingScheduleSection_Organization";
};
export type VestingScheduleSection_Organization$key = {
  readonly " $data"?: VestingScheduleSection_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"VestingScheduleSection_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VestingScheduleSection_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmployeeVestingGraph_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "7503ab97ee8e9be7c0415ae5f076ee32";

export default node;
