/**
 * @generated SignedSource<<8406d8b48c2dfcaa6e0280c3e979b17a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Exercises_Organization$data = {
  readonly ctmsExerciseRequestsAreOutdated: boolean;
  readonly id: string;
  readonly isConnectedToCTMS: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"ExercisesView_Organization">;
  readonly " $fragmentType": "Exercises_Organization";
};
export type Exercises_Organization$key = {
  readonly " $data"?: Exercises_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"Exercises_Organization">;
};

import Exercises_Organization_RefetchQuery_graphql from './Exercises_Organization_RefetchQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": Exercises_Organization_RefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "Exercises_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctmsExerciseRequestsAreOutdated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isConnectedToCTMS",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExercisesView_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "d371a4ee6077dc05d9d98d4be1289abb";

export default node;
