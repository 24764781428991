/**
 * @generated SignedSource<<ea941a4c851e7f458dc8b1c24f8ef13a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DraftGrantVestingPreview_Organization$data = {
  readonly id: string;
  readonly " $fragmentType": "DraftGrantVestingPreview_Organization";
};
export type DraftGrantVestingPreview_Organization$key = {
  readonly " $data"?: DraftGrantVestingPreview_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"DraftGrantVestingPreview_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DraftGrantVestingPreview_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "16f045c25e74c8ae8c7ae625233084f1";

export default node;
