import { graphql, useFragment } from "react-relay";

import { FormattedFMV } from "../../../../components/FormattedFMV";
import { ShortDate } from "../../../../components/ShortDate";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { Typography } from "../../../../components/ui/Typography";
import { ApprovalSlideOverFairMarketValueDetailsCard_FairMarketValue$key } from "./__generated__/ApprovalSlideOverFairMarketValueDetailsCard_FairMarketValue.graphql";
import { ApprovalSlideOverFairMarketValueDetailsCard_Organization$key } from "./__generated__/ApprovalSlideOverFairMarketValueDetailsCard_Organization.graphql";
import { ApprovalSlideOverGrantsCard } from "./ApprovalSlideOverGrantsCard";

const ORGANIZATION_FRAGMENT = graphql`
  fragment ApprovalSlideOverFairMarketValueDetailsCard_Organization on Organization {
    ...FormattedFMV_Organization
  }
`;

const FAIR_MARKET_VALUE_FRAGMENT = graphql`
  fragment ApprovalSlideOverFairMarketValueDetailsCard_FairMarketValue on FairMarketValue {
    date
    value
    valuationFirm
  }
`;

export const ApprovalSlideOverFairMarketValueDetailsCard: React.FC<{
  fairMarketValueFragment: ApprovalSlideOverFairMarketValueDetailsCard_FairMarketValue$key;
  organizationFragment: ApprovalSlideOverFairMarketValueDetailsCard_Organization$key;
}> = ({ fairMarketValueFragment, organizationFragment }) => {
  const fairMarketValue = useFragment(
    FAIR_MARKET_VALUE_FRAGMENT,
    fairMarketValueFragment,
  );
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  return (
    <RoundedBox withBorder withShadow>
      <div className="space-y-0.5 px-4 py-3">
        <Typography as="div" className="text-primary" variant="Medium/Small">
          Approval Breakdown
        </Typography>
      </div>

      <ApprovalSlideOverGrantsCard.GrantCardSectionTitle>
        409A Valuation detail
      </ApprovalSlideOverGrantsCard.GrantCardSectionTitle>

      <div className="grid grid-cols-2 sm:grid-cols-3">
        <ApprovalSlideOverGrantsCard.GrantData title="Valuation date">
          <ShortDate value={fairMarketValue.date} />
        </ApprovalSlideOverGrantsCard.GrantData>
        <ApprovalSlideOverGrantsCard.GrantData title="Valuation firm">
          {fairMarketValue.valuationFirm}
        </ApprovalSlideOverGrantsCard.GrantData>
        <ApprovalSlideOverGrantsCard.GrantData
          legend="one share of common stock"
          title="Fair Market Value"
        >
          <FormattedFMV
            organizationFragment={organization}
            value={fairMarketValue.value}
          />
        </ApprovalSlideOverGrantsCard.GrantData>
      </div>
    </RoundedBox>
  );
};
