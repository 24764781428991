import { zodResolver } from "@hookform/resolvers/zod";
import { Pill } from "@remote-com/norma";
import { isEmpty } from "lodash";
import { startTransition, Suspense, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { useToaster } from "../../../../components/Toaster";
import { Button } from "../../../../components/ui/Button";
import { Checkbox } from "../../../../components/ui/Form/Checkbox";
import { FormRow } from "../../../../components/ui/Form/FormRow";
import { SelectAutocomplete } from "../../../../components/ui/Form/Inputs/Select/SelectAutocomplete";
import { NoticeMessage } from "../../../../components/ui/NoticeMessage";
import { Toast } from "../../../../components/ui/Toast";
import { Typography } from "../../../../components/ui/Typography";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import { ChangeGrantInstrumentForm_ChangeGrantInstrument_Mutation } from "./__generated__/ChangeGrantInstrumentForm_ChangeGrantInstrument_Mutation.graphql";
import { ChangeGrantInstrumentForm_EasopGrant_Query } from "./__generated__/ChangeGrantInstrumentForm_EasopGrant_Query.graphql";
import {
  ChangeGrantInstrumentForm_Grants_Query,
  ChangeGrantInstrumentForm_Grants_Query$data,
} from "./__generated__/ChangeGrantInstrumentForm_Grants_Query.graphql";
import { ChangeGrantInstrumentForm_Instruments_Query } from "./__generated__/ChangeGrantInstrumentForm_Instruments_Query.graphql";
import {
  ChangeGrantInstrumentForm_Organization$data,
  ChangeGrantInstrumentForm_Organization$key,
} from "./__generated__/ChangeGrantInstrumentForm_Organization.graphql";

const CHANGE_GRANT_INSTRUMENT_MUTATION = graphql`
  mutation ChangeGrantInstrumentForm_ChangeGrantInstrument_Mutation(
    $ctmsGrantId: CtmsGrantId!
    $instrumentId: UUID!
    $generateNewDocuments: Boolean!
  ) {
    changeGrantInstrument(
      ctmsGrantId: $ctmsGrantId
      generateNewDocuments: $generateNewDocuments
      instrumentId: $instrumentId
    ) {
      matchingInstrument {
        name
      }
    }
  }
`;

const schema = z.object({
  ctmsGrantId: z.string(),
  generateNewDocuments: z.boolean(),
  instrumentId: z.string(),
  organizationId: z.string(),
});

type FormInputs = z.infer<typeof schema>;

const useChangeGrantInstrumentForm = () => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      ctmsGrantId: null,
      generateNewDocuments: false,
      instrumentId: null,
      organizationId: null,
    },
    resolver: zodResolver(schema),
  });

  return { control, errors, handleSubmit, setValue, watch };
};

const GRANT_QUERY = graphql`
  query ChangeGrantInstrumentForm_EasopGrant_Query($ctmsGrantId: CtmsGrantId!) {
    ctmsGrant(id: $ctmsGrantId) @required(action: THROW) {
      grantee {
        taxResidenceCountry {
          code
        }
        workRelationship
      }
      matchingInstrument {
        id
      }
    }
  }
`;

const INSTRUMENTS_QUERY = graphql`
  query ChangeGrantInstrumentForm_Instruments_Query(
    $countryCode: String
    $workRelationship: WorkRelationship
  ) {
    instruments(
      countryCode: $countryCode
      workRelationship: $workRelationship
    ) {
      name
      id
    }
  }
`;

const SelectInstrumentInput: React.FC<{
  control: ReturnType<typeof useChangeGrantInstrumentForm>["control"];
  ctmsGrantId: string;
}> = ({ control, ctmsGrantId }) => {
  const { ctmsGrant } =
    useLazyLoadQuery<ChangeGrantInstrumentForm_EasopGrant_Query>(GRANT_QUERY, {
      ctmsGrantId,
    });

  const { instruments } =
    useLazyLoadQuery<ChangeGrantInstrumentForm_Instruments_Query>(
      INSTRUMENTS_QUERY,
      {
        countryCode: ctmsGrant.grantee.taxResidenceCountry?.code,
        workRelationship: ctmsGrant.grantee.workRelationship,
      },
    );

  const newInstruments = useMemo(
    () =>
      instruments.filter(
        (instrument) =>
          !ctmsGrant.matchingInstrument ||
          instrument.id !== ctmsGrant.matchingInstrument.id,
      ),
    [ctmsGrant, instruments],
  );

  if (isEmpty(newInstruments))
    return (
      <NoticeMessage size="Small" variant="Warning">
        No other instrument seem to be matching the grantee situation.
      </NoticeMessage>
    );

  return (
    <Controller
      control={control}
      name="instrumentId"
      render={({ field, fieldState }) => (
        <FormRow
          error={fieldState.error?.message}
          label="Select a new instrument"
          subLabel="Based on grantee data, the grant can be linked to these instruments"
        >
          <SelectAutocomplete
            getOptionLabel={(instrument) => instrument.name}
            getOptionValue={(instrument) => instrument.id}
            onChange={(instrument) => {
              field.onChange(instrument?.id);
            }}
            options={newInstruments}
            value={newInstruments.find(
              (instrument) => field.value === instrument.id,
            )}
          />
        </FormRow>
      )}
    />
  );
};

const GRANTS_QUERY = graphql`
  query ChangeGrantInstrumentForm_Grants_Query(
    $organizationId: OrganizationId!
  ) {
    organization(id: $organizationId) @required(action: THROW) {
      ctmsGrants(filters: { statusIn: [Active, Terminated] }) {
        edges {
          node {
            id
            label
            equityTypeAwardName
            matchingInstrument {
              name
            }
            grantee {
              id
              name
              taxResidenceCountry {
                emoji
              }
            }
            easopGrant {
              __typename
            }
          }
        }
      }
    }
  }
`;

function OptionGrantLabel({
  grant,
}: {
  grant: NonNullable<
    ChangeGrantInstrumentForm_Grants_Query$data["organization"]["ctmsGrants"]["edges"][number]["node"]
  >;
}) {
  const label = useMemo(() => {
    return `${grant.label} - ${
      grant.grantee.taxResidenceCountry?.emoji ?? ""
    } ${grant.grantee.name} - ${grant.matchingInstrument?.name ?? grant.equityTypeAwardName ?? ""}`;
  }, [grant]);

  return (
    <div className="flex items-center gap-2">
      <Typography as="div" className="flex-grow" variant="Regular/Extra Small">
        {label}
      </Typography>
      {!grant.easopGrant && (
        <Pill tone="neutralLight" type="subtle">
          Legacy
        </Pill>
      )}
      {!grant.matchingInstrument && (
        <Pill tone="error" type="subtle">
          No instrument
        </Pill>
      )}
    </div>
  );
}

const ORGANIZATIONS_FRAGMENT = graphql`
  fragment ChangeGrantInstrumentForm_Organization on Organization
  @relay(plural: true) {
    name
    id
  }
`;

const SelectNewInstrumentForm: React.FC<{
  control: ReturnType<typeof useChangeGrantInstrumentForm>["control"];
  organization: ChangeGrantInstrumentForm_Organization$data[number];
  setValue: ReturnType<typeof useChangeGrantInstrumentForm>["setValue"];
  watch: ReturnType<typeof useChangeGrantInstrumentForm>["watch"];
}> = ({ control, organization, setValue, watch }) => {
  const {
    organization: { ctmsGrants: _ctmsGrants },
  } = useLazyLoadQuery<ChangeGrantInstrumentForm_Grants_Query>(GRANTS_QUERY, {
    organizationId: organization.id,
  });

  const ctmsGrants = useMemo(
    () => _ctmsGrants.edges.map(({ node }) => node),
    [_ctmsGrants],
  );

  const [instrumentId, generateNewDocuments] = watch([
    "instrumentId",
    "generateNewDocuments",
  ]);

  const [ctmsGrantId, setCtmsGrantId] = useState<null | string>(null);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      switch (name) {
        case "ctmsGrantId": {
          startTransition(() => {
            setCtmsGrantId(value.ctmsGrantId ?? null);
          });
          break;
        }
        default:
          break;
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const selectedGrant = useMemo(
    () => ctmsGrants.find((grant) => grant.id === ctmsGrantId),
    [ctmsGrantId, ctmsGrants],
  );

  const showGenerateNewDocuments = useMemo(
    () => selectedGrant?.easopGrant,
    [selectedGrant],
  );

  return (
    <>
      <Controller
        control={control}
        name="ctmsGrantId"
        render={({ field, fieldState }) => (
          <FormRow error={fieldState.error?.message} label="Select a grant">
            <SelectAutocomplete
              formatOptionLabel={(grant) => <OptionGrantLabel grant={grant} />}
              getOptionLabel={(grant) => grant.label}
              getOptionValue={(grant) => grant.id}
              onChange={(grant) => {
                field.onChange(grant?.id);
              }}
              options={ctmsGrants}
              value={ctmsGrants.find((grant) => field.value === grant.id)}
            />
          </FormRow>
        )}
      />
      {ctmsGrantId && (
        <Suspense>
          <SelectInstrumentInput control={control} ctmsGrantId={ctmsGrantId} />
        </Suspense>
      )}
      {instrumentId && (
        <>
          {showGenerateNewDocuments && (
            <div className="flex items-center gap-2">
              <Checkbox
                checked={generateNewDocuments}
                className="!h-4 !w-4"
                onChange={(event) =>
                  setValue("generateNewDocuments", event.currentTarget.checked)
                }
              />
              <Typography variant="Regular/Extra Small">
                I want a new set of documents to be generated and replace the
                previous one
              </Typography>
            </div>
          )}
          <NoticeMessage size="Small" variant="Warning">
            By clicking on the button I know:
            <ul className="list-outside list-disc pl-4">
              <li>
                the grant instrument is going to be changed, meaning the grantee
                portal is going to change too
              </li>
              {showGenerateNewDocuments && (
                <li>
                  a new set of documents is going to be generated and replace
                  the previous one in the app
                </li>
              )}
            </ul>
          </NoticeMessage>
        </>
      )}
    </>
  );
};

export const ChangeGrantInstrumentForm: React.FC<{
  organizationsFragment: ChangeGrantInstrumentForm_Organization$key;
}> = ({ organizationsFragment }) => {
  const organizations = useFragment(
    ORGANIZATIONS_FRAGMENT,
    organizationsFragment,
  );

  const [changeGrantInstrument, changeGrantInstrumentMutationIsInFlight] =
    useSafeMutation<ChangeGrantInstrumentForm_ChangeGrantInstrument_Mutation>(
      CHANGE_GRANT_INSTRUMENT_MUTATION,
    );

  const { control, handleSubmit, setValue, watch } =
    useChangeGrantInstrumentForm();

  const toaster = useToaster();

  const [ctmsGrantId, instrumentId] = watch(["ctmsGrantId", "instrumentId"]);
  const [organizationId, setOrganizationId] = useState<null | string>(null);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      switch (name) {
        case "organizationId": {
          startTransition(() => {
            setOrganizationId(value.organizationId ?? null);
          });
          break;
        }
        default:
          break;
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (organizationId) {
      setValue("ctmsGrantId", null);
      setValue("instrumentId", null);
      setValue("generateNewDocuments", false);
    }
  }, [organizationId, setValue]);

  useEffect(() => {
    if (ctmsGrantId) {
      setValue("instrumentId", null);
      setValue("generateNewDocuments", false);
    }
  }, [ctmsGrantId, setValue]);

  const onSubmit = handleSubmit(async (_formInputs: unknown) => {
    const formInputs = _formInputs as FormInputs;

    await changeGrantInstrument({
      variables: {
        ctmsGrantId: formInputs.ctmsGrantId,
        generateNewDocuments: formInputs.generateNewDocuments,
        instrumentId: formInputs.instrumentId,
      },
    });

    toaster.push(
      <Toast title="Alright!">
        Grant instrument has been modified.
        {formInputs.generateNewDocuments && (
          <> New documents are being generated.</>
        )}
      </Toast>,
    );
  });

  const organization = useMemo(
    () =>
      organizations.find((organization) => organization.id === organizationId),
    [organizationId, organizations],
  );

  return (
    <form className="m-auto max-w-[420px] space-y-6" onSubmit={onSubmit}>
      <Controller
        control={control}
        name="organizationId"
        render={({ field, fieldState }) => (
          <FormRow
            error={fieldState.error?.message}
            label="Select an organization"
          >
            <SelectAutocomplete
              getOptionLabel={(organization) => organization.name}
              getOptionValue={(organization) => organization.id}
              onChange={(organization) => {
                field.onChange(organization?.id);
              }}
              options={organizations}
              value={organizations.find(
                (organization) => field.value === organization.id,
              )}
            />
          </FormRow>
        )}
      />
      {organization && (
        <Suspense>
          <SelectNewInstrumentForm
            control={control}
            organization={organization}
            setValue={setValue}
            watch={watch}
          />
        </Suspense>
      )}
      {instrumentId && (
        <div className="flex justify-end">
          <Button
            loading={changeGrantInstrumentMutationIsInFlight}
            type="submit"
          >
            I understand, let&apos;s do it!
          </Button>
        </div>
      )}
    </form>
  );
};
