/**
 * @generated SignedSource<<7935e44f0cd98f4fbc12439834f109ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompanyDetailsPage_Viewer$data = {
  readonly isSuperAdmin: boolean;
  readonly " $fragmentType": "CompanyDetailsPage_Viewer";
};
export type CompanyDetailsPage_Viewer$key = {
  readonly " $data"?: CompanyDetailsPage_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompanyDetailsPage_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CompanyDetailsPage_Viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSuperAdmin",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "26f09829617e4683ba16145df21f4ec5";

export default node;
