/**
 * @generated SignedSource<<872c13a45d43695567fe2246e01d3983>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Projection_Planning_Query$variables = {
  organizationId: string;
};
export type Projection_Planning_Query$data = {
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"PlanningChartView_Organization">;
  };
};
export type Projection_Planning_Query = {
  response: Projection_Planning_Query$data;
  variables: Projection_Planning_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Projection_Planning_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PlanningChartView_Organization"
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Projection_Planning_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "availableSharesForPlanningNow",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "planningReservedShares",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "planningReturningShares",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "planningSharesLeft",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "planningEntries",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "planningNewHiresTotalShares",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "planningTenureGrantsTotalShares",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "planningPromotionGrantsTotalShares",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "planningExceptionalGrantsTotalShares",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "planningTerminationsTotalShares",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PricePerShare",
            "kind": "LinkedField",
            "name": "latestPricePerShare",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullyDilutedShares",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "defaultCurrencyCode",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b58c7006f52342855d59b185a0efef6b",
    "id": null,
    "metadata": {},
    "name": "Projection_Planning_Query",
    "operationKind": "query",
    "text": "query Projection_Planning_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    name\n    id\n    ...PlanningChartView_Organization\n  }\n}\n\nfragment FormattedCurrencyParts_Organization on Organization {\n  defaultCurrencyCode\n}\n\nfragment PlanningChartView_Organization on Organization {\n  availableSharesForPlanningNow\n  planningReservedShares\n  planningReturningShares\n  planningSharesLeft\n  planningEntries {\n    __typename\n    id\n  }\n  ...PlanningChart_Organization\n}\n\nfragment PlanningChart_Organization on Organization {\n  availableSharesForPlanningNow\n  planningNewHiresTotalShares\n  planningTenureGrantsTotalShares\n  planningPromotionGrantsTotalShares\n  planningExceptionalGrantsTotalShares\n  planningTerminationsTotalShares\n  planningSharesLeft\n  ...SharesValue_Organization\n}\n\nfragment SharesValue_Organization on Organization {\n  ...useOrganizationSharesUtil_Organization\n  ...FormattedCurrencyParts_Organization\n}\n\nfragment useOrganizationSharesUtil_Organization on Organization {\n  latestPricePerShare {\n    value\n    id\n  }\n  fullyDilutedShares\n}\n"
  }
};
})();

(node as any).hash = "f74ce619df05d4c2845c615b7c947619";

export default node;
