/**
 * @generated SignedSource<<b178d37714a74f7c2572ae443d5a088d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AccelerationClause = "DoubleTrigger100" | "DoubleTrigger25" | "DoubleTrigger35" | "DoubleTrigger50" | "SingleTrigger";
export type CTMSGrantTaxation = "UPON_LIQUIDITY_EVENT" | "UPON_VESTING";
export type DurationUnit = "day" | "month" | "year";
import { FragmentRefs } from "relay-runtime";
export type GrantDetailsInformationSection_CTMSGrant$data = {
  readonly accelerationClause: AccelerationClause | null;
  readonly activePostTerminationExercisePeriod: {
    readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantActivePTEPValue_ActivePostTerminationExercisePeriod">;
  } | null;
  readonly boardApprovalDate: string | null;
  readonly equityTypeAwardName: string | null;
  readonly isVirtual: boolean;
  readonly matchingInstrument: {
    readonly equityType: {
      readonly exercisable: boolean;
    } | null;
  } | null;
  readonly postTerminationExercisePeriod: {
    readonly __typename: "OrganizationVariablePostTerminationExercisePeriod";
    readonly extensionDuration: number;
    readonly extensionDurationUnit: DurationUnit;
    readonly thresholdForExtensionInYears: number;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
  readonly stakeholderSignatureDate: string | null;
  readonly taxation: CTMSGrantTaxation | null;
  readonly terminationDate: string | null;
  readonly vestingStartDate: string | null;
  readonly " $fragmentType": "GrantDetailsInformationSection_CTMSGrant";
};
export type GrantDetailsInformationSection_CTMSGrant$key = {
  readonly " $data"?: GrantDetailsInformationSection_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantDetailsInformationSection_CTMSGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantDetailsInformationSection_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "equityTypeAwardName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vestingStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stakeholderSignatureDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "boardApprovalDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVirtual",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "terminationDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accelerationClause",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taxation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ActivePostTerminationExercisePeriod",
      "kind": "LinkedField",
      "name": "activePostTerminationExercisePeriod",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CtmsGrantActivePTEPValue_ActivePostTerminationExercisePeriod"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "matchingInstrument",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EquityType",
          "kind": "LinkedField",
          "name": "equityType",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "exercisable",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "postTerminationExercisePeriod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "extensionDuration",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "extensionDurationUnit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "thresholdForExtensionInYears",
              "storageKey": null
            }
          ],
          "type": "OrganizationVariablePostTerminationExercisePeriod",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "b2c7157c7021c056cc14c322952005df";

export default node;
