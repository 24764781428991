import { ArrowUpRightIcon } from "@heroicons/react/24/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Text } from "@remote-com/norma";
import { ArrowRight } from "iconsax-react";
import { useId, useMemo } from "react";
import { Controller, DeepPartial, useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import {
  EssentialsOnboardingTemplate,
  EssentialsOnboardingTemplateFooter,
} from "../EssentialsOnboardingTemplate";
import { IssuingCompanyPage_Organization$key } from "./__generated__/IssuingCompanyPage_Organization.graphql";
import { IssuingCompanyPage_SetEssentialsOrganizationIssuingCompany_Mutation } from "./__generated__/IssuingCompanyPage_SetEssentialsOrganizationIssuingCompany_Mutation.graphql";
import illustration from "./illustration.png";
import {
  EssentialsOnboardingRadioCard,
  EssentialsOnboardingRadioGroup,
} from "./RadioCard";

const ORGANIZATION_FRAGMENT = graphql`
  fragment IssuingCompanyPage_Organization on Organization {
    id
    remoteLegalEntities {
      isDefault
      remoteSlug
      name
      country {
        name
        code
      }
      city
      desiredCurrencyCode
    }
    issuingCompany {
      remoteSlug
    }
  }
`;

const SET_ESSENTIALS_ORGANIZATION_ISSUING_COMPANY_MUTATION = graphql`
  mutation IssuingCompanyPage_SetEssentialsOrganizationIssuingCompany_Mutation(
    $companyCity: String
    $companyCountryCode: String!
    $companyDesiredCurrencyCode: String
    $companyName: String!
    $companyRemoteSlug: String!
    $organizationId: OrganizationId!
  ) {
    setEssentialsOrganizationIssuingCompany(
      companyCountryCode: $companyCountryCode
      companyDesiredCurrencyCode: $companyDesiredCurrencyCode
      companyName: $companyName
      companyRemoteSlug: $companyRemoteSlug
      organizationId: $organizationId
      companyCity: $companyCity
    ) {
      defaultCurrencyCode
      currentRemoteOnboardingStep
      issuingCompany {
        remoteSlug
        name
        city
        country {
          name
          emoji
        }
      }
    }
  }
`;

const FORM_SCHEMA = z.object({
  legalEntityRemoteSlug: z.string(),
});

type FormInput = DeepPartial<z.input<typeof FORM_SCHEMA>>;
type FormOutput = z.output<typeof FORM_SCHEMA>;

const REMOTE_LEGAL_ENTITIES_URL = `https://employ.remote.com/dashboard/company-settings/legal-entities/`;

export default function IssuingCompanyPage({
  onNextStep,
  onPreviousStep,
  organizationFragment,
  previousStepIndex,
}: {
  onNextStep: () => void;
  onPreviousStep: () => void;
  organizationFragment: IssuingCompanyPage_Organization$key;
  previousStepIndex?: number;
}) {
  const formId = useId();
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const organizationDefaultLegalEntity = useMemo(() => {
    return organization.remoteLegalEntities.find(
      (remoteLegalEntity) => remoteLegalEntity.isDefault,
    );
  }, [organization.remoteLegalEntities]);

  const form = useForm<FormInput, unknown, FormOutput>({
    defaultValues: {
      legalEntityRemoteSlug:
        organization.issuingCompany?.remoteSlug ??
        organizationDefaultLegalEntity?.remoteSlug,
    },
    resolver: zodResolver(FORM_SCHEMA),
  });

  const [setEssentialsOrganizationIssuingCompany, mutationIsInFlight] =
    useSafeMutation<IssuingCompanyPage_SetEssentialsOrganizationIssuingCompany_Mutation>(
      SET_ESSENTIALS_ORGANIZATION_ISSUING_COMPANY_MUTATION,
    );

  const handleFormSubmit = form.handleSubmit(async (data) => {
    const remoteLegalEntity = organization.remoteLegalEntities.find(
      (remoteLegalEntity) =>
        remoteLegalEntity.remoteSlug === data.legalEntityRemoteSlug,
    );

    if (!remoteLegalEntity) {
      throw new Error(
        `Remote legal entity not found: ${data.legalEntityRemoteSlug}`,
      );
    }

    await setEssentialsOrganizationIssuingCompany({
      variables: {
        companyCity: remoteLegalEntity.city,
        companyCountryCode: remoteLegalEntity.country.code,
        companyDesiredCurrencyCode: remoteLegalEntity.desiredCurrencyCode,
        companyName: remoteLegalEntity.name,
        companyRemoteSlug: remoteLegalEntity.remoteSlug,
        organizationId: organization.id,
      },
    });

    onNextStep();
  });

  return (
    <EssentialsOnboardingTemplate
      footer={
        <EssentialsOnboardingTemplateFooter
          onPreviousClick={onPreviousStep}
          previousStepIndex={previousStepIndex}
          stepIndex={1}
          stepName="Issuing Company"
          submitButton={
            <Button
              disabled={mutationIsInFlight}
              form={formId}
              IconAfter={ArrowRight}
              isLoading={mutationIsInFlight}
              type="submit"
            >
              Continue
            </Button>
          }
        />
      }
      illustration={illustration}
      subtitle="We’ll set up Remote Equity according to your issuing company."
      title="Select the issuing company"
    >
      <form className="space-y-4" id={formId} onSubmit={handleFormSubmit}>
        <Controller
          control={form.control}
          name="legalEntityRemoteSlug"
          render={({ field }) => (
            <EssentialsOnboardingRadioGroup
              onChange={field.onChange}
              value={field.value}
            >
              {organization.remoteLegalEntities.map((remoteLegalEntity) => (
                <EssentialsOnboardingRadioCard
                  city={remoteLegalEntity.city}
                  country={remoteLegalEntity.country.name}
                  isDefault={remoteLegalEntity.isDefault}
                  key={remoteLegalEntity.remoteSlug}
                  name={remoteLegalEntity.name}
                  remoteSlug={remoteLegalEntity.remoteSlug}
                />
              ))}
            </EssentialsOnboardingRadioGroup>
          )}
        />
        <Text as="div" className="flex gap-2 px-4" variant="xs">
          <div>Missing an entity?</div>
          <a
            className="flex items-center gap-1 font-medium text-brand-600"
            href={REMOTE_LEGAL_ENTITIES_URL}
          >
            <span>Add a new entity in Remote</span>
            <ArrowUpRightIcon className="size-4" />
          </a>
        </Text>
      </form>
    </EssentialsOnboardingTemplate>
  );
}
