import React from "react";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { CountryFlag } from "../../../../components/CountryFlag";
import { CurrencySymbol } from "../../../../components/CurrencySymbol";
import { FormattedCurrency } from "../../../../components/Formatted/FormattedCurrency";
import { FormattedPercentage } from "../../../../components/Formatted/FormattedPercentage";
import { LongDate } from "../../../../components/LongDate";
import { ShortDate } from "../../../../components/ShortDate";
import { NoticeMessage } from "../../../../components/ui/NoticeMessage";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { Tag } from "../../../../components/ui/Tag";
import { Typography } from "../../../../components/ui/Typography";
import { useOrganizationSharesUtil } from "../../../../hooks/useOrganizationSharesUtil";
import { ACCELERATION_CLAUSE_LABELS } from "../../../../services/AccelerationClause";
import { WORK_RELATIONSHIP_TO_LABEL_HELPER } from "../../../../services/workRelationship";
import {
  ApprovalSlideOverGrantsCard_BoardConsent$data,
  ApprovalSlideOverGrantsCard_BoardConsent$key,
} from "./__generated__/ApprovalSlideOverGrantsCard_BoardConsent.graphql";
import { ApprovalSlideOverGrantsCard_GrantAmendmentCard_CTMSGrantAmendmentRequest$key } from "./__generated__/ApprovalSlideOverGrantsCard_GrantAmendmentCard_CTMSGrantAmendmentRequest.graphql";
import {
  ApprovalSlideOverGrantsCard_GrantAmendmentCard_GranteeTerminationCTMSGrantAmendmentRequest$key,
  GranteeTerminationCTMSGrantAmendmentRequestVestingEndOption,
} from "./__generated__/ApprovalSlideOverGrantsCard_GrantAmendmentCard_GranteeTerminationCTMSGrantAmendmentRequest.graphql";
import {
  ApprovalSlideOverGrantsCard_Organization$data,
  ApprovalSlideOverGrantsCard_Organization$key,
} from "./__generated__/ApprovalSlideOverGrantsCard_Organization.graphql";
import { ApprovalGranteeEquityGridLevelTag } from "./ApprovalGranteeEquityGridLevelTag";

const BOARD_CONSENT_FRAGMENT = graphql`
  fragment ApprovalSlideOverGrantsCard_BoardConsent on BoardConsent {
    __typename
    ... on GranteeTerminationGrantAmendmentBoardConsent {
      granteeTerminationCTMSGrantAmendmentRequests {
        id
        ...ApprovalSlideOverGrantsCard_GrantAmendmentCard_GranteeTerminationCTMSGrantAmendmentRequest
      }
    }
    ... on GrantBoardConsent {
      grants {
        id
        instrument {
          awardSuperType
          taxResidenceCountry {
            emoji
            name
            ...CountryFlag_Country
          }
        }
        grantee {
          name
          jobTitle
          workRelationship
          equityGridLevel {
            name
          }
          offGrid
        }
        quantityGranted
        vestingSchedule {
          name
        }
        postTerminationExercisePeriod {
          displayName
        }
        vestingStartDate
        isVirtual
        ...ApprovalGranteeEquityGridLevelTag_EasopGrant
      }
    }
    ... on GrantAmendmentBoardConsent {
      ctmsGrantAmendmentRequests {
        id
        ...ApprovalSlideOverGrantsCard_GrantAmendmentCard_CTMSGrantAmendmentRequest
      }
    }
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment ApprovalSlideOverGrantsCard_Organization on Organization {
    id
    equityGrid {
      activated
    }
    ...useOrganizationSharesUtil_Organization
    ...CurrencySymbol_Organization
    ...FormattedCurrency_Organization
  }
`;

const GrantData: React.FC<{
  children: React.ReactNode;
  legend?: React.ReactNode;
  title: React.ReactNode;
}> = ({ children, legend, title }) => (
  <div className="flex flex-col gap-1 p-2 sm:p-4">
    <Typography as="div" className="text-gray-09" variant="Regular/Extra Small">
      {title}
    </Typography>
    <div>
      <Typography as="div" variant="Regular/Small">
        {children}
      </Typography>
      {legend && (
        <Typography
          as="div"
          className="text-gray-08"
          variant="Regular/Extra Small"
        >
          {legend}
        </Typography>
      )}
    </div>
  </div>
);

const GrantCardSectionTitle: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <Typography
    as="div"
    className="bg-gray-02 px-4 py-1 text-gray-09"
    variant="Regular/Caption"
  >
    {children}
  </Typography>
);

const GRANTEE_TERMINATION_GRANT_AMENDMENT_CARD_GRANT_AMENDMENT_REQUEST_FRAGMENT = graphql`
  fragment ApprovalSlideOverGrantsCard_GrantAmendmentCard_GranteeTerminationCTMSGrantAmendmentRequest on GranteeTerminationCTMSGrantAmendmentRequest {
    id
    postTerminationExercisePeriod {
      displayName
    }
    vestingEndCustomDate
    waiveCliff
    vestingEndOption
    grant {
      label
      grantee {
        name
      }
      terminationDate
    }
  }
`;

const VESTING_END_OPTION_TO_LABEL_MAP: Record<
  GranteeTerminationCTMSGrantAmendmentRequestVestingEndOption,
  string
> = {
  CUSTOM_DATE: "Custom date",
  LAST_DAY_AT_THE_COMPANY: "Last day at the company",
  TERMINATION_DATE: "Termination date",
};

const BOOLEAN_TO_YES_NO_MAPPER = (boolean: boolean) => {
  switch (boolean) {
    case false:
      return "No";
    case true:
      return "Yes";
  }
};

const GranteeTerminationGrantAmendmentCard: React.FC<{
  grantAmendmentRequestFragment: ApprovalSlideOverGrantsCard_GrantAmendmentCard_GranteeTerminationCTMSGrantAmendmentRequest$key;
}> = ({ grantAmendmentRequestFragment }) => {
  const grantAmendmentRequest = useFragment(
    GRANTEE_TERMINATION_GRANT_AMENDMENT_CARD_GRANT_AMENDMENT_REQUEST_FRAGMENT,
    grantAmendmentRequestFragment,
  );

  return (
    <RoundedBox withBorder withShadow>
      <div className="space-y-0.5 px-4 py-3">
        <Typography as="div" className="text-primary" variant="Medium/Small">
          {grantAmendmentRequest.grant.label}
        </Typography>
        <Typography as="div" className="text-black-05" variant="Regular/Small">
          {grantAmendmentRequest.grant.grantee.name}
        </Typography>
        {grantAmendmentRequest.grant.terminationDate && (
          <Typography
            as="div"
            className="text-gray-09"
            variant="Regular/Extra Small"
          >
            Terminated on{" "}
            <LongDate value={grantAmendmentRequest.grant.terminationDate} />
          </Typography>
        )}
      </div>

      <GrantCardSectionTitle>Amendment details</GrantCardSectionTitle>

      <div className="grid grid-cols-2 sm:grid-cols-3">
        <GrantData title="PTEP">
          <Typography as="div" variant="Regular/Small">
            {grantAmendmentRequest.postTerminationExercisePeriod?.displayName ??
              "-"}
          </Typography>
        </GrantData>
        <GrantData title="Vesting ends on">
          <Typography as="div" variant="Regular/Small">
            {grantAmendmentRequest.vestingEndOption
              ? VESTING_END_OPTION_TO_LABEL_MAP[
                  grantAmendmentRequest.vestingEndOption
                ]
              : "-"}
          </Typography>
          {grantAmendmentRequest.vestingEndCustomDate &&
            grantAmendmentRequest.vestingEndOption === "CUSTOM_DATE" && (
              <Typography as="div" variant="Medium/Small">
                <ShortDate value={grantAmendmentRequest.vestingEndCustomDate} />
              </Typography>
            )}
        </GrantData>
        <GrantData title="Waive cliff">
          <Typography as="div" variant="Regular/Small">
            {typeof grantAmendmentRequest.waiveCliff === "boolean"
              ? BOOLEAN_TO_YES_NO_MAPPER(grantAmendmentRequest.waiveCliff)
              : "-"}
          </Typography>
        </GrantData>
      </div>
    </RoundedBox>
  );
};

const GRANT_AMENDMENT_CARD_GRANT_AMENDMENT_REQUEST_FRAGMENT = graphql`
  fragment ApprovalSlideOverGrantsCard_GrantAmendmentCard_CTMSGrantAmendmentRequest on CTMSGrantAmendmentRequest {
    id
    postTerminationExercisePeriodIsModified
    postTerminationExercisePeriod {
      displayName
    }
    accelerationClauseIsModified
    accelerationClause
    earlyExerciseIsModified
    earlyExercise
    ctmsGrant {
      label
      grantee {
        name
      }
    }
  }
`;

const NoChangesLabel = () => {
  return (
    <Typography as="div" className="text-gray-09" variant="Regular/Small">
      No changes
    </Typography>
  );
};

const GrantAmendmentCard: React.FC<{
  grantAmendmentRequestFragment: ApprovalSlideOverGrantsCard_GrantAmendmentCard_CTMSGrantAmendmentRequest$key;
}> = ({ grantAmendmentRequestFragment }) => {
  const grantAmendmentRequest = useFragment(
    GRANT_AMENDMENT_CARD_GRANT_AMENDMENT_REQUEST_FRAGMENT,
    grantAmendmentRequestFragment,
  );

  return (
    <RoundedBox withBorder withShadow>
      <div className="space-y-0.5 px-4 py-3">
        <Typography as="div" className="text-primary" variant="Medium/Small">
          {grantAmendmentRequest.ctmsGrant.label}
        </Typography>
        <Typography as="div" className="text-black-05" variant="Regular/Small">
          {grantAmendmentRequest.ctmsGrant.grantee.name}
        </Typography>
      </div>

      <GrantCardSectionTitle>Amendment details</GrantCardSectionTitle>

      <div className="grid grid-cols-2 sm:grid-cols-3">
        <GrantData title="PTEP">
          {grantAmendmentRequest.postTerminationExercisePeriodIsModified ? (
            <Typography as="div" variant="Regular/Small">
              {grantAmendmentRequest.postTerminationExercisePeriod
                ?.displayName ?? "-"}
            </Typography>
          ) : (
            <NoChangesLabel />
          )}
        </GrantData>
        <GrantData title="Acceleration">
          {grantAmendmentRequest.accelerationClauseIsModified ? (
            grantAmendmentRequest.accelerationClause ? (
              <div>
                <Typography as="div" variant="Regular/Small">
                  Allowed
                </Typography>
                <Typography as="div" variant="Medium/Small">
                  {
                    ACCELERATION_CLAUSE_LABELS[
                      grantAmendmentRequest.accelerationClause
                    ]
                  }
                </Typography>
              </div>
            ) : (
              <Typography as="div" variant="Regular/Small">
                Not allowed
              </Typography>
            )
          ) : (
            <NoChangesLabel />
          )}
        </GrantData>
        <GrantData title="Early exercise">
          {grantAmendmentRequest.earlyExerciseIsModified ? (
            grantAmendmentRequest.earlyExercise ? (
              <Typography as="div" variant="Regular/Small">
                Allowed
              </Typography>
            ) : (
              <Typography as="div" variant="Regular/Small">
                Not allowed
              </Typography>
            )
          ) : (
            <NoChangesLabel />
          )}
        </GrantData>
      </div>
    </RoundedBox>
  );
};

const GrantBoardConsentGrantsList: React.FC<{
  boardConsent: ApprovalSlideOverGrantsCard_BoardConsent$data & {
    __typename: "GrantBoardConsent";
  };
  isCompleted: boolean;
  organization: ApprovalSlideOverGrantsCard_Organization$data;
}> = ({ boardConsent, isCompleted, organization }) => {
  const { sharesToFullyDilutedRatio, sharesToValue } =
    useOrganizationSharesUtil({
      organizationFragment: organization,
    });

  const showEquityGridFeature = organization.equityGrid.activated;
  return (
    <>
      {boardConsent.grants.map((easopGrant, i) => {
        const { grantee } = easopGrant;
        const equityGridLevelName = grantee.offGrid
          ? "Off Grid"
          : grantee.equityGridLevel?.name || null;

        const ownershipGranted = sharesToFullyDilutedRatio(
          easopGrant.quantityGranted,
        );
        const usdValueGranted = sharesToValue(easopGrant.quantityGranted);

        return (
          <RoundedBox key={easopGrant.id} withBorder withShadow>
            <div className="flex flex-col items-start gap-2 px-4 py-3 sm:flex-row sm:items-center sm:gap-4">
              <div className="flex items-center gap-4">
                <Typography
                  className="rounded bg-gray-01 px-2 py-1 text-gray-09"
                  variant="Medium/Caption"
                >
                  {i + 1}/{boardConsent.grants.length}
                </Typography>
                <div className="flex flex-col">
                  <Typography variant="Medium/Small">
                    {easopGrant.grantee.name}
                  </Typography>
                  <div className="flex items-center gap-1">
                    {showEquityGridFeature && equityGridLevelName && (
                      <Tag color="glass-green">{equityGridLevelName}</Tag>
                    )}
                    {easopGrant.grantee.jobTitle && (
                      <Typography
                        className="text-black-05"
                        variant="Regular/Extra Small"
                      >
                        {easopGrant.grantee.jobTitle}
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
              {showEquityGridFeature && !isCompleted && (
                <ApprovalGranteeEquityGridLevelTag
                  className="sm:ml-auto"
                  grantFragment={easopGrant}
                />
              )}
            </div>

            <Typography
              as="div"
              className="bg-gray-02 px-4 py-1 text-gray-09"
              variant="Regular/Caption"
            >
              Grant details
            </Typography>

            <div className="grid grid-cols-2 sm:grid-cols-4">
              {ownershipGranted !== null && (
                <GrantData title="Fully diluted">
                  <FormattedPercentage value={ownershipGranted} />
                </GrantData>
              )}

              {usdValueGranted !== null && (
                <GrantData
                  title={
                    <>
                      <CurrencySymbol organizationFragment={organization} />{" "}
                      Value
                    </>
                  }
                >
                  <FormattedCurrency
                    organizationFragment={organization}
                    value={usdValueGranted}
                  />
                </GrantData>
              )}

              <GrantData
                title={easopGrant.isVirtual ? "# Shares" : "# Options"}
              >
                <FormattedNumber value={easopGrant.quantityGranted} />
              </GrantData>

              <GrantData title="Equity type">
                <CountryFlag
                  countryFragment={easopGrant.instrument.taxResidenceCountry}
                />{" "}
                {easopGrant.instrument.awardSuperType}
              </GrantData>
            </div>

            <Typography
              as="div"
              className="bg-gray-02 px-4 py-1 text-gray-09"
              variant="Regular/Caption"
            >
              Grant timeline
            </Typography>

            <div className="grid grid-cols-2 sm:grid-cols-4">
              <GrantData title="Vesting">
                {easopGrant.vestingSchedule.name}
              </GrantData>

              <GrantData title="Work Rel.">
                {easopGrant.grantee.workRelationship
                  ? WORK_RELATIONSHIP_TO_LABEL_HELPER[
                      easopGrant.grantee.workRelationship
                    ].singularLabel
                  : "-"}
              </GrantData>

              <GrantData title={easopGrant.isVirtual ? "PTSP" : "PTEP"}>
                {easopGrant.postTerminationExercisePeriod.displayName}
              </GrantData>

              <GrantData title="Start">
                <ShortDate value={easopGrant.vestingStartDate} />
              </GrantData>
            </div>

            {easopGrant.isVirtual && (
              <div className="p-4">
                <NoticeMessage size="Small" variant="Warning">
                  The award is a cash bonus payable in case of exit event. It’s
                  an alternative to equity awards due to regulatory obstacles in{" "}
                  {easopGrant.instrument.taxResidenceCountry.emoji}{" "}
                  {easopGrant.instrument.taxResidenceCountry.name}
                </NoticeMessage>
              </div>
            )}
          </RoundedBox>
        );
      })}
    </>
  );
};

const GranteeTerminationGrantAmendmentBoardConsentGrantAmendmentList: React.FC<{
  boardConsent: ApprovalSlideOverGrantsCard_BoardConsent$data & {
    __typename: "GranteeTerminationGrantAmendmentBoardConsent";
  };
}> = ({ boardConsent }) => {
  return (
    <>
      {boardConsent.granteeTerminationCTMSGrantAmendmentRequests.map(
        (granteeTerminationCTMSGrantAmendmentRequest) => (
          <GranteeTerminationGrantAmendmentCard
            grantAmendmentRequestFragment={
              granteeTerminationCTMSGrantAmendmentRequest
            }
            key={granteeTerminationCTMSGrantAmendmentRequest.id}
          />
        ),
      )}
    </>
  );
};

const GrantAmendmentBoardConsentGrantAmendmentList: React.FC<{
  boardConsent: ApprovalSlideOverGrantsCard_BoardConsent$data & {
    __typename: "GrantAmendmentBoardConsent";
  };
}> = ({ boardConsent }) => {
  return (
    <>
      {boardConsent.ctmsGrantAmendmentRequests.map(
        (ctmsGrantAmendmentRequest) => (
          <GrantAmendmentCard
            grantAmendmentRequestFragment={ctmsGrantAmendmentRequest}
            key={ctmsGrantAmendmentRequest.id}
          />
        ),
      )}
    </>
  );
};

const BoardConsentGrantsList: React.FC<{
  boardConsent: ApprovalSlideOverGrantsCard_BoardConsent$data;
  isCompleted: boolean;
  organization: ApprovalSlideOverGrantsCard_Organization$data;
}> = ({ boardConsent, isCompleted, organization }) => {
  switch (boardConsent.__typename) {
    case "%other":
      return null;
    case "GrantAmendmentBoardConsent":
      return (
        <GrantAmendmentBoardConsentGrantAmendmentList
          boardConsent={boardConsent}
        />
      );
    case "GrantBoardConsent":
      return (
        <GrantBoardConsentGrantsList
          boardConsent={boardConsent}
          isCompleted={isCompleted}
          organization={organization}
        />
      );
    case "GranteeTerminationGrantAmendmentBoardConsent":
      return (
        <GranteeTerminationGrantAmendmentBoardConsentGrantAmendmentList
          boardConsent={boardConsent}
        />
      );
  }
};

const ApprovalSlideOverGrantsCard_: React.FC<
  React.ComponentProps<"div"> & {
    boardConsentFragment: ApprovalSlideOverGrantsCard_BoardConsent$key;
    isCompleted: boolean;
    organizationFragment: ApprovalSlideOverGrantsCard_Organization$key;
  }
> = ({ boardConsentFragment, isCompleted, organizationFragment, ...props }) => {
  const boardConsent = useFragment(
    BOARD_CONSENT_FRAGMENT,
    boardConsentFragment,
  );
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <div {...props}>
      <Typography className="mb-6 block" variant="Medium/Small">
        Full breakdown of this board consent
      </Typography>

      <div className="flex flex-col gap-4">
        <BoardConsentGrantsList
          boardConsent={boardConsent}
          isCompleted={isCompleted}
          organization={organization}
        />
      </div>
    </div>
  );
};

export const ApprovalSlideOverGrantsCard = Object.assign(
  ApprovalSlideOverGrantsCard_,
  { GrantCardSectionTitle, GrantData },
);
