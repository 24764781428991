import { InfoBlock, ListItem, Pill } from "@remote-com/norma";
import { compact } from "lodash";
import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { ExerciseStatusTag } from "../../../../components/ExerciseStatusTag";
import { FormattedCurrency } from "../../../../components/Formatted/FormattedCurrency";
import { FormattedNumber } from "../../../../components/Formatted/FormattedNumber";
import { ShortDate } from "../../../../components/ShortDate";
import { Section } from "../../Grantee/GranteeDetails/Section";
import { GrantDetailsExerciseSection_CTMSGrant$key } from "./__generated__/GrantDetailsExerciseSection_CTMSGrant.graphql";
import { GrantDetailsExerciseSection_Organization$key } from "./__generated__/GrantDetailsExerciseSection_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment GrantDetailsExerciseSection_Organization on Organization {
    ...FormattedCurrency_Organization
  }
`;

const CTMS_GRANT_FRAGMENT = graphql`
  fragment GrantDetailsExerciseSection_CTMSGrant on CTMSGrant {
    earlyExercise
    quantityExercised
    exercisableShares
    exercisePrice
    terminationDate
    lastDayToExerciseBasedOnTerminationDate
    ...ExerciseStatusTag_CTMSGrant
  }
`;

export function GrantDetailsExerciseSection({
  className,
  ctmsGrantFragment,
  organizationFragment,
}: {
  className?: string;
  ctmsGrantFragment: GrantDetailsExerciseSection_CTMSGrant$key;
  organizationFragment: GrantDetailsExerciseSection_Organization$key;
}) {
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const infoBlockList = useMemo((): ListItem[] => {
    return compact([
      {
        title: "Early exercise",
        value: ctmsGrant.earlyExercise ? (
          <Pill tone="success">Allowed</Pill>
        ) : (
          <Pill tone="error">Not Allowed</Pill>
        ),
      },
      {
        title: "Exercise status",
        value: <ExerciseStatusTag ctmsGrantFragment={ctmsGrant} />,
      },
      {
        title: "Quantity exercised",
        value: (
          <span>
            <FormattedNumber value={ctmsGrant.quantityExercised} /> /{" "}
            <FormattedNumber value={ctmsGrant.exercisableShares} /> shares
          </span>
        ),
      },
      {
        title: "Exercise price (one share)",
        value: ctmsGrant.exercisePrice ? (
          <FormattedCurrency
            organizationFragment={organization}
            value={ctmsGrant.exercisePrice}
          />
        ) : undefined,
      },
      {
        title: "Quantity exercisable",
        value: (
          <span>
            <FormattedNumber value={ctmsGrant.exercisableShares} /> shares
          </span>
        ),
      },
      {
        title: "Exercise price (all exercisable shares)",
        value: ctmsGrant.exercisePrice ? (
          <FormattedCurrency
            organizationFragment={organization}
            value={ctmsGrant.exercisableShares * ctmsGrant.exercisePrice}
          />
        ) : undefined,
      },
      !!ctmsGrant.terminationDate && {
        title: "Termination date",
        value: <ShortDate value={ctmsGrant.terminationDate} />,
      },
      !!ctmsGrant.lastDayToExerciseBasedOnTerminationDate && {
        title: "Last day to exercise",
        value: (
          <ShortDate
            value={ctmsGrant.lastDayToExerciseBasedOnTerminationDate}
          />
        ),
      },
    ]);
  }, [ctmsGrant, organization]);

  return (
    <Section className={className} title="Exercise">
      <InfoBlock list={infoBlockList} />
    </Section>
  );
}
