import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import { Page } from "../../components/Page";
import { FAQQuestion } from "../../components/ui/FAQQuestion";
import { NoticeMessage } from "../../components/ui/NoticeMessage";
import { Typography } from "../../components/ui/Typography";
import { useQuery } from "../../hooks/useQuery";
import {
  GRANTEE_LAYOUT_LINKS,
  GranteePortalSection,
  useGranteeLayoutContext,
} from "../../layouts/GranteeEquityTypesGroupLayout/GranteeEquityTypesGroupLayout";
import { useEquityTypesGroupParam, useGranteeIdParam } from "../../paths";
import NotFoundPage from "../NotFound/NotFound";
import { FAQ_Query } from "./__generated__/FAQ_Query.graphql";

const QUERY = graphql`
  query FAQ_Query($granteeId: GranteeId!) {
    grantee(id: $granteeId) {
      organization {
        id
        name
      }
    }
  }
`;

const LinkToGranteeSection: React.FC<{
  section: GranteePortalSection;
}> = ({ section }) => {
  const granteeId = useGranteeIdParam();
  const link = GRANTEE_LAYOUT_LINKS[section];
  const equityTypesGroup = useEquityTypesGroupParam();
  return (
    <Link
      className="cursor-pointer text-primary"
      to={generatePath(link.route, {
        equityTypesGroup,
        granteeId,
      })}
    >
      {link.label}
    </Link>
  );
};

const FAQ: React.FC = () => (
  <div className="space-y-2">
    <FAQQuestion question="When can I exercise my options?">
      <p>
        As a rule, you can only exercise the options that have already vested.
        Refer to your vesting schedule in{" "}
        <LinkToGranteeSection section="overview" /> to know when and how many
        options you can exercise.
      </p>
      <p>
        A common type of vesting schedule for startups is 4 years, with a 1 year
        cliff and a monthly (or quarterly) vesting, meaning that 25% of the
        options will vest at the end of the 1st year starting from the date on
        which you were granted the options, and the remaining options will vest
        on a monthly (or quarterly) basis throughout the next 3 years, meaning
        that each month you will vest an additional ~2% (75% / 36 months) of
        your options (or ~6% every quarter in case of a quarterly vesting).
      </p>
      <NoticeMessage size="Small" variant="Idea">
        <span className="font-semibold">Example:</span> When you started at the
        company on June 15, 2021 you were granted 20,000 shares with a vesting
        schedule of 4 years, with a 1 year cliff and monthly vesting.
        <ul className="mt-4 list-inside list-disc">
          <li>
            On June 15, 2022, 25% of your options vest: you now have 5,000
            options vested that you can exercise
          </li>
          <li>
            On November 15, 2022, you will have 7,085 options vested since you
            vest 417 new options per month (15,000 remaining options / 36
            months)
          </li>
          <li>On June 15, 2025, 100% of your options will have vested</li>
        </ul>
      </NoticeMessage>
      <p>
        Another emerging type of vesting schedule is the backloaded vesting, in
        which a smaller portion of the total grant becomes vested initially,
        while a larger portion becomes vested as time progresses. In a
        backloaded vesting, you would for instance vest 10% in year 1, then 20%
        in year 2, 30% in year 3 and 40% in year 4.
      </p>
      <p>
        Even if it is not common, there could also be certain periods during
        which you can’t exercise. You can refer to the stock option plan which
        is attached as an annex to the award agreement you’ve signed at the time
        of grant to check if there are restrictions, or simply ask your manager.
      </p>
      <p>
        You could also have been granted the possibility to “early exercise”
        (exercise options that haven’t vested yet). Again, this is not something
        that’s frequently seen.
      </p>
    </FAQQuestion>

    <FAQQuestion question="What will I need to pay when I exercise my options?">
      <p>
        In order to exercise your options, you will have to pay the exercise
        price, also called strike price. The strike price will most often (but
        not always) be the FMV (which stands for “fair market value”) of your
        company’s shares of common stock at the time you were granted the
        options.
      </p>
      <p>
        You can find the exercise price of your options in{" "}
        <LinkToGranteeSection section="exercise" />. You can also find it,
        together with the most relevant parameters of your grant, in the grant
        notice that you’ve signed on your company’s cap table management
        solution at the time you were granted the options.
      </p>
      <p>
        In addition to the strike price, you could also need to pay taxes at the
        time you exercise. In order to know if you’ll need to pay taxes and how
        much, please refer to <LinkToGranteeSection section="reporting" />.
      </p>
    </FAQQuestion>

    <FAQQuestion question="What are the risks if I exercise my options?">
      By exercising your options, you’ll become an effective shareholder of the
      company. As such, your risks are then the same as other shareholders in a
      company: if the company goes bankrupt or, for instance, get sold at a
      price which is lower than the price you’ve paid to exercise your options
      (and the taxes you’ve paid thereon), then any or all of your investment in
      the company would be lost.
    </FAQQuestion>

    <FAQQuestion question="How can I know how much my options are worth today?">
      <p>
        The current potential value of your options can be assed thanks to the
        price per share (PPS). The price per share is determined by reference to
        the highest valuation at which external investors - such as venture
        capital firms or business angels - have invested in your company during
        your company’s latest financing round. It’s basically what your
        company’s potentially worth in the eyes of these investors at a certain
        point in time, divided by the number of shares of the company.
      </p>
      <p>
        Refer to <LinkToGranteeSection section="overview" /> to see what your
        options could be worth, where we’ve multiplied the number of shares you
        are entitled to acquire if you exercise your options by the price per
        share.
      </p>
      <NoticeMessage size="Small" variant="Danger">
        You should pay attention to the fact that the value of your option grant
        is still theoretical at this stage. It may increase or decrease over
        time, according to your company’s future success. In addition, the price
        per share will typically be the value of the shares of preferred stock,
        which is a category of shares usually held by the (external) investors.
        You, as option holder, will normally only be able to acquire shares of
        common stock, which are subject to more restrictions and have less
        rights compared to shares of preferred stock. It’s only in the scenario
        of a successful exit that the value of your shares will equal the value
        of the investors’ shares.
      </NoticeMessage>
    </FAQQuestion>
    <FAQQuestion question="What happens when I leave, will my options be lost?">
      <p>
        If you have not reached the end of the cliff period, you will lose all
        your options.
      </p>
      <p>
        If you have already vested some of your options, you will be able to
        exercise the vested options within a certain period called the
        post-termination exercise period (PTEP), which is traditionally set at 3
        months in US companies. Refer to{" "}
        <LinkToGranteeSection section="exercise" /> to know what’s your own
        PTEP. All options that are not vested yet will be lost.
      </p>
      <NoticeMessage size="Small" variant="Idea">
        <span className="font-semibold">Example:</span> When you started at the
        company, you had been granted 20,000 shares with a PTEP of 3 months.
        When you leave the company on August 15, you have already vested 12,000
        shares. If you haven’t exercised them before, you will be able to do so
        until November 15 (Termination date + 3 months). After this date, you
        won’t be able to exercise the options, which will be lost. The remaining
        8,000 options that had not been vested are lost anyway.
      </NoticeMessage>
    </FAQQuestion>
    <FAQQuestion question="When can I sell my shares?">
      <p>
        You will typically be able to sell your shares if and when a liquidity
        event occurs. Liquidity events can be:
        <ul className="my-4 ml-4 list-disc">
          <li>
            an exit - also called “trade sale” or M&A: a sale of (usually) 100%
            of the shares of a private company to a third party buyer. This is
            the most frequent scenario.{" "}
          </li>
          <li>
            a secondary sale or liquidity program: a sale by an existing
            shareholder of shares in a private company to another shareholder, a
            third party or to the company itself (that does not occur in
            connection with an exit or an IPO).
          </li>
          <li>
            an IPO: time when a privately held company goes public, i.e. the
            shares become listed on a regulated market such as Nasdaq or NYSE.
          </li>
        </ul>
        There is no guarantee that a liquidity event will ever take place.
      </p>
    </FAQQuestion>
    <FAQQuestion question="Should I pay taxes?">
      In most cases, taxation will not occur before you actually exercise your
      options. Refer to <LinkToGranteeSection section="reporting" /> to know if
      and to what extent you’ll need to pay taxes.
    </FAQQuestion>
  </div>
);

const GranteeFAQPage: React.FC = () => {
  const granteeId = useGranteeIdParam();
  const { query } = useQuery<FAQ_Query>(QUERY, {
    granteeId,
  });
  const { isExpertMode } = useGranteeLayoutContext();

  if (!query.grantee) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Grantee view"
      analyticsName="Grantee - FAQ"
      analyticsProperties={{
        expertiseMode: isExpertMode ? "expert" : "beginner",
      }}
      organizationId={query.grantee.organization.id}
      title={`Grantee | ${query.grantee.organization.name} FAQ`}
    >
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <Typography variant="Medium/Large">A few explanations...</Typography>
          <Typography variant="Regular/Small">
            You will often be told about a number of shares, but what does it
            mean, how do you get it, what is the outcome?
          </Typography>
        </div>

        <FAQ />
      </div>
    </Page>
  );
};

export default GranteeFAQPage;
