/**
 * @generated SignedSource<<4578f8b071ca948b7d04d7fc109f0290>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type ExerciseRequestGranteeSection_CTMSGrant$data = {
  readonly grantee: {
    readonly email: string;
    readonly name: string;
    readonly taxResidenceCountry: {
      readonly name: string;
    } | null;
    readonly workRelationship: WorkRelationship | null;
  };
  readonly lastDayToExerciseBasedOnTerminationDate: string | null;
  readonly remainingDaysToExercise: number | null;
  readonly terminationDate: string | null;
  readonly " $fragmentType": "ExerciseRequestGranteeSection_CTMSGrant";
};
export type ExerciseRequestGranteeSection_CTMSGrant$key = {
  readonly " $data"?: ExerciseRequestGranteeSection_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestGranteeSection_CTMSGrant">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExerciseRequestGranteeSection_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "terminationDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastDayToExerciseBasedOnTerminationDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remainingDaysToExercise",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "grantee",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workRelationship",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Country",
          "kind": "LinkedField",
          "name": "taxResidenceCountry",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};
})();

(node as any).hash = "1b6380dcf5926c14fb3af2a73e7ed8f2";

export default node;
