/**
 * @generated SignedSource<<e2d5d11ca18454db2614ddb206da98b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompanyDetailsPage_Query$variables = {
  organizationId: string;
};
export type CompanyDetailsPage_Query$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"CompanyDetailsPage_Viewer">;
  };
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"CompanyDetailsPage_Organization">;
  } | null;
};
export type CompanyDetailsPage_Query = {
  response: CompanyDetailsPage_Query$data;
  variables: CompanyDetailsPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompanyDetailsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CompanyDetailsPage_Organization"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CompanyDetailsPage_Viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompanyDetailsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "IssuingCompany",
            "kind": "LinkedField",
            "name": "issuingCompany",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Country",
                "kind": "LinkedField",
                "name": "country",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "emoji",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GranteesConnection",
            "kind": "LinkedField",
            "name": "grantees",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GranteeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "declaredCTMSSolution",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToCarta",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "defaultCurrencyCode",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isSuperAdmin",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "72faaed43516122487d4984dc9e839ab",
    "id": null,
    "metadata": {},
    "name": "CompanyDetailsPage_Query",
    "operationKind": "query",
    "text": "query CompanyDetailsPage_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    id\n    name\n    ...CompanyDetailsPage_Organization\n  }\n  me {\n    ...CompanyDetailsPage_Viewer\n    id\n  }\n}\n\nfragment CompanyDetailsPage_Organization on Organization {\n  id\n  name\n  issuingCompany {\n    name\n    country {\n      emoji\n      name\n    }\n  }\n  grantees {\n    edges {\n      __typename\n    }\n  }\n  declaredCTMSSolution\n  ...LargeOneColumnLayout_Organization\n  ...DeleteOrganizationButton_Organization\n  ...CurrencySymbol_Organization\n  ...CurrencyUnit_Organization\n}\n\nfragment CompanyDetailsPage_Viewer on Account {\n  isSuperAdmin\n}\n\nfragment CurrencySymbol_Organization on Organization {\n  defaultCurrencyCode\n}\n\nfragment CurrencyUnit_Organization on Organization {\n  defaultCurrencyCode\n}\n\nfragment DeleteOrganizationButton_Organization on Organization {\n  id\n  name\n}\n\nfragment Footer_Organization on Organization {\n  isConnectedToCarta\n}\n\nfragment LargeOneColumnLayout_Organization on Organization {\n  ...Footer_Organization\n}\n"
  }
};
})();

(node as any).hash = "1c4e9decb2e4f215e2b94f859eddc8c8";

export default node;
