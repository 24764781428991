import { IconV2OutlineCheck } from "@remote-com/norma/icons/IconV2OutlineCheck";
import classNames from "classnames";
import React from "react";
import { ElementType } from "react";

interface Icons {
  activeIcon: ElementType;
  icon: ElementType;
}

export function CheckboxCard({
  checked,
  children,
  icons,
  onChange,
}: React.PropsWithChildren<{
  checked: boolean;
  icons?: Icons;

  onChange: (checked: boolean) => void;
}>) {
  return (
    <div
      className={classNames(
        "group flex flex-1 cursor-pointer items-center gap-2 rounded-remote-2xs border-[0.5px] border-grey-600 px-3 py-2 transition-all",
        "bg-white text-SM text-grey-600",
        "data-[checked=true]:bg-brand-50 data-[checked=true]:text-SM/Medium data-[checked=true]:text-brand-600 data-[checked=true]:ring-2 data-[checked=true]:ring-brand-600",
      )}
      data-checked={checked}
      onClick={() => {
        onChange(!checked);
      }}
    >
      <div
        className={classNames(
          "box-border flex h-5 w-5 items-center justify-center rounded-[5px] border-2 transition-all",
          "border-grey-500",
          "group-data-[checked=true]:border-0 group-data-[checked=true]:border-brand-600 group-data-[checked=true]:bg-brand-600",
        )}
      >
        {checked && <IconV2OutlineCheck className="h-4 text-white" />}
      </div>
      <div className="flex flex-1 items-center justify-center gap-2">
        {icons && (
          <>
            {React.createElement(icons.icon, {
              className: "h-4 group-data-[checked]:hidden block",
            })}
            {React.createElement(icons.activeIcon, {
              className: "h-4 group-data-[checked]:block hidden",
            })}
          </>
        )}
        <span>{children}</span>
      </div>
    </div>
  );
}
