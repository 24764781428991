/**
 * @generated SignedSource<<e793c2fa48ea847303ef3c2ff1d777cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type EquityGridLevelAttributes = {
  equityGridId: string;
  equityValue: number;
  name: string;
  role?: string | null;
  yearlySalary?: number | null;
};
export type CreateEquityGridLevelSlideOver_CreateEquityGridLevel_Mutation$variables = {
  attributes: EquityGridLevelAttributes;
};
export type CreateEquityGridLevelSlideOver_CreateEquityGridLevel_Mutation$data = {
  readonly setEquityGridLevel: {
    readonly id: string;
  };
};
export type CreateEquityGridLevelSlideOver_CreateEquityGridLevel_Mutation = {
  response: CreateEquityGridLevelSlideOver_CreateEquityGridLevel_Mutation$data;
  variables: CreateEquityGridLevelSlideOver_CreateEquityGridLevel_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "attributes"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "attributes",
        "variableName": "attributes"
      }
    ],
    "concreteType": "EquityGridLevel",
    "kind": "LinkedField",
    "name": "setEquityGridLevel",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateEquityGridLevelSlideOver_CreateEquityGridLevel_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateEquityGridLevelSlideOver_CreateEquityGridLevel_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1729985185a7807eb468e297362d8209",
    "id": null,
    "metadata": {},
    "name": "CreateEquityGridLevelSlideOver_CreateEquityGridLevel_Mutation",
    "operationKind": "mutation",
    "text": "mutation CreateEquityGridLevelSlideOver_CreateEquityGridLevel_Mutation(\n  $attributes: EquityGridLevelAttributes!\n) {\n  setEquityGridLevel(attributes: $attributes) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "52c58393ea456c6d0113622a7499d9a9";

export default node;
