import { Avatar } from "@remote-com/norma";
import { ReactNode } from "react";
import { graphql, useFragment } from "react-relay";

import { Typography } from "../../../../components/ui/Typography";
import { ApprovalOrganizationBlock_Organization$key } from "./__generated__/ApprovalOrganizationBlock_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment ApprovalOrganizationBlock_Organization on Organization {
    name
  }
`;

export const ApprovalOrganizationBlock: React.FC<{
  children: ReactNode;
  organizationFragment: ApprovalOrganizationBlock_Organization$key;
  title: string;
}> = ({ children, organizationFragment, title }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <div className="px-6 py-10">
      <Typography
        className="mb-6 flex items-center gap-2"
        variant="Medium/Small"
      >
        <Avatar name={organization.name} size="sm" />
        {`${organization.name} ${title}`}
      </Typography>

      {children}
    </div>
  );
};
