import { endOfMonth, formatDate, parse, startOfMonth } from "date-fns";
import React, { useCallback, useMemo, useState } from "react";
import { useFragment } from "react-relay";
import { useSearchParams } from "react-router-dom";
import { graphql } from "relay-runtime";

import { Page } from "../../../components/Page";
import { LargeOneColumnLayout } from "../../../components/ui/Layout/LargeOneColumnLayout";
import { useQuery } from "../../../hooks/useQuery";
import { useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { RSUVestingPage_Organization$key } from "./__generated__/RSUVestingPage_Organization.graphql";
import { RSUVestingPage_Query } from "./__generated__/RSUVestingPage_Query.graphql";
import {
  FilterFormSchema,
  filterFormSchema,
  RSUVestingFilters,
} from "./RSUVestingFilters";
import { RSUVestingTable } from "./RSUVestingTable";

const ORGANIZATION_FRAGMENT = graphql`
  fragment RSUVestingPage_Organization on Organization
  @argumentDefinitions(
    ctmsGrantsFilters: { type: "OrganizationCTMSGrantsFilters" }
    vestingDataPointsFilters: { type: "CTMSGrantVestingDataPointsFilters" }
  ) {
    ...LargeOneColumnLayout_Organization
    ...RSUVestingTable_Organization
    ctmsGrants(filters: $ctmsGrantsFilters) {
      edges {
        node {
          ...RSUVestingTable_CTMSGrant
            @arguments(vestingDataPointsFilters: $vestingDataPointsFilters)
        }
      }
    }
  }
`;

const buildVestingDataPointsFilters = (period: string) => {
  return {
    afterDate: formatDate(
      startOfMonth(parse(period, "yyyy-MM", new Date())),
      "yyyy-MM-dd",
    ),
    beforeDate: formatDate(
      endOfMonth(parse(period, "yyyy-MM", new Date())),
      "yyyy-MM-dd",
    ),
    withNewlyVestedShares: true,
  };
};

const AdminRSUVestingPage_: React.FC<{
  filters: FilterFormSchema;
  organizationFragment: RSUVestingPage_Organization$key;
  setFilters: (filters: FilterFormSchema) => void;
}> = ({ filters, organizationFragment, setFilters }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const ctmsGrants = useMemo(() => {
    return organization.ctmsGrants.edges.map((edge) => edge.node);
  }, [organization.ctmsGrants.edges]);

  return (
    <LargeOneColumnLayout
      isNew
      organizationFragment={organization}
      title="RSU vesting"
    >
      <div className="space-y-6">
        <RSUVestingFilters
          filters={filters}
          onFiltersChange={(filters) => {
            setFilters(filters);
          }}
        />
        <RSUVestingTable
          ctmsGrantsFragment={ctmsGrants}
          organizationFragment={organization}
        />
      </div>
    </LargeOneColumnLayout>
  );
};

const QUERY = graphql`
  query RSUVestingPage_Query(
    $organizationId: OrganizationId!
    $ctmsGrantsFilters: OrganizationCTMSGrantsFilters
    $vestingDataPointsFilters: CTMSGrantVestingDataPointsFilters
  ) {
    organization(id: $organizationId) {
      id
      name
      ...RSUVestingPage_Organization
        @arguments(
          ctmsGrantsFilters: $ctmsGrantsFilters
          vestingDataPointsFilters: $vestingDataPointsFilters
        )
    }
  }
`;

const AdminRSUVestingPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();

  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, _setFilters] = useState<FilterFormSchema>(() => {
    const filters = searchParams.get("filters");

    if (filters) {
      const parsingResult = filterFormSchema.safeParse(
        JSON.parse(decodeURI(atob(filters))),
      );
      if (parsingResult.success) {
        return parsingResult.data;
      }
    }

    return { period: formatDate(new Date(), "yyyy-MM") };
  });

  const setFilters = useCallback(
    (filters: FilterFormSchema) => {
      setSearchParams((params) => ({
        // eslint-disable-next-line @typescript-eslint/no-misused-spread
        ...params,
        filters: btoa(encodeURI(JSON.stringify(filters))),
      }));
      _setFilters(filters);
    },
    [setSearchParams],
  );

  const vestingDataPointsFilters = useMemo(
    () => buildVestingDataPointsFilters(filters.period),
    [filters.period],
  );

  const ctmsGrantsFilters = useMemo(() => {
    return {
      equityTypeIn: ["RSU"],
      search: filters.fullTextFilter,
      statusIn: ["Active"],
    } as const;
  }, [filters.fullTextFilter]);

  const {
    query: { organization },
  } = useQuery<RSUVestingPage_Query>(QUERY, {
    ctmsGrantsFilters,
    organizationId,
    vestingDataPointsFilters,
  });

  if (!organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="RSUVesting"
      analyticsName="Admin - RSU vesting"
      organizationId={organization.id}
      title={`Admin | ${organization.name} RSU vesting`}
    >
      <AdminRSUVestingPage_
        filters={filters}
        organizationFragment={organization}
        setFilters={setFilters}
      />
    </Page>
  );
};

export default AdminRSUVestingPage;
