import _, { compact } from "lodash";
import React, { Suspense, useCallback, useMemo, useState } from "react";
import { useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { BoardMemberApprovalsTables } from "../../../components/BoardMemberApprovalsTables";
import { LoadingPlaceholder } from "../../../components/LoadingPlaceholder";
import { Page } from "../../../components/Page";
import { BreadCrumb } from "../../../components/ui/BreadCrumb";
import { OneColumnLayout } from "../../../components/ui/Layout/OneColumnLayout";
import {
  NoticeMessage,
  useClosableNoticeMessageState,
} from "../../../components/ui/NoticeMessage";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { SlideOver } from "../../../components/ui/SlideOver";
import { Tab } from "../../../components/ui/Tab";
import { Typography } from "../../../components/ui/Typography";
import { useQuery } from "../../../hooks/useQuery";
import { APPLICATION_ROUTES, useBoardMemberIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { Approvals_Organization$key } from "./__generated__/Approvals_Organization.graphql";
import { Approvals_Query } from "./__generated__/Approvals_Query.graphql";
import {
  Approvals_SignatureRequests$data,
  Approvals_SignatureRequests$key,
} from "./__generated__/Approvals_SignatureRequests.graphql";
import { ApprovalSlideOverContent } from "./ApprovalSlideOverContent/ApprovalSlideOverContent";

const SIGNATURE_REQUESTS_FRAGMENT = graphql`
  fragment Approvals_SignatureRequests on SignatureRequest
  @relay(plural: true) {
    id
    createdAt
    completedAt
    justSigned
    completedAt
    ...ApprovalSlideOverContent_SignatureRequest
    ...BoardMemberApprovalsTables_SignatureRequests
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment Approvals_Organization on Organization {
    ...ApprovalSlideOverContent_Organization
    ...BoardMemberApprovalsTables_Organization
    id
    name
  }
`;

const ApprovalsPage_: React.FC<{
  organizationFragment: Approvals_Organization$key;
  signatureRequestsFragment: Approvals_SignatureRequests$key;
}> = ({ organizationFragment, signatureRequestsFragment }) => {
  const boardMemberId = useBoardMemberIdParam();
  const signatureRequests = useFragment(
    SIGNATURE_REQUESTS_FRAGMENT,
    signatureRequestsFragment,
  );

  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const [selectedSignatureRequest, setSelectedSignatureRequest] = useState<
    (typeof signatureRequests)[number] | null
  >(null);

  const onSignatureRequestClick = useCallback(
    (signatureRequestId: string) => {
      setSelectedSignatureRequest(
        signatureRequests.find(
          (signatureRequest) => signatureRequest.id === signatureRequestId,
        ) || null,
      );
    },
    [setSelectedSignatureRequest, signatureRequests],
  );

  const { pendingSignatureRequests, signedSignatureRequests } = useMemo(
    () =>
      _(signatureRequests)
        .orderBy((signatureRequest) => signatureRequest.createdAt, "desc")
        .reduce(
          (
            accumulator: {
              pendingSignatureRequests: Approvals_SignatureRequests$data;
              signedSignatureRequests: Approvals_SignatureRequests$data;
            },
            signatureRequest,
          ) => ({
            pendingSignatureRequests: compact([
              ...accumulator.pendingSignatureRequests,
              signatureRequest.completedAt === null ||
              signatureRequest.justSigned
                ? signatureRequest
                : null,
            ]),
            signedSignatureRequests: compact([
              ...accumulator.signedSignatureRequests,
              signatureRequest.completedAt !== null ? signatureRequest : null,
            ]),
          }),
          { pendingSignatureRequests: [], signedSignatureRequests: [] },
        ),
    [signatureRequests],
  );

  const handleSlideOverClose = useCallback(() => {
    setSelectedSignatureRequest(null);
  }, []);

  const {
    isHidden: isPendingApprovalsMessageHidden,
    onHide: onHidePendingApprovalsMessage,
  } = useClosableNoticeMessageState({
    localStorageClosedKey: "approvals-pending",
  });

  return (
    <OneColumnLayout
      Breadcrumb={
        <BreadCrumb>
          <BreadCrumb.Link
            to={generatePath(APPLICATION_ROUTES.boardMemberOverview, {
              boardMemberId,
            })}
          >
            {organization.name}
          </BreadCrumb.Link>
          <BreadCrumb.Link to=".">Approvals</BreadCrumb.Link>
        </BreadCrumb>
      }
    >
      <SlideOver
        header={
          <SlideOver.Header onClose={handleSlideOverClose}>
            Board Consent approval
          </SlideOver.Header>
        }
        onClose={handleSlideOverClose}
        show={!!selectedSignatureRequest}
      >
        {selectedSignatureRequest && (
          <Suspense fallback={<LoadingPlaceholder />}>
            <ApprovalSlideOverContent
              onCompleted={() => {
                setSelectedSignatureRequest(null);
              }}
              organizationFragment={organization}
              signatureRequestFragment={selectedSignatureRequest}
            />
          </Suspense>
        )}
      </SlideOver>

      <RoundedBox className="space-y-6 p-6" withBorder withShadow>
        <Typography as="div" variant="Medium/Default">
          Approvals
        </Typography>

        <Tab.Group>
          <Tab.List>
            <Tab>Pending</Tab>
            <Tab>Approved</Tab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <Page
                analyticsName="Board - Pending Approvals"
                organizationId={organization.id}
                title={`Board member | ${organization.name} pending approvals board consents`}
              >
                <div className="space-y-4">
                  <NoticeMessage
                    isHidden={isPendingApprovalsMessageHidden}
                    onHide={onHidePendingApprovalsMessage}
                    size="Small"
                  >
                    This list only displays board consents and approvals linked
                    to {organization.name}. Change organization or contact your
                    organization admin to get access to board consents related
                    to other entities you might be part of.
                  </NoticeMessage>
                  {pendingSignatureRequests.length > 0 ? (
                    <BoardMemberApprovalsTables
                      onSignatureRequestClick={onSignatureRequestClick}
                      organizationFragment={organization}
                      signatureRequestsFragment={pendingSignatureRequests}
                    />
                  ) : (
                    <NoticeMessage size="Small">
                      You have no pending approvals
                    </NoticeMessage>
                  )}
                </div>
              </Page>
            </Tab.Panel>
            <Tab.Panel>
              <Page
                analyticsName="Board - Approved Approvals"
                organizationId={organization.id}
                title={`Board member | ${organization.name} approved board consents`}
              >
                <div className="space-y-4">
                  {signedSignatureRequests.length > 0 ? (
                    <BoardMemberApprovalsTables
                      onSignatureRequestClick={onSignatureRequestClick}
                      organizationFragment={organization}
                      signatureRequestsFragment={signedSignatureRequests}
                    />
                  ) : (
                    <NoticeMessage size="Small">
                      You have no signed approvals
                    </NoticeMessage>
                  )}
                </div>
              </Page>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </RoundedBox>
    </OneColumnLayout>
  );
};

const QUERY = graphql`
  query Approvals_Query($boardMemberId: UUID!) {
    boardMember(id: $boardMemberId) {
      organization {
        ...Approvals_Organization
      }
      signatureRequests {
        ...Approvals_SignatureRequests
      }
    }
  }
`;

const ApprovalsPage: React.FC = () => {
  const boardMemberId = useBoardMemberIdParam();
  const { query } = useQuery<Approvals_Query>(QUERY, {
    boardMemberId,
  });

  if (!query.boardMember) {
    return <NotFoundPage />;
  }

  return (
    <ApprovalsPage_
      organizationFragment={query.boardMember.organization}
      signatureRequestsFragment={query.boardMember.signatureRequests}
    />
  );
};

export default ApprovalsPage;
