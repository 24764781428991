export function ColorSquareLegend({
  children,
  color,
}: React.PropsWithChildren<{
  color: string;
}>) {
  return (
    <div className="flex items-center justify-center gap-1 text-XS">
      <div
        className="size-2.5 rounded-[2px]"
        style={{ backgroundColor: color }}
      />
      {children}
    </div>
  );
}
