import { Pill } from "@remote-com/norma";
import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { useGetRandomColor } from "../helpers/getRandomColorPalette";
import { VestingScheduleLegend_CTMSGrants$key } from "./__generated__/VestingScheduleLegend_CTMSGrants.graphql";
import { VestingScheduleLegend_DraftGrants$key } from "./__generated__/VestingScheduleLegend_DraftGrants.graphql";
import { ColorSquareLegend } from "./ColorSquareLegend";
const CTMS_GRANT_FRAGMENT = graphql`
  fragment VestingScheduleLegend_CTMSGrants on CTMSGrant @relay(plural: true) {
    label
  }
`;

const DRAFT_GRANT_FRAGMENT = graphql`
  fragment VestingScheduleLegend_DraftGrants on EasopGrant
  @relay(plural: true) {
    label
  }
`;

export const VestingScheduleLegend: React.FC<{
  ctmsGrantsFragment?: VestingScheduleLegend_CTMSGrants$key;
  draftGrantsFragment?: VestingScheduleLegend_DraftGrants$key;
  randomColorSeed: string;
  vestingScheduleView: "cumulative" | "split";
}> = ({
  ctmsGrantsFragment,
  draftGrantsFragment,
  randomColorSeed,
  vestingScheduleView,
}) => {
  const ctmsGrants = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantsFragment);
  const draftGrants = useFragment(DRAFT_GRANT_FRAGMENT, draftGrantsFragment);

  const allLabels = useMemo(
    () => [
      ...(draftGrants ?? []).map(({ label }) => label),
      ...(ctmsGrants ?? []).map(({ label }) => label),
    ],
    [ctmsGrants, draftGrants],
  );

  const getRandomColor = useGetRandomColor({
    paletteSize: allLabels.length,
    seed: randomColorSeed,
  });

  switch (vestingScheduleView) {
    case "cumulative":
      return <Pill tone="purple">All grants</Pill>;
    case "split":
      return allLabels.map((label, index) => {
        return (
          <ColorSquareLegend color={getRandomColor(index)} key={label}>
            {label}
          </ColorSquareLegend>
        );
      });
  }
};
