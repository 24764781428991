/**
 * @generated SignedSource<<22e198bbf2890c90926dfd6365b6c07b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DocumentsTable_CTMSGrant$data = {
  readonly documents: ReadonlyArray<{
    readonly downloadUrl: string;
    readonly originalFilename: string | null;
    readonly updatedAt: string;
  }>;
  readonly easopGrant: {
    readonly " $fragmentSpreads": FragmentRefs<"useGrantDocuments_EasopGrant">;
  } | null;
  readonly " $fragmentType": "DocumentsTable_CTMSGrant";
};
export type DocumentsTable_CTMSGrant$key = {
  readonly " $data"?: DocumentsTable_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"DocumentsTable_CTMSGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DocumentsTable_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrant",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useGrantDocuments_EasopGrant"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Document",
      "kind": "LinkedField",
      "name": "documents",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "downloadUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "updatedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "originalFilename",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "a2f1ecf6635554d978195c012f956b54";

export default node;
