/**
 * @generated SignedSource<<59118f4dc0e85d35b1d716d38161592c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AwardSuperType = "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
import { FragmentRefs } from "relay-runtime";
export type ExerciseRequestDeclareNew_Organization$data = {
  readonly grantees: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly ctmsGrants: ReadonlyArray<{
          readonly cumulativeVested: number;
          readonly exercisableNonExercisedShares: number;
          readonly exercisePrice: number | null;
          readonly label: string;
          readonly matchingInstrument: {
            readonly awardSuperType: AwardSuperType;
          } | null;
          readonly postTerminationExercisePeriod: {
            readonly displayName: string;
          } | null;
          readonly quantityIssued: number;
          readonly vestingStartDate: string | null;
          readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantVestingTag_CtmsGrant">;
        }>;
        readonly email: string;
        readonly id: string;
        readonly name: string;
        readonly taxResidenceCountry: {
          readonly emoji: string;
        } | null;
      };
    }>;
  };
  readonly id: string;
  readonly latestFairMarketValue: {
    readonly value: number;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"FormattedCurrency_Organization" | "RemoteInputCurrency_Organization">;
  readonly " $fragmentType": "ExerciseRequestDeclareNew_Organization";
};
export type ExerciseRequestDeclareNew_Organization$key = {
  readonly " $data"?: ExerciseRequestDeclareNew_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestDeclareNew_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExerciseRequestDeclareNew_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "FairMarketValue",
      "kind": "LinkedField",
      "name": "latestFairMarketValue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GranteesConnection",
      "kind": "LinkedField",
      "name": "grantees",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GranteeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Grantee",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "email",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Country",
                  "kind": "LinkedField",
                  "name": "taxResidenceCountry",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "emoji",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "grantStatusIn",
                      "value": [
                        "Active",
                        "Terminated"
                      ]
                    }
                  ],
                  "concreteType": "CTMSGrant",
                  "kind": "LinkedField",
                  "name": "ctmsGrants",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "label",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "exercisableNonExercisedShares",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "vestingStartDate",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "exercisePrice",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "quantityIssued",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Instrument",
                      "kind": "LinkedField",
                      "name": "matchingInstrument",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "awardSuperType",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "postTerminationExercisePeriod",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "displayName",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cumulativeVested",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "CtmsGrantVestingTag_CtmsGrant"
                    }
                  ],
                  "storageKey": "ctmsGrants(grantStatusIn:[\"Active\",\"Terminated\"])"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemoteInputCurrency_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedCurrency_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "95a09efaba1802122249f650ad6e7181";

export default node;
