import { useState } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { ExercisesView_Organization$key } from "./__generated__/ExercisesView_Organization.graphql";
import ExercisesFilters from "./ExercisesFilters";
import { ExercisesTable } from "./ExercisesTable";

const ORGANIZATION_FRAGMENT = graphql`
  fragment ExercisesView_Organization on Organization {
    ...ExercisesTable_Organization
    ctmsExerciseRequests {
      ctmsId
      ...ExercisesFilters_Exercises
      ...ExercisesTable_Exercises
    }
    ...ExercisesFilters_Organization
  }
`;

export const ExercisesView: React.FC<{
  exerciseStatus: "Cancelled" | "Done" | "InProgress";
  organizationFragment: ExercisesView_Organization$key;
}> = ({ exerciseStatus, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const [filteredExercisesIds, setFilteredExercisesIds] = useState<string[]>(
    organization.ctmsExerciseRequests.map((exercise) => exercise.ctmsId),
  );

  const filteredExercises = organization.ctmsExerciseRequests.filter(
    (exercise) => filteredExercisesIds.includes(exercise.ctmsId),
  );

  return (
    <div className="space-y-6">
      <ExercisesFilters
        exercisesFragment={organization.ctmsExerciseRequests}
        organizationFragment={organization}
        setFilteredExercisesIds={setFilteredExercisesIds}
      />
      <ExercisesTable
        exercisesFragment={filteredExercises}
        exerciseStatus={exerciseStatus}
        organizationFragment={organization}
      />
    </div>
  );
};
