/**
 * @generated SignedSource<<e91704e8e3557bd9cc2a5b8bd0085ef0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MainSection_EssentialsOverview_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EsopPoolProgressCard_EssentialsOverview_Organization" | "FairMarketValueHistoryCard_EssentialsOverview_Organization" | "FinishSetupCard_EssentialsOverview_Organization" | "SharesOutstandingCard_EssentialsOverview_Organization" | "TakeActionCard_EssentialsOverview_Organization">;
  readonly " $fragmentType": "MainSection_EssentialsOverview_Organization";
};
export type MainSection_EssentialsOverview_Organization$key = {
  readonly " $data"?: MainSection_EssentialsOverview_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"MainSection_EssentialsOverview_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MainSection_EssentialsOverview_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FinishSetupCard_EssentialsOverview_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TakeActionCard_EssentialsOverview_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EsopPoolProgressCard_EssentialsOverview_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharesOutstandingCard_EssentialsOverview_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FairMarketValueHistoryCard_EssentialsOverview_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "2ac6fea623004b58746a2fa494ab3a42";

export default node;
