import { graphql, useFragment } from "react-relay";

import { CtmsGrantsTableRowGranteeCell_Grantee$key } from "./__generated__/CtmsGrantsTableRowGranteeCell_Grantee.graphql";
import { GranteeNameWithCountryFlag } from "./GranteeNameWithCountryFlag";
import { Typography } from "./ui/Typography";

const GRANTEE_FRAGMENT = graphql`
  fragment CtmsGrantsTableRowGranteeCell_Grantee on Grantee {
    email
    ...GranteeNameWithCountryFlag_Grantee
  }
`;

export const CtmsGrantsTableRowGranteeCell: React.FC<{
  granteeFragment: CtmsGrantsTableRowGranteeCell_Grantee$key;
}> = ({ granteeFragment }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  return (
    <div className="flex flex-col items-start justify-center space-y-1">
      <Typography as="div" variant="Regular/Extra Small">
        <GranteeNameWithCountryFlag granteeFragment={grantee} />
      </Typography>
      <Typography
        as="div"
        className="w-full truncate text-black-05"
        variant="Regular/Caption"
      >
        {grantee.email}
      </Typography>
    </div>
  );
};
