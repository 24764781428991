import { FormattedNumberParts } from "react-intl";
import { graphql, useFragment } from "react-relay";

import { CurrencySymbol_Organization$key } from "./__generated__/CurrencySymbol_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment CurrencySymbol_Organization on Organization {
    defaultCurrencyCode
  }
`;

export const CurrencySymbol: React.FC<{
  organizationFragment: CurrencySymbol_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <FormattedNumberParts
      currency={organization.defaultCurrencyCode}
      style="currency"
      value={0}
    >
      {(parts) => (
        <>
          {parts.map((part) => {
            switch (part.type) {
              case "currency":
                return part.value;
              default:
                return null;
            }
          })}
        </>
      )}
    </FormattedNumberParts>
  );
};
