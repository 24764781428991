import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { Page } from "../../../components/Page";
import { CenteredColumnLayout } from "../../../components/ui/Layout/CenteredColumnLayout";
import { LogoWithoutText } from "../../../components/ui/Logo/Logo";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { Typography } from "../../../components/ui/Typography";
import { AnalyticsContextProvider } from "../../../hooks/useAnalytics";
import { useApplicationName } from "../../../hooks/useApplicationTheme";
import { FeatureFlagProvider } from "../../../hooks/useIsFeatureEnabled";
import { useQuery } from "../../../hooks/useQuery";
import {
  useCTMSExerciseRequestCTMSIdParam,
  useOrganizationIdParam,
} from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { ExerciseRequestTaxInformationExport_CTMSExerciseRequest$key } from "./__generated__/ExerciseRequestTaxInformationExport_CTMSExerciseRequest.graphql";
import { ExerciseRequestTaxInformationExport_FairMarketValueAtExercise$key } from "./__generated__/ExerciseRequestTaxInformationExport_FairMarketValueAtExercise.graphql";
import { ExerciseRequestTaxInformationExport_Organization$key } from "./__generated__/ExerciseRequestTaxInformationExport_Organization.graphql";
import { ExerciseRequestTaxInformationExport_Query } from "./__generated__/ExerciseRequestTaxInformationExport_Query.graphql";
import { TaxDetailsContent } from "./TaxDetailsContent";

const EXERCISE_REQUEST_FRAGMENT = graphql`
  fragment ExerciseRequestTaxInformationExport_CTMSExerciseRequest on CTMSExerciseRequest {
    ctmsGrant {
      grantee {
        name
      }
      ...TaxDetailsContent_CTMSGrant
    }
    exercisePrice
    totalExercisePrice
    quantityExercised
    spread
    taxResidenceCountryIsKnown
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment ExerciseRequestTaxInformationExport_Organization on Organization {
    name
    ...TaxDetailsContent_Organization
  }
`;

const FAIR_MARKET_VALUE_AT_EXERCISE_FRAGMENT = graphql`
  fragment ExerciseRequestTaxInformationExport_FairMarketValueAtExercise on FairMarketValue {
    ...TaxDetailsContent_FairMarketValueAtExercise
  }
`;

const ExerciseRequestTaxInformationExport_: React.FC<{
  ctmsExerciseRequestFragment: ExerciseRequestTaxInformationExport_CTMSExerciseRequest$key;
  fairMarketValueAtExerciseFragment: ExerciseRequestTaxInformationExport_FairMarketValueAtExercise$key | null;
  organizationFragment: ExerciseRequestTaxInformationExport_Organization$key;
}> = ({
  ctmsExerciseRequestFragment,
  fairMarketValueAtExerciseFragment,
  organizationFragment,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const ctmsExerciseRequest = useFragment(
    EXERCISE_REQUEST_FRAGMENT,
    ctmsExerciseRequestFragment,
  );
  const fairMarketValueAtExercise =
    useFragment(
      FAIR_MARKET_VALUE_AT_EXERCISE_FRAGMENT,
      fairMarketValueAtExerciseFragment,
    ) ?? null;

  const { grantee } = ctmsExerciseRequest.ctmsGrant;
  const applicationName = useApplicationName();

  return (
    <div className="h-full overflow-auto bg-gray-01 p-6">
      <CenteredColumnLayout>
        <RoundedBox
          className="flex flex-col items-center gap-4 px-10 py-4"
          withBorder
          withShadow
        >
          <div className="flex flex-col items-center gap-1">
            <Typography variant="Medium/Default">Tax information</Typography>
            <Typography variant="Regular/Small">
              Exercise overview of {grantee.name}
            </Typography>
            <Typography className="text-primary" variant="Regular/Small">
              {organization.name}
            </Typography>
          </div>
          <TaxDetailsContent
            ctmsGrantFragment={ctmsExerciseRequest.ctmsGrant}
            exercisePrice={ctmsExerciseRequest.exercisePrice}
            fairMarketValueAtExerciseFragment={fairMarketValueAtExercise}
            hideHowIsTheSpreadCalculated
            organizationFragment={organization}
            quantityExercised={ctmsExerciseRequest.quantityExercised}
            spread={ctmsExerciseRequest.spread}
            taxResidenceCountryIsKnown={
              ctmsExerciseRequest.taxResidenceCountryIsKnown
            }
            totalExercisePrice={ctmsExerciseRequest.totalExercisePrice}
          />
          <div className="flex items-center gap-2">
            <Typography className="text-black-05" variant="Medium/Caption">
              Powered by {applicationName}
            </Typography>
            <LogoWithoutText className="w-4" />
          </div>
        </RoundedBox>
      </CenteredColumnLayout>
    </div>
  );
};

const QUERY = graphql`
  query ExerciseRequestTaxInformationExport_Query(
    $organizationId: OrganizationId!
    $ctmsExerciseRequestCTMSId: ID!
  ) {
    organization(id: $organizationId) {
      name
      id
      ...ExerciseRequestTaxInformationExport_Organization
    }
    ctmsExerciseRequest(ctmsExerciseRequestCTMSId: $ctmsExerciseRequestCTMSId) {
      ...ExerciseRequestTaxInformationExport_CTMSExerciseRequest
      fairMarketValueAtExercise {
        ...ExerciseRequestTaxInformationExport_FairMarketValueAtExercise
      }
    }
  }
`;

const ExerciseRequestTaxInformationExport: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const ctmsExerciseRequestCTMSId = useCTMSExerciseRequestCTMSIdParam();

  const {
    query: { ctmsExerciseRequest, organization },
  } = useQuery<ExerciseRequestTaxInformationExport_Query>(QUERY, {
    ctmsExerciseRequestCTMSId,
    organizationId,
  });

  if (!organization || !ctmsExerciseRequest) {
    return <NotFoundPage />;
  }

  return (
    <FeatureFlagProvider organizationName={organization.name}>
      <AnalyticsContextProvider organizationId={organization.id}>
        <Page
          analyticsCategory="Admin Option exercises"
          analyticsName="Admin - Option exercises - Export view"
          organizationId={organization.id}
          title={`Admin | ${organization.name} exercise request export view`}
        >
          <ExerciseRequestTaxInformationExport_
            ctmsExerciseRequestFragment={ctmsExerciseRequest}
            fairMarketValueAtExerciseFragment={
              ctmsExerciseRequest.fairMarketValueAtExercise
            }
            organizationFragment={organization}
          />
        </Page>
      </AnalyticsContextProvider>
    </FeatureFlagProvider>
  );
};

export default ExerciseRequestTaxInformationExport;
