/**
 * @generated SignedSource<<345aa157dfb8c69bac144c2f1f89413e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type RemoteOnboardingStep = "CLEAN_UP_GRANTEE_DATA" | "CONNECT_CTMS" | "ESSENTIALS_CTMS" | "ESSENTIALS_EQUITY_PLAN_PARAMETERS" | "ESSENTIALS_EXPECTATION" | "ESSENTIALS_FAIR_MARKET_VALUE" | "ESSENTIALS_ISSUING_COMPANY" | "ESSENTIALS_LAST_STEP" | "SET_PRICE_PER_SHARE" | "TEST_LAW_FIRM_CARTA_CONNECTION";
import { FragmentRefs } from "relay-runtime";
export type FinishRemoteEquityOnboardingPage_Organization$data = {
  readonly currentRemoteOnboardingStep: RemoteOnboardingStep | null;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"CTMSPage_Organization" | "ConnectCTMSView_Organization" | "ExpectationPage_Organization" | "FairMarketValuePage_Organization" | "IssuingCompanyPage_Organization" | "KeyEquityPlansParametersPage_Organization" | "LastStepPage_Organization" | "MatchGranteeView_Organization" | "SubmitPricePerShareView_Organization" | "TestLawFirmCartaConnectionView_Organization">;
  readonly " $fragmentType": "FinishRemoteEquityOnboardingPage_Organization";
};
export type FinishRemoteEquityOnboardingPage_Organization$key = {
  readonly " $data"?: FinishRemoteEquityOnboardingPage_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"FinishRemoteEquityOnboardingPage_Organization">;
};

import FinishRemoteEquityOnboardingPage_Organization_RefetchQuery_graphql from './FinishRemoteEquityOnboardingPage_Organization_RefetchQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": FinishRemoteEquityOnboardingPage_Organization_RefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "FinishRemoteEquityOnboardingPage_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentRemoteOnboardingStep",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConnectCTMSView_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SubmitPricePerShareView_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MatchGranteeView_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TestLawFirmCartaConnectionView_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExpectationPage_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CTMSPage_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LastStepPage_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "IssuingCompanyPage_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "KeyEquityPlansParametersPage_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FairMarketValuePage_Organization"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "81785224f11361efbdb24c572537fec6";

export default node;
