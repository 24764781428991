import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { Button } from "../../../../../components/ui/Button";
import { SlideOver } from "../../../../../components/ui/SlideOver";
import { makeRemoteController } from "../../../../../helpers/makeRemoteController";
import { useSafeMutation } from "../../../../../hooks/useSafeMutation";
import { CreateEquityGridLevelSlideOver_CreateEquityGridLevel_Mutation } from "./__generated__/CreateEquityGridLevelSlideOver_CreateEquityGridLevel_Mutation.graphql";
import { CreateEquityGridLevelSlideOver_Organization$key } from "./__generated__/CreateEquityGridLevelSlideOver_Organization.graphql";
import { EquityGridLevelForm } from "./EquityGridLevelForm";
import { FORM_SCHEMA, FormValues } from "./FORM_SCHEMA";

const ORGANIZATION_FRAGMENT = graphql`
  fragment CreateEquityGridLevelSlideOver_Organization on Organization {
    ...EquityGridLevelForm_ConfigureGrants_Organization
  }
`;

const CREATE_EQUITY_GRID_LEVEL_MUTATION = graphql`
  mutation CreateEquityGridLevelSlideOver_CreateEquityGridLevel_Mutation(
    $attributes: EquityGridLevelAttributes!
  ) {
    setEquityGridLevel(attributes: $attributes) {
      id
    }
  }
`;

function CreateEquityGridLevelSlideOver({
  equityGridId,
  onClose,
  onCreated,
  organizationFragment,
  show,
}: {
  equityGridId: string;
  onClose: () => void;
  onCreated?: () => void;
  organizationFragment: CreateEquityGridLevelSlideOver_Organization$key;
  show: boolean;
}) {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const form = useForm<FormValues>({
    resolver: zodResolver(FORM_SCHEMA),
  });
  const [
    triggerCreateEquityGridLevelMutation,
    createEquityGridLevelMutationIsInFlight,
  ] =
    useSafeMutation<CreateEquityGridLevelSlideOver_CreateEquityGridLevel_Mutation>(
      CREATE_EQUITY_GRID_LEVEL_MUTATION,
    );
  const handleSubmit = form.handleSubmit(async (values) => {
    await triggerCreateEquityGridLevelMutation({
      variables: {
        attributes: {
          equityGridId,
          equityValue: values.equityInUsd,
          name: values.name,
          role: values.role,
          yearlySalary: values.yearlySalary,
        },
      },
    });

    onClose();
    onCreated?.();
  });

  return (
    <SlideOver
      footer={
        <div className="flex items-center justify-end gap-4 p-6">
          <Button
            disabled={createEquityGridLevelMutationIsInFlight}
            onClick={onClose}
            size="small"
            type="button"
            variant="Secondary Full"
          >
            Cancel
          </Button>
          <Button
            form="create-equity-grid-level"
            loading={createEquityGridLevelMutationIsInFlight}
            size="small"
            type="submit"
          >
            Save level
          </Button>
        </div>
      }
      header={
        <SlideOver.Header onClose={onClose}>Add a new level</SlideOver.Header>
      }
      onClose={onClose}
      onExited={form.reset}
      show={show}
    >
      <div className="px-6 py-10">
        <EquityGridLevelForm
          control={form.control}
          id="create-equity-grid-level"
          onSubmit={handleSubmit}
          organizationFragment={organization}
        />
      </div>
    </SlideOver>
  );
}

export const CreateEquityGridLevelSlideOverController = makeRemoteController<
  {
    equityGridId: string;
    organizationFragment: CreateEquityGridLevelSlideOver_Organization$key;
  },
  {
    created: boolean;
  }
>({
  render: ({ close, state }) => {
    if (!state.data) {
      return null;
    }

    return (
      <CreateEquityGridLevelSlideOver
        equityGridId={state.data.equityGridId}
        onClose={() => {
          close({ created: false });
        }}
        onCreated={() => {
          close({ created: true });
        }}
        organizationFragment={state.data.organizationFragment}
        show={state.show}
      />
    );
  },
});
