/**
 * @generated SignedSource<<5ec1ea8d2102d6541d8b1f74aa58f2ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TeamIncentivizationGraph_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CurrencySymbol_Organization" | "FormattedCurrencyCompact_Organization" | "GranteesListSlideOver_Organization" | "useCurrencyFormatter_Organization">;
  readonly " $fragmentType": "TeamIncentivizationGraph_Organization";
};
export type TeamIncentivizationGraph_Organization$key = {
  readonly " $data"?: TeamIncentivizationGraph_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"TeamIncentivizationGraph_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TeamIncentivizationGraph_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteesListSlideOver_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurrencySymbol_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useCurrencyFormatter_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedCurrencyCompact_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "48d11e5b71ed0fac251bc6da1a32dc50";

export default node;
