/**
 * @generated SignedSource<<4eda91ddac374bb6717e5d38c34cc76e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GrantedSharesInput_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useCurrencyFormatter_Organization">;
  readonly " $fragmentType": "GrantedSharesInput_Organization";
};
export type GrantedSharesInput_Organization$key = {
  readonly " $data"?: GrantedSharesInput_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantedSharesInput_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantedSharesInput_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useCurrencyFormatter_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "2143ac5bca643113f3dfdfef3987134d";

export default node;
