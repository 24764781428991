import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { ExerciseRequestTimelineSection } from "../../../components/ExerciseRequestTimelineSection";
import { LinkButton } from "../../../components/ui/Button";
import { CTMSLogo } from "../../../components/ui/CTMSLogo";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { Typography } from "../../../components/ui/Typography";
import { useOrganizationCTMS } from "../../../hooks/useOrganizationCTMS";
import {
  ExerciseRequestApprovalSection_CTMSGrant$data,
  ExerciseRequestApprovalSection_CTMSGrant$key,
} from "./__generated__/ExerciseRequestApprovalSection_CTMSGrant.graphql";
import {
  ExerciseRequestApprovalSection_Organization$data,
  ExerciseRequestApprovalSection_Organization$key,
} from "./__generated__/ExerciseRequestApprovalSection_Organization.graphql";
import { ExerciseRequestApprovalSection_Viewer$key } from "./__generated__/ExerciseRequestApprovalSection_Viewer.graphql";
import { TaxDetailsContent } from "./TaxDetailsContent";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment ExerciseRequestApprovalSection_CTMSGrant on CTMSGrant {
    grantee {
      workRelationship
    }
    ...TaxDetailsContent_CTMSGrant
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment ExerciseRequestApprovalSection_Organization on Organization {
    ...useOrganizationCTMS_Organization
    ...TaxDetailsContent_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment ExerciseRequestApprovalSection_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    isAllowedToManageOrganization(organizationId: $organizationId)
  }
`;

const ApproveInCTMSButton: React.FC<{
  organization: ExerciseRequestApprovalSection_Organization$data;
}> = ({ organization }) => {
  const organizationCTMS = useOrganizationCTMS({
    organizationFragment: organization,
  });

  if (!organizationCTMS) {
    return null;
  }

  return (
    <LinkButton
      size="small"
      target="_blank"
      to={organizationCTMS.loginUrl}
      variant="Primary Full"
    >
      Approve exercise in {organizationCTMS.name}
    </LinkButton>
  );
};

const WorkRelationshipSpecificTaxInformationBoxContent: React.FC<{
  ctmsGrant: ExerciseRequestApprovalSection_CTMSGrant$data;
  organization: ExerciseRequestApprovalSection_Organization$data;
  spread: null | number;
  taxResidenceCountryIsKnown: boolean;
}> = ({ ctmsGrant, organization, spread, taxResidenceCountryIsKnown }) => {
  const { workRelationship } = ctmsGrant.grantee;
  if (!workRelationship) {
    return null;
  }
  switch (workRelationship) {
    case "Advisor":
    case "ContractorManagementCompany":
    case "ContractorNaturalPerson":
    case "EoREmployee":
      return <TaxDetailsContent.ForNonDirectEmployeeCtmsApproval />;
    case "DirectEmployee":
      return (
        <TaxDetailsContent.ForDirectEmployeeCtmsApproval
          ctmsGrantFragment={ctmsGrant}
          organizationFragment={organization}
          spread={spread}
          taxResidenceCountryIsKnown={taxResidenceCountryIsKnown}
        />
      );
  }
};

export const ExerciseRequestApprovalSection: React.FC<{
  ctmsGrantFragment: ExerciseRequestApprovalSection_CTMSGrant$key;
  currentStep: number;
  nextStep?: string;
  organizationFragment: ExerciseRequestApprovalSection_Organization$key;
  spread: null | number;
  taxResidenceCountryIsKnown: boolean;
  totalSteps: number;
  viewerFragment: ExerciseRequestApprovalSection_Viewer$key;
}> = ({
  ctmsGrantFragment,
  currentStep,
  nextStep,
  organizationFragment,
  spread,
  taxResidenceCountryIsKnown,
  totalSteps,
  viewerFragment,
}) => {
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  const { grantee } = ctmsGrant;

  const organizationCTMS = useOrganizationCTMS({
    organizationFragment: organization,
  });

  return (
    <ExerciseRequestTimelineSection
      chipContent={
        <Typography className="text-black-07" variant="Regular/Extra Small">
          {currentStep}
        </Typography>
      }
    >
      <div className="space-y-6">
        <div className="space-y-4">
          <Typography as="div" variant="Medium/Default">
            Approve the exercise request in {organizationCTMS?.name ?? "CTMS"}
          </Typography>
          {grantee.workRelationship && (
            <Typography
              as="div"
              className="text-black-05"
              variant="Regular/Small"
            >
              Time to fill in payment details and approve the exercise request
              in {organizationCTMS?.name}!
              <br />
              <br />
              If necessary, use the tax information below when completing the
              exercise payment details and taxes in{" "}
              {organizationCTMS?.name ?? "CTMS"}.
            </Typography>
          )}
        </div>
        <RoundedBox
          className="space-y-6 !rounded-[20px] px-10 py-6"
          withBorder
          withShadow
        >
          <div className="flex w-full flex-col items-center gap-2">
            <Typography
              as="div"
              className="flex items-center gap-2"
              variant="Medium/Default"
            >
              <span>Tax information for</span>
              <CTMSLogo className="h-6" organizationFragment={organization} />
            </Typography>
            <Typography className="text-black-05" variant="Regular/Extra Small">
              Fill in the following taxes information in{" "}
              {organizationCTMS?.name ?? "CTMS"}
            </Typography>
          </div>
          <WorkRelationshipSpecificTaxInformationBoxContent
            ctmsGrant={ctmsGrant}
            organization={organization}
            spread={spread}
            taxResidenceCountryIsKnown={taxResidenceCountryIsKnown}
          />
          <div className="flex items-center gap-10">
            <Typography
              as="div"
              className="flex-grow text-right text-black-05"
              variant="Regular/Extra Small"
            >
              Step {currentStep} of {totalSteps}{" "}
              {nextStep && (
                <>
                  <br />
                  Next: {nextStep}
                </>
              )}
            </Typography>
            {viewer.isAllowedToManageOrganization && (
              <ApproveInCTMSButton organization={organization} />
            )}
          </div>
        </RoundedBox>
      </div>
    </ExerciseRequestTimelineSection>
  );
};
