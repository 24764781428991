/**
 * @generated SignedSource<<dd6a1219b33559707f06b35fa9bba568>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CTMSGrantStatus = "Active" | "Canceled" | "Terminated";
export type OrganizationCTMSGrantsFiltersOrderByField = "cumulativeVested" | "equityType" | "exercisePrice" | "grantee" | "label" | "quantityExercised" | "quantityIssued" | "remainingDaysToExercise" | "settled" | "terminationDate";
export type OrganizationCTMSGrantsFiltersVestingStatus = "CLIFF_NOT_PASSED" | "FULLY_VESTED" | "VESTING";
export type SortDirection = "ASC" | "DESC";
export type OrganizationCTMSGrantsFilters = {
  equityTypeIn?: ReadonlyArray<string> | null;
  granteeId?: string | null;
  idIn?: ReadonlyArray<string> | null;
  orderBy?: OrganizationCTMSGrantsFiltersOrderBy | null;
  search?: string | null;
  statusIn?: ReadonlyArray<CTMSGrantStatus> | null;
  vestingStatus?: OrganizationCTMSGrantsFiltersVestingStatus | null;
};
export type OrganizationCTMSGrantsFiltersOrderBy = {
  direction: SortDirection;
  field: OrganizationCTMSGrantsFiltersOrderByField;
};
export type CTMSGrantVestingDataPointsFilters = {
  afterDate?: string | null;
  beforeDate?: string | null;
  withNewlyVestedShares?: boolean | null;
};
export type RSUVestingPage_Query$variables = {
  ctmsGrantsFilters?: OrganizationCTMSGrantsFilters | null;
  organizationId: string;
  vestingDataPointsFilters?: CTMSGrantVestingDataPointsFilters | null;
};
export type RSUVestingPage_Query$data = {
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"RSUVestingPage_Organization">;
  } | null;
};
export type RSUVestingPage_Query = {
  response: RSUVestingPage_Query$data;
  variables: RSUVestingPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ctmsGrantsFilters"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "vestingDataPointsFilters"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RSUVestingPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "args": [
              {
                "kind": "Variable",
                "name": "ctmsGrantsFilters",
                "variableName": "ctmsGrantsFilters"
              },
              {
                "kind": "Variable",
                "name": "vestingDataPointsFilters",
                "variableName": "vestingDataPointsFilters"
              }
            ],
            "kind": "FragmentSpread",
            "name": "RSUVestingPage_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "RSUVestingPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToCarta",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "defaultCurrencyCode",
                "storageKey": null
              }
            ],
            "type": "IOrganization",
            "abstractKey": "__isIOrganization"
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "filters",
                "variableName": "ctmsGrantsFilters"
              }
            ],
            "concreteType": "OrganizationCTMSGrantsConnection",
            "kind": "LinkedField",
            "name": "ctmsGrants",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationCTMSGrantsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CTMSGrant",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "label",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Grantee",
                        "kind": "LinkedField",
                        "name": "grantee",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Country",
                            "kind": "LinkedField",
                            "name": "taxResidenceCountry",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "emoji",
                                "storageKey": null
                              },
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Variable",
                            "name": "filters",
                            "variableName": "vestingDataPointsFilters"
                          }
                        ],
                        "concreteType": "VestingDataPoint",
                        "kind": "LinkedField",
                        "name": "vestingDataPoints",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "date",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "newlyVested",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "FairMarketValue",
                            "kind": "LinkedField",
                            "name": "fairMarketValueAtDate",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "value",
                                "storageKey": null
                              },
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "44af39769e32cc5138d5ad02dd251a11",
    "id": null,
    "metadata": {},
    "name": "RSUVestingPage_Query",
    "operationKind": "query",
    "text": "query RSUVestingPage_Query(\n  $organizationId: OrganizationId!\n  $ctmsGrantsFilters: OrganizationCTMSGrantsFilters\n  $vestingDataPointsFilters: CTMSGrantVestingDataPointsFilters\n) {\n  organization(id: $organizationId) {\n    id\n    name\n    ...RSUVestingPage_Organization_2YHpK9\n  }\n}\n\nfragment CountryFlag_Country on Country {\n  emoji\n  name\n}\n\nfragment CtmsGrantsTableRowGranteeCell_Grantee on Grantee {\n  email\n  ...GranteeNameWithCountryFlag_Grantee\n}\n\nfragment Footer_Organization on Organization {\n  isConnectedToCarta\n}\n\nfragment FormattedCurrency_Organization on IOrganization {\n  __isIOrganization: __typename\n  defaultCurrencyCode\n}\n\nfragment GranteeNameWithCountryFlag_Grantee on Grantee {\n  name\n  taxResidenceCountry {\n    ...CountryFlag_Country\n  }\n}\n\nfragment LargeOneColumnLayout_Organization on Organization {\n  ...Footer_Organization\n}\n\nfragment RSUVestingPage_Organization_2YHpK9 on Organization {\n  ...LargeOneColumnLayout_Organization\n  ...RSUVestingTable_Organization\n  ctmsGrants(filters: $ctmsGrantsFilters) {\n    edges {\n      node {\n        ...RSUVestingTable_CTMSGrant_3gW8oe\n        id\n      }\n    }\n  }\n}\n\nfragment RSUVestingTable_CTMSGrant_3gW8oe on CTMSGrant {\n  label\n  grantee {\n    name\n    ...CtmsGrantsTableRowGranteeCell_Grantee\n    id\n  }\n  vestingDataPoints(filters: $vestingDataPointsFilters) {\n    date\n    newlyVested\n    fairMarketValueAtDate {\n      value\n      id\n    }\n  }\n}\n\nfragment RSUVestingTable_Organization on Organization {\n  ...FormattedCurrency_Organization\n}\n"
  }
};
})();

(node as any).hash = "3bcabf456d56d3919e67837fd3619aa5";

export default node;
