/**
 * @generated SignedSource<<9aab61ff7fc3fb29dd437106540d34ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EquityTypeHandbookEvent = "Exercise" | "Grant" | "Holding" | "Sale" | "Vesting";
export type EquityTypeHandbookSection = "Reporting" | "TaxBenefitsAndWatchouts" | "Taxation";
export type EquityTypeHandbookSectionEventItemCategory = "Employee" | "Employer" | "Issuer" | "PotentialTaxBenefit" | "PotentialWatchout";
import { FragmentRefs } from "relay-runtime";
export type EquityHandbooksCountryEquityTypeContent_EquityTypeHandbook$data = {
  readonly sectionEvents: ReadonlyArray<{
    readonly event: EquityTypeHandbookEvent;
    readonly items: ReadonlyArray<{
      readonly category: EquityTypeHandbookSectionEventItemCategory;
      readonly content: string;
    }>;
    readonly nothingToDeclare: boolean;
    readonly section: EquityTypeHandbookSection;
  }>;
  readonly " $fragmentType": "EquityHandbooksCountryEquityTypeContent_EquityTypeHandbook";
};
export type EquityHandbooksCountryEquityTypeContent_EquityTypeHandbook$key = {
  readonly " $data"?: EquityHandbooksCountryEquityTypeContent_EquityTypeHandbook$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquityHandbooksCountryEquityTypeContent_EquityTypeHandbook">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "enabled",
    "value": true
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquityHandbooksCountryEquityTypeContent_EquityTypeHandbook",
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "EquityTypeHandbookSectionEvent",
      "kind": "LinkedField",
      "name": "sectionEvents",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "section",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "event",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nothingToDeclare",
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v0/*: any*/),
          "concreteType": "EquityTypeHandbookSectionEventItem",
          "kind": "LinkedField",
          "name": "items",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "category",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "content",
              "storageKey": null
            }
          ],
          "storageKey": "items(enabled:true)"
        }
      ],
      "storageKey": "sectionEvents(enabled:true)"
    }
  ],
  "type": "EquityTypeHandbook",
  "abstractKey": null
};
})();

(node as any).hash = "74706b30147ba3323e482e846225b77f";

export default node;
