import {
  ArrowTopRightOnSquareIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import { FormattedCurrency } from "../../../../components/Formatted/FormattedCurrency";
import { FormattedPercentage } from "../../../../components/Formatted/FormattedPercentage";
import { LongDate } from "../../../../components/LongDate";
import { StripeCardPaymentMethod } from "../../../../components/StripeCardPaymentMethod";
import { BreadCrumb } from "../../../../components/ui/BreadCrumb";
import { Button } from "../../../../components/ui/Button";
import { Divider } from "../../../../components/ui/Divider";
import { HelpCard } from "../../../../components/ui/HelpCard";
import { CenteredColumnLayout } from "../../../../components/ui/Layout/CenteredColumnLayout";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { Tag } from "../../../../components/ui/Tag";
import { TooltipContainer } from "../../../../components/ui/TooltipContainer";
import { Typography } from "../../../../components/ui/Typography";
import { useApplicationSupportEmailAddress } from "../../../../hooks/useApplicationTheme";
import { useBoolean } from "../../../../hooks/useBoolean";
import { useStripeCustomerPortal } from "../../../../hooks/useStripeCustomerPortal";
import { APPLICATION_ROUTES } from "../../../../paths";
import { EnterBillingInformationBox } from "../../Overview/EnterBillingInformationBox";
import { SettingsContainer } from "../SettingsContainer";
import { EasopOrganizationSettingsBilling_Organization$key } from "./__generated__/EasopOrganizationSettingsBilling_Organization.graphql";
import { EasopOrganizationSettingsBilling_StripeCustomer$key } from "./__generated__/EasopOrganizationSettingsBilling_StripeCustomer.graphql";
import { BillableGeographiesSlideOver } from "./BillableGeographiesSlideOver";
import { BillableGranteesSlideOver } from "./BillableGranteesSlideOver";

const ORGANIZATION_FRAGMENT = graphql`
  fragment EasopOrganizationSettingsBilling_Organization on Organization {
    id
    name
    upcomingInvoice {
      amountDue
      periodStart
      periodEnd
      creationDate
      billingPeriod
    }
    currentPlan {
      platformFees {
        amountBeforeDiscount
        amountAfterDiscount
        discount
        discountEndDate
      }
      geographiesFees {
        amountBeforeDiscount
        amountAfterDiscount
        discount
        discountEndDate
      }
      billingPeriod
    }
    billableGrantees {
      ...BillableGranteesSlideOver_Grantee
    }
    organizationGeographies {
      __typename
    }
    ...BillableGranteesSlideOver_Organization
    ...BillableGeographiesSlideOver_Organization
    ...FormattedCurrency_Organization
  }
`;

const STRIPE_CUSTOMER_FRAGMENT = graphql`
  fragment EasopOrganizationSettingsBilling_StripeCustomer on StripeCustomer {
    invoicesDefaultPaymentMethod {
      __typename
      ...StripeCardPaymentMethod_StripePaymentMethod
    }
  }
`;

export const EasopOrganizationSettingsBillingPage: React.FC<{
  organizationFragment: EasopOrganizationSettingsBilling_Organization$key;
  stripeCustomerFragment: EasopOrganizationSettingsBilling_StripeCustomer$key | null;
}> = ({ organizationFragment, stripeCustomerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const stripeCustomer = useFragment(
    STRIPE_CUSTOMER_FRAGMENT,
    stripeCustomerFragment,
  );
  const supportEmailAddress = useApplicationSupportEmailAddress();
  const mailtoSubject = encodeURIComponent(
    `[${organization.name}] Switch to yearly billing`,
  );
  const mailtoBody = encodeURIComponent(
    `Hi, I would like to switch to yearly billing. Can you help me with that?`,
  );
  const { openStripeCustomerPortal, openStripeCustomerPortalIsLoading } =
    useStripeCustomerPortal({ organizationId: organization.id });

  const {
    setFalse: closeBillableGranteesSlideOver,
    setTrue: openBillableGranteesSlideOver,
    value: billableGranteesSlideOverShown,
  } = useBoolean(false);
  const {
    setFalse: closeBillableGeographiesSlideOver,
    setTrue: openBillableGeographiesSlideOver,
    value: billableGeographiesSlideOverShown,
  } = useBoolean(false);

  return (
    <CenteredColumnLayout
      breadcrumbs={
        <BreadCrumb>
          <BreadCrumb.Link
            to={generatePath(APPLICATION_ROUTES.organizationHome, {
              organizationId: organization.id,
            })}
          >
            {organization.name}
          </BreadCrumb.Link>
          <BreadCrumb.Link to="..">Settings</BreadCrumb.Link>
          <BreadCrumb.Link to=".">Plan & Billing</BreadCrumb.Link>
        </BreadCrumb>
      }
    >
      <BillableGranteesSlideOver
        granteesFragment={organization.billableGrantees}
        onClose={closeBillableGranteesSlideOver}
        organizationFragment={organization}
        show={billableGranteesSlideOverShown}
      />
      <BillableGeographiesSlideOver
        onClose={closeBillableGeographiesSlideOver}
        organizationFragment={organization}
        show={billableGeographiesSlideOverShown}
      />
      <div className="space-y-6">
        <SettingsContainer title="Plan & Billing">
          {stripeCustomer && !stripeCustomer.invoicesDefaultPaymentMethod && (
            <SettingsContainer.Item>
              <RoundedBox className="!bg-primary-01 p-6">
                <EnterBillingInformationBox />
              </RoundedBox>
            </SettingsContainer.Item>
          )}
          {organization.upcomingInvoice && (
            <SettingsContainer.Item title="Your next payment" titleSize="large">
              <div className="space-y-4">
                <div className="space-y-2">
                  <div>
                    The next payment of{" "}
                    <span className="text-Base/SemiBold">
                      <FormattedCurrency
                        organizationFragment={organization}
                        value={organization.upcomingInvoice.amountDue}
                      />
                    </span>{" "}
                    is set on{" "}
                    <span className="text-Base/SemiBold">
                      <LongDate
                        value={organization.upcomingInvoice.creationDate}
                      />
                    </span>
                    .
                  </div>
                  <div className="text-XS/Normal text-gray-09">
                    Your recurring fees are subject to increase based on your
                    usage.
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-10 rounded-2xl border-[0.5px] border-gray-04 p-10 md:grid-cols-2 lg:grid-cols-3">
                  <div className="space-y-2">
                    <div className="text-XS/Normal text-gray-09">
                      Billing frequency
                    </div>
                    <div className="text-LG/Medium capitalize">
                      {organization.upcomingInvoice.billingPeriod}
                    </div>
                    {organization.upcomingInvoice.billingPeriod ===
                      "monthly" && (
                      <Link
                        className="block text-2XS/Medium text-primary"
                        to={`mailto:${supportEmailAddress}?subject=${mailtoSubject}&body=${mailtoBody}`}
                      >
                        SWITCH TO YEARLY FOR 10% OFF
                      </Link>
                    )}
                    <Button
                      loading={openStripeCustomerPortalIsLoading}
                      onClick={openStripeCustomerPortal}
                      size="small"
                      variant="Primary Outline"
                    >
                      Invoice history
                    </Button>
                  </div>
                  {organization.upcomingInvoice.periodStart &&
                    organization.upcomingInvoice.periodEnd && (
                      <div className="space-y-2">
                        <div className="text-XS/Normal text-gray-09">
                          Billing period
                        </div>
                        <div className="text-LG/Medium capitalize">
                          <LongDate
                            value={organization.upcomingInvoice.periodStart}
                          />{" "}
                          -{" "}
                          <LongDate
                            value={organization.upcomingInvoice.periodEnd}
                          />
                        </div>
                      </div>
                    )}

                  <div className="space-y-2">
                    <div className="text-XS/Normal text-gray-09">
                      Payment method
                    </div>
                    {stripeCustomer?.invoicesDefaultPaymentMethod && (
                      <StripeCardPaymentMethod
                        stripePaymentMethodFragment={
                          stripeCustomer.invoicesDefaultPaymentMethod
                        }
                      />
                    )}
                    <Button
                      loading={openStripeCustomerPortalIsLoading}
                      onClick={openStripeCustomerPortal}
                      rightIcon={<ArrowTopRightOnSquareIcon />}
                      size="small"
                    >
                      Update billing info
                    </Button>
                  </div>
                </div>
              </div>
            </SettingsContainer.Item>
          )}
          {organization.currentPlan?.platformFees && (
            <SettingsContainer.Item title="Platform access">
              <div className="flex flex-col rounded border-[0.5px] border-gray-07">
                <Typography
                  className="flex items-center gap-2 px-3 py-3.5"
                  variant="Regular/Extra Small"
                >
                  <span>Billed grantees:</span>
                  <TooltipContainer
                    tooltipContent={
                      <span className="text-gray-07">
                        Billed grantees correspond to grantees that are either
                        active or settling
                      </span>
                    }
                  >
                    <InformationCircleIcon className="ml-1 h-4 w-4 text-primary" />
                  </TooltipContainer>
                  <Typography variant="Medium/Extra Small">
                    <FormattedMessage
                      defaultMessage="{granteesCount} {granteesCount, plural, one {grantee} other {grantees}}"
                      values={{
                        granteesCount: organization.billableGrantees.length,
                      }}
                    />
                  </Typography>
                  <Typography
                    className="text-primary"
                    variant="Medium/XS-Caption"
                  >
                    <button onClick={openBillableGranteesSlideOver}>
                      VIEW GRANTEES
                    </button>
                  </Typography>
                </Typography>
                <Divider variant="dark" />
                <Typography
                  className="flex items-center gap-2 px-3 py-3.5"
                  variant="Regular/Extra Small"
                >
                  <span>Fee:</span>
                  {organization.currentPlan.platformFees.discount && (
                    <Typography
                      className="line-through"
                      variant="Regular/Extra Small"
                    >
                      <FormattedCurrency
                        organizationFragment={organization}
                        value={
                          organization.currentPlan.platformFees
                            .amountBeforeDiscount
                        }
                      />
                    </Typography>
                  )}
                  <Typography variant="Medium/Extra Small">
                    <FormattedCurrency
                      organizationFragment={organization}
                      value={
                        organization.currentPlan.platformFees
                          .amountAfterDiscount
                      }
                    />
                  </Typography>
                  <Typography variant="Regular/Caption">
                    {organization.currentPlan.billingPeriod === "monthly"
                      ? "/month"
                      : "/year"}
                  </Typography>
                </Typography>
                {organization.currentPlan.platformFees.discount && (
                  <>
                    <Divider variant="dark" />
                    <Typography
                      className="flex items-center gap-2 px-3 py-3.5"
                      variant="Regular/Extra Small"
                    >
                      <span>Discount:</span>
                      <Tag color="purple">
                        <FormattedPercentage
                          value={
                            organization.currentPlan.platformFees.discount /
                            organization.currentPlan.platformFees
                              .amountBeforeDiscount
                          }
                        />
                      </Tag>
                      {organization.currentPlan.platformFees
                        .discountEndDate && (
                        <Typography
                          className="italic"
                          variant="Regular/Extra Small"
                        >
                          valid until{" "}
                          <LongDate
                            value={
                              organization.currentPlan.platformFees
                                .discountEndDate
                            }
                          />
                        </Typography>
                      )}
                    </Typography>
                  </>
                )}
              </div>
            </SettingsContainer.Item>
          )}
          {organization.currentPlan?.geographiesFees && (
            <SettingsContainer.Item title="Geography access">
              <div className="flex flex-col rounded border-[0.5px] border-gray-07">
                <Typography
                  className="flex items-center gap-2 px-3 py-3.5"
                  variant="Regular/Extra Small"
                >
                  <span>Active geographies:</span>
                  <TooltipContainer
                    tooltipContent={
                      <span className="text-gray-07">
                        Active geographies is the number of geographies that are
                        currently unlocked.
                      </span>
                    }
                  >
                    <InformationCircleIcon className="ml-1 h-4 w-4 text-primary" />
                  </TooltipContainer>
                  <Typography variant="Medium/Extra Small">
                    <FormattedMessage
                      defaultMessage="{geographiesCount} {geographiesCount, plural, one {geography} other {geographies}}"
                      values={{
                        geographiesCount:
                          organization.organizationGeographies.length,
                      }}
                    />
                  </Typography>
                  <Typography
                    className="text-primary"
                    variant="Medium/XS-Caption"
                  >
                    <button onClick={openBillableGeographiesSlideOver}>
                      VIEW GEOGRAPHIES
                    </button>
                  </Typography>
                </Typography>
                <Divider variant="dark" />
                <Typography
                  className="flex items-center gap-2 px-3 py-3.5"
                  variant="Regular/Extra Small"
                >
                  <span>Fee:</span>
                  {organization.currentPlan.geographiesFees.discount && (
                    <Typography
                      className="line-through"
                      variant="Regular/Extra Small"
                    >
                      <FormattedCurrency
                        organizationFragment={organization}
                        value={
                          organization.currentPlan.geographiesFees
                            .amountBeforeDiscount
                        }
                      />
                    </Typography>
                  )}
                  <Typography variant="Medium/Extra Small">
                    <FormattedCurrency
                      organizationFragment={organization}
                      value={
                        organization.currentPlan.geographiesFees
                          .amountAfterDiscount
                      }
                    />
                  </Typography>
                  <Typography variant="Regular/Caption">
                    {organization.currentPlan.billingPeriod === "monthly"
                      ? "/month"
                      : "/year"}
                  </Typography>
                </Typography>
                {organization.currentPlan.geographiesFees.discount && (
                  <>
                    <Divider variant="dark" />
                    <Typography
                      className="flex items-center gap-2 px-3 py-3.5"
                      variant="Regular/Extra Small"
                    >
                      <span>Discount:</span>
                      <Tag color="purple">
                        <FormattedPercentage
                          value={
                            organization.currentPlan.geographiesFees.discount /
                            organization.currentPlan.geographiesFees
                              .amountBeforeDiscount
                          }
                        />
                      </Tag>
                      {organization.currentPlan.geographiesFees
                        .discountEndDate && (
                        <Typography
                          className="italic"
                          variant="Regular/Extra Small"
                        >
                          valid until{" "}
                          <LongDate
                            value={
                              organization.currentPlan.geographiesFees
                                .discountEndDate
                            }
                          />
                        </Typography>
                      )}
                    </Typography>
                  </>
                )}
              </div>
            </SettingsContainer.Item>
          )}
        </SettingsContainer>

        <HelpCard
          buttonLabel="Talk to our team"
          label="Anything unclear? You need more information regarding your countries or our billing methods?"
        />
      </div>
    </CenteredColumnLayout>
  );
};
