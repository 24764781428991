import { BoxedIcon, Button, Text } from "@remote-com/norma";
import { IconV2DuotoneListUnordered } from "@remote-com/norma/icons/IconV2DuotoneListUnordered";
import { ArrowDown } from "iconsax-react";
import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { SectionHero } from "../../../components/SectionHero";
import { generateEmployeePortalSectionAnchorPath } from "../useSections";
import { EmployeePortalReportingAndGeneralTaxationSection_Grantee$key } from "./__generated__/EmployeePortalReportingAndGeneralTaxationSection_Grantee.graphql";
import { EmployeePortalReportingAndGeneralTaxationDetailsSection } from "./EmployeePortalReportingAndGeneralTaxationDetailsSection";
import illustration from "./illustration.png";

const GRANTEE_FRAGMENT = graphql`
  fragment EmployeePortalReportingAndGeneralTaxationSection_Grantee on Grantee {
    id
    ...EmployeePortalReportingAndGeneralTaxationDetailsSection_Grantee
  }
`;

export const EmployeePortalReportingAndGeneralTaxationSection = forwardRef<
  HTMLDivElement,
  {
    granteeFragment: EmployeePortalReportingAndGeneralTaxationSection_Grantee$key;
  }
>(function EmployeePortalReportingAndGeneralTaxationSection(
  { granteeFragment },
  ref,
) {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  return (
    <div className="space-y-10" ref={ref}>
      <SectionHero
        aria-labelledby="reporting-and-general-taxation-heading"
        className="bg-background-subtle"
        hideBorderOnMobile
        id="reporting-and-general-taxation"
        role="region"
        withBorder
      >
        <div className="flex items-center gap-10">
          <div className="flex-1 space-y-4">
            <BoxedIcon
              Icon={IconV2DuotoneListUnordered}
              size="lg"
              tone="green"
            />
            <Text
              id="reporting-and-general-taxation-heading"
              variant="3xlMedium"
            >
              Reporting and general taxation
            </Text>
            <Text variant="lgMedium">We made it simple for you</Text>
            <Text className="text-grey-600" variant="sm">
              Reporting to your local tax can be painful. We’re here to help you
              through the process because receiving equity has to be declared
              properly, to the correct authority and at the right moment,
              depending on where you live...
            </Text>
            <Button
              as="a"
              href={generateEmployeePortalSectionAnchorPath({
                anchor: "reporting-and-general-taxation-details",
                granteeId: grantee.id,
              })}
              IconAfter={ArrowDown}
              size="md"
            >
              Learn more
            </Button>
          </div>
          <img className="hidden w-[320px] lg:block" src={illustration} />
        </div>
      </SectionHero>
      <EmployeePortalReportingAndGeneralTaxationDetailsSection
        granteeFragment={grantee}
      />
    </div>
  );
});
