import { Radio, RadioGroup } from "@headlessui/react";
import { Pill, Text } from "@remote-com/norma";

import { cx } from "../../../../helpers/cva.config";

export function EssentialsOnboardingRadioCard({
  city,
  country,
  isDefault,
  name,
  remoteSlug,
}: {
  city: null | string;
  country: string;
  isDefault?: boolean;
  name: string;
  remoteSlug: string;
}) {
  return (
    <Radio
      className="group block cursor-pointer overflow-hidden rounded-remote-xl border bg-white p-2 transition-all data-[checked]:border-brand-600"
      value={remoteSlug}
    >
      <div
        className={cx(
          "space-y-2 overflow-hidden rounded-remote-lg px-9 py-5 transition-all group-data-[checked]:bg-brand-50",
        )}
      >
        <div className="flex items-center justify-between gap-2">
          <Text variant="lgMedium">{name}</Text>
          {isDefault && <Pill tone="bayoux">DEFAULT ENTITY</Pill>}
        </div>
        <Text className="text-grey-600" variant="sm">
          {city ? `${city}, ${country}` : country}
        </Text>
      </div>
    </Radio>
  );
}

export function EssentialsOnboardingRadioGroup({
  children,
  onChange,
  value,
}: React.PropsWithChildren<{
  onChange: (value: string | undefined) => void;
  value: string | undefined;
}>) {
  return (
    <RadioGroup className="space-y-4" onChange={onChange} value={value}>
      {children}
    </RadioGroup>
  );
}
