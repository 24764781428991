import { BoxedIcon, DropdownMenu, Text } from "@remote-com/norma";
import { IconV2OutlineFileText } from "@remote-com/norma/icons/IconV2OutlineFileText";
import { IconV2OutlineFormatPdf } from "@remote-com/norma/icons/IconV2OutlineFormatPdf";
import saveAs from "file-saver";
import { isEmpty } from "lodash";
import { useCallback, useMemo } from "react";
import { FormattedDate } from "react-intl";
import { graphql, useFragment } from "react-relay";

import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { useTrackDocumentsDownloaded } from "../../../../hooks/useAnalytics";
import { useGrantDocuments } from "../../Documents/useGrantDocuments";
import { Section } from "../../Grantee/GranteeDetails/Section";
import { GrantDetailsDocumentsSection_CTMSGrant$key } from "./__generated__/GrantDetailsDocumentsSection_CTMSGrant.graphql";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment GrantDetailsDocumentsSection_CTMSGrant on CTMSGrant {
    easopGrant {
      ...useGrantDocuments_EasopGrant
    }
    documents {
      downloadUrl
      updatedAt
      originalFilename
    }
  }
`;

const DocumentBoxedIcon = ({ fileName }: { fileName: string }) => {
  const extension = fileName.split(".").pop();

  switch (extension) {
    case "pdf":
      return <BoxedIcon Icon={IconV2OutlineFormatPdf} size="sm" tone="red" />;
    default:
      return <BoxedIcon Icon={IconV2OutlineFileText} size="sm" tone="red" />;
  }
};

const DocumentFileExtension = ({ fileName }: { fileName: string }) => {
  const extension = fileName.split(".").pop();

  switch (extension) {
    case "csv":
      return "CSV file";
    case "doc":
      return "Word file";
    case "docx":
      return "Word file";
    case "pdf":
      return "PDF file";
    case "ppt":
      return "PowerPoint file";
    case "pptx":
      return "PowerPoint file";
    case "rar":
      return "RAR file";
    case "txt":
      return "Text file";
    case "xls":
      return "Excel file";
    case "xlsx":
      return "Excel file";
    case "zip":
      return "Zip file";

    default:
      return null;
  }
};

export function GrantDetailsDocumentsSection({
  className,
  ctmsGrantFragment,
}: {
  className?: string;
  ctmsGrantFragment: GrantDetailsDocumentsSection_CTMSGrant$key;
}) {
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);

  const easopGrantDocuments = useGrantDocuments({
    easopGrantFragment: ctmsGrant.easopGrant,
  });

  const documents = useMemo(
    () => [
      ...easopGrantDocuments,
      ...ctmsGrant.documents.map((document) => ({
        downloadUrl: document.downloadUrl,
        fileName: document.originalFilename ?? "unknown",
        label: document.originalFilename ?? "Missing document info",
        type: "GRANT_DOCUMENT" as const,
        updatedAt: document.updatedAt,
      })),
    ],
    [easopGrantDocuments, ctmsGrant.documents],
  );

  const trackDocumentsDownloaded = useTrackDocumentsDownloaded();

  const handleDownloadDocumentButtonClick = useCallback(
    (document: (typeof documents)[number]) => {
      trackDocumentsDownloaded({
        documentsTypes: [document.type],
        downloadType: "UNIQUE",
      });
      saveAs(document.downloadUrl, document.fileName);
    },
    [trackDocumentsDownloaded],
  );

  return (
    <Section className={className} title="Grant documents">
      {isEmpty(documents) ? (
        <RoundedBox background="remote-grey-100" className="p-4 text-center">
          No documents uploaded for this grant.
        </RoundedBox>
      ) : (
        <RoundedBox
          className="flex flex-col divide-y-[0.5px] divide-grey-50 overflow-hidden"
          withBorder
        >
          {documents.map((document) => (
            <div
              className="flex items-center gap-4 px-6 py-4 hover:bg-grey-50"
              key={document.downloadUrl}
            >
              <DocumentBoxedIcon fileName={document.fileName} />
              <div className="flex flex-1 flex-col gap-1">
                <Text variant="smMedium">{document.fileName}</Text>
                <Text className="text-grey-600" variant="xs">
                  {<DocumentFileExtension fileName={document.fileName} />} •
                  created{" "}
                  {document.updatedAt ? (
                    <FormattedDate
                      day="2-digit"
                      month="2-digit"
                      timeZone="UTC"
                      value={document.updatedAt}
                      year="numeric"
                    />
                  ) : (
                    "-"
                  )}
                </Text>
              </div>
              <DropdownMenu
                // @ts-expect-error className and trigger not defined on DropdownMenu props
                className="z-10"
                menuButtonProps={{
                  tone: "secondary",
                  variant: "ghost",
                }}
              >
                <DropdownMenu.Item
                  onClick={() => {
                    handleDownloadDocumentButtonClick(document);
                  }}
                >
                  Download
                </DropdownMenu.Item>
              </DropdownMenu>
            </div>
          ))}
        </RoundedBox>
      )}
    </Section>
  );
}
