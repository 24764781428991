/**
 * @generated SignedSource<<c7cd69c72be7a396ab21c0fac6bbd0ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type RemoteOnboardingStep = "CLEAN_UP_GRANTEE_DATA" | "CONNECT_CTMS" | "ESSENTIALS_CTMS" | "ESSENTIALS_EQUITY_PLAN_PARAMETERS" | "ESSENTIALS_EXPECTATION" | "ESSENTIALS_FAIR_MARKET_VALUE" | "ESSENTIALS_ISSUING_COMPANY" | "ESSENTIALS_LAST_STEP" | "SET_PRICE_PER_SHARE" | "TEST_LAW_FIRM_CARTA_CONNECTION";
export type TestLawFirmCartaConnectionView_SetTestLawFirmCartaConnectionOnboardingAsCompleted_Mutation$variables = {
  organizationId: string;
};
export type TestLawFirmCartaConnectionView_SetTestLawFirmCartaConnectionOnboardingAsCompleted_Mutation$data = {
  readonly setTestLawFirmCartaConnectionOnboardingAsCompleted: {
    readonly currentRemoteOnboardingStep: RemoteOnboardingStep | null;
  };
};
export type TestLawFirmCartaConnectionView_SetTestLawFirmCartaConnectionOnboardingAsCompleted_Mutation = {
  response: TestLawFirmCartaConnectionView_SetTestLawFirmCartaConnectionOnboardingAsCompleted_Mutation$data;
  variables: TestLawFirmCartaConnectionView_SetTestLawFirmCartaConnectionOnboardingAsCompleted_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currentRemoteOnboardingStep",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TestLawFirmCartaConnectionView_SetTestLawFirmCartaConnectionOnboardingAsCompleted_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "setTestLawFirmCartaConnectionOnboardingAsCompleted",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TestLawFirmCartaConnectionView_SetTestLawFirmCartaConnectionOnboardingAsCompleted_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "setTestLawFirmCartaConnectionOnboardingAsCompleted",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8780b64e75b900f4fff88890719ebd09",
    "id": null,
    "metadata": {},
    "name": "TestLawFirmCartaConnectionView_SetTestLawFirmCartaConnectionOnboardingAsCompleted_Mutation",
    "operationKind": "mutation",
    "text": "mutation TestLawFirmCartaConnectionView_SetTestLawFirmCartaConnectionOnboardingAsCompleted_Mutation(\n  $organizationId: OrganizationId!\n) {\n  setTestLawFirmCartaConnectionOnboardingAsCompleted(organizationId: $organizationId) {\n    currentRemoteOnboardingStep\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "134803ff61144f0786f6ff5ad965b4e5";

export default node;
