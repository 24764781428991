import { FormattedNumber } from "react-intl";

import { SingleValueProgress } from "../SingleValueProgress";
import { Typography } from "../Typography";

export const TableCellExercisedProgress: React.FC<{
  exercisableShares: number;
  exercisedShares: number;
}> = ({ exercisableShares, exercisedShares }) => (
  <div className="flex flex-col gap-2">
    <Typography as="div" variant="Regular/Extra Small">
      <FormattedNumber value={exercisedShares} />{" "}
      <span className="text-grey-500">
        / <FormattedNumber value={exercisableShares} />
      </span>
    </Typography>
    <SingleValueProgress
      className="relative h-2 w-[150px]"
      current={exercisedShares}
      max={exercisableShares}
    />
  </div>
);
