/**
 * @generated SignedSource<<99837a4d7b987ff2d9367002e923efe5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquityDetailsSection_CTMSGrants$data = ReadonlyArray<{
  readonly cumulativeVested: number;
  readonly id: string;
  readonly label: string;
  readonly quantityIssued: number;
  readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantStatusTag_CtmsGrant">;
  readonly " $fragmentType": "EquityDetailsSection_CTMSGrants";
}>;
export type EquityDetailsSection_CTMSGrants$key = ReadonlyArray<{
  readonly " $data"?: EquityDetailsSection_CTMSGrants$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquityDetailsSection_CTMSGrants">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "EquityDetailsSection_CTMSGrants",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantityIssued",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cumulativeVested",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CtmsGrantStatusTag_CtmsGrant"
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "70c40ef9d1553f939dccc91c2ef3ef84";

export default node;
