import * as ReactDOM from "react-dom/client";

import App from "./App";
import { preventNumberInputsScroll } from "./helpers/preventNumberInputScroll";
import "./index.css";

preventNumberInputsScroll();

const rootElement = document.getElementById("root");

if (!rootElement) {
  throw new Error("can't find #root element in DOM");
}

const root = ReactDOM.createRoot(rootElement);
root.render(<App />);
