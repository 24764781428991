import React from "react";
import { graphql } from "relay-runtime";

import { useQuery } from "../../../hooks/useQuery";
import { useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { Overview_Admin_Query } from "./__generated__/Overview_Admin_Query.graphql";
import AdminAdvancedOverviewPage from "./AdvancedOverview";
import AdminEssentialsOverviewPage from "./EssentialsOverview/EssentialsOverview";

const QUERY = graphql`
  query Overview_Admin_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      isRemoteEquityEssentials
    }
  }
`;

const AdminOverviewPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const {
    query: { organization },
  } = useQuery<Overview_Admin_Query>(QUERY, {
    organizationId,
  });

  if (!organization) {
    return <NotFoundPage />;
  }

  if (organization.isRemoteEquityEssentials) {
    return <AdminEssentialsOverviewPage />;
  }

  return <AdminAdvancedOverviewPage />;
};

export default AdminOverviewPage;
