import { BoxedIcon, Pill, Text } from "@remote-com/norma";
import { IconRocket } from "@remote-com/norma/icons/IconRocket";
import { isBefore, isSameDay } from "date-fns";
import { forwardRef, useMemo, useState } from "react";
import { graphql, useFragment } from "react-relay";

import { ColorSquareLegend } from "../../components/ColorSquareLegend";
import { LeftConfetti, RightConfetti } from "../../components/Confettis";
import { EmployeeVestingGraph } from "../../components/EmployeeVestingGraph";
import { FormattedCurrency } from "../../components/Formatted/FormattedCurrency";
import { FormattedNumber } from "../../components/Formatted/FormattedNumber";
import { SectionHero } from "../../components/SectionHero";
import { ShortDate } from "../../components/ShortDate";
import { Divider } from "../../components/ui/Divider";
import { Switch } from "../../components/ui/Switch";
import { VestingScheduleLegend } from "../../components/VestingScheduleLegend";
import { useGetRandomColor } from "../../helpers/getRandomColorPalette";
import { useComputeSharesNetEquityValue } from "../../hooks/useComputeSharesNetEquityValue";
import {
  AwardSuperType,
  EmployeePortalVestingSection_Grantee$data,
  EmployeePortalVestingSection_Grantee$key,
} from "./__generated__/EmployeePortalVestingSection_Grantee.graphql";
import { HelpLink } from "./HelpLink";

const GRANTEE_FRAGMENT = graphql`
  fragment EmployeePortalVestingSection_Grantee on Grantee {
    id
    totalVestedSharesBreakdown {
      total
    }
    totalGrantedSharesBreakdown {
      total
    }
    ctmsGrants(
      grantStatusIn: [Active, Terminated]
      orderBy: { field: vestingStartDate, direction: ASC }
    ) {
      label
      # eslint-disable-next-line relay/unused-fields
      exercisePrice
      quantityIssued
      cumulativeVested
      matchingInstrument {
        awardSuperType
      }
      vestingDataPoints {
        date
        cumulativeVested
      }
      ...EmployeeVestingGraph_CTMSGrants
      ...VestingScheduleLegend_CTMSGrants
    }
    organization {
      granteePortalSettings {
        displayFullyDilutedValues
      }
      ...useComputeSharesNetEquityValue_Organization
      ...EmployeeVestingGraph_Organization
      ...FormattedCurrency_Organization
      ...HelpLink_Organization
    }
  }
`;

interface NextVestingEvent {
  additionalNetEquityValue: null | number;
  additionalVestedShares: number;
  ctmsGrantIndex: number;
  ctmsGrantLabel: string;
  date: string;
}

const getNextVestingEventFromDate = ({
  computeSharesNetEquityValue,
  grantee,
  lookingFromVestingEvent,
}: {
  computeSharesNetEquityValue: ReturnType<
    typeof useComputeSharesNetEquityValue
  >["computeSharesNetEquityValue"];
  grantee: EmployeePortalVestingSection_Grantee$data;
  lookingFromVestingEvent: NextVestingEvent | null;
}) => {
  return grantee.ctmsGrants.reduce<NextVestingEvent | null>(
    (nextVestingEvent, ctmsGrant, ctmsGrantIndex) => {
      const ctmsGrantNextVestingEvent = ctmsGrant.vestingDataPoints.find(
        (dataPoint) => {
          if (!dataPoint.cumulativeVested) return false;
          if (
            isBefore(
              dataPoint.date,
              lookingFromVestingEvent?.date ?? new Date().toISOString(),
            )
          )
            return false;

          if (lookingFromVestingEvent) {
            if (
              lookingFromVestingEvent.ctmsGrantLabel === ctmsGrant.label &&
              isSameDay(dataPoint.date, lookingFromVestingEvent.date)
            )
              return false;
          }

          return true;
        },
      );

      if (!ctmsGrantNextVestingEvent) return nextVestingEvent;

      if (
        nextVestingEvent &&
        !isBefore(ctmsGrantNextVestingEvent.date, nextVestingEvent.date)
      )
        return nextVestingEvent;

      const previousVestingEvent = ctmsGrant.vestingDataPoints.findLast(
        (dataPoint) =>
          dataPoint.cumulativeVested > 0 &&
          isBefore(dataPoint.date, ctmsGrantNextVestingEvent.date),
      );

      const additionalVestedShares = previousVestingEvent
        ? ctmsGrantNextVestingEvent.cumulativeVested -
          previousVestingEvent.cumulativeVested
        : ctmsGrantNextVestingEvent.cumulativeVested;

      return {
        additionalNetEquityValue: computeSharesNetEquityValue({
          exercisePrice: ctmsGrant.exercisePrice,
          shares: additionalVestedShares,
        }),
        additionalVestedShares,
        ctmsGrantIndex,
        ctmsGrantLabel: ctmsGrant.label,
        date: ctmsGrantNextVestingEvent.date,
      };
    },
    null,
  );
};

const SharesUnitLabel: React.FC<{
  awardSuperType: AwardSuperType | null;
}> = ({ awardSuperType }) => {
  switch (awardSuperType) {
    case "RSU":
      return <>shares</>;
    case "SAR":
      return <>virtual shares</>;
    default:
      return <>options</>;
  }
};

export const EmployeePortalVestingSection = forwardRef<
  HTMLDivElement,
  {
    granteeFragment: EmployeePortalVestingSection_Grantee$key;
    id: string;
    valuationMultiple: number;
  }
>(function EmployeePortalVestingSection(
  { granteeFragment, id, valuationMultiple },
  ref,
) {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const displayValuation =
    grantee.organization.granteePortalSettings.displayFullyDilutedValues;
  const getRandomColor = useGetRandomColor({
    paletteSize: grantee.ctmsGrants.length,
    seed: grantee.id,
  });

  const [vestingScheduleView, setVestingScheduleView] = useState<
    "cumulative" | "split"
  >("split");

  const { computeSharesNetEquityValue } = useComputeSharesNetEquityValue({
    organizationFragment: grantee.organization,
    valuationMultiple,
  });

  const nextVestingEvent = useMemo(
    () =>
      getNextVestingEventFromDate({
        computeSharesNetEquityValue,
        grantee,
        lookingFromVestingEvent: null,
      }),
    [grantee, computeSharesNetEquityValue],
  );

  const followingVestingEvent = useMemo(
    () =>
      nextVestingEvent
        ? getNextVestingEventFromDate({
            computeSharesNetEquityValue,
            grantee,
            lookingFromVestingEvent: nextVestingEvent,
          })
        : null,
    [grantee, nextVestingEvent, computeSharesNetEquityValue],
  );

  const totalSharesGranted = grantee.totalGrantedSharesBreakdown.total;
  const totalSharesUnvested =
    grantee.totalGrantedSharesBreakdown.total -
    grantee.totalVestedSharesBreakdown.total;

  const { totalNetEquityValueUnvested, totalNetEquityValueVested } =
    useMemo(() => {
      return grantee.ctmsGrants.reduce<{
        totalNetEquityValueUnvested: null | number;
        totalNetEquityValueVested: null | number;
      }>(
        (acc, ctmsGrant) => {
          const netEquityValueVested = computeSharesNetEquityValue({
            exercisePrice: ctmsGrant.exercisePrice,
            shares: ctmsGrant.cumulativeVested,
          });

          const netEquityValueUnvested = computeSharesNetEquityValue({
            exercisePrice: ctmsGrant.exercisePrice,
            shares: ctmsGrant.quantityIssued - ctmsGrant.cumulativeVested,
          });

          const totalNetEquityValueVested =
            acc.totalNetEquityValueVested === null ||
            netEquityValueVested === null
              ? (acc.totalNetEquityValueVested ?? netEquityValueVested)
              : acc.totalNetEquityValueVested + netEquityValueVested;

          const totalNetEquityValueUnvested =
            acc.totalNetEquityValueUnvested === null ||
            netEquityValueUnvested === null
              ? (acc.totalNetEquityValueUnvested ?? netEquityValueUnvested)
              : acc.totalNetEquityValueUnvested + netEquityValueUnvested;

          return {
            totalNetEquityValueUnvested,
            totalNetEquityValueVested,
          };
        },
        {
          totalNetEquityValueUnvested: null,
          totalNetEquityValueVested: null,
        },
      );
    }, [grantee.ctmsGrants, computeSharesNetEquityValue]);

  return (
    <SectionHero id={id} ref={ref} withBorder>
      <div className="space-y-6">
        <div className="flex items-center gap-3">
          <BoxedIcon Icon={IconRocket} size="sm" tone="pink" />
          <Text variant="lgMedium">Vesting your units over time</Text>
        </div>
        <Text className="text-grey-600" variant="sm">
          The idea is to make you committed for the long run! 🤝 You will then
          progressively vest (unlock) your unit, month after month (or quarter
          after quarter) as you stay at the company, until you unlock 100% of
          them! You can see below how many units you have already vested
          (unlocked):
        </Text>
        {displayValuation && (
          <div className="flex flex-col gap-6 py-6 lg:flex-row lg:items-center">
            <div className="space-y-6 lg:flex-1">
              <Text variant="lgMedium">Your ownership projection</Text>
              <div className="h-[348px] w-full">
                <EmployeeVestingGraph
                  ctmsGrantsFragment={grantee.ctmsGrants}
                  groupBy="year"
                  organizationFragment={grantee.organization}
                  randomColorSeed={grantee.id}
                  valuationMultiple={valuationMultiple}
                  view="cumulative"
                />
              </div>
              <div className="text-center">
                <Pill tone="purple" type="subtle">
                  Projection at{" "}
                  <FormattedNumber animated value={valuationMultiple} />x
                </Pill>
              </div>
            </div>
            <div className="space-y-4">
              <div className="flex flex-col overflow-hidden rounded-2xl border-[0.5px] border-grey-300 text-center shadow-100 lg:w-[280px] lg:border-none">
                <div className="space-y-2 rounded-t-2xl border-[8px] border-white bg-purple-100 p-8">
                  {totalNetEquityValueVested !== null && (
                    <>
                      <Text variant="lgMedium">
                        Cumulative net equity value vested today
                      </Text>
                      <Text className="text-purple-700" variant="4xlMedium">
                        <FormattedCurrency
                          animated
                          maximumFractionDigits={0}
                          organizationFragment={grantee.organization}
                          value={totalNetEquityValueVested}
                        />
                      </Text>
                    </>
                  )}
                  <Text variant="lg">
                    <FormattedNumber
                      animated
                      value={grantee.totalVestedSharesBreakdown.total}
                    />{" "}
                    units
                  </Text>
                  <Pill
                    // @ts-expect-error: we have no other way to set the Pill background color
                    style={{ backgroundColor: "white" }}
                    tone="purple"
                    type="subtle"
                  >
                    At <FormattedNumber animated value={valuationMultiple} />x
                    valuation
                  </Pill>
                </div>
                {totalSharesGranted > 0 && totalSharesUnvested <= 0 ? (
                  <div className="flex items-start gap-2 px-8 py-6">
                    <LeftConfetti className="w-6 shrink-0" />
                    <Text variant="lgMedium">
                      All of your shares have{" "}
                      <span className="text-purple-700">vested.</span>
                    </Text>
                    <RightConfetti className="w-6 shrink-0" />
                  </div>
                ) : (
                  <div className="space-y-2 border-[8px] border-white bg-white p-8">
                    {totalNetEquityValueUnvested !== null && (
                      <>
                        <Text variant="lgMedium">
                          Cumulative net equity value unvested
                        </Text>
                        <Text className="text-purple-700" variant="4xlMedium">
                          <FormattedCurrency
                            animated
                            maximumFractionDigits={0}
                            organizationFragment={grantee.organization}
                            value={totalNetEquityValueUnvested}
                          />
                        </Text>
                      </>
                    )}
                    <Text variant="lg">
                      <FormattedNumber animated value={totalSharesUnvested} />{" "}
                      units
                    </Text>
                    <Pill tone="purple" type="subtle">
                      At <FormattedNumber animated value={valuationMultiple} />x
                      valuation
                    </Pill>
                  </div>
                )}
              </div>
              <HelpLink organizationFragment={grantee.organization} />
            </div>
          </div>
        )}
        <Text className="text-grey-600" variant="sm">
          Your company has set a specific schedule 🗓 (called the “vesting
          schedule”) defining when you vest (unlock) your units. Usually, you
          will not vest (unlock) any of them in your first year at the company,
          to be sure you’re fully onboard 🚢 before allowing you to own a slice
          of the company.
        </Text>
        <Divider />
        <div className="flex flex-col gap-6 py-6 lg:flex-row lg:items-center">
          <div className="space-y-6 lg:flex-1">
            <Text variant="lgMedium">Your vesting schedule</Text>
            <div className="flex justify-center">
              <Switch
                getOptionLabel={(option) => {
                  switch (option) {
                    case "cumulative":
                      return "Cumulative";
                    case "split":
                      return "Split view";
                  }
                }}
                getOptionValue={(option) => option}
                name="vesting-graph-view"
                onChange={setVestingScheduleView}
                options={["split", "cumulative"] as const}
                selectedOption={vestingScheduleView}
              />
            </div>

            <div className="h-[348px] w-full">
              <EmployeeVestingGraph
                ctmsGrantsFragment={grantee.ctmsGrants}
                groupBy="month"
                hideFullyDilutedValues={!displayValuation}
                organizationFragment={grantee.organization}
                randomColorSeed={grantee.id}
                valuationMultiple={valuationMultiple}
                view={vestingScheduleView}
              />
            </div>
            <div className="flex justify-center gap-6">
              <VestingScheduleLegend
                ctmsGrantsFragment={grantee.ctmsGrants}
                randomColorSeed={grantee.id}
                vestingScheduleView={vestingScheduleView}
              />
            </div>
          </div>
          {nextVestingEvent && (
            <div className="flex w-full flex-col overflow-hidden rounded-2xl border-[0.5px] border-grey-300 text-center shadow-100 lg:w-[280px] lg:border-none">
              <div className="space-y-2 p-8">
                <Text variant="lgMedium">Next vesting event</Text>
                {displayValuation &&
                  nextVestingEvent.additionalNetEquityValue !== null && (
                    <Text
                      style={{
                        color: getRandomColor(nextVestingEvent.ctmsGrantIndex),
                      }}
                      variant="4xlMedium"
                    >
                      <FormattedCurrency
                        animated
                        maximumFractionDigits={0}
                        organizationFragment={grantee.organization}
                        value={nextVestingEvent.additionalNetEquityValue}
                      />
                    </Text>
                  )}
                <Text variant="lg">
                  <FormattedNumber
                    animated
                    value={nextVestingEvent.additionalVestedShares}
                  />{" "}
                  <SharesUnitLabel
                    awardSuperType={
                      grantee.ctmsGrants[nextVestingEvent.ctmsGrantIndex]
                        ?.matchingInstrument?.awardSuperType ?? null
                    }
                  />
                </Text>
                <div className="space-y-0.5">
                  <Text className="text-grey-500" variant="xs">
                    Event date:
                  </Text>
                  <Text variant="smMedium">
                    <ShortDate value={nextVestingEvent.date} />
                  </Text>
                </div>
                <ColorSquareLegend
                  color={getRandomColor(nextVestingEvent.ctmsGrantIndex)}
                >
                  {grantee.ctmsGrants[nextVestingEvent.ctmsGrantIndex]?.label}
                </ColorSquareLegend>
              </div>
              {followingVestingEvent && (
                <>
                  <Divider />
                  <div className="space-y-2 p-8">
                    <Text variant="lgMedium">Following vesting event</Text>
                    {displayValuation &&
                      followingVestingEvent.additionalNetEquityValue !==
                        null && (
                        <Text
                          style={{
                            color: getRandomColor(
                              followingVestingEvent.ctmsGrantIndex,
                            ),
                          }}
                          variant="4xlMedium"
                        >
                          <FormattedCurrency
                            animated
                            maximumFractionDigits={0}
                            organizationFragment={grantee.organization}
                            value={
                              followingVestingEvent.additionalNetEquityValue
                            }
                          />
                        </Text>
                      )}
                    <Text variant="lg">
                      <FormattedNumber
                        animated
                        value={followingVestingEvent.additionalVestedShares}
                      />{" "}
                      <SharesUnitLabel
                        awardSuperType={
                          grantee.ctmsGrants[nextVestingEvent.ctmsGrantIndex]
                            ?.matchingInstrument?.awardSuperType ?? null
                        }
                      />
                    </Text>
                    <div className="space-y-0.5">
                      <Text className="text-grey-500" variant="xs">
                        Event date:
                      </Text>
                      <Text variant="smMedium">
                        <ShortDate value={followingVestingEvent.date} />
                      </Text>
                    </div>
                    <ColorSquareLegend
                      color={getRandomColor(
                        followingVestingEvent.ctmsGrantIndex,
                      )}
                    >
                      {
                        grantee.ctmsGrants[followingVestingEvent.ctmsGrantIndex]
                          ?.label
                      }
                    </ColorSquareLegend>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </SectionHero>
  );
});
