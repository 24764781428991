import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { EmployeeVestingGraph_CTMSGrants$key } from "./__generated__/EmployeeVestingGraph_CTMSGrants.graphql";
import { EmployeeVestingGraph_DraftGrants$key } from "./__generated__/EmployeeVestingGraph_DraftGrants.graphql";
import { EmployeeVestingGraph_Organization$key } from "./__generated__/EmployeeVestingGraph_Organization.graphql";
import { VestingGraph } from "./VestingGraph";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment EmployeeVestingGraph_CTMSGrants on CTMSGrant @relay(plural: true) {
    __typename
    label
    exercisePrice
    vestingDataPoints {
      cumulativeVested
      date
    }
  }
`;

const DRAFT_GRANT_FRAGMENT = graphql`
  fragment EmployeeVestingGraph_DraftGrants on EasopGrant @relay(plural: true) {
    __typename
    label
    exercisePrice
    vestingDataPoints {
      cumulativeVested
      date
    }
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment EmployeeVestingGraph_Organization on Organization {
    latestPricePerShare {
      __typename
    }
    ...VestingGraph_Organization
  }
`;

type GROUP_BY = "month" | "year";

export const EmployeeVestingGraph: React.FC<{
  ctmsGrantsFragment?: EmployeeVestingGraph_CTMSGrants$key;
  draftGrantsFragment?: EmployeeVestingGraph_DraftGrants$key;
  groupBy: GROUP_BY;
  hideFullyDilutedValues?: boolean;
  organizationFragment: EmployeeVestingGraph_Organization$key;
  randomColorSeed: string;
  valuationMultiple?: number;
  view: "cumulative" | "split";
}> = ({
  ctmsGrantsFragment,
  draftGrantsFragment,
  groupBy,
  hideFullyDilutedValues,
  organizationFragment,
  randomColorSeed,
  valuationMultiple = 1,
  view,
}) => {
  const ctmsGrants = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantsFragment);
  const draftGrants = useFragment(DRAFT_GRANT_FRAGMENT, draftGrantsFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const grants = useMemo(() => {
    return [...(ctmsGrants ?? []), ...(draftGrants ?? [])].map((grant) => ({
      exercisePrice: grant.exercisePrice,
      label: grant.label,
      vestingDataPoints: grant.vestingDataPoints.map((dataPoint) => ({
        cumulativeVested: dataPoint.cumulativeVested,
        date: new Date(dataPoint.date),
      })),
    }));
  }, [ctmsGrants, draftGrants]);

  const displayValuation = Boolean(
    organization.latestPricePerShare && !hideFullyDilutedValues,
  );

  return (
    <VestingGraph
      displayValuation={displayValuation}
      grants={grants}
      groupBy={groupBy}
      organizationFragment={organization}
      randomColorSeed={randomColorSeed}
      valuationMultiple={valuationMultiple}
      view={view}
    />
  );
};
