import { isNaN, isNull } from "lodash";
import React, { useState } from "react";
import { useFragment } from "react-relay";
import { generatePath, useSearchParams } from "react-router-dom";
import { graphql } from "relay-runtime";

import { ExerciseRequestTimelineSection } from "../../../components/ExerciseRequestTimelineSection";
import { Page } from "../../../components/Page";
import { CenteredColumnLayout } from "../../../components/ui/Layout/CenteredColumnLayout";
import { useOrganizationCTMS } from "../../../hooks/useOrganizationCTMS";
import { useQuery } from "../../../hooks/useQuery";
import {
  APPLICATION_ROUTES,
  useCtmsGrantIdParam,
  useOrganizationIdParam,
} from "../../../paths";
import { getEquityTypeWorkRelationship } from "../../../services/workRelationship";
import NotFoundPage from "../../NotFound/NotFound";
import { ExerciseRequestSimulation_CTMSGrant$key } from "./__generated__/ExerciseRequestSimulation_CTMSGrant.graphql";
import { ExerciseRequestSimulation_Organization$key } from "./__generated__/ExerciseRequestSimulation_Organization.graphql";
import { ExerciseRequestSimulation_Query } from "./__generated__/ExerciseRequestSimulation_Query.graphql";
import { ExerciseRequestSimulation_Viewer$key } from "./__generated__/ExerciseRequestSimulation_Viewer.graphql";
import { ExerciseRequestApprovalSection } from "./ExerciseRequestApprovalSection";
import { ExerciseRequestGranteeSection } from "./ExerciseRequestGranteeSection";
import { ExerciseRequestNotifySection } from "./ExerciseRequestNotifySection";
import { ExerciseRequestTaxTreatmentSection } from "./ExerciseRequestTaxTreatmentSection";
import { useExerciseRequestSteps } from "./useExerciseRequestSteps";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment ExerciseRequestSimulation_CTMSGrant on CTMSGrant {
    exercisePrice @required(action: THROW)
    grantee {
      id
      workRelationship
    }
    ...ExerciseRequestGranteeSection_CTMSGrant
    ...ExerciseRequestTaxTreatmentSection_CTMSGrant
    ...ExerciseRequestApprovalSection_CTMSGrant
    ...ExerciseRequestNotifySection_CTMSGrant
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment ExerciseRequestSimulation_Organization on Organization {
    id
    ...ExerciseRequestTaxTreatmentSection_Organization
    ...ExerciseRequestApprovalSection_Organization
    ...ExerciseRequestNotifySection_Organization
    ...useOrganizationCTMS_Organization
    latestFairMarketValue @required(action: THROW) {
      value
      ...ExerciseRequestTaxTreatmentSection_FairMarketValueAtExercise
      ...ExerciseRequestNotifySection_FairMarketValueAtExercise
    }
    ...ExerciseRequestGranteeSection_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment ExerciseRequestSimulation_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    ...ExerciseRequestTaxTreatmentSection_Viewer
      @arguments(organizationId: $organizationId)
    ...ExerciseRequestApprovalSection_Viewer
      @arguments(organizationId: $organizationId)
    ...ExerciseRequestNotifySection_Viewer
      @arguments(organizationId: $organizationId)
  }
`;

const AdminExerciseRequestSimulationPage_: React.FC<{
  ctmsGrantFragment: ExerciseRequestSimulation_CTMSGrant$key;
  organizationFragment: ExerciseRequestSimulation_Organization$key;
  quantityExercised: number;
  viewerFragment: ExerciseRequestSimulation_Viewer$key;
}> = ({
  ctmsGrantFragment,
  organizationFragment,
  quantityExercised,
  viewerFragment,
}) => {
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const { workRelationship } = ctmsGrant.grantee;

  const equityTypeWorkRelationshipCategory = workRelationship
    ? getEquityTypeWorkRelationship(workRelationship).slug
    : null;
  const organizationCTMS = useOrganizationCTMS({
    organizationFragment: organization,
  });
  const steps = useExerciseRequestSteps({
    ctmsName: organizationCTMS?.name as null | string,
    equityTypeWorkRelationshipCategory,
  });
  const [taxResidenceCountryIsKnown, setTaxResidenceCountryIsKnown] =
    useState(true);
  const [requestSubmittedAt] = useState(() => new Date());
  const spread =
    (organization.latestFairMarketValue.value - ctmsGrant.exercisePrice) *
    quantityExercised;
  const totalExercisePrice = ctmsGrant.exercisePrice * quantityExercised;
  const exercisePrice = ctmsGrant.exercisePrice;

  return (
    <CenteredColumnLayout
      backButton={{
        label: "Back to Grantee",
        linkTo: generatePath(APPLICATION_ROUTES.organizationGrantee, {
          granteeId: ctmsGrant.grantee.id,
          organizationId: organization.id,
        }),
      }}
      maxWidth="690"
    >
      <div>
        <ExerciseRequestTimelineSection className="mt-6 h-10" />
        <ExerciseRequestGranteeSection
          ctmsGrantFragment={ctmsGrant}
          exerciseFundsReceivedAt={null}
          organizationFragment={organization}
          quantityExercised={quantityExercised}
          requestSubmittedAt={requestSubmittedAt}
          simulated
          spread={spread}
          totalExercisePrice={totalExercisePrice}
        />
        {steps.TaxTreatment && (
          <ExerciseRequestTaxTreatmentSection
            ctmsGrantFragment={ctmsGrant}
            currentStep={steps.TaxTreatment.current}
            exercisePrice={exercisePrice}
            fairMarketValueAtExerciseFragment={
              organization.latestFairMarketValue
            }
            nextStep={steps.TaxTreatment.next}
            onTaxResidenceCountryIsKnownChange={setTaxResidenceCountryIsKnown}
            organizationFragment={organization}
            quantityExercised={quantityExercised}
            spread={spread}
            taxResidenceCountryIsKnown={taxResidenceCountryIsKnown}
            totalExercisePrice={totalExercisePrice}
            totalSteps={steps.total}
            viewerFragment={viewer}
          />
        )}
        {steps.Approval && (
          <ExerciseRequestApprovalSection
            ctmsGrantFragment={ctmsGrant}
            currentStep={steps.Approval.current}
            nextStep={steps.Approval.next}
            organizationFragment={organization}
            spread={spread}
            taxResidenceCountryIsKnown={taxResidenceCountryIsKnown}
            totalSteps={steps.total}
            viewerFragment={viewer}
          />
        )}
        {steps.Notify && (
          <ExerciseRequestNotifySection
            ctmsGrantFragment={ctmsGrant}
            currentStep={steps.Notify.current}
            exercisePrice={exercisePrice}
            fairMarketValueAtExerciseFragment={
              organization.latestFairMarketValue
            }
            organizationFragment={organization}
            quantityExercised={quantityExercised}
            spread={spread}
            taxResidenceCountryIsKnown={taxResidenceCountryIsKnown}
            totalExercisePrice={totalExercisePrice}
            totalSteps={steps.total}
            viewerFragment={viewer}
          />
        )}
      </div>
    </CenteredColumnLayout>
  );
};

const QUERY = graphql`
  query ExerciseRequestSimulation_Query(
    $organizationId: OrganizationId!
    $ctmsGrantId: CtmsGrantId!
  ) {
    me {
      ...ExerciseRequestSimulation_Viewer
        @arguments(organizationId: $organizationId)
    }
    organization(id: $organizationId) {
      name
      id
      ...ExerciseRequestSimulation_Organization
    }
    ctmsGrant(id: $ctmsGrantId) {
      id
      label
      ...ExerciseRequestSimulation_CTMSGrant
    }
  }
`;

const AdminExerciseRequestSimulationPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const ctmsGrantId = useCtmsGrantIdParam();
  const [searchParams] = useSearchParams();
  const quantityExercisedSearchParam = searchParams.get("quantityExercised");
  const quantityExercised = quantityExercisedSearchParam
    ? parseInt(quantityExercisedSearchParam)
    : null;

  const { query } = useQuery<ExerciseRequestSimulation_Query>(QUERY, {
    ctmsGrantId,
    organizationId,
  });

  if (
    !query.organization ||
    !query.ctmsGrant ||
    isNaN(quantityExercised) ||
    isNull(quantityExercised)
  ) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Exercises"
      analyticsName="Admin - Exercise Request Simulation"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} exercise request simulation ${query.ctmsGrant.label}`}
    >
      <AdminExerciseRequestSimulationPage_
        ctmsGrantFragment={query.ctmsGrant}
        organizationFragment={query.organization}
        quantityExercised={quantityExercised}
        viewerFragment={query.me}
      />
    </Page>
  );
};

export default AdminExerciseRequestSimulationPage;
