import React, { useMemo } from "react";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useOrganizationSharesUtil } from "../hooks/useOrganizationSharesUtil";
import { SharesValue_Organization$key } from "./__generated__/SharesValue_Organization.graphql";
import { FormattedPercentage } from "./Formatted/FormattedPercentage";
import { FormattedCurrencyParts } from "./FormattedCurrencyParts";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment SharesValue_Organization on Organization {
    ...useOrganizationSharesUtil_Organization
    ...FormattedCurrencyParts_Organization
  }
`;

export const SHARES_VALUE_MODES = [
  "SHARES",
  "FULLY_DILUTED",
  "USD_VALUE",
] as const;

export const SHARES_VALUE_MODE_LABELS: Record<
  (typeof SHARES_VALUE_MODES)[number],
  {
    sharesLabel: string;
    virtualSharesLabel: string;
  }
> = {
  FULLY_DILUTED: {
    sharesLabel: "% Fully diluted",
    virtualSharesLabel: "% Fully diluted",
  },
  SHARES: {
    sharesLabel: "# Shares",
    virtualSharesLabel: "# SARs",
  },
  USD_VALUE: {
    sharesLabel: "$ Value",
    virtualSharesLabel: "$ Value",
  },
};

export const getSharesValueModeLabel = (mode: SharesValueMode) =>
  SHARES_VALUE_MODE_LABELS[mode].sharesLabel;

export type SharesValueMode = (typeof SHARES_VALUE_MODES)[number];

const FormattedCurrencyPartsVariants: Record<
  React.ComponentProps<typeof SharesValue>["variant"],
  Pick<
    React.ComponentProps<typeof FormattedCurrencyParts>,
    "defaultVariant" | "fractionVariant"
  >
> = {
  "Medium/Extra Large": {
    defaultVariant: "Medium/Extra Large",
    fractionVariant: "Medium/Default",
  },
  "Medium/Extra Small": {
    defaultVariant: "Medium/Extra Small",
    fractionVariant: "Medium/Caption",
  },
  "Medium/Small": {
    defaultVariant: "Medium/Small",
    fractionVariant: "Medium/Caption",
  },
  "Regular/Extra Large": {
    defaultVariant: "Regular/Extra Large",
    fractionVariant: "Regular/Default",
  },
  "Regular/Extra Small": {
    defaultVariant: "Regular/Extra Small",
    fractionVariant: "Regular/Caption",
  },
  "Regular/Small": {
    defaultVariant: "Regular/Small",
    fractionVariant: "Regular/Caption",
  },
};

export const SharesValue: React.FC<{
  appendSharesLabel?: boolean;
  className?: string;
  mode: SharesValueMode;
  organizationFragment: SharesValue_Organization$key;
  shares: number;
  signDisplay?: "always" | "auto" | "never";
  variant:
    | "Medium/Extra Large"
    | "Medium/Extra Small"
    | "Medium/Small"
    | "Regular/Extra Large"
    | "Regular/Extra Small"
    | "Regular/Small";
}> = ({
  appendSharesLabel,
  className,
  mode,
  organizationFragment,
  shares,
  signDisplay,
  variant,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const { sharesToFullyDilutedRatio, sharesToValue } =
    useOrganizationSharesUtil({ organizationFragment: organization });

  const usdValue = sharesToValue(shares);
  const ownership = sharesToFullyDilutedRatio(shares);

  const label = useMemo(() => {
    switch (mode) {
      case "FULLY_DILUTED":
        if (ownership === null) return null;
        return (
          <FormattedPercentage signDisplay={signDisplay} value={ownership} />
        );
      case "SHARES":
        return (
          <>
            <FormattedNumber signDisplay={signDisplay} value={shares} />
            {appendSharesLabel && " shares"}
          </>
        );
      case "USD_VALUE":
        if (usdValue === null) return null;
        return (
          <FormattedCurrencyParts
            defaultVariant={
              FormattedCurrencyPartsVariants[variant].defaultVariant
            }
            fractionVariant={
              FormattedCurrencyPartsVariants[variant].fractionVariant
            }
            organizationFragment={organization}
            signDisplay={signDisplay}
            value={usdValue}
          />
        );
    }
  }, [
    shares,
    appendSharesLabel,
    organization,
    mode,
    variant,
    ownership,
    usdValue,
    signDisplay,
  ]);

  return (
    <Typography className={className} variant={variant}>
      {label}
    </Typography>
  );
};
