/**
 * @generated SignedSource<<9c0994d3efa27b22b9df28a2066fffe8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EmployeeDetailsTopBarActions_Viewer$data = {
  readonly isAllowedToManageOrganization: boolean;
  readonly " $fragmentType": "EmployeeDetailsTopBarActions_Viewer";
};
export type EmployeeDetailsTopBarActions_Viewer$key = {
  readonly " $data"?: EmployeeDetailsTopBarActions_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeeDetailsTopBarActions_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmployeeDetailsTopBarActions_Viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "organizationId",
          "variableName": "organizationId"
        }
      ],
      "kind": "ScalarField",
      "name": "isAllowedToManageOrganization",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "86e1f1ce814cb5eef8f8cc8baf998978";

export default node;
