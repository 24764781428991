import { IconV2DuotoneBookOpen } from "@remote-com/norma/icons/IconV2DuotoneBookOpen";
import { IconV2DuotoneBriefcase } from "@remote-com/norma/icons/IconV2DuotoneBriefcase";
import { IconV2DuotoneChartBar } from "@remote-com/norma/icons/IconV2DuotoneChartBar";
import { IconV2DuotoneClipboard } from "@remote-com/norma/icons/IconV2DuotoneClipboard";
import { IconV2DuotoneColumns2 } from "@remote-com/norma/icons/IconV2DuotoneColumns2";
import { IconV2DuotoneCopy } from "@remote-com/norma/icons/IconV2DuotoneCopy";
import { IconV2DuotoneEditUnderline } from "@remote-com/norma/icons/IconV2DuotoneEditUnderline";
import { IconV2DuotoneFilesText } from "@remote-com/norma/icons/IconV2DuotoneFilesText";
import { IconV2DuotoneFolderOpen } from "@remote-com/norma/icons/IconV2DuotoneFolderOpen";
import { IconV2DuotoneGlobe } from "@remote-com/norma/icons/IconV2DuotoneGlobe";
import { IconV2DuotoneHome } from "@remote-com/norma/icons/IconV2DuotoneHome";
import { IconV2DuotoneMail } from "@remote-com/norma/icons/IconV2DuotoneMail";
import { IconV2DuotonePieChart } from "@remote-com/norma/icons/IconV2DuotonePieChart";
import { IconV2DuotonePlus } from "@remote-com/norma/icons/IconV2DuotonePlus";
import { IconV2DuotoneUmbrella } from "@remote-com/norma/icons/IconV2DuotoneUmbrella";
import { IconV2DuotoneUserCheck } from "@remote-com/norma/icons/IconV2DuotoneUserCheck";
import { IconV2DuotoneUsers } from "@remote-com/norma/icons/IconV2DuotoneUsers";
import { IconV2DuotoneZap } from "@remote-com/norma/icons/IconV2DuotoneZap";
import { IconV2OutlineBookOpen } from "@remote-com/norma/icons/IconV2OutlineBookOpen";
import { IconV2OutlineBriefcase } from "@remote-com/norma/icons/IconV2OutlineBriefcase";
import { IconV2OutlineChartBar } from "@remote-com/norma/icons/IconV2OutlineChartBar";
import { IconV2OutlineClipboard } from "@remote-com/norma/icons/IconV2OutlineClipboard";
import { IconV2OutlineColumns2 } from "@remote-com/norma/icons/IconV2OutlineColumns2";
import { IconV2OutlineCopy } from "@remote-com/norma/icons/IconV2OutlineCopy";
import { IconV2OutlineEditUnderline } from "@remote-com/norma/icons/IconV2OutlineEditUnderline";
import { IconV2OutlineFilesText } from "@remote-com/norma/icons/IconV2OutlineFilesText";
import { IconV2OutlineFolderOpen } from "@remote-com/norma/icons/IconV2OutlineFolderOpen";
import { IconV2OutlineGlobe } from "@remote-com/norma/icons/IconV2OutlineGlobe";
import { IconV2OutlineHome } from "@remote-com/norma/icons/IconV2OutlineHome";
import { IconV2OutlineMail } from "@remote-com/norma/icons/IconV2OutlineMail";
import { IconV2OutlinePieChart } from "@remote-com/norma/icons/IconV2OutlinePieChart";
import { IconV2OutlinePlus } from "@remote-com/norma/icons/IconV2OutlinePlus";
import { IconV2OutlineUmbrella } from "@remote-com/norma/icons/IconV2OutlineUmbrella";
import { IconV2OutlineUserCheck } from "@remote-com/norma/icons/IconV2OutlineUserCheck";
import { IconV2OutlineUsers } from "@remote-com/norma/icons/IconV2OutlineUsers";
import { IconV2OutlineZap } from "@remote-com/norma/icons/IconV2OutlineZap";
import { sumBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { graphql, useFragment } from "react-relay";
import { generatePath, useMatch } from "react-router-dom";

import { NonEmptyArray } from "../../helpers/ts-utlity";
import useIsFeatureEnabled from "../../hooks/useIsFeatureEnabled";
import { GRANT_STATUS_LABEL_MAP } from "../../layouts/AdminLayout/GRANT_STATUS_LABEL_MAP";
import { APPLICATION_ROUTES } from "../../paths";
import { ApplicationSideBar } from "../ui/ApplicationSideBar/ApplicationSideBar";
import { PoweredByCarta } from "../ui/PoweredByCarta/PoweredByCarta";
import {
  AdvancedApplicationSidebar_Organization$data,
  AdvancedApplicationSidebar_Organization$key,
} from "./__generated__/AdvancedApplicationSidebar_Organization.graphql";
import { AdvancedApplicationSidebar_Viewer$key } from "./__generated__/AdvancedApplicationSidebar_Viewer.graphql";

const VIEWER_FRAGMENT = graphql`
  fragment AdvancedApplicationSidebar_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    ...ApplicationSideBar_Viewer
    isAllowedToManageOrganization(organizationId: $organizationId)
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment AdvancedApplicationSidebar_Organization on Organization {
    id
    granteeCleanupSuggestionsCount
    planIsFreemium
    shouldShowExerciseHandler
    adminInvitationIsAllowed
    pendingApprovalFairMarketValueBoardConsents {
      __typename
    }
    fairMarketValueHistory {
      status
    }
    isConnectedToCarta
    isOriginatingFromRemoteEquity

    draftEasopGrants: easopGrants(filters: { statusIn: [Draft] }) {
      __typename
    }
    pendingReviewEasopGrants: easopGrants(
      filters: { statusIn: [PendingReview] }
    ) {
      __typename
    }
    reviewedEasopGrants: easopGrants(filters: { statusIn: [Reviewed] }) {
      __typename
    }
    pendingBoardConsentEasopGrants: easopGrants(
      filters: { statusIn: [PendingBoardConsent] }
    ) {
      __typename
    }
    pendingCtmsImplementationEasopGrants: easopGrants(
      filters: { statusIn: [PendingCtmsImplementation] }
    ) {
      __typename
    }

    underReviewGranteeTerminationCTMSGrantAmendmentRequests: granteeTerminationCTMSGrantAmendmentRequests(
      statusIn: [UNDER_REVIEW]
    ) {
      __typename
    }
    readyForConsentGranteeTerminationCTMSGrantAmendmentRequests: granteeTerminationCTMSGrantAmendmentRequests(
      statusIn: [READY_FOR_CONSENT]
    ) {
      __typename
    }
    pendingApprovalGranteeTerminationCTMSGrantAmendmentRequests: granteeTerminationCTMSGrantAmendmentRequests(
      statusIn: [PENDING_APPROVAL]
    ) {
      __typename
    }
    implementationGranteeTerminationCTMSGrantAmendmentRequests: granteeTerminationCTMSGrantAmendmentRequests(
      statusIn: [IMPLEMENTATION]
    ) {
      __typename
    }

    underReviewCTMSGrantAmendmentRequests: ctmsGrantAmendmentRequests(
      statusIn: [UNDER_REVIEW]
    ) {
      __typename
    }
    readyForConsentCTMSGrantAmendmentRequests: ctmsGrantAmendmentRequests(
      statusIn: [READY_FOR_CONSENT]
    ) {
      __typename
    }
    pendingApprovalCTMSGrantAmendmentRequests: ctmsGrantAmendmentRequests(
      statusIn: [PENDING_APPROVAL]
    ) {
      __typename
    }
    implementationCTMSGrantAmendmentRequests: ctmsGrantAmendmentRequests(
      statusIn: [IMPLEMENTATION]
    ) {
      __typename
    }
    ...ApplicationSideBar_Organization
  }
`;

interface GrantCategory {
  color: string;
  counter: number;
  label: string;
  route:
    | "organizationCTMSGrantAmendmentRequestsBoardApproval"
    | "organizationCTMSGrantAmendmentRequestsImplementation"
    | "organizationCTMSGrantAmendmentRequestsPendingApproval"
    | "organizationCTMSGrantAmendmentRequestsUnderReview"
    | "organizationGranteeTerminationCTMSGrantAmendmentRequestsBoardApproval"
    | "organizationGranteeTerminationCTMSGrantAmendmentRequestsImplementation"
    | "organizationGranteeTerminationCTMSGrantAmendmentRequestsPendingApproval"
    | "organizationGranteeTerminationCTMSGrantAmendmentRequestsUnderReview"
    | "organizationPrepareYourGrantsBoardApproval"
    | "organizationPrepareYourGrantsDrafts"
    | "organizationPrepareYourGrantsPendingApproval"
    | "organizationPrepareYourGrantsPendingImplementation"
    | "organizationPrepareYourGrantsUnderReview";
}

export const AdvancedApplicationSidebar = ({
  onCreateNewButtonClick,
  onInviteAdminButtonClick,
  organizationFragment,
  viewerFragment,
}: {
  onCreateNewButtonClick: () => void;
  onInviteAdminButtonClick: () => void;
  organizationFragment: AdvancedApplicationSidebar_Organization$key;
  viewerFragment: AdvancedApplicationSidebar_Viewer$key;
}) => {
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const newGranteePortalFeatureIsEnabled =
    useIsFeatureEnabled("newGranteePortal");

  const isEquityHandbooksEnabledForAdvancedOrganizations = useIsFeatureEnabled(
    "equityHandbooksForAdvancedOrganizations",
  );

  const grantCategories: NonEmptyArray<GrantCategory> = useMemo(() => {
    return [
      {
        color: "bg-green",
        counter: organization.draftEasopGrants.length,
        label: "Draft",
        route: "organizationPrepareYourGrantsDrafts",
      },
      {
        color: "bg-primary",
        counter: organization.pendingReviewEasopGrants.length,
        label: "Under review",
        route: "organizationPrepareYourGrantsUnderReview",
      },
      {
        color: "bg-purple",
        counter: organization.reviewedEasopGrants.length,
        label: "Board approval",
        route: "organizationPrepareYourGrantsBoardApproval",
      },
      {
        color: "bg-orange",
        counter: organization.pendingBoardConsentEasopGrants.length,
        label: "Pending",
        route: "organizationPrepareYourGrantsPendingApproval",
      },
      {
        color: "bg-yellow",
        counter: organization.pendingCtmsImplementationEasopGrants.length,
        label: "Implementation",
        route: "organizationPrepareYourGrantsPendingImplementation",
      },
    ];
  }, [
    organization.draftEasopGrants.length,
    organization.pendingBoardConsentEasopGrants.length,
    organization.pendingCtmsImplementationEasopGrants.length,
    organization.pendingReviewEasopGrants.length,
    organization.reviewedEasopGrants.length,
  ]);

  const grantCount = useMemo(
    () => sumBy(grantCategories, (grantCategory) => grantCategory.counter),
    [grantCategories],
  );

  const granteeTerminationGrantAmendmentCategories: NonEmptyArray<GrantCategory> =
    useMemo(() => {
      return [
        {
          color: "bg-primary",
          counter:
            organization.underReviewGranteeTerminationCTMSGrantAmendmentRequests
              .length,
          label: GRANT_STATUS_LABEL_MAP.PendingReview,
          route:
            "organizationGranteeTerminationCTMSGrantAmendmentRequestsUnderReview",
        },
        {
          color: "bg-purple",
          counter:
            organization
              .readyForConsentGranteeTerminationCTMSGrantAmendmentRequests
              .length,
          label: GRANT_STATUS_LABEL_MAP.Reviewed,
          route:
            "organizationGranteeTerminationCTMSGrantAmendmentRequestsBoardApproval",
        },
        {
          color: "bg-orange",
          counter:
            organization
              .pendingApprovalGranteeTerminationCTMSGrantAmendmentRequests
              .length,
          label: GRANT_STATUS_LABEL_MAP.PendingBoardConsent,
          route:
            "organizationGranteeTerminationCTMSGrantAmendmentRequestsPendingApproval",
        },
        {
          color: "bg-yellow",
          counter:
            organization
              .implementationGranteeTerminationCTMSGrantAmendmentRequests
              .length,
          label: GRANT_STATUS_LABEL_MAP.PendingCtmsImplementation,
          route:
            "organizationGranteeTerminationCTMSGrantAmendmentRequestsImplementation",
        },
      ];
    }, [
      organization.underReviewGranteeTerminationCTMSGrantAmendmentRequests
        .length,
      organization.implementationGranteeTerminationCTMSGrantAmendmentRequests
        .length,
      organization.pendingApprovalGranteeTerminationCTMSGrantAmendmentRequests
        .length,
      organization.readyForConsentGranteeTerminationCTMSGrantAmendmentRequests
        .length,
    ]);

  const granteeTerminationGrantAmendmentCount = useMemo(
    () =>
      sumBy(
        granteeTerminationGrantAmendmentCategories,
        (category) => category.counter,
      ),
    [granteeTerminationGrantAmendmentCategories],
  );

  const grantAmendmentCategories: NonEmptyArray<GrantCategory> = useMemo(() => {
    return [
      {
        color: "bg-primary",
        counter: organization.underReviewCTMSGrantAmendmentRequests.length,
        label: GRANT_STATUS_LABEL_MAP.PendingReview,
        route: "organizationCTMSGrantAmendmentRequestsUnderReview",
      },
      {
        color: "bg-purple",
        counter: organization.readyForConsentCTMSGrantAmendmentRequests.length,
        label: GRANT_STATUS_LABEL_MAP.Reviewed,
        route: "organizationCTMSGrantAmendmentRequestsBoardApproval",
      },
      {
        color: "bg-orange",
        counter: organization.pendingApprovalCTMSGrantAmendmentRequests.length,
        label: GRANT_STATUS_LABEL_MAP.PendingBoardConsent,
        route: "organizationCTMSGrantAmendmentRequestsPendingApproval",
      },
      {
        color: "bg-yellow",
        counter: organization.implementationCTMSGrantAmendmentRequests.length,
        label: GRANT_STATUS_LABEL_MAP.PendingCtmsImplementation,
        route: "organizationCTMSGrantAmendmentRequestsImplementation",
      },
    ];
  }, [
    organization.underReviewCTMSGrantAmendmentRequests.length,
    organization.implementationCTMSGrantAmendmentRequests.length,
    organization.pendingApprovalCTMSGrantAmendmentRequests.length,
    organization.readyForConsentCTMSGrantAmendmentRequests.length,
  ]);

  const showCleanupGranteesLink =
    viewer.isAllowedToManageOrganization &&
    organization.granteeCleanupSuggestionsCount > 0;

  const grantAmendmentCount = useMemo(
    () => sumBy(grantAmendmentCategories, (category) => category.counter),
    [grantAmendmentCategories],
  );

  const fairMarketValuesPendingReviewCount =
    organization.fairMarketValueHistory.filter(
      (fairMarketValue) => fairMarketValue.status === "PendingReview",
    ).length;

  const fairMarketValuesReviewedCount =
    organization.fairMarketValueHistory.filter(
      (fairMarketValue) => fairMarketValue.status === "Reviewed",
    ).length;

  const fairMarketValueApprovalCount =
    organization.pendingApprovalFairMarketValueBoardConsents.length;

  const fairMarketValuesBeingApprovedCount =
    fairMarketValuesPendingReviewCount +
    fairMarketValuesReviewedCount +
    fairMarketValueApprovalCount;

  const isOnDocumentsSection = !!useMatch(
    `/organizations/:organizationId/documents/*`,
  );

  const isOnSettingsSection = !!useMatch(
    `/organizations/:organizationId/settings/*`,
  );

  const isOnGrantEquitySection = !!useMatch(
    `/organizations/:organizationId/prepare-your-grants/*`,
  );

  const isOnGranteeTerminationAmendEquitySection = !!useMatch(
    `/organizations/:organizationId/grantee-termination-grant-amendment-requests/*`,
  );

  const isOnAmendEquitySection = !!useMatch(
    `/organizations/:organizationId/grant-amendment-requests/*`,
  );

  const isOnFairMarketValueApprovalSection = !!useMatch(
    `/organizations/:organizationId/fair-market-value-approvals/*`,
  );

  const isOnEquity = !!useMatch(`/organizations/:organizationId/equity/*`);

  const [unfoldedSection, setUnfoldedSection] = useState<
    | "amend-equity"
    | "documents"
    | "equity"
    | "fair_market_value_approval"
    | "grant-equity"
    | "grantee-termination-amend-equity"
    | "settings"
    | null
  >(null);

  useEffect(() => {
    if (isOnDocumentsSection) {
      setUnfoldedSection("documents");
      return;
    }
    if (isOnSettingsSection) {
      setUnfoldedSection("settings");
      return;
    }
    if (isOnGrantEquitySection) {
      setUnfoldedSection("grant-equity");
      return;
    }
    if (isOnGranteeTerminationAmendEquitySection) {
      setUnfoldedSection("grantee-termination-amend-equity");
      return;
    }
    if (isOnAmendEquitySection) {
      setUnfoldedSection("amend-equity");
      return;
    }
    if (isOnEquity) {
      setUnfoldedSection("equity");
      return;
    }
    if (isOnFairMarketValueApprovalSection) {
      setUnfoldedSection("fair_market_value_approval");
      return;
    }
    setUnfoldedSection(null);
  }, [
    isOnDocumentsSection,
    isOnSettingsSection,
    isOnGrantEquitySection,
    isOnGranteeTerminationAmendEquitySection,
    isOnAmendEquitySection,
    isOnEquity,
    isOnFairMarketValueApprovalSection,
  ]);

  return (
    <ApplicationSideBar
      activeRole="Admin"
      bottomFixedContent={
        organization.isConnectedToCarta && (
          <PoweredByCarta className="m-auto h-full" />
        )
      }
      homePath={generatePath(APPLICATION_ROUTES.organizationHome, {
        organizationId: organization.id,
      })}
      organizationFragment={organization}
      viewerFragment={viewer}
    >
      <div className="flex flex-col gap-[1px]">
        <ApplicationSideBar.NavLink
          end
          icons={{
            ActiveIcon: <IconV2DuotoneColumns2 />,
            Icon: <IconV2OutlineColumns2 />,
          }}
          to={generatePath(APPLICATION_ROUTES.organizationHome, {
            organizationId: organization.id,
          })}
        >
          Overview
        </ApplicationSideBar.NavLink>
        {showCleanupGranteesLink && (
          <ApplicationSideBar.NavLink
            count={organization.granteeCleanupSuggestionsCount}
            icons={{
              ActiveIcon: <IconV2DuotoneUserCheck />,
              Icon: <IconV2OutlineUserCheck />,
            }}
            to={generatePath(
              APPLICATION_ROUTES.organizationSettingsCleanupGrantees,
              {
                organizationId: organization.id,
              },
            )}
          >
            <CleanUpGranteesLabel organization={organization} />
          </ApplicationSideBar.NavLink>
        )}
        {organization.adminInvitationIsAllowed && (
          <ApplicationSideBar.NavButton
            icons={{
              ActiveIcon: <IconV2DuotoneMail />,
              Icon: <IconV2OutlineMail />,
            }}
            onClick={onInviteAdminButtonClick}
          >
            Invite admin
          </ApplicationSideBar.NavButton>
        )}
        {viewer.isAllowedToManageOrganization &&
          !organization.planIsFreemium && (
            <ApplicationSideBar.NavButton
              icons={{
                ActiveIcon: <IconV2DuotonePlus />,
                Icon: <IconV2OutlinePlus />,
              }}
              onClick={onCreateNewButtonClick}
            >
              Create new
            </ApplicationSideBar.NavButton>
          )}
      </div>

      <ApplicationSideBar.Section title="Organization">
        <ApplicationSideBar.NavLink
          icons={{
            ActiveIcon: <IconV2DuotoneChartBar />,
            Icon: <IconV2OutlineChartBar />,
          }}
          to={generatePath(APPLICATION_ROUTES.organizationInsights, {
            organizationId: organization.id,
          })}
        >
          Insights
        </ApplicationSideBar.NavLink>
        <ApplicationSideBar.NavLink
          icons={{
            ActiveIcon: <IconV2DuotoneZap />,
            Icon: <IconV2OutlineZap />,
          }}
          to={generatePath(APPLICATION_ROUTES.organizationPlanning, {
            organizationId: organization.id,
          })}
        >
          Planning
        </ApplicationSideBar.NavLink>
        <ApplicationSideBar.NavLink
          icons={{
            ActiveIcon: <IconV2DuotoneUsers />,
            Icon: <IconV2OutlineUsers />,
          }}
          to={generatePath(APPLICATION_ROUTES.organizationGrantees, {
            organizationId: organization.id,
          })}
        >
          Team overview
        </ApplicationSideBar.NavLink>
        <ApplicationSideBar.FoldableItem
          icons={{
            ActiveIcon: <IconV2DuotonePieChart />,
            Icon: <IconV2OutlinePieChart />,
          }}
          isOpen={unfoldedSection === "equity"}
          label="Equity"
          onToggle={() => {
            setUnfoldedSection(unfoldedSection !== "equity" ? "equity" : null);
          }}
        >
          <ApplicationSideBar.NavLink
            isSubLink
            to={generatePath(APPLICATION_ROUTES.organizationEquityGrants, {
              organizationId: organization.id,
            })}
          >
            Grants
          </ApplicationSideBar.NavLink>
          <ApplicationSideBar.NavLink
            isSubLink
            to={generatePath(APPLICATION_ROUTES.organizationEquityConfigure, {
              organizationId: organization.id,
            })}
          >
            Configure
          </ApplicationSideBar.NavLink>
        </ApplicationSideBar.FoldableItem>
        {!organization.planIsFreemium && (
          <ApplicationSideBar.NavLink
            icons={{
              ActiveIcon: <IconV2DuotoneFolderOpen />,
              Icon: <IconV2OutlineFolderOpen />,
            }}
            to={generatePath(APPLICATION_ROUTES.organizationDocuments, {
              organizationId: organization.id,
            })}
          >
            Documents
          </ApplicationSideBar.NavLink>
        )}
        {viewer.isAllowedToManageOrganization && (
          <ApplicationSideBar.FoldableItem
            icons={{
              ActiveIcon: <IconV2DuotoneHome />,
              Icon: <IconV2OutlineHome />,
            }}
            isOpen={unfoldedSection === "settings"}
            label="Settings"
            onToggle={() => {
              setUnfoldedSection(
                unfoldedSection !== "settings" ? "settings" : null,
              );
            }}
          >
            <ApplicationSideBar.NavLink
              end
              isSubLink
              to={generatePath(APPLICATION_ROUTES.organizationSettings, {
                organizationId: organization.id,
              })}
            >
              Organization
            </ApplicationSideBar.NavLink>
            <ApplicationSideBar.NavLink
              isSubLink
              to={generatePath(APPLICATION_ROUTES.organizationSettingsAdmins, {
                organizationId: organization.id,
              })}
            >
              Admins
            </ApplicationSideBar.NavLink>
            <ApplicationSideBar.NavLink
              isSubLink
              to={generatePath(
                APPLICATION_ROUTES.organizationSettingsEquityViewers,
                {
                  organizationId: organization.id,
                },
              )}
            >
              Equity viewers
            </ApplicationSideBar.NavLink>
            <ApplicationSideBar.NavLink
              isSubLink
              to={generatePath(
                APPLICATION_ROUTES.organizationSettingsBoardMembers,
                {
                  organizationId: organization.id,
                },
              )}
            >
              Board members
            </ApplicationSideBar.NavLink>
            <ApplicationSideBar.NavLink
              isSubLink
              to={generatePath(
                APPLICATION_ROUTES.organizationSettingsSafeCalculator,
                {
                  organizationId: organization.id,
                },
              )}
            >
              SAFE converter
            </ApplicationSideBar.NavLink>
            <ApplicationSideBar.NavLink
              isSubLink
              to={generatePath(
                APPLICATION_ROUTES.organizationSettingsIntegrations,
                {
                  organizationId: organization.id,
                },
              )}
            >
              Integrations
            </ApplicationSideBar.NavLink>
            <ApplicationSideBar.NavLink
              isSubLink
              to={generatePath(APPLICATION_ROUTES.organizationSettingsBilling, {
                organizationId: organization.id,
              })}
            >
              Plan & Billing
            </ApplicationSideBar.NavLink>
            <ApplicationSideBar.NavLink
              isSubLink
              to={{
                pathname: generatePath(
                  APPLICATION_ROUTES.organizationSettingsCleanupGrantees,
                  {
                    organizationId: organization.id,
                  },
                ),
                search: "ignored=true",
              }}
            >
              <CleanUpGranteesLabel organization={organization} />
            </ApplicationSideBar.NavLink>
          </ApplicationSideBar.FoldableItem>
        )}
      </ApplicationSideBar.Section>

      <ApplicationSideBar.Section title="Legal workflows">
        {!organization.planIsFreemium ? (
          <>
            <ApplicationSideBar.FoldableItem
              count={grantCount}
              icons={{
                ActiveIcon: <IconV2DuotoneCopy />,
                Icon: <IconV2OutlineCopy />,
              }}
              isOpen={unfoldedSection === "grant-equity"}
              label="New grants"
              onToggle={() => {
                setUnfoldedSection(
                  unfoldedSection !== "grant-equity" ? "grant-equity" : null,
                );
              }}
            >
              {grantCategories.map((grantCategory) => (
                <ApplicationSideBar.NavLink
                  count={grantCategory.counter}
                  isSubLink
                  key={grantCategory.label}
                  to={generatePath(APPLICATION_ROUTES[grantCategory.route], {
                    organizationId: organization.id,
                  })}
                >
                  {grantCategory.label}
                </ApplicationSideBar.NavLink>
              ))}
            </ApplicationSideBar.FoldableItem>
            {granteeTerminationGrantAmendmentCount > 0 && (
              <ApplicationSideBar.FoldableItem
                count={granteeTerminationGrantAmendmentCount}
                icons={{
                  ActiveIcon: <IconV2DuotoneClipboard />,
                  Icon: <IconV2OutlineClipboard />,
                }}
                isOpen={unfoldedSection === "grantee-termination-amend-equity"}
                label="Terminations"
                onToggle={() => {
                  setUnfoldedSection(
                    unfoldedSection !== "grantee-termination-amend-equity"
                      ? "grantee-termination-amend-equity"
                      : null,
                  );
                }}
              >
                {granteeTerminationGrantAmendmentCategories.map((category) => (
                  <ApplicationSideBar.NavLink
                    count={category.counter}
                    isSubLink
                    key={category.label}
                    to={generatePath(APPLICATION_ROUTES[category.route], {
                      organizationId: organization.id,
                    })}
                  >
                    {category.label}
                  </ApplicationSideBar.NavLink>
                ))}
              </ApplicationSideBar.FoldableItem>
            )}
            {grantAmendmentCount > 0 && (
              <ApplicationSideBar.FoldableItem
                count={grantAmendmentCount}
                icons={{
                  ActiveIcon: <IconV2DuotoneEditUnderline />,
                  Icon: <IconV2OutlineEditUnderline />,
                }}
                isOpen={unfoldedSection === "amend-equity"}
                label="Amendments"
                onToggle={() => {
                  setUnfoldedSection(
                    unfoldedSection !== "amend-equity" ? "amend-equity" : null,
                  );
                }}
              >
                {grantAmendmentCategories.map((category) => (
                  <ApplicationSideBar.NavLink
                    count={category.counter}
                    isSubLink
                    key={category.label}
                    to={generatePath(APPLICATION_ROUTES[category.route], {
                      organizationId: organization.id,
                    })}
                  >
                    {category.label}
                  </ApplicationSideBar.NavLink>
                ))}
              </ApplicationSideBar.FoldableItem>
            )}
            {fairMarketValuesBeingApprovedCount > 0 && (
              <ApplicationSideBar.FoldableItem
                count={fairMarketValuesBeingApprovedCount}
                icons={{
                  ActiveIcon: <IconV2DuotoneCopy />,
                  Icon: <IconV2OutlineCopy />,
                }}
                isOpen={unfoldedSection === "fair_market_value_approval"}
                label="409A approval"
                onToggle={() => {
                  setUnfoldedSection(
                    unfoldedSection !== "fair_market_value_approval"
                      ? "fair_market_value_approval"
                      : null,
                  );
                }}
              >
                <ApplicationSideBar.NavLink
                  count={fairMarketValuesPendingReviewCount}
                  isSubLink
                  to={generatePath(
                    APPLICATION_ROUTES.organizationFairMarketValueApprovalsUnderReview,
                    {
                      organizationId: organization.id,
                    },
                  )}
                >
                  {GRANT_STATUS_LABEL_MAP.PendingReview}
                </ApplicationSideBar.NavLink>
                <ApplicationSideBar.NavLink
                  count={fairMarketValuesReviewedCount}
                  isSubLink
                  to={generatePath(
                    APPLICATION_ROUTES.organizationFairMarketValueApprovalsBoardApproval,
                    {
                      organizationId: organization.id,
                    },
                  )}
                >
                  {GRANT_STATUS_LABEL_MAP.Reviewed}
                </ApplicationSideBar.NavLink>
                <ApplicationSideBar.NavLink
                  count={fairMarketValueApprovalCount}
                  isSubLink
                  to={generatePath(
                    APPLICATION_ROUTES.organizationFairMarketValueApprovalsPendingApproval,
                    {
                      organizationId: organization.id,
                    },
                  )}
                >
                  {GRANT_STATUS_LABEL_MAP.PendingBoardConsent}
                </ApplicationSideBar.NavLink>
              </ApplicationSideBar.FoldableItem>
            )}
          </>
        ) : (
          <>
            <ApplicationSideBar.NavLink
              icons={{
                ActiveIcon: <IconV2DuotoneCopy />,
                Icon: <IconV2OutlineCopy />,
              }}
              paid
              to={generatePath(
                APPLICATION_ROUTES.organizationPrepareYourGrantsDrafts,
                { organizationId: organization.id },
              )}
            >
              New grants
            </ApplicationSideBar.NavLink>
            <ApplicationSideBar.NavLink
              icons={{
                ActiveIcon: <IconV2DuotoneClipboard />,
                Icon: <IconV2OutlineClipboard />,
              }}
              paid
              to={generatePath(
                APPLICATION_ROUTES.organizationGranteeTerminationCTMSGrantAmendmentRequestsUnderReview,
                { organizationId: organization.id },
              )}
            >
              Terminations
            </ApplicationSideBar.NavLink>
          </>
        )}
      </ApplicationSideBar.Section>

      <ApplicationSideBar.Section title="Global compliance">
        {viewer.isAllowedToManageOrganization && (
          <ApplicationSideBar.NavLink
            icons={{
              ActiveIcon: <IconV2DuotoneGlobe />,
              Icon: <IconV2OutlineGlobe />,
            }}
            to={generatePath(APPLICATION_ROUTES.organizationCountries, {
              organizationId: organization.id,
            })}
          >
            Countries
          </ApplicationSideBar.NavLink>
        )}
        {isEquityHandbooksEnabledForAdvancedOrganizations && (
          <ApplicationSideBar.NavLink
            icons={{
              ActiveIcon: <IconV2DuotoneBookOpen />,
              Icon: <IconV2OutlineBookOpen />,
            }}
            to={generatePath(APPLICATION_ROUTES.organizationEquityHandbooks, {
              organizationId: organization.id,
            })}
          >
            Equity handbooks
          </ApplicationSideBar.NavLink>
        )}

        {organization.shouldShowExerciseHandler && (
          <ApplicationSideBar.NavLink
            icons={{
              ActiveIcon: <IconV2DuotoneFilesText />,
              Icon: <IconV2OutlineFilesText />,
            }}
            paid={organization.planIsFreemium}
            to={generatePath(APPLICATION_ROUTES.organizationExerciseRequests, {
              organizationId: organization.id,
            })}
          >
            Option exercises
          </ApplicationSideBar.NavLink>
        )}
      </ApplicationSideBar.Section>

      {viewer.isAllowedToManageOrganization && (
        <ApplicationSideBar.Section title="Employee education">
          {newGranteePortalFeatureIsEnabled ? (
            <ApplicationSideBar.NavLink
              icons={{
                ActiveIcon: <IconV2DuotoneUmbrella />,
                Icon: <IconV2OutlineUmbrella />,
              }}
              paid={organization.planIsFreemium}
              to={generatePath(
                APPLICATION_ROUTES.organizationEmployeePortalsSettings,
                {
                  organizationId: organization.id,
                },
              )}
            >
              Employee portals
            </ApplicationSideBar.NavLink>
          ) : (
            <ApplicationSideBar.NavLink
              icons={{
                ActiveIcon: <IconV2DuotoneUmbrella />,
                Icon: <IconV2OutlineUmbrella />,
              }}
              paid={organization.planIsFreemium}
              to={generatePath(
                APPLICATION_ROUTES.organizationToolsGranteePortalSettings,
                {
                  organizationId: organization.id,
                },
              )}
            >
              Grantee portal
            </ApplicationSideBar.NavLink>
          )}
          <ApplicationSideBar.NavLink
            icons={{
              ActiveIcon: <IconV2DuotoneBriefcase />,
              Icon: <IconV2OutlineBriefcase />,
            }}
            paid={organization.planIsFreemium}
            to={generatePath(
              APPLICATION_ROUTES.organizationToolsEquityOffersSettings,
              {
                organizationId: organization.id,
              },
            )}
          >
            Equity offers
          </ApplicationSideBar.NavLink>
        </ApplicationSideBar.Section>
      )}
    </ApplicationSideBar>
  );
};

const CleanUpGranteesLabel: React.FC<{
  organization: AdvancedApplicationSidebar_Organization$data;
}> = ({ organization }) => {
  if (organization.isOriginatingFromRemoteEquity) {
    return <>Match accounts</>;
  }

  return <>Clean up grantees</>;
};
