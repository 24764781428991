/**
 * @generated SignedSource<<47ffaf1174dc9e9d1fdd169d4a9b4c47>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlanningEntrySuggestionsTable_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FormattedCurrency_Organization" | "useOrganizationSharesUtil_Organization">;
  readonly " $fragmentType": "PlanningEntrySuggestionsTable_Organization";
};
export type PlanningEntrySuggestionsTable_Organization$key = {
  readonly " $data"?: PlanningEntrySuggestionsTable_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlanningEntrySuggestionsTable_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlanningEntrySuggestionsTable_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationSharesUtil_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedCurrency_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "bf1f6401f3d1f2d4080ab79ffabf115d";

export default node;
