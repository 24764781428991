/**
 * @generated SignedSource<<65089a4121b7a9158a1e5b1a16a4cea1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquityTypeHandbookEdition_PublishEquityTypeHandbook_Mutation$variables = {
  equityTypeHandbookId: string;
  lastModifiedAt?: string | null;
};
export type EquityTypeHandbookEdition_PublishEquityTypeHandbook_Mutation$data = {
  readonly publishEquityTypeHandbook: {
    readonly " $fragmentSpreads": FragmentRefs<"EquityTypeHandbookEdition_EquityTypeHandbook">;
  };
};
export type EquityTypeHandbookEdition_PublishEquityTypeHandbook_Mutation = {
  response: EquityTypeHandbookEdition_PublishEquityTypeHandbook_Mutation$data;
  variables: EquityTypeHandbookEdition_PublishEquityTypeHandbook_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "equityTypeHandbookId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lastModifiedAt"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "equityTypeHandbookId",
    "variableName": "equityTypeHandbookId"
  },
  {
    "kind": "Variable",
    "name": "lastModifiedAt",
    "variableName": "lastModifiedAt"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "enabled",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EquityTypeHandbookEdition_PublishEquityTypeHandbook_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EquityTypeHandbook",
        "kind": "LinkedField",
        "name": "publishEquityTypeHandbook",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EquityTypeHandbookEdition_EquityTypeHandbook"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EquityTypeHandbookEdition_PublishEquityTypeHandbook_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EquityTypeHandbook",
        "kind": "LinkedField",
        "name": "publishEquityTypeHandbook",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "publishedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "published",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastModifiedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EquityTypeHandbookSectionEvent",
            "kind": "LinkedField",
            "name": "sectionEvents",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "section",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "event",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nothingToDeclare",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EquityTypeHandbookSectionEventItem",
                "kind": "LinkedField",
                "name": "items",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "category",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "content",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f59cfbf822b87da30ad754ccb31e41e0",
    "id": null,
    "metadata": {},
    "name": "EquityTypeHandbookEdition_PublishEquityTypeHandbook_Mutation",
    "operationKind": "mutation",
    "text": "mutation EquityTypeHandbookEdition_PublishEquityTypeHandbook_Mutation(\n  $equityTypeHandbookId: String!\n  $lastModifiedAt: DateTime\n) {\n  publishEquityTypeHandbook(equityTypeHandbookId: $equityTypeHandbookId, lastModifiedAt: $lastModifiedAt) {\n    ...EquityTypeHandbookEdition_EquityTypeHandbook\n    id\n  }\n}\n\nfragment EquityTypeHandbookEdition_EquityTypeHandbook on EquityTypeHandbook {\n  id\n  publishedAt\n  published\n  lastModifiedAt\n  sectionEvents {\n    section\n    event\n    enabled\n    nothingToDeclare\n    items {\n      category\n      content\n      enabled\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a5832489a7662127ca577eae6f822311";

export default node;
