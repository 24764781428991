import { InputDatePicker } from "@remote-com/norma";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";

const _RemoteInputDatePicker = ({
  dateFormat,
  portalId,
  showMonthYearPicker,
  ...props
}: {
  dateFormat?: string;
  errorText?: string;
  isClearable?: boolean;
  label: string;
  name: string;
  onChange: (date: string) => void;
  portalId?: string;
  selected?: null | string;
  showMonthYearPicker?: boolean;
  value: null | string;
}) => {
  return (
    <InputDatePicker
      props={{
        dateFormat,
        popperClassName: "z-10",
        portalId,
        showMonthYearPicker,
      }}
      {...props}
    />
  );
};

const FormInput = <TFieldValues extends FieldValues>({
  control,
  label,
  name,
  ...props
}: Omit<
  React.ComponentProps<typeof _RemoteInputDatePicker>,
  "errorText" | "label" | "name" | "onChange" | "selected" | "value"
> & {
  control: Control<TFieldValues>;
  label: string;
  name: FieldPath<TFieldValues>;
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <_RemoteInputDatePicker
            isClearable={false}
            {...props}
            errorText={fieldState.error?.message}
            label={label}
            name={name}
            onChange={(date: string) => {
              field.onChange(date);
            }}
            selected={field.value}
            value={field.value}
          />
        );
      }}
    />
  );
};

export const RemoteInputDatePicker = Object.assign(_RemoteInputDatePicker, {
  Form: FormInput,
});
