import { Suspense } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { Deprecated_VestingGraphSkeleton } from "../../../../components/Deprecated_VestingGraph";
import { DraftGrantVestingPreview } from "../../../../components/DraftGrantVestingPreview";
import { GrantVestingOverviewRow_Grantee$key } from "./__generated__/GrantVestingOverviewRow_Grantee.graphql";
import { GrantVestingOverviewRow_Organization$key } from "./__generated__/GrantVestingOverviewRow_Organization.graphql";
import { GrantReviewRow } from "./GrantReviewRow";

const ORGANIZATION_FRAGMENT = graphql`
  fragment GrantVestingOverviewRow_Organization on Organization {
    ...DraftGrantVestingPreview_Organization
  }
`;

const GRANTEE_FRAGMENT = graphql`
  fragment GrantVestingOverviewRow_Grantee on Grantee {
    ...DraftGrantVestingPreview_Grantee
  }
`;

export const GrantVestingOverviewRow: React.FC<{
  granteeFragment: GrantVestingOverviewRow_Grantee$key;
  label: string;
  organizationFragment: GrantVestingOverviewRow_Organization$key;
  quantityGranted: number;
  vestingScheduleId: string;
  vestingStartDate: string;
}> = ({
  granteeFragment,
  label,
  organizationFragment,
  quantityGranted,
  vestingScheduleId,
  vestingStartDate,
}) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  return (
    <GrantReviewRow title="Vesting overview">
      <Suspense fallback={<Deprecated_VestingGraphSkeleton />}>
        <DraftGrantVestingPreview
          draftGrant={{
            label,
            quantityGranted,
            vestingScheduleId,
            vestingStartDate,
          }}
          granteeFragment={grantee}
          organizationFragment={organization}
        />
      </Suspense>
    </GrantReviewRow>
  );
};
