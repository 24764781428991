/**
 * @generated SignedSource<<f1244cc1259799000d5137448024131b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EquityTypeName = "BSPCE" | "EMI" | "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
import { FragmentRefs } from "relay-runtime";
export type EquityTypePage_SuperAdminEquityTypeHandbooks_EquityType$data = {
  readonly contractorHandbook: {
    readonly " $fragmentSpreads": FragmentRefs<"EquityTypeHandbookEdition_EquityTypeHandbook">;
  } | null;
  readonly directEmployeeHandbook: {
    readonly " $fragmentSpreads": FragmentRefs<"EquityTypeHandbookEdition_EquityTypeHandbook">;
  } | null;
  readonly eoREmployeeHandbook: {
    readonly " $fragmentSpreads": FragmentRefs<"EquityTypeHandbookEdition_EquityTypeHandbook">;
  } | null;
  readonly id: string;
  readonly name: EquityTypeName;
  readonly " $fragmentSpreads": FragmentRefs<"EquityTypeHandbookEdition_EquityType">;
  readonly " $fragmentType": "EquityTypePage_SuperAdminEquityTypeHandbooks_EquityType";
};
export type EquityTypePage_SuperAdminEquityTypeHandbooks_EquityType$key = {
  readonly " $data"?: EquityTypePage_SuperAdminEquityTypeHandbooks_EquityType$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquityTypePage_SuperAdminEquityTypeHandbooks_EquityType">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "EquityTypeHandbookEdition_EquityTypeHandbook"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquityTypePage_SuperAdminEquityTypeHandbooks_EquityType",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityTypeHandbook",
      "kind": "LinkedField",
      "name": "directEmployeeHandbook",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityTypeHandbook",
      "kind": "LinkedField",
      "name": "eoREmployeeHandbook",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityTypeHandbook",
      "kind": "LinkedField",
      "name": "contractorHandbook",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquityTypeHandbookEdition_EquityType"
    }
  ],
  "type": "EquityType",
  "abstractKey": null
};
})();

(node as any).hash = "e087991936c637f1ac0b9ca75dbbe5d7";

export default node;
