/**
 * @generated SignedSource<<7188a1895cbde4151001c9bf04de6e59>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SuspensedTabList_Organization$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"SuspensedTabList_Deferred_Organization">;
  readonly " $fragmentType": "SuspensedTabList_Organization";
};
export type SuspensedTabList_Organization$key = {
  readonly " $data"?: SuspensedTabList_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"SuspensedTabList_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SuspensedTabList_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "kind": "Defer",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SuspensedTabList_Deferred_Organization"
        }
      ]
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "0ecc6226910f273b581423bc4735800a";

export default node;
