import { BoxedIcon, Pill, Text } from "@remote-com/norma";
import { IconV2DuotoneEditBox } from "@remote-com/norma/icons/IconV2DuotoneEditBox";
import { IconV2DuotoneMail } from "@remote-com/norma/icons/IconV2DuotoneMail";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { graphql, useFragment } from "react-relay";
import { generatePath } from "react-router-dom";

import { LinkButton } from "../../../../components/ui/Button";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { APPLICATION_ROUTES } from "../../../../paths";
import { TakeActionCard_EssentialsOverview_Organization$key } from "./__generated__/TakeActionCard_EssentialsOverview_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment TakeActionCard_EssentialsOverview_Organization on Organization {
    id
    grantees {
      edges {
        node {
          cannotBeInvitedReason
          equityNeedsReview
        }
      }
    }
  }
`;

export const TakeActionCard: React.FC<{
  organizationFragment: TakeActionCard_EssentialsOverview_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const employeesNeedingReviewCount = useMemo(
    () =>
      organization.grantees.edges.filter(
        ({ node: grantee }) => grantee.equityNeedsReview,
      ).length,
    [organization.grantees.edges],
  );
  const invitableEmployeesCount = useMemo(
    () =>
      organization.grantees.edges.filter(
        ({ node }) => !node.cannotBeInvitedReason,
      ).length,
    [organization.grantees.edges],
  );
  return (
    <RoundedBox className="divide-y-[0.5px] divide-grey-300" withBorder>
      <div className="px-6 py-4">
        <Text variant="lgMedium">Take action</Text>
      </div>
      {employeesNeedingReviewCount > 0 && (
        <div className="flex items-center gap-4 p-6">
          <BoxedIcon Icon={IconV2DuotoneEditBox} size="sm" tone="orange" />
          <div className="flex flex-1 items-center gap-2">
            <Text variant="smMedium">New hire grant declaration</Text>
            <Pill tone="warning">missing Information</Pill>
          </div>
          <LinkButton
            size="small"
            to={generatePath(APPLICATION_ROUTES.organizationGrantees, {
              organizationId: organization.id,
            })}
            variant="Primary Full"
          >
            Declare grant
          </LinkButton>
        </div>
      )}
      {invitableEmployeesCount > 0 && (
        <div className="flex items-center gap-4 p-6">
          <BoxedIcon Icon={IconV2DuotoneMail} size="sm" tone="purple" />
          <Text className="flex-1" variant="smMedium">
            Invite{" "}
            <FormattedMessage
              defaultMessage="{count, plural, one {# employee} other {# employees}}"
              values={{
                count: invitableEmployeesCount,
              }}
            />{" "}
            to their portals
          </Text>
          <LinkButton
            size="small"
            to={generatePath(
              APPLICATION_ROUTES.organizationEmployeePortalsSettings,
              {
                organizationId: organization.id,
              },
            )}
            variant="Secondary Full"
          >
            Invite
          </LinkButton>
        </div>
      )}
    </RoundedBox>
  );
};
