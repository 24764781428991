/**
 * @generated SignedSource<<337a1468546725e30cc9039be4e78626>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type LinearVestingOccurrence = "Every12Months" | "Every2Months" | "Every3Months" | "Every6Months" | "EveryMonth" | "Once";
export type VestingSchedulePreview_LinearVestingSchedulePreview_Query$variables = {
  cliffDurationInMonths: number;
  durationInMonths: number;
  organizationId: string;
  quantityGranted: number;
  vestedAtCliffPercentage: number;
  vestingOccurrence: LinearVestingOccurrence;
  vestingStartDate: string;
};
export type VestingSchedulePreview_LinearVestingSchedulePreview_Query$data = {
  readonly vestingDataPointsFromLinearVestingScheduleParameters: ReadonlyArray<{
    readonly cumulativeVested: number;
    readonly date: string;
  }>;
};
export type VestingSchedulePreview_LinearVestingSchedulePreview_Query = {
  response: VestingSchedulePreview_LinearVestingSchedulePreview_Query$data;
  variables: VestingSchedulePreview_LinearVestingSchedulePreview_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cliffDurationInMonths"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "durationInMonths"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "quantityGranted"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "vestedAtCliffPercentage"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "vestingOccurrence"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "vestingStartDate"
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "cliffDurationInMonths",
      "variableName": "cliffDurationInMonths"
    },
    {
      "kind": "Variable",
      "name": "durationInMonths",
      "variableName": "durationInMonths"
    },
    {
      "kind": "Variable",
      "name": "organizationId",
      "variableName": "organizationId"
    },
    {
      "kind": "Variable",
      "name": "quantityGranted",
      "variableName": "quantityGranted"
    },
    {
      "kind": "Variable",
      "name": "vestedAtCliffPercentage",
      "variableName": "vestedAtCliffPercentage"
    },
    {
      "kind": "Variable",
      "name": "vestingOccurrence",
      "variableName": "vestingOccurrence"
    },
    {
      "kind": "Variable",
      "name": "vestingStartDate",
      "variableName": "vestingStartDate"
    }
  ],
  "concreteType": "VestingDataPoint",
  "kind": "LinkedField",
  "name": "vestingDataPointsFromLinearVestingScheduleParameters",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cumulativeVested",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "VestingSchedulePreview_LinearVestingSchedulePreview_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": (v7/*: any*/),
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v6/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "VestingSchedulePreview_LinearVestingSchedulePreview_Query",
    "selections": [
      (v7/*: any*/)
    ]
  },
  "params": {
    "cacheID": "a55c4d5b0c89509c0d03288b3c4df75d",
    "id": null,
    "metadata": {},
    "name": "VestingSchedulePreview_LinearVestingSchedulePreview_Query",
    "operationKind": "query",
    "text": "query VestingSchedulePreview_LinearVestingSchedulePreview_Query(\n  $vestingStartDate: Date!\n  $quantityGranted: Int!\n  $vestingOccurrence: LinearVestingOccurrence!\n  $durationInMonths: Int!\n  $cliffDurationInMonths: Int!\n  $vestedAtCliffPercentage: Float!\n  $organizationId: OrganizationId!\n) {\n  vestingDataPointsFromLinearVestingScheduleParameters(organizationId: $organizationId, vestingStartDate: $vestingStartDate, quantityGranted: $quantityGranted, vestingOccurrence: $vestingOccurrence, durationInMonths: $durationInMonths, cliffDurationInMonths: $cliffDurationInMonths, vestedAtCliffPercentage: $vestedAtCliffPercentage) {\n    cumulativeVested\n    date\n  }\n}\n"
  }
};
})();

(node as any).hash = "2670a0138dbca6fbfe06fd9a896bb350";

export default node;
