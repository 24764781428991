import { isEmpty } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { EmptyListPlaceholder } from "../../../components/EmptyListPlaceholder/EmptyListPlaceholder";
import { Page } from "../../../components/Page";
import { SelectedItemsActionCard } from "../../../components/SelectedItemsActionCard";
import { useToaster } from "../../../components/Toaster";
import { Button } from "../../../components/ui/Button";
import { Toast } from "../../../components/ui/Toast";
import { useDownloadFairMarketValueBoardConsentPreview } from "../../../hooks/useDownloadBoardConsentPreview";
import { useQuery } from "../../../hooks/useQuery";
import { useSafeMutation } from "../../../hooks/useSafeMutation";
import { LegalWorkflowLayout } from "../../../layouts/LegalWorkflowLayout";
import { useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { UnderReview_FairMarketValues_Account$key } from "./__generated__/UnderReview_FairMarketValues_Account.graphql";
import { UnderReview_FairMarketValues_Organization$key } from "./__generated__/UnderReview_FairMarketValues_Organization.graphql";
import { UnderReview_FairMarketValues_Query } from "./__generated__/UnderReview_FairMarketValues_Query.graphql";
import { UnderReview_FairMarketValues_ReviewFairMarketValue_Mutation } from "./__generated__/UnderReview_FairMarketValues_ReviewFairMarketValue_Mutation.graphql";
import { FairMarketValueApprovalTable } from "./FairMarketValueApprovalTable";

const ORGANIZATION_FRAGMENT = graphql`
  fragment UnderReview_FairMarketValues_Organization on Organization {
    id
    name
    fairMarketValueHistory {
      id
      status
      ...FairMarketValueApprovalTable_FairMarketValue
    }
    ...LegalWorkflowLayout_Organization
    ...FairMarketValueApprovalTable_Organization
  }
`;

const ACCOUNT_FRAGMENT = graphql`
  fragment UnderReview_FairMarketValues_Account on Account {
    isSuperAdmin
  }
`;

const REVIEW_FAIR_MARKET_VALUE_MUTATION = graphql`
  mutation UnderReview_FairMarketValues_ReviewFairMarketValue_Mutation(
    $fairMarketValueId: ID!
  ) {
    reviewFairMarketValue(fairMarketValueId: $fairMarketValueId) {
      ...UnderReview_FairMarketValues_Organization
    }
  }
`;

const AdminFairMarketValueApprovalsUnderReviewPage_: React.FC<{
  organizationFragment: UnderReview_FairMarketValues_Organization$key;
  viewerFragment: UnderReview_FairMarketValues_Account$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(ACCOUNT_FRAGMENT, viewerFragment);

  const fairMarketValuesUnderReview = useMemo(
    () =>
      organization.fairMarketValueHistory.filter(
        (fairMarketValue) => fairMarketValue.status === "PendingReview",
      ),
    [organization.fairMarketValueHistory],
  );

  const [selectedFairMarketValueId, setSelectedFairMarketValueId] = useState<
    null | string
  >(fairMarketValuesUnderReview[0]?.id ?? null);

  const [validateFairMarketValue, validateFairMarketValueMutationIsInFlight] =
    useSafeMutation<UnderReview_FairMarketValues_ReviewFairMarketValue_Mutation>(
      REVIEW_FAIR_MARKET_VALUE_MUTATION,
    );

  const toaster = useToaster();

  const handleReviewFairMarketValueButtonClick = useCallback(async () => {
    if (!selectedFairMarketValueId) return;

    await validateFairMarketValue({
      variables: {
        fairMarketValueId: selectedFairMarketValueId,
      },
    });

    setSelectedFairMarketValueId(null);

    toaster.push(
      <Toast title="Wonderful!">
        Fair market value successfully reviewed!
      </Toast>,
    );
  }, [
    selectedFairMarketValueId,
    setSelectedFairMarketValueId,
    toaster,
    validateFairMarketValue,
  ]);

  const { downloadFairMarketValueBoardConsentPreview, downloadIsInFlight } =
    useDownloadFairMarketValueBoardConsentPreview({
      fairMarketValueId: selectedFairMarketValueId,
    });

  return (
    <LegalWorkflowLayout
      organizationFragment={organization}
      subtitle="Our team is currently reviewing and validating"
      title="Under review"
      topBarActionsRender={({ mainContentIsScrolled }) => {
        if (!viewer.isSuperAdmin) return null;

        return (
          <SelectedItemsActionCard
            actions={
              <>
                <Button
                  className="whitespace-nowrap"
                  disabled={!selectedFairMarketValueId}
                  loading={downloadIsInFlight}
                  onClick={downloadFairMarketValueBoardConsentPreview}
                  size="small"
                  variant="Secondary Full"
                >
                  Preview board consent
                </Button>

                <Button
                  disabled={!selectedFairMarketValueId}
                  loading={validateFairMarketValueMutationIsInFlight}
                  onClick={handleReviewFairMarketValueButtonClick}
                  size="small"
                  variant="Approve Light"
                >
                  Approve
                </Button>
              </>
            }
            compact={mainContentIsScrolled}
            emptyListLabel="Select fair market value"
            itemCount={selectedFairMarketValueId ? 1 : 0}
            loading={validateFairMarketValueMutationIsInFlight}
            pluralLabel="fair market values selected"
            singularLabel="fair market value selected"
          />
        );
      }}
    >
      {isEmpty(fairMarketValuesUnderReview) ? (
        <EmptyListPlaceholder
          hideImage
          title="No fair market value under review"
        />
      ) : (
        <FairMarketValueApprovalTable
          containerized
          fairMarketValuesFragment={fairMarketValuesUnderReview}
          organizationFragment={organization}
          selectedFairMarketValueId={selectedFairMarketValueId}
          setSelectedFairMarketValueId={setSelectedFairMarketValueId}
        />
      )}
    </LegalWorkflowLayout>
  );
};

const QUERY = graphql`
  query UnderReview_FairMarketValues_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...UnderReview_FairMarketValues_Organization
    }
    me {
      ...UnderReview_FairMarketValues_Account
    }
  }
`;

const AdminFairMarketValueApprovalsUnderReviewPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const {
    query: { me, organization },
  } = useQuery<UnderReview_FairMarketValues_Query>(QUERY, {
    organizationId,
  });

  if (!organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Fair Market Value Approvals"
      analyticsName="Admin - Fair Market Value Under Review"
      organizationId={organization.id}
      title={`Admin | ${organization.name} fair market value under review`}
    >
      <AdminFairMarketValueApprovalsUnderReviewPage_
        organizationFragment={organization}
        viewerFragment={me}
      />
    </Page>
  );
};

export default AdminFairMarketValueApprovalsUnderReviewPage;
