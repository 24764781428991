import { Button } from "@remote-com/norma";
import { IconV2OutlineArrowRight } from "@remote-com/norma/icons/IconV2OutlineArrowRight";
import React from "react";
import { graphql, useFragment } from "react-relay";

import { RemoteEquityOnboardingLayout } from "../../../components/ui/Layout/RemoteLikeApplicationLayout/RemoteEquityOnboardingLayout";
import CONFIGURATION from "../../../services/configuration";
import { DemoBookedView_Organization$key } from "./__generated__/DemoBookedView_Organization.graphql";
import { DemoBookedView_Viewer$key } from "./__generated__/DemoBookedView_Viewer.graphql";
import DemoBookedIllustration from "./demo-booked-illustration.png";

const REMOTE_DASHBOARD_EQUITY_URL = CONFIGURATION.REMOTE_DASHBOARD_EQUITY_URL;
if (!REMOTE_DASHBOARD_EQUITY_URL) {
  throw new Error("REMOTE_DASHBOARD_EQUITY_URL is required");
}

const ORGANIZATION_FRAGMENT = graphql`
  fragment DemoBookedView_Organization on Organization {
    ...RemoteEquityOnboardingLayout_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment DemoBookedView_Viewer on Account {
    ...RemoteEquityOnboardingLayout_Viewer
  }
`;

export const DemoBookedView: React.FC<{
  organizationFragment: DemoBookedView_Organization$key;
  viewerFragment: DemoBookedView_Viewer$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  return (
    <RemoteEquityOnboardingLayout
      className="max-w-[570px] !gap-10"
      organizationFragment={organization}
      subtitle={
        <>
          Our equity team will finalize your account setup before the meeting.
          <br />
          You&apos;ll get access to Remote Equity right after the meeting. Speak
          soon!
        </>
      }
      title={
        <>
          Meeting booked <span className="font-emoji">🚀</span>
        </>
      }
      viewerFragment={viewer}
    >
      <>
        <img
          alt="Demo booked illustration"
          className="w-[270px]"
          src={DemoBookedIllustration}
        />
        <Button
          as="a"
          href={REMOTE_DASHBOARD_EQUITY_URL}
          IconAfter={IconV2OutlineArrowRight}
          size="lg"
          tone="primary"
          variant="solid"
        >
          Back to Remote
        </Button>
      </>
    </RemoteEquityOnboardingLayout>
  );
};
