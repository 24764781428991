/**
 * @generated SignedSource<<b8464abe11a9462e7b5ec46068177d3e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Exercises_Query$variables = {
  organizationId: string;
};
export type Exercises_Query$data = {
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"Exercises_Organization">;
  } | null;
};
export type Exercises_Query = {
  response: Exercises_Query$data;
  variables: Exercises_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Exercises_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Exercises_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Exercises_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ctmsExerciseRequestsAreOutdated",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToCTMS",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isRemoteEquityEssentials",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CTMSExerciseRequest",
            "kind": "LinkedField",
            "name": "ctmsExerciseRequests",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ctmsId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ctmsGrantLabel",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalExercisePrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CTMSGrant",
                "kind": "LinkedField",
                "name": "ctmsGrant",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Grantee",
                    "kind": "LinkedField",
                    "name": "grantee",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "workRelationship",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Country",
                        "kind": "LinkedField",
                        "name": "taxResidenceCountry",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "emoji",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isInProgress",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "requestSubmittedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "quantityExercised",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "spread",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fundsTransferredAt",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "defaultCurrencyCode",
            "storageKey": null
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isIOrganization"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f9b73e6ac8ddd1402819f89f5a260ff4",
    "id": null,
    "metadata": {},
    "name": "Exercises_Query",
    "operationKind": "query",
    "text": "query Exercises_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    id\n    name\n    ...Exercises_Organization\n  }\n}\n\nfragment CountryFlag_Country on Country {\n  emoji\n  name\n}\n\nfragment CurrencySymbol_Organization on Organization {\n  defaultCurrencyCode\n}\n\nfragment ExercisesFilters_Exercises on CTMSExerciseRequest {\n  ctmsId\n  ctmsGrantLabel\n  totalExercisePrice\n  ctmsGrant {\n    grantee {\n      name\n      email\n      id\n    }\n    id\n  }\n}\n\nfragment ExercisesFilters_Organization on Organization {\n  ...CurrencySymbol_Organization\n  ...FormattedCurrency_Organization\n}\n\nfragment ExercisesTable_Exercises on CTMSExerciseRequest {\n  ctmsId\n  ctmsGrantLabel\n  status\n  isInProgress\n  requestSubmittedAt\n  totalExercisePrice\n  quantityExercised\n  spread\n  fundsTransferredAt\n  ctmsGrant {\n    grantee {\n      name\n      workRelationship\n      ...GranteeNameWithCountryFlag_Grantee\n      id\n    }\n    id\n  }\n}\n\nfragment ExercisesTable_Organization on Organization {\n  id\n  isRemoteEquityEssentials\n  ...FormattedCurrency_Organization\n}\n\nfragment ExercisesView_Organization on Organization {\n  ...ExercisesTable_Organization\n  ctmsExerciseRequests {\n    ctmsId\n    ...ExercisesFilters_Exercises\n    ...ExercisesTable_Exercises\n    id\n  }\n  ...ExercisesFilters_Organization\n}\n\nfragment Exercises_Organization on Organization {\n  id\n  ctmsExerciseRequestsAreOutdated\n  isConnectedToCTMS\n  ...ExercisesView_Organization\n}\n\nfragment FormattedCurrency_Organization on IOrganization {\n  __isIOrganization: __typename\n  defaultCurrencyCode\n}\n\nfragment GranteeNameWithCountryFlag_Grantee on Grantee {\n  name\n  taxResidenceCountry {\n    ...CountryFlag_Country\n  }\n}\n"
  }
};
})();

(node as any).hash = "b1a7cb90a6dea81e0d49eb108f8fa058";

export default node;
