import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import {
  GranteeSelect_Grantees$data,
  GranteeSelect_Grantees$key,
} from "./__generated__/GranteeSelect_Grantees.graphql";
import { GranteeSelect_Organization$key } from "./__generated__/GranteeSelect_Organization.graphql";
import { CreateGranteeButton } from "./CreateGranteeButton";
import { GranteeFormSlideState } from "./GranteeFormSlide";
import { SelectAutocomplete } from "./ui/Form/Inputs/Select/SelectAutocomplete";
import { Tag } from "./ui/Tag";
import { Typography } from "./ui/Typography";

const EASOP_GRANTEES_FRAGMENT = graphql`
  fragment GranteeSelect_Grantees on Grantee @relay(plural: true) {
    id
    name
    grantableStatus
    taxResidenceCountry {
      emoji
      name
    }
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment GranteeSelect_Organization on Organization {
    id
    isRemoteEquityEssentials
    ...CreateGranteeButton_Organization
  }
`;

const GranteeNotGrantableTag: React.FC<{
  grantee: NonNullable<GranteeSelect_Grantees$data[number]>;
}> = ({ grantee }) => {
  switch (grantee.grantableStatus) {
    case "COUNTRY_NOT_UNLOCKED":
      return (
        <Tag color="gray">
          {grantee.taxResidenceCountry?.emoji}{" "}
          {grantee.taxResidenceCountry?.name} needs to be unlocked
        </Tag>
      );
    case "EQUITY_TYPE_IN_GEOGRAPHY_NOT_UNLOCKED":
      return (
        <Tag color="gray">
          Special plan missing for {grantee.taxResidenceCountry?.emoji}{" "}
          {grantee.taxResidenceCountry?.name}
        </Tag>
      );
    case "GRANT_ON_MANAGEMENT_COMPANIES_NOT_ALLOWED":
      return null;
    case "GRANTABLE":
      return null;
    case "GRANTEE_IS_MISSING_REQUIRED_INFORMATION":
      return null;
    case "WORK_RELATIONSHIP_NOT_COVERED":
      return (
        <Tag color="gray">
          {grantee.taxResidenceCountry?.emoji}{" "}
          {grantee.taxResidenceCountry?.name} is not available yet
        </Tag>
      );
  }
};

function OptionLabel({
  grantee,
}: {
  grantee: NonNullable<GranteeSelect_Grantees$data[number]>;
}) {
  return (
    <div className="flex items-center justify-between">
      <Typography as="div" variant="Regular/Extra Small">
        {[grantee.taxResidenceCountry?.emoji, grantee.name].join(" ")}
      </Typography>
      {grantee.grantableStatus !== "GRANTABLE" && (
        <div>
          <GranteeNotGrantableTag grantee={grantee} />
        </div>
      )}
    </div>
  );
}

export const GranteeSelect: React.FC<{
  defaultGranteeCreationValues?: GranteeFormSlideState["defaultValues"];
  disabled?: boolean;
  granteesFragment: GranteeSelect_Grantees$key;
  invalid?: boolean;
  onChange: (value: null | string) => void;
  onGranteesUpdated?: () => void;
  organizationFragment: GranteeSelect_Organization$key;
  value?: string;
}> = ({
  defaultGranteeCreationValues,
  disabled = false,
  granteesFragment,
  invalid = false,
  onChange,
  onGranteesUpdated,
  organizationFragment,
  value,
}) => {
  const grantees = useFragment(EASOP_GRANTEES_FRAGMENT, granteesFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <div className="space-y-2">
      <SelectAutocomplete
        disabled={disabled}
        formatOptionLabel={(grantee) => <OptionLabel grantee={grantee} />}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        invalid={invalid}
        onChange={(option) => {
          onChange(option?.id ?? null);
        }}
        options={grantees}
        placeholder="Search or add new grantee..."
        value={grantees.find((grantee) => grantee.id === value)}
      />

      {!disabled && !organization.isRemoteEquityEssentials && (
        <CreateGranteeButton
          defaultGranteeCreationValues={defaultGranteeCreationValues}
          mode="panel"
          onGranteeCreated={(granteeId) => {
            onGranteesUpdated?.();
            onChange(granteeId);
          }}
          organizationFragment={organization}
        />
      )}
    </div>
  );
};
