import { Text } from "@remote-com/norma";
import { IconV2DuotoneThumbsUp } from "@remote-com/norma/icons/IconV2DuotoneThumbsUp";

import { Section, SECTIONS_MAP } from "../services/EquityHandbookService";

export const EquityHandbookNothingToDeclareTag: React.FC<{
  section: Section;
}> = ({ section }) => {
  return (
    <div className="flex items-center gap-2 rounded-lg bg-greenLight-100 px-4 py-2 text-grey-600">
      <IconV2DuotoneThumbsUp className="h-4 w-4 text-greenLight-600" />
      <Text variant="sm">{SECTIONS_MAP[section].nothingToDeclareLabel}</Text>
    </div>
  );
};
