import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { AnimatePresence, motion } from "motion/react";
import React from "react";

import { Divider } from "./Divider";
import { Picture } from "./Picture";
import { Typography } from "./Typography";

const Header: React.FC<
  React.PropsWithChildren<{
    borderless?: boolean;
    icon?: React.ReactElement<React.ComponentProps<"svg">>;
    onClose: () => void;
    padding?: 6 | 10;
    subtitle?: React.ReactNode;
  }>
> = ({ borderless, children, icon, onClose, padding = 6, subtitle }) => {
  return (
    <div
      className={classNames("rounded-tl-lg bg-white py-6", {
        "border-b-[0.5px] border-gray-04": !borderless,
        "px-6": padding === 6,
        "px-10": padding === 10,
      })}
    >
      <div className="flex items-center gap-4">
        {icon && (
          <div className="flex items-center justify-center rounded-full bg-primary-01 p-1">
            {React.cloneElement(icon, {
              ...icon.props,
              className: classNames(
                icon.props.className,
                /* tailwind */ `h-6 w-6 text-primary`,
              ),
            })}
          </div>
        )}

        <div className="flex-1 space-y-1">
          <div className="flex gap-4">
            <DialogTitle as="h6">
              <Typography
                as="span"
                className="text-black-07"
                variant="Medium/Default"
              >
                {children}
              </Typography>
            </DialogTitle>
          </div>
          {subtitle && (
            <Typography
              className="block text-black-05"
              variant="Regular/Extra Small"
            >
              {subtitle}
            </Typography>
          )}
        </div>
        <div className="flex h-7 items-center">
          <button
            className="text-black-07 hover:text-black-09"
            onClick={onClose}
            type="button"
          >
            <span className="sr-only">Close panel</span>
            <XMarkIcon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
      </div>
    </div>
  );
};

const AlternativeHeader: React.FC<
  React.PropsWithChildren<{
    onClose: () => void;
    subtitle?: React.ReactNode;
  }>
> = ({ children, onClose, subtitle }) => {
  return (
    <>
      <div className="relative space-y-1 rounded-tl-lg bg-gray-01 py-6 pl-10 pr-6">
        <button
          className="absolute -left-13 top-4 flex h-9 w-9 items-center justify-center rounded-full bg-gray-04"
          onClick={onClose}
          type="button"
        >
          <XMarkIcon className="h-5 w-5 text-white" />
        </button>
        <DialogTitle>
          <Typography
            as="div"
            className="text-black-05"
            variant="Regular/Caption"
          >
            {children}
          </Typography>
          <Typography as="div" variant="Medium/Default">
            {subtitle}
          </Typography>
        </DialogTitle>
      </div>
      <Divider />
    </>
  );
};

const NotionHeader: React.FC<
  React.PropsWithChildren<{
    backgroundImage?: string;
    emoji?: React.ReactNode;
    onClose: () => void;
    subtitle?: React.ReactNode;
  }>
> = ({ backgroundImage, children, emoji, onClose, subtitle }) => {
  return (
    <>
      <div className="relative flex h-36 gap-6 rounded-tl-lg bg-gray-01 px-6">
        {backgroundImage && (
          <div className="absolute inset-0 flex h-full justify-end overflow-hidden">
            <Picture className="w-1/3 object-cover" image={backgroundImage} />
          </div>
        )}
        <button
          className="absolute -left-13 top-4 flex h-9 w-9 items-center justify-center rounded-full bg-gray-07"
          onClick={onClose}
          type="button"
        >
          <XMarkIcon className="h-5 w-5 text-white" />
        </button>
        {emoji && (
          <div className="mt-4 flex h-42 w-48 items-center justify-center rounded-lg border-[0.5px] border-gray-04 bg-white font-emoji text-[70px] leading-7">
            {emoji}
          </div>
        )}
        <DialogTitle as="div" className="flex flex-col justify-center">
          <Typography
            as="div"
            className="uppercase text-black-05"
            variant="Regular/Caption"
          >
            {children}
          </Typography>
          <Typography as="div" variant="Medium/Default">
            {subtitle}
          </Typography>
        </DialogTitle>
      </div>
      <Divider />
    </>
  );
};

const WIDTHS = {
  "2xl": classNames("max-w-2xl"),
  "4xl": classNames("max-w-4xl"),
  "600": classNames("max-w-[600px]"),
  904: classNames("max-w-[904px]"),
  sm: classNames("max-w-[401px]"),
  xl: classNames("max-w-xl"),
};

const SlideOver_: React.FC<{
  children?: React.ReactNode;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  onClose: () => void;
  onExited?: () => void;
  show: boolean;
  width?: keyof typeof WIDTHS;
}> = ({ children, footer, header, onClose, onExited, show, width = "2xl" }) => {
  return (
    <AnimatePresence mode="wait" onExitComplete={onExited}>
      {show && (
        <Dialog
          className={classNames("relative z-50")}
          onClose={onClose}
          open={show}
          static
        >
          <DialogBackdrop
            animate={{ opacity: 1 }}
            as={motion.div}
            className="fixed inset-0 bg-black/30"
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
          />
          <div className="fixed inset-0">
            <DialogPanel
              animate={{ x: 0 }}
              as={motion.div}
              className={classNames(
                "fixed inset-y-4 right-4 flex w-full flex-1 flex-col overflow-hidden rounded-lg bg-white",
                WIDTHS[width],
              )}
              data-cy="SlideOver"
              exit={{ x: "100%" }}
              initial={{ x: "100%" }}
            >
              {header}
              <div className="flex-1 overflow-y-auto">{children}</div>
              {footer && (
                <div className="border-t-[0.5px] border-gray-04">{footer}</div>
              )}
            </DialogPanel>
          </div>
        </Dialog>
      )}
    </AnimatePresence>
  );
};

export const SlideOver = Object.assign(SlideOver_, {
  AlternativeHeader,
  Header,
  NotionHeader,
});
