/**
 * @generated SignedSource<<2a733552ebe866c66884e3e65c7693b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GrantOwnershipRow_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FormattedCurrency_Organization" | "useOrganizationSharesUtil_Organization">;
  readonly " $fragmentType": "GrantOwnershipRow_Organization";
};
export type GrantOwnershipRow_Organization$key = {
  readonly " $data"?: GrantOwnershipRow_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantOwnershipRow_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantOwnershipRow_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationSharesUtil_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedCurrency_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "6421fac44080e042d1946008aa95076d";

export default node;
