/**
 * @generated SignedSource<<02b64f8e74a520da6f3eb3ca0604683a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DraftGrantDetailsComponent_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DraftGrantDetailsGrant_Organization" | "DraftGrantDetailsHeader_Organization" | "DraftGrantVestingPreview_Organization" | "FormattedFMV_Organization" | "GranteeDetailsBox_Organization">;
  readonly " $fragmentType": "DraftGrantDetailsComponent_Organization";
};
export type DraftGrantDetailsComponent_Organization$key = {
  readonly " $data"?: DraftGrantDetailsComponent_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"DraftGrantDetailsComponent_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DraftGrantDetailsComponent_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedFMV_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeDetailsBox_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DraftGrantDetailsGrant_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DraftGrantDetailsHeader_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DraftGrantVestingPreview_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "6437b91c08fbb142cd577d444368b75c";

export default node;
