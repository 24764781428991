import { Pill } from "@remote-com/norma";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { compact } from "lodash";
import React, { useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { CtmsGrantsTableRowGranteeCell } from "../../../components/CtmsGrantsTableRowGranteeCell";
import { EmptyState } from "../../../components/EmptyState";
import { FormattedCurrency } from "../../../components/Formatted/FormattedCurrency";
import { FormattedNumber } from "../../../components/Formatted/FormattedNumber";
import { ShortDate } from "../../../components/ShortDate";
import { Table } from "../../../components/ui/Table";
import {
  RSUVestingTable_CTMSGrant$data,
  RSUVestingTable_CTMSGrant$key,
} from "./__generated__/RSUVestingTable_CTMSGrant.graphql";
import { RSUVestingTable_Organization$key } from "./__generated__/RSUVestingTable_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment RSUVestingTable_Organization on Organization {
    ...FormattedCurrency_Organization
  }
`;

const CTMS_GRANT_FRAGMENT = graphql`
  fragment RSUVestingTable_CTMSGrant on CTMSGrant
  @relay(plural: true)
  @argumentDefinitions(
    vestingDataPointsFilters: { type: "CTMSGrantVestingDataPointsFilters" }
  ) {
    label
    grantee {
      name
      ...CtmsGrantsTableRowGranteeCell_Grantee
    }
    vestingDataPoints(filters: $vestingDataPointsFilters) {
      date
      newlyVested
      fairMarketValueAtDate {
        value
      }
    }
  }
`;

type CTMSGrant = RSUVestingTable_CTMSGrant$data[number];

type VestingEvent = CTMSGrant["vestingDataPoints"][number];

const columnHelper = createColumnHelper<{
  ctmsGrant: CTMSGrant;
  vestingEvent: VestingEvent;
}>();

export const RSUVestingTable: React.FC<{
  ctmsGrantsFragment: RSUVestingTable_CTMSGrant$key;
  organizationFragment: RSUVestingTable_Organization$key;
}> = ({ ctmsGrantsFragment, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const ctmsGrants = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantsFragment);

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.ctmsGrant.label, {
        cell: (context) => <Pill tone="bayoux">{context.getValue()}</Pill>,
        enableSorting: true,
        header: () => "Grant",
        id: "grantLabel",
        sortingFn: "text",
      }),
      columnHelper.accessor((row) => row.ctmsGrant.grantee.name, {
        cell: (context) => {
          const { ctmsGrant } = context.row.original;
          return (
            <CtmsGrantsTableRowGranteeCell
              granteeFragment={ctmsGrant.grantee}
            />
          );
        },
        enableSorting: true,
        header: () => "Employee",
        id: "employee",
        sortingFn: "text",
      }),
      columnHelper.accessor((row) => row.vestingEvent.date, {
        cell: (context) => <ShortDate value={context.getValue()} />,
        enableSorting: true,
        header: () => "Vesting date",
        id: "vestingDate",
        sortingFn: "datetime",
      }),
      columnHelper.accessor((row) => row.vestingEvent.newlyVested, {
        cell: (context) => (
          <>
            <FormattedNumber value={context.getValue()} /> shares
          </>
        ),
        enableSorting: true,
        header: () => "Quantity vested",
        id: "quantityVested",
      }),
      columnHelper.accessor(
        (row) => row.vestingEvent.fairMarketValueAtDate?.value ?? null,
        {
          cell: (context) => {
            const priceOfOneShare = context.getValue();
            if (priceOfOneShare === null) {
              return "-";
            }
            return (
              <FormattedCurrency
                organizationFragment={organization}
                value={priceOfOneShare}
              />
            );
          },
          enableSorting: true,
          header: () => "Price of one share",
          id: "priceOfOneShare",
        },
      ),
      columnHelper.accessor(
        (row) =>
          row.vestingEvent.fairMarketValueAtDate
            ? row.vestingEvent.newlyVested *
              row.vestingEvent.fairMarketValueAtDate.value
            : null,
        {
          cell: (context) => {
            const taxableBasis = context.getValue();
            if (taxableBasis === null) {
              return "-";
            }
            return (
              <FormattedCurrency
                organizationFragment={organization}
                value={taxableBasis}
              />
            );
          },
          enableSorting: true,
          header: () => "Taxable basis",
          id: "taxableBasis",
        },
      ),
    ],
    [organization],
  );

  const data = useMemo(
    () =>
      ctmsGrants.flatMap((ctmsGrant) =>
        ctmsGrant.vestingDataPoints.map((vestingEvent) => ({
          ctmsGrant,
          vestingEvent,
        })),
      ),
    [ctmsGrants],
  );

  const table = useReactTable({
    columns: compact(columns),
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (data.length === 0) {
    return (
      <EmptyState>No RSU vesting events found for selected period</EmptyState>
    );
  }

  return <Table.Smart table={table} />;
};
