/**
 * @generated SignedSource<<7dc443bb017d257ba66759d45d1edf45>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExercisesActivity_Organization$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"CurrencySymbol_Organization" | "ExercisesActivityExercisableSharesBoxes_Organization" | "ExercisesActivityGraph_Organization">;
  readonly " $fragmentType": "ExercisesActivity_Organization";
};
export type ExercisesActivity_Organization$key = {
  readonly " $data"?: ExercisesActivity_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExercisesActivity_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExercisesActivity_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExercisesActivityGraph_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExercisesActivityExercisableSharesBoxes_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurrencySymbol_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "116f37e4a277dff7ae857780d623fb7b";

export default node;
