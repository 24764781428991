/**
 * @generated SignedSource<<1d85c6a8011a4497fc333e05d24c408b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Grantees_Viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationGranteesTable_Viewer">;
  readonly " $fragmentType": "Grantees_Viewer";
};
export type Grantees_Viewer$key = {
  readonly " $data"?: Grantees_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Grantees_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Grantees_Viewer",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "organizationId",
          "variableName": "organizationId"
        }
      ],
      "kind": "FragmentSpread",
      "name": "OrganizationGranteesTable_Viewer"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "cd20a5a1892507acbbedb56dd31e62b8";

export default node;
