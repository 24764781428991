import { ChevronRightIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

import { Typography } from "./ui/Typography";

const ICON_COLOR_CLASSNAMES = {
  blue: {
    container: classNames("bg-primary-01"),
    icon: classNames("text-primary-05"),
  },
  green: {
    container: classNames("bg-green-01"),
    icon: classNames("text-green-05"),
  },
  purple: {
    container: classNames("bg-purple-01"),
    icon: classNames("text-purple-05"),
  },
};

export const Icon: React.FC<{
  color: keyof typeof ICON_COLOR_CLASSNAMES;
  icon: React.ComponentType<{ className?: string }>;
}> = ({ color, icon: Icon }) => {
  return (
    <div
      className={classNames(
        ICON_COLOR_CLASSNAMES[color].container,
        "flex items-center justify-center rounded-full p-2",
      )}
    >
      <Icon className={classNames(ICON_COLOR_CLASSNAMES[color].icon, "w-5")} />
    </div>
  );
};

const COLOR_VARIANTS = {
  blue: {
    chevron: "text-white",
    container: "bg-gradient-linear-onboarding text-white",
    subtitle: "text-white text-opacity-80",
  },
  default: {
    chevron: "text-black-07",
    container: "",
    subtitle: "text-gray-09",
  },
};

type ColorVariant = keyof typeof COLOR_VARIANTS;

const _ButtonBanner = React.forwardRef<
  HTMLAnchorElement,
  React.ComponentProps<typeof Link> & {
    actionText?: string;
    icon?: React.ReactNode;
    subtitle?: React.ReactNode;
    tag?: React.ReactNode;
    variant?: ColorVariant;
  }
>(function ButtonBanner(
  {
    actionText,
    children,
    className,
    icon,
    subtitle,
    tag,
    variant = "default",
    ...props
  },
  ref,
) {
  const colorScheme = COLOR_VARIANTS[variant];

  return (
    <Link
      className={classNames(
        className,
        colorScheme.container,
        "flex items-center gap-6 rounded-lg border-[0.5px] border-gray-04 p-6 shadow-100 transition-all hover:shadow-200",
      )}
      ref={ref}
      {...props}
    >
      {icon && <div>{icon}</div>}
      <div className="flex-1 space-y-2">
        <div className="flex items-center gap-2">
          <Typography as="div" variant="Medium/Small">
            {children}
          </Typography>
          {tag}
        </div>
        {subtitle && (
          <Typography
            as="div"
            className={classNames(colorScheme.subtitle)}
            variant="Regular/Extra Small"
          >
            {subtitle}
          </Typography>
        )}
      </div>
      <div className="flex items-center gap-2">
        {actionText && (
          <Typography as="span" variant="Medium/Small">
            {actionText}
          </Typography>
        )}
        <ChevronRightIcon className={classNames("w-5", colorScheme.chevron)} />
      </div>
    </Link>
  );
});

export const ButtonBanner = Object.assign(_ButtonBanner, {
  Icon,
});
