import { Pill } from "@remote-com/norma";
import { graphql, useFragment } from "react-relay";

import {
  ExerciseStatus,
  ExerciseStatusTag_CTMSGrant$key,
} from "./__generated__/ExerciseStatusTag_CTMSGrant.graphql";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment ExerciseStatusTag_CTMSGrant on CTMSGrant {
    settled
    exerciseStatus
  }
`;

const EXERCISE_STATUS_TAGS: Record<ExerciseStatus, React.ReactNode> = {
  FullyExercised: <Pill tone="success">Fully exercised</Pill>,
  NotExercised: <Pill tone="neutralDark">Not exercised</Pill>,
  PartiallyExercised: <Pill tone="blue">Partially exercised</Pill>,
};

export const ExerciseStatusTag: React.FC<{
  ctmsGrantFragment: ExerciseStatusTag_CTMSGrant$key;
}> = ({ ctmsGrantFragment }) => {
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);
  if (ctmsGrant.settled) {
    return <Pill tone="warning">Settled</Pill>;
  }
  return EXERCISE_STATUS_TAGS[ctmsGrant.exerciseStatus];
};
