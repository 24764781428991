import { Avatar } from "@remote-com/norma";
import React, { useCallback } from "react";
import { useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { InviteEquityViewerSlideOver } from "../../../components/InviteEquityViewerSlideOver";
import { Page } from "../../../components/Page";
import { useToaster } from "../../../components/Toaster";
import { BreadCrumb } from "../../../components/ui/BreadCrumb";
import { Button } from "../../../components/ui/Button";
import { CenteredColumnLayout } from "../../../components/ui/Layout/CenteredColumnLayout";
import { NoticeMessage } from "../../../components/ui/NoticeMessage";
import { Toast } from "../../../components/ui/Toast";
import { Typography } from "../../../components/ui/Typography";
import { WarningButton } from "../../../components/ui/WarningButton";
import { useBoolean } from "../../../hooks/useBoolean";
import { useQuery } from "../../../hooks/useQuery";
import { useSafeMutation } from "../../../hooks/useSafeMutation";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { OrganizationSettingsEquityViewers_Item_EquityViewer$key } from "./__generated__/OrganizationSettingsEquityViewers_Item_EquityViewer.graphql";
import { OrganizationSettingsEquityViewers_Organization$key } from "./__generated__/OrganizationSettingsEquityViewers_Organization.graphql";
import { OrganizationSettingsEquityViewers_Query } from "./__generated__/OrganizationSettingsEquityViewers_Query.graphql";
import { OrganizationSettingsEquityViewers_RemoveEquityViewer_Mutation } from "./__generated__/OrganizationSettingsEquityViewers_RemoveEquityViewer_Mutation.graphql";
import { SettingsContainer } from "./SettingsContainer";

const REMOVE_EQUITY_VIEWER_MUTATION = graphql`
  mutation OrganizationSettingsEquityViewers_RemoveEquityViewer_Mutation(
    $equityViewerId: UUID!
  ) {
    removeEquityViewer(id: $equityViewerId) {
      ...OrganizationSettingsEquityViewers_Organization
    }
  }
`;

const ITEM_EQUITY_VIEWER_FRAGMENT = graphql`
  fragment OrganizationSettingsEquityViewers_Item_EquityViewer on EquityViewer {
    id
    account {
      id
      firstName
      lastName
      email
    }
  }
`;

const Item: React.FC<{
  equityViewerFragment: OrganizationSettingsEquityViewers_Item_EquityViewer$key;
}> = ({ equityViewerFragment }) => {
  const equityViewer = useFragment(
    ITEM_EQUITY_VIEWER_FRAGMENT,
    equityViewerFragment,
  );
  const toaster = useToaster();
  const [removeEquityViewer, removeEquityViewerIsInFlight] =
    useSafeMutation<OrganizationSettingsEquityViewers_RemoveEquityViewer_Mutation>(
      REMOVE_EQUITY_VIEWER_MUTATION,
    );
  const handleRemoveEquityViewerButtonClick = useCallback(async () => {
    await removeEquityViewer({
      variables: { equityViewerId: equityViewer.id },
    });

    toaster.push(
      <Toast title="Great!">Equity viewer successfully removed!</Toast>,
    );
  }, [equityViewer.id, removeEquityViewer, toaster]);

  return (
    <SettingsContainer.Item.MultipleValues.Item className="group flex items-center gap-2 transition-all hover:bg-gray-01">
      <Avatar name={equityViewer.account.firstName} size="sm" />
      <div className="flex-1 space-y-1">
        <Typography as="div" variant="Regular/Extra Small">
          {equityViewer.account.firstName} {equityViewer.account.lastName}
        </Typography>
        <Typography
          as="div"
          className="text-black-05"
          variant="Regular/Extra Small"
        >
          {equityViewer.account.email}
        </Typography>
      </div>
      <WarningButton
        className="opacity-0 group-hover:opacity-100"
        loading={removeEquityViewerIsInFlight}
        onConfirm={handleRemoveEquityViewerButtonClick}
        size="small"
        variant="Danger Outline"
        warningContent={`This is going to remove ${equityViewer.account.firstName}
            ${equityViewer.account.lastName} from your Equity Viewers. Please confirm this
            action`}
        warningTitle="Remove equity viewer"
      >
        Remove
      </WarningButton>
    </SettingsContainer.Item.MultipleValues.Item>
  );
};

const ORGANIZATION_FRAGMENT = graphql`
  fragment OrganizationSettingsEquityViewers_Organization on Organization {
    id
    name
    equityViewers {
      id
      ...OrganizationSettingsEquityViewers_Item_EquityViewer
    }
  }
`;

const OrganizationSettingsEquityViewersPage_: React.FC<{
  organizationFragment: OrganizationSettingsEquityViewers_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const {
    setFalse: hideInviteEquityViewerSlideOver,
    setTrue: showInviteEquityViewerSlideOver,
    value: inviteEquityViewerSlideOverIsShown,
  } = useBoolean(false);

  return (
    <CenteredColumnLayout
      breadcrumbs={
        <BreadCrumb>
          <BreadCrumb.Link
            to={generatePath(APPLICATION_ROUTES.organizationHome, {
              organizationId: organization.id,
            })}
          >
            {organization.name}
          </BreadCrumb.Link>
          <BreadCrumb.Link to="..">Settings</BreadCrumb.Link>
          <BreadCrumb.Link to=".">Equity viewers</BreadCrumb.Link>
        </BreadCrumb>
      }
    >
      <InviteEquityViewerSlideOver
        onClose={hideInviteEquityViewerSlideOver}
        organizationId={organization.id}
        show={inviteEquityViewerSlideOverIsShown}
      />

      <SettingsContainer
        action={
          <Button onClick={showInviteEquityViewerSlideOver} size="small">
            + Invite new
          </Button>
        }
        subTitle="Here is the list of users that have an equity viewer access. They can view the company equity status, grantees and grants, and drafts funnel."
        title="Equity viewers"
      >
        <SettingsContainer.Item>
          {organization.equityViewers.length > 0 ? (
            <SettingsContainer.Item.MultipleValues>
              {organization.equityViewers.map((equityViewer) => (
                <Item
                  equityViewerFragment={equityViewer}
                  key={equityViewer.id}
                />
              ))}
            </SettingsContainer.Item.MultipleValues>
          ) : (
            <NoticeMessage size="Small">
              You have no equity viewer.
            </NoticeMessage>
          )}
        </SettingsContainer.Item>
      </SettingsContainer>
    </CenteredColumnLayout>
  );
};

const QUERY = graphql`
  query OrganizationSettingsEquityViewers_Query(
    $organizationId: OrganizationId!
  ) {
    organization(id: $organizationId) {
      id
      name
      ...OrganizationSettingsEquityViewers_Organization
    }
  }
`;

const OrganizationSettingsEquityViewersPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<OrganizationSettingsEquityViewers_Query>(QUERY, {
    organizationId,
  });

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Equity Viewers Settings"
      analyticsName="Admin - Equity Viewers Settings"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} organization equity viewers`}
    >
      <OrganizationSettingsEquityViewersPage_
        organizationFragment={query.organization}
      />
    </Page>
  );
};

export default OrganizationSettingsEquityViewersPage;
