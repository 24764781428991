/**
 * @generated SignedSource<<14ba81737899bf8561f19c43f4bd61cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CTMSGrantStatus = "Active" | "Canceled" | "Terminated";
export type RepricingForm_Grants_Query$variables = {
  organizationId: string;
};
export type RepricingForm_Grants_Query$data = {
  readonly organization: {
    readonly ctmsGrants: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly exercisePrice: number | null;
          readonly grantStatus: CTMSGrantStatus;
          readonly grantee: {
            readonly id: string;
            readonly name: string;
            readonly taxResidenceCountry: {
              readonly emoji: string;
              readonly name: string;
            } | null;
          };
          readonly id: string;
          readonly label: string;
          readonly organizationId: string;
          readonly quantityIssued: number;
          readonly repricedFromCTMSGrant: {
            readonly id: string;
          } | null;
          readonly repricedToCTMSGrant: {
            readonly id: string;
          } | null;
          readonly vestingStartDate: string | null;
          readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantStatusTag_CtmsGrant">;
        };
      }>;
    };
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"FormattedCurrency_Organization">;
  };
};
export type RepricingForm_Grants_Query = {
  response: RepricingForm_Grants_Query$data;
  variables: RepricingForm_Grants_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "filters",
    "value": {
      "statusIn": [
        "Active",
        "Terminated",
        "Canceled"
      ]
    }
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "organizationId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "grantStatus",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vestingStartDate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantityIssued",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "exercisePrice",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Grantee",
  "kind": "LinkedField",
  "name": "grantee",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "taxResidenceCountry",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emoji",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = [
  (v4/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "CTMSGrant",
  "kind": "LinkedField",
  "name": "repricedFromCTMSGrant",
  "plural": false,
  "selections": (v12/*: any*/),
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "CTMSGrant",
  "kind": "LinkedField",
  "name": "repricedToCTMSGrant",
  "plural": false,
  "selections": (v12/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RepricingForm_Grants_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": (v3/*: any*/),
              "concreteType": "OrganizationCTMSGrantsConnection",
              "kind": "LinkedField",
              "name": "ctmsGrants",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrganizationCTMSGrantsEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "CTMSGrant",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v4/*: any*/),
                        (v5/*: any*/),
                        (v6/*: any*/),
                        (v7/*: any*/),
                        (v8/*: any*/),
                        (v9/*: any*/),
                        (v10/*: any*/),
                        (v11/*: any*/),
                        (v13/*: any*/),
                        (v14/*: any*/),
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "CtmsGrantStatusTag_CtmsGrant"
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": "ctmsGrants(filters:{\"statusIn\":[\"Active\",\"Terminated\",\"Canceled\"]})"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "FormattedCurrency_Organization"
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RepricingForm_Grants_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "OrganizationCTMSGrantsConnection",
            "kind": "LinkedField",
            "name": "ctmsGrants",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationCTMSGrantsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CTMSGrant",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isActive",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "fullyVested",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "settled",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "ctmsGrants(filters:{\"statusIn\":[\"Active\",\"Terminated\",\"Canceled\"]})"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "defaultCurrencyCode",
                "storageKey": null
              }
            ],
            "type": "IOrganization",
            "abstractKey": "__isIOrganization"
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bd9ab9a296b74d9440d9e9be605b80d4",
    "id": null,
    "metadata": {},
    "name": "RepricingForm_Grants_Query",
    "operationKind": "query",
    "text": "query RepricingForm_Grants_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    name\n    ctmsGrants(filters: {statusIn: [Active, Terminated, Canceled]}) {\n      edges {\n        node {\n          id\n          organizationId\n          label\n          grantStatus\n          vestingStartDate\n          quantityIssued\n          exercisePrice\n          grantee {\n            id\n            name\n            taxResidenceCountry {\n              name\n              emoji\n            }\n          }\n          repricedFromCTMSGrant {\n            id\n          }\n          repricedToCTMSGrant {\n            id\n          }\n          ...CtmsGrantStatusTag_CtmsGrant\n        }\n      }\n    }\n    ...FormattedCurrency_Organization\n    id\n  }\n}\n\nfragment CtmsGrantStatusTag_CtmsGrant on CTMSGrant {\n  grantStatus\n  isActive\n  fullyVested\n  settled\n  ...CtmsGrantVestingTag_CtmsGrant\n}\n\nfragment CtmsGrantVestingTag_CtmsGrant on CTMSGrant {\n  fullyVested\n}\n\nfragment FormattedCurrency_Organization on IOrganization {\n  __isIOrganization: __typename\n  defaultCurrencyCode\n}\n"
  }
};
})();

(node as any).hash = "d3b6b8cdb0c09512aa33b6e991ee1853";

export default node;
