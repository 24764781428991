import classNames from "classnames";
import { isNil } from "lodash";
import { default as React, useMemo } from "react";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useCurrencyFormatter } from "../hooks/useCurrencyFormatter";
import { GranteePortalProjectionScenarioKey } from "./__generated__/ProjectionGraph_GranteePortalProjectionScenarios.graphql";
import { ProjectionGraph_Organization$key } from "./__generated__/ProjectionGraph_Organization.graphql";
import { BarGraph } from "./BarGraph";
import { FormattedCurrency } from "./Formatted/FormattedCurrency";
import { FormattedCurrencyCompact } from "./Formatted/FormattedCurrencyCompact";
import { TooltipVerticallyLabeledValue } from "./ui/TooltipContainer";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment ProjectionGraph_Organization on IOrganization {
    ...useCurrencyFormatter_Organization
    ...FormattedCurrency_Organization
    ...FormattedCurrencyCompact_Organization
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
graphql`
  fragment ProjectionGraph_GranteePortalProjectionScenarios on GranteePortalProjectionScenario {
    key
  }
`;

type ProjectionGraphBarKey =
  | "VALUE_AT_GRANT"
  | GranteePortalProjectionScenarioKey;

export const ProjectionGraphClassNames: Record<
  ProjectionGraphBarKey,
  { full: string; light: string }
> = {
  CURRENT_VALUE: {
    full: classNames("bg-primary-05 fill-primary-05"),
    light: classNames("bg-primary-02 fill-primary-02"),
  },
  FIRST_ROUND: {
    full: classNames("bg-purple-05 fill-purple-05"),
    light: classNames("bg-purple-02 fill-purple-02"),
  },
  SECOND_ROUND: {
    full: classNames("bg-orange-05 fill-orange-05"),
    light: classNames("bg-orange-02 fill-orange-02"),
  },
  THIRD_ROUND: {
    full: classNames("bg-yellow-05 fill-yellow-05"),
    light: classNames("bg-yellow-02 fill-yellow-02"),
  },
  VALUE_AT_GRANT: {
    full: classNames("bg-glass-green-05 fill-glass-green-05"),
    light: classNames("bg-glass-green-02 fill-glass-green-02"),
  },
};

export interface ProjectionGraphBar {
  key: ProjectionGraphBarKey;
  label: string;
  tooltip: React.ReactNode;
  value: number;
}

const ProjectionGraph_: React.FC<{
  bars: ProjectionGraphBar[];
  organizationFragment: ProjectionGraph_Organization$key;
}> = ({ bars: _bars, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const stacks = useMemo(
    () =>
      _bars.map(
        (bar) =>
          ({
            elements: [
              {
                ...bar,
                className: ProjectionGraphClassNames[bar.key].full,
                legendLabel: bar.label,
              },
            ],
            key: bar.key,
            label: bar.label,
          }) as const,
      ),
    [_bars],
  );

  const formatCurrency = useCurrencyFormatter({
    organizationFragment: organization,
  });

  return (
    <BarGraph
      barGap={8}
      barSize={56}
      hideXAxis
      legendOptions={{ fullWidth: false }}
      renderTooltip={({ stackElement }) => stackElement.tooltip}
      showLegend
      stacks={stacks}
      yLabel=""
      yTickFormatter={(value: number) =>
        formatCurrency(value, {
          compactDisplay: "short",
          maximumFractionDigits: 2,
          minimumFractionDigits: 0,
          notation: "compact",
        })
      }
    />
  );
};

const Tooltip: React.FC<{
  barKey: ProjectionGraphBarKey;
  children?: React.ReactNode;
  label: string;
  multiplier?: number;
  organizationFragment: ProjectionGraph_Organization$key;
  potentialValueUSD?: number;
  valueUSD?: number;
}> = ({
  barKey,
  children,
  label,
  multiplier,
  organizationFragment,
  potentialValueUSD,
  valueUSD,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <Typography
      as="div"
      className="flex w-[300px] flex-col gap-2 rounded bg-black-07 px-4 py-2 text-white"
      variant="Regular/Extra Small"
    >
      <div className="flex items-center gap-2 uppercase">
        <div
          className={classNames(
            "h-[10px] w-[10px] rounded-sm",
            ProjectionGraphClassNames[barKey].full,
          )}
        ></div>
        <span>{label}</span>
      </div>

      <div className="flex gap-2">
        {!isNil(valueUSD) && (
          <TooltipVerticallyLabeledValue className="flex-grow" label="Value">
            <FormattedCurrency
              maximumFractionDigits={0}
              organizationFragment={organization}
              value={valueUSD}
            />
          </TooltipVerticallyLabeledValue>
        )}
        {!isNil(multiplier) && (
          <TooltipVerticallyLabeledValue
            className="flex-grow"
            label="Multiplier"
          >
            x
            <FormattedNumber value={multiplier} />
          </TooltipVerticallyLabeledValue>
        )}
        {!isNil(potentialValueUSD) && (
          <TooltipVerticallyLabeledValue
            className="flex-grow"
            label="Potential value"
          >
            <FormattedCurrencyCompact
              organizationFragment={organization}
              value={potentialValueUSD}
            />
          </TooltipVerticallyLabeledValue>
        )}
      </div>

      {children}
    </Typography>
  );
};

export const ProjectionGraph = Object.assign(ProjectionGraph_, {
  Tooltip,
});
