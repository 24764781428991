import { isNil } from "lodash";
import { FormattedPlural } from "react-intl";
import { graphql, useFragment } from "react-relay";

import {
  BentoCard,
  BentoCardCell,
  BentoCardRow,
  BentoCardValue,
} from "../../../../components/BentoCard";
import { CurrencyUnit } from "../../../../components/CurrencyUnit";
import { FormattedCurrency } from "../../../../components/Formatted/FormattedCurrency";
import { FormattedNumber } from "../../../../components/Formatted/FormattedNumber";
import { useOrganizationSharesUtil } from "../../../../hooks/useOrganizationSharesUtil";
import { Section } from "../../Grantee/GranteeDetails/Section";
import { GrantDetailsOwnershipSection_CTMSGrant$key } from "./__generated__/GrantDetailsOwnershipSection_CTMSGrant.graphql";
import { GrantDetailsOwnershipSection_Organization$key } from "./__generated__/GrantDetailsOwnershipSection_Organization.graphql";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment GrantDetailsOwnershipSection_CTMSGrant on CTMSGrant {
    cumulativeVested
    quantityIssued
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment GrantDetailsOwnershipSection_Organization on Organization {
    latestPricePerShare {
      value
    }
    ...useOrganizationSharesUtil_Organization
    ...FormattedCurrency_Organization
    ...CurrencyUnit_Organization
  }
`;

export function GrantDetailsOwnershipSection({
  ctmsGrantFragment,
  organizationFragment,
}: {
  ctmsGrantFragment: GrantDetailsOwnershipSection_CTMSGrant$key;
  organizationFragment: GrantDetailsOwnershipSection_Organization$key;
}) {
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const { sharesToFullyDilutedRatio, sharesToValue } =
    useOrganizationSharesUtil({
      organizationFragment: organization,
    });

  const valueIssued = sharesToValue(ctmsGrant.quantityIssued);
  const ownershipIssued = sharesToFullyDilutedRatio(ctmsGrant.quantityIssued);

  return (
    <Section title="Ownership">
      <BentoCard>
        <BentoCardRow>
          <BentoCardCell title="Quantity">
            <BentoCardValue
              main={
                <FormattedNumber animated value={ctmsGrant.quantityIssued} />
              }
              sub={
                <FormattedPlural
                  one="share"
                  other="shares"
                  value={ctmsGrant.quantityIssued}
                />
              }
            />
          </BentoCardCell>
          {!isNil(valueIssued) && (
            <BentoCardCell title="Value">
              <BentoCardValue
                main={
                  <FormattedCurrency
                    animated
                    organizationFragment={organization}
                    value={valueIssued}
                  />
                }
                sub={<CurrencyUnit organizationFragment={organization} />}
              />
            </BentoCardCell>
          )}
          {!isNil(ownershipIssued) && (
            <BentoCardCell title="Percentage">
              <BentoCardValue
                main={
                  <FormattedNumber
                    animated
                    maximumFractionDigits={2}
                    value={ownershipIssued * 100}
                  />
                }
                sub="%"
              />
            </BentoCardCell>
          )}
        </BentoCardRow>
        <BentoCardRow>
          <BentoCardCell title="Total vested">
            <BentoCardValue
              main={
                <FormattedNumber animated value={ctmsGrant.cumulativeVested} />
              }
              sub={
                <>
                  /{" "}
                  <FormattedNumber animated value={ctmsGrant.quantityIssued} />{" "}
                  <FormattedPlural
                    one="share"
                    other="shares"
                    value={ctmsGrant.quantityIssued}
                  />
                </>
              }
            />
          </BentoCardCell>
          <BentoCardCell title="Current price per share">
            <BentoCardValue
              main={
                organization.latestPricePerShare ? (
                  <FormattedCurrency
                    animated
                    organizationFragment={organization}
                    value={organization.latestPricePerShare.value}
                  />
                ) : (
                  "-"
                )
              }
              sub={<CurrencyUnit organizationFragment={organization} />}
            />
          </BentoCardCell>
        </BentoCardRow>
      </BentoCard>
    </Section>
  );
}
