/**
 * @generated SignedSource<<50659ab8b8c27c182aedbadbf66cf1bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BoardMemberApprovalsTables_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"CurrencySymbol_Organization" | "FormattedCurrency_Organization" | "FormattedFMV_Organization" | "useOrganizationSharesUtil_Organization">;
  readonly " $fragmentType": "BoardMemberApprovalsTables_Organization";
};
export type BoardMemberApprovalsTables_Organization$key = {
  readonly " $data"?: BoardMemberApprovalsTables_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardMemberApprovalsTables_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BoardMemberApprovalsTables_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationSharesUtil_Organization"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedCurrency_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurrencySymbol_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedFMV_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "691428478beb2b95ba1208725c9ecf9b";

export default node;
