import { isEmpty } from "lodash";
import React, {
  startTransition,
  useCallback,
  useMemo,
  useState,
  useTransition,
} from "react";
import { FormattedMessage } from "react-intl";
import { useFragment, useRefetchableFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { EmptyListPlaceholder } from "../../../components/EmptyListPlaceholder/EmptyListPlaceholder";
import {
  GranteeTerminationCTMSGrantAmendmentRequestDeletionConfirmationModal,
  useGranteeTerminationCTMSGrantAmendmentRequestDeletionConfirmationModalState,
} from "../../../components/GranteeTerminationCTMSGrantAmendmentRequestDeletionConfirmationModal";
import { Page } from "../../../components/Page";
import { SelectedItemsActionCard } from "../../../components/SelectedItemsActionCard";
import { useToaster } from "../../../components/Toaster";
import { Button } from "../../../components/ui/Button";
import { Toast } from "../../../components/ui/Toast";
import { useApplicationName } from "../../../hooks/useApplicationTheme";
import { useDebounced } from "../../../hooks/useDebounced";
import { useDownloadeGranteeTerminationGrantAmendmentBoardConsentPreview } from "../../../hooks/useDownloadBoardConsentPreview";
import { useQuery } from "../../../hooks/useQuery";
import { useSafeMutation } from "../../../hooks/useSafeMutation";
import { LegalWorkflowLayout } from "../../../layouts/LegalWorkflowLayout";
import { useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Account$key } from "./__generated__/UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Account.graphql";
import { UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Organization$key } from "./__generated__/UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Organization.graphql";
import { UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Organization_RefetchQuery } from "./__generated__/UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Organization_RefetchQuery.graphql";
import { UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Query } from "./__generated__/UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Query.graphql";
import { UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_ReviewGrant_GranteeTerminationCTMSGrantAmendmentRequests_Mutation } from "./__generated__/UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_ReviewGrant_GranteeTerminationCTMSGrantAmendmentRequests_Mutation.graphql";
import { GranteeTerminationAmendmentRequestsTable } from "./GranteeTerminationAmendmentRequestsTable";

const ORGANIZATION_FRAGMENT = graphql`
  fragment UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Organization on Organization
  @argumentDefinitions(
    grantAmendmentRequestsSearch: { type: "String", defaultValue: "" }
  )
  @refetchable(
    queryName: "UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Organization_RefetchQuery"
  ) {
    id
    name
    underReviewAmendmentRequests: granteeTerminationCTMSGrantAmendmentRequests(
      statusIn: [UNDER_REVIEW]
    ) {
      id
      ...GranteeTerminationAmendmentRequestsTable_GranteeTerminationCTMSGrantAmendmentRequest
    }
    searchedUnderReviewAmendmentRequests: granteeTerminationCTMSGrantAmendmentRequests(
      statusIn: [UNDER_REVIEW]
      search: $grantAmendmentRequestsSearch
    ) {
      id
      ...GranteeTerminationAmendmentRequestsTable_GranteeTerminationCTMSGrantAmendmentRequest
    }
    ...LegalWorkflowLayout_Organization
    ...GranteeTerminationAmendmentRequestsTable_Organization
  }
`;

const ACCOUNT_FRAGMENT = graphql`
  fragment UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Account on Account {
    isSuperAdmin
  }
`;

const REVIEW_AMENDMENT_REQUESTS_MUTATION = graphql`
  mutation UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_ReviewGrant_GranteeTerminationCTMSGrantAmendmentRequests_Mutation(
    $granteeTerminationCTMSGrantAmendmentRequestIds: [UUID!]!
  ) {
    reviewGranteeTerminationCTMSGrantAmendmentRequests(
      granteeTerminationCTMSGrantAmendmentRequestIds: $granteeTerminationCTMSGrantAmendmentRequestIds
    ) {
      organization {
        ...UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Organization
      }
    }
  }
`;

const AdminAmendmentRequestsUnderReviewPage_: React.FC<{
  organizationFragment: UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Organization$key;
  viewerFragment: UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Account$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const [organization, refetchOrganization] = useRefetchableFragment<
    UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Organization_RefetchQuery,
    UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Organization$key
  >(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(ACCOUNT_FRAGMENT, viewerFragment);
  const [amendmentRequestSelection, setAmendmentRequestSelection] = useState<
    Set<string>
  >(new Set());
  const selectedAmendmentRequests = useMemo(
    () =>
      organization.underReviewAmendmentRequests.filter((grant) =>
        amendmentRequestSelection.has(grant.id),
      ),
    [amendmentRequestSelection, organization.underReviewAmendmentRequests],
  );

  const granteeTerminationCTMSGrantAmendmentRequestIds = useMemo(
    () =>
      selectedAmendmentRequests.map((amendmentRequest) => amendmentRequest.id),
    [selectedAmendmentRequests],
  );

  const [
    validateAmendmentRequests,
    validateAmendmentRequestsMutationIsInFlight,
  ] =
    useSafeMutation<UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_ReviewGrant_GranteeTerminationCTMSGrantAmendmentRequests_Mutation>(
      REVIEW_AMENDMENT_REQUESTS_MUTATION,
    );

  const toaster = useToaster();

  const [searchTransitionIsInProgress, startSearchTransition] = useTransition();

  const {
    isDebouncing: searchIsDebouncing,
    liveState: searchInputValue,
    setState: setSearchInputValue,
  } = useDebounced<string>({
    delay: 1000,
    initialState: "",
    onDebounce: useCallback(
      (search: string) => {
        startSearchTransition(() => {
          refetchOrganization({
            grantAmendmentRequestsSearch: search,
          });
        });
      },
      [refetchOrganization],
    ),
  });

  const handleDataUpdated = useCallback(() => {
    startTransition(() => {
      refetchOrganization({
        grantAmendmentRequestsSearch: searchInputValue,
      });
    });
  }, [refetchOrganization, searchInputValue]);

  const handleReviewAmendmentRequestsButtonClick = useCallback(async () => {
    await validateAmendmentRequests({
      variables: {
        granteeTerminationCTMSGrantAmendmentRequestIds,
      },
    });

    handleDataUpdated();

    setAmendmentRequestSelection(new Set());

    toaster.push(
      <Toast title="Wonderful!">
        <FormattedMessage
          defaultMessage={`{grantCount, plural,
            one {# amendment request}
            other {# amendment requests}
          } successfully reviewed!`}
          values={{
            grantCount: granteeTerminationCTMSGrantAmendmentRequestIds.length,
          }}
        />
      </Toast>,
    );
  }, [
    handleDataUpdated,
    granteeTerminationCTMSGrantAmendmentRequestIds,
    toaster,
    validateAmendmentRequests,
  ]);

  const {
    closeModal: closeDeleteConfirmationModal,
    showModal: openDeleteConfirmationModal,
    state: deleteConfirmationModalState,
  } = useGranteeTerminationCTMSGrantAmendmentRequestDeletionConfirmationModalState();

  const onDeletionCompleted = () => {
    setAmendmentRequestSelection(new Set());
    startTransition(() => {
      refetchOrganization({});
    });
  };

  const {
    downloadGranteeTerminationGrantAmendmentBoardConsentPreview,
    downloadIsInFlight,
  } = useDownloadeGranteeTerminationGrantAmendmentBoardConsentPreview({
    granteeTerminationCTMSGrantAmendmentRequestIds,
    organizationId: organization.id,
  });

  const applicationName = useApplicationName();

  return (
    <LegalWorkflowLayout
      onSearchChange={setSearchInputValue}
      organizationFragment={organization}
      searchIsLoading={searchTransitionIsInProgress || searchIsDebouncing}
      searchPlaceholder="Search amendments..."
      searchValue={searchInputValue}
      subtitle={`Grant amendments the ${applicationName} team is currently reviewing and validating`}
      title="Under review"
      topBarActionsRender={({ mainContentIsScrolled }) => {
        if (!viewer.isSuperAdmin) return null;

        return (
          <SelectedItemsActionCard
            actions={
              <>
                <Button
                  className="whitespace-nowrap"
                  disabled={isEmpty(selectedAmendmentRequests)}
                  loading={downloadIsInFlight}
                  onClick={
                    downloadGranteeTerminationGrantAmendmentBoardConsentPreview
                  }
                  size="small"
                  variant="Secondary Full"
                >
                  Preview board consent
                </Button>
                <Button
                  disabled={amendmentRequestSelection.size === 0}
                  onClick={() => {
                    openDeleteConfirmationModal([...amendmentRequestSelection]);
                  }}
                  size="small"
                  variant="Danger Outline"
                >
                  Delete
                </Button>
                <Button
                  disabled={isEmpty(selectedAmendmentRequests)}
                  loading={validateAmendmentRequestsMutationIsInFlight}
                  onClick={handleReviewAmendmentRequestsButtonClick}
                  size="small"
                  variant="Approve Light"
                >
                  Approve
                </Button>
              </>
            }
            compact={mainContentIsScrolled}
            emptyListLabel="Select amendments"
            itemCount={selectedAmendmentRequests.length}
            loading={validateAmendmentRequestsMutationIsInFlight}
            pluralLabel="amendments selected"
            singularLabel="amendment selected"
          />
        );
      }}
    >
      <GranteeTerminationCTMSGrantAmendmentRequestDeletionConfirmationModal
        onClose={closeDeleteConfirmationModal}
        onDeletionCompleted={onDeletionCompleted}
        state={deleteConfirmationModalState}
      />
      {isEmpty(organization.underReviewAmendmentRequests) ? (
        <EmptyListPlaceholder
          hideImage
          subtitle="You will see items here if you initiate grant amendments"
          title="No grant amendment request under review"
        />
      ) : (
        <GranteeTerminationAmendmentRequestsTable
          amendmentRequestFragment={
            organization.searchedUnderReviewAmendmentRequests
          }
          disableRowSelection={!viewer.isSuperAdmin}
          emptyListPlaceholder="No amendments found"
          grantSelection={amendmentRequestSelection}
          onDeleteGrantAmendmentRequestClick={(grantAmendmentRequestId) => {
            openDeleteConfirmationModal([grantAmendmentRequestId]);
          }}
          onGrantSelectionChange={setAmendmentRequestSelection}
          organizationFragment={organization}
          showActionsColumn={viewer.isSuperAdmin}
        />
      )}
    </LegalWorkflowLayout>
  );
};

const QUERY = graphql`
  query UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Query(
    $organizationId: OrganizationId!
  ) {
    organization(id: $organizationId) {
      id
      name
      ...UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Organization
    }
    me {
      ...UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Account
    }
  }
`;

const AdminAmendmentRequestsUnderReviewPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query } =
    useQuery<UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Query>(
      QUERY,
      {
        organizationId,
      },
    );

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Amendment Requests"
      analyticsName="Admin - Under Review Amendment Requests"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} under review amendment requests`}
    >
      <AdminAmendmentRequestsUnderReviewPage_
        organizationFragment={query.organization}
        viewerFragment={query.me}
      />
    </Page>
  );
};

export default AdminAmendmentRequestsUnderReviewPage;
