/**
 * @generated SignedSource<<ca51bba7e7d0de7225b0d7a85853a2e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VestingScheduleSection_DraftGrants$data = ReadonlyArray<{
  readonly vestingDataPoints: ReadonlyArray<{
    readonly __typename: "VestingDataPoint";
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"EmployeeVestingGraph_DraftGrants" | "VestingScheduleLegend_DraftGrants">;
  readonly " $fragmentType": "VestingScheduleSection_DraftGrants";
}>;
export type VestingScheduleSection_DraftGrants$key = ReadonlyArray<{
  readonly " $data"?: VestingScheduleSection_DraftGrants$data;
  readonly " $fragmentSpreads": FragmentRefs<"VestingScheduleSection_DraftGrants">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "VestingScheduleSection_DraftGrants",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "VestingDataPoint",
      "kind": "LinkedField",
      "name": "vestingDataPoints",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmployeeVestingGraph_DraftGrants"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VestingScheduleLegend_DraftGrants"
    }
  ],
  "type": "EasopGrant",
  "abstractKey": null
};

(node as any).hash = "546dc53591eae4c3e885389938495c4a";

export default node;
