import { Pill } from "@remote-com/norma";
import { graphql, useFragment } from "react-relay";

import { CtmsGrantVestingTag_CtmsGrant$key } from "./__generated__/CtmsGrantVestingTag_CtmsGrant.graphql";

const GRANT_FRAGMENT = graphql`
  fragment CtmsGrantVestingTag_CtmsGrant on CTMSGrant {
    fullyVested
  }
`;

export const CtmsGrantVestingTag: React.FC<{
  ctmsGrantFragment: CtmsGrantVestingTag_CtmsGrant$key;
}> = ({ ctmsGrantFragment }) => {
  const ctmsGrant = useFragment(GRANT_FRAGMENT, ctmsGrantFragment);

  return !ctmsGrant.fullyVested ? (
    <Pill>Vesting</Pill>
  ) : (
    <Pill tone="success">Fully vested</Pill>
  );
};
