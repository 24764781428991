/**
 * @generated SignedSource<<60e447e48737f3e4659fe6b97028e303>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewCtmsGrantDetails_CTMSGrant$data = {
  readonly grantee: {
    readonly id: string;
    readonly name: string;
  };
  readonly label: string;
  readonly matchingInstrument: {
    readonly equityType: {
      readonly exercisable: boolean;
      readonly humanReadableName: string;
    } | null;
  } | null;
  readonly repricedFromCTMSGrant: {
    readonly " $fragmentSpreads": FragmentRefs<"RepricedFromCtmsGrantNoticeMessage_CtmsGrant">;
  } | null;
  readonly repricedToCTMSGrant: {
    readonly " $fragmentSpreads": FragmentRefs<"RepricedToCtmsGrantNoticeMessage_CtmsGrant">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantVestingStatusPill_CTMSGrant" | "GrantDetailsDocumentsSection_CTMSGrant" | "GrantDetailsEmployeeSection_CTMSGrant" | "GrantDetailsExerciseSection_CTMSGrant" | "GrantDetailsInformationSection_CTMSGrant" | "GrantDetailsOwnershipSection_CTMSGrant" | "GrantDetailsVestingScheduleSection_CTMSGrant">;
  readonly " $fragmentType": "NewCtmsGrantDetails_CTMSGrant";
};
export type NewCtmsGrantDetails_CTMSGrant$key = {
  readonly " $data"?: NewCtmsGrantDetails_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewCtmsGrantDetails_CTMSGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewCtmsGrantDetails_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "grantee",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "repricedFromCTMSGrant",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RepricedFromCtmsGrantNoticeMessage_CtmsGrant"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "repricedToCTMSGrant",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RepricedToCtmsGrantNoticeMessage_CtmsGrant"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "matchingInstrument",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EquityType",
          "kind": "LinkedField",
          "name": "equityType",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "exercisable",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "humanReadableName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CtmsGrantVestingStatusPill_CTMSGrant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantDetailsOwnershipSection_CTMSGrant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantDetailsVestingScheduleSection_CTMSGrant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantDetailsInformationSection_CTMSGrant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantDetailsExerciseSection_CTMSGrant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantDetailsDocumentsSection_CTMSGrant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantDetailsEmployeeSection_CTMSGrant"
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "be6f5fcc1c3a567b36309504d017c930";

export default node;
