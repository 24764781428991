import { Popover } from "@headlessui/react";
import {
  CheckIcon,
  ChevronDownIcon,
  EllipsisHorizontalIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { useCallback, useMemo } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { useFragment, useSubscription } from "react-relay";
import { graphql } from "relay-runtime";

import {
  BoardConsentVoidedReasonTag,
  getInstrumentValuationTypeFromBoardConsentVoidedReason,
} from "../../../../components/BoardConsentVoidedReasonTag";
import { CancelBoardConsentButton } from "../../../../components/CancelBoardConsentButton";
import { EmptyListPlaceholder } from "../../../../components/EmptyListPlaceholder/EmptyListPlaceholder";
import { FormattedCurrency } from "../../../../components/Formatted/FormattedCurrency";
import { FormattedPercentage } from "../../../../components/Formatted/FormattedPercentage";
import { Page } from "../../../../components/Page";
import { SendReminderButton } from "../../../../components/SendReminderButton";
import { LargeOneColumnLayout } from "../../../../components/ui/Layout/LargeOneColumnLayout";
import { LoadingSpinner } from "../../../../components/ui/LoadingSpinner";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { FadeAndScaleTransition } from "../../../../components/ui/Transition";
import { Typography } from "../../../../components/ui/Typography";
import { useOrganizationSharesUtil } from "../../../../hooks/useOrganizationSharesUtil";
import { useQuery } from "../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import { useOrganizationIdParam } from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { PendingApproval_deleteBoardConsent_Mutation } from "./__generated__/PendingApproval_deleteBoardConsent_Mutation.graphql";
import { PendingApproval_Organization$key } from "./__generated__/PendingApproval_Organization.graphql";
import { PendingApproval_Query } from "./__generated__/PendingApproval_Query.graphql";
import { PendingApproval_Viewer$key } from "./__generated__/PendingApproval_Viewer.graphql";
import { BoardConsentSignatureLinkModal } from "./BoardConsentSignatureLinkModal";
import { GrantsTable } from "./GrantsTable";

const DELETE_BOARD_CONSENT_MUTATION = graphql`
  mutation PendingApproval_deleteBoardConsent_Mutation($boardConsentId: UUID!) {
    deleteBoardConsentAndSendLinkedEasopGrantsToDraft(id: $boardConsentId)
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
graphql`
  fragment PendingApproval_BoardConsent on GrantBoardConsent {
    id
    grantCount
    totalGrantedShares
    canBoardMemberSignatureRequestReminderEmailsBeSent
    signatureRequests {
      id
      completedAt
      signatoryFirstName
      signatoryLastName
    }
    voidedReason
    ...SendReminderButton_BoardConsent
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
graphql`
  fragment PendingApproval_BoardConsents on GrantBoardConsent
  @relay(plural: true) {
    ...PendingApproval_BoardConsent @relay(mask: false)
  }
`;

const BOARD_CONSENTS_UPDATES_SUBSCRIPTION = graphql`
  subscription PendingApproval_BoardConsentsUpdatesSubscription(
    $boardConsentsIds: [UUID!]!
  ) {
    boardConsentsUpdates(ids: $boardConsentsIds) {
      ...PendingApproval_BoardConsent
    }
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment PendingApproval_Organization on Organization
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    id
    name
    pendingApprovalGrantBoardConsents {
      grants {
        ...GrantsTable_EasopGrant @arguments(organizationId: $organizationId)
      }
      ...PendingApproval_BoardConsents @relay(mask: false)
    }
    ...useOrganizationSharesUtil_Organization
    ...GrantsTable_Organization
    ...LargeOneColumnLayout_Organization
    ...FormattedCurrency_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment PendingApproval_Viewer on Account {
    isSuperAdmin
    ...GrantsTable_Account
  }
`;

const PendingApprovalPage_: React.FC<{
  onBoardConsentUpdated: () => void;
  organizationFragment: PendingApproval_Organization$key;
  viewerFragment: PendingApproval_Viewer$key;
}> = ({ onBoardConsentUpdated, organizationFragment, viewerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const boardConsentsUpdatesSubscriptionConfig = useMemo(
    () => ({
      subscription: BOARD_CONSENTS_UPDATES_SUBSCRIPTION,
      variables: {
        boardConsentsIds: organization.pendingApprovalGrantBoardConsents.map(
          (boardConsent) => boardConsent.id,
        ),
      },
    }),
    [organization.pendingApprovalGrantBoardConsents],
  );
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  useSubscription(boardConsentsUpdatesSubscriptionConfig);

  const { sharesToFullyDilutedRatio, sharesToValue } =
    useOrganizationSharesUtil({
      organizationFragment: organization,
    });

  const [deleteBoardConsent, deleteBoardConsentMutationIsInFlight] =
    useSafeMutation<PendingApproval_deleteBoardConsent_Mutation>(
      DELETE_BOARD_CONSENT_MUTATION,
    );

  const handleDeleteBoardConsentButtonClick = useCallback(
    async (boardConsentId: string) => {
      await deleteBoardConsent({
        variables: { boardConsentId },
      });

      onBoardConsentUpdated();
    },
    [deleteBoardConsent, onBoardConsentUpdated],
  );

  return (
    <LargeOneColumnLayout
      maxWidth={1200}
      organizationFragment={organization}
      subtitle="Grants are pending approval from the board"
      title="Pending approval"
    >
      <div className="space-y-4">
        {organization.pendingApprovalGrantBoardConsents.length > 0 ? (
          organization.pendingApprovalGrantBoardConsents.map((boardConsent) => {
            const completedSignatureRequests =
              boardConsent.signatureRequests.filter(
                (signatureRequest) => signatureRequest.completedAt,
              );

            const totalGrantedOwnership = sharesToFullyDilutedRatio(
              boardConsent.totalGrantedShares,
            );
            const totalGrantedValue = sharesToValue(
              boardConsent.totalGrantedShares,
            );

            return (
              <RoundedBox
                className={classNames(
                  boardConsent.voidedReason
                    ? "border-red-05"
                    : "border-gray-04",
                )}
                key={boardConsent.id}
                withBorder
              >
                <Typography
                  as="div"
                  className={classNames(
                    "flex w-full items-center gap-6 rounded-t-lg px-4 py-2",
                    boardConsent.voidedReason
                      ? "bg-red-01 text-red-09"
                      : "bg-gray-02 text-gray-09",
                  )}
                  variant="Medium/Caption"
                >
                  <span>
                    <FormattedMessage
                      defaultMessage={`Approval - Total of {grantCount, plural,
                              one {# grant}
                              other {# grants}
                            }`}
                      values={{
                        grantCount: boardConsent.grantCount,
                      }}
                    />
                  </span>
                  {totalGrantedOwnership !== null && (
                    <span>
                      <FormattedPercentage value={totalGrantedOwnership} />
                      &nbsp;fully diluted
                    </span>
                  )}
                  {totalGrantedValue !== null && (
                    <span>
                      Total&nbsp;
                      <FormattedCurrency
                        maximumFractionDigits={0}
                        organizationFragment={organization}
                        value={totalGrantedValue}
                      />
                    </span>
                  )}
                  <span>
                    <FormattedNumber value={boardConsent.totalGrantedShares} />
                    &nbsp;Shares
                  </span>
                  {boardConsent.voidedReason && (
                    <button
                      className="ml-auto h-5 w-5 shrink-0"
                      disabled={deleteBoardConsentMutationIsInFlight}
                      onClick={() =>
                        handleDeleteBoardConsentButtonClick(boardConsent.id)
                      }
                    >
                      {deleteBoardConsentMutationIsInFlight ? (
                        <LoadingSpinner />
                      ) : (
                        <XMarkIcon />
                      )}
                    </button>
                  )}
                </Typography>
                <div className="border-t-[0.5px] border-gray-03">
                  <GrantsTable
                    columns={[
                      "Draft",
                      "Employee",
                      "Ownership",
                      "Details",
                      "Strike price",
                      "Grant type",
                      "Status",
                    ]}
                    easopGrantsFragment={boardConsent.grants}
                    onUpdate={() => {
                      throw new Error(
                        "Pending approval grant should not be modified nor deleted",
                      );
                    }}
                    organizationFragment={organization}
                    viewerFragment={viewer}
                  />
                </div>
                {boardConsent.voidedReason ? (
                  <div className="flex w-full justify-center border-t-[0.5px] border-gray-03 p-4">
                    <BoardConsentVoidedReasonTag
                      instrumentValuationType={getInstrumentValuationTypeFromBoardConsentVoidedReason(
                        boardConsent.voidedReason,
                      )}
                    />
                  </div>
                ) : viewer.isSuperAdmin ||
                  !isEmpty(boardConsent.signatureRequests) ? (
                  <div className="flex items-center border-t-[0.5px] border-gray-03 p-4">
                    {!isEmpty(boardConsent.signatureRequests) && (
                      <Popover className="relative">
                        <Popover.Button className="focus:outline-none">
                          <Typography
                            as="div"
                            className="flex cursor-pointer items-center text-black-05"
                            variant="Medium/Caption"
                          >
                            <div
                              className={classNames(
                                "flex h-6 w-6 items-center justify-center rounded-full text-black-09",
                                {
                                  "bg-glass-green-02":
                                    completedSignatureRequests.length > 0,
                                  "bg-gray-02":
                                    completedSignatureRequests.length === 0,
                                },
                              )}
                            >
                              <div>{completedSignatureRequests.length}</div>
                            </div>
                            &nbsp; out of{" "}
                            {boardConsent.signatureRequests.length} board
                            members have signed &nbsp;
                            <ChevronDownIcon className="h-4 w-4 text-black-07 ui-open:rotate-180 ui-open:transform" />
                          </Typography>
                        </Popover.Button>
                        <Popover.Panel className="absolute bottom-10 left-0 z-10 w-80">
                          <RoundedBox
                            className="flex flex-col gap-4 p-4"
                            withBorder
                          >
                            <Typography
                              className="text-black-05"
                              variant="Medium/Extra Small"
                            >
                              List of board members:
                            </Typography>
                            <div className="space-y-1">
                              {boardConsent.signatureRequests.map(
                                (signatureRequest) => (
                                  <Typography
                                    as="div"
                                    className={classNames(
                                      "flex items-center justify-between gap-2 px-2 py-1 text-gray-09",
                                      {
                                        "text-gray-09":
                                          !signatureRequest.completedAt,
                                      },
                                    )}
                                    key={signatureRequest.id}
                                    variant="Regular/Extra Small"
                                  >
                                    <div className="flex items-center gap-2">
                                      {signatureRequest.completedAt ? (
                                        <CheckIcon className="h-4 w-4 shrink-0 text-green-05" />
                                      ) : (
                                        <EllipsisHorizontalIcon className="h-4 w-4 shrink-0 text-gray-09" />
                                      )}
                                      <div>
                                        {signatureRequest.signatoryFirstName}
                                        &nbsp;
                                        {signatureRequest.signatoryLastName}
                                      </div>
                                    </div>
                                    {!signatureRequest.completedAt && (
                                      <BoardConsentSignatureLinkModal
                                        signatureRequestId={signatureRequest.id}
                                      />
                                    )}
                                  </Typography>
                                ),
                              )}
                            </div>
                            <Typography
                              as="div"
                              className="border-t-[0.5px] border-gray-03 pt-4"
                              variant="Medium/Extra Small"
                            >
                              <FormattedMessage
                                defaultMessage={`{unsignedSignatureRequestsCount, plural,
                              one {# board member hasn’t signed yet.}
                              other {# board members haven’t signed yet.}
                            }`}
                                values={{
                                  unsignedSignatureRequestsCount:
                                    boardConsent.signatureRequests.length -
                                    completedSignatureRequests.length,
                                }}
                              />
                            </Typography>
                            <FadeAndScaleTransition
                              appear={false}
                              show={
                                boardConsent.canBoardMemberSignatureRequestReminderEmailsBeSent
                              }
                            >
                              <SendReminderButton
                                boardConsentFragment={boardConsent}
                                fullWidth
                              />
                            </FadeAndScaleTransition>
                          </RoundedBox>
                        </Popover.Panel>
                      </Popover>
                    )}

                    <div className="flex flex-1 items-center justify-end gap-8">
                      {boardConsent.canBoardMemberSignatureRequestReminderEmailsBeSent && (
                        <div className="flex items-center gap-6">
                          <Typography
                            className="text-black-05"
                            variant="Regular/Caption"
                          >
                            Send a reminder to the missing board members
                          </Typography>
                          <SendReminderButton
                            boardConsentFragment={boardConsent}
                          />
                        </div>
                      )}
                      {viewer.isSuperAdmin && !boardConsent.voidedReason && (
                        <CancelBoardConsentButton
                          boardConsentId={boardConsent.id}
                          onBoardConsentVoided={onBoardConsentUpdated}
                        />
                      )}
                    </div>
                  </div>
                ) : null}
              </RoundedBox>
            );
          })
        ) : (
          <EmptyListPlaceholder
            hideImage
            subtitle="Feel free to create new grants or plan equity from your planning"
            title="No grants currently pending approval"
          />
        )}
      </div>
    </LargeOneColumnLayout>
  );
};

const QUERY = graphql`
  query PendingApproval_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...PendingApproval_Organization
        @arguments(organizationId: $organizationId)
    }
    me {
      ...PendingApproval_Viewer
    }
  }
`;

const PendingApprovalPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const {
    query: { me, organization },
    refreshQuery,
  } = useQuery<PendingApproval_Query>(QUERY, {
    organizationId,
  });

  if (!organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="New Equity Flow"
      analyticsName="Admin - New Equity Pending Approval"
      organizationId={organization.id}
      title={`Admin | ${organization.name} pending approval grants`}
    >
      <PendingApprovalPage_
        onBoardConsentUpdated={refreshQuery}
        organizationFragment={organization}
        viewerFragment={me}
      />
    </Page>
  );
};

export default PendingApprovalPage;
