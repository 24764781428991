import { zodResolver } from "@hookform/resolvers/zod";
import { debounce } from "lodash";
import { useCallback, useMemo, useState, useTransition } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "../../../components/ui/Button";
import { RemoteInputDatePicker } from "../../../components/ui/Form/Inputs/RemoteInputDatePicker";
import { SearchBar } from "../../../components/ui/SearchBar";

export const filterFormSchema = z.object({
  fullTextFilter: z.string().nullish(),
  period: z.string(),
});

export type FilterFormSchema = z.infer<typeof filterFormSchema>;

export const RSUVestingFilters: React.FC<{
  filters: FilterFormSchema;
  onFiltersChange: (filters: FilterFormSchema) => void;
}> = ({ filters, onFiltersChange }) => {
  const filterForm = useForm<z.infer<typeof filterFormSchema>>({
    resolver: zodResolver(filterFormSchema),
    values: {
      fullTextFilter: filters.fullTextFilter,
      period: filters.period,
    },
  });

  const [filtersTransitionInProgress, startFiltersTransition] = useTransition();

  const handleSubmit = filterForm.handleSubmit((submittedFilters) => {
    startFiltersTransition(() => {
      onFiltersChange({
        ...filters,
        period: submittedFilters.period,
      });
    });
  });

  const [searchTransitionInProgress, startSearchTransition] = useTransition();
  const onFullTextFilterChange = useCallback(
    (fullTextFilter: string) => {
      startSearchTransition(() => {
        onFiltersChange({ ...filters, fullTextFilter });
      });
    },
    [filters, onFiltersChange],
  );

  const debouncedOnFullTextFilterChange = useMemo(
    () => debounce(onFullTextFilterChange, 500),
    [onFullTextFilterChange],
  );

  const [realTimeFullTextFilter, setRealTimeFullTextFilter] = useState(
    filters.fullTextFilter ?? "",
  );

  return (
    <div className="relative flex items-center justify-between gap-4">
      <SearchBar.FiltersBox
        popoverButton={
          <SearchBar.FiltersBoxPopoverButton
            loading={filtersTransitionInProgress}
          />
        }
        renderApplyButton={() => <Button />}
        renderClearButton={(close) => (
          <Button
            onClick={() => {
              close();
            }}
          />
        )}
        renderForm={(close) => (
          <form
            onSubmit={(event) => {
              event.preventDefault();
              void handleSubmit();
              close();
            }}
          />
        )}
      >
        <SearchBar.FiltersBoxItem label="Period">
          <RemoteInputDatePicker.Form
            control={filterForm.control}
            dateFormat="yyyy-MM"
            isClearable={false}
            label="Period"
            name="period"
            portalId="root-portal"
            showMonthYearPicker={true}
          />
        </SearchBar.FiltersBoxItem>
      </SearchBar.FiltersBox>
      <SearchBar
        className="w-full max-w-[300px]"
        loading={searchTransitionInProgress}
        onChange={(value) => {
          setRealTimeFullTextFilter(value);
          debouncedOnFullTextFilterChange(value);
        }}
        placeholder="Search grants"
        value={realTimeFullTextFilter}
      />
    </div>
  );
};
