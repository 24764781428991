import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useFragment } from "react-relay";
import { generatePath, Navigate, useNavigate } from "react-router-dom";
import { graphql } from "relay-runtime";

import { FormattedUSD } from "../../../../components/Formatted/FormattedCurrency";
import { LoadingPlaceholder } from "../../../../components/LoadingPlaceholder";
import { Page } from "../../../../components/Page";
import { RedirectHandler } from "../../../../components/RedirectHandler";
import { useToaster } from "../../../../components/Toaster";
import { Button, LinkButton } from "../../../../components/ui/Button";
import { HelpCard } from "../../../../components/ui/HelpCard";
import { LargeCenteredDetailsLayout } from "../../../../components/ui/Layout/LargeCenteredDetailsLayout";
import { Toast } from "../../../../components/ui/Toast";
import { TooltipContainer } from "../../../../components/ui/TooltipContainer";
import { Typography } from "../../../../components/ui/Typography";
import { useQuery } from "../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import { useStripeCustomerPortal } from "../../../../hooks/useStripeCustomerPortal";
import {
  APPLICATION_ROUTES,
  useCountryCodeParam,
  useOrganizationIdParam,
} from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { useSetAdminPageVisited } from "../../useSetAdminPageVisited";
import { UnlockCountry_GeographyWithPrice$key } from "./__generated__/UnlockCountry_GeographyWithPrice.graphql";
import { UnlockCountry_Organization$key } from "./__generated__/UnlockCountry_Organization.graphql";
import { UnlockCountry_OrganizationStripeAccount$key } from "./__generated__/UnlockCountry_OrganizationStripeAccount.graphql";
import { UnlockCountry_Query } from "./__generated__/UnlockCountry_Query.graphql";
import { UnlockCountry_UnlockGeography_Mutation } from "./__generated__/UnlockCountry_UnlockGeography_Mutation.graphql";
import { UnlockCountry_UnlockGeographyForFree_Mutation } from "./__generated__/UnlockCountry_UnlockGeographyForFree_Mutation.graphql";
import { UnlockCountry_Viewer$key } from "./__generated__/UnlockCountry_Viewer.graphql";
import { BillingDetails } from "./BillingDetails";
import { EquityIncentivesAvailable } from "./EquityIncentivesAvailable";

const ORGANIZATION_FRAGMENT = graphql`
  fragment UnlockCountry_Organization on Organization {
    id
    name
    subscriptionBillingPeriod
    ...LargeCenteredDetailsLayout_Organization
    ...BillingDetails_Organization
  }
`;

const GEOGRAPHY_WITH_PRICE_FRAGMENT = graphql`
  fragment UnlockCountry_GeographyWithPrice on GeographyWithPrice {
    isUnlocked
    standardPrice {
      priceAfterDiscount
    }
    geography {
      country {
        name
        emoji
        code
      }
      ...EquityIncentivesAvailable_Geography
    }
  }
`;

const ORGANIZATION_STRIPE_ACCOUNT_FRAGMENT = graphql`
  fragment UnlockCountry_OrganizationStripeAccount on StripeCustomer {
    ...BillingDetails_OrganizationStripeAccount
    invoicesDefaultPaymentMethod {
      __typename
    }
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment UnlockCountry_Viewer on Account {
    isSuperAdmin
  }
`;

const UNLOCK_GEOGRAPHY_MUTATION = graphql`
  mutation UnlockCountry_UnlockGeography_Mutation(
    $countryCode: String!
    $withTaxFavoredEquityType: Boolean!
    $organizationId: OrganizationId!
  ) {
    unlockGeography(
      countryCode: $countryCode
      organizationId: $organizationId
      withTaxFavoredEquityType: $withTaxFavoredEquityType
    ) {
      __typename
    }
  }
`;

const UNLOCK_GEOGRAPHY_FOR_FREE_MUTATION = graphql`
  mutation UnlockCountry_UnlockGeographyForFree_Mutation(
    $countryCode: String!
    $withTaxFavoredEquityType: Boolean!
    $organizationId: OrganizationId!
  ) {
    unlockGeographyForFree(
      countryCode: $countryCode
      organizationId: $organizationId
      withTaxFavoredEquityType: $withTaxFavoredEquityType
    ) {
      __typename
    }
  }
`;

const UnlockCountryPage_: React.FC<{
  geographyWithPriceFragment: UnlockCountry_GeographyWithPrice$key;
  organizationFragment: UnlockCountry_Organization$key;
  organizationStripeAccountFragment: UnlockCountry_OrganizationStripeAccount$key;
  viewerFragment: UnlockCountry_Viewer$key;
}> = ({
  geographyWithPriceFragment,
  organizationFragment,
  organizationStripeAccountFragment,
  viewerFragment,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const geographyWithPrice = useFragment(
    GEOGRAPHY_WITH_PRICE_FRAGMENT,
    geographyWithPriceFragment,
  );
  const organizationStripeAccount = useFragment(
    ORGANIZATION_STRIPE_ACCOUNT_FRAGMENT,
    organizationStripeAccountFragment,
  );
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  useSetAdminPageVisited({
    organizationId: organization.id,
    page: "GEOGRAPHIES",
  });

  const { openStripeCustomerPortal } = useStripeCustomerPortal({
    organizationId: organization.id,
    target: "_self",
  });

  const [unlockGeography, _unlockGeographyMutationIsInFlight] =
    useSafeMutation<UnlockCountry_UnlockGeography_Mutation>(
      UNLOCK_GEOGRAPHY_MUTATION,
    );
  const [unlockGeographyForFree, _unlockGeographyForFreeMutationIsInFlight] =
    useSafeMutation<UnlockCountry_UnlockGeographyForFree_Mutation>(
      UNLOCK_GEOGRAPHY_FOR_FREE_MUTATION,
    );

  const unlockGeographyMutationIsInFlight =
    _unlockGeographyMutationIsInFlight ||
    _unlockGeographyForFreeMutationIsInFlight;

  const toaster = useToaster();

  const navigate = useNavigate();

  const price = geographyWithPrice.standardPrice?.priceAfterDiscount || 0;

  const handleGeographyUnlocked = useCallback(() => {
    toaster.push(
      <Toast title="Remarkable!" variant="congrats">
        You can now offer equity in {geographyWithPrice.geography.country.emoji}{" "}
        {geographyWithPrice.geography.country.name}!
      </Toast>,
    );

    void navigate(
      generatePath(APPLICATION_ROUTES.organizationCountry, {
        countryCode: geographyWithPrice.geography.country.code,
        organizationId: organization.id,
      }),
      { replace: true },
    );
  }, [
    geographyWithPrice.geography.country.code,
    geographyWithPrice.geography.country.emoji,
    geographyWithPrice.geography.country.name,
    navigate,
    organization.id,
    toaster,
  ]);

  const handleUnlockGeographyButtonClick = useCallback(async () => {
    await unlockGeography({
      variables: {
        countryCode: geographyWithPrice.geography.country.code,
        organizationId: organization.id,
        withTaxFavoredEquityType: false,
      },
    });

    handleGeographyUnlocked();
  }, [
    organization.id,
    handleGeographyUnlocked,
    unlockGeography,
    geographyWithPrice.geography.country.code,
  ]);

  const handleUnlockForFreeGeographyButtonClick = useCallback(async () => {
    await unlockGeographyForFree({
      variables: {
        countryCode: geographyWithPrice.geography.country.code,
        organizationId: organization.id,
        withTaxFavoredEquityType: false,
      },
    });

    handleGeographyUnlocked();
  }, [
    organization.id,
    handleGeographyUnlocked,
    unlockGeographyForFree,
    geographyWithPrice.geography.country.code,
  ]);

  return (
    <LargeCenteredDetailsLayout
      footerHelpCard={
        <HelpCard
          buttonLabel="Talk to our team"
          label="Anything unclear? You need more information regarding your countries or our billing methods?"
        />
      }
      navigationTitle={geographyWithPrice.geography.country.name}
      organizationFragment={organization}
      parents={[
        {
          link: "../../..",
          title: "Countries",
        },
        {
          link: "../..",
          title: "Unlock new countries",
        },
      ]}
      title={`Unlock a new country: ${geographyWithPrice.geography.country.name} ${geographyWithPrice.geography.country.emoji}`}
    >
      <div className="grid grid-cols-1 gap-x-6 gap-y-10 lg:grid-cols-[1fr_360px]">
        <div className="space-y-6">
          <EquityIncentivesAvailable
            geographyFragment={geographyWithPrice.geography}
          />
          <BillingDetails
            organizationFragment={organization}
            organizationStripeAccountFragment={organizationStripeAccount}
          />
        </div>
        <div className="relative">
          <div className="sticky top-18 -mt-4 space-y-4">
            <Typography as="div" variant="Medium/Small">
              Review and confirm
            </Typography>
            <div className="relative space-y-4 overflow-hidden rounded-lg border-[0.5px] border-gray-04 px-4 py-6">
              {unlockGeographyMutationIsInFlight && (
                <div className="absolute inset-0 bg-white">
                  <LoadingPlaceholder />
                </div>
              )}
              <div>
                <div className="flex items-center gap-4 rounded-lg bg-gray-01 p-4">
                  <Typography className="font-emoji" variant="Medium/Large">
                    {geographyWithPrice.geography.country.emoji}
                  </Typography>
                  <Typography
                    as="div"
                    className="truncate"
                    variant="Medium/Small"
                  >
                    {geographyWithPrice.geography.country.name}
                  </Typography>
                </div>
                <div className="flex items-center justify-between py-4">
                  <Typography
                    as="div"
                    className="text-black-05"
                    variant="Regular/Extra Small"
                  >
                    Total price
                  </Typography>
                  <div className="text-right">
                    <Typography as="div" variant="Medium/Extra Small">
                      {geographyWithPrice.standardPrice ? (
                        <FormattedUSD
                          value={
                            geographyWithPrice.standardPrice.priceAfterDiscount
                          }
                        />
                      ) : (
                        "N/A"
                      )}
                    </Typography>
                    <Typography
                      as="div"
                      className="text-black-05"
                      variant="Regular/Extra Small"
                    >
                      {organization.subscriptionBillingPeriod === "monthly" ? (
                        <>/Month</>
                      ) : (
                        <>/Year</>
                      )}
                    </Typography>
                  </div>
                </div>
                <div className="space-y-2">
                  <TooltipContainer
                    disabled={
                      !!organizationStripeAccount.invoicesDefaultPaymentMethod
                    }
                    tooltipContent={
                      <>
                        Please add a payment method on{" "}
                        <button
                          className="text-primary"
                          onClick={openStripeCustomerPortal}
                        >
                          your billing dashboard{" "}
                        </button>{" "}
                        to proceed.
                      </>
                    }
                  >
                    <Button
                      disabled={
                        !organizationStripeAccount.invoicesDefaultPaymentMethod
                      }
                      fullWidth
                      onClick={handleUnlockGeographyButtonClick}
                      size="small"
                    >
                      <FormattedMessage
                        defaultMessage="Unlock for {price, number, ::currency/USD} {billingPeriod}"
                        values={{
                          billingPeriod: organization.subscriptionBillingPeriod,
                          price,
                        }}
                      />
                    </Button>
                  </TooltipContainer>
                  {viewer.isSuperAdmin && (
                    <Button
                      fullWidth
                      onClick={handleUnlockForFreeGeographyButtonClick}
                      size="small"
                      variant="Primary Full"
                    >
                      Unlock for free
                    </Button>
                  )}
                  <LinkButton
                    fullWidth
                    size="small"
                    to=".."
                    variant="Tertiary Link"
                  >
                    Cancel
                  </LinkButton>
                </div>
                <Typography
                  as="div"
                  className="text-right text-gray-09"
                  variant="Regular/Caption"
                >
                  <FormattedMessage
                    defaultMessage="After you click Pay, the geography will be automatically unlocked and ready for use. Your first monthly geography fee ({price, number, ::currency/USD} / {billingPeriod}) will be debited (on a pro rata basis) with your next {billingPeriod} instalment. Subscription is subject to a 12 months commitment."
                    values={{
                      billingPeriod:
                        organization.subscriptionBillingPeriod === "monthly"
                          ? "month"
                          : "year",
                      price,
                    }}
                  />
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LargeCenteredDetailsLayout>
  );
};

const QUERY = graphql`
  query UnlockCountry_Query(
    $organizationId: OrganizationId!
    $countryCode: String!
  ) {
    me {
      isAllowedToUnlockCountryForOrganization(organizationId: $organizationId)
      ...UnlockCountry_Viewer
    }
    organization(id: $organizationId) {
      id
      name
      ...UnlockCountry_Organization
    }
    organizationStripeAccount(organizationId: $organizationId)
      @required(action: THROW) {
      ...UnlockCountry_OrganizationStripeAccount
    }

    geographyWithOrganizationSpecificPrices(
      organizationId: $organizationId
      countryCode: $countryCode
    ) {
      isUnlocked
      geography {
        country {
          name
          emoji
        }
      }
      ...UnlockCountry_GeographyWithPrice
    }
  }
`;

const UnlockCountryPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const countryCode = useCountryCodeParam();
  const { query } = useQuery<UnlockCountry_Query>(QUERY, {
    countryCode,
    organizationId,
  });

  if (!query.organization) {
    return <NotFoundPage />;
  }

  if (query.geographyWithOrganizationSpecificPrices.isUnlocked) {
    return (
      <Navigate
        replace
        to={generatePath(APPLICATION_ROUTES.organizationCountry, {
          countryCode,
          organizationId,
        })}
      />
    );
  }

  if (!query.me.isAllowedToUnlockCountryForOrganization) {
    return (
      <RedirectHandler
        to={generatePath(APPLICATION_ROUTES.organizationCountries, {
          organizationId,
        })}
      />
    );
  }

  return (
    <Page
      analyticsCategory="Countries"
      analyticsName="Admin - Unlock country"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} unlock country | ${query.geographyWithOrganizationSpecificPrices.geography.country.name} ${query.geographyWithOrganizationSpecificPrices.geography.country.emoji}`}
    >
      <UnlockCountryPage_
        geographyWithPriceFragment={
          query.geographyWithOrganizationSpecificPrices
        }
        organizationFragment={query.organization}
        organizationStripeAccountFragment={query.organizationStripeAccount}
        viewerFragment={query.me}
      />
    </Page>
  );
};

export default UnlockCountryPage;
