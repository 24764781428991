/**
 * @generated SignedSource<<8268f7c5901c966a0d0ce3259db0bf61>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquityAlertingPage_Organization$data = ReadonlyArray<{
  readonly ctmsFullyDilutedShares: number | null;
  readonly ctmsLatestFMVEffectiveDate: string | null;
  readonly ctmsLatestFMVValue: number | null;
  readonly ctmsReportedAvailableEsopPoolShares: number | null;
  readonly ctmsSafeCount: number | null;
  readonly ctmsSafeTotalAmount: number | null;
  readonly ctmsWarrantTotalAmount: number | null;
  readonly easopSafeCount: number | null;
  readonly easopSafeTotalAmount: number | null;
  readonly easopWarrantTotalAmount: number | null;
  readonly equityAlertingMuted: boolean;
  readonly fullyDilutedShares: number | null;
  readonly id: string;
  readonly latestFairMarketValue: {
    readonly date: string;
    readonly value: number;
  } | null;
  readonly mismatchBetweenCtmsAndEasopCapTable: boolean;
  readonly name: string;
  readonly poolAvailableShares: number;
  readonly postSafeConversionFullyDilutedSharesDelta: number | null;
  readonly terminated: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"FormattedCurrency_Organization" | "FormattedFMV_Organization">;
  readonly " $fragmentType": "EquityAlertingPage_Organization";
}>;
export type EquityAlertingPage_Organization$key = ReadonlyArray<{
  readonly " $data"?: EquityAlertingPage_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquityAlertingPage_Organization">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "EquityAlertingPage_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "terminated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mismatchBetweenCtmsAndEasopCapTable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullyDilutedShares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postSafeConversionFullyDilutedSharesDelta",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctmsFullyDilutedShares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "includingPendingShares",
          "value": false
        }
      ],
      "kind": "ScalarField",
      "name": "poolAvailableShares",
      "storageKey": "poolAvailableShares(includingPendingShares:false)"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctmsReportedAvailableEsopPoolShares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "easopSafeCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctmsSafeCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "easopSafeTotalAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctmsSafeTotalAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "easopWarrantTotalAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctmsWarrantTotalAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "equityAlertingMuted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctmsLatestFMVEffectiveDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctmsLatestFMVValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FairMarketValue",
      "kind": "LinkedField",
      "name": "latestFairMarketValue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedCurrency_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedFMV_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "b5b9cf1b2e52cffd622a20a76bf80655";

export default node;
