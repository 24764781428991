/**
 * @generated SignedSource<<7e8ac4e4e1f9f47561edc19a3743902f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type RemoteOnboardingStep = "CLEAN_UP_GRANTEE_DATA" | "CONNECT_CTMS" | "ESSENTIALS_CTMS" | "ESSENTIALS_EQUITY_PLAN_PARAMETERS" | "ESSENTIALS_EXPECTATION" | "ESSENTIALS_FAIR_MARKET_VALUE" | "ESSENTIALS_ISSUING_COMPANY" | "ESSENTIALS_LAST_STEP" | "SET_PRICE_PER_SHARE" | "TEST_LAW_FIRM_CARTA_CONNECTION";
export type KeyEquityPlansParametersPage_SetEssentialsKeyEquityPlansParametersOnboardingStepAsCompleted_Mutation$variables = {
  esopPoolShares?: number | null;
  fullyDilutedShares?: number | null;
  organizationId: string;
  pricePerShare?: number | null;
};
export type KeyEquityPlansParametersPage_SetEssentialsKeyEquityPlansParametersOnboardingStepAsCompleted_Mutation$data = {
  readonly setEssentialsEquityPlanParametersOnboardingStepAsCompleted: {
    readonly currentRemoteOnboardingStep: RemoteOnboardingStep | null;
  };
};
export type KeyEquityPlansParametersPage_SetEssentialsKeyEquityPlansParametersOnboardingStepAsCompleted_Mutation = {
  response: KeyEquityPlansParametersPage_SetEssentialsKeyEquityPlansParametersOnboardingStepAsCompleted_Mutation$data;
  variables: KeyEquityPlansParametersPage_SetEssentialsKeyEquityPlansParametersOnboardingStepAsCompleted_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "esopPoolShares"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fullyDilutedShares"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pricePerShare"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "esopPoolShares",
    "variableName": "esopPoolShares"
  },
  {
    "kind": "Variable",
    "name": "fullyDilutedShares",
    "variableName": "fullyDilutedShares"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  },
  {
    "kind": "Variable",
    "name": "pricePerShare",
    "variableName": "pricePerShare"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currentRemoteOnboardingStep",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "KeyEquityPlansParametersPage_SetEssentialsKeyEquityPlansParametersOnboardingStepAsCompleted_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "setEssentialsEquityPlanParametersOnboardingStepAsCompleted",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "KeyEquityPlansParametersPage_SetEssentialsKeyEquityPlansParametersOnboardingStepAsCompleted_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "setEssentialsEquityPlanParametersOnboardingStepAsCompleted",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c592653a98cc554584989eee63d4955d",
    "id": null,
    "metadata": {},
    "name": "KeyEquityPlansParametersPage_SetEssentialsKeyEquityPlansParametersOnboardingStepAsCompleted_Mutation",
    "operationKind": "mutation",
    "text": "mutation KeyEquityPlansParametersPage_SetEssentialsKeyEquityPlansParametersOnboardingStepAsCompleted_Mutation(\n  $esopPoolShares: Int\n  $fullyDilutedShares: Int\n  $organizationId: OrganizationId!\n  $pricePerShare: Float\n) {\n  setEssentialsEquityPlanParametersOnboardingStepAsCompleted(esopPoolShares: $esopPoolShares, fullyDilutedShares: $fullyDilutedShares, organizationId: $organizationId, pricePerShare: $pricePerShare) {\n    currentRemoteOnboardingStep\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "8138bac387d263e149a5b80c6177487d";

export default node;
