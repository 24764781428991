import classNames from "classnames";
import { motion } from "motion/react";

import { Button } from "../ui/Button";
import { Typography } from "../ui/Typography";

export const FloatingSaveBar: React.FC<{
  loading: boolean;
  show: boolean;
}> = ({ loading, show }) => {
  return (
    <motion.div
      animate={{ opacity: show ? 1 : 0, y: show ? 0 : 100 }}
      className={classNames(
        "fixed flex items-center gap-4 rounded-lg bg-grey-900 p-4 text-white",
      )}
      initial={{ bottom: "40px", left: "50%", opacity: 0, x: "-50%", y: 100 }}
    >
      <Typography variant="Regular/Extra Small">
        You have unsaved changes
      </Typography>
      <Button loading={loading} type="submit" variant="Secondary Outline">
        <span className="text-white">Save changes</span>
      </Button>
    </motion.div>
  );
};
