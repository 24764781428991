/**
 * @generated SignedSource<<cc4b525854b725c858f16c1c090993cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GrantDetailsOwnershipSection_CTMSGrant$data = {
  readonly cumulativeVested: number;
  readonly quantityIssued: number;
  readonly " $fragmentType": "GrantDetailsOwnershipSection_CTMSGrant";
};
export type GrantDetailsOwnershipSection_CTMSGrant$key = {
  readonly " $data"?: GrantDetailsOwnershipSection_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantDetailsOwnershipSection_CTMSGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantDetailsOwnershipSection_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cumulativeVested",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantityIssued",
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "77e1807b45f66cd72c9023c28db898f9";

export default node;
