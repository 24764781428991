import React from "react";
import { graphql, useFragment } from "react-relay";

import { ButtonBanner } from "../../../../components/ButtonBanner";
import { CartaLogo } from "../../../../components/ui/CartaLogo";
import { useApplicationSupportEmailAddress } from "../../../../hooks/useApplicationTheme";
import { CartaIntegrationBanner_Organization$key } from "./__generated__/CartaIntegrationBanner_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment CartaIntegrationBanner_Organization on Organization {
    name
  }
`;

export const CartaIntegrationBanner: React.FC<{
  organizationFragment: CartaIntegrationBanner_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const supportEmailAddress = useApplicationSupportEmailAddress();
  const mailtoSubjectCartaIntegration = encodeURI(
    `${organization.name} I want to know more about Remote Equity Advanced`,
  );

  return (
    <ButtonBanner
      actionText="Talk to sales"
      icon={<CartaLogo className="h-14 w-14" fill="#fff" />}
      subtitle="Your cap table synchronized and up-to-date right in Remote Equity Advanced"
      to={`mailto:${supportEmailAddress}?subject=${mailtoSubjectCartaIntegration}`}
      variant="blue"
    >
      Discover our Carta Integration
    </ButtonBanner>
  );
};
