/**
 * @generated SignedSource<<9ade2a79c5c560db81913f6693c82088>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PricePerShareExplanationDrawer_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CurrencySymbol_Organization">;
  readonly " $fragmentType": "PricePerShareExplanationDrawer_Organization";
};
export type PricePerShareExplanationDrawer_Organization$key = {
  readonly " $data"?: PricePerShareExplanationDrawer_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"PricePerShareExplanationDrawer_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PricePerShareExplanationDrawer_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurrencySymbol_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "5083e6f1a3f4387685c969fa2cfeb8a2";

export default node;
