/**
 * @generated SignedSource<<9cef7016b4b47bf687296fd2bad9d762>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteeOutcome_Grantee$data = {
  readonly organization: {
    readonly granteePortalProjectionScenarios: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"ProjectionCard_GranteePortalProjectionScenarios">;
    }>;
    readonly latestPricePerShare: {
      readonly value: number;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"ProjectionCard_Organization">;
  };
  readonly " $fragmentType": "GranteeOutcome_Grantee";
};
export type GranteeOutcome_Grantee$key = {
  readonly " $data"?: GranteeOutcome_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeOutcome_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeOutcome_Grantee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PricePerShare",
          "kind": "LinkedField",
          "name": "latestPricePerShare",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GranteePortalProjectionScenario",
          "kind": "LinkedField",
          "name": "granteePortalProjectionScenarios",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProjectionCard_GranteePortalProjectionScenarios"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProjectionCard_Organization"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "fff59c79c040b9c6b971a907995c521e";

export default node;
