/**
 * @generated SignedSource<<11dd37622e325d0caf473db85668d850>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdvancedOverview_Admin_StripeCustomer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"OnboardingSlider_StripeCustomer">;
  readonly " $fragmentType": "AdvancedOverview_Admin_StripeCustomer";
};
export type AdvancedOverview_Admin_StripeCustomer$key = {
  readonly " $data"?: AdvancedOverview_Admin_StripeCustomer$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdvancedOverview_Admin_StripeCustomer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdvancedOverview_Admin_StripeCustomer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OnboardingSlider_StripeCustomer"
    }
  ],
  "type": "StripeCustomer",
  "abstractKey": null
};

(node as any).hash = "4c2bfb68abe4fc8bb2afe0bc5913fa94";

export default node;
