export const CartaLogo: React.FC<React.ComponentProps<"svg">> = ({
  className,
  fill = "#010101",
  ...props
}) => {
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 144 63"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.819 3.819h136.348v55.136H3.819V3.82zM0 62.789h144V0H0v62.789z"
        fill={fill}
      ></path>
      <path
        d="M31.974 44.203c-5.415 0-11.12-4.174-11.12-11.208s5.968-11.28 11.324-11.28c3.838 0 7.413 1.444 9.325 4.771l-3.59 2.087a6.715 6.715 0 00-5.677-3.02c-3.152 0-6.902 2.466-6.902 7.37 0 4.902 3.59 7.412 7.194 7.412 2.51 0 4.597-1.4 5.837-3.59l3.663 1.708c-2.072 3.75-5.779 5.75-10.084 5.75M55.279 40.16c4.086 0 6.902-3.064 6.902-7.194s-2.816-7.195-6.902-7.195-6.99 3.021-6.99 7.107 2.816 7.282 6.99 7.282zm-.424 4.043c-6.479 0-11.076-4.904-11.076-11.252 0-6.348 4.553-11.236 11.076-11.236 2.992 0 5.867 1.255 7.239 3.195v-2.64h4.421v21.334h-4.421v-2.641c-1.445 1.912-4.261 3.24-7.239 3.24zM88.872 43.604V26.4h-3.415v-4.261h3.459v-6.305h4.553v6.305h4.728v4.26H93.47v17.206h-4.597zM111.243 40.16c4.086 0 6.903-3.064 6.903-7.194s-2.817-7.195-6.903-7.195c-4.086 0-6.99 3.021-6.99 7.107s2.817 7.282 6.99 7.282zm-.437 4.043c-6.48 0-11.077-4.904-11.077-11.252 0-6.348 4.553-11.236 11.077-11.236 2.991 0 5.866 1.255 7.238 3.195v-2.64h4.436v21.334h-4.436v-2.641c-1.445 1.912-4.261 3.24-7.238 3.24zM71.069 43.575V22.24h4.217v3.707c.876-2.408 2.714-4.043 5.151-4.043a6.37 6.37 0 011.576.175v4.13c-3.444-.394-6.348 2-6.348 7.501v9.88h-4.597v-.015z"
        fill={fill}
      ></path>
    </svg>
  );
};
