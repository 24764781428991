import { Suspense } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { GrantsFilters } from "../pages/Admin/GrantsFilters";
import {
  OrganizationCTMSGrantsFilters,
  OrganizationCTMSGrantsFiltersOrderBy,
} from "../pages/Admin/Insights/UnderwaterGrants/__generated__/UnderwaterGrantsSlideOver_OrganizationCTMSGrantsPagination_Query.graphql";
import { OrganizationCtmsGrantsTable_CTMSGrants$key } from "./__generated__/OrganizationCtmsGrantsTable_CTMSGrants.graphql";
import { OrganizationCtmsGrantsTable_Organization$key } from "./__generated__/OrganizationCtmsGrantsTable_Organization.graphql";
import { CtmsGrantsTableRow } from "./CtmsGrantsTableRow";
import { SuspenseWithLoadingFallback } from "./LoadingPlaceholder";
import { LoadMoreButton } from "./ui/LoadMoreButton";
import { Table } from "./ui/Table";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment OrganizationCtmsGrantsTable_Organization on Organization {
    isRemoteEquityEssentials
    ...CtmsGrantsTableRow_Organization
    ...GrantsFilters_Organization @defer
  }
`;

const CTMS_GRANTS_FRAGMENT = graphql`
  fragment OrganizationCtmsGrantsTable_CTMSGrants on CTMSGrant
  @relay(plural: true) {
    id
    ...CtmsGrantsTableRow_CTMSGrant
  }
`;

export interface OrganizationCtmsGrantsTableOrderBy {
  direction: "asc" | "desc";
  field:
    | "cumulativeVested"
    | "equityType"
    | "exercisePrice"
    | "grantee"
    | "label"
    | "quantityExercised"
    | "quantityIssued"
    | "remainingDaysToExercise"
    | "settled"
    | "terminationDate";
}

const OrganizationCtmsGrantsTable_: React.FC<{
  ctmsGrantsFragment: OrganizationCtmsGrantsTable_CTMSGrants$key;
  filters: OrganizationCTMSGrantsFilters;
  hasNext: boolean;
  isLoadingNext: boolean;
  loadNext: () => void;
  onOrderByChange: (
    orderBy: null | OrganizationCTMSGrantsFiltersOrderBy,
  ) => void;
  organizationFragment: OrganizationCtmsGrantsTable_Organization$key;
  renderEmptyState: () => React.ReactNode;
  totalCount: number;
}> = ({
  ctmsGrantsFragment,
  filters,
  hasNext,
  isLoadingNext,
  loadNext,
  onOrderByChange,
  organizationFragment,
  renderEmptyState,
  totalCount,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const ctmsGrants = useFragment(CTMS_GRANTS_FRAGMENT, ctmsGrantsFragment);

  const variant = filters.statusIn?.includes("Active")
    ? "Active"
    : "Terminated";

  if (totalCount === 0) {
    return renderEmptyState();
  }

  return (
    <>
      <Table.Containerized>
        <Table.Header className="border-b-[0.5px] border-gray-04">
          <CtmsGrantsTableRow.Header
            onOrderByChange={onOrderByChange}
            orderBy={filters.orderBy ?? null}
            organizationFragment={organization}
            variant={variant}
          />
        </Table.Header>
        <Table.Body>
          {ctmsGrants.map((ctmsGrant) => (
            <CtmsGrantsTableRow
              ctmsGrantFragment={ctmsGrant}
              key={ctmsGrant.id}
              organizationFragment={organization}
              variant={variant}
            />
          ))}
        </Table.Body>
      </Table.Containerized>
      {hasNext && (
        <div className="flex justify-center">
          <LoadMoreButton
            loading={isLoadingNext}
            onLoadMoreRequest={loadNext}
          />
        </div>
      )}
    </>
  );
};

export const OrganizationCTMSGrantsTable: React.FC<
  Omit<
    React.ComponentProps<typeof OrganizationCtmsGrantsTable_>,
    "onOrderByChange"
  > & {
    onFiltersChange: (filters: OrganizationCTMSGrantsFilters) => void;
  }
> = ({ filters, onFiltersChange, organizationFragment, ...props }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const handleOrderByChange = (
    orderBy: null | OrganizationCTMSGrantsFiltersOrderBy,
  ) => {
    onFiltersChange({
      ...filters,
      orderBy,
    });
  };

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        {!organization.isRemoteEquityEssentials && (
          <Typography
            as="div"
            className="text-black-05"
            variant="Regular/Extra Small"
          >
            Overview of all your active grants and vesting schedules
          </Typography>
        )}
        <Suspense>
          <GrantsFilters
            filters={filters}
            onFiltersChange={onFiltersChange}
            organizationFragment={organization}
          />
        </Suspense>
      </div>
      <SuspenseWithLoadingFallback>
        <OrganizationCtmsGrantsTable_
          {...props}
          filters={filters}
          onOrderByChange={handleOrderByChange}
          organizationFragment={organizationFragment}
        />
      </SuspenseWithLoadingFallback>
    </div>
  );
};
