/**
 * @generated SignedSource<<f9b12a78a6ca14b8428c170d6843666c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlanParametersPage_Organization$data = {
  readonly esopPoolShares: number | null;
  readonly fullyDilutedShares: number | null;
  readonly id: string;
  readonly issuingCompany: {
    readonly name: string | null;
  } | null;
  readonly latestPricePerShare: {
    readonly value: number;
  } | null;
  readonly latestValuation: number | null;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"CurrencyUnit_Organization" | "FormattedCurrencyCompact_Organization" | "FormattedFMV_Organization" | "LargeOneColumnLayout_Organization" | "RemoteInputCurrency_Organization">;
  readonly " $fragmentType": "PlanParametersPage_Organization";
};
export type PlanParametersPage_Organization$key = {
  readonly " $data"?: PlanParametersPage_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlanParametersPage_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlanParametersPage_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "IssuingCompany",
      "kind": "LinkedField",
      "name": "issuingCompany",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestValuation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullyDilutedShares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "esopPoolShares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PricePerShare",
      "kind": "LinkedField",
      "name": "latestPricePerShare",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LargeOneColumnLayout_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurrencyUnit_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedFMV_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedCurrencyCompact_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemoteInputCurrency_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "82d62805cb271d4bba0fd4dd0f643c04";

export default node;
