import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { useFragment } from "react-relay";
import { generatePath, Link, Navigate } from "react-router-dom";
import { graphql } from "relay-runtime";

import { Page } from "../../components/Page";
import { LogoWithoutText } from "../../components/ui/Logo/Logo";
import { RoundedBox } from "../../components/ui/RoundedBox";
import { Tag } from "../../components/ui/Tag";
import { Typography } from "../../components/ui/Typography";
import { AnalyticsContextProvider } from "../../hooks/useAnalytics";
import { FeatureFlagProvider } from "../../hooks/useIsFeatureEnabled";
import { useQuery } from "../../hooks/useQuery";
import {
  APPLICATION_ROUTES,
  GRANTEE_PORTAL_EQUITY_TYPES_GROUPS,
  useGranteeIdParam,
} from "../../paths";
import { useAuthentication } from "../../services/AuthenticationProvider";
import NotFoundPage from "../NotFound/NotFound";
import { EquityTypeSelector_Grantee_Grantee$key } from "./__generated__/EquityTypeSelector_Grantee_Grantee.graphql";
import { EquityTypeSelector_Grantee_Query } from "./__generated__/EquityTypeSelector_Grantee_Query.graphql";

const GRANTEE_FRAGMENT = graphql`
  fragment EquityTypeSelector_Grantee_Grantee on Grantee {
    id
    ctmsGrants {
      isVirtual
    }
  }
`;

const NavigateToGranteeOverview: React.FC<{
  equityTypesGroup: keyof typeof GRANTEE_PORTAL_EQUITY_TYPES_GROUPS;
  granteeId: string;
}> = ({ equityTypesGroup, granteeId }) => {
  return (
    <Navigate
      to={generatePath(APPLICATION_ROUTES.granteeOverview, {
        equityTypesGroup: GRANTEE_PORTAL_EQUITY_TYPES_GROUPS[equityTypesGroup],
        granteeId,
      })}
    />
  );
};

const GranteeEquityTypeSelectorPage_: React.FC<{
  granteeFragment: EquityTypeSelector_Grantee_Grantee$key;
}> = ({ granteeFragment }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  const virtualGrants = grantee.ctmsGrants.filter((grant) => grant.isVirtual);
  const realGrants = grantee.ctmsGrants.filter((grant) => !grant.isVirtual);

  if (virtualGrants.length === 0) {
    return (
      <NavigateToGranteeOverview
        equityTypesGroup="STOCK_OPTIONS"
        granteeId={grantee.id}
      />
    );
  }
  if (realGrants.length === 0) {
    return (
      <NavigateToGranteeOverview
        equityTypesGroup="VIRTUAL_STOCK_OPTIONS"
        granteeId={grantee.id}
      />
    );
  }

  return (
    <div className="flex min-h-screen w-full items-center bg-gray-01 p-10">
      <RoundedBox
        className="m-auto flex w-full max-w-[510px] flex-col items-center gap-10 p-10"
        withBorder
      >
        <LogoWithoutText className="w-10" />
        <Typography variant="Medium/Large">Choose your portal</Typography>

        <Link
          to={generatePath(APPLICATION_ROUTES.granteeOverview, {
            equityTypesGroup: GRANTEE_PORTAL_EQUITY_TYPES_GROUPS.STOCK_OPTIONS,
            granteeId: grantee.id,
          })}
        >
          <RoundedBox
            className="flex w-full items-center gap-4 p-6"
            withBorder
            withShadow
          >
            <div className="flex flex-grow flex-col gap-4">
              <div className="flex items-center gap-4">
                <Typography variant="Medium/Default">Stock Options</Typography>
                <div className="flex gap-2">
                  <Tag color="purple">NSO</Tag>
                  <Tag color="purple">ISO</Tag>
                  <Tag color="purple">EMI</Tag>
                  <Tag color="purple">BSPCE</Tag>
                </div>
              </div>
              <Typography variant="Regular/Small">
                An option is an agreement with your company that gives you the
                right, if you stay long enough in your company, to buy a slice
                of the company yourself at a low price if your company proves to
                be successful.
              </Typography>
            </div>
            <ArrowRightIcon className="h-6 w-6 shrink-0 text-primary" />
          </RoundedBox>
        </Link>
        <Link
          to={generatePath(APPLICATION_ROUTES.granteeOverview, {
            equityTypesGroup:
              GRANTEE_PORTAL_EQUITY_TYPES_GROUPS.VIRTUAL_STOCK_OPTIONS,
            granteeId: grantee.id,
          })}
        >
          <RoundedBox
            className="flex w-full items-center gap-4 p-6"
            withBorder
            withShadow
          >
            <div className="flex flex-grow flex-col gap-4">
              <div className="flex items-center gap-4">
                <Typography variant="Medium/Default">
                  Virtual Stock Options
                </Typography>
                <div className="flex gap-2">
                  <Tag color="purple">VSOP</Tag>
                  <Tag color="purple">SAR</Tag>
                </div>
              </div>
              <Typography variant="Regular/Small">
                Virtual stock options, also called phantom stock are a type of
                equity incentive that allow you to benefit from an increase in
                your company&apos;s stock price without actually owning any
                stock or stock options.
              </Typography>
            </div>
            <ArrowRightIcon className="h-6 w-6 shrink-0 text-primary" />
          </RoundedBox>
        </Link>
        <RoundedBox className="flex w-full items-center gap-4 !bg-gray-01 p-6">
          <div className="flex flex-grow flex-col gap-4">
            <div className="flex items-center gap-4">
              <Typography variant="Medium/Default">RSU</Typography>
              <div className="flex gap-2">
                <Tag color="purple">RSU</Tag>
                <Tag color="purple">RSA</Tag>
                <Tag color="purple">RGA</Tag>
              </div>
            </div>
            <div>
              <Tag color="gray">Coming soon...</Tag>
            </div>
          </div>
          <ArrowRightIcon className="h-6 w-6 shrink-0 text-primary" />
        </RoundedBox>
      </RoundedBox>
    </div>
  );
};

const QUERY = graphql`
  query EquityTypeSelector_Grantee_Query($granteeId: GranteeId!) {
    grantee(id: $granteeId) {
      ...EquityTypeSelector_Grantee_Grantee
      organization {
        id
        name
      }
    }
  }
`;

const GranteeEquityTypeSelectorPage: React.FC = () => {
  const granteeId = useGranteeIdParam();
  const { query } = useQuery<EquityTypeSelector_Grantee_Query>(QUERY, {
    granteeId,
  });
  const { account } = useAuthentication();

  if (!query.grantee) {
    return <NotFoundPage />;
  }

  return (
    <FeatureFlagProvider
      account={account}
      organizationName={query.grantee.organization.name}
    >
      <AnalyticsContextProvider organizationId={query.grantee.organization.id}>
        <Page
          analyticsCategory="Grantee view"
          analyticsName="Grantee - EquityTypeSelector"
          organizationId={query.grantee.organization.id}
          title={`Grantee | ${query.grantee.organization.name} equity type selector`}
        >
          <GranteeEquityTypeSelectorPage_ granteeFragment={query.grantee} />
        </Page>
      </AnalyticsContextProvider>
    </FeatureFlagProvider>
  );
};

export default GranteeEquityTypeSelectorPage;
