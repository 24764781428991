import {
  ArrowUpRightIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { graphql, useFragment } from "react-relay";

import { HelpLink_Organization$key } from "./__generated__/HelpLink_Organization.graphql";
import { Equity101DrawerRemote } from "./Equity101Drawer/Equity101Drawer";

const ORGANIZATION_FRAGMENT = graphql`
  fragment HelpLink_Organization on Organization {
    ...Equity101Drawer_Organization
  }
`;

export function HelpLink({
  organizationFragment,
}: {
  organizationFragment: HelpLink_Organization$key;
}) {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <Equity101DrawerRemote.Controller
      render={({ open }) => (
        <button
          className="flex items-center gap-2 rounded-remote-sm bg-brand-100 p-2 text-SM"
          onClick={() => {
            open({
              data: {
                organizationFragment: organization,
              },
            });
          }}
        >
          <InformationCircleIcon className="size-5 text-brand-600" />
          <span>
            Feeling lost?{" "}
            <span className="font-medium text-brand-600">
              Read the guide{" "}
              <ArrowUpRightIcon className="-mt-1 inline size-3" />
            </span>
          </span>
        </button>
      )}
    />
  );
}
