import { zodResolver } from "@hookform/resolvers/zod";
import { Button, StandaloneLink } from "@remote-com/norma";
import { IconV2OutlineArrowUpRight } from "@remote-com/norma/icons/IconV2OutlineArrowUpRight";
import { ArrowRight } from "iconsax-react";
import { useCallback, useId } from "react";
import { DeepPartial, useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { KeyEquityPlanParameterExplanationDrawer } from "../../../../components/KeyEquityPlanParameterExplanationDrawer";
import { RemoteInputCurrency } from "../../../../components/ui/Form/Inputs/RemoteInputCurrency";
import { RemoteInputText } from "../../../../components/ui/Form/Inputs/RemoteInputText";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import {
  EssentialsOnboardingTemplate,
  EssentialsOnboardingTemplateFooter,
} from "../EssentialsOnboardingTemplate";
import { IssuingCompanyCard } from "../IssuingCompanyCard";
import { KeyEquityPlansParametersPage_Organization$key } from "./__generated__/KeyEquityPlansParametersPage_Organization.graphql";
import { KeyEquityPlansParametersPage_SetEssentialsKeyEquityPlansParametersOnboardingStepAsCompleted_Mutation } from "./__generated__/KeyEquityPlansParametersPage_SetEssentialsKeyEquityPlansParametersOnboardingStepAsCompleted_Mutation.graphql";
import illustration from "./illustration.png";

const ORGANIZATION_FRAGMENT = graphql`
  fragment KeyEquityPlansParametersPage_Organization on Organization {
    id
    issuingCompany {
      name
      city
      country {
        emoji
        name
      }
    }
    latestFairMarketValue {
      value
    }
    ...IssuingCompanyCard_Organization
    ...RemoteInputCurrency_Organization
  }
`;

const SET_ESSENTIALS_EQUITY_PLAN_PARAMETERS_ONBOARDING_STEP_AS_COMPLETED_MUTATION = graphql`
  mutation KeyEquityPlansParametersPage_SetEssentialsKeyEquityPlansParametersOnboardingStepAsCompleted_Mutation(
    $esopPoolShares: Int
    $fullyDilutedShares: Int
    $organizationId: OrganizationId!
    $pricePerShare: Float
  ) {
    setEssentialsEquityPlanParametersOnboardingStepAsCompleted(
      esopPoolShares: $esopPoolShares
      fullyDilutedShares: $fullyDilutedShares
      organizationId: $organizationId
      pricePerShare: $pricePerShare
    ) {
      currentRemoteOnboardingStep
    }
  }
`;

const FORM_SCHEMA = z
  .object({
    fullyDilutedShares: z.coerce.number().positive(),
    poolSize: z.coerce.number().nonnegative(),
    pricePerShare: z.coerce.number().positive(),
  })
  .refine((data) => data.poolSize < data.fullyDilutedShares, {
    message: "Pool size cannot be greater than fully-diluted shares",
    path: ["poolSize"],
  });

type FormInput = DeepPartial<z.input<typeof FORM_SCHEMA>>;
type FormOutput = z.output<typeof FORM_SCHEMA>;

export default function KeyEquityPlansParametersPage({
  onNextStep,
  onPreviousStep,
  organizationFragment,
  previousStepIndex,
}: {
  onNextStep: () => void;
  onPreviousStep: () => void;
  organizationFragment: KeyEquityPlansParametersPage_Organization$key;
  previousStepIndex?: number;
}) {
  const formId = useId();
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const form = useForm<FormInput, unknown, FormOutput>({
    resolver: zodResolver(FORM_SCHEMA),
  });

  const [
    setEssentialsEquityPlanParametersOnboardingStepAsCompleted,
    mutationIsInFlight,
  ] =
    useSafeMutation<KeyEquityPlansParametersPage_SetEssentialsKeyEquityPlansParametersOnboardingStepAsCompleted_Mutation>(
      SET_ESSENTIALS_EQUITY_PLAN_PARAMETERS_ONBOARDING_STEP_AS_COMPLETED_MUTATION,
    );

  const handleFormSubmit = form.handleSubmit(
    async ({ fullyDilutedShares, poolSize, pricePerShare }) => {
      await setEssentialsEquityPlanParametersOnboardingStepAsCompleted({
        variables: {
          esopPoolShares: poolSize,
          fullyDilutedShares,
          organizationId: organization.id,
          pricePerShare,
        },
      });

      onNextStep();
    },
  );

  const handleSkipButtonClicked = useCallback(() => {
    void setEssentialsEquityPlanParametersOnboardingStepAsCompleted({
      variables: { organizationId: organization.id },
    }).then(() => {
      onNextStep();
    });
  }, [
    organization.id,
    onNextStep,
    setEssentialsEquityPlanParametersOnboardingStepAsCompleted,
  ]);

  const [fullyDilutedShares, poolSize, pricePerShare] = form.watch([
    "fullyDilutedShares",
    "poolSize",
    "pricePerShare",
  ]);

  return (
    <EssentialsOnboardingTemplate
      aside={
        <IssuingCompanyCard
          companyCity={organization.issuingCompany?.city}
          companyCountry={organization.issuingCompany?.country?.name}
          companyCountryEmoji={organization.issuingCompany?.country?.emoji}
          companyName={organization.issuingCompany?.name}
          fairMarketValue={organization.latestFairMarketValue?.value}
          fullyDilutedSharesCount={fullyDilutedShares}
          organizationFragment={organization}
          poolSize={poolSize}
          pricePerShare={pricePerShare}
        />
      }
      footer={
        <EssentialsOnboardingTemplateFooter
          onPreviousClick={onPreviousStep}
          previousStepIndex={previousStepIndex}
          stepIndex={4}
          stepName="Key equity plan parameters"
          submitButton={
            <Button
              disabled={mutationIsInFlight}
              form={formId}
              IconAfter={ArrowRight}
              isLoading={mutationIsInFlight}
              type="submit"
            >
              Continue
            </Button>
          }
        />
      }
      illustration={illustration}
      subtitle="We’ll use these key parameters to enhance admin experience and employee communication"
      title="Key equity plan parameters"
    >
      <form className="space-y-14" id={formId} onSubmit={handleFormSubmit}>
        <div className="space-y-4">
          <RemoteInputText.Form
            control={form.control}
            label="Current (fully-diluted) number of shares"
            name="fullyDilutedShares"
          />
          <RemoteInputText.Form
            control={form.control}
            label="Pool size"
            name="poolSize"
          />
          <RemoteInputCurrency.Form
            control={form.control}
            label="Current price per share"
            name="pricePerShare"
            organizationFragment={organization}
          />
          <div className="px-4">
            <KeyEquityPlanParameterExplanationDrawer>
              <StandaloneLink IconAfter={IconV2OutlineArrowUpRight} size="sm">
                Why do we need these parameters and who will access it?
              </StandaloneLink>
            </KeyEquityPlanParameterExplanationDrawer>
          </div>
        </div>
        <Button
          IconAfter={ArrowRight}
          onClick={handleSkipButtonClicked}
          tone="secondary"
          type="button"
        >
          I’ll answer later
        </Button>
      </form>
    </EssentialsOnboardingTemplate>
  );
}
