import { BoxedIcon, BoxedIconTone, Button, Text } from "@remote-com/norma";
import { IconV2DuotoneBilling } from "@remote-com/norma/icons/IconV2DuotoneBilling";
import { IconV2DuotoneBriefcase } from "@remote-com/norma/icons/IconV2DuotoneBriefcase";
import { IconV2DuotoneDollarCircle } from "@remote-com/norma/icons/IconV2DuotoneDollarCircle";
import { IconV2DuotonePieChart } from "@remote-com/norma/icons/IconV2DuotonePieChart";
import { FormattedNumber } from "react-intl";
import { graphql, useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";

import { FormattedCurrencyCompact } from "../../../../components/Formatted/FormattedCurrencyCompact";
import { FormattedFMV } from "../../../../components/FormattedFMV";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { APPLICATION_ROUTES } from "../../../../paths";
import { EquityPlanParametersCard_EssentialsOverview_Organization$key } from "./__generated__/EquityPlanParametersCard_EssentialsOverview_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment EquityPlanParametersCard_EssentialsOverview_Organization on Organization {
    id
    name
    issuingCompany {
      name
    }
    latestFairMarketValue {
      value
    }
    latestValuation
    fullyDilutedShares
    latestPricePerShare {
      value
    }
    ...FormattedCurrencyCompact_Organization
    ...FormattedFMV_Organization
  }
`;

export const EquityPlanParametersCardRow: React.FC<{
  children: React.ReactNode;
  Icon: React.ElementType;
  label: React.ReactNode;
  tone?: BoxedIconTone;
}> = ({ children, Icon, label, tone = "grey" }) => {
  return (
    <div className="flex items-center gap-4 py-4">
      <BoxedIcon Icon={Icon} size="sm" tone={tone} />
      <div>
        <Text variant="lgMedium">{children}</Text>
        <Text className="uppercase text-grey-600" variant="2xsMedium">
          {label}
        </Text>
      </div>
    </div>
  );
};

export const EquityPlanParametersCard: React.FC<{
  organizationFragment: EquityPlanParametersCard_EssentialsOverview_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <RoundedBox className="space-y-2 p-6" withBorder>
      <Text variant="lgMedium">
        {organization.issuingCompany?.name ?? organization.name}
      </Text>
      <div className="divide-y-[0.5px] divide-grey-300 border-b-[0.5px] border-grey-300">
        <EquityPlanParametersCardRow
          Icon={IconV2DuotoneBilling}
          label="Fair Market Value"
        >
          {organization.latestFairMarketValue ? (
            <FormattedFMV
              organizationFragment={organization}
              value={organization.latestFairMarketValue.value}
            />
          ) : (
            "-"
          )}
        </EquityPlanParametersCardRow>
        <EquityPlanParametersCardRow
          Icon={IconV2DuotoneBriefcase}
          label="Latest valuation"
        >
          {organization.latestValuation ? (
            <FormattedCurrencyCompact
              organizationFragment={organization}
              value={organization.latestValuation}
            />
          ) : (
            "-"
          )}
        </EquityPlanParametersCardRow>
        <EquityPlanParametersCardRow
          Icon={IconV2DuotonePieChart}
          label="Fully diluted shares"
        >
          {organization.fullyDilutedShares ? (
            <FormattedNumber value={organization.fullyDilutedShares} />
          ) : (
            "-"
          )}
        </EquityPlanParametersCardRow>
        <EquityPlanParametersCardRow
          Icon={IconV2DuotoneDollarCircle}
          label="Price per share"
        >
          {organization.latestPricePerShare ? (
            <FormattedFMV
              organizationFragment={organization}
              value={organization.latestPricePerShare.value}
            />
          ) : (
            "-"
          )}
        </EquityPlanParametersCardRow>
      </div>
      <Text className="text-grey-600" variant="sm">
        Edit your prices in your company settings
      </Text>
      <Button
        as={Link}
        size="sm"
        to={generatePath(
          APPLICATION_ROUTES.organizationCompanySettingsPlanParameters,
          {
            organizationId: organization.id,
          },
        )}
        variant="outline"
      >
        Edit company settings
      </Button>
    </RoundedBox>
  );
};
