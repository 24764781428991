/**
 * @generated SignedSource<<50c663fa086a6ec90cf124897a7ed7fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CTMSSolution = "ANGELLIST" | "ASTRELLA" | "CAPDESK" | "CARTA" | "CERTENT" | "COMPTRACK" | "EQUIFY" | "GLOBAL_SHARES" | "LEDGY" | "LTSE_EQUITY" | "NO_CTMS" | "OTHER" | "PULLEY" | "SHAREWORKS" | "SPREADSHEETS" | "VESTD";
import { FragmentRefs } from "relay-runtime";
export type CompanyDetailsPage_Organization$data = {
  readonly declaredCTMSSolution: CTMSSolution | null;
  readonly grantees: {
    readonly edges: ReadonlyArray<{
      readonly __typename: "GranteeEdge";
    }>;
  };
  readonly id: string;
  readonly issuingCompany: {
    readonly country: {
      readonly emoji: string;
      readonly name: string;
    } | null;
    readonly name: string | null;
  } | null;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"CurrencySymbol_Organization" | "CurrencyUnit_Organization" | "DeleteOrganizationButton_Organization" | "LargeOneColumnLayout_Organization">;
  readonly " $fragmentType": "CompanyDetailsPage_Organization";
};
export type CompanyDetailsPage_Organization$key = {
  readonly " $data"?: CompanyDetailsPage_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompanyDetailsPage_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CompanyDetailsPage_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "IssuingCompany",
      "kind": "LinkedField",
      "name": "issuingCompany",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Country",
          "kind": "LinkedField",
          "name": "country",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "emoji",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GranteesConnection",
      "kind": "LinkedField",
      "name": "grantees",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GranteeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "declaredCTMSSolution",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LargeOneColumnLayout_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteOrganizationButton_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurrencySymbol_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurrencyUnit_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "8a46d8729af238559db819a33b02408b";

export default node;
