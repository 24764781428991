import { useCallback, useMemo } from "react";
import {
  matchPath,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { z } from "zod";

import { stringIsEquityTypeWorkRelationshipCategory } from "./services/workRelationship";

export const APPLICATION_ROUTES = {
  authJoinTheTeam: "/join-the-team",
  boardMember: "/board-member/:boardMemberId",
  boardMemberApprovals: "/board-member/:boardMemberId/approvals",
  boardMemberEquityGrid: "/board-member/:boardMemberId/equity/grid",
  boardMemberOverview: "/board-member/:boardMemberId/overview",
  candidateOffer: "/offer/:equityOfferCandidateAccessSecretKey",
  employeePortal: "/grantee/:granteeId",
  forgotPassword: "/forgot-password",
  forgotPasswordSuccess: "/forgot-password-success",
  granteeExercise: "/grantee/:granteeId/:equityTypesGroup/exercise",
  granteeFAQ: "/grantee/:granteeId/:equityTypesGroup/faq",
  granteeOverview: "/grantee/:granteeId/:equityTypesGroup/overview",
  granteeProjection: "/grantee/:granteeId/:equityTypesGroup/projection",
  granteeReporting: "/grantee/:granteeId/:equityTypesGroup/reporting",
  granteeTaxation: "/grantee/:granteeId/:equityTypesGroup/taxation",
  organizationAssistedGrant: "/organizations/:organizationId/assisted-grant",
  organizationAssistedGrantGrantee:
    "/organizations/:organizationId/assisted-grant/grantee",
  organizationAssistedGrantGranteeGrantee:
    "/organizations/:organizationId/assisted-grant/grantee/grantee",
  organizationAssistedGrantGranteeInstrument:
    "/organizations/:organizationId/assisted-grant/grantee/instrument",
  organizationAssistedGrantReview:
    "/organizations/:organizationId/assisted-grant/review",
  organizationAssistedGrantShares:
    "/organizations/:organizationId/assisted-grant/shares",
  organizationAssistedGrantVesting:
    "/organizations/:organizationId/assisted-grant/vesting",
  organizationAssistedGrantVestingPTEP:
    "/organizations/:organizationId/assisted-grant/vesting/post-termination-exercise-period",
  organizationAssistedGrantVestingVestingSchedule:
    "/organizations/:organizationId/assisted-grant/vesting/vesting-schedule",
  organizationCompanySettings:
    "/organizations/:organizationId/company-settings",
  organizationCompanySettingsAddNewFairMarketValue:
    "/organizations/:organizationId/company-settings/fair-market-value/add-new",
  organizationCompanySettingsCompanyDetails:
    "/organizations/:organizationId/company-settings/company-details",
  organizationCompanySettingsFairMarketValue:
    "/organizations/:organizationId/company-settings/fair-market-value",
  organizationCompanySettingsPlanParameters:
    "/organizations/:organizationId/company-settings/plan-parameters",
  organizationCountries: "/organizations/:organizationId/countries",
  organizationCountry: "/organizations/:organizationId/countries/:countryCode",
  organizationCTMSGrantAmendmentRequest:
    "/organizations/:organizationId/grant-amendment-requests/:ctmsGrantAmendmentRequestId",
  organizationCTMSGrantAmendmentRequestReviewModification:
    "/organizations/:organizationId/grant-amendment-requests/:ctmsGrantAmendmentRequestId/review-modification",
  organizationCTMSGrantAmendmentRequestsBoardApproval:
    "/organizations/:organizationId/grant-amendment-requests/board-approval",
  organizationCTMSGrantAmendmentRequestsImplementation:
    "/organizations/:organizationId/grant-amendment-requests/implementation",
  organizationCTMSGrantAmendmentRequestsPendingApproval:
    "/organizations/:organizationId/grant-amendment-requests/pending-approval",
  organizationCTMSGrantAmendmentRequestsUnderReview:
    "/organizations/:organizationId/grant-amendment-requests/under-review",
  organizationDeclareNewExerciseRequests:
    "/organizations/:organizationId/exercises/declare-new",
  organizationDeclareNewExerciseRequestsForCTMSGrant:
    "/organizations/:organizationId/exercises/declare-new/:selectedGranteeId/:selectedCTMSGrantLabel",
  organizationDeclareNewExerciseRequestsForGrantee:
    "/organizations/:organizationId/exercises/declare-new/:selectedGranteeId",
  organizationDeclareNewGrant:
    "/organizations/:organizationId/equity/grants/declare-new",
  organizationDeclareNewGrantForGrantee:
    "/organizations/:organizationId/equity/grants/declare-new/:selectedGranteeId",
  organizationDocuments: "/organizations/:organizationId/documents",
  organizationDocumentsBoardConsents:
    "/organizations/:organizationId/documents/board-consents",
  organizationDocumentsGeographies:
    "/organizations/:organizationId/documents/geographies",
  organizationDocumentsGrantDocumentation:
    "/organizations/:organizationId/documents/grant-documentation",
  organizationDocumentsGrantDocumentationDetails:
    "/organizations/:organizationId/documents/grant-documentation/:easopGrantId",
  organizationDocumentsLegalDocumentation:
    "/organizations/:organizationId/documents/legal-documentation",
  organizationEmployeePortalsSettings:
    "/organizations/:organizationId/employee-portals-settings",
  organizationEquityConfigure:
    "/organizations/:organizationId/equity/configure",
  organizationEquityConfigureAcceleration:
    "/organizations/:organizationId/equity/configure/acceleration",
  organizationEquityConfigureDoubleTriggerAccelerationDetails:
    "/organizations/:organizationId/equity/configure/acceleration/double-trigger",
  organizationEquityConfigureEarlyExercise:
    "/organizations/:organizationId/equity/configure/early-exercise",
  organizationEquityConfigureEquityGrid:
    "/organizations/:organizationId/equity/configure/equity-grid",
  organizationEquityConfigureEquityOffers:
    "/organizations/:organizationId/equity/configure/equity-offers",
  organizationEquityConfigureEquityPhilosophy:
    "/organizations/:organizationId/equity/configure/equity-philosophy",
  organizationEquityConfigureNewHires:
    "/organizations/:organizationId/equity/configure/new-hires",
  organizationEquityConfigureNewHiresAdvisors:
    "/organizations/:organizationId/equity/configure/new-hires/advisors",
  organizationEquityConfigureNewHiresEmployeesAndContractors:
    "/organizations/:organizationId/equity/configure/new-hires/employees-and-contractors",
  organizationEquityConfigurePostTermination:
    "/organizations/:organizationId/equity/configure/post-termination",
  organizationEquityConfigureRefreshers:
    "/organizations/:organizationId/equity/configure/refreshers",
  organizationEquityConfigureSingleTriggerAccelerationDetails:
    "/organizations/:organizationId/equity/configure/acceleration/single-trigger",
  organizationEquityConfigureVestingSchedules:
    "/organizations/:organizationId/equity/configure/vesting-schedules",
  organizationEquityConfigureVestingSchedulesAddNew:
    "/organizations/:organizationId/equity/configure/vesting-schedules/add-new",
  organizationEquityConfigureVestingSchedulesDetailsView:
    "/organizations/:organizationId/equity/configure/vesting-schedules/:vestingScheduleId",
  organizationEquityCtmsGrant:
    "/organizations/:organizationId/equity/grants/ctms/:ctmsGrantId",
  organizationEquityCtmsGrantExerciseRequestSimulation:
    "/organizations/:organizationId/equity/grants/ctms/:ctmsGrantId/exercise-request-simulation",
  organizationEquityCtmsGrantModify:
    "/organizations/:organizationId/equity/grants/ctms/:ctmsGrantId/modify",
  organizationEquityCtmsGrantModifyReview:
    "/organizations/:organizationId/equity/grants/ctms/:ctmsGrantId/modify/review",
  organizationEquityGrants: "/organizations/:organizationId/equity/grants",
  organizationEquityGrantsActive:
    "/organizations/:organizationId/equity/grants/active",
  organizationEquityGrantsCanceled:
    "/organizations/:organizationId/equity/grants/canceled",
  organizationEquityGrantsTerminated:
    "/organizations/:organizationId/equity/grants/terminated",
  organizationEquityHandbooks:
    "/organizations/:organizationId/equity-handbooks",
  organizationEquityHandbooksCountry:
    "/organizations/:organizationId/equity-handbooks/:countryCode",
  organizationEquityHandbooksCountryEquityType:
    "/organizations/:organizationId/equity-handbooks/:countryCode/:equityTypeId",
  organizationEquityTypeWorkRelationship:
    "/organizations/:organizationId/equity-types/:equityTypeId/work-relationships/:equityTypeWorkRelationshipCategory",
  organizationExerciseRequestDetails:
    "/organizations/:organizationId/exercises/:ctmsExerciseRequestCTMSId",
  organizationExerciseRequests: "/organizations/:organizationId/exercises",
  organizationExerciseRequestsActive:
    "/organizations/:organizationId/exercises/active",
  organizationExerciseRequestsCanceled:
    "/organizations/:organizationId/exercises/canceled",
  organizationExerciseRequestsCompleted:
    "/organizations/:organizationId/exercises/completed",
  organizationFairMarketValueApprovalsBoardApproval:
    "/organizations/:organizationId/fair-market-value-approvals/board-approval",
  organizationFairMarketValueApprovalsPendingApproval:
    "/organizations/:organizationId/fair-market-value-approvals/pending-approval",
  organizationFairMarketValueApprovalsUnderReview:
    "/organizations/:organizationId/fair-market-value-approvals/under-review",
  organizationFinishOnboarding:
    "/organizations/:organizationId/finish-onboarding",
  organizationGrantee: "/organizations/:organizationId/grantees/:granteeId",
  organizationGrantees: "/organizations/:organizationId/grantees",
  organizationGranteesActive: "/organizations/:organizationId/grantees/active",
  organizationGranteesTerminated:
    "/organizations/:organizationId/grantees/terminated",
  organizationGranteeTerminationCTMSGrantAmendmentRequest:
    "/organizations/:organizationId/grantee-termination-grant-amendment-requests/:granteeTerminationCTMSGrantAmendmentRequestId",
  organizationGranteeTerminationCTMSGrantAmendmentRequestsBoardApproval:
    "/organizations/:organizationId/grantee-termination-grant-amendment-requests/board-approval",
  organizationGranteeTerminationCTMSGrantAmendmentRequestsImplementation:
    "/organizations/:organizationId/grantee-termination-grant-amendment-requests/implementation",
  organizationGranteeTerminationCTMSGrantAmendmentRequestsPendingApproval:
    "/organizations/:organizationId/grantee-termination-grant-amendment-requests/pending-approval",
  organizationGranteeTerminationCTMSGrantAmendmentRequestsUnderReview:
    "/organizations/:organizationId/grantee-termination-grant-amendment-requests/under-review",
  organizationHome: "/organizations/:organizationId",
  organizationInsights: "/organizations/:organizationId/insights",
  organizationInsightsCliffPeriods:
    "/organizations/:organizationId/insights/cliff-periods",
  organizationInsightsEquityGridLevels:
    "/organizations/:organizationId/insights/equity-grid-levels",
  organizationInsightsExercisesActivity:
    "/organizations/:organizationId/insights/exercises-activity",
  organizationInsightsLargestGain:
    "/organizations/:organizationId/insights/largest-gain",
  organizationInsightsPTEP: "/organizations/:organizationId/insights/ptep",
  organizationInsightsQuantityVested:
    "/organizations/:organizationId/insights/quantity-vested",
  organizationInsightsTeamIncentivization:
    "/organizations/:organizationId/insights/team-incentivization",
  organizationInsightsUnderwaterGrants:
    "/organizations/:organizationId/insights/underwater-grants",
  organizationInsightsVestingSchedules:
    "/organizations/:organizationId/insights/vesting-schedules",
  organizationNewGrantee: "/organizations/:organizationId/grantees/new-grantee",
  organizationPlanning: "/organizations/:organizationId/planning",
  organizationPlanningIgnored:
    "/organizations/:organizationId/planning/ignored",
  organizationPlanningProjection:
    "/organizations/:organizationId/planning/projection",
  organizationPrepareYourGrants:
    "/organizations/:organizationId/prepare-your-grants",
  organizationPrepareYourGrantsBoardApproval:
    "/organizations/:organizationId/prepare-your-grants/board-approval",
  organizationPrepareYourGrantsDetails:
    "/organizations/:organizationId/prepare-your-grants/grants/:easopGrantId",
  organizationPrepareYourGrantsDrafts:
    "/organizations/:organizationId/prepare-your-grants/drafts",
  organizationPrepareYourGrantsPendingApproval:
    "/organizations/:organizationId/prepare-your-grants/pending-approval",
  organizationPrepareYourGrantsPendingImplementation:
    "/organizations/:organizationId/prepare-your-grants/pending-implementation",
  organizationPrepareYourGrantsUnderReview:
    "/organizations/:organizationId/prepare-your-grants/under-review",
  organizationRSUVesting: "/organizations/:organizationId/rsu-vesting",
  organizationSettings: "/organizations/:organizationId/settings",
  organizationSettingsAdmins: "/organizations/:organizationId/settings/admins",
  organizationSettingsBilling:
    "/organizations/:organizationId/settings/billing",
  organizationSettingsBoardMembers:
    "/organizations/:organizationId/settings/board-members",
  organizationSettingsCleanupGrantees:
    "/organizations/:organizationId/settings/cleanup-grantees",
  organizationSettingsCleanupGranteesCreateNewGrantees:
    "/organizations/:organizationId/settings/cleanup-grantees/create-new-grantees",
  organizationSettingsCleanupGranteesIgnored:
    "/organizations/:organizationId/settings/cleanup-grantees/ignored",
  organizationSettingsCleanupGranteesSynchronizeEmployeesData:
    "/organizations/:organizationId/settings/cleanup-grantees/synchronize-employees-data",
  organizationSettingsCleanupGranteesUpdateInformation:
    "/organizations/:organizationId/settings/cleanup-grantees/update-information",
  organizationSettingsEquityViewers:
    "/organizations/:organizationId/settings/equity-viewers",
  organizationSettingsIntegrations:
    "/organizations/:organizationId/settings/integrations",
  organizationSettingsIntegrationsCarta:
    "/organizations/:organizationId/settings/integrations/carta",
  organizationSettingsIntegrationsDeel:
    "/organizations/:organizationId/settings/integrations/deel",
  organizationSettingsSafeCalculator:
    "/organizations/:organizationId/settings/safe-converter",
  organizationSettingsSafeCalculatorVersion:
    "/organizations/:organizationId/settings/safe-converter/:safeCalculatorVersionId",
  organizationTerminateGrantee:
    "/organizations/:organizationId/grantees/:granteeId/terminate",
  organizationToolsEquityOfferCandidate:
    "/organizations/:organizationId/equity-offer/:equityOfferId/candidate",
  organizationToolsEquityOfferCompany:
    "/organizations/:organizationId/equity-offer/:equityOfferId/company",
  organizationToolsEquityOfferEquiuniversity:
    "/organizations/:organizationId/equity-offer/:equityOfferId/equiuniversity",
  organizationToolsEquityOfferFinal:
    "/organizations/:organizationId/equity-offer/:equityOfferId/final-thoughts",
  organizationToolsEquityOfferIncentive:
    "/organizations/:organizationId/equity-offer/:equityOfferId/incentive",
  organizationToolsEquityOfferProjection:
    "/organizations/:organizationId/equity-offer/:equityOfferId/projection",
  organizationToolsEquityOffersSettings:
    "/organizations/:organizationId/tools/equity-offers",
  organizationToolsEquityOfferVesting:
    "/organizations/:organizationId/equity-offer/:equityOfferId/vesting",
  organizationToolsGranteePortalSettings:
    "/organizations/:organizationId/tools/grantee-portal",
  organizationUnlockCountries:
    "/organizations/:organizationId/countries/unlock",
  organizationUnlockCountry:
    "/organizations/:organizationId/countries/unlock/:countryCode",
  roleSelection: "/",
  setupWorkspace: "/setup-workspace",
  setupWorkspaceAccountCreation: "/setup-workspace/account-creation",
  setupWorkspaceCartaIssuerSelection: "/setup-workspace/carta-issuer-selection",
  signUp: "/sign-up",
  superAdmin: "/administration",
  superAdminBulkUploadDrafts: "/administration/bulk-upload-drafts",
  superAdminCartaOrganizations: "/administration/carta-organizations",
  superAdminChangeGrantInstrument:
    "/administration/organization-admin/change-grant-instrument",
  superAdminDocumentsApproval:
    "/administration/legal-content/documents-approval",
  superAdminDocumentsUpload: "/administration/documents-upload",
  superAdminDocumentsUploadOrganizationView:
    "/administration/documents-upload/:organizationId",
  superAdminEarlyExerciseBeneficial:
    "/administration/legal-content/early-exercise-beneficial",
  superAdminEquityAlerting:
    "/administration/organization-admin/equity-alerting",
  superAdminEquityHandbooks: "/administration/legal-content/equity-handbooks",
  superAdminEquityHandbooksEquityType:
    "/administration/legal-content/equity-handbooks/geographies/:countryCode/equity-types/:equityTypeId",
  superAdminEquityHandbooksEquityTypeForContractor:
    "/administration/legal-content/equity-handbooks/geographies/:countryCode/equity-types/:equityTypeId/contractor",
  superAdminEquityHandbooksEquityTypeForDirectEmployee:
    "/administration/legal-content/equity-handbooks/geographies/:countryCode/equity-types/:equityTypeId/direct-employee",
  superAdminEquityHandbooksEquityTypeForEoREmployee:
    "/administration/legal-content/equity-handbooks/geographies/:countryCode/equity-types/:equityTypeId/eor-employee",
  superAdminEquityHandbooksGeography:
    "/administration/legal-content/equity-handbooks/geographies/:countryCode",
  superAdminEquityHandbooksPublishedGeographies:
    "/administration/legal-content/equity-handbooks/published-geographies",
  superAdminEquityHandbooksUnpublishedGeographies:
    "/administration/legal-content/equity-handbooks/unpublished-geographies",
  superAdminExercisesTaxParameters:
    "/administration/legal-content/exercises-tax-parameters",
  superAdminExercisesTaxParametersEdition:
    "/administration/legal-content/exercises-tax-parameters/:equityTypeId",
  superAdminLegalContent: "/administration/legal-content",
  superAdminLegalWatchOuts: "/administration/legal-content/legal-watch-outs",
  superAdminLegalWatchOutsEdition:
    "/administration/legal-content/legal-watch-outs/:equityTypeId",
  superAdminOngoingObligations:
    "/administration/legal-content/ongoing-obligations",
  superAdminOrganizationAdmin: "/administration/organization-admin",
  superAdminRemoteEquityPlanThresholdsExceededOrganizations:
    "/administration/organization-admin/remote-equity-plan-thresholds-exceeded",
  superAdminRepricing: "/administration/organization-admin/repricing",
  superAdminSubplanApprovalDate:
    "/administration/organization-admin/subplan-approval-date",
  superAdminUnusedGeographies: "/administration/unused-geographies",
} as const;

export const useLocationMatchesRoutes = (routes: ApplicationRoute[]) => {
  const location = useLocation();
  const locationMatchesRoutes = useMemo(() => {
    return routes.some((route) => {
      const routePath = APPLICATION_ROUTES[route];
      return matchPath(routePath, location.pathname);
    });
  }, [location, routes]);
  return locationMatchesRoutes;
};

export type ApplicationRoute = keyof typeof APPLICATION_ROUTES;

export const useOrganizationIdParam = () => {
  const { organizationId } = useParams();
  if (!organizationId) {
    throw new Error("can't get organizationId param");
  }

  return organizationId;
};

export const useGranteeTerminationCTMSGrantAmendmentRequestIdParam = () => {
  const { granteeTerminationCTMSGrantAmendmentRequestId } = useParams();
  if (!granteeTerminationCTMSGrantAmendmentRequestId) {
    throw new Error(
      "can't get granteeTerminationCTMSGrantAmendmentRequestId param",
    );
  }

  return granteeTerminationCTMSGrantAmendmentRequestId;
};

export const useCTMSGrantAmendmentRequestIdParam = () => {
  const { ctmsGrantAmendmentRequestId } = useParams();
  if (!ctmsGrantAmendmentRequestId) {
    throw new Error("can't get ctmsGrantAmendmentRequestId param");
  }

  return ctmsGrantAmendmentRequestId;
};

export const useEquityOfferIdParam = () => {
  const { equityOfferId } = useParams();
  if (!equityOfferId) {
    throw new Error("can't get equityOfferId param");
  }

  return equityOfferId;
};

export const useEquityOfferCandidateAccessSecretKey = () => {
  const { equityOfferCandidateAccessSecretKey } =
    useParams<"equityOfferCandidateAccessSecretKey">();
  if (!equityOfferCandidateAccessSecretKey) {
    throw new Error("can't get equityOfferCandidateAccessSecretKey param");
  }

  return equityOfferCandidateAccessSecretKey;
};

export const useBoardMemberIdParam = () => {
  const { boardMemberId } = useParams();
  if (!boardMemberId) {
    throw new Error("can't get boardMemberId param");
  }

  return boardMemberId;
};

export const useEasopGrantIdParam = () => {
  const { easopGrantId } = useParams();
  if (!easopGrantId) {
    throw new Error("can't get easopGrantId param");
  }

  return easopGrantId;
};

export const useCtmsGrantIdParam = () => {
  const { ctmsGrantId } = useParams();
  if (!ctmsGrantId) {
    throw new Error("can't get ctmsGrantId param");
  }

  return ctmsGrantId;
};

export const useGranteeIdParam = () => {
  const { granteeId } = useParams();
  if (!granteeId) {
    throw new Error("can't get granteeId param");
  }

  return granteeId;
};

export const useVestingScheduleIdParam = () => {
  const { vestingScheduleId } = useParams();
  if (!vestingScheduleId) {
    throw new Error("can't get vestingScheduleId param");
  }

  return vestingScheduleId;
};

export const useCountryCodeParam = () => {
  const { countryCode } = useParams();
  if (!countryCode) {
    throw new Error("can't get countryCode param");
  }

  return countryCode;
};

export const useEquityTypeIdParam = () => {
  const { equityTypeId } = useParams<"equityTypeId">();

  if (!equityTypeId) {
    throw new Error("can't get equityTypeId param");
  }

  return equityTypeId;
};

export const useEquityTypeWorkRelationshipCategoryParam = () => {
  const { equityTypeWorkRelationshipCategory } =
    useParams<"equityTypeWorkRelationshipCategory">();

  if (!equityTypeWorkRelationshipCategory) {
    throw new Error("can't get equityTypeWorkRelationshipCategory param");
  }

  if (
    !stringIsEquityTypeWorkRelationshipCategory(
      equityTypeWorkRelationshipCategory,
    )
  ) {
    throw new Error("equityTypeWorkRelationshipCategory is not a valid value");
  }

  return equityTypeWorkRelationshipCategory;
};

export const useCTMSExerciseRequestCTMSIdParam = () => {
  const { ctmsExerciseRequestCTMSId } = useParams();
  if (!ctmsExerciseRequestCTMSId) {
    throw new Error("can't get ctmsExerciseRequestCTMSId param");
  }

  return ctmsExerciseRequestCTMSId;
};

export const useSafeCalculatorVersionIdParam = () => {
  const { safeCalculatorVersionId } = useParams();
  if (!safeCalculatorVersionId) {
    throw new Error("can't get safeCalculatorVersionId param");
  }

  return safeCalculatorVersionId;
};

export const GRANTEE_PORTAL_EQUITY_TYPES_GROUPS = {
  STOCK_OPTIONS: "stock-options",
  VIRTUAL_STOCK_OPTIONS: "virtual-stock-options",
} as const;
export type GranteePortalEquityTypeGroup =
  (typeof GRANTEE_PORTAL_EQUITY_TYPES_GROUPS)[keyof typeof GRANTEE_PORTAL_EQUITY_TYPES_GROUPS];

export const useEquityTypesGroupParam = () => {
  const { equityTypesGroup } = useParams();
  if (!equityTypesGroup) {
    throw new Error("can't get equityTypesGroup param");
  }

  if (
    Object.values(GRANTEE_PORTAL_EQUITY_TYPES_GROUPS).every(
      (value) => value !== equityTypesGroup,
    )
  ) {
    throw new Error("equityTypesGroup is not valid");
  }

  return equityTypesGroup as GranteePortalEquityTypeGroup;
};

const grantsTableSearchParamsSchema = z.object({
  orderBy: z.enum([
    "label",
    "grantee",
    "equityType",
    "cumulativeVested",
    "exercisePrice",
    "quantityIssued",
    "quantityExercised",
    "settled",
    "remainingDaysToExercise",
    "terminationDate",
  ]),
  orderDirection: z.enum(["ASC", "DESC"]),
});

export type GrantsTableSearchParams = z.infer<
  typeof grantsTableSearchParamsSchema
>;

export const useGrantsTableSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const params = useMemo(() => {
    const orderBy = searchParams.get("orderBy");
    const orderDirection = searchParams.get("orderDirection");
    const parsedSearchParams = grantsTableSearchParamsSchema.safeParse({
      orderBy,
      orderDirection,
    });

    if (!parsedSearchParams.success) {
      return null;
    }

    return {
      direction: parsedSearchParams.data.orderDirection,
      field: parsedSearchParams.data.orderBy,
    };
  }, [searchParams]);

  const setParams = useCallback(
    (orderByParams: typeof params) => {
      if (!orderByParams) {
        setSearchParams((params) => {
          params.delete("orderBy");
          params.delete("orderDirection");
          return params;
        });
      } else {
        setSearchParams((params) => ({
          // eslint-disable-next-line @typescript-eslint/no-misused-spread
          ...params,
          orderBy: orderByParams.field,
          orderDirection: orderByParams.direction,
        }));
      }
    },
    [setSearchParams],
  );

  return { params, setParams };
};

type CTMSGrantAmendmentRequestStatus =
  | "IMPLEMENTATION"
  | "PENDING_APPROVAL"
  | "READY_FOR_CONSENT"
  | "UNDER_REVIEW";

export const MAP_AMENDMENT_STATUS_TO_PATH: Record<
  CTMSGrantAmendmentRequestStatus,
  Extract<ApplicationRoute, `organizationCTMSGrantAmendmentRequests${string}`>
> = {
  IMPLEMENTATION: "organizationCTMSGrantAmendmentRequestsImplementation",
  PENDING_APPROVAL: "organizationCTMSGrantAmendmentRequestsPendingApproval",
  READY_FOR_CONSENT: "organizationCTMSGrantAmendmentRequestsBoardApproval",
  UNDER_REVIEW: "organizationCTMSGrantAmendmentRequestsUnderReview",
};
