import { Pill } from "@remote-com/norma";
import { graphql, useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";

import { APPLICATION_ROUTES } from "../paths";
import { RepricedToCtmsGrantTag_CtmsGrant$key } from "./__generated__/RepricedToCtmsGrantTag_CtmsGrant.graphql";

const GRANT_FRAGMENT = graphql`
  fragment RepricedToCtmsGrantTag_CtmsGrant on CTMSGrant {
    id
    label
    organizationId
  }
`;

export const RepricedToCtmsGrantTag: React.FC<{
  ctmsGrantFragment: RepricedToCtmsGrantTag_CtmsGrant$key;
}> = ({ ctmsGrantFragment }) => {
  const ctmsGrant = useFragment(GRANT_FRAGMENT, ctmsGrantFragment);

  return (
    <Pill tone="warning">
      <span>
        This grant was repriced to{" "}
        <Link
          className="font-medium text-brand-600"
          to={generatePath(APPLICATION_ROUTES.organizationEquityCtmsGrant, {
            ctmsGrantId: ctmsGrant.id,
            organizationId: ctmsGrant.organizationId,
          })}
        >
          {ctmsGrant.label}
        </Link>
      </span>
    </Pill>
  );
};
