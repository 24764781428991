import { Pill } from "@remote-com/norma";
import {
  addMonths,
  addYears,
  differenceInMonths,
  differenceInYears,
  formatDate,
  isBefore,
  isSameMonth,
  isSameYear,
} from "date-fns";
import _, { mapValues, maxBy, minBy, sumBy } from "lodash";
import { useCallback, useMemo } from "react";
import { FormattedDate, FormattedNumber } from "react-intl";
import { graphql, useFragment } from "react-relay";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Rectangle,
  RectangleProps,
  ReferenceLine,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { formatNumber } from "../helpers/formatter";
import { useGetRandomColor } from "../helpers/getRandomColorPalette";
import { useComputeSharesNetEquityValue } from "../hooks/useComputeSharesNetEquityValue";
import { useCurrencyFormatter } from "../hooks/useCurrencyFormatter";
import { VestingGraph_Organization$key } from "./__generated__/VestingGraph_Organization.graphql";
import { FormattedCurrency } from "./Formatted/FormattedCurrency";
import { RoundedBarShape, RoundedBarShapeProps } from "./ui/RoundedBarShape";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment VestingGraph_Organization on Organization {
    ...useComputeSharesNetEquityValue_Organization
    ...FormattedCurrency_Organization
    ...useCurrencyFormatter_Organization
  }
`;

type GROUP_BY = "month" | "year";

const buildEmptyVestingEventsFromDates = ({
  groupBy,
  maximumVestingEventDate,
  minimumVestingEventDate,
}: {
  groupBy: GROUP_BY;
  maximumVestingEventDate: Date | string;
  minimumVestingEventDate: Date | string;
}): { date: string }[] => {
  switch (groupBy) {
    case "month":
      return Array.from(
        {
          length:
            differenceInMonths(
              maximumVestingEventDate,
              minimumVestingEventDate,
            ) + 1,
        },
        (_, index) => ({
          date: formatDate(
            addMonths(minimumVestingEventDate, index),
            "yyyy-MM",
          ),
        }),
      );
    case "year":
      return Array.from(
        {
          length:
            differenceInYears(
              maximumVestingEventDate,
              minimumVestingEventDate,
            ) + 1,
        },
        (_, index) => ({
          date: formatDate(addYears(minimumVestingEventDate, index), "yyyy"),
        }),
      );
  }
};

const getCumulativeVestedAtDatePerGrant = ({
  date,
  grants,
  groupBy,
}: {
  date: string;
  grants: Grant[];
  groupBy: GROUP_BY;
}): Record<string, { cumulativeVested: number; grant: Grant }> => {
  switch (groupBy) {
    case "month":
      return grants.reduce((acc, grant) => {
        const vestingDataPoint = grant.vestingDataPoints.findLast(
          (dataPoint) =>
            isSameMonth(dataPoint.date, date) || isBefore(dataPoint.date, date),
        );
        return {
          ...acc,
          [grant.label]: {
            cumulativeVested: vestingDataPoint?.cumulativeVested || 0,
            grant,
          },
        };
      }, {});
    case "year":
      return grants.reduce((acc, grant) => {
        const vestingDataPoint = grant.vestingDataPoints.findLast(
          (dataPoint) =>
            isSameYear(dataPoint.date, date) || isBefore(dataPoint.date, date),
        );
        return {
          ...acc,
          [grant.label]: {
            cumulativeVested: vestingDataPoint?.cumulativeVested || 0,
            grant,
          },
        };
      }, {});
  }
};

export interface Grant {
  exercisePrice: null | number;
  label: string;
  vestingDataPoints: VestingDataPoint[];
}
export interface VestingDataPoint {
  cumulativeVested: number;
  date: Date;
}

export const VestingGraph: React.FC<{
  displayValuation: boolean;
  grants: Grant[];
  groupBy: GROUP_BY;
  organizationFragment: VestingGraph_Organization$key;
  randomColorSeed: string;
  valuationMultiple?: number;
  view: "cumulative" | "split";
}> = ({
  displayValuation,
  grants,
  groupBy,
  organizationFragment,
  randomColorSeed,
  valuationMultiple = 1,
  view,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const getRandomColor = useGetRandomColor({
    paletteSize: grants.length,
    seed: randomColorSeed,
  });

  const { computeSharesNetEquityValue } = useComputeSharesNetEquityValue({
    organizationFragment: organization,
    valuationMultiple,
  });

  const convertSharesToGraphData = useCallback(
    ({
      exercisePrice,
      shares,
    }: {
      exercisePrice: null | number;
      shares: number;
    }) => {
      if (displayValuation) {
        const value = computeSharesNetEquityValue({
          exercisePrice,
          shares,
        });

        if (value === null) {
          throw new Error(
            "Unexpected: displayValuation is true but computeSharesNetEquityValue returned null",
          );
        }

        return value;
      }

      return shares;
    },
    [computeSharesNetEquityValue, displayValuation],
  );

  const vestingEvents = useMemo(() => {
    const allVestingEventsDate = _(grants)
      .flatMap((grant) => grant.vestingDataPoints)
      .map((dataPoint) =>
        dataPoint.cumulativeVested > 0 ? dataPoint.date : null,
      )
      .compact()
      .value();
    const minimumVestingEventDate = minBy(allVestingEventsDate, (date) =>
      new Date(date).getTime(),
    );
    const maximumVestingEventDate = maxBy(allVestingEventsDate, (date) =>
      new Date(date).getTime(),
    );

    if (!minimumVestingEventDate || !maximumVestingEventDate) {
      return [];
    }

    const emptyVestingEvents = buildEmptyVestingEventsFromDates({
      groupBy,
      maximumVestingEventDate,
      minimumVestingEventDate,
    });

    return emptyVestingEvents.map(({ date }) => {
      const cumulativeVestedAtDatePerGrants = getCumulativeVestedAtDatePerGrant(
        {
          date,
          grants,
          groupBy,
        },
      );

      return {
        date,
        vested: sumBy(
          Object.values(cumulativeVestedAtDatePerGrants),
          ({ cumulativeVested, grant }) =>
            convertSharesToGraphData({
              exercisePrice: grant.exercisePrice,
              shares: cumulativeVested,
            }),
        ),
        ...mapValues(
          cumulativeVestedAtDatePerGrants,
          ({ cumulativeVested, grant }) =>
            convertSharesToGraphData({
              exercisePrice: grant.exercisePrice,
              shares: cumulativeVested,
            }),
        ),
        roundedLabel: grants.find((grant) => {
          const cumulativeVested =
            cumulativeVestedAtDatePerGrants[grant.label]?.cumulativeVested;
          return cumulativeVested && cumulativeVested > 0;
        })?.label,
      };
    });
  }, [convertSharesToGraphData, groupBy, grants]);

  const today = formatDate(new Date(), "yyyy-MM");

  const formatCurrency = useCurrencyFormatter({
    organizationFragment: organization,
  });

  return (
    <ResponsiveContainer height="100%" width="100%">
      <BarChart data={vestingEvents} margin={{ bottom: 5, top: 20 }}>
        <CartesianGrid strokeDasharray="2 4" vertical={false} />
        <XAxis
          axisLine={false}
          dataKey="date"
          padding={{ left: 70, right: 20 }}
          tick={(props) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            const date: string = props.payload.value;
            return (
              <Text
                {...props}
                className="font-regularSmall translate-y-3 fill-grey-500"
              >
                {formatDate(date, "MMM yy")}
              </Text>
            );
          }}
          tickLine={false}
        />
        <YAxis
          axisLine={false}
          mirror={true}
          tick={(props: { payload: { value: number } }) => (
            <Text
              {...props}
              className="font-regularSmall -translate-x-2 -translate-y-3 transform fill-grey-500"
            >
              {displayValuation
                ? formatCurrency(props.payload.value, {
                    compactDisplay: "short",
                    maximumSignificantDigits: 3,
                    notation: "compact",
                  })
                : formatNumber(props.payload.value)}
            </Text>
          )}
          tickLine={false}
        />

        <Tooltip
          content={({ active, payload }) => {
            if (!active || !payload?.[0]) return null;

            const data = payload[0].payload as { date: string; vested: number };

            return (
              <div className="rounded bg-black-07 px-4 py-2">
                <Typography
                  as="div"
                  className="capitalize text-white"
                  variant="Regular/Extra Small"
                >
                  <FormattedDate
                    month="long"
                    value={data.date}
                    year="numeric"
                  />
                </Typography>

                {view === "cumulative" ? (
                  <Typography
                    as="div"
                    className="mt-1 text-white"
                    variant="Regular/Caption"
                  >
                    <span className="text-purple-03">All grants:</span>
                    &nbsp;
                    <FormattedCurrency
                      maximumFractionDigits={0}
                      organizationFragment={organization}
                      value={data.vested}
                    />
                  </Typography>
                ) : (
                  payload

                    .filter((vestingDataPoints) => vestingDataPoints.value)
                    .map((vestingDataPoints, index) => (
                      <Typography
                        as="div"
                        className="mt-1 text-white"
                        key={vestingDataPoints.name}
                        variant="Regular/Caption"
                      >
                        <span
                          style={{
                            color: getRandomColor(grants.length - index - 1),
                          }}
                        >
                          {vestingDataPoints.name}:
                        </span>
                        &nbsp;
                        {displayValuation ? (
                          <FormattedCurrency
                            maximumFractionDigits={0}
                            organizationFragment={organization}
                            value={Number(vestingDataPoints.value)}
                          />
                        ) : (
                          <>
                            <FormattedNumber
                              maximumFractionDigits={0}
                              value={Number(vestingDataPoints.value)}
                            />{" "}
                            shares
                          </>
                        )}
                      </Typography>
                    ))
                    .toReversed()
                )}
              </div>
            );
          }}
          cursor={false}
        />

        {view === "cumulative" && (
          <Bar
            className="fill-purple-700"
            dataKey="vested"
            shape={(props: unknown) => (
              <RoundedBarShape {...(props as RoundedBarShapeProps)} />
            )}
          />
        )}
        {view === "split" &&
          grants.toReversed().map((grant, index) => {
            return (
              <Bar
                dataKey={grant.label}
                fill={getRandomColor(grants.length - index - 1)}
                key={grant.label}
                shape={(props: unknown) => {
                  const {
                    dataKey,
                    payload: { roundedLabel },
                  } = props as {
                    dataKey: string;
                    payload: { roundedLabel: string };
                  };

                  return roundedLabel === dataKey ? (
                    <RoundedBarShape {...(props as RoundedBarShapeProps)} />
                  ) : (
                    <Rectangle {...(props as RectangleProps)} />
                  );
                }}
                stackId="__stackId"
              />
            );
          })}

        <ReferenceLine
          isFront
          label={({ viewBox }: { viewBox: { x: number; y: number } }) => {
            return (
              <foreignObject
                className={`h-7 w-[52px]`}
                x={viewBox.x - 26}
                y={viewBox.y - 20}
              >
                <Pill tone="bayoux">Today</Pill>
              </foreignObject>
            );
          }}
          stroke="#626D7D"
          x={today}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
