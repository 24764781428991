/**
 * @generated SignedSource<<4b765a74f5740f36bad4a69d83c9bb40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExpectationPage_Organization$data = {
  readonly id: string;
  readonly " $fragmentType": "ExpectationPage_Organization";
};
export type ExpectationPage_Organization$key = {
  readonly " $data"?: ExpectationPage_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExpectationPage_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExpectationPage_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "9861759ccf078de5e0d7a94f3b874d6c";

export default node;
