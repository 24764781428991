/**
 * @generated SignedSource<<e56d13265c18a9f9fe8c5bff874a3f06>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GrantDetailsVestingScheduleSection_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EmployeeVestingGraph_Organization">;
  readonly " $fragmentType": "GrantDetailsVestingScheduleSection_Organization";
};
export type GrantDetailsVestingScheduleSection_Organization$key = {
  readonly " $data"?: GrantDetailsVestingScheduleSection_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantDetailsVestingScheduleSection_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantDetailsVestingScheduleSection_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmployeeVestingGraph_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "3dc3e256b6ff8e61b2a294c0cf3bfb0c";

export default node;
