import { startTransition } from "react";
import { useSubscribeToInvalidationState } from "react-relay";
import { generatePath, Outlet } from "react-router-dom";
import { graphql } from "relay-runtime";

import { LargeOneColumnLayout } from "../../../components/ui/Layout/LargeOneColumnLayout";
import { TabNavigation } from "../../../components/ui/TabNavigation";
import { useQuery } from "../../../hooks/useQuery";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import { PlanningLayout_Query } from "./__generated__/PlanningLayout_Query.graphql";

const QUERY = graphql`
  query PlanningLayout_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) @required(action: THROW) {
      ...LargeOneColumnLayout_Organization
      planningEntries {
        __typename
      }
      ignoredPlanningEntrySuggestions {
        __typename
      }
    }
  }
`;

const AdminPlanningLayout: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query, refreshQuery } = useQuery<PlanningLayout_Query>(QUERY, {
    organizationId,
  });

  useSubscribeToInvalidationState([organizationId], () => {
    startTransition(() => {
      refreshQuery();
    });
  });

  return (
    <LargeOneColumnLayout
      dataCy="planning"
      organizationFragment={query.organization}
      subtitle="Overview of all your planned equity grants and terminations"
      title="Equity planning"
    >
      <div className="space-y-6">
        <div>
          <TabNavigation layoutId="planning-tab-navigation">
            <TabNavigation.Link
              count={query.organization.planningEntries.length}
              end
              to={generatePath(APPLICATION_ROUTES.organizationPlanning, {
                organizationId,
              })}
            >
              Planning
            </TabNavigation.Link>
            <TabNavigation.Link
              to={generatePath(
                APPLICATION_ROUTES.organizationPlanningProjection,
                { organizationId },
              )}
            >
              Projection
            </TabNavigation.Link>
            {query.organization.ignoredPlanningEntrySuggestions.length > 0 && (
              <TabNavigation.Link
                count={
                  query.organization.ignoredPlanningEntrySuggestions.length
                }
                to={generatePath(
                  APPLICATION_ROUTES.organizationPlanningIgnored,
                  { organizationId },
                )}
              >
                Ignored
              </TabNavigation.Link>
            )}
          </TabNavigation>
        </div>
        <div>
          <Outlet />
        </div>
      </div>
    </LargeOneColumnLayout>
  );
};

export default AdminPlanningLayout;
