/**
 * @generated SignedSource<<fbe182cf7827e91429e5e565c5ecc8e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EssentialsApplicationSidebar_Viewer$data = {
  readonly isAllowedToManageOrganization: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"ApplicationSideBar_Viewer">;
  readonly " $fragmentType": "EssentialsApplicationSidebar_Viewer";
};
export type EssentialsApplicationSidebar_Viewer$key = {
  readonly " $data"?: EssentialsApplicationSidebar_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"EssentialsApplicationSidebar_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "EssentialsApplicationSidebar_Viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApplicationSideBar_Viewer"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "organizationId",
          "variableName": "organizationId"
        }
      ],
      "kind": "ScalarField",
      "name": "isAllowedToManageOrganization",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "192bcab1bff45fdf1a547ed66b0b080b";

export default node;
