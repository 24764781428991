import { format } from "date-fns";
import saveAs from "file-saver";
import { useCallback } from "react";
import { graphql } from "relay-runtime";

import { useApplicationName } from "../../hooks/useApplicationTheme";
import { useSafeMutation } from "../../hooks/useSafeMutation";
import { Button } from "../ui/Button";
import { ExportGrantsAmendmentsSection_GenerateGrantAmendmentBoardConsentExhibitURL_Mutation } from "./__generated__/ExportGrantsAmendmentsSection_GenerateGrantAmendmentBoardConsentExhibitURL_Mutation.graphql";
import { Section } from "./Section";

const MUTATION = graphql`
  mutation ExportGrantsAmendmentsSection_GenerateGrantAmendmentBoardConsentExhibitURL_Mutation(
    $ctmsGrantAmendmentRequestIds: [UUID!]!
  ) {
    generateGrantAmendmentBoardConsentExhibitURL(
      ctmsGrantAmendmentRequestIds: $ctmsGrantAmendmentRequestIds
    )
  }
`;

export function ExportGrantsAmendmentsSection({
  ctmsGrantAmendmentRequestIds,
  onPreviewBoardConsentClick,
  previewBoardConsentIsInFlight,
}: {
  ctmsGrantAmendmentRequestIds: string[];
  onPreviewBoardConsentClick: () => void;
  previewBoardConsentIsInFlight: boolean;
}) {
  const { download, downloadIsInFlight } =
    useDownloadGrantsAmendmentsBoardConsent({
      ctmsGrantAmendmentRequestIds,
    });
  const applicationName = useApplicationName();

  return (
    <Section
      index={1}
      subtitle={
        <>
          You may need the list of grant amendments to append to your own
          written board consent, or to the minutes of the board meeting during
          which the grants are approved (if you approve the grant amendments
          during a real board meeting). You can also download a preview of the
          board consent that would be generated by {applicationName}.
        </>
      }
      title="Export your list of grant amendments"
    >
      <div className="flex justify-between gap-4">
        <Button
          className="shrink-0"
          loading={downloadIsInFlight}
          onClick={download}
          size="small"
          variant="Secondary Full"
        >
          Export grant amendments
        </Button>
        <Button
          loading={previewBoardConsentIsInFlight}
          onClick={onPreviewBoardConsentClick}
          size="small"
          type="button"
          variant="Secondary Outline"
        >
          Preview board consent
        </Button>
      </div>
    </Section>
  );
}

function useDownloadGrantsAmendmentsBoardConsent({
  ctmsGrantAmendmentRequestIds,
}: {
  ctmsGrantAmendmentRequestIds: string[];
}) {
  const [
    generateGrantAmendmentBoardConsentExhibitURL,
    generateGrantAmendmentBoardConsentExhibitURLMutationIsInFlight,
  ] =
    useSafeMutation<ExportGrantsAmendmentsSection_GenerateGrantAmendmentBoardConsentExhibitURL_Mutation>(
      MUTATION,
    );

  const download = useCallback(async () => {
    const result = await generateGrantAmendmentBoardConsentExhibitURL({
      variables: {
        ctmsGrantAmendmentRequestIds,
      },
    });

    saveAs(
      result.generateGrantAmendmentBoardConsentExhibitURL,
      `grant-amendment-board-consent-${format(new Date(), "yyyy-MM-dd")}.pdf`,
    );
  }, [
    ctmsGrantAmendmentRequestIds,
    generateGrantAmendmentBoardConsentExhibitURL,
  ]);

  return {
    download,
    downloadIsInFlight:
      generateGrantAmendmentBoardConsentExhibitURLMutationIsInFlight,
  };
}
