import { IconV2DuotoneAlertTriangle } from "@remote-com/norma/icons/IconV2DuotoneAlertTriangle";
import { IconV2DuotoneBookOpen } from "@remote-com/norma/icons/IconV2DuotoneBookOpen";
import { IconV2DuotoneCalendarNote } from "@remote-com/norma/icons/IconV2DuotoneCalendarNote";
import { IconV2DuotoneClock } from "@remote-com/norma/icons/IconV2DuotoneClock";
import { IconV2DuotoneFolderCheck } from "@remote-com/norma/icons/IconV2DuotoneFolderCheck";
import { IconV2DuotoneReceipt } from "@remote-com/norma/icons/IconV2DuotoneReceipt";
import React from "react";
import { generatePath } from "react-router-dom";

import { Page } from "../../../components/Page";
import { SuperAdminBoxLink } from "../../../components/SuperAdminBoxLink";
import { CenteredColumnLayout } from "../../../components/ui/Layout/CenteredColumnLayout";
import { Typography } from "../../../components/ui/Typography";
import { APPLICATION_ROUTES } from "../../../paths";

const _LegalContent: React.FC = () => {
  return (
    <CenteredColumnLayout maxWidth="800" showFooter>
      <div className="space-y-6">
        <Typography as="div" variant="Medium/Large">
          What do you want to create
        </Typography>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <SuperAdminBoxLink
            advancedVersion
            description="Add the legal content for each country covered and multiple equity types"
            essentialsVersion
            Icon={IconV2DuotoneBookOpen}
            title="Equity handbooks"
            to={generatePath(APPLICATION_ROUTES.superAdminEquityHandbooks)}
            tone="pink"
          />
          <SuperAdminBoxLink
            advancedVersion
            description="Add the exercise tax parameters that appear in the exercise handler content"
            Icon={IconV2DuotoneReceipt}
            title="Exercise Tax parameters"
            to={generatePath(
              APPLICATION_ROUTES.superAdminExercisesTaxParameters,
            )}
            tone="brand"
          />
          <SuperAdminBoxLink
            advancedVersion
            description="Add the legal watch out that appear when a user draft a new equity grant"
            Icon={IconV2DuotoneAlertTriangle}
            title="Legal watch outs"
            to={generatePath(APPLICATION_ROUTES.superAdminLegalWatchOuts)}
            tone="orange"
          />
          <SuperAdminBoxLink
            advancedVersion
            description="Configure the documentation that needs to be approved by board members"
            Icon={IconV2DuotoneFolderCheck}
            title="Documents approval"
            to={generatePath(APPLICATION_ROUTES.superAdminDocumentsApproval)}
            tone="green"
          />
          <SuperAdminBoxLink
            advancedVersion
            description="Add the ongoing obligations by due date per instruments"
            Icon={IconV2DuotoneCalendarNote}
            title="Ongoing obligation"
            to={generatePath(APPLICATION_ROUTES.superAdminOngoingObligations)}
            tone="purple"
          />
          <SuperAdminBoxLink
            advancedVersion
            description="Define which instrument has a beneficial early exercise"
            Icon={IconV2DuotoneClock}
            title="Early exercise rules"
            to={generatePath(
              APPLICATION_ROUTES.superAdminEarlyExerciseBeneficial,
            )}
            tone="pink"
          />
        </div>
      </div>
    </CenteredColumnLayout>
  );
};

const LegalContent: React.FC = () => {
  return (
    <Page
      analyticsName="Super Admin - Legal content"
      title={`Super admin | Legal content`}
    >
      <_LegalContent />
    </Page>
  );
};

export default LegalContent;
