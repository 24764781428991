import { graphql, useFragment } from "react-relay";

import { AsideSection_EssentialsOverview_Organization$key } from "./__generated__/AsideSection_EssentialsOverview_Organization.graphql";
import { EquityPlanParametersCard } from "./EquityPlanParametersCard";
import { EquityRepartitionCard } from "./EquityRepartitionCard";
import { RemindersCard } from "./RemindersCard";

const ORGANIZATION_FRAGMENT = graphql`
  fragment AsideSection_EssentialsOverview_Organization on Organization {
    ...EquityPlanParametersCard_EssentialsOverview_Organization
    ...EquityRepartitionCard_EssentialsOverview_Organization
  }
`;

export const AsideSection: React.FC<{
  organizationFragment: AsideSection_EssentialsOverview_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <aside className="space-y-6">
      <RemindersCard />
      <EquityPlanParametersCard organizationFragment={organization} />
      <EquityRepartitionCard organizationFragment={organization} />
    </aside>
  );
};
