import { isEmpty, isNil } from "lodash";
import React from "react";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { FormattedCurrency } from "../../../components/Formatted/FormattedCurrency";
import { FormattedPercentage } from "../../../components/Formatted/FormattedPercentage";
import { FormattedFMV } from "../../../components/FormattedFMV";
import { LongDate } from "../../../components/LongDate";
import { MissingInformationTag } from "../../../components/MissingInformationTag";
import { RoundedDataRow } from "../../../components/RoundedDataRow";
import { SpreadExplanationSlideOver } from "../../../components/SpreadExplanationSlideOver";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { Typography } from "../../../components/ui/Typography";
import { useApplicationSupportEmailAddress } from "../../../hooks/useApplicationTheme";
import { useBoolean } from "../../../hooks/useBoolean";
import { getEquityTypeWorkRelationship } from "../../../services/workRelationship";
import {
  TaxDetailsContent_CTMSGrant$data,
  TaxDetailsContent_CTMSGrant$key,
} from "./__generated__/TaxDetailsContent_CTMSGrant.graphql";
import { TaxDetailsContent_ExerciseParameters$key } from "./__generated__/TaxDetailsContent_ExerciseParameters.graphql";
import { TaxDetailsContent_FairMarketValueAtExercise$key } from "./__generated__/TaxDetailsContent_FairMarketValueAtExercise.graphql";
import {
  TaxDetailsContent_Organization$data,
  TaxDetailsContent_Organization$key,
} from "./__generated__/TaxDetailsContent_Organization.graphql";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment TaxDetailsContent_CTMSGrant on CTMSGrant {
    grantee {
      name
      workRelationship
      taxResidenceCountry {
        name
        emoji
      }
    }
    matchingInstrument {
      equityType {
        exerciseParameters {
          # eslint-disable-next-line relay/unused-fields
          directEmployee {
            ...TaxDetailsContent_ExerciseParameters
          }
          # eslint-disable-next-line relay/unused-fields
          eoREmployee {
            ...TaxDetailsContent_ExerciseParameters
          }
          # eslint-disable-next-line relay/unused-fields
          contractor {
            ...TaxDetailsContent_ExerciseParameters
          }
        }
      }
    }
  }
`;

const EXERCISE_PARAMETERS_FRAGMENT = graphql`
  fragment TaxDetailsContent_ExerciseParameters on EquityTypeExerciseParametersPerWorkRelationship {
    taxRanges {
      description
      percentage
    }
    benefits {
      content
    }
    watchout
    ctmsTaxRateInPercent
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment TaxDetailsContent_Organization on Organization {
    name
    ...SpreadExplanationSlideOver_Organization
    ...FormattedCurrency_Organization
    ...FormattedFMV_Organization
  }
`;

const FAIR_MARKET_VALUE_AT_EXERCISE_FRAGMENT = graphql`
  fragment TaxDetailsContent_FairMarketValueAtExercise on FairMarketValue {
    value
    date
    isLatest
  }
`;

const useExerciseParameters = ({
  ctmsGrant,
}: {
  ctmsGrant: TaxDetailsContent_CTMSGrant$data;
}) => {
  const workRelationship = ctmsGrant.grantee.workRelationship;
  const equityTypeWorkRelationship = workRelationship
    ? getEquityTypeWorkRelationship(workRelationship).slug
    : null;
  const equityType = ctmsGrant.matchingInstrument?.equityType;
  const exerciseParametersFragment =
    equityTypeWorkRelationship && equityType
      ? equityType.exerciseParameters[equityTypeWorkRelationship]
      : null;

  return useFragment<TaxDetailsContent_ExerciseParameters$key>(
    EXERCISE_PARAMETERS_FRAGMENT,
    exerciseParametersFragment,
  );
};

const SeePortalTaxationBlock: React.FC<{
  label?: React.ReactNode;
  organization: TaxDetailsContent_Organization$data;
}> = ({ label, organization }) => {
  const helpAndSupportSubject = encodeURIComponent(
    `[${organization.name}] Advice from a tax advisor`,
  );
  const supportEmailAddress = useApplicationSupportEmailAddress();

  return (
    <RoundedDataRow
      background="orange"
      childrenClassName="flex-1"
      label={
        label || (
          <div className="space-y-1">
            <div>Estimated taxes due on spread</div>
            <Typography
              as="div"
              className="text-black-05"
              variant="Regular/Caption"
            >
              Tax % on spread
            </Typography>
          </div>
        )
      }
    >
      <Typography className="text-black-05" variant="Regular/Caption">
        Consult your grantee portal to learn more about the amount to be
        declared for tax calculation purposes. If you want to receive advice
        from a tax advisor,{" "}
        <a
          href={`mailto:${supportEmailAddress}?subject=${helpAndSupportSubject}`}
        >
          <Typography className="text-primary" variant="Medium/Caption">
            contact us
          </Typography>
        </a>{" "}
        and we&apos;ll be happy to help!
      </Typography>
    </RoundedDataRow>
  );
};

const Disclaimer: React.FC = () => {
  return (
    <RoundedBox className="space-y-4 !bg-gray-02 p-2">
      <Typography as="div" className="text-black-05" variant="Regular/Caption">
        <strong>Disclaimer:</strong> This is not a tax calculator. The estimated
        taxes shown here are indicative only, and may vary according to other
        parameters such as grantee&apos;s personal income and civil status,
        application of local taxes, exceptional surcharges and/or social
        security charges, application of tax reductions and deductions, etc.
      </Typography>
    </RoundedBox>
  );
};

const TaxDetailsContent_: React.FC<{
  ctmsGrantFragment: TaxDetailsContent_CTMSGrant$key;
  exercisePrice: number;
  fairMarketValueAtExerciseFragment: null | TaxDetailsContent_FairMarketValueAtExercise$key;
  hideHowIsTheSpreadCalculated?: boolean;
  organizationFragment: TaxDetailsContent_Organization$key;
  quantityExercised: number;
  spread: null | number;
  taxResidenceCountryIsKnown: boolean;
  totalExercisePrice: number;
}> = ({
  ctmsGrantFragment,
  exercisePrice,
  fairMarketValueAtExerciseFragment,
  hideHowIsTheSpreadCalculated,
  organizationFragment,
  quantityExercised,
  spread,
  taxResidenceCountryIsKnown,
  totalExercisePrice,
}) => {
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const { grantee } = ctmsGrant;

  const exerciseParameters = useExerciseParameters({
    ctmsGrant,
  });

  const fairMarketValueAtExercise = useFragment(
    FAIR_MARKET_VALUE_AT_EXERCISE_FRAGMENT,
    fairMarketValueAtExerciseFragment,
  );

  const {
    setFalse: hideSpreadExplanation,
    setTrue: showSpreadExplanation,
    value: isSpreadExplanationShown,
  } = useBoolean(false);

  return (
    <>
      <SpreadExplanationSlideOver
        onClose={hideSpreadExplanation}
        organizationFragment={organization}
        show={isSpreadExplanationShown}
      />
      <div className="space-y-2">
        <RoundedDataRow label="Quantity exercised">
          <FormattedNumber value={quantityExercised} /> shares
        </RoundedDataRow>
        <RoundedDataRow label="Exercise price (one share)">
          <FormattedFMV
            organizationFragment={organization}
            value={exercisePrice}
          />
        </RoundedDataRow>
        <RoundedDataRow label="Exercise price (all exercised shares)">
          <FormattedCurrency
            organizationFragment={organization}
            value={totalExercisePrice}
          />
        </RoundedDataRow>
        {fairMarketValueAtExercise && (
          <RoundedDataRow
            label={
              <div className="space-y-1">
                <div>Fair Market Value at exercise (one share)</div>
                <Typography
                  as="div"
                  className="text-gray-09"
                  variant="Regular/Caption"
                >
                  {fairMarketValueAtExercise.isLatest
                    ? "Latest 409A valuation: "
                    : "409A valuation: "}
                  <LongDate value={fairMarketValueAtExercise.date} />
                </Typography>
              </div>
            }
          >
            <FormattedCurrency
              organizationFragment={organization}
              value={fairMarketValueAtExercise.value}
            />
          </RoundedDataRow>
        )}
        {fairMarketValueAtExercise && (
          <RoundedDataRow
            label={
              <div className="space-y-1">
                <div>Fair Market Value at exercise (all exercised shares)</div>
                <Typography
                  as="div"
                  className="text-black-05"
                  variant="Regular/Caption"
                >
                  <FormattedCurrency
                    organizationFragment={organization}
                    value={fairMarketValueAtExercise.value}
                  />{" "}
                  * <FormattedNumber value={quantityExercised} /> shares
                </Typography>
              </div>
            }
          >
            <FormattedCurrency
              organizationFragment={organization}
              value={fairMarketValueAtExercise.value * quantityExercised}
            />
          </RoundedDataRow>
        )}
        {fairMarketValueAtExercise && spread !== null && (
          <RoundedDataRow
            label={
              <div className="space-y-1">
                <div>Spread (Bargain element)</div>
                <Typography
                  as="div"
                  className="text-black-05"
                  variant="Regular/Caption"
                >
                  FMV (all exercised shares) - Exercise price (all exercised
                  shares)
                </Typography>
                <Typography
                  as="div"
                  className="text-black-05"
                  variant="Regular/Caption"
                >
                  <FormattedCurrency
                    organizationFragment={organization}
                    value={fairMarketValueAtExercise.value * quantityExercised}
                  />{" "}
                  -{" "}
                  <FormattedCurrency
                    organizationFragment={organization}
                    value={totalExercisePrice}
                  />
                </Typography>
                {!hideHowIsTheSpreadCalculated && (
                  <button onClick={showSpreadExplanation} type="button">
                    <Typography
                      className="text-primary"
                      variant="Medium/Extra Small"
                    >
                      How is the spread calculated?
                    </Typography>
                  </button>
                )}
              </div>
            }
          >
            <FormattedCurrency
              organizationFragment={organization}
              value={spread}
            />
          </RoundedDataRow>
        )}

        {!grantee.taxResidenceCountry ||
        !grantee.workRelationship ? null : !taxResidenceCountryIsKnown ? (
          <SeePortalTaxationBlock organization={organization} />
        ) : (
          <>
            {exerciseParameters?.taxRanges &&
            !isEmpty(exerciseParameters.taxRanges) ? (
              <RoundedDataRow
                bottomContent={<Disclaimer />}
                label={
                  <div className="space-y-1">
                    <div>
                      Estimated taxes due on spread in{" "}
                      <strong>
                        {grantee.taxResidenceCountry.emoji}{" "}
                        {grantee.taxResidenceCountry.name}
                      </strong>
                    </div>
                    <Typography
                      as="div"
                      className="text-black-05"
                      variant="Regular/Caption"
                    >
                      Tax % on spread
                    </Typography>
                  </div>
                }
              >
                <div className="space-y-2">
                  {exerciseParameters.taxRanges.map((taxRange, index) => (
                    <div className="space-y-1" key={`taxRange-${index}`}>
                      <Typography
                        as="div"
                        className="text-black-05"
                        variant="Regular/Caption"
                      >
                        {taxRange.description
                          ? `${taxRange.description} - ${taxRange.percentage}%`
                          : `${taxRange.percentage}%`}
                      </Typography>
                      <div>
                        {spread !== null ? (
                          <FormattedCurrency
                            organizationFragment={organization}
                            value={(spread * taxRange.percentage) / 100}
                          />
                        ) : (
                          <MissingInformationTag />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </RoundedDataRow>
            ) : (
              <SeePortalTaxationBlock organization={organization} />
            )}
            {exerciseParameters?.benefits &&
              !isEmpty(exerciseParameters.benefits) && (
                <RoundedDataRow
                  background="green"
                  label={
                    <>
                      Potential tax benefits{" "}
                      <strong>
                        {grantee.taxResidenceCountry.emoji}{" "}
                        {grantee.taxResidenceCountry.name}
                      </strong>
                    </>
                  }
                >
                  <div className="space-y-2">
                    {exerciseParameters.benefits.map((benefit, index) => (
                      <div
                        className="text-left"
                        key={`${index}${benefit.content}`}
                      >
                        <Typography
                          as="div"
                          className="text-black-05"
                          variant="Medium/Caption"
                        >
                          Benefit {index + 1}
                        </Typography>
                        <Typography
                          as="div"
                          className="whitespace-break-spaces"
                          variant="Regular/Caption"
                        >
                          {benefit.content}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </RoundedDataRow>
              )}
            {exerciseParameters?.watchout && (
              <RoundedDataRow
                background="orange"
                label={
                  <div className="flex gap-2">
                    <span className="font-emoji">⚠️</span>
                    <span>Potential watchout</span>
                  </div>
                }
              >
                <Typography
                  as="div"
                  className="whitespace-break-spaces text-left text-black-05"
                  variant="Regular/Caption"
                >
                  {exerciseParameters.watchout}
                </Typography>
              </RoundedDataRow>
            )}
          </>
        )}
      </div>
    </>
  );
};

const _ForCtmsApproval: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <RoundedDataRow
      bottomContent={<Disclaimer />}
      label={
        <Typography variant="Regular/Extra Small">
          Estimated taxes to input
          <br />
          <span className="text-black-05">
            (<span className="font-emoji">⚠️</span> based on marginal tax rate)
          </span>
          <br />
          Spread * marginal tax rate
        </Typography>
      }
    >
      {children}
    </RoundedDataRow>
  );
};

const ForDirectEmployeeCtmsApproval: React.FC<{
  ctmsGrantFragment: TaxDetailsContent_CTMSGrant$key;
  organizationFragment: TaxDetailsContent_Organization$key;
  spread: null | number;
  taxResidenceCountryIsKnown: boolean;
}> = ({
  ctmsGrantFragment,
  organizationFragment,
  spread,
  taxResidenceCountryIsKnown,
}) => {
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const { grantee } = ctmsGrant;

  const exerciseParameters = useExerciseParameters({
    ctmsGrant,
  });

  const ctmsTaxRateInPercent = exerciseParameters?.ctmsTaxRateInPercent;

  if (
    !grantee.taxResidenceCountry ||
    !grantee.workRelationship ||
    !taxResidenceCountryIsKnown ||
    isNil(ctmsTaxRateInPercent)
  ) {
    return (
      <SeePortalTaxationBlock
        label="Estimated taxes to input"
        organization={organization}
      />
    );
  }

  if (ctmsTaxRateInPercent === 0) {
    return <_ForCtmsApproval>0%</_ForCtmsApproval>;
  }

  return (
    <_ForCtmsApproval>
      {spread !== null ? (
        <div className="space-y-1">
          <Typography as="div" variant="Regular/Extra Small">
            <FormattedCurrency
              organizationFragment={organization}
              value={spread}
            />{" "}
            * <FormattedPercentage value={ctmsTaxRateInPercent / 100} />
          </Typography>
          <div>
            <FormattedCurrency
              organizationFragment={organization}
              value={(spread * ctmsTaxRateInPercent) / 100}
            />
          </div>
        </div>
      ) : (
        <MissingInformationTag />
      )}
    </_ForCtmsApproval>
  );
};

const ForNonDirectEmployeeCtmsApproval: React.FC = () => {
  return <_ForCtmsApproval>0%</_ForCtmsApproval>;
};

export const TaxDetailsContent = Object.assign(TaxDetailsContent_, {
  ForDirectEmployeeCtmsApproval,
  ForNonDirectEmployeeCtmsApproval,
});
