/**
 * @generated SignedSource<<c7c856650b139d6abff5d6a032957047>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type Overview_Admin_Query$variables = {
  organizationId: string;
};
export type Overview_Admin_Query$data = {
  readonly organization: {
    readonly isRemoteEquityEssentials: boolean;
  } | null;
};
export type Overview_Admin_Query = {
  response: Overview_Admin_Query$data;
  variables: Overview_Admin_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isRemoteEquityEssentials",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Overview_Admin_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Overview_Admin_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f504751a60ba86c2e8bfe7d2795b98c5",
    "id": null,
    "metadata": {},
    "name": "Overview_Admin_Query",
    "operationKind": "query",
    "text": "query Overview_Admin_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    isRemoteEquityEssentials\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "bbd3583d23ce3a91073a651f3780e1b3";

export default node;
