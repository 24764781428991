import { Button, Pill } from "@remote-com/norma";
import { graphql, useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";

import { CtmsGrantStatusTag } from "../../../../components/CtmsGrantStatusTag";
import { FormattedNumber } from "../../../../components/Formatted/FormattedNumber";
import { FormattedPercentage } from "../../../../components/Formatted/FormattedPercentage";
import { NewEquitySelectionModalRemote } from "../../../../components/NewEquitySelectionModal/NewEquitySelectionModal";
import { Progress } from "../../../../components/ui/Progress";
import { Table } from "../../../../components/ui/Table";
import { useOrganizationSharesUtil } from "../../../../hooks/useOrganizationSharesUtil";
import { APPLICATION_ROUTES } from "../../../../paths";
import {
  EquityDetailsSection_CTMSGrants$data,
  EquityDetailsSection_CTMSGrants$key,
} from "./__generated__/EquityDetailsSection_CTMSGrants.graphql";
import {
  EquityDetailsSection_DraftGrants$data,
  EquityDetailsSection_DraftGrants$key,
} from "./__generated__/EquityDetailsSection_DraftGrants.graphql";
import {
  EquityDetailsSection_Employee$data,
  EquityDetailsSection_Employee$key,
} from "./__generated__/EquityDetailsSection_Employee.graphql";
import {
  EquityDetailsSection_Organization$data,
  EquityDetailsSection_Organization$key,
} from "./__generated__/EquityDetailsSection_Organization.graphql";
import { EmptyStatePlaceholder } from "./EmptyStatePlaceholder";
import { Section } from "./Section";

const CTMS_GRANTS_FRAGMENT = graphql`
  fragment EquityDetailsSection_CTMSGrants on CTMSGrant @relay(plural: true) {
    id
    label
    quantityIssued
    cumulativeVested
    ...CtmsGrantStatusTag_CtmsGrant
  }
`;

const DRAFT_GRANTS_FRAGMENT = graphql`
  fragment EquityDetailsSection_DraftGrants on EasopGrant @relay(plural: true) {
    id
    label
    quantityGranted
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment EquityDetailsSection_Organization on Organization {
    id
    isRemoteEquityEssentials
    ...useOrganizationSharesUtil_Organization
  }
`;

const EMPLOYEE_FRAGMENT = graphql`
  fragment EquityDetailsSection_Employee on Grantee
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    id
    ...NewEquitySelectionModal_DefaultGrantee
      @arguments(organizationId: $organizationId)
  }
`;

export function EquityDetailsSection({
  ctmsGrantsFragment,
  draftGrantsFragment,
  employeeFragment,
  organizationFragment,
}: {
  ctmsGrantsFragment: EquityDetailsSection_CTMSGrants$key;
  draftGrantsFragment: EquityDetailsSection_DraftGrants$key;
  employeeFragment: EquityDetailsSection_Employee$key;
  organizationFragment: EquityDetailsSection_Organization$key;
}) {
  const ctmsGrants = useFragment(CTMS_GRANTS_FRAGMENT, ctmsGrantsFragment);
  const draftGrants = useFragment(DRAFT_GRANTS_FRAGMENT, draftGrantsFragment);
  const employee = useFragment(EMPLOYEE_FRAGMENT, employeeFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <Section title="Equity details">
      <EquityDetailsSectionContent
        ctmsGrants={ctmsGrants}
        draftGrants={draftGrants}
        employee={employee}
        organization={organization}
      />
    </Section>
  );
}

function CTMSGrantRow({
  ctmsGrant,
  organization,
}: {
  ctmsGrant: EquityDetailsSection_CTMSGrants$data[number];
  organization: EquityDetailsSection_Organization$data;
}) {
  return (
    <GrantRow
      label={ctmsGrant.label}
      linkTarget={generatePath(APPLICATION_ROUTES.organizationEquityCtmsGrant, {
        ctmsGrantId: ctmsGrant.id,
        organizationId: organization.id,
      })}
      organization={organization}
      quantityIssued={ctmsGrant.quantityIssued}
      quantityVested={ctmsGrant.cumulativeVested}
      status={<CtmsGrantStatusTag ctmsGrantFragment={ctmsGrant} />}
    />
  );
}

function DraftGrantRow({
  draftGrant,
  organization,
}: {
  draftGrant: EquityDetailsSection_DraftGrants$data[number];
  organization: EquityDetailsSection_Organization$data;
}) {
  return (
    <GrantRow
      label={draftGrant.label}
      linkTarget={generatePath(
        APPLICATION_ROUTES.organizationPrepareYourGrantsDetails,
        {
          easopGrantId: draftGrant.id,
          organizationId: organization.id,
        },
      )}
      organization={organization}
      quantityIssued={draftGrant.quantityGranted}
      status={<Pill tone="bayoux">Draft</Pill>}
    />
  );
}

function EquityDetailsSectionContent({
  ctmsGrants,
  draftGrants,
  employee,
  organization,
}: {
  ctmsGrants: EquityDetailsSection_CTMSGrants$data;
  draftGrants: EquityDetailsSection_DraftGrants$data;
  employee: EquityDetailsSection_Employee$data;
  organization: EquityDetailsSection_Organization$data;
}) {
  if (ctmsGrants.length === 0 && draftGrants.length === 0) {
    return (
      <NoEquityGrantedPlaceholder
        employee={employee}
        organization={organization}
      />
    );
  }

  return (
    <Table.Containerized>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Label</Table.HeaderCell>
          <Table.HeaderCell>Granted</Table.HeaderCell>
          <Table.HeaderCell>Vested</Table.HeaderCell>
          <Table.HeaderCell alignRight>Status</Table.HeaderCell>
          <Table.HeaderCell alignRight>Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {draftGrants.map((draftGrant) => (
          <DraftGrantRow
            draftGrant={draftGrant}
            key={draftGrant.id}
            organization={organization}
          />
        ))}
        {ctmsGrants.map((ctmsGrant) => (
          <CTMSGrantRow
            ctmsGrant={ctmsGrant}
            key={ctmsGrant.id}
            organization={organization}
          />
        ))}
      </Table.Body>
    </Table.Containerized>
  );
}

function GrantRow({
  label,
  linkTarget,
  organization,
  quantityIssued,
  quantityVested,
  status,
}: {
  label: string;
  linkTarget: string;
  organization: EquityDetailsSection_Organization$data;
  quantityIssued: number;
  quantityVested?: number;
  status: React.ReactNode;
}) {
  const { sharesToFullyDilutedRatio } = useOrganizationSharesUtil({
    organizationFragment: organization,
  });
  const ownership = sharesToFullyDilutedRatio(quantityIssued);

  return (
    <Table.Row>
      <Table.Cell>
        <Pill tone="bayoux">{label}</Pill>
      </Table.Cell>
      <Table.Cell>
        <div className="space-y-1">
          <div className="text-SM">
            <FormattedNumber animated value={quantityIssued} /> shares
          </div>
          <div className="text-XS text-grey-500">
            {ownership ? (
              <FormattedPercentage animated value={ownership} />
            ) : (
              "－"
            )}
          </div>
        </div>
      </Table.Cell>
      <Table.Cell>
        {typeof quantityVested === "number" ? (
          <div className="space-y-2">
            <div className="text-SM">
              <FormattedNumber animated value={quantityVested} /> /{" "}
              <span className="text-grey-500">
                <FormattedNumber animated value={quantityIssued} />
              </span>
            </div>
            <Progress className="bg-grey-200" max={quantityIssued}>
              <Progress.Value className="bg-primary" value={quantityVested} />
            </Progress>
          </div>
        ) : (
          "－"
        )}
      </Table.Cell>
      <Table.Cell className="text-right">{status}</Table.Cell>
      <Table.Cell className="text-right">
        <Link to={linkTarget}>
          <Button size="sm" variant="outline">
            View details
          </Button>
        </Link>
      </Table.Cell>
    </Table.Row>
  );
}

function NoEquityGrantedPlaceholder({
  employee,
  organization,
}: {
  employee: EquityDetailsSection_Employee$data;
  organization: EquityDetailsSection_Organization$data;
}) {
  switch (organization.isRemoteEquityEssentials) {
    case false:
      return (
        <EmptyStatePlaceholder textCenter>
          No equity has been granted yet.{" "}
          <NewEquitySelectionModalRemote.Controller
            render={({ open }) => (
              <button
                onClick={() => {
                  open({
                    data: {
                      employeeFragment: employee,
                      organizationId: organization.id,
                    },
                  });
                }}
              >
                Issue a new grant
              </button>
            )}
          />
        </EmptyStatePlaceholder>
      );
    case true:
      return (
        <>
          <EmptyStatePlaceholder textCenter>
            No equity has been declared yet.{" "}
            <Link
              className="inline-block"
              to={generatePath(
                APPLICATION_ROUTES.organizationDeclareNewGrantForGrantee,
                {
                  organizationId: organization.id,
                  selectedGranteeId: employee.id,
                },
              )}
            >
              Declare a new grant
            </Link>
          </EmptyStatePlaceholder>
        </>
      );
  }
}
