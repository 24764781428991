/**
 * @generated SignedSource<<68de269fa1d45d6d0e3d44b1053e579c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FormattedCurrency_Organization$data = {
  readonly defaultCurrencyCode: string;
  readonly " $fragmentType": "FormattedCurrency_Organization";
};
export type FormattedCurrency_Organization$key = {
  readonly " $data"?: FormattedCurrency_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"FormattedCurrency_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FormattedCurrency_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultCurrencyCode",
      "storageKey": null
    }
  ],
  "type": "IOrganization",
  "abstractKey": "__isIOrganization"
};

(node as any).hash = "921d9eab2ee5133b375b728cd826c33a";

export default node;
