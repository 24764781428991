import { useCallback, useMemo, useState } from "react";
import {
  useFragment,
  usePaginationFragment,
  useSubscription,
} from "react-relay";
import { useSearchParams } from "react-router-dom";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { EmptyState } from "../../../components/EmptyState";
import { Page } from "../../../components/Page";
import { useQuery } from "../../../hooks/useQuery";
import { useOrganizationIdParam } from "../../../paths";
import CONFIGURATION from "../../../services/configuration";
import NotFoundPage from "../../NotFound/NotFound";
import {
  Grantees_GranteesPagination_Query,
  OrganizationGranteesFilters,
} from "./__generated__/Grantees_GranteesPagination_Query.graphql";
import { Grantees_Organization$key } from "./__generated__/Grantees_Organization.graphql";
import {
  Grantees_Query,
  GranteeStatus,
} from "./__generated__/Grantees_Query.graphql";
import { Grantees_Refetchable_Organization$key } from "./__generated__/Grantees_Refetchable_Organization.graphql";
import { Grantees_Subscription } from "./__generated__/Grantees_Subscription.graphql";
import { Grantees_Viewer$key } from "./__generated__/Grantees_Viewer.graphql";
import { OrganizationGranteesTable } from "./GranteesTable/OrganizationGranteesTable";

const VIEWER_FRAGMENT = graphql`
  fragment Grantees_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    ...OrganizationGranteesTable_Viewer
      @arguments(organizationId: $organizationId)
  }
`;

export const ORGANIZATION_FRAGMENT = graphql`
  fragment Grantees_Organization on Organization
  @argumentDefinitions(
    organizationId: { type: "OrganizationId!" }
    filters: { type: "OrganizationGranteesFilters!" }
  ) {
    id
    name
    ...OrganizationGranteesTable_Organization
    ...Grantees_Refetchable_Organization
      @arguments(organizationId: $organizationId, filters: $filters)
  }
`;

const ORGANIZATION_REFETCHABLE_FRAGMENT = graphql`
  fragment Grantees_Refetchable_Organization on Organization
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 20 }
    filters: { type: "OrganizationGranteesFilters!" }
    organizationId: { type: "OrganizationId!" }
  )
  @refetchable(queryName: "Grantees_GranteesPagination_Query") {
    paginatedGrantees: grantees(
      first: $count
      after: $cursor
      filters: $filters
    ) @connection(key: "Grantees_Organization_paginatedGrantees") {
      __id
      totalCount
      edges {
        node {
          id
          ...OrganizationGranteesTable_Grantees
            @arguments(organizationId: $organizationId)
        }
      }
    }
  }
`;

const SUBSCRIPTION = graphql`
  subscription Grantees_Subscription(
    $id: OrganizationId!
    $filters: OrganizationGranteesFilters!
  ) {
    organizationEmployeesUpdates(id: $id) {
      ...Grantees_Organization
        @arguments(organizationId: $id, filters: $filters)
    }
  }
`;

const REMOTE_DASHBOARD_EQUITY_URL = CONFIGURATION.REMOTE_DASHBOARD_EQUITY_URL;
if (!REMOTE_DASHBOARD_EQUITY_URL) {
  throw new Error("REMOTE_DASHBOARD_EQUITY_URL is required");
}

const TableEmptyState: React.FC<{
  status: GranteeStatus | null;
}> = ({ status }) => {
  if (!status)
    return <EmptyState>There are no employees to display.</EmptyState>;

  switch (status) {
    case "Active":
      return <EmptyState>There are no active employees to display.</EmptyState>;
    case "Terminated":
      return (
        <EmptyState>There are no terminated employees to display.</EmptyState>
      );
  }
};

const AdminGranteesPage_: React.FC<{
  filters: OrganizationGranteesFilters;
  organizationFragment: Grantees_Organization$key;
  setFilters: (filters: OrganizationGranteesFilters) => void;
  viewerFragment: Grantees_Viewer$key;
}> = ({ filters, organizationFragment, setFilters, viewerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const {
    data: { paginatedGrantees: grantees },
    hasNext,
    isLoadingNext,
    loadNext,
    refetch,
  } = usePaginationFragment<
    Grantees_GranteesPagination_Query,
    Grantees_Refetchable_Organization$key
  >(ORGANIZATION_REFETCHABLE_FRAGMENT, organization);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  useSubscription<Grantees_Subscription>({
    subscription: SUBSCRIPTION,
    variables: {
      filters,
      id: organization.id,
    },
  });

  const handleFiltersChange = (filters: OrganizationGranteesFilters) => {
    setFilters(filters);
    refetch({ filters });
  };

  const selectedIndex = filters.status === "Active" ? 0 : 1;

  const pageAttributes = useMemo(() => {
    switch (selectedIndex) {
      case 0:
        return {
          analyticsName: "Admin - Active Grantees",
          title: `Admin | ${organization.name} active grantees`,
        };
      case 1:
        return {
          analyticsName: "Admin - Terminated Grantees",
          title: `Admin | ${organization.name} terminated grantees`,
        };
    }
  }, [organization.name, selectedIndex]);

  const handleLoadNext = () => {
    loadNext(20);
  };

  return (
    <>
      <Page
        analyticsName={pageAttributes.analyticsName}
        organizationId={organization.id}
        title={pageAttributes.title}
      >
        <OrganizationGranteesTable
          filters={filters}
          granteesFragment={grantees.edges.map((edge) => edge.node)}
          granteesRelayConnectionIds={[grantees.__id]}
          hasNext={hasNext}
          isLoadingNext={isLoadingNext}
          loadNext={handleLoadNext}
          onFiltersChange={handleFiltersChange}
          organizationFragment={organization}
          renderEmptyState={() => (
            <TableEmptyState status={filters.status ?? null} />
          )}
          totalCount={grantees.totalCount}
          viewerFragment={viewer}
        />
      </Page>
    </>
  );
};

const QUERY = graphql`
  query Grantees_Query(
    $organizationId: OrganizationId!
    $filters: OrganizationGranteesFilters!
  ) {
    organization(id: $organizationId) {
      ...Grantees_Organization
        @arguments(organizationId: $organizationId, filters: $filters)
    }
    me {
      ...Grantees_Viewer @arguments(organizationId: $organizationId)
    }
  }
`;

const filtersSchema = z.object({
  countryCode: z.string().nullish(),
  equityGridLevelId: z.string().nullish(),
  hRISProvider: z.enum(["DEEL", "REMOTE"]).nullish(),
  maximumTotalOwnershipValue: z.number().nullish(),
  minimumTotalOwnershipValue: z.number().nullish(),
  orderBy: z
    .object({
      direction: z.enum(["ASC", "DESC"]),
      field: z.enum([
        "equityGridLevel",
        "grantsCount",
        "jobTitle",
        "name",
        "portalAccess",
        "quantityExercisedRatio",
        "quantityGranted",
        "quantityVestedRatio",
        "settled",
      ]),
    })
    .nullish(),
  portalAccess: z.boolean().nullish(),
  portalInvitationSuggested: z.boolean().nullish(),
  search: z.string().nullish(),
  status: z.enum(["Active", "Terminated"]).nullish(),
  workRelationship: z
    .enum([
      "Advisor",
      "ContractorManagementCompany",
      "ContractorNaturalPerson",
      "DirectEmployee",
      "EoREmployee",
    ])
    .nullish(),
});

const AdminGranteesPage: React.FC<{ granteeStatus: GranteeStatus }> = ({
  granteeStatus,
}) => {
  const organizationId = useOrganizationIdParam();

  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, _setFilters] = useState<OrganizationGranteesFilters>(() => {
    const filters = searchParams.get("filters");

    if (filters) {
      const parsingResult = filtersSchema.safeParse(
        JSON.parse(decodeURI(atob(filters))),
      );
      if (parsingResult.success) {
        return parsingResult.data;
      }
    }

    return {};
  });

  const filtersWithStatus = useMemo(() => {
    return {
      ...filters,
      status: granteeStatus,
    };
  }, [filters, granteeStatus]);

  const setFilters = useCallback(
    (filters: OrganizationGranteesFilters) => {
      setSearchParams((params) => ({
        // eslint-disable-next-line @typescript-eslint/no-misused-spread
        ...params,
        filters: btoa(encodeURI(JSON.stringify(filters))),
      }));
      _setFilters(filters);
    },
    [setSearchParams],
  );

  const { query } = useQuery<Grantees_Query>(QUERY, {
    filters: filtersWithStatus,
    organizationId,
  });

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <AdminGranteesPage_
      filters={filtersWithStatus}
      organizationFragment={query.organization}
      setFilters={setFilters}
      viewerFragment={query.me}
    />
  );
};

export default AdminGranteesPage;
