import { DropdownMenu } from "@remote-com/norma";
import { IconV2DuotoneExternalLink } from "@remote-com/norma/icons/IconV2DuotoneExternalLink";
import { IconV2OutlineChevronDown } from "@remote-com/norma/icons/IconV2OutlineChevronDown";
import { isNil } from "lodash";
import { useCallback } from "react";
import { graphql, useFragment } from "react-relay";
import { generatePath, useNavigate } from "react-router-dom";

import { DraftGrantButton } from "../../../../components/DraftGrantButton";
import {
  GranteeDeletionConfirmationModal,
  useGranteeDeletionConfirmationModalState,
} from "../../../../components/GranteeDeletionConfirmationModal";
import {
  GranteeFormSlide,
  useGranteeFormSlideState,
} from "../../../../components/GranteeFormSlide";
import {
  InviteGranteesModal,
  useInviteGranteesModalState,
} from "../../../../components/InviteGranteesModal";
import {
  NewEquitySelectionModal,
  useNewEquitySelectionModalState,
} from "../../../../components/NewEquitySelectionModal/NewEquitySelectionModal";
import {
  RevokeGranteeAccessModal,
  useRevokeGranteeAccessModalState,
} from "../../../../components/RevokeGranteeAccessModal";
import { Button } from "../../../../components/ui/Button";
import { useBoolean } from "../../../../hooks/useBoolean";
import { useResendGranteeInvitationEmail } from "../../../../hooks/useResendGranteeInvitationEmail";
import { APPLICATION_ROUTES } from "../../../../paths";
import { EmployeeDetailsTopBarActions_Employee$key } from "./__generated__/EmployeeDetailsTopBarActions_Employee.graphql";
import { EmployeeDetailsTopBarActions_Organization$key } from "./__generated__/EmployeeDetailsTopBarActions_Organization.graphql";
import { EmployeeDetailsTopBarActions_Viewer$key } from "./__generated__/EmployeeDetailsTopBarActions_Viewer.graphql";
import { SimulateExerciseModal } from "./SimulateExerciseModal";

const VIEWER_FRAGMENT = graphql`
  fragment EmployeeDetailsTopBarActions_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    isAllowedToManageOrganization(organizationId: $organizationId)
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment EmployeeDetailsTopBarActions_Organization on Organization {
    id
    isOriginatingFromRemoteEquity
    isRemoteEquityEssentials
    planIsFreemium
    granteePortalInvitationIsAllowed
    ...DraftGrantButton_Organization
    ...GranteeFormSlide_Organization
  }
`;

const EMPLOYEE_FRAGMENT = graphql`
  fragment EmployeeDetailsTopBarActions_Employee on Grantee {
    id
    name
    hRISProviderEmployee {
      hRISProvider
    }
    cannotBeInvitedReason
    hasBeenInvitedMoreThanAWeekAgoButDidNotVisitTheirPortal
    hasPortalAccess
    isTerminable
    isDeletable
    ctmsGrants {
      id
      exercisePrice
    }
    ...RevokeGranteeAccessModal_Grantee
    ...GranteeFormSlide_Grantee
  }
`;

export function EmployeeDetailsTopBarActions({
  employeeFragment,
  onDataUpdated,
  organizationFragment,
  viewerFragment,
}: {
  employeeFragment: EmployeeDetailsTopBarActions_Employee$key;
  onDataUpdated: () => void;
  organizationFragment: EmployeeDetailsTopBarActions_Organization$key;
  viewerFragment: EmployeeDetailsTopBarActions_Viewer$key;
}) {
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const employee = useFragment(EMPLOYEE_FRAGMENT, employeeFragment);

  const {
    hideRevokeGranteeAccessModal,
    revokeGranteeAccessModalState,
    showRevokeGranteeAccessModal,
  } = useRevokeGranteeAccessModalState();

  const {
    hideInviteGranteeModal,
    inviteGranteeModalState,
    showInviteGranteeModal,
  } = useInviteGranteesModalState();

  const {
    closeGranteeDeletionConfirmationModal: hideGranteeDeletionConfirmationModal,
    granteeDeletionConfirmationModalState,
    showGranteeDeletionConfirmationModal,
  } = useGranteeDeletionConfirmationModalState();

  const {
    closeGranteeFormSlide,
    granteeFormSlideState,
    openGranteeFormSlideInEditMode,
  } = useGranteeFormSlideState();

  const { resendInvitationEmail } = useResendGranteeInvitationEmail();

  const {
    setFalse: closeSimulateExerciseModal,
    setTrue: openSimulateExerciseModal,
    value: simulateExerciseModalIsOpen,
  } = useBoolean(false);

  const simulationReadyGrants = employee.ctmsGrants.filter(
    (grant) => !isNil(grant.exercisePrice),
  );
  const navigate = useNavigate();

  const granteeHasSimulationReadyGrants = simulationReadyGrants.length > 0;

  const goToGranteesPage = useCallback(() => {
    void navigate(
      generatePath(APPLICATION_ROUTES.organizationGrantees, {
        organizationId: organization.id,
      }),
    );
  }, [navigate, organization.id]);

  const {
    hideNewEquitySelectionModal,
    newEquitySelectionModalState,
    showNewEquitySelectionModal,
  } = useNewEquitySelectionModalState();

  if (!viewer.isAllowedToManageOrganization) {
    return null;
  }

  return (
    <>
      {simulateExerciseModalIsOpen && (
        <SimulateExerciseModal
          granteeId={employee.id}
          onExited={closeSimulateExerciseModal}
        />
      )}
      <GranteeDeletionConfirmationModal
        onClose={hideGranteeDeletionConfirmationModal}
        onGranteeDeleted={() => {
          hideGranteeDeletionConfirmationModal();
          goToGranteesPage();
        }}
        state={granteeDeletionConfirmationModalState}
      />

      <GranteeFormSlide
        onCancel={closeGranteeFormSlide}
        onGranteeCreated={closeGranteeFormSlide}
        onGranteeDeleted={() => {
          closeGranteeFormSlide();
          goToGranteesPage();
        }}
        onGranteeUpdated={closeGranteeFormSlide}
        organizationFragment={organization}
        state={granteeFormSlideState}
      />

      <InviteGranteesModal
        onClose={hideInviteGranteeModal}
        onDraftNewGrantButtonClick={() => {
          hideInviteGranteeModal();
          showNewEquitySelectionModal({ granteeFragment: null });
        }}
        onGranteesInvited={() => {
          hideInviteGranteeModal();
          onDataUpdated();
        }}
        onGranteeUpdated={onDataUpdated}
        organizationId={organization.id}
        state={inviteGranteeModalState}
      />
      <RevokeGranteeAccessModal
        onClose={hideRevokeGranteeAccessModal}
        onGranteeAccessRevoked={() => {
          hideRevokeGranteeAccessModal();
          onDataUpdated();
        }}
        state={revokeGranteeAccessModalState}
      />

      <NewEquitySelectionModal
        onClose={hideNewEquitySelectionModal}
        organizationId={organization.id}
        state={newEquitySelectionModalState}
      />

      <DropdownMenu
        align="end"
        // @ts-expect-error className not defined on DropdownMenu props
        className="z-10"
        trigger={
          <Button
            rightIcon={<IconV2OutlineChevronDown />}
            size="small"
            variant="Secondary Full"
          >
            Actions
          </Button>
        }
      >
        {organization.isOriginatingFromRemoteEquity &&
        employee.hRISProviderEmployee?.hRISProvider === "REMOTE" ? (
          // TODO: Add link to remote equity
          <DropdownMenu.Item href="" Icon={IconV2DuotoneExternalLink}>
            Edit in Remote
          </DropdownMenu.Item>
        ) : (
          <DropdownMenu.Item
            onClick={() => {
              openGranteeFormSlideInEditMode(employee);
            }}
          >
            Edit employee
          </DropdownMenu.Item>
        )}
        {organization.isRemoteEquityEssentials && (
          <DropdownMenu.Item
            href={generatePath(
              APPLICATION_ROUTES.organizationDeclareNewGrantForGrantee,
              {
                organizationId: organization.id,
                selectedGranteeId: employee.id,
              },
            )}
          >
            Declare grant
          </DropdownMenu.Item>
        )}
        {organization.isRemoteEquityEssentials && (
          <DropdownMenu.Item
            href={generatePath(
              APPLICATION_ROUTES.organizationDeclareNewExerciseRequestsForGrantee,
              {
                organizationId: organization.id,
                selectedGranteeId: employee.id,
              },
            )}
          >
            Declare exercise
          </DropdownMenu.Item>
        )}
        {!organization.isRemoteEquityEssentials &&
          organization.planIsFreemium && (
            <DraftGrantButton
              buttonRender={({ showNewEquitySelectionModal }) => (
                <DropdownMenu.Item
                  onClick={() => {
                    showNewEquitySelectionModal();
                  }}
                >
                  Draft equity
                </DropdownMenu.Item>
              )}
              organizationFragment={organization}
            ></DraftGrantButton>
          )}
        {!organization.isRemoteEquityEssentials &&
          !organization.planIsFreemium && (
            <DropdownMenu.Item
              href={generatePath(
                APPLICATION_ROUTES.organizationPrepareYourGrantsDrafts,
                {
                  organizationId: organization.id,
                },
              )}
            >
              Draft equity
            </DropdownMenu.Item>
          )}
        {organization.granteePortalInvitationIsAllowed &&
          !employee.cannotBeInvitedReason && (
            <DropdownMenu.Item
              onClick={() => {
                showInviteGranteeModal(employee.id);
              }}
            >
              Invite grantee
            </DropdownMenu.Item>
          )}
        {employee.hasBeenInvitedMoreThanAWeekAgoButDidNotVisitTheirPortal && (
          <DropdownMenu.Item
            onClick={() => {
              void resendInvitationEmail({
                id: employee.id,
                name: employee.name,
              });
            }}
          >
            Resend invitation
          </DropdownMenu.Item>
        )}
        {organization.granteePortalInvitationIsAllowed && (
          <DropdownMenu.Item
            href={generatePath(APPLICATION_ROUTES.employeePortal, {
              granteeId: employee.id,
            })}
            target="_blank"
          >
            Preview portal
          </DropdownMenu.Item>
        )}
        {employee.hasPortalAccess && (
          <DropdownMenu.Item
            onClick={() => {
              showRevokeGranteeAccessModal(employee);
            }}
          >
            Revoke access to portal
          </DropdownMenu.Item>
        )}
        {organization.planIsFreemium && employee.isTerminable && (
          <DropdownMenu.Item
            href={generatePath(
              APPLICATION_ROUTES.organizationTerminateGrantee,
              {
                granteeId: employee.id,
                organizationId: organization.id,
              },
            )}
          >
            Terminate grantee
          </DropdownMenu.Item>
        )}
        {employee.isDeletable && (
          <DropdownMenu.Item
            onClick={() => {
              showGranteeDeletionConfirmationModal(employee.id);
            }}
          >
            Delete grantee
          </DropdownMenu.Item>
        )}
        {granteeHasSimulationReadyGrants && !organization.planIsFreemium && (
          <DropdownMenu.Item onClick={openSimulateExerciseModal}>
            Simulate exercise
          </DropdownMenu.Item>
        )}
      </DropdownMenu>
    </>
  );
}
