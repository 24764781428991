import { Text } from "@remote-com/norma";
import { useCallback, useMemo } from "react";
import { FormattedNumber, useIntl } from "react-intl";
import { graphql, useFragment } from "react-relay";

import { BarGraph } from "../../../../components/BarGraph";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { SharesOutstandingCard_EssentialsOverview_Organization$key } from "./__generated__/SharesOutstandingCard_EssentialsOverview_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment SharesOutstandingCard_EssentialsOverview_Organization on Organization {
    poolGrantedSharesBreakdown {
      total
    }
    poolGrantedToVestSharesBreakdown {
      total
    }
    poolVestedToExerciseSharesBreakdown {
      total
    }
    poolSettlingSharesBreakdown {
      total
    }
    poolVestedExercisedSharesBreakdown {
      total
    }
  }
`;

export const SharesOutstandingCard: React.FC<{
  organizationFragment: SharesOutstandingCard_EssentialsOverview_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const intl = useIntl();

  const yTickFormatter = useCallback(
    (value: number) =>
      intl.formatNumber(value, {
        compactDisplay: "short",
        notation: "compact",
      }),
    [intl],
  );

  const stacks = useMemo(() => {
    return [
      {
        elements: [
          {
            className: "bg-purple-600 fill-purple-600",
            key: "vesting",
            value: organization.poolGrantedToVestSharesBreakdown.total,
          },
        ],
        label: "Vesting",
      },
      {
        elements: [
          {
            className: "bg-purple-500 fill-purple-500",
            key: "vested",
            value: organization.poolVestedToExerciseSharesBreakdown.total,
          },
        ],
        label: "Vested",
      },
      {
        elements: [
          {
            className: "bg-purple-400 fill-purple-400",
            key: "settling",
            value: organization.poolSettlingSharesBreakdown.total,
          },
        ],
        label: "Settling",
      },
      {
        elements: [
          {
            className: "bg-pink-500 fill-pink-500",
            key: "exercised",
            value: organization.poolVestedExercisedSharesBreakdown.total,
          },
        ],
        label: "Exercised",
      },
    ];
  }, [
    organization.poolGrantedToVestSharesBreakdown.total,
    organization.poolVestedToExerciseSharesBreakdown.total,
    organization.poolSettlingSharesBreakdown.total,
    organization.poolVestedExercisedSharesBreakdown.total,
  ]);

  return (
    <RoundedBox className="space-y-4 p-6" withBorder>
      <Text variant="lgMedium">Shares outstanding by status</Text>
      <div>
        <Text as="span" variant="xlMedium">
          <FormattedNumber
            value={organization.poolGrantedSharesBreakdown.total}
          />{" "}
          <Text as="span" className="text-grey-600" variant="sm">
            shares
          </Text>
        </Text>
      </div>
      <BarGraph
        barGap={40}
        height={230}
        marginTop={22}
        stacks={stacks}
        yTickFormatter={yTickFormatter}
      />
    </RoundedBox>
  );
};
