import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import { compact } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { CurrencyUnit } from "../../../../components/CurrencyUnit";
import { FormattedCurrency } from "../../../../components/Formatted/FormattedCurrency";
import {
  ProjectionCardSwitch,
  ProjectionMode,
} from "../../../../components/ProjectionCard";
import { ProjectionGraph } from "../../../../components/ProjectionGraph";
import { ProjectionTable } from "../../../../components/ProjectionTable";
import { useToaster } from "../../../../components/Toaster";
import { FormRow } from "../../../../components/ui/Form/FormRow";
import { Input } from "../../../../components/ui/Form/Inputs/Input";
import { TextArea } from "../../../../components/ui/Form/Inputs/TextArea";
import { Toggle } from "../../../../components/ui/Form/Toggle";
import { PreviewBox } from "../../../../components/ui/Layout/PreviewLayout";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { Tab } from "../../../../components/ui/Tab";
import { Tag } from "../../../../components/ui/Tag";
import { Toast } from "../../../../components/ui/Toast";
import { TooltipContainer } from "../../../../components/ui/TooltipContainer";
import { Typography } from "../../../../components/ui/Typography";
import { zodExtra } from "../../../../helpers/zod-extra";
import { useCurrencyFormatter } from "../../../../hooks/useCurrencyFormatter";
import { useDebouncedSafeMutation } from "../../../../hooks/useDebouncedSafeMutation";
import { useOrganizationSharesUtil } from "../../../../hooks/useOrganizationSharesUtil";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import {
  GranteePortalSettingsProjectionSection_GranteePortalProjectionScenario$data,
  GranteePortalSettingsProjectionSection_GranteePortalProjectionScenario$key,
} from "./__generated__/GranteePortalSettingsProjectionSection_GranteePortalProjectionScenario.graphql";
import {
  GranteePortalSettingsProjectionSection_Organization$data,
  GranteePortalSettingsProjectionSection_Organization$key,
} from "./__generated__/GranteePortalSettingsProjectionSection_Organization.graphql";
import { GranteePortalSettingsProjectionSection_SetGranteePortalProjectionScenarioDisplayed_Mutation } from "./__generated__/GranteePortalSettingsProjectionSection_SetGranteePortalProjectionScenarioDisplayed_Mutation.graphql";
import { GranteePortalSettingsProjectionSection_SetShowGranteePortalProjectionScenarios_Mutation } from "./__generated__/GranteePortalSettingsProjectionSection_SetShowGranteePortalProjectionScenarios_Mutation.graphql";
import { GranteePortalSettingsProjectionSection_UpdateGranteePortalProjectionScenario_Mutation } from "./__generated__/GranteePortalSettingsProjectionSection_UpdateGranteePortalProjectionScenario_Mutation.graphql";

const GRANTEE_PORTAL_PROJECTION_SCENARIO_FRAGMENT = graphql`
  fragment GranteePortalSettingsProjectionSection_GranteePortalProjectionScenario on GranteePortalProjectionScenario {
    key
    name
    multiple
    displayed
    additionalInformation
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment GranteePortalSettingsProjectionSection_Organization on Organization {
    id
    name
    granteePortalSettings {
      showProjectionScenarios
    }
    latestPricePerShare {
      value
    }
    granteePortalProjectionScenarios {
      key
      name
      multiple
      displayed
      additionalInformation
      ...GranteePortalSettingsProjectionSection_GranteePortalProjectionScenario
      ...ProjectionTable_GranteePortalProjectionScenarios
    }
    ...useOrganizationSharesUtil_Organization
    ...CurrencyUnit_Organization
    ...FormattedCurrency_Organization
    ...useCurrencyFormatter_Organization
    ...ProjectionGraph_Organization
    ...ProjectionTable_Organization
  }
`;

const SET_SHOW_GRANTEE_PORTAL_PROJECTION_SCENARIOS_MUTATION = graphql`
  mutation GranteePortalSettingsProjectionSection_SetShowGranteePortalProjectionScenarios_Mutation(
    $organizationId: OrganizationId!
    $showProjectionScenarios: Boolean!
  ) {
    setShowGranteePortalProjectionScenarios(
      organizationId: $organizationId
      showProjectionScenarios: $showProjectionScenarios
    ) {
      granteePortalSettings {
        showProjectionScenarios
      }
    }
  }
`;

const SET_PROJECTION_SCENARIO_DISPLAYED_MUTATION = graphql`
  mutation GranteePortalSettingsProjectionSection_SetGranteePortalProjectionScenarioDisplayed_Mutation(
    $organizationId: OrganizationId!
    $key: GranteePortalProjectionScenarioKey!
    $displayed: Boolean!
  ) {
    setGranteePortalProjectionScenarioDisplayed(
      organizationId: $organizationId
      key: $key
      displayed: $displayed
    ) {
      ...GranteePortalSettingsProjectionSection_GranteePortalProjectionScenario
    }
  }
`;

const UPDATE_PROJECTION_SCENARIO_MUTATION = graphql`
  mutation GranteePortalSettingsProjectionSection_UpdateGranteePortalProjectionScenario_Mutation(
    $organizationId: OrganizationId!
    $key: GranteePortalProjectionScenarioKey!
    $attributes: UpdateGranteePortalProjectionScenarioAttributes!
  ) {
    updateGranteePortalProjectionScenario(
      organizationId: $organizationId
      key: $key
      attributes: $attributes
    ) {
      ...GranteePortalSettingsProjectionSection_GranteePortalProjectionScenario
    }
  }
`;

type ProjectionScenario =
  GranteePortalSettingsProjectionSection_GranteePortalProjectionScenario$data;
type ProjectionScenarioKey = ProjectionScenario["key"];
const PROJECTION_SCENARIO_TABS: ProjectionScenarioKey[] = [
  "CURRENT_VALUE",
  "FIRST_ROUND",
  "SECOND_ROUND",
  "THIRD_ROUND",
];

const GranteePortalProjectionScenarioSchema = z.object({
  additionalInformation: zodExtra.nonEmptyNullableString(),
  multiple: z.number().nonnegative(),
  name: z.string().trim().min(1),
});

type GranteePortalProjectionScenarioFormInputs = z.infer<
  typeof GranteePortalProjectionScenarioSchema
>;

const GranteePortalProjectionScenarioSettings: React.FC<{
  organization: GranteePortalSettingsProjectionSection_Organization$data;
  projectionScenarioFragment: GranteePortalSettingsProjectionSection_GranteePortalProjectionScenario$key;
}> = ({ organization, projectionScenarioFragment }) => {
  const projectionScenario = useFragment(
    GRANTEE_PORTAL_PROJECTION_SCENARIO_FRAGMENT,
    projectionScenarioFragment,
  );

  const [_setProjectionScenarioDisplayed] =
    useSafeMutation<GranteePortalSettingsProjectionSection_SetGranteePortalProjectionScenarioDisplayed_Mutation>(
      SET_PROJECTION_SCENARIO_DISPLAYED_MUTATION,
    );

  const setProjectionScenarioDisplayed = useCallback(
    (displayed: boolean) =>
      _setProjectionScenarioDisplayed({
        variables: {
          displayed,
          key: projectionScenario.key,
          organizationId: organization.id,
        },
      }),
    [organization.id, projectionScenario.key, _setProjectionScenarioDisplayed],
  );

  const [updateProjectionScenario] =
    useDebouncedSafeMutation<GranteePortalSettingsProjectionSection_UpdateGranteePortalProjectionScenario_Mutation>(
      UPDATE_PROJECTION_SCENARIO_MUTATION,
    );

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {
      additionalInformation: projectionScenario.additionalInformation,
      multiple: projectionScenario.multiple,
      name: projectionScenario.name,
    },
    resolver: zodResolver(GranteePortalProjectionScenarioSchema),
  });

  const onSubmit = handleSubmit(
    (attributes: GranteePortalProjectionScenarioFormInputs) =>
      updateProjectionScenario({
        variables: {
          attributes,
          key: projectionScenario.key,
          organizationId: organization.id,
        },
      }),
  );

  watch(() => void onSubmit());

  const { sharesToValue } = useOrganizationSharesUtil({
    organizationFragment: organization,
  });

  const projectedPricePerShare = sharesToValue(1 * projectionScenario.multiple);

  const formatCurrency = useCurrencyFormatter({
    organizationFragment: organization,
  });

  return (
    <form id="grantee-portal-projection-scenario-form">
      <RoundedBox className="space-y-4 p-4" withBorder>
        <div className="flex gap-4">
          <div className="flex-grow">
            <FormRow error={errors.name?.message} label="Milestone name">
              <Input
                disabled={projectionScenario.key === "CURRENT_VALUE"}
                invalid={!!errors.name}
                {...control.register("name")}
              />
            </FormRow>
          </div>
          {projectionScenario.key !== "CURRENT_VALUE" && (
            <div className="flex-grow">
              <FormRow error={errors.multiple?.message} label="Multiple">
                <Input
                  invalid={!!errors.multiple}
                  placeholder="E.g. 3"
                  step="any"
                  type="number"
                  {...control.register("multiple", {
                    valueAsNumber: true,
                  })}
                />
              </FormRow>
            </div>
          )}

          {projectedPricePerShare !== null && (
            <div className="flex-grow">
              <FormRow label="Price per share">
                <Input
                  disabled
                  name="pricePerShare"
                  value={formatCurrency(projectedPricePerShare, {
                    maximumFractionDigits: 2,
                  })}
                />
              </FormRow>
            </div>
          )}
        </div>
        <FormRow label="Additional details">
          <TextArea
            className="h-16 resize-none"
            placeholder="Our company is growing, and we believe we’ll be able to keep doing so. We’re confident we can grow at least this much over the next several years."
            {...control.register("additionalInformation")}
          />
        </FormRow>
        {projectionScenario.key !== "CURRENT_VALUE" && (
          <div className="flex items-center gap-4 rounded border-[0.5px] border-gray-07 px-3 py-2">
            <Toggle
              enabled={projectionScenario.displayed}
              onChange={(value) => void setProjectionScenarioDisplayed(value)}
              size="small"
            />
            <div className="flex-grow">
              <Typography as="div" variant="Medium/Extra Small">
                Include this scenario
              </Typography>
            </div>
          </div>
        )}
      </RoundedBox>
    </form>
  );
};

const CURRENT_VALUE_IN_USD = 50_000;

const GranteePortalProjectionScenariosSettings: React.FC<{
  organization: GranteePortalSettingsProjectionSection_Organization$data;
  shares: number;
}> = ({ organization, shares }) => {
  const findProjectionScenario = useCallback(
    (key: ProjectionScenarioKey) => {
      const projectionScenario =
        organization.granteePortalProjectionScenarios.find(
          (projectionScenario) => projectionScenario.key === key,
        );
      if (!projectionScenario)
        throw new Error(`Missing projection scenario - ${key}`);
      return { ...projectionScenario };
    },
    [organization.granteePortalProjectionScenarios],
  );

  const projectionScenarios = useMemo(() => {
    return PROJECTION_SCENARIO_TABS.map((key) => findProjectionScenario(key));
  }, [findProjectionScenario]);

  const { sharesToValue } = useOrganizationSharesUtil({
    organizationFragment: organization,
  });

  const sharesValue = sharesToValue(shares);

  const projectionGraphBars = useMemo(() => {
    const currentValueProjectionScenario =
      findProjectionScenario("CURRENT_VALUE");

    const projectionGraphBars: React.ComponentProps<
      typeof ProjectionGraph
    >["bars"] = compact([
      sharesValue !== null
        ? {
            key: currentValueProjectionScenario.key,
            label: currentValueProjectionScenario.name,
            tooltip: (
              <ProjectionGraph.Tooltip
                barKey={currentValueProjectionScenario.key}
                label={currentValueProjectionScenario.name}
                organizationFragment={organization}
                potentialValueUSD={sharesValue}
              >
                {currentValueProjectionScenario.additionalInformation}
              </ProjectionGraph.Tooltip>
            ),
            value: sharesValue,
          }
        : null,
      ...(["FIRST_ROUND", "SECOND_ROUND", "THIRD_ROUND"] as const).map(
        (key) => {
          const projectionScenario = findProjectionScenario(key);
          if (!projectionScenario.displayed) return null;
          const value = sharesToValue(shares * projectionScenario.multiple);
          if (value === null) return null;
          return {
            key: projectionScenario.key,
            label: projectionScenario.name,
            tooltip: (
              <ProjectionGraph.Tooltip
                barKey={projectionScenario.key}
                label={projectionScenario.name}
                multiplier={projectionScenario.multiple}
                organizationFragment={organization}
                potentialValueUSD={value}
              >
                {projectionScenario.additionalInformation}
              </ProjectionGraph.Tooltip>
            ),
            value,
          };
        },
      ),
    ]);
    return projectionGraphBars;
  }, [
    findProjectionScenario,
    sharesToValue,
    sharesValue,
    shares,
    organization,
  ]);

  const [selectedProjectionMode, setSelectedProjectionMode] =
    useState<ProjectionMode>("CHART");

  const pricePerShare = sharesToValue(1);

  return (
    <>
      <Tab.Group>
        <Tab.List>
          {projectionScenarios.map((projectionScenario) => (
            <Tab key={projectionScenario.key}>{projectionScenario.name}</Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          {projectionScenarios.map((projectionScenario) => (
            <Tab.Panel key={projectionScenario.key}>
              <GranteePortalProjectionScenarioSettings
                organization={organization}
                projectionScenarioFragment={projectionScenario}
              />
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
      <PreviewBox className="w-full" previewClassnames="space-y-6">
        <Typography as="div" variant="Medium/Large">
          Let’s see what it could potentially be worth in the future:
        </Typography>
        <div className="flex justify-center">
          <ProjectionCardSwitch
            selectedMode={selectedProjectionMode}
            setSelectedMode={setSelectedProjectionMode}
          />
        </div>

        <RoundedBox className="!border-primary px-6 py-4" withBorder>
          <div className="flex flex-col gap-2">
            <div className="flex flex-wrap-reverse items-start justify-between gap-2">
              <Typography variant="Medium/Small">
                Potential value projection
              </Typography>
              {pricePerShare !== null && (
                <Tag color="gray">
                  Current Price Per Share:{" "}
                  <FormattedCurrency
                    organizationFragment={organization}
                    value={pricePerShare}
                  />
                </Tag>
              )}
            </div>
            <Typography className="text-gray-09" variant="Regular/Extra Small">
              e.g. Grantee receives{" "}
              <FormattedCurrency
                organizationFragment={organization}
                value={50_000}
              />{" "}
              <CurrencyUnit organizationFragment={organization} /> in equity
              today
            </Typography>
          </div>
          {selectedProjectionMode === "CHART" && (
            <ProjectionGraph
              bars={projectionGraphBars}
              organizationFragment={organization}
            />
          )}
          {selectedProjectionMode === "TABLE" &&
            organization.latestPricePerShare && (
              <ProjectionTable
                className="mt-4 w-full"
                latestPricePerShareValue={
                  organization.latestPricePerShare.value
                }
                organizationFragment={organization}
                projectionScenariosFragment={
                  organization.granteePortalProjectionScenarios
                }
                shares={shares}
                valueAtGrant={null}
              />
            )}
        </RoundedBox>
      </PreviewBox>
    </>
  );
};

export const GranteePortalSettingsProjectionSection: React.FC<{
  organizationFragment: GranteePortalSettingsProjectionSection_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const toaster = useToaster();

  const [_setShowGranteePortalProjectionScenarios] =
    useSafeMutation<GranteePortalSettingsProjectionSection_SetShowGranteePortalProjectionScenarios_Mutation>(
      SET_SHOW_GRANTEE_PORTAL_PROJECTION_SCENARIOS_MUTATION,
    );

  const setShowProjectionScenarios = useCallback(
    async (showProjectionScenarios: boolean) => {
      await _setShowGranteePortalProjectionScenarios({
        variables: {
          organizationId: organization.id,
          showProjectionScenarios,
        },
      });
      toaster.push(
        <Toast title="Great!">
          Projection scenarios successfully updated!
        </Toast>,
      );
    },
    [_setShowGranteePortalProjectionScenarios, toaster, organization.id],
  );
  const { valueToShares } = useOrganizationSharesUtil({
    organizationFragment: organization,
  });

  const shares = valueToShares(CURRENT_VALUE_IN_USD);

  return (
    <div className="space-y-6 p-10 pt-6">
      <div className="space-y-4">
        <Typography variant="Medium/Small">Projection scenarios</Typography>
        <div className="flex items-center gap-4 rounded border-[0.5px] border-gray-07 px-3 py-2">
          <Toggle
            enabled={organization.granteePortalSettings.showProjectionScenarios}
            onChange={(value) => void setShowProjectionScenarios(value)}
            size="small"
          />
          <div className="flex-grow space-y-1">
            <Typography as="div" variant="Medium/Extra Small">
              Projection scenarios
            </Typography>
            <Typography
              as="div"
              className="text-black-05"
              variant="Regular/Extra Small"
            >
              Add milestones to help employees know how much the company can
              grow over time
            </Typography>
          </div>
          <TooltipContainer
            position="bottom"
            tooltipContent={
              <div className="space-y-2">
                <Typography as="div" variant="Regular/Extra Small">
                  Projection scenarios
                </Typography>
                <Typography
                  as="div"
                  className="text-gray-07"
                  variant="Regular/Caption"
                >
                  Enter different inputs and scenarios to see how the final
                  value of your startup company equity value may turn out. Allow
                  the grantee to view the potential value of its equity based on
                  the company&apos;s future valuation.
                  <br /> <br />
                  You have full control hover the numbers that you display in
                  the portal. You can also choose to not include a projection
                  scenario.
                </Typography>
              </div>
            }
          >
            <InformationCircleIcon className="h-4 w-4 shrink-0 text-primary" />
          </TooltipContainer>
        </div>
      </div>
      {organization.granteePortalSettings.showProjectionScenarios &&
        shares !== null && (
          <GranteePortalProjectionScenariosSettings
            organization={organization}
            shares={shares}
          />
        )}
    </div>
  );
};
