/**
 * @generated SignedSource<<7d025ee71938971e7a4fbaaed710be94>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DownloadUnderwaterGrantsCSV_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useCurrencyFormatter_Organization">;
  readonly " $fragmentType": "DownloadUnderwaterGrantsCSV_Organization";
};
export type DownloadUnderwaterGrantsCSV_Organization$key = {
  readonly " $data"?: DownloadUnderwaterGrantsCSV_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"DownloadUnderwaterGrantsCSV_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DownloadUnderwaterGrantsCSV_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useCurrencyFormatter_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "8434f3018f7339c44354d9e4f87e4509";

export default node;
