/* eslint-disable relay/must-colocate-fragment-spreads */
import { debounce } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useFragment, UseMutationConfig } from "react-relay";
import { generatePath, useNavigate } from "react-router-dom";
import { graphql } from "relay-runtime";

import { useEquityOfferTrackEvent } from "../../../hooks/useEquityOfferTrackEvent";
import { useLocationChangeHandler } from "../../../hooks/useLocationChangeHandler";
import { useSafeMutation } from "../../../hooks/useSafeMutation";
import { APPLICATION_ROUTES } from "../../../paths";
import { EquityOfferContext_AddStepToEquityOfferVisitedSteps_Mutation } from "./__generated__/EquityOfferContext_AddStepToEquityOfferVisitedSteps_Mutation.graphql";
import {
  EquityOfferContext_EquityOffer$data,
  EquityOfferContext_EquityOffer$key,
} from "./__generated__/EquityOfferContext_EquityOffer.graphql";
import { EquityOfferContext_UpdateEquityOffer_Mutation } from "./__generated__/EquityOfferContext_UpdateEquityOffer_Mutation.graphql";

const EQUITY_OFFER_FRAGMENT = graphql`
  fragment EquityOfferContext_EquityOffer on EquityOffer {
    id
    benefits
    shares
    candidateName
    displayBenefits
    displayCompanyInformation
    displayEquityAsDollarValue
    displayEquityAsPercentage
    displayFAQ
    displayProjectionScenarios
    displaySalary
    displayTaxationExplanation
    exercisePrice
    finalThoughts
    instrument {
      id
    }
    position
    salary
    taxResidenceCountry {
      code
    }
    vestingSchedule {
      id
    }
    workRelationship
    organization {
      id
    }
    postTerminationExercisePeriod {
      id
    }
    contractStartDate
    salaryCurrencyCode
    ...CompanySettings_EquityOffer
    ...CandidateSettings_EquityOffer
    ...VestingSettings_EquityOffer
    ...EquiuniversitySettings_EquityOffer
    ...IncentiveSettings_EquityOffer
    ...ProjectionSettings_EquityOffer
    ...FinalThoughtsSettings_EquityOffer
    ...useEquityOfferTrackEvent_EquityOffer
  }
`;

const UPDATE_EQUITY_OFFER_MUTATION = graphql`
  mutation EquityOfferContext_UpdateEquityOffer_Mutation(
    $equityOfferId: UUID!
    $attributes: EquityOfferInput!
  ) {
    updateEquityOffer(equityOfferId: $equityOfferId, attributes: $attributes) {
      ...EquityOfferContext_EquityOffer
    }
  }
`;

const ADD_STEP_TO_EQUITY_OFFER_VISITED_STEPS_MUTATION = graphql`
  mutation EquityOfferContext_AddStepToEquityOfferVisitedSteps_Mutation(
    $equityOfferId: UUID!
    $step: EquityOfferSettingsStep!
  ) {
    addStepToEquityOfferVisitedSteps(
      equityOfferId: $equityOfferId
      step: $step
    ) {
      visitedSteps
    }
  }
`;

interface EquityOfferContext {
  activeStep: EquityOfferSettingsStep;
  equityOffer: EquityOfferContext_EquityOffer$data;
  equityOfferIsBeingUpdated: boolean;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  // TODO: Rename this when all pages will be done to avoid conflicts
  notDebouncedUpdatePartialEquityOffer: (
    partialAttributes: Partial<
      EquityOfferContext_UpdateEquityOffer_Mutation["variables"]["attributes"]
    >,
    config?: Omit<
      UseMutationConfig<EquityOfferContext_UpdateEquityOffer_Mutation>,
      "variables"
    >,
  ) => Promise<void>;
  onStepEntered: (step: EquityOfferSettingsStep) => void;
  trackStepCompleted: (step: EquityOfferSettingsStep) => void;
  updatePartialEquityOffer: (
    partialAttributes: Partial<
      EquityOfferContext_UpdateEquityOffer_Mutation["variables"]["attributes"]
    >,
  ) => Promise<void>;
}

type EquityOfferSettingsStep =
  EquityOfferContext_AddStepToEquityOfferVisitedSteps_Mutation["variables"]["step"];

const useBuildUpdateEquityOfferMutationAttributes = ({
  equityOffer,
}: {
  equityOffer: EquityOfferContext_EquityOffer$data;
}) => {
  const buildAttributes = useCallback(
    (
      partialAttributes: Partial<
        EquityOfferContext_UpdateEquityOffer_Mutation["variables"]["attributes"]
      >,
    ) => {
      const attributes: Required<
        EquityOfferContext_UpdateEquityOffer_Mutation["variables"]["attributes"]
      > = {
        benefits: equityOffer.benefits,
        candidateName: equityOffer.candidateName,
        contractStartDate: equityOffer.contractStartDate,
        displayBenefits: equityOffer.displayBenefits,
        displayCompanyInformation: equityOffer.displayCompanyInformation,
        displayEquityAsPercentage: equityOffer.displayEquityAsPercentage,
        displayEquityAsValue: equityOffer.displayEquityAsDollarValue,
        displayFAQ: equityOffer.displayFAQ,
        displayProjectionScenarios: equityOffer.displayProjectionScenarios,
        displaySalary: equityOffer.displaySalary,
        displayTaxationExplanation: equityOffer.displayTaxationExplanation,
        exercisePrice: equityOffer.exercisePrice,
        finalThoughts: equityOffer.finalThoughts,
        instrumentId: equityOffer.instrument?.id ?? null,
        position: equityOffer.position,
        postTerminationExercisePeriodId:
          equityOffer.postTerminationExercisePeriod?.id ?? null,
        salary: equityOffer.salary,
        salaryCurrencyCode: equityOffer.salaryCurrencyCode,
        shares: equityOffer.shares,
        taxResidenceCountryCode: equityOffer.taxResidenceCountry?.code ?? null,
        vestingScheduleId: equityOffer.vestingSchedule?.id ?? null,
        workRelationship: equityOffer.workRelationship,
        ...partialAttributes,
      };

      return attributes;
    },
    [equityOffer],
  );

  return buildAttributes;
};

export const EquityOfferContextProvider: React.FC<{
  children: React.ReactNode;
  equityOfferFragment: EquityOfferContext_EquityOffer$key;
}> = ({ children, equityOfferFragment }) => {
  const equityOffer = useFragment(EQUITY_OFFER_FRAGMENT, equityOfferFragment);
  const [_updateEquityOffer, equityOfferIsBeingUpdated] =
    useSafeMutation<EquityOfferContext_UpdateEquityOffer_Mutation>(
      UPDATE_EQUITY_OFFER_MUTATION,
    );

  const updateEquityOffer = useCallback(
    (
      attributes: EquityOfferContext_UpdateEquityOffer_Mutation["variables"]["attributes"],
      config: Omit<
        UseMutationConfig<EquityOfferContext_UpdateEquityOffer_Mutation>,
        "variables"
      > = {},
    ) =>
      _updateEquityOffer({
        ...config,
        variables: {
          attributes,
          equityOfferId: equityOffer.id,
        },
      }),
    [_updateEquityOffer, equityOffer.id],
  );

  const debouncedUpdateEquityOffer = useMemo(
    () => debounce(updateEquityOffer, 500),
    [updateEquityOffer],
  );

  const flushEquityOfferDebouncedUpdates = useCallback(async () => {
    await debouncedUpdateEquityOffer.flush();
  }, [debouncedUpdateEquityOffer]);

  useEffect(() => {
    return () => {
      void flushEquityOfferDebouncedUpdates();
    };
  }, [flushEquityOfferDebouncedUpdates]);

  const buildUpdateEquityOfferMutationAttributes =
    useBuildUpdateEquityOfferMutationAttributes({ equityOffer });

  useLocationChangeHandler(flushEquityOfferDebouncedUpdates);

  const updatePartialEquityOffer: EquityOfferContext["updatePartialEquityOffer"] =
    useCallback(
      async (partialAttributes) => {
        const attributes =
          buildUpdateEquityOfferMutationAttributes(partialAttributes);

        await debouncedUpdateEquityOffer(attributes);
      },
      [debouncedUpdateEquityOffer, buildUpdateEquityOfferMutationAttributes],
    );

  const notDebouncedUpdatePartialEquityOffer: EquityOfferContext["notDebouncedUpdatePartialEquityOffer"] =
    useCallback(
      async (partialAttributes, config) => {
        const attributes =
          buildUpdateEquityOfferMutationAttributes(partialAttributes);

        await updateEquityOffer(attributes, config);
      },
      [updateEquityOffer, buildUpdateEquityOfferMutationAttributes],
    );

  const [activeStep, setActiveStep] =
    useState<EquityOfferSettingsStep>("CANDIDATE");

  const [addStepToEquityOfferVisitedSteps] =
    useSafeMutation<EquityOfferContext_AddStepToEquityOfferVisitedSteps_Mutation>(
      ADD_STEP_TO_EQUITY_OFFER_VISITED_STEPS_MUTATION,
    );
  const onStepEntered = useCallback(
    async (step: EquityOfferSettingsStep) => {
      setActiveStep(step);
      await addStepToEquityOfferVisitedSteps({
        variables: {
          equityOfferId: equityOffer.id,
          step,
        },
      });
    },
    [addStepToEquityOfferVisitedSteps, equityOffer.id],
  );

  const navigate = useNavigate();

  const _moveInEquityOfferSteps = useCallback(
    (direction: "backward" | "forward") => {
      const step = EQUITY_OFFER_STEPS.find(({ step }) => step === activeStep);
      if (!step) {
        throw new Error("can't find active step");
      }
      const futureStep =
        EQUITY_OFFER_STEPS[
          EQUITY_OFFER_STEPS.indexOf(step) + (direction === "forward" ? 1 : -1)
        ];
      if (!futureStep) {
        throw new Error("can't find next step");
      }
      void navigate(
        generatePath(futureStep.route, {
          equityOfferId: equityOffer.id,
          organizationId: equityOffer.organization.id,
        }),
      );
    },
    [activeStep, equityOffer.id, equityOffer.organization.id, navigate],
  );

  const { trackStepCompleted } = useEquityOfferTrackEvent({
    equityOfferFragment: equityOffer,
  });

  const context: EquityOfferContext = useMemo(
    () => ({
      activeStep,
      equityOffer,
      equityOfferIsBeingUpdated,
      goToNextStep: () => {
        _moveInEquityOfferSteps("forward");
      },
      goToPreviousStep: () => {
        _moveInEquityOfferSteps("backward");
      },
      notDebouncedUpdatePartialEquityOffer,
      onStepEntered,
      trackStepCompleted,
      updatePartialEquityOffer,
    }),
    [
      equityOffer,
      updatePartialEquityOffer,
      notDebouncedUpdatePartialEquityOffer,
      equityOfferIsBeingUpdated,
      activeStep,
      onStepEntered,
      _moveInEquityOfferSteps,
      trackStepCompleted,
    ],
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

const Context = React.createContext<EquityOfferContext | null>(null);

export const useEquityOfferContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error("can't get equity offer context");
  }

  return context;
};

export const EQUITY_OFFER_STEPS = [
  {
    analyticsName: "Candidate",
    label: "Candidate",
    route: APPLICATION_ROUTES.organizationToolsEquityOfferCandidate,
    SARLabel: "Candidate",
    step: "CANDIDATE",
  },
  {
    analyticsName: "Company Information",
    label: "Company",
    route: APPLICATION_ROUTES.organizationToolsEquityOfferCompany,
    SARLabel: "Company",
    step: "COMPANY",
  },
  {
    analyticsName: "Equity Incentive",
    label: "Equity incentive",
    route: APPLICATION_ROUTES.organizationToolsEquityOfferIncentive,
    SARLabel: "Equity Incentive",
    step: "INCENTIVE",
  },
  {
    analyticsName: "Vesting & PTEP",
    label: "Vesting & PTEP",
    route: APPLICATION_ROUTES.organizationToolsEquityOfferVesting,
    SARLabel: "Vesting & PTSP",
    step: "VESTING",
  },
  {
    analyticsName: "Projection Scenarios",
    label: "Projection scenarios",
    route: APPLICATION_ROUTES.organizationToolsEquityOfferProjection,
    SARLabel: "Projection Scenarios",
    step: "PROJECTION",
  },
  {
    analyticsName: "Equity University",
    label: "Equi-universi-ty",
    route: APPLICATION_ROUTES.organizationToolsEquityOfferEquiuniversity,
    SARLabel: "FAQ regarding SAR",
    step: "EQUIUNIVERSITY",
  },
  {
    analyticsName: "Final words",
    label: "Final thoughts",
    route: APPLICATION_ROUTES.organizationToolsEquityOfferFinal,
    SARLabel: "Final thoughts",
    step: "FINAL_THOUGHTS",
  },
] as const;
