/**
 * @generated SignedSource<<df6dc7aa5cbb298a8465f7576ef844a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DraftGrantDetailsHeader_Organization$data = {
  readonly equityGrid: {
    readonly activated: boolean;
  };
  readonly " $fragmentSpreads": FragmentRefs<"EmployeeEquityGridLevelTag_Organization">;
  readonly " $fragmentType": "DraftGrantDetailsHeader_Organization";
};
export type DraftGrantDetailsHeader_Organization$key = {
  readonly " $data"?: DraftGrantDetailsHeader_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"DraftGrantDetailsHeader_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DraftGrantDetailsHeader_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityGrid",
      "kind": "LinkedField",
      "name": "equityGrid",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "activated",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmployeeEquityGridLevelTag_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "0299eb897a6a08cd4b0200140d816940";

export default node;
