import { Pill } from "@remote-com/norma";
import { addMonths, isPast } from "date-fns";
import { chain, isEmpty } from "lodash";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import { BackButton } from "../../../../components/BackButton";
import { FormattedCurrency } from "../../../../components/Formatted/FormattedCurrency";
import { Page } from "../../../../components/Page";
import { ShortDate } from "../../../../components/ShortDate";
import { BreadCrumb } from "../../../../components/ui/BreadCrumb";
import { Button } from "../../../../components/ui/Button";
import { OneColumnLayout } from "../../../../components/ui/Layout/OneColumnLayout";
import { NoticeMessage } from "../../../../components/ui/NoticeMessage";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { Table } from "../../../../components/ui/Table";
import { Typography } from "../../../../components/ui/Typography";
import { useBoolean } from "../../../../hooks/useBoolean";
import { APPLICATION_ROUTES } from "../../../../paths";
import { UnusedGeographies_Organization$key } from "./__generated__/UnusedGeographies_Organization.graphql";
import { UnusedGeographies_Query } from "./__generated__/UnusedGeographies_Query.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment UnusedGeographies_Organization on Organization @relay(plural: true) {
    id
    name
    topCompany {
      country {
        code
      }
    }
    organizationGeographies {
      hasDraftsPending
      unlockedAt
      geography {
        country {
          emoji
          name
          code
        }
      }
      price {
        priceAfterDiscount
      }
      billableActiveGranteesCount
      settlingGranteesCount
    }
    ...FormattedCurrency_Organization
  }
`;

const UnusedGeographiesPage_: React.FC<{
  organizationsFragment: UnusedGeographies_Organization$key;
}> = ({ organizationsFragment }) => {
  const organizations = useFragment(
    ORGANIZATION_FRAGMENT,
    organizationsFragment,
  );

  const {
    toggle: toggleHideGeographiesUnlockedForFree,
    value: hideGeographiesUnlockedForFree,
  } = useBoolean(false);

  const organizationsWithUnusedGeographies = useMemo(
    () =>
      chain(organizations)
        .filter((organization) => organization.name !== "Easop")
        .map((organization) => ({
          ...organization,
          unusedGeographies: chain(organization.organizationGeographies)
            .filter(
              ({ geography }) =>
                geography.country.code !==
                organization.topCompany?.country.code,
            )
            .filter(
              ({ price }) =>
                !hideGeographiesUnlockedForFree ||
                Boolean(price?.priceAfterDiscount),
            )
            .filter(
              ({ billableActiveGranteesCount }) =>
                billableActiveGranteesCount === 0,
            )
            .sortBy(({ geography }) => geography.country.name)
            .value(),
        }))
        .filter(({ unusedGeographies }) => !isEmpty(unusedGeographies))
        .sortBy(({ name }) => name)
        .value(),
    [organizations, hideGeographiesUnlockedForFree],
  );

  return (
    <OneColumnLayout
      Breadcrumb={
        <BreadCrumb>
          <BreadCrumb.Link to="..">Organization admin</BreadCrumb.Link>
          <BreadCrumb.Link to=".">Unused geographies</BreadCrumb.Link>
        </BreadCrumb>
      }
      showFooter
    >
      <RoundedBox className="space-y-4 p-6" withBorder withShadow>
        <div className="flex items-center gap-4">
          <BackButton />
          <Typography className="flex-1" variant="Medium/Default">
            Check which organizations are paying for unused geographies
          </Typography>
          <Button
            onClick={() => {
              toggleHideGeographiesUnlockedForFree();
            }}
            size="small"
            type="button"
            variant={
              hideGeographiesUnlockedForFree ? "Primary Full" : "Secondary Full"
            }
          >
            {hideGeographiesUnlockedForFree ? (
              <>Show geographies unlocked for free</>
            ) : (
              <>Hide geographies unlocked for free</>
            )}
          </Button>
        </div>
        {isEmpty(organizationsWithUnusedGeographies) ? (
          <NoticeMessage size="Small" variant="Positive">
            All organizations are ok
          </NoticeMessage>
        ) : (
          <Table
            className="grid grid-cols-[1fr_1fr_1fr_320px] gap-x-2"
            display="grid"
          >
            <Table.Header className="rounded-lg">
              <Table.Row>
                <Table.HeaderCell>Organization</Table.HeaderCell>
                <Table.HeaderCell>Geography</Table.HeaderCell>
                <Table.HeaderCell>Price</Table.HeaderCell>
                <Table.HeaderCell>Unlocked on</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {organizationsWithUnusedGeographies.map((organization) =>
                organization.unusedGeographies.map(
                  (
                    {
                      geography,
                      hasDraftsPending,
                      price,
                      settlingGranteesCount,
                      unlockedAt,
                    },
                    index,
                  ) => (
                    <Table.Row
                      key={`${organization.id}:${geography.country.name}`}
                    >
                      {index === 0 && (
                        <Table.Cell variant="Medium/Extra Small">
                          <Link
                            to={generatePath(
                              APPLICATION_ROUTES.organizationGrantees,
                              {
                                organizationId: organization.id,
                              },
                            )}
                          >
                            {organization.name}
                          </Link>
                        </Table.Cell>
                      )}
                      <Table.Cell className="col-start-2">
                        <Link
                          to={generatePath(
                            APPLICATION_ROUTES.organizationCountry,
                            {
                              countryCode: geography.country.code,
                              organizationId: organization.id,
                            },
                          )}
                        >
                          {geography.country.emoji} {geography.country.name}
                          {hasDraftsPending && (
                            <>
                              <br />
                              <Pill type="subtle">Drafts pending</Pill>
                            </>
                          )}
                          {settlingGranteesCount > 0 && (
                            <>
                              <br />
                              <Pill type="subtle">
                                <FormattedMessage
                                  defaultMessage="{count, plural, one {# settling grantee} other {# settling grantees}}"
                                  values={{
                                    count: settlingGranteesCount,
                                  }}
                                />
                              </Pill>
                            </>
                          )}
                        </Link>
                      </Table.Cell>
                      <Table.Cell>
                        {price?.priceAfterDiscount ? (
                          <FormattedCurrency
                            organizationFragment={organization}
                            value={price.priceAfterDiscount}
                          />
                        ) : (
                          <Pill type="subtle">Unlocked for free</Pill>
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        <div className="flex items-center gap-2">
                          <ShortDate value={unlockedAt} />
                          {isPast(addMonths(unlockedAt, 12)) && (
                            <Pill tone="warning" type="subtle">
                              MORE THAN 12 months ago
                            </Pill>
                          )}
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  ),
                ),
              )}
            </Table.Body>
          </Table>
        )}
      </RoundedBox>
    </OneColumnLayout>
  );
};

const QUERY = graphql`
  query UnusedGeographies_Query {
    organizationsNotOriginatingFromRemoteEquity: organizations(
      filters: { isOriginatingFromRemoteEquity: false }
    ) {
      ...UnusedGeographies_Organization
    }
  }
`;

const UnusedGeographiesPage: React.FC = () => {
  const { organizationsNotOriginatingFromRemoteEquity } =
    useLazyLoadQuery<UnusedGeographies_Query>(QUERY, {});
  return (
    <Page
      analyticsCategory="Super Admin"
      analyticsName="Super Admin - Unused Geographies"
      title="Super admin | unused geographies"
    >
      <UnusedGeographiesPage_
        organizationsFragment={organizationsNotOriginatingFromRemoteEquity}
      />
    </Page>
  );
};

export default UnusedGeographiesPage;
