/**
 * @generated SignedSource<<d3ef9fafe298a8324826b23b827b494e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewCtmsGrantDetails_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"GrantDetailsExerciseSection_Organization" | "GrantDetailsOwnershipSection_Organization" | "GrantDetailsVestingScheduleSection_Organization" | "LargeCenteredDetailsLayout_Organization">;
  readonly " $fragmentType": "NewCtmsGrantDetails_Organization";
};
export type NewCtmsGrantDetails_Organization$key = {
  readonly " $data"?: NewCtmsGrantDetails_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewCtmsGrantDetails_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewCtmsGrantDetails_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LargeCenteredDetailsLayout_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantDetailsOwnershipSection_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantDetailsVestingScheduleSection_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantDetailsExerciseSection_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "4504a2ec21904d2986d6b725411418c4";

export default node;
