import { Button, ListNumbers, StandaloneLink, Text } from "@remote-com/norma";
import { IconV2OutlineArrowRight } from "@remote-com/norma/icons/IconV2OutlineArrowRight";
import { IconV2OutlineArrowUpRight } from "@remote-com/norma/icons/IconV2OutlineArrowUpRight";
import React, { useCallback, useState } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { CartaLogo } from "../../../components/ui/CartaLogo";
import { RemoteEquityOnboardingLayout } from "../../../components/ui/Layout/RemoteLikeApplicationLayout/RemoteEquityOnboardingLayout";
import { NoticeMessage } from "../../../components/ui/NoticeMessage";
import { useManualQuery } from "../../../hooks/useManualQuery";
import { useSafeMutation } from "../../../hooks/useSafeMutation";
import { useTrackRemoteEquityOnboardingFlowEvent } from "../../../hooks/useTrackRemoteEquityOnboardingFlowEvent";
import CONFIGURATION from "../../../services/configuration";
import { TestLawFirmCartaConnectionView_HasOrganizationAddedEasopAsCartaLegalAdmin_Query } from "./__generated__/TestLawFirmCartaConnectionView_HasOrganizationAddedEasopAsCartaLegalAdmin_Query.graphql";
import { TestLawFirmCartaConnectionView_Organization$key } from "./__generated__/TestLawFirmCartaConnectionView_Organization.graphql";
import { TestLawFirmCartaConnectionView_SetTestLawFirmCartaConnectionOnboardingAsCompleted_Mutation } from "./__generated__/TestLawFirmCartaConnectionView_SetTestLawFirmCartaConnectionOnboardingAsCompleted_Mutation.graphql";
import { TestLawFirmCartaConnectionView_Viewer$key } from "./__generated__/TestLawFirmCartaConnectionView_Viewer.graphql";
import ContactUsCard from "./ContactUsCard";

const CARTA_APP_LOGIN_URL = CONFIGURATION.CARTA_APP_LOGIN_URL;

const ORGANIZATION_FRAGMENT = graphql`
  fragment TestLawFirmCartaConnectionView_Organization on Organization {
    id
    ...RemoteEquityOnboardingLayout_Organization
    ...useTrackRemoteEquityOnboardingFlowEvent_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment TestLawFirmCartaConnectionView_Viewer on Account {
    ...RemoteEquityOnboardingLayout_Viewer
  }
`;

const SET_TEST_LAW_FIRM_CARTA_CONNECTION_ONBOARDING_AS_COMPLETED_MUTATION = graphql`
  mutation TestLawFirmCartaConnectionView_SetTestLawFirmCartaConnectionOnboardingAsCompleted_Mutation(
    $organizationId: OrganizationId!
  ) {
    setTestLawFirmCartaConnectionOnboardingAsCompleted(
      organizationId: $organizationId
    ) {
      currentRemoteOnboardingStep
    }
  }
`;

const HAS_ORGANIZATION_ADDED_EASOP_AS_CARTA_LEGAL_ADMIN_QUERY = graphql`
  query TestLawFirmCartaConnectionView_HasOrganizationAddedEasopAsCartaLegalAdmin_Query(
    $organizationId: OrganizationId!
  ) {
    isRemoteEquityOrganizationCartaLawFirm(organizationId: $organizationId) {
      __typename
      ... on IsRemoteEquityOrganizationCartaLawFirmFailure {
        __typename
      }
      ... on IsRemoteEquityOrganizationCartaLawFirmSuccess {
        remoteEquityIsOrganizationCartaLawFirm
      }
    }
  }
`;

const TestConnectionResultMessage: React.FC<{
  result: "failure" | "success";
}> = ({ result }) => {
  switch (result) {
    case "failure":
      return (
        <NoticeMessage
          size="Small"
          title="We couldn’t verify Remote Equity’s connection to your Carta account"
          variant="Warning"
        >
          Wait a few moments, then repeat step 1 to try adding Remote Equity as
          a law firm in Carta again.
        </NoticeMessage>
      );
    case "success":
      return (
        <NoticeMessage size="Small" variant="Positive">
          Connection verified! Remote Equity has been added as a law firm in
          your Carta account.
        </NoticeMessage>
      );
  }
};

export const TestLawFirmCartaConnectionView: React.FC<{
  onNextButtonClick?: () => void;
  organizationFragment: TestLawFirmCartaConnectionView_Organization$key;
  skippable?: boolean;
  viewerFragment: TestLawFirmCartaConnectionView_Viewer$key;
}> = ({
  onNextButtonClick: _onNextButtonClick,
  organizationFragment,
  skippable,
  viewerFragment,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  const trackEvent = useTrackRemoteEquityOnboardingFlowEvent({
    organizationFragment: organization,
  });

  const [hasOrganizationAddedEasopAsCartaLegalAdmin, checkingConnection] =
    useManualQuery<TestLawFirmCartaConnectionView_HasOrganizationAddedEasopAsCartaLegalAdmin_Query>(
      HAS_ORGANIZATION_ADDED_EASOP_AS_CARTA_LEGAL_ADMIN_QUERY,
    );

  const [setTestLawFirmCartaConnectionOnboardingAsCompleted] =
    useSafeMutation<TestLawFirmCartaConnectionView_SetTestLawFirmCartaConnectionOnboardingAsCompleted_Mutation>(
      SET_TEST_LAW_FIRM_CARTA_CONNECTION_ONBOARDING_AS_COMPLETED_MUTATION,
    );

  const onNextButtonClick = useCallback(async () => {
    await setTestLawFirmCartaConnectionOnboardingAsCompleted({
      variables: { organizationId: organization.id },
    });
    _onNextButtonClick?.();
  }, [
    organization.id,
    setTestLawFirmCartaConnectionOnboardingAsCompleted,
    _onNextButtonClick,
  ]);

  const onSkipButtonClick = useCallback(async () => {
    trackEvent("Law Firm skipped");
    await onNextButtonClick();
  }, [trackEvent, onNextButtonClick]);

  const [testConnectionResult, setTestConnectionResult] = useState<
    "failure" | "success" | undefined
  >();

  const onTestConnectionClick = useCallback(async () => {
    const { isRemoteEquityOrganizationCartaLawFirm: result } =
      await new Promise<
        TestLawFirmCartaConnectionView_HasOrganizationAddedEasopAsCartaLegalAdmin_Query["response"]
      >((resolve, reject) =>
        hasOrganizationAddedEasopAsCartaLegalAdmin({
          onError: (error) => {
            reject(error);
          },
          onResponse: (response) => {
            resolve(response);
          },
          variables: {
            organizationId: organization.id,
          },
        }),
      ).catch(() => ({
        isRemoteEquityOrganizationCartaLawFirm: null,
      }));
    if (
      result &&
      result.__typename === "IsRemoteEquityOrganizationCartaLawFirmSuccess" &&
      result.remoteEquityIsOrganizationCartaLawFirm
    ) {
      setTestConnectionResult("success");
      trackEvent("Law Firm added");
    } else {
      setTestConnectionResult("failure");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasOrganizationAddedEasopAsCartaLegalAdmin, organization.id]);

  return (
    <RemoteEquityOnboardingLayout
      organizationFragment={organization}
      subtitle={
        <div className="max-w-[473px]">
          To perform your cap table health check and implement new grants and
          terminations in Carta, Remote Equity needs access as a law firm in
          your Carta workspace.
        </div>
      }
      title={
        <div className="max-w-[416px]">
          Add Remote Equity as a law firm in Carta
        </div>
      }
      viewerFragment={viewer}
    >
      <div className="w-full max-w-[752px]">
        <div className="space-y-8">
          <div className="space-y-2 rounded-remote-md bg-remote-white p-2">
            <div className="flex items-center gap-4 px-6 pt-4">
              <div className="flex-1">
                <div className="font-remote-brand text-remote-LG/Medium text-remote-grey-800">
                  1. Add Remote Equity as law firm
                </div>
                <div className="text-remote-SM/Normal text-remote-grey-700">
                  Follow the instructions below to connect Carta to Remote.
                </div>
              </div>
              <CartaLogo className="h-6" />
            </div>
            <div className="rounded-lg bg-remote-grey-50 px-4 py-2">
              <ListNumbers shape="simple">
                <li>
                  <span>
                    Log in to your{" "}
                    <StandaloneLink
                      as="a"
                      className="!underline"
                      href={CARTA_APP_LOGIN_URL}
                      IconAfter={IconV2OutlineArrowUpRight}
                      target="_blank"
                    >
                      Carta account
                    </StandaloneLink>
                  </span>
                </li>
                <li>
                  <span>
                    Navigate to the{" "}
                    <span className="font-medium">Company settings</span>{" "}
                    section, and select{" "}
                    <span className="font-medium">Users and permissions</span>.
                  </span>
                </li>
                <li>
                  <span>
                    In the top navigation bar, select{" "}
                    <span className="font-medium">Law firms</span>.
                  </span>
                </li>
                <li>
                  <span>
                    Select <span className="font-medium">Add law firm</span>.
                  </span>
                </li>
                <li>
                  <span>
                    Select <span className="font-medium">Remote Equity</span>{" "}
                    from the dropdown list.
                  </span>
                </li>
                <li>
                  <span>
                    Click the <span className="font-medium">Add law firm</span>{" "}
                    button to confirm the addition.
                  </span>
                </li>
              </ListNumbers>
            </div>
          </div>
          <div className="space-y-6 rounded-remote-md bg-remote-white p-2">
            <div className="px-6 pt-4">
              <div className="font-remote-brand text-remote-LG/Medium text-remote-grey-800">
                2. Verify connection
              </div>
              <div className="text-remote-SM/Normal text-remote-grey-700">
                Make sure Remote Equity is fully connected to Carta.
              </div>
            </div>
            <div className="space-y-4 rounded-lg bg-remote-grey-50 p-6">
              <div className="flex items-center justify-between gap-3">
                <div className="max-w-[368px] flex-1">
                  <Text color="grey.700" variant="sm">
                    Test the connection to make sure Remote Equity has
                    successfully been added as a law firm in Carta.
                  </Text>
                </div>

                <Button
                  disabled={testConnectionResult === "success"}
                  isLoading={checkingConnection}
                  onClick={onTestConnectionClick}
                  size="md"
                >
                  Test connection
                </Button>
              </div>
              {testConnectionResult && (
                <TestConnectionResultMessage result={testConnectionResult} />
              )}
            </div>
          </div>
          <ContactUsCard />
          <div className="flex items-center justify-center gap-6">
            {skippable && (
              <Button onClick={onSkipButtonClick} size="md" variant="outline">
                Skip this step
              </Button>
            )}
            <Button
              disabled={testConnectionResult !== "success"}
              IconAfter={IconV2OutlineArrowRight}
              onClick={() => onNextButtonClick()}
              size="md"
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
    </RemoteEquityOnboardingLayout>
  );
};
