import {
  Bars3Icon,
  ChevronUpIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Pill, Text } from "@remote-com/norma";
import classNames from "classnames";
import { motion } from "motion/react";
import React, { useCallback, useEffect } from "react";
import { useFragment } from "react-relay";
import { NavLink as RouterNavLink, To, useLocation } from "react-router-dom";
import { graphql } from "relay-runtime";

import { useBoolean } from "../../../hooks/useBoolean";
import { OrganizationRoleSwitcher, Role } from "../../OrganizationRoleSwitcher";
import { Modal } from "../Modal";
import { ApplicationSideBar_Organization$key } from "./__generated__/ApplicationSideBar_Organization.graphql";
import { ApplicationSideBar_Viewer$key } from "./__generated__/ApplicationSideBar_Viewer.graphql";
import { ApplicationSideBarContent } from "./ApplicationSideBarContent";
import { LogoLink } from "./LogoLink";

interface Icons {
  ActiveIcon: React.ReactElement<React.ComponentProps<"svg">>;
  Icon: React.ReactElement<React.ComponentProps<"svg">>;
}

const Section: React.FC<{
  children: React.ReactNode;
  title: string;
}> = ({ children, title }) => {
  return (
    <div className="flex flex-col">
      <Text
        className="px-2 pb-0.5 pt-2.5 uppercase text-grey-600"
        variant="2xsMedium"
      >
        {title}
      </Text>
      {children}
    </div>
  );
};

const useNavLinkClassNames = () => {
  return useCallback(
    ({
      hoverable,
      isActive,
      isSubLink,
    }: {
      hoverable: boolean;
      isActive: boolean;
      isSubLink: boolean;
    }) =>
      classNames(
        "rounded-lg pr-2 py-[7px] transition-all relative",
        isActive && "border-brand-600 font-medium text-brand-600",
        !isActive && "text-grey-900 border-grey-300",
        hoverable && "hover:text-brand-600",
        isSubLink && [
          "pl-4 before:bg-grey-300 before:w-[1px] before:absolute before:inset-0 first:before:top-1.5 last:before:bottom-1.5",
          {
            "after:bg-brand-600 after:w-[1px] after:absolute after:inset-0 after:top-1.5 after:bottom-1.5":
              isActive,
          },
        ],
        !isSubLink && [
          "pl-2",
          {
            "bg-brand-50": isActive,
            "pl-2.5 hover:bg-brand-50": hoverable,
          },
        ],
      ),
    [],
  );
};

const PaidTag: React.FC = () => <Pill>Paid</Pill>;

const NavLinkContent: React.FC<{
  active?: boolean;
  children: React.ReactNode;
  count?: number;
  icons?: Icons | null;
  paid?: boolean;
  tag?: null | React.ReactNode;
}> = ({ active, children, count = 0, icons, paid, tag }) => {
  const icon = active ? icons?.ActiveIcon : icons?.Icon;

  return (
    <div className="flex items-center gap-2">
      {icon && (
        <div>
          {React.cloneElement(icon, {
            className: classNames("h-3.5 w-3.5", icon.props.className),
            ...icon.props,
          })}
        </div>
      )}
      <div className="flex flex-grow items-center justify-between">
        <div className="flex items-center gap-2">
          <Text as="div" variant={active ? "smMedium" : "sm"}>
            {children}
          </Text>
          {paid && <PaidTag />}
          {tag && <Pill tone="purple">{tag}</Pill>}
        </div>
        {count > 0 && (
          <Text
            as="div"
            className="flex h-5.5 w-5.5 min-w-fit items-center justify-center rounded bg-brand-100 text-brand-700"
            variant="2xsSemiBold"
          >
            {count}
          </Text>
        )}
      </div>
    </div>
  );
};

const NavLink: React.FC<
  Omit<
    React.ComponentPropsWithoutRef<typeof RouterNavLink>,
    "children" | "className" | "to"
  > & {
    children: React.ReactNode;
    className?: string;
    count?: number;
    icons?: Icons | null;
    isSubLink?: boolean;
    paid?: boolean;
    tag?: React.ReactNode;
    to?: To;
  }
> = ({
  children,
  className,
  count = 0,
  icons,
  isSubLink = false,
  paid = false,
  tag = null,
  to,
  ...props
}) => {
  const navLinkClassNames = useNavLinkClassNames();

  if (!to) {
    return (
      <div
        className={classNames(
          navLinkClassNames({ hoverable: false, isActive: false, isSubLink }),
          className,
          "cursor-not-allowed",
        )}
      >
        <NavLinkContent count={count} icons={icons} paid={paid} tag={tag}>
          {children}
        </NavLinkContent>
      </div>
    );
  }

  return (
    <RouterNavLink
      className={({ isActive }) =>
        classNames(
          navLinkClassNames({ hoverable: true, isActive, isSubLink }),
          className,
        )
      }
      {...props}
      to={to}
    >
      {({ isActive }) => (
        <NavLinkContent
          active={isActive}
          count={count}
          icons={icons}
          paid={paid}
          tag={tag}
        >
          {children}
        </NavLinkContent>
      )}
    </RouterNavLink>
  );
};

function ActiveLinkBackground({ layoutId }: { layoutId: string }) {
  return (
    <motion.div
      className="absolute inset-0 -z-10 rounded-lg bg-brand-50"
      layout
      layoutId={layoutId}
    />
  );
}

const NavAnchorLink: React.FC<
  React.ComponentPropsWithoutRef<"a"> & {
    active?: boolean;
    children: React.ReactNode;
    className?: string;
    count?: number;
    icons?: Icons | null;
    isSubLink?: boolean;
    layoutId: string;
    paid?: boolean;
    tag?: React.ReactNode;
  }
> = ({
  active = false,
  children,
  className,
  count = 0,
  href,
  icons,
  isSubLink = false,
  layoutId,
  paid = false,
  tag = null,
  ...props
}) => {
  const navLinkClassNames = useNavLinkClassNames();

  if (!href) {
    return (
      <div
        className={classNames(
          navLinkClassNames({ hoverable: false, isActive: false, isSubLink }),
          className,
          "cursor-not-allowed",
        )}
      >
        {active && <ActiveLinkBackground layoutId={layoutId} />}
        <NavLinkContent
          active={active}
          count={count}
          icons={icons}
          paid={paid}
          tag={tag}
        >
          {children}
        </NavLinkContent>
      </div>
    );
  }

  return (
    <a
      className={classNames(
        navLinkClassNames({ hoverable: true, isActive: active, isSubLink }),
        className,
      )}
      {...props}
      href={href}
    >
      {active && <ActiveLinkBackground layoutId={layoutId} />}
      <NavLinkContent
        active={active}
        count={count}
        icons={icons}
        paid={paid}
        tag={tag}
      >
        {children}
      </NavLinkContent>
    </a>
  );
};

const NavButton: React.FC<{
  children: React.ReactNode;
  icons?: Icons | null;
  loading?: boolean;
  onClick: () => void;
}> = ({ children, icons, loading, onClick }) => {
  const navLinkClassNames = useNavLinkClassNames();
  return (
    <button
      className={classNames(
        navLinkClassNames({
          hoverable: true,
          isActive: false,
          isSubLink: false,
        }),
        {
          "animate-pulse bg-gray-02": loading,
        },
      )}
      onClick={onClick}
      type="button"
    >
      <NavLinkContent icons={icons}>{children}</NavLinkContent>
    </button>
  );
};

const FoldableItem: React.FC<{
  children: React.ReactNode;
  className?: string;
  count?: number;
  icons?: Icons | null;
  isOpen: boolean;
  label: React.ReactNode;
  onToggle: () => void;
  tag?: React.ReactNode;
}> = ({ children, count = 0, icons, isOpen, label, onToggle, tag }) => {
  const navLinkClassNames = useNavLinkClassNames();

  return (
    <>
      <button
        className={classNames(
          navLinkClassNames({
            hoverable: true,
            isActive: false,
            isSubLink: false,
          }),
          "flex items-center gap-4",
        )}
        onClick={onToggle}
        type="button"
      >
        <div className="flex-1">
          <NavLinkContent count={count} icons={icons} tag={tag}>
            {label}
          </NavLinkContent>
        </div>

        <ChevronUpIcon
          className={classNames(
            "w-4 transition-all",
            isOpen ? "" : "-rotate-180",
          )}
        />
      </button>
      {isOpen && <div className="flex flex-col pl-4">{children}</div>}
    </>
  );
};

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="flex h-full flex-col bg-white md:flex-row">{children}</div>
);

const VIEWER_FRAGMENT = graphql`
  fragment ApplicationSideBar_Viewer on Account {
    # eslint-disable-next-line relay/must-colocate-fragment-spreads
    ...ApplicationSideBarContent_Viewer
    # eslint-disable-next-line relay/must-colocate-fragment-spreads
    ...OrganizationRoleSwitcher_Account
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment ApplicationSideBar_Organization on Organization {
    # eslint-disable-next-line relay/must-colocate-fragment-spreads
    ...ApplicationSideBarContent_Organization
    # eslint-disable-next-line relay/must-colocate-fragment-spreads
    ...OrganizationRoleSwitcher_Organization
  }
`;

const ApplicationSideBar_: React.FC<
  React.PropsWithChildren<{
    activeRole: null | Role;
    bottomFixedContent?: React.ReactNode;
    className?: string;
    homePath?: React.ComponentProps<typeof NavLink>["to"];
    organizationFragment: ApplicationSideBar_Organization$key | null;
    showLogo?: boolean;
    showSideBarHeader?: boolean;
    viewerFragment: ApplicationSideBar_Viewer$key;
  }>
> = ({
  activeRole,
  bottomFixedContent,
  children,
  className,
  homePath,
  organizationFragment,
  showSideBarHeader,
  viewerFragment,
}) => {
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  const organization =
    useFragment(ORGANIZATION_FRAGMENT, organizationFragment) ?? null;

  const {
    setFalse: hideMobileNavigationModal,
    setTrue: showMobileNavigationModal,
    value: mobileNavigationModalIsShown,
  } = useBoolean(false);

  const location = useLocation();

  // Needed to close modal when clicking on a link
  useEffect(() => {
    hideMobileNavigationModal();
  }, [hideMobileNavigationModal, location]);

  return (
    <>
      <Modal.Raw
        onClose={hideMobileNavigationModal}
        panelClassName="w-screen h-screen overflow-y-auto py-6 px-4"
        show={mobileNavigationModalIsShown}
        suspense
      >
        <div className="flex items-center gap-4 !pb-0">
          <div className="flex flex-grow items-center gap-4">
            <LogoLink homePath={homePath} />
            {organization && activeRole && (
              <OrganizationRoleSwitcher
                accountFragment={viewer}
                activeRole={activeRole}
                organizationFragment={organization}
              />
            )}
          </div>
          <button
            className="h-6 w-6 self-start"
            onClick={() => {
              hideMobileNavigationModal();
            }}
          >
            <XMarkIcon className="text-black-07" />
          </button>
        </div>
        <ApplicationSideBarContent
          activeRole={activeRole}
          className={classNames(className, "h-auto w-full px-0 shadow-none")}
          homePath={homePath}
          organizationFragment={organization}
          showHeader={false}
          viewerFragment={viewer}
        >
          {children}
        </ApplicationSideBarContent>
      </Modal.Raw>
      <div className="z-0 flex justify-between bg-white px-4 py-5 shadow-100 md:hidden">
        <LogoLink homePath={homePath} />
        <button onClick={showMobileNavigationModal} type="button">
          <Bars3Icon className="h-6 w-6" />
        </button>
      </div>
      <div className="flex flex-col border-r border-grey-200 bg-gray-01 md:h-screen">
        <ApplicationSideBarContent
          activeRole={activeRole}
          className={classNames(
            className,
            "hidden w-[240px] flex-grow px-4 md:flex",
          )}
          homePath={homePath}
          organizationFragment={organization}
          showHeader={showSideBarHeader}
          viewerFragment={viewer}
        >
          {children}
        </ApplicationSideBarContent>
        {bottomFixedContent && (
          <div className="hidden shrink-0 p-4 md:block">
            {bottomFixedContent}
          </div>
        )}
      </div>
    </>
  );
};

export const ApplicationSideBar = Object.assign(ApplicationSideBar_, {
  FoldableItem,
  Layout,
  NavAnchorLink,
  NavButton,
  NavLink,
  Section,
});
