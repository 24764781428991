/**
 * @generated SignedSource<<bbcf888556bfc632500d7e3aa48bafb9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquityHandbooksCountryEquityTypePage_Query$variables = {
  equityTypeId: string;
};
export type EquityHandbooksCountryEquityTypePage_Query$data = {
  readonly equityType: {
    readonly eoREmployeeHandbook: {
      readonly published: boolean;
      readonly " $fragmentSpreads": FragmentRefs<"EquityHandbooksCountryEquityTypeContent_EquityTypeHandbook">;
    } | null;
  } | null;
};
export type EquityHandbooksCountryEquityTypePage_Query = {
  response: EquityHandbooksCountryEquityTypePage_Query$data;
  variables: EquityHandbooksCountryEquityTypePage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "equityTypeId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "equityTypeId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "published",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "enabled",
    "value": true
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EquityHandbooksCountryEquityTypePage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EquityType",
        "kind": "LinkedField",
        "name": "equityType",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EquityTypeHandbook",
            "kind": "LinkedField",
            "name": "eoREmployeeHandbook",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EquityHandbooksCountryEquityTypeContent_EquityTypeHandbook"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EquityHandbooksCountryEquityTypePage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EquityType",
        "kind": "LinkedField",
        "name": "equityType",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EquityTypeHandbook",
            "kind": "LinkedField",
            "name": "eoREmployeeHandbook",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "EquityTypeHandbookSectionEvent",
                "kind": "LinkedField",
                "name": "sectionEvents",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "section",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "event",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nothingToDeclare",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "concreteType": "EquityTypeHandbookSectionEventItem",
                    "kind": "LinkedField",
                    "name": "items",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "category",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "content",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": "items(enabled:true)"
                  },
                  (v4/*: any*/)
                ],
                "storageKey": "sectionEvents(enabled:true)"
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ce55a29f1e168902f7fa9fa847fb51d0",
    "id": null,
    "metadata": {},
    "name": "EquityHandbooksCountryEquityTypePage_Query",
    "operationKind": "query",
    "text": "query EquityHandbooksCountryEquityTypePage_Query(\n  $equityTypeId: UUID!\n) {\n  equityType(id: $equityTypeId) {\n    eoREmployeeHandbook {\n      published\n      ...EquityHandbooksCountryEquityTypeContent_EquityTypeHandbook\n      id\n    }\n    id\n  }\n}\n\nfragment EquityHandbooksCountryEquityTypeContent_EquityTypeHandbook on EquityTypeHandbook {\n  sectionEvents(enabled: true) {\n    section\n    event\n    nothingToDeclare\n    items(enabled: true) {\n      category\n      content\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a9165333c9b568eeec26ecb95580c9d8";

export default node;
