/**
 * @generated SignedSource<<a3eed53b3884fad5b31ac58d60f13fe4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquityTypeHandbookEdition_EquityType$data = {
  readonly id: string;
  readonly " $fragmentType": "EquityTypeHandbookEdition_EquityType";
};
export type EquityTypeHandbookEdition_EquityType$key = {
  readonly " $data"?: EquityTypeHandbookEdition_EquityType$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquityTypeHandbookEdition_EquityType">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquityTypeHandbookEdition_EquityType",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "EquityType",
  "abstractKey": null
};

(node as any).hash = "06c8a01dcf21af6f1736119353abbcee";

export default node;
