import { graphql, useFragment } from "react-relay";

import { MainSection_EssentialsOverview_Organization$key } from "./__generated__/MainSection_EssentialsOverview_Organization.graphql";
import { EsopPoolProgressCard } from "./EsopPoolProgressCard";
import { FairMarketValueHistoryCard } from "./FairMarketValueHistoryCard";
import { FinishSetupCard } from "./FinishSetupCard";
import { SharesOutstandingCard } from "./SharesOutstandingCard";
import { TakeActionCard } from "./TakeActionCard";

const ORGANIZATION_FRAGMENT = graphql`
  fragment MainSection_EssentialsOverview_Organization on Organization {
    ...FinishSetupCard_EssentialsOverview_Organization
    ...TakeActionCard_EssentialsOverview_Organization
    ...EsopPoolProgressCard_EssentialsOverview_Organization
    ...SharesOutstandingCard_EssentialsOverview_Organization
    ...FairMarketValueHistoryCard_EssentialsOverview_Organization
  }
`;

export const MainSection: React.FC<{
  organizationFragment: MainSection_EssentialsOverview_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <main className="space-y-6">
      <FinishSetupCard organizationFragment={organization} />
      <TakeActionCard organizationFragment={organization} />
      <EsopPoolProgressCard organizationFragment={organization} />
      <SharesOutstandingCard organizationFragment={organization} />
      <FairMarketValueHistoryCard organizationFragment={organization} />
    </main>
  );
};
