/**
 * @generated SignedSource<<68808dcaf50c6bc647fd9f82951d4048>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExercisesView_Organization$data = {
  readonly ctmsExerciseRequests: ReadonlyArray<{
    readonly ctmsId: string;
    readonly " $fragmentSpreads": FragmentRefs<"ExercisesFilters_Exercises" | "ExercisesTable_Exercises">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"ExercisesFilters_Organization" | "ExercisesTable_Organization">;
  readonly " $fragmentType": "ExercisesView_Organization";
};
export type ExercisesView_Organization$key = {
  readonly " $data"?: ExercisesView_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExercisesView_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExercisesView_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExercisesTable_Organization"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CTMSExerciseRequest",
      "kind": "LinkedField",
      "name": "ctmsExerciseRequests",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ctmsId",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ExercisesFilters_Exercises"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ExercisesTable_Exercises"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExercisesFilters_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "e60b31174f5cfea4cf9d13bf2d048edf";

export default node;
