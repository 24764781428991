import { Text } from "@remote-com/norma";
import classNames from "classnames";
import React from "react";
import { generatePath, Outlet } from "react-router-dom";

import { SuspenseWithLoadingFallback } from "../../components/LoadingPlaceholder";
import { LinkButton } from "../../components/ui/Button";
import { Logo } from "../../components/ui/Logo/Logo";
import { Typography } from "../../components/ui/Typography";
import { APPLICATION_ROUTES } from "../../paths";
import { useAuthentication } from "../../services/AuthenticationProvider";

const Card: React.FC<
  React.PropsWithChildren<{
    emoji?: React.ReactNode;
    extraLarge?: boolean;
    logo: null | React.ReactElement<React.ComponentProps<"svg">>;
    subtitle?: React.ReactNode;
    title?: React.ReactNode;
  }>
> = ({ children, emoji, extraLarge, logo, subtitle, title }) => (
  <div
    className={classNames(
      "relative mx-auto w-full space-y-8 rounded-lg bg-white px-6 md:px-10",
      {
        "max-w-[520px] py-10": !extraLarge,
        "max-w-[600px] py-20": extraLarge,
      },
    )}
  >
    <div className="space-y-6">
      {logo && (
        <div className="flex justify-center">
          {React.cloneElement(logo, {
            className: classNames(logo.props.className, /* tailwind */ `h-8`),
          })}
        </div>
      )}
      <div className="space-y-2 text-center">
        {emoji && (
          <Typography as="div" variant="Medium/Extra Large">
            {emoji}
          </Typography>
        )}
        {title && (
          <Text as="div" variant={extraLarge ? "4xlMedium" : "2xlMedium"}>
            {title}
          </Text>
        )}
        {subtitle && (
          <Text as="div" className="text-black-05" variant="sm">
            {subtitle}
          </Text>
        )}
      </div>
    </div>
    {children}
  </div>
);

const AuthLayout_: React.FC<{
  children?: React.ReactNode;
  hideSignInButton?: boolean;
}> = ({ children = <Outlet />, hideSignInButton: _hideSignInButton }) => {
  const authentication = useAuthentication();

  const hideSignInButton = _hideSignInButton || !!authentication.account;

  return (
    <main
      className={classNames(
        "flex min-h-full bg-remote-background-base font-remote-body",
      )}
    >
      <div className="fixed inset-0 z-10 flex h-fit items-center justify-between bg-white px-6 py-4">
        <Logo
          className="h-6"
          useEasopXRemoteEquityLogoInsteadOfEasopOnly
          variant="black"
        />
        {!hideSignInButton && (
          <div className="flex items-center gap-2">
            <Typography className="text-black-05" variant="Regular/Small">
              Already have an account?
            </Typography>
            <LinkButton
              size="extra small"
              to={generatePath(APPLICATION_ROUTES.roleSelection)}
              variant="Secondary Full"
            >
              Log in
            </LinkButton>
          </div>
        )}
      </div>

      <div className="mt-14 w-full items-center justify-center py-8 lg:flex">
        <SuspenseWithLoadingFallback>{children}</SuspenseWithLoadingFallback>
      </div>
    </main>
  );
};

const AuthLayout = Object.assign(AuthLayout_, { Card });

export default AuthLayout;
