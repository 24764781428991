/**
 * @generated SignedSource<<c7c12f516e687549891694f9a05f0989>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConnectCTMSView_Viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ContactUsModal_Viewer" | "RemoteEquityOnboardingLayout_Viewer">;
  readonly " $fragmentType": "ConnectCTMSView_Viewer";
};
export type ConnectCTMSView_Viewer$key = {
  readonly " $data"?: ConnectCTMSView_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConnectCTMSView_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConnectCTMSView_Viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContactUsModal_Viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemoteEquityOnboardingLayout_Viewer"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "be6365e35219dde5e4154b3799b26a1d";

export default node;
