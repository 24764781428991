import { zodResolver } from "@hookform/resolvers/zod";
import { Pill } from "@remote-com/norma";
import { useId } from "react";
import { DeepPartial, useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import { generatePath, useNavigate } from "react-router-dom";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { FormattedCurrency } from "../../../components/Formatted/FormattedCurrency";
import { Page } from "../../../components/Page";
import { ShortDate } from "../../../components/ShortDate";
import { Button } from "../../../components/ui/Button";
import {
  FileInput,
  useFileInputFormUploadController,
} from "../../../components/ui/Form/FileInput";
import { FormRow } from "../../../components/ui/Form/FormRow";
import { RemoteInputCurrency } from "../../../components/ui/Form/Inputs/RemoteInputCurrency";
import { RemoteInputDatePicker } from "../../../components/ui/Form/Inputs/RemoteInputDatePicker";
import {
  CheckoutLikeLayout,
  CheckoutLikeLayoutAside,
  CheckoutLikeLayoutAsideSection,
  CheckoutLikeLayoutAsideSectionItem,
  CheckoutLikeLayoutMainCard,
  CheckoutLikeLayoutMainSection,
  CheckoutLikeLayoutMainTitle,
} from "../../../components/ui/Layout/CheckoutLikeLayout";
import { useQuery } from "../../../hooks/useQuery";
import { useSafeMutation } from "../../../hooks/useSafeMutation";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { AddNewFairMarketValuePage_AddNewFairMarketValueToEssentialsOrganization_Mutation } from "./__generated__/AddNewFairMarketValuePage_AddNewFairMarketValueToEssentialsOrganization_Mutation.graphql";
import { AddNewFairMarketValuePage_Organization$key } from "./__generated__/AddNewFairMarketValuePage_Organization.graphql";
import { AddNewFairMarketValuePage_Query } from "./__generated__/AddNewFairMarketValuePage_Query.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment AddNewFairMarketValuePage_Organization on Organization {
    id
    latestFairMarketValue {
      value
    }
    ...FormattedCurrency_Organization
    ...RemoteInputCurrency_Organization
  }
`;

const ADD_NEW_FAIR_MARKET_VALUE_TO_ESSENTIALS_ORGANIZATION_MUTATION = graphql`
  mutation AddNewFairMarketValuePage_AddNewFairMarketValueToEssentialsOrganization_Mutation(
    $organizationId: OrganizationId!
    $attributes: AddNewFairMarketValueToEssentialsOrganizationAttributes!
  ) {
    addNewFairMarketValueToEssentialsOrganization(
      organizationId: $organizationId
      attributes: $attributes
    ) {
      __typename
    }
  }
`;

const FORM_SCHEMA = z.object({
  date: z.string().date(),
  documentId: z.string().optional(),
  value: z.coerce.number().positive(),
});

type FormInput = DeepPartial<z.input<typeof FORM_SCHEMA>>;
type FormOutput = z.output<typeof FORM_SCHEMA>;

const AddNewFairMarketValuePage_: React.FC<{
  organizationFragment: AddNewFairMarketValuePage_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const formId = useId();

  const form = useForm<FormInput, unknown, FormOutput>({
    resolver: zodResolver(FORM_SCHEMA),
  });

  const [value, date] = form.watch(["value", "date"]);

  const fileInputUploadController = useFileInputFormUploadController({
    control: form.control,
    multiple: false,
    name: "documentId",
  });

  const [addNewFairMarketValueToEssentialsOrganization, mutationIsInFlight] =
    useSafeMutation<AddNewFairMarketValuePage_AddNewFairMarketValueToEssentialsOrganization_Mutation>(
      ADD_NEW_FAIR_MARKET_VALUE_TO_ESSENTIALS_ORGANIZATION_MUTATION,
    );

  const navigate = useNavigate();

  const onSubmit = form.handleSubmit(async (data) => {
    await addNewFairMarketValueToEssentialsOrganization({
      variables: {
        attributes: data,
        organizationId: organization.id,
      },
    });

    await navigate(
      generatePath(
        APPLICATION_ROUTES.organizationCompanySettingsFairMarketValue,
        {
          organizationId: organization.id,
        },
      ),
      {
        replace: true,
      },
    );
  });

  return (
    <CheckoutLikeLayout
      aside={
        <CheckoutLikeLayoutAside title="Details">
          <CheckoutLikeLayoutAsideSection>
            {organization.latestFairMarketValue && (
              <CheckoutLikeLayoutAsideSectionItem title="Current value">
                <FormattedCurrency
                  animated
                  organizationFragment={organization}
                  value={organization.latestFairMarketValue.value}
                />
              </CheckoutLikeLayoutAsideSectionItem>
            )}
            <CheckoutLikeLayoutAsideSectionItem title="New value">
              {value && (
                <FormattedCurrency
                  animated
                  organizationFragment={organization}
                  value={value}
                />
              )}
            </CheckoutLikeLayoutAsideSectionItem>
            <CheckoutLikeLayoutAsideSectionItem title="Validity date">
              {date && <ShortDate value={date} />}
            </CheckoutLikeLayoutAsideSectionItem>
            <CheckoutLikeLayoutAsideSectionItem title="Document">
              {fileInputUploadController.inputProps.items[0]?.fileName}
            </CheckoutLikeLayoutAsideSectionItem>
          </CheckoutLikeLayoutAsideSection>
        </CheckoutLikeLayoutAside>
      }
      navigationBreadcrumbs={[
        {
          label: "Company settings",
          to: generatePath(APPLICATION_ROUTES.organizationCompanySettings, {
            organizationId: organization.id,
          }),
        },
        {
          label: "Fair market value",
          to: generatePath(
            APPLICATION_ROUTES.organizationCompanySettingsFairMarketValue,
            {
              organizationId: organization.id,
            },
          ),
        },
      ]}
      pageTitle="Add new fair market value"
    >
      <CheckoutLikeLayoutMainCard>
        <CheckoutLikeLayoutMainTitle
          description="Fair market value is needed for taxes, security, and compliance. Make sure these details match your company’s legal documents."
          illustration={<Pill tone="blueLight">fair market value</Pill>}
          title="Add new fair market value"
        />
        <form id={formId} onSubmit={onSubmit}>
          <CheckoutLikeLayoutMainSection
            subtitle="Input the new fair market value"
            title="New value"
          >
            <RemoteInputCurrency.Form
              control={form.control}
              label="New fair market value"
              locale="en-US"
              name="value"
              organizationFragment={organization}
            />
            <RemoteInputDatePicker.Form
              control={form.control}
              label="Validity date"
              name="date"
            />
          </CheckoutLikeLayoutMainSection>
          <CheckoutLikeLayoutMainSection
            subtitle="Upload the audit report that actually proves the price change."
            title="Upload audit report"
          >
            <FormRow.Form control={form.control} name="documentId">
              <FileInput {...fileInputUploadController.inputProps} />
            </FormRow.Form>
          </CheckoutLikeLayoutMainSection>

          <Button
            className="mt-10"
            form={formId}
            fullWidth
            loading={mutationIsInFlight}
            type="submit"
          >
            Save
          </Button>
        </form>
      </CheckoutLikeLayoutMainCard>
    </CheckoutLikeLayout>
  );
};

const QUERY = graphql`
  query AddNewFairMarketValuePage_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...AddNewFairMarketValuePage_Organization
    }
  }
`;

export const AddNewFairMarketValuePage: React.FC = () => {
  const organizationId = useOrganizationIdParam();

  const {
    query: { organization },
  } = useQuery<AddNewFairMarketValuePage_Query>(QUERY, {
    organizationId,
  });

  if (!organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Admin Company Settings"
      analyticsName="Admin - Company Settings - Add new fair market value"
      organizationId={organization.id}
      title={`Admin | ${organization.name} - Add new fair market value`}
    >
      <AddNewFairMarketValuePage_ organizationFragment={organization} />
    </Page>
  );
};
