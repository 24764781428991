import { graphql, useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";

import { APPLICATION_ROUTES } from "../paths";
import { RepricedToCtmsGrantNoticeMessage_CtmsGrant$key } from "./__generated__/RepricedToCtmsGrantNoticeMessage_CtmsGrant.graphql";
import { DeclarationNoticeMessage } from "./DeclarationNoticeMessage";

const GRANT_FRAGMENT = graphql`
  fragment RepricedToCtmsGrantNoticeMessage_CtmsGrant on CTMSGrant {
    id
    label
    organizationId
  }
`;

export const RepricedToCtmsGrantNoticeMessage: React.FC<{
  ctmsGrantFragment: RepricedToCtmsGrantNoticeMessage_CtmsGrant$key;
}> = ({ ctmsGrantFragment }) => {
  const ctmsGrant = useFragment(GRANT_FRAGMENT, ctmsGrantFragment);

  return (
    <DeclarationNoticeMessage variant="warning">
      This grant was repriced to{" "}
      <Link
        className="font-medium text-brand-600"
        to={generatePath(APPLICATION_ROUTES.organizationEquityCtmsGrant, {
          ctmsGrantId: ctmsGrant.id,
          organizationId: ctmsGrant.organizationId,
        })}
      >
        {ctmsGrant.label}
      </Link>
    </DeclarationNoticeMessage>
  );
};
