import { Button } from "@remote-com/norma";
import { useCallback } from "react";
import { useFragment } from "react-relay";
import { generatePath, useNavigate } from "react-router-dom";
import { graphql } from "relay-runtime";

import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import { APPLICATION_ROUTES } from "../../../../paths";
import { EssentialsOnboardingTemplate } from "../EssentialsOnboardingTemplate";
import { LastStepPage_Organization$key } from "./__generated__/LastStepPage_Organization.graphql";
import { LastStepPage_SetEssentialsLastStepOnboardingStepAsCompleted_Mutation } from "./__generated__/LastStepPage_SetEssentialsLastStepOnboardingStepAsCompleted_Mutation.graphql";
import illustration from "./illustration.png";

const ORGANIZATION_FRAGMENT = graphql`
  fragment LastStepPage_Organization on Organization {
    id
  }
`;

const SET_ESSENTIALS_LAST_STEP_ONBOARDING_STEP_AS_COMPLETED_MUTATION = graphql`
  mutation LastStepPage_SetEssentialsLastStepOnboardingStepAsCompleted_Mutation(
    $organizationId: OrganizationId!
  ) {
    setEssentialsLastStepOnboardingStepAsCompleted(
      organizationId: $organizationId
    ) {
      currentRemoteOnboardingStep
    }
  }
`;

export default function LastStepPage({
  organizationFragment,
}: {
  organizationFragment: LastStepPage_Organization$key;
}) {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const navigate = useNavigate();

  const [
    setEssentialsFairMarketValueOnboardingStepAsCompleted,
    mutationIsInFlight,
  ] =
    useSafeMutation<LastStepPage_SetEssentialsLastStepOnboardingStepAsCompleted_Mutation>(
      SET_ESSENTIALS_LAST_STEP_ONBOARDING_STEP_AS_COMPLETED_MUTATION,
    );

  const handleGoToEssentialsButtonClicked = useCallback(async () => {
    await setEssentialsFairMarketValueOnboardingStepAsCompleted({
      variables: { organizationId: organization.id },
    });
    await navigate(
      generatePath(APPLICATION_ROUTES.organizationHome, {
        organizationId: organization.id,
      }),
    );
  }, [
    organization.id,
    navigate,
    setEssentialsFairMarketValueOnboardingStepAsCompleted,
  ]);

  const handleDeclareGrantButtonClicked = useCallback(async () => {
    await setEssentialsFairMarketValueOnboardingStepAsCompleted({
      variables: { organizationId: organization.id },
    });
    await navigate(
      generatePath(APPLICATION_ROUTES.organizationDeclareNewGrant, {
        organizationId: organization.id,
      }),
    );
  }, [
    organization.id,
    navigate,
    setEssentialsFairMarketValueOnboardingStepAsCompleted,
  ]);

  return (
    <EssentialsOnboardingTemplate
      illustration={illustration}
      subtitle="Ready to manage your equity compliantly? You can start declaring equity grant right now"
      title="Your company is all set!"
    >
      <div className="flex gap-4">
        <Button
          disabled={mutationIsInFlight}
          onClick={handleDeclareGrantButtonClicked}
          type="button"
        >
          Declare a grant
        </Button>
        <Button
          disabled={mutationIsInFlight}
          onClick={handleGoToEssentialsButtonClicked}
          type="button"
          variant="outline"
        >
          Go to Remote Equity Essentials
        </Button>
      </div>
    </EssentialsOnboardingTemplate>
  );
}
