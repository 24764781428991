import { Text } from "@remote-com/norma";
import { IconV2DuotoneFileCheck } from "@remote-com/norma/icons/IconV2DuotoneFileCheck";
import { IconV2DuotoneGlobe } from "@remote-com/norma/icons/IconV2DuotoneGlobe";
import { IconV2DuotoneUserCheck } from "@remote-com/norma/icons/IconV2DuotoneUserCheck";
import { uniqBy } from "lodash";
import { useMemo } from "react";
import { FormattedNumber } from "react-intl";
import { graphql, useFragment } from "react-relay";

import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { EquityRepartitionCard_EssentialsOverview_Organization$key } from "./__generated__/EquityRepartitionCard_EssentialsOverview_Organization.graphql";
import { EquityPlanParametersCardRow } from "./EquityPlanParametersCard";

const ORGANIZATION_FRAGMENT = graphql`
  fragment EquityRepartitionCard_EssentialsOverview_Organization on Organization {
    grantees {
      edges {
        node {
          equityInformationProvidedDuringRemoteOnboarding
          ctmsGrantsCount
          taxResidenceCountry {
            code
          }
        }
      }
    }
    poolGrantedSharesBreakdown {
      total
    }
  }
`;

export const EquityRepartitionCard: React.FC<{
  organizationFragment: EquityRepartitionCard_EssentialsOverview_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const granteesIncentivized = useMemo(
    () =>
      organization.grantees.edges.filter(
        ({ node: grantee }) =>
          grantee.ctmsGrantsCount > 0 ||
          grantee.equityInformationProvidedDuringRemoteOnboarding,
      ),
    [organization.grantees.edges],
  );

  const countriesCoveredCount = useMemo(
    () =>
      uniqBy(
        granteesIncentivized.filter(
          ({ node: grantee }) => grantee.taxResidenceCountry?.code,
        ),
        ({ node: grantee }) => grantee.taxResidenceCountry?.code,
      ).length,
    [granteesIncentivized],
  );

  return (
    <RoundedBox className="space-y-2 p-6" withBorder>
      <Text variant="lgMedium">Equity repartition</Text>
      <div className="divide-y-[0.5px] divide-grey-300">
        <EquityPlanParametersCardRow
          Icon={IconV2DuotoneUserCheck}
          label="EMPLOYEES incentivized"
          tone="purple"
        >
          <FormattedNumber value={granteesIncentivized.length} />
        </EquityPlanParametersCardRow>
        <EquityPlanParametersCardRow
          Icon={IconV2DuotoneGlobe}
          label="countries"
          tone="orange"
        >
          <FormattedNumber value={countriesCoveredCount} />
        </EquityPlanParametersCardRow>
        <EquityPlanParametersCardRow
          Icon={IconV2DuotoneFileCheck}
          label="SHARES offered"
          tone="green"
        >
          <FormattedNumber
            value={organization.poolGrantedSharesBreakdown.total}
          />
        </EquityPlanParametersCardRow>
      </div>
    </RoundedBox>
  );
};
