import { Button, DropdownMenu } from "@remote-com/norma";
import { IconV2OutlineChevronDown } from "@remote-com/norma/icons/IconV2OutlineChevronDown";
import classNames from "classnames";
import React from "react";
import { useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import { CTMSGrantVestingStatusPill } from "../../../../components/CtmsGrantVestingStatusPill";
import { Page } from "../../../../components/Page";
import { RepricedFromCtmsGrantNoticeMessage } from "../../../../components/RepricedFromCtmsGrantNoticeMessage";
import { RepricedToCtmsGrantNoticeMessage } from "../../../../components/RepricedToCtmsGrantNoticeMessage";
import { LargeCenteredDetailsLayout } from "../../../../components/ui/Layout/LargeCenteredDetailsLayout";
import { useQuery } from "../../../../hooks/useQuery";
import {
  APPLICATION_ROUTES,
  useCtmsGrantIdParam,
  useOrganizationIdParam,
} from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { NewCtmsGrantDetails_CTMSGrant$key } from "./__generated__/NewCtmsGrantDetails_CTMSGrant.graphql";
import { NewCtmsGrantDetails_Organization$key } from "./__generated__/NewCtmsGrantDetails_Organization.graphql";
import { NewCtmsGrantDetails_Query } from "./__generated__/NewCtmsGrantDetails_Query.graphql";
import { GrantDetailsDocumentsSection } from "./GrantDetailsDocumentsSection";
import { GrantDetailsEmployeeSection } from "./GrantDetailsEmployeeSection";
import { GrantDetailsExerciseSection } from "./GrantDetailsExerciseSection";
import { GrantDetailsInformationSection } from "./GrantDetailsInformationSection";
import { GrantDetailsOwnershipSection } from "./GrantDetailsOwnershipSection";
import { GrantDetailsVestingScheduleSection } from "./GrantDetailsVestingScheduleSection";

const ORGANIZATION_FRAGMENT = graphql`
  fragment NewCtmsGrantDetails_Organization on Organization {
    id
    name
    ...LargeCenteredDetailsLayout_Organization
    ...GrantDetailsOwnershipSection_Organization
    ...GrantDetailsVestingScheduleSection_Organization
    ...GrantDetailsExerciseSection_Organization
  }
`;

const CTMS_GRANT_FRAGMENT = graphql`
  fragment NewCtmsGrantDetails_CTMSGrant on CTMSGrant {
    label
    grantee {
      id
      name
    }
    repricedFromCTMSGrant {
      ...RepricedFromCtmsGrantNoticeMessage_CtmsGrant
    }
    repricedToCTMSGrant {
      ...RepricedToCtmsGrantNoticeMessage_CtmsGrant
    }
    matchingInstrument {
      equityType {
        exercisable
        humanReadableName
      }
    }

    ...CtmsGrantVestingStatusPill_CTMSGrant
    ...GrantDetailsOwnershipSection_CTMSGrant
    ...GrantDetailsVestingScheduleSection_CTMSGrant
    ...GrantDetailsInformationSection_CTMSGrant
    ...GrantDetailsExerciseSection_CTMSGrant
    ...GrantDetailsDocumentsSection_CTMSGrant
    ...GrantDetailsEmployeeSection_CTMSGrant
  }
`;

const NewCtmsGrantDetails_: React.FC<{
  ctmsGrantFragment: NewCtmsGrantDetails_CTMSGrant$key;
  organizationFragment: NewCtmsGrantDetails_Organization$key;
}> = ({ ctmsGrantFragment, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);

  const isExercisable =
    ctmsGrant.matchingInstrument?.equityType?.exercisable !== false;

  return (
    <LargeCenteredDetailsLayout
      navigationTitle={ctmsGrant.label}
      organizationFragment={organization}
      parents={[
        {
          link: generatePath(APPLICATION_ROUTES.organizationEquityGrants, {
            organizationId: organization.id,
          }),
          title: "Equity grants",
        },
      ]}
      subtitle={
        <>
          {ctmsGrant.matchingInstrument?.equityType?.humanReadableName && (
            <span className="font-medium">
              {ctmsGrant.matchingInstrument.equityType.humanReadableName}{" "}
            </span>
          )}
          grant to{" "}
          <Link
            className="font-medium text-brand-600"
            to={generatePath(APPLICATION_ROUTES.organizationGrantee, {
              granteeId: ctmsGrant.grantee.id,
              organizationId: organization.id,
            })}
          >
            {ctmsGrant.grantee.name}
          </Link>
        </>
      }
      title={
        <div className="flex items-center gap-2">
          <span>{ctmsGrant.label}</span>
          <CTMSGrantVestingStatusPill ctmsGrantFragment={ctmsGrant} />
        </div>
      }
      titleActions={
        isExercisable ? (
          <DropdownMenu
            // @ts-expect-error className and trigger not defined on DropdownMenu props
            className="z-10"
            trigger={
              <Button IconAfter={IconV2OutlineChevronDown} size="md">
                Actions
              </Button>
            }
          >
            <DropdownMenu.Item
              href={generatePath(
                APPLICATION_ROUTES.organizationDeclareNewExerciseRequestsForCTMSGrant,
                {
                  organizationId: organization.id,
                  selectedCTMSGrantLabel: ctmsGrant.label,
                  selectedGranteeId: ctmsGrant.grantee.id,
                },
              )}
            >
              Declare exercise
            </DropdownMenu.Item>
          </DropdownMenu>
        ) : null
      }
    >
      <div className="space-y-10">
        {ctmsGrant.repricedToCTMSGrant && (
          <RepricedToCtmsGrantNoticeMessage
            ctmsGrantFragment={ctmsGrant.repricedToCTMSGrant}
          />
        )}
        {ctmsGrant.repricedFromCTMSGrant && (
          <RepricedFromCtmsGrantNoticeMessage
            ctmsGrantFragment={ctmsGrant.repricedFromCTMSGrant}
          />
        )}
        <GrantDetailsOwnershipSection
          ctmsGrantFragment={ctmsGrant}
          organizationFragment={organization}
        />
        <GrantDetailsVestingScheduleSection
          ctmsGrantFragment={ctmsGrant}
          employeeId={ctmsGrant.grantee.id}
          organizationFragment={organization}
        />
        <div className="grid grid-cols-1 gap-10 lg:grid-cols-2">
          <GrantDetailsInformationSection ctmsGrantFragment={ctmsGrant} />
          {isExercisable && (
            <GrantDetailsExerciseSection
              ctmsGrantFragment={ctmsGrant}
              organizationFragment={organization}
            />
          )}
          <GrantDetailsDocumentsSection
            className={classNames({
              "order-3 lg:col-span-2": !isExercisable,
            })}
            ctmsGrantFragment={ctmsGrant}
          />
          <GrantDetailsEmployeeSection
            className={classNames({
              "order-2": !isExercisable,
            })}
            ctmsGrantFragment={ctmsGrant}
          />
        </div>
      </div>
    </LargeCenteredDetailsLayout>
  );
};

const QUERY = graphql`
  query NewCtmsGrantDetails_Query(
    $organizationId: OrganizationId!
    $ctmsGrantId: CtmsGrantId!
  ) {
    organization(id: $organizationId) {
      id
      name
      ...NewCtmsGrantDetails_Organization
    }
    ctmsGrant(id: $ctmsGrantId) {
      ...NewCtmsGrantDetails_CTMSGrant
    }
  }
`;

const NewCtmsGrantDetails: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const ctmsGrantId = useCtmsGrantIdParam();

  const {
    query: { ctmsGrant, organization },
  } = useQuery<NewCtmsGrantDetails_Query>(QUERY, {
    ctmsGrantId,
    organizationId,
  });

  if (!organization || !ctmsGrant) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Admin Grants"
      analyticsName="Admin - Grants - Grant details"
      organizationId={organization.id}
      title={`Admin | ${organization.name} grant details`}
    >
      <NewCtmsGrantDetails_
        ctmsGrantFragment={ctmsGrant}
        organizationFragment={organization}
      />
    </Page>
  );
};

export default NewCtmsGrantDetails;
