/**
 * @generated SignedSource<<0e49b9d4760dadeb94d1405426117f7d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EquityOfferStatus = "DRAFTED" | "IMPLEMENTED" | "OFFER";
import { FragmentRefs } from "relay-runtime";
export type EquityOffersSettings_Organization$data = {
  readonly equityOffers: ReadonlyArray<{
    readonly canBeConvertedToDraft: boolean;
    readonly canBeDuplicated: boolean;
    readonly candidateAccessSecretKey: string;
    readonly candidateName: string | null;
    readonly contractStartDate: string | null;
    readonly easopGrant: {
      readonly ctmsGrant: {
        readonly id: string;
      } | null;
      readonly id: string;
    } | null;
    readonly editable: boolean;
    readonly id: string;
    readonly position: string | null;
    readonly shares: number | null;
    readonly status: EquityOfferStatus;
    readonly " $fragmentSpreads": FragmentRefs<"EquityOfferShareModal_EquityOffer" | "EquityOfferStatusTag_EquityOffer">;
  }>;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"FormattedCurrency_Organization" | "LargeOneColumnLayout_Organization" | "useOrganizationSharesUtil_Organization">;
  readonly " $fragmentType": "EquityOffersSettings_Organization";
};
export type EquityOffersSettings_Organization$key = {
  readonly " $data"?: EquityOffersSettings_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquityOffersSettings_Organization">;
};

import EquityOffersSettings_Organization_RefetchQuery_graphql from './EquityOffersSettings_Organization_RefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "search"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": EquityOffersSettings_Organization_RefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "EquityOffersSettings_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "search"
        }
      ],
      "concreteType": "EquityOffer",
      "kind": "LinkedField",
      "name": "equityOffers",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "candidateAccessSecretKey",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "candidateName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "position",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shares",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contractStartDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canBeConvertedToDraft",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "editable",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canBeDuplicated",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EasopGrant",
          "kind": "LinkedField",
          "name": "easopGrant",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "CTMSGrant",
              "kind": "LinkedField",
              "name": "ctmsGrant",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EquityOfferShareModal_EquityOffer"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EquityOfferStatusTag_EquityOffer"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationSharesUtil_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LargeOneColumnLayout_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedCurrency_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "04844d607f3bd10f92fcdef2a585a7d8";

export default node;
