import {
  ArrowTopRightOnSquareIcon,
  DocumentDuplicateIcon,
  EllipsisHorizontalIcon,
  EyeIcon,
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { isEmpty, isNil } from "lodash";
import {
  default as React,
  startTransition,
  useCallback,
  useMemo,
  useState,
  useTransition,
} from "react";
import { FormattedNumber } from "react-intl";
import {
  useRefetchableFragment,
  useSubscribeToInvalidationState,
} from "react-relay";
import { generatePath, useNavigate } from "react-router-dom";
import { graphql } from "relay-runtime";

import { EmptyListPlaceholder } from "../../../../components/EmptyListPlaceholder/EmptyListPlaceholder";
import { EquityOfferShareModal } from "../../../../components/EquityOfferShareModal";
import { EquityOfferStatusTag } from "../../../../components/EquityOfferStatusTag";
import { FormattedCurrency } from "../../../../components/Formatted/FormattedCurrency";
import { FormattedPercentage } from "../../../../components/Formatted/FormattedPercentage";
import { Page } from "../../../../components/Page";
import { ShortDate } from "../../../../components/ShortDate";
import { StopClickPropagationWrapper } from "../../../../components/StopClickPropagationWrapper";
import { Button, LinkButton } from "../../../../components/ui/Button";
import { LargeOneColumnLayout } from "../../../../components/ui/Layout/LargeOneColumnLayout";
import { MenuButton } from "../../../../components/ui/MenuButton";
import { SearchBar } from "../../../../components/ui/SearchBar";
import { Table } from "../../../../components/ui/Table";
import { Typography } from "../../../../components/ui/Typography";
import { useBoolean } from "../../../../hooks/useBoolean";
import { useDebounced } from "../../../../hooks/useDebounced";
import { useOrganizationSharesUtil } from "../../../../hooks/useOrganizationSharesUtil";
import { useQuery } from "../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { EquityOffersSettings_CreateEquityOffer_Mutation } from "./__generated__/EquityOffersSettings_CreateEquityOffer_Mutation.graphql";
import { EquityOffersSettings_DeleteEquityOffer_Mutation } from "./__generated__/EquityOffersSettings_DeleteEquityOffer_Mutation.graphql";
import { EquityOffersSettings_DuplicateEquityOffer_Mutation } from "./__generated__/EquityOffersSettings_DuplicateEquityOffer_Mutation.graphql";
import {
  EquityOffersSettings_Organization$data,
  EquityOffersSettings_Organization$key,
} from "./__generated__/EquityOffersSettings_Organization.graphql";
import { EquityOffersSettings_Query } from "./__generated__/EquityOffersSettings_Query.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment EquityOffersSettings_Organization on Organization
  @argumentDefinitions(search: { type: "String", defaultValue: "" })
  @refetchable(queryName: "EquityOffersSettings_Organization_RefetchQuery") {
    id
    name
    equityOffers(search: $search) {
      id
      status
      candidateAccessSecretKey
      candidateName
      position
      shares
      contractStartDate
      canBeConvertedToDraft
      editable
      canBeDuplicated
      easopGrant {
        id
        ctmsGrant {
          id
        }
      }
      ...EquityOfferShareModal_EquityOffer
      ...EquityOfferStatusTag_EquityOffer
    }
    ...useOrganizationSharesUtil_Organization
    ...LargeOneColumnLayout_Organization
    ...FormattedCurrency_Organization
  }
`;

const CREATE_EQUITY_OFFER_MUTATION = graphql`
  mutation EquityOffersSettings_CreateEquityOffer_Mutation(
    $organizationId: OrganizationId!
  ) {
    createEquityOffer(organizationId: $organizationId) {
      id
    }
  }
`;

const DELETE_EQUITY_OFFER_MUTATION = graphql`
  mutation EquityOffersSettings_DeleteEquityOffer_Mutation(
    $equityOfferId: UUID!
  ) {
    deleteEquityOffer(equityOfferId: $equityOfferId)
  }
`;

const DUPLICATE_EQUITY_OFFER_MUTATION = graphql`
  mutation EquityOffersSettings_DuplicateEquityOffer_Mutation(
    $equityOfferId: UUID!
  ) {
    duplicateEquityOffer(equityOfferId: $equityOfferId) {
      __typename
    }
  }
`;

type EquityOffer =
  EquityOffersSettings_Organization$data["equityOffers"][number];

const columnHelper = createColumnHelper<EquityOffer>();

export const useCreateEquityOffer = ({
  organizationId,
}: {
  organizationId: string;
}) => {
  const [_createEquityOffer, createEquityOfferMutationIsInFlight] =
    useSafeMutation<EquityOffersSettings_CreateEquityOffer_Mutation>(
      CREATE_EQUITY_OFFER_MUTATION,
    );
  const navigate = useNavigate();

  const createEquityOffer = useCallback(async () => {
    const result = await _createEquityOffer({
      variables: {
        organizationId,
      },
    });

    void navigate(
      generatePath(APPLICATION_ROUTES.organizationToolsEquityOfferCandidate, {
        equityOfferId: result.createEquityOffer.id,
        organizationId,
      }),
    );
  }, [navigate, _createEquityOffer, organizationId]);

  return { createEquityOffer, createEquityOfferMutationIsInFlight };
};

const EquityOffersSettingsPage_: React.FC<{
  organizationFragment: EquityOffersSettings_Organization$key;
}> = ({ organizationFragment }) => {
  const [organization, refetchOrganization] = useRefetchableFragment(
    ORGANIZATION_FRAGMENT,
    organizationFragment,
  );
  const [_deleteEquityOffer, deleteEquityOfferMutationIsInFlight] =
    useSafeMutation<EquityOffersSettings_DeleteEquityOffer_Mutation>(
      DELETE_EQUITY_OFFER_MUTATION,
    );
  const deleteEquityOffer = useCallback(
    (equityOfferId: string) =>
      _deleteEquityOffer({ variables: { equityOfferId } }),
    [_deleteEquityOffer],
  );
  const [_duplicateEquityOffer, duplicateEquityOfferMutationIsInFlight] =
    useSafeMutation<EquityOffersSettings_DuplicateEquityOffer_Mutation>(
      DUPLICATE_EQUITY_OFFER_MUTATION,
    );
  const duplicateEquityOffer = useCallback(
    (equityOfferId: string) =>
      _duplicateEquityOffer({ variables: { equityOfferId } }),
    [_duplicateEquityOffer],
  );

  const { createEquityOffer, createEquityOfferMutationIsInFlight } =
    useCreateEquityOffer({ organizationId: organization.id });

  const { sharesToFullyDilutedRatio, sharesToValue } =
    useOrganizationSharesUtil({
      organizationFragment: organization,
    });

  const [_sharedEquityOffer, setSharedEquityOffer] =
    useState<EquityOffer | null>(null);
  const sharedEquityOffer = useMemo(() => {
    if (_sharedEquityOffer) return _sharedEquityOffer;
    return organization.equityOffers[0] ?? null;
  }, [_sharedEquityOffer, organization.equityOffers]);

  const {
    setFalse: hideShareModal,
    setTrue: showShareModal,
    value: isShareModalShown,
  } = useBoolean(false);

  const onShareClick = useCallback(
    (equityOffer: EquityOffer) => {
      setSharedEquityOffer(equityOffer);
      showShareModal();
    },
    [setSharedEquityOffer, showShareModal],
  );

  const [searchTransitionIsInProgress, startSearchTransition] = useTransition();

  const {
    isDebouncing: searchIsDebouncing,
    liveState: searchInputValue,
    setState: setSearchInputValue,
  } = useDebounced<string>({
    delay: 1000,
    initialState: "",
    onDebounce: useCallback(
      (search: string) => {
        startSearchTransition(() => {
          refetchOrganization({ search });
        });
      },
      [refetchOrganization],
    ),
  });

  useSubscribeToInvalidationState([organization.id], () => {
    startTransition(() => {
      refetchOrganization({ search: searchInputValue });
    });
  });

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.candidateName ?? "-", {
        cell: (context) => {
          const candidateName = context.getValue();

          return (
            <div className="space-y-1">
              <div>{candidateName}</div>
              {/* Uncomment once email is added on equity offers */}
              {/* <Typography variant="Regular/Caption" className="text-black-05">
                {context.row.original.candidateEmailAddress
                  ? context.row.original.candidateEmailAddress
                  : "-"}
              </Typography> */}
            </div>
          );
        },
        header: () => "Employee",
        id: "candidateName",
      }),
      columnHelper.accessor((row) => row.position ?? "-", {
        cell: (context) => {
          const position = context.getValue();

          return (
            <div className="space-y-1">
              <div>{position}</div>
              {/* Uncomment once equity grid level is added on equity offers */}
              {/* <Typography variant="Regular/Caption" className="text-black-05">
                {context.row.original.equityGridLevel
                  ? <Tag>{context.row.original.equityGridLevel}</Tag>
                  : "-"}
              </Typography> */}
            </div>
          );
        },
        header: () => "Position",
        id: "position",
      }),
      columnHelper.accessor((row) => row.shares, {
        cell: (context) => {
          const shares = context.getValue();

          if (shares === null) return "-";

          const usdValue = sharesToValue(shares);
          const ownership = sharesToFullyDilutedRatio(shares);

          return (
            <div className="space-y-1 text-right">
              {usdValue !== null && (
                <div>
                  <FormattedCurrency
                    maximumFractionDigits={0}
                    organizationFragment={organization}
                    value={usdValue}
                  />
                </div>
              )}
              <Typography
                as="div"
                className="text-black-05"
                variant="Regular/Caption"
              >
                <FormattedNumber value={shares} /> shares
              </Typography>
              {ownership !== null && (
                <Typography
                  as="div"
                  className="text-black-05"
                  variant="Regular/Caption"
                >
                  <FormattedPercentage value={ownership} />
                </Typography>
              )}
            </div>
          );
        },
        header: () => "Equity package",
        id: "equity",
        meta: { alignRight: true },
      }),
      columnHelper.accessor((row) => row.contractStartDate, {
        cell: (context) => {
          const contractStartDate = context.getValue();

          if (isNil(contractStartDate)) return "-";

          return <ShortDate value={contractStartDate} />;
        },
        header: () => "Start date",
        id: "startDate",
      }),
      columnHelper.accessor((row) => row.status, {
        cell: (context) => {
          const equityOffer = context.row.original;

          return <EquityOfferStatusTag equityOfferFragment={equityOffer} />;
        },
        header: () => "Status",
        id: "status",
      }),
      columnHelper.accessor((row) => row.id, {
        cell: (context) => {
          const equityOffer = context.row.original;
          const equityOfferId = context.getValue();
          return (
            <div className="flex justify-end gap-2">
              {equityOffer.easopGrant && (
                <LinkButton
                  rightIcon={<ArrowTopRightOnSquareIcon />}
                  size="extra small"
                  to={
                    equityOffer.easopGrant.ctmsGrant
                      ? generatePath(
                          APPLICATION_ROUTES.organizationEquityCtmsGrant,
                          {
                            ctmsGrantId: equityOffer.easopGrant.ctmsGrant.id,
                            organizationId: organization.id,
                          },
                        )
                      : generatePath(
                          APPLICATION_ROUTES.organizationPrepareYourGrantsDetails,
                          {
                            easopGrantId: equityOffer.easopGrant.id,
                            organizationId: organization.id,
                          },
                        )
                  }
                  variant="Secondary Full"
                />
              )}
              <StopClickPropagationWrapper>
                <MenuButton
                  button={
                    <Button
                      data-cy="equity-offer-menu-button"
                      leftIcon={<EllipsisHorizontalIcon />}
                      size="extra small"
                      variant="Secondary Full"
                    />
                  }
                  menuClassName="text-left"
                  placement="bottom end"
                >
                  {equityOffer.status === "OFFER" && (
                    <MenuButton.LinkItem
                      leftIcon={<EyeIcon />}
                      target="_blank"
                      to={generatePath(APPLICATION_ROUTES.candidateOffer, {
                        equityOfferCandidateAccessSecretKey:
                          context.row.original.candidateAccessSecretKey,
                      })}
                    >
                      Preview
                    </MenuButton.LinkItem>
                  )}

                  {equityOffer.editable && (
                    <MenuButton.LinkItem
                      leftIcon={<PencilSquareIcon />}
                      to={generatePath(
                        APPLICATION_ROUTES.organizationToolsEquityOfferCandidate,
                        {
                          equityOfferId,
                          organizationId: organization.id,
                        },
                      )}
                    >
                      Edit
                    </MenuButton.LinkItem>
                  )}

                  {equityOffer.canBeDuplicated && (
                    <MenuButton.Item
                      leftIcon={<DocumentDuplicateIcon />}
                      loading={duplicateEquityOfferMutationIsInFlight}
                      onClick={() => duplicateEquityOffer(equityOfferId)}
                    >
                      Duplicate
                    </MenuButton.Item>
                  )}

                  <MenuButton.Item
                    leftIcon={<TrashIcon />}
                    loading={deleteEquityOfferMutationIsInFlight}
                    onClick={() => deleteEquityOffer(equityOfferId)}
                    variant="Danger"
                  >
                    Delete
                  </MenuButton.Item>
                </MenuButton>
              </StopClickPropagationWrapper>
              {equityOffer.canBeConvertedToDraft && (
                <LinkButton
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  size="extra small"
                  to={{
                    pathname: generatePath(
                      APPLICATION_ROUTES.organizationAssistedGrantGranteeGrantee,
                      { organizationId: organization.id },
                    ),
                    search: new URLSearchParams({
                      equityOfferId,
                    }).toString(),
                  }}
                  variant="Secondary Outline"
                >
                  Draft
                </LinkButton>
              )}
              {equityOffer.status === "OFFER" && (
                <StopClickPropagationWrapper>
                  <Button
                    onClick={(event) => {
                      onShareClick(context.row.original);
                      event.preventDefault();
                    }}
                    size="extra small"
                    variant="Primary Full"
                  >
                    Share
                  </Button>
                </StopClickPropagationWrapper>
              )}
            </div>
          );
        },
        enableSorting: false,
        header: () => "Actions",
        id: "actions",
        meta: { alignRight: true },
      }),
    ],
    [
      sharesToValue,
      sharesToFullyDilutedRatio,
      organization.id,
      duplicateEquityOfferMutationIsInFlight,
      deleteEquityOfferMutationIsInFlight,
      duplicateEquityOffer,
      deleteEquityOffer,
      onShareClick,
    ],
  );

  const data = useMemo(
    () => [...organization.equityOffers],
    [organization.equityOffers],
  );

  const table = useReactTable({
    columns,
    data,
    enableRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const hasNoEquityOffers = isEmpty(organization.equityOffers);

  return (
    <LargeOneColumnLayout
      organizationFragment={organization}
      subtitle="Be transparent and hire faster with equity offers."
      title="Equity offers"
    >
      {sharedEquityOffer && (
        <EquityOfferShareModal
          equityOfferFragment={sharedEquityOffer}
          onClose={hideShareModal}
          show={isShareModalShown}
        />
      )}
      <>
        <div className="space-y-6">
          <div className="flex flex-wrap gap-4">
            <Button
              leftIcon={<PlusIcon />}
              loading={createEquityOfferMutationIsInFlight}
              onClick={createEquityOffer}
              size="small"
            >
              New offer
            </Button>
            <SearchBar
              className="w-full max-w-[280px]"
              loading={searchIsDebouncing || searchTransitionIsInProgress}
              onChange={setSearchInputValue}
              placeholder="Search offers..."
              value={searchInputValue}
            />
          </div>
          <div>
            {hasNoEquityOffers ? (
              <EmptyListPlaceholder
                action={
                  <Button
                    leftIcon={<PlusIcon />}
                    loading={createEquityOfferMutationIsInFlight}
                    onClick={createEquityOffer}
                    size="small"
                  >
                    New offer
                  </Button>
                }
                loading={searchTransitionIsInProgress}
                subtitle={
                  <>
                    Create as many offers as you want. Once ready, share it via
                    link or generate a PDF.
                    <br />
                    If the offer is accepted, turn it into a draft
                  </>
                }
                title="🎉️ Share equity offers to candidates!"
              />
            ) : (
              <Table.Smart
                loading={searchTransitionIsInProgress}
                rowRenderer={({ cells, rowData }) => {
                  const equityOffer = rowData.original;
                  if (equityOffer.easopGrant?.ctmsGrant) {
                    return (
                      <Table.LinkRow
                        to={generatePath(
                          APPLICATION_ROUTES.organizationEquityCtmsGrant,
                          {
                            ctmsGrantId: equityOffer.easopGrant.ctmsGrant.id,
                            organizationId: organization.id,
                          },
                        )}
                      >
                        {cells}
                      </Table.LinkRow>
                    );
                  }
                  if (equityOffer.easopGrant) {
                    return (
                      <Table.LinkRow
                        to={generatePath(
                          APPLICATION_ROUTES.organizationPrepareYourGrantsDetails,
                          {
                            easopGrantId: equityOffer.easopGrant.id,
                            organizationId: organization.id,
                          },
                        )}
                      >
                        {cells}
                      </Table.LinkRow>
                    );
                  }
                  return (
                    <Table.LinkRow
                      to={generatePath(
                        APPLICATION_ROUTES.organizationToolsEquityOfferCandidate,
                        {
                          equityOfferId: equityOffer.id,
                          organizationId: organization.id,
                        },
                      )}
                    >
                      {cells}
                    </Table.LinkRow>
                  );
                }}
                table={table}
                tableClassName="grid-cols-6"
                tableDisplay="grid"
              />
            )}
          </div>
        </div>
      </>
    </LargeOneColumnLayout>
  );
};

const QUERY = graphql`
  query EquityOffersSettings_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...EquityOffersSettings_Organization
    }
  }
`;

const EquityOffersSettingsPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();

  const {
    query: { organization },
  } = useQuery<EquityOffersSettings_Query>(QUERY, {
    organizationId,
  });

  if (!organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Admin Settings"
      analyticsName="Admin - Equity offer settings"
      organizationId={organization.id}
      title={`Admin | ${organization.name} Equity offer settings`}
    >
      <EquityOffersSettingsPage_ organizationFragment={organization} />
    </Page>
  );
};

export default EquityOffersSettingsPage;
