import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Text, Tooltip } from "@remote-com/norma";
import classNames from "classnames";
import React from "react";

type ColoredBoxValueTone = "blueLight" | "green" | "purple";

const COLORED_BOX_VALUE_STYLE: Record<
  ColoredBoxValueTone,
  { backgroundClassName: string; textClassName: string }
> = {
  blueLight: {
    backgroundClassName: "bg-blueLight-100",
    textClassName: "text-blueLight-700",
  },
  green: {
    backgroundClassName: "bg-green-100",
    textClassName: "text-green-700",
  },
  purple: {
    backgroundClassName: "bg-purple-100",
    textClassName: "text-purple-700",
  },
};

export const ColoredBoxValue: React.FC<{
  className?: string;
  footer?: React.ReactNode;
  secondary?: {
    unit: React.ReactNode;
    value: React.ReactNode;
  };
  title: React.ReactNode;
  tone: ColoredBoxValueTone;
  tooltip?: React.ReactNode;
  unit: React.ReactNode;
  value: React.ReactNode;
}> = ({ className, footer, secondary, title, tone, tooltip, unit, value }) => {
  return (
    <div
      className={classNames(
        "rounded-remote-lg bg-white p-2 shadow-[1px_4px_16px_0px_rgba(15,20,25,0.04)]",
        className,
      )}
    >
      <div
        className={classNames(
          "flex-1 space-y-2 rounded-sm p-8",
          COLORED_BOX_VALUE_STYLE[tone].backgroundClassName,
        )}
      >
        <Text variant="lgMedium">
          {title}
          {tooltip && (
            <>
              {" "}
              <Tooltip label={tooltip}>
                <InformationCircleIcon className="inline size-6 text-brand-600" />
              </Tooltip>
            </>
          )}
        </Text>
        <div
          className={classNames(
            "space-y-2",
            COLORED_BOX_VALUE_STYLE[tone].textClassName,
          )}
        >
          <div className="flex items-end gap-2">
            <Text variant="4xlMedium">{value}</Text>
            <Text variant="xlMedium">{unit}</Text>
          </div>
          {secondary && (
            <div className="flex items-end gap-1">
              <Text className="!leading-[1.2]" variant="lgMedium">
                {secondary.value}
              </Text>
              <Text className="text-grey-800" variant="xs">
                {secondary.unit}
              </Text>
            </div>
          )}
        </div>
      </div>
      {footer && <div className="p-2">{footer}</div>}
    </div>
  );
};
