/**
 * @generated SignedSource<<ff37685d00dc83df16ec590284014a6e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SubmitPricePerShareView_Viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"RemoteEquityOnboardingLayout_Viewer">;
  readonly " $fragmentType": "SubmitPricePerShareView_Viewer";
};
export type SubmitPricePerShareView_Viewer$key = {
  readonly " $data"?: SubmitPricePerShareView_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SubmitPricePerShareView_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubmitPricePerShareView_Viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemoteEquityOnboardingLayout_Viewer"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "d38908c9e630630125e11cea0136cdd2";

export default node;
