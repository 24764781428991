/**
 * @generated SignedSource<<5195fec2818d3210be00ad423a4dca0e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type GranteePortalOverviewHeader_Grantee$data = {
  readonly email: string;
  readonly jobTitle: string | null;
  readonly name: string;
  readonly organization: {
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"FormattedCurrency_Organization" | "useOrganizationSharesUtil_Organization">;
  };
  readonly taxResidenceCountry: {
    readonly emoji: string;
    readonly name: string;
  } | null;
  readonly totalGrantedSharesBreakdown: {
    readonly real: number;
    readonly virtual: number;
  };
  readonly workRelationship: WorkRelationship | null;
  readonly " $fragmentSpreads": FragmentRefs<"useDisplayFullyDilutedValuesInGranteePortal_Grantee">;
  readonly " $fragmentType": "GranteePortalOverviewHeader_Grantee";
};
export type GranteePortalOverviewHeader_Grantee$key = {
  readonly " $data"?: GranteePortalOverviewHeader_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteePortalOverviewHeader_Grantee">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteePortalOverviewHeader_Grantee",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "taxResidenceCountry",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emoji",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workRelationship",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jobTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useOrganizationSharesUtil_Organization"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FormattedCurrency_Organization"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SharesBreakdown",
      "kind": "LinkedField",
      "name": "totalGrantedSharesBreakdown",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "real",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "virtual",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useDisplayFullyDilutedValuesInGranteePortal_Grantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};
})();

(node as any).hash = "012ac0dc5004c96b270cc70e1b9280c7";

export default node;
