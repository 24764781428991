/**
 * @generated SignedSource<<c17335f88ad28fec35fcac8bfe367ce3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GrantDeclarationBillingInformationSection_Deferred_Organization$data = {
  readonly essentialsEmploymentPrice: {
    readonly amount: number;
    readonly currencyCode: string;
    readonly feeIsWaived: boolean;
  } | null;
  readonly " $fragmentType": "GrantDeclarationBillingInformationSection_Deferred_Organization";
};
export type GrantDeclarationBillingInformationSection_Deferred_Organization$key = {
  readonly " $data"?: GrantDeclarationBillingInformationSection_Deferred_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantDeclarationBillingInformationSection_Deferred_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantDeclarationBillingInformationSection_Deferred_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RemoteProductPrice",
      "kind": "LinkedField",
      "name": "essentialsEmploymentPrice",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currencyCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "feeIsWaived",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "14311d268a3ed3202516a50bbece5d45";

export default node;
