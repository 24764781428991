import {
  BookOpenIcon,
  ChartBarSquareIcon,
  PresentationChartBarIcon,
} from "@heroicons/react/24/outline";
import { Button } from "@remote-com/norma";
import React, { startTransition, useCallback, useMemo } from "react";
import { useFragment, useRefetchableFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { AdminEntitiesOverview } from "../../../components/AdminEntitiesOverview";
import { AdminUpcomingCard } from "../../../components/AdminUpcomingCard";
import { ButtonBanner } from "../../../components/ButtonBanner";
import { EquityOverviewCard } from "../../../components/EquityOverviewCard";
import { EsopPoolChartCard } from "../../../components/EsopPoolChartCard";
import { FairMarketValueHistoryCard } from "../../../components/FairMarketValueHistoryCard";
import { FairMarketValueHistoryChart } from "../../../components/FairMarketValueHistoryChart";
import { GrantedSharesBreakdownCard } from "../../../components/GrantedSharesBreakdownCard";
import {
  NewEquitySelectionModal,
  useNewEquitySelectionModalState,
} from "../../../components/NewEquitySelectionModal/NewEquitySelectionModal";
import { OrganizationInfoCard } from "../../../components/OrganizationInfoCard";
import { Page } from "../../../components/Page";
import { PricePerShareHistoryCard } from "../../../components/PricePerShareHistoryCard";
import { PricePerShareHistoryChart } from "../../../components/PricePerShareHistoryChart";
import { AdminTasksCard } from "../../../components/Tasks/AdminTasksCard";
import { Divider } from "../../../components/ui/Divider";
import { CenteredColumnLayout } from "../../../components/ui/Layout/CenteredColumnLayout";
import { NoticeMessage } from "../../../components/ui/NoticeMessage";
import { Tag } from "../../../components/ui/Tag";
import { Typography } from "../../../components/ui/Typography";
import { ValuationWarningMessage } from "../../../components/ValuationWarningMessage";
import { useApplicationName } from "../../../hooks/useApplicationTheme";
import useIsFeatureEnabled from "../../../hooks/useIsFeatureEnabled";
import { useNavatticPopup } from "../../../hooks/useNavatticPopup";
import { useQuery } from "../../../hooks/useQuery";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import {
  AdvancedOverview_Admin_Organization$data,
  AdvancedOverview_Admin_Organization$key,
} from "./__generated__/AdvancedOverview_Admin_Organization.graphql";
import { AdvancedOverview_Admin_Query } from "./__generated__/AdvancedOverview_Admin_Query.graphql";
import { AdvancedOverview_Admin_StripeCustomer$key } from "./__generated__/AdvancedOverview_Admin_StripeCustomer.graphql";
import { AdvancedOverview_Admin_Viewer$key } from "./__generated__/AdvancedOverview_Admin_Viewer.graphql";
import { OnboardingSlider } from "./OnboardingSlider";
import { OverviewHero } from "./OverviewHero";

const VIEWER_FRAGMENT = graphql`
  fragment AdvancedOverview_Admin_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    firstName
    lastName
    boardMembers {
      organization {
        id
      }
      ...AdminTasksCard_BoardMember
    }
    isAllowedToManageOrganization(organizationId: $organizationId)
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment AdvancedOverview_Admin_Organization on Organization
  @refetchable(queryName: "AdvancedOverview_Admin_Organization_RefetchQuery") {
    id
    planIsFreemium
    hasBeenOnboarded
    name
    isOriginatingFromRemoteEquity
    remoteEquityPlanThresholdsExceeded
    meAsAnAdmin {
      geographiesPageHasBeenVisited: pageHasBeenVisited(page: GEOGRAPHIES)
      granteePortalSettingsPageHasBeenVisited: pageHasBeenVisited(
        page: GRANTEE_PORTAL_SETTINGS
      )
      insightsPageHasBeenVisited: pageHasBeenVisited(page: INSIGHTS)
    }
    valuationWarnings {
      reason
      valuationType
      ...ValuationWarningMessage_ValuationWarning
    }
    ...ValuationWarningMessage_Organization
    ...OrganizationInfoCard_Organization
    ...AdminEntitiesOverview_Organization
    ...EsopPoolChartCard_Organization
    ...PricePerShareHistoryCard_Organization
    ...PricePerShareHistoryChart_Organization
    ...AdminTasksCard_Organization
    ...AdminUpcomingCard_Organization
    ...EquityOverviewCard_Organization
    ...FairMarketValueHistoryCard_Organization
    ...FairMarketValueHistoryChart_Organization
    ...OverviewHero_Organization
    ...GrantedSharesBreakdownCard_Organization
    ...OnboardingSlider_Organization
  }
`;

const STRIPE_ACCOUNT_FRAGMENT = graphql`
  fragment AdvancedOverview_Admin_StripeCustomer on StripeCustomer {
    ...OnboardingSlider_StripeCustomer
  }
`;

const ButtonBanners: React.FC<{
  organization: AdvancedOverview_Admin_Organization$data;
}> = ({ organization }) => {
  if (!organization.meAsAnAdmin) {
    return null;
  }

  const showInsightsBanner =
    organization.planIsFreemium ||
    !organization.meAsAnAdmin.insightsPageHasBeenVisited;

  const showGeographiesBanner =
    organization.planIsFreemium ||
    !organization.meAsAnAdmin.geographiesPageHasBeenVisited;

  const showGranteePortalSettingsBanner =
    organization.planIsFreemium ||
    !organization.meAsAnAdmin.granteePortalSettingsPageHasBeenVisited;

  const someBannerIsVisible =
    showInsightsBanner ||
    showGeographiesBanner ||
    showGranteePortalSettingsBanner;

  if (organization.isOriginatingFromRemoteEquity) {
    return null;
  }

  if (!someBannerIsVisible) {
    return null;
  }

  return (
    <div className="space-y-4">
      {showInsightsBanner && (
        <ButtonBanner
          icon={
            <ButtonBanner.Icon color="green" icon={PresentationChartBarIcon} />
          }
          subtitle="Discover the equity compensation insights you always dreamed of"
          tag={<Tag color="green">Insights</Tag>}
          to={generatePath(APPLICATION_ROUTES.organizationInsights, {
            organizationId: organization.id,
          })}
        >
          Discover your equity compensation insights
        </ButtonBanner>
      )}
      {showGeographiesBanner && (
        <ButtonBanner
          icon={<ButtonBanner.Icon color="blue" icon={BookOpenIcon} />}
          subtitle="Keep an eye on legal obligations in 70+ countries and get local documentation sets"
          tag={<Tag color="primary">Global compliance</Tag>}
          to={generatePath(APPLICATION_ROUTES.organizationCountries, {
            organizationId: organization.id,
          })}
        >
          Explore our global knowledge base
        </ButtonBanner>
      )}
      {showGranteePortalSettingsBanner && (
        <ButtonBanner
          icon={<ButtonBanner.Icon color="purple" icon={ChartBarSquareIcon} />}
          subtitle="Increase your team awareness around equity, local taxation and reporting obligations"
          tag={<Tag color="purple">Employee education</Tag>}
          to={generatePath(
            APPLICATION_ROUTES.organizationToolsGranteePortalSettings,
            {
              organizationId: organization.id,
            },
          )}
        >
          Improve the employee experience with grantee portals
        </ButtonBanner>
      )}
    </div>
  );
};

const AdminOverviewPage_: React.FC<{
  onGrantCreated?: () => void;
  organizationFragment: AdvancedOverview_Admin_Organization$key;
  stripeCustomerFragment: AdvancedOverview_Admin_StripeCustomer$key | null;
  viewerFragment: AdvancedOverview_Admin_Viewer$key;
}> = ({
  onGrantCreated,
  organizationFragment,
  stripeCustomerFragment,
  viewerFragment,
}) => {
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const [organization, _refetchOrganization] = useRefetchableFragment(
    ORGANIZATION_FRAGMENT,
    organizationFragment,
  );
  const stripeCustomer =
    useFragment(STRIPE_ACCOUNT_FRAGMENT, stripeCustomerFragment) ?? null;

  const refectchOrganization = useCallback(() => {
    startTransition(() => {
      _refetchOrganization({});
    });
  }, [_refetchOrganization]);

  const {
    hideNewEquitySelectionModal,
    newEquitySelectionModalState,
    showNewEquitySelectionModal,
  } = useNewEquitySelectionModalState();

  const boardMember = useMemo(
    () =>
      viewer.boardMembers.find(
        (boardMember) => boardMember.organization.id === organization.id,
      ),
    [viewer.boardMembers, organization.id],
  );

  const productDemoTourFeatureIsEnabled =
    useIsFeatureEnabled("productDemoTour");
  const navatticPopup = useNavatticPopup({
    title: "Remote Equity Product Tour",
    url: "https://capture.navattic.com/clwq3q49m000o09lceazs95af",
  });
  const applicationName = useApplicationName();

  return (
    <CenteredColumnLayout showFooter>
      <div className="space-y-10">
        <NewEquitySelectionModal
          onClose={hideNewEquitySelectionModal}
          organizationId={organization.id}
          state={newEquitySelectionModalState}
        />

        <div className="space-y-6">
          <div className="flex items-center justify-between gap-2">
            <Typography as="div" variant="Medium/Large">
              {organization.hasBeenOnboarded ? (
                <>
                  Welcome back, {viewer.firstName} {viewer.lastName}!
                </>
              ) : (
                <>
                  👋 Welcome to {applicationName}, {viewer.firstName}{" "}
                  {viewer.lastName}!
                </>
              )}
            </Typography>
            {viewer.isAllowedToManageOrganization && (
              <OnboardingSlider
                onGrantCreated={onGrantCreated}
                organizationFragment={organization}
                stripeCustomerFragment={stripeCustomer}
              />
            )}
          </div>
          <Divider />
        </div>

        <ButtonBanners organization={organization} />

        {viewer.isAllowedToManageOrganization &&
          organization.hasBeenOnboarded && (
            <OverviewHero organizationFragment={organization} />
          )}

        <div className="space-y-6">
          {organization.remoteEquityPlanThresholdsExceeded && (
            <NoticeMessage size="Large" variant="Warning">
              You have reached the maximum amount of employees or countries
              allowed under your current plan. Your account manager will reach
              out to you shortly to upgrade it.
            </NoticeMessage>
          )}
          {organization.valuationWarnings.map((warning) => (
            <ValuationWarningMessage
              key={`${warning.reason}-${warning.valuationType}`}
              onValuationSentForReview={refectchOrganization}
              organizationFragment={organization}
              valuationWarningFragment={warning}
            />
          ))}

          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-6">
              {viewer.isAllowedToManageOrganization &&
                !organization.planIsFreemium && (
                  <AdminTasksCard
                    boardMemberFragment={boardMember ?? null}
                    organizationFragment={organization}
                  />
                )}
              <EquityOverviewCard
                onCreateGrantButtonClick={() => {
                  showNewEquitySelectionModal({ granteeFragment: null });
                }}
                organizationFragment={organization}
              />
              <EsopPoolChartCard organizationFragment={organization} />
              <GrantedSharesBreakdownCard organizationFragment={organization} />
              <PricePerShareHistoryCard organizationFragment={organization}>
                <PricePerShareHistoryChart
                  organizationFragment={organization}
                />
              </PricePerShareHistoryCard>
            </div>
            <div className="space-y-6">
              {!organization.planIsFreemium && (
                <AdminUpcomingCard organizationFragment={organization} />
              )}
              <OrganizationInfoCard organizationFragment={organization} />
              <AdminEntitiesOverview organizationFragment={organization} />
              <FairMarketValueHistoryCard organizationFragment={organization}>
                <FairMarketValueHistoryChart
                  organizationFragment={organization}
                />
              </FairMarketValueHistoryCard>

              {productDemoTourFeatureIsEnabled && (
                <Button onClick={navatticPopup.openPopup}>Take a tour</Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </CenteredColumnLayout>
  );
};

const QUERY = graphql`
  query AdvancedOverview_Admin_Query($organizationId: OrganizationId!) {
    me {
      ...AdvancedOverview_Admin_Viewer
        @arguments(organizationId: $organizationId)
    }
    organization(id: $organizationId) {
      id
      name
      ...AdvancedOverview_Admin_Organization
    }
    organizationStripeAccount(organizationId: $organizationId) {
      ...AdvancedOverview_Admin_StripeCustomer
    }
  }
`;

const AdminAdvancedOverviewPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query, refreshQuery } = useQuery<AdvancedOverview_Admin_Query>(
    QUERY,
    {
      organizationId,
    },
  );

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsName="Admin - Home"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} overview`}
    >
      <AdminOverviewPage_
        onGrantCreated={refreshQuery}
        organizationFragment={query.organization}
        stripeCustomerFragment={query.organizationStripeAccount}
        viewerFragment={query.me}
      />
    </Page>
  );
};

export default AdminAdvancedOverviewPage;
