/**
 * @generated SignedSource<<d5599ef5c7dbc98942d960197eb699b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FairMarketValuePage_CompanySettings_Query$variables = {
  organizationId: string;
};
export type FairMarketValuePage_CompanySettings_Query$data = {
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"FairMarketValuePage_CompanySettings_Organization">;
  } | null;
};
export type FairMarketValuePage_CompanySettings_Query = {
  response: FairMarketValuePage_CompanySettings_Query$data;
  variables: FairMarketValuePage_CompanySettings_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "boardDetermined",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tag",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FairMarketValuePage_CompanySettings_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "FairMarketValuePage_CompanySettings_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FairMarketValuePage_CompanySettings_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FairMarketValue",
            "kind": "LinkedField",
            "name": "fairMarketValueHistory",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "expirationDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isLatest",
                "storageKey": null
              },
              (v2/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "FairMarketValue",
            "kind": "LinkedField",
            "name": "latestFairMarketValue",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToCarta",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PricePerShare",
            "kind": "LinkedField",
            "name": "pricePerShareHistory",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "defaultCurrencyCode",
            "storageKey": null
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isIOrganization"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6e99125cc65959449807e7dda5b3e9e9",
    "id": null,
    "metadata": {},
    "name": "FairMarketValuePage_CompanySettings_Query",
    "operationKind": "query",
    "text": "query FairMarketValuePage_CompanySettings_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    id\n    name\n    ...FairMarketValuePage_CompanySettings_Organization\n  }\n}\n\nfragment CurrencyUnit_Organization on Organization {\n  defaultCurrencyCode\n}\n\nfragment FairMarketValueHistoryChart_Organization on Organization {\n  ...ValuationHistoryChart_Organization\n}\n\nfragment FairMarketValuePage_CompanySettings_Organization on Organization {\n  id\n  name\n  fairMarketValueHistory {\n    value\n    date\n    expirationDate\n    isLatest\n    id\n  }\n  latestFairMarketValue {\n    value\n    date\n    id\n  }\n  ...LargeOneColumnLayout_Organization\n  ...FairMarketValueHistoryChart_Organization\n  ...FormattedCurrency_Organization\n  ...CurrencyUnit_Organization\n}\n\nfragment Footer_Organization on Organization {\n  isConnectedToCarta\n}\n\nfragment FormattedCurrency_Organization on IOrganization {\n  __isIOrganization: __typename\n  defaultCurrencyCode\n}\n\nfragment FormattedFMV_Organization on IOrganization {\n  __isIOrganization: __typename\n  ...FormattedCurrency_Organization\n}\n\nfragment LargeOneColumnLayout_Organization on Organization {\n  ...Footer_Organization\n}\n\nfragment ValuationHistoryChart_Organization on Organization {\n  name\n  pricePerShareHistory {\n    value\n    date\n    boardDetermined\n    tag\n    id\n  }\n  fairMarketValueHistory {\n    value\n    date\n    boardDetermined\n    tag\n    id\n  }\n  ...FormattedFMV_Organization\n}\n"
  }
};
})();

(node as any).hash = "0c7389512295cefea44245855994ca0a";

export default node;
