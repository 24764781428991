import { generatePath, Outlet } from "react-router-dom";
import { graphql } from "relay-runtime";

import { Page } from "../../../components/Page";
import { LargeCenteredDetailsLayout } from "../../../components/ui/Layout/LargeCenteredDetailsLayout";
import { TabNavigation } from "../../../components/ui/TabNavigation";
import { useQuery } from "../../../hooks/useQuery";
import {
  APPLICATION_ROUTES,
  useCountryCodeParam,
  useOrganizationIdParam,
} from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { EquityHandbooksCountryLayout_Query } from "./__generated__/EquityHandbooksCountryLayout_Query.graphql";
import { EquityHandbooksCountryEquityTypeEmptyState } from "./EquityHandbooksCountryEquityTypeEmptyState";

const QUERY = graphql`
  query EquityHandbooksCountryLayout_Query(
    $organizationId: OrganizationId!
    $countryCode: String!
  ) {
    organizationGeography(
      countryCode: $countryCode
      organizationId: $organizationId
    ) {
      country {
        code
        displayName
      }
      geography {
        equityTypes {
          id
          humanReadableName
        }
      }
    }
  }
`;

export default function EquityHandbooksCountryLayout() {
  const organizationId = useOrganizationIdParam();
  const countryCode = useCountryCodeParam();
  const {
    query: { organizationGeography },
  } = useQuery<EquityHandbooksCountryLayout_Query>(QUERY, {
    countryCode,
    organizationId,
  });

  if (!organizationGeography) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsName={`Admin - Equity handbooks - ${organizationGeography.country.displayName}`}
      organizationId={organizationId}
      title={`Admin | Equity handbooks - ${organizationGeography.country.displayName}`}
    >
      <LargeCenteredDetailsLayout
        footerHelpCard={null}
        navigationTitle={organizationGeography.country.displayName}
        organizationFragment={null}
        parents={[
          {
            link: generatePath(APPLICATION_ROUTES.organizationEquityHandbooks, {
              organizationId: organizationId,
            }),
            title: "Equity handbooks",
          },
        ]}
        subtitle={`Discover what you (including your subsidiary) and the employee have to do to comply with local rules and how this equity incentive will be taxed in ${organizationGeography.country.displayName}.`}
        title={`${organizationGeography.country.displayName} - Equity handbook`}
      >
        {organizationGeography.geography.equityTypes.length > 0 ? (
          <div className="space-y-6">
            <TabNavigation layoutId="equity-type-handbooks-geographies-tab-navigation">
              {organizationGeography.geography.equityTypes.map((equityType) => (
                <TabNavigation.Link
                  key={equityType.id}
                  to={generatePath(
                    APPLICATION_ROUTES.organizationEquityHandbooksCountryEquityType,
                    {
                      countryCode: organizationGeography.country.code,
                      equityTypeId: equityType.id,
                      organizationId: organizationId,
                    },
                  )}
                >
                  {equityType.humanReadableName}
                </TabNavigation.Link>
              ))}
            </TabNavigation>
            <div>
              <Outlet />
            </div>
          </div>
        ) : (
          <EquityHandbooksCountryEquityTypeEmptyState />
        )}
      </LargeCenteredDetailsLayout>
    </Page>
  );
}
