import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Avatar } from "@remote-com/norma";
import { useCallback } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useSafeMutation } from "../hooks/useSafeMutation";
import { SettingsContainer } from "../pages/Admin/OrganizationSettings/SettingsContainer";
import { RemoteCompanyAdminsList_Account$key } from "./__generated__/RemoteCompanyAdminsList_Account.graphql";
import { RemoteCompanyAdminsList_Organization$key } from "./__generated__/RemoteCompanyAdminsList_Organization.graphql";
import { RemoteCompanyAdminsList_SetRemoteCompanyAdminAllowedAsAdmin_Mutation } from "./__generated__/RemoteCompanyAdminsList_SetRemoteCompanyAdminAllowedAsAdmin_Mutation.graphql";
import { Toggle } from "./ui/Form/Toggle";
import { RemoteLogo } from "./ui/RemoteLogo";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment RemoteCompanyAdminsList_Organization on Organization {
    id
    name
    remoteCompanyAdmins(filters: { orderBy: { direction: ASC, field: name } }) {
      id
      userSlug
      name
      email
      allowedAsAdmin
    }
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment RemoteCompanyAdminsList_Account on Account {
    remoteUserSlug
  }
`;

const SET_REMOTE_COMPANY_ADMIN_ALLOWED_AS_ADMIN_MUTATION = graphql`
  mutation RemoteCompanyAdminsList_SetRemoteCompanyAdminAllowedAsAdmin_Mutation(
    $allowedAsAdmin: Boolean!
    $remoteCompanyAdminId: String!
  ) {
    setRemoteCompanyAdminAllowedAsAdmin(
      allowedAsAdmin: $allowedAsAdmin
      remoteCompanyAdminId: $remoteCompanyAdminId
    ) {
      ...RemoteCompanyAdminsList_Organization
    }
  }
`;

export const RemoteCompanyAdminsList: React.FC<{
  organizationFragment: RemoteCompanyAdminsList_Organization$key;
  viewerFragment: RemoteCompanyAdminsList_Account$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  const [setRemoteCompanyAdminAllowedAsAdmin] =
    useSafeMutation<RemoteCompanyAdminsList_SetRemoteCompanyAdminAllowedAsAdmin_Mutation>(
      SET_REMOTE_COMPANY_ADMIN_ALLOWED_AS_ADMIN_MUTATION,
    );
  const handleSetRemoteCompanyAdminAllowedAsAdminButtonClick = useCallback(
    async ({
      allowedAsAdmin,
      remoteCompanyAdminId,
    }: {
      allowedAsAdmin: boolean;
      remoteCompanyAdminId: string;
    }) => {
      await setRemoteCompanyAdminAllowedAsAdmin({
        variables: { allowedAsAdmin, remoteCompanyAdminId },
      });
    },
    [setRemoteCompanyAdminAllowedAsAdmin],
  );

  return (
    <SettingsContainer.Item.MultipleValues>
      {organization.remoteCompanyAdmins.map((remoteCompanyAdmin) => {
        const showAction =
          remoteCompanyAdmin.userSlug !== viewer.remoteUserSlug;

        return (
          <SettingsContainer.Item.MultipleValues.Item
            className="group flex items-center gap-2 transition-all hover:bg-gray-01"
            key={remoteCompanyAdmin.id}
          >
            <Avatar name={remoteCompanyAdmin.name} size="sm" />
            <div className="flex-1 space-y-1">
              <Typography as="div" variant="Regular/Extra Small">
                {remoteCompanyAdmin.name}
              </Typography>
              <Typography
                as="div"
                className="text-black-05"
                variant="Regular/Extra Small"
              >
                {remoteCompanyAdmin.email}
              </Typography>
            </div>
            {showAction && (
              <div className="flex items-center gap-4">
                <div className="flex items-center gap-2">
                  <ArrowPathIcon className="h-4" />
                  <RemoteLogo className="h-4" />
                </div>
                <Toggle
                  enabled={remoteCompanyAdmin.allowedAsAdmin}
                  onChange={() =>
                    handleSetRemoteCompanyAdminAllowedAsAdminButtonClick({
                      allowedAsAdmin: !remoteCompanyAdmin.allowedAsAdmin,
                      remoteCompanyAdminId: remoteCompanyAdmin.id,
                    })
                  }
                />
              </div>
            )}{" "}
          </SettingsContainer.Item.MultipleValues.Item>
        );
      })}
    </SettingsContainer.Item.MultipleValues>
  );
};
