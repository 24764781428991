import React from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { Page } from "../../../../components/Page";
import { SafeSimulatorGraphSection } from "../../../../components/SafeSimulatorGraphSection";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { useQuery } from "../../../../hooks/useQuery";
import {
  useOrganizationIdParam,
  useSafeCalculatorVersionIdParam,
} from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { SafeSimulatorCalculatorContent } from "../../../SafeSimulator/Calculator/SafeSimulatorCalculatorContent";
import { useSafeSimulatorContext } from "../../../SafeSimulator/IContext";
import { OrganizationSettingsSafeCalculatorVersion_Account$key } from "./__generated__/OrganizationSettingsSafeCalculatorVersion_Account.graphql";
import { OrganizationSettingsSafeCalculatorVersion_Organization$key } from "./__generated__/OrganizationSettingsSafeCalculatorVersion_Organization.graphql";
import { OrganizationSettingsSafeCalculatorVersion_Query } from "./__generated__/OrganizationSettingsSafeCalculatorVersion_Query.graphql";
import { OrganizationSettingsSafeCalculatorVersionContextProvider } from "./OrganizationSettingsSafeCalculatorVersionContext";

const ORGANIZATION_FRAGMENT = graphql`
  fragment OrganizationSettingsSafeCalculatorVersion_Organization on Organization {
    id
    ...SafeSimulatorCalculatorContent_Organization
    ...SafeSimulatorGraphSection_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment OrganizationSettingsSafeCalculatorVersion_Account on Account {
    isSuperAdmin
  }
`;

const OrganizationSettingsSafeCalculatorVersionPage_: React.FC<{
  organizationFragment: OrganizationSettingsSafeCalculatorVersion_Organization$key;
  viewerFragment: OrganizationSettingsSafeCalculatorVersion_Account$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  const {
    computedValues: {
      currentShareholdersShares,
      postConversionCurrentPricePerShare,
      postConversionFullyDilutedShares,
      postConversionSafeShares,
    },
    state: { optionPoolShares },
  } = useSafeSimulatorContext();

  return (
    <div className="flex items-start gap-6">
      <div className="space-y-4">
        <SafeSimulatorCalculatorContent
          organizationFragment={organization}
          readOnly={!viewer.isSuperAdmin}
        />
      </div>
      <RoundedBox withBorder>
        <SafeSimulatorGraphSection
          className="w-[296px] px-6 py-10"
          currentShareholdersShares={currentShareholdersShares}
          optionPoolShares={optionPoolShares}
          organizationFragment={organization}
          postConversionCurrentPricePerShare={
            postConversionCurrentPricePerShare
          }
          postConversionLatestValuation={
            postConversionCurrentPricePerShare !== null &&
            postConversionFullyDilutedShares !== null
              ? postConversionCurrentPricePerShare *
                postConversionFullyDilutedShares
              : null
          }
          postConversionSafeShares={postConversionSafeShares}
        />
      </RoundedBox>
    </div>
  );
};

const QUERY = graphql`
  query OrganizationSettingsSafeCalculatorVersion_Query(
    $organizationId: OrganizationId!
    $safeCalculatorVersionId: ID!
  ) {
    organization(id: $organizationId) {
      id
      name
      ...OrganizationSettingsSafeCalculatorVersion_Organization
    }
    safeCalculatorVersion(id: $safeCalculatorVersionId) {
      id
      ...OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion
    }
    me {
      ...OrganizationSettingsSafeCalculatorVersion_Account
    }
  }
`;

const OrganizationSettingsSafeCalculatorVersionPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const safeCalculatorVersionId = useSafeCalculatorVersionIdParam();
  const {
    query: { me, organization, safeCalculatorVersion },
  } = useQuery<OrganizationSettingsSafeCalculatorVersion_Query>(QUERY, {
    organizationId,
    safeCalculatorVersionId,
  });

  if (!organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Admin Settings"
      analyticsName="Admin - SAFE converter version"
      key={safeCalculatorVersion.id}
      organizationId={organization.id}
      title={`Admin | ${organization.name} SAFE converter version`}
    >
      <OrganizationSettingsSafeCalculatorVersionContextProvider
        safeCalculatorVersionFragment={safeCalculatorVersion}
      >
        <OrganizationSettingsSafeCalculatorVersionPage_
          organizationFragment={organization}
          viewerFragment={me}
        />
      </OrganizationSettingsSafeCalculatorVersionContextProvider>
    </Page>
  );
};

export default OrganizationSettingsSafeCalculatorVersionPage;
