import { BoxedIcon, Button, Skeleton, Text } from "@remote-com/norma";
import { IconV2DuotoneCheckSquare } from "@remote-com/norma/icons/IconV2DuotoneCheckSquare";
import { IconV2OutlineInfo } from "@remote-com/norma/icons/IconV2OutlineInfo";
import { Suspense } from "react";
import { useFragment } from "react-relay";
import { Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import CONFIGURATION from "../services/configuration";
import {
  GrantDeclarationBillingInformationSection_Deferred_Organization$data,
  GrantDeclarationBillingInformationSection_Deferred_Organization$key,
} from "./__generated__/GrantDeclarationBillingInformationSection_Deferred_Organization.graphql";
import { GrantDeclarationBillingInformationSection_Organization$key } from "./__generated__/GrantDeclarationBillingInformationSection_Organization.graphql";
import { FormattedCustomCurrency } from "./Formatted/FormattedCurrency";
import { MissingInformationTag } from "./MissingInformationTag";
import { Divider } from "./ui/Divider";
import {
  CheckoutLikeLayoutMainCard,
  CheckoutLikeLayoutMainTitle,
} from "./ui/Layout/CheckoutLikeLayout";
import { SkeletonWrapper } from "./ui/SkeletonWrapper";
import { TooltipContainer } from "./ui/TooltipContainer";

const NotIncludedInPlanGrantDeclarationBillingInformationSection: React.FC<{
  formId: string;
  mutationIsInFlight: boolean;
  price: GrantDeclarationBillingInformationSection_Deferred_Organization$data["essentialsEmploymentPrice"];
  skeleton?: boolean;
}> = ({ formId, mutationIsInFlight, price, skeleton }) => {
  return (
    <GrantDeclarationBillingInformationSectionWrapper
      formId={formId}
      mutationIsInFlight={mutationIsInFlight}
      submitButtonLabel="Accept and declare grant"
    >
      <div className="rounded-2xl border border-grey-200">
        <div className="flex items-center justify-between gap-2 rounded-3xl border-8 border-white bg-grey-50 p-4">
          <Text variant="lgMedium">Equity admin fee</Text>
        </div>
        <div className="space-y-4 p-6 pt-2">
          <div className="space-y-1">
            <Text className="text-grey-500" variant="xs">
              Per month
            </Text>
            <SkeletonWrapper reveal={!skeleton}>
              <Text variant="baseMedium">
                {price ? (
                  <>
                    <FormattedCustomCurrency
                      currency={price.currencyCode}
                      minimumFractionDigits={0}
                      value={price.amount}
                    />{" "}
                    {price.currencyCode}
                  </>
                ) : (
                  <MissingInformationTag />
                )}
              </Text>
            </SkeletonWrapper>
          </div>
          <div className="space-y-1">
            <Text className="text-grey-500" variant="xs">
              Description
            </Text>
            <Text variant="sm">
              Remote should be notified of any equity grants made, and any
              related taxable events (e.g., exercise of stock options, vesting
              of RSUs).
            </Text>
          </div>
        </div>
      </div>
      <Divider />
      <div className="flex items-center justify-between gap-4 rounded-lg border border-grey-200 bg-grey-50 px-4 py-3">
        <div className="flex items-center gap-1 text-grey-600">
          <Text variant="smMedium">
            To be added to the employee&apos;s monthly EOR costs
          </Text>
          <TooltipContainer
            tooltipContent={
              <>
                You&apos;ll see this charge on your
                <br />
                EOR reconciliation invoices.
              </>
            }
          >
            <IconV2OutlineInfo className="h-3.5 w-3.5" />
          </TooltipContainer>
        </div>
        <SkeletonWrapper reveal={!skeleton}>
          {price ? (
            <div className="flex items-center gap-1">
              <Text variant="baseMedium">
                <FormattedCustomCurrency
                  currency={price.currencyCode}
                  minimumFractionDigits={0}
                  signDisplay="always"
                  value={price.amount}
                />
              </Text>
              <Text className="text-grey-600" variant="sm">
                / month
              </Text>
            </div>
          ) : (
            <MissingInformationTag />
          )}
        </SkeletonWrapper>
      </div>
      <div className="h-6 text-right text-grey-500">
        <Text variant="xs">
          *Check your{" "}
          <Link
            className="text-brand-600"
            target="_blank"
            to={CONFIGURATION.REMOTE_APP_URL}
          >
            company details
          </Link>{" "}
          for more pricing details
        </Text>
      </div>
    </GrantDeclarationBillingInformationSectionWrapper>
  );
};

const IncludedInPlanGrantDeclarationBillingInformationSection: React.FC<{
  formId: string;
  mutationIsInFlight: boolean;
}> = ({ formId, mutationIsInFlight }) => {
  return (
    <GrantDeclarationBillingInformationSectionWrapper
      formId={formId}
      mutationIsInFlight={mutationIsInFlight}
      submitButtonLabel="Declare grant"
    />
  );
};

const GrantDeclarationBillingInformationSectionWrapper: React.FC<{
  children?: React.ReactNode;
  formId: string;
  mutationIsInFlight: boolean;
  submitButtonLabel: React.ReactNode;
}> = ({ children, formId, mutationIsInFlight, submitButtonLabel }) => {
  return (
    <CheckoutLikeLayoutMainCard>
      <CheckoutLikeLayoutMainTitle
        description="Please confirm the grant declaration and associated costs"
        illustration={<BoxedIcon Icon={IconV2DuotoneCheckSquare} tone="cyan" />}
        title="Confirm grant declaration"
      />
      <div className="space-y-4">
        {children}
        <Button
          className="w-full"
          disabled={mutationIsInFlight}
          form={formId}
          isLoading={mutationIsInFlight}
          type="submit"
        >
          {submitButtonLabel}
        </Button>
        <Text className="text-grey-700" variant="xs">
          These declarations are essential in order for Remote to take care of
          local reporting obligations relating to equity compensation, without
          missing deadlines and incurring fines and other penalties.
        </Text>
      </div>
    </CheckoutLikeLayoutMainCard>
  );
};

const _GrantDeclarationBillingInformationSection: React.FC<{
  formId: string;
  includedInPlan: boolean;
  mutationIsInFlight: boolean;
  price: GrantDeclarationBillingInformationSection_Deferred_Organization$data["essentialsEmploymentPrice"];
  skeleton?: boolean;
}> = ({ includedInPlan, price, ...props }) => {
  if (includedInPlan || price?.amount === 0 || price?.feeIsWaived) {
    return (
      <IncludedInPlanGrantDeclarationBillingInformationSection {...props} />
    );
  }

  return (
    <NotIncludedInPlanGrantDeclarationBillingInformationSection
      {...props}
      price={price}
    />
  );
};

const DEFERRED_ORGANIZATION_FRAGMENT = graphql`
  fragment GrantDeclarationBillingInformationSection_Deferred_Organization on Organization {
    essentialsEmploymentPrice {
      amount
      currencyCode
      feeIsWaived
    }
  }
`;

const Deferred: React.FC<{
  formId: string;
  includedInPlan: boolean;
  mutationIsInFlight: boolean;
  organizationFragment: GrantDeclarationBillingInformationSection_Deferred_Organization$key;
}> = ({ organizationFragment, ...props }) => {
  const organization = useFragment(
    DEFERRED_ORGANIZATION_FRAGMENT,
    organizationFragment,
  );

  return (
    <Suspense fallback={<Skeleton />}>
      <_GrantDeclarationBillingInformationSection
        {...props}
        price={organization.essentialsEmploymentPrice}
      />
    </Suspense>
  );
};

const ORGANIZATION_FRAGMENT = graphql`
  fragment GrantDeclarationBillingInformationSection_Organization on Organization {
    ...GrantDeclarationBillingInformationSection_Deferred_Organization @defer
  }
`;

export const GrantDeclarationBillingInformationSection: React.FC<{
  formId: string;
  includedInPlan: boolean;
  mutationIsInFlight: boolean;
  organizationFragment: GrantDeclarationBillingInformationSection_Organization$key;
}> = ({ organizationFragment, ...props }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <Suspense
      fallback={
        <_GrantDeclarationBillingInformationSection
          {...props}
          price={null}
          skeleton
        />
      }
    >
      <Deferred organizationFragment={organization} {...props} />
    </Suspense>
  );
};
