/**
 * @generated SignedSource<<895522f3e8f61540b62d2d30340823c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateSlideOver_VestingSchedule_Organization$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"VestingSchedulePreview_Organization" | "useVestingScheduleForm_Organization">;
  readonly " $fragmentType": "CreateSlideOver_VestingSchedule_Organization";
};
export type CreateSlideOver_VestingSchedule_Organization$key = {
  readonly " $data"?: CreateSlideOver_VestingSchedule_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateSlideOver_VestingSchedule_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateSlideOver_VestingSchedule_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useVestingScheduleForm_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VestingSchedulePreview_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "f3bc4c3ecf1a31aaa231fb39d61d388c";

export default node;
