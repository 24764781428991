import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@remote-com/norma";
import { ArrowRight } from "iconsax-react";
import { useId, useMemo } from "react";
import { Controller, DeepPartial, useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { RemoteInputSelect } from "../../../../components/ui/Form/Inputs/RemoteInputSelect";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import {
  EssentialsOnboardingTemplate,
  EssentialsOnboardingTemplateFooter,
} from "../EssentialsOnboardingTemplate";
import { CTMSPage_Organization$key } from "./__generated__/CTMSPage_Organization.graphql";
import { CTMSPage_SetEssentialsCTMSOnboardingStepAsCompleted_Mutation } from "./__generated__/CTMSPage_SetEssentialsCTMSOnboardingStepAsCompleted_Mutation.graphql";
import illustration from "./illustration.png";
import {
  EssentialsOnboardingRadioCardWithIndicatorAndSelect,
  EssentialsOnboardingRadioGroup,
} from "./RadioCardWithIndicatorAndSelect";

const ORGANIZATION_FRAGMENT = graphql`
  fragment CTMSPage_Organization on Organization {
    id
    declaredCTMSSolution
  }
`;

const SET_ESSENTIALS_CTMS_ONBOARDING_STEP_AS_COMPLETED_MUTATION = graphql`
  mutation CTMSPage_SetEssentialsCTMSOnboardingStepAsCompleted_Mutation(
    $declaredCTMSSolution: CTMSSolution
    $organizationId: OrganizationId!
  ) {
    setEssentialsCTMSOnboardingStepAsCompleted(
      declaredCTMSSolution: $declaredCTMSSolution
      organizationId: $organizationId
    ) {
      declaredCTMSSolution
    }
  }
`;

const CTMSSolutions = [
  "ANGELLIST",
  "ASTRELLA",
  "CAPDESK",
  "CARTA",
  "CERTENT",
  "COMPTRACK",
  "EQUIFY",
  "GLOBAL_SHARES",
  "LEDGY",
  "LTSE_EQUITY",
  "PULLEY",
  "SHAREWORKS",
  "VESTD",
  "OTHER",
] as const;

type CTMSSolution = (typeof CTMSSolutions)[number];

export const CTMSSolutionLabelMap: Record<
  "NO_CTMS" | "SPREADSHEETS" | CTMSSolution,
  string
> = {
  ANGELLIST: "AngelList",
  ASTRELLA: "Astella",
  CAPDESK: "Capdesk",
  CARTA: "Carta",
  CERTENT: "Certent",
  COMPTRACK: "Comptrack",
  EQUIFY: "Equify",
  GLOBAL_SHARES: "Global Shares",
  LEDGY: "Ledgy",
  LTSE_EQUITY: "LTSE Equity",
  NO_CTMS: "No CTMS",
  OTHER: "Other",
  PULLEY: "Pulley",
  SHAREWORKS: "Shareworks",
  SPREADSHEETS: "Spreadsheets",
  VESTD: "Vestd",
};

const FORM_SCHEMA = z.discriminatedUnion("type", [
  z.object({
    ctmsSolution: z.enum(CTMSSolutions),
    type: z.literal("ctms"),
  }),
  z.object({
    type: z.literal("spreadsheet"),
  }),
]);

type FormInput = DeepPartial<z.input<typeof FORM_SCHEMA>>;
type FormOutput = z.output<typeof FORM_SCHEMA>;

export default function CTMSPage({
  onNextStep,
  onPreviousStep,
  organizationFragment,
  previousStepIndex,
}: {
  onNextStep: () => void;
  onPreviousStep: () => void;
  organizationFragment: CTMSPage_Organization$key;
  previousStepIndex?: number;
}) {
  const formId = useId();
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const defaultValues = useMemo(() => {
    switch (organization.declaredCTMSSolution) {
      case "NO_CTMS":
      case null:
        return {} satisfies FormInput;
      case "SPREADSHEETS":
        return {
          type: "spreadsheet",
        } satisfies FormInput;
      default:
        return {
          ctmsSolution: organization.declaredCTMSSolution,
          type: "ctms",
        } satisfies FormInput;
    }
  }, [organization.declaredCTMSSolution]);

  const form = useForm<FormInput, unknown, FormOutput>({
    defaultValues,
    resolver: zodResolver(FORM_SCHEMA),
  });

  const [setEssentialsCTMSOnboardingStepAsCompleted, mutationIsInFlight] =
    useSafeMutation<CTMSPage_SetEssentialsCTMSOnboardingStepAsCompleted_Mutation>(
      SET_ESSENTIALS_CTMS_ONBOARDING_STEP_AS_COMPLETED_MUTATION,
    );

  const handleFormSubmit = form.handleSubmit(async (data: unknown) => {
    const formData = FORM_SCHEMA.parse(data);

    if (formData.type === "ctms") {
      await setEssentialsCTMSOnboardingStepAsCompleted({
        variables: {
          declaredCTMSSolution: formData.ctmsSolution,
          organizationId: organization.id,
        },
      });
    } else {
      await setEssentialsCTMSOnboardingStepAsCompleted({
        variables: {
          declaredCTMSSolution: "SPREADSHEETS",
          organizationId: organization.id,
        },
      });
    }

    onNextStep();
  });

  return (
    <EssentialsOnboardingTemplate
      footer={
        <EssentialsOnboardingTemplateFooter
          onPreviousClick={onPreviousStep}
          previousStepIndex={previousStepIndex}
          stepIndex={2}
          stepName="Cap table management system"
          submitButton={
            <Button
              disabled={mutationIsInFlight}
              form={formId}
              IconAfter={ArrowRight}
              isLoading={mutationIsInFlight}
              type="submit"
            >
              Continue
            </Button>
          }
        />
      }
      illustration={illustration}
      subtitle="Let us know how your company manages the equity"
      title="Do you use a cap table management system?"
    >
      <form className="space-y-14" id={formId} onSubmit={handleFormSubmit}>
        <Controller
          control={form.control}
          name="type"
          render={({ field }) => (
            <EssentialsOnboardingRadioGroup
              onChange={field.onChange}
              value={field.value}
            >
              <EssentialsOnboardingRadioCardWithIndicatorAndSelect
                label="We use a cap table management solution"
                select={
                  <RemoteInputSelect.Form
                    control={form.control}
                    label="Select a cap table management solution"
                    menuPortalTarget={document.body}
                    name="ctmsSolution"
                    options={CTMSSolutions.map((solution) => ({
                      label: CTMSSolutionLabelMap[solution],
                      value: solution,
                    }))}
                  />
                }
                value="ctms"
              />
              <EssentialsOnboardingRadioCardWithIndicatorAndSelect
                label="We use spreadsheets (Excel / Google Sheets)"
                value="spreadsheet"
              />
            </EssentialsOnboardingRadioGroup>
          )}
        />
      </form>
    </EssentialsOnboardingTemplate>
  );
}
