import { BoxedIcon, Pill, Text } from "@remote-com/norma";
import { ReactNode } from "react";
import { Link, To } from "react-router-dom";

import { RoundedBox } from "./ui/RoundedBox";

type VersionTag = "Advanced" | "Essentials";

const VersionTagPill: React.FC<{
  tag: VersionTag;
}> = ({ tag }) => {
  switch (tag) {
    case "Advanced":
      return <Pill tone="purple">Advanced</Pill>;
    case "Essentials":
      return <Pill tone="success">Essentials</Pill>;
  }
};

export const SuperAdminBoxLink: React.FC<{
  advancedVersion?: boolean;
  description: ReactNode;
  essentialsVersion?: boolean;
  Icon: React.ElementType;
  tags?: ReactNode[];
  title: ReactNode;
  to: To;
  tone: React.ComponentProps<typeof BoxedIcon>["tone"];
}> = ({
  advancedVersion,
  description,
  essentialsVersion,
  Icon,
  tags,
  title,
  to,
  tone,
}) => {
  return (
    <Link to={to}>
      <RoundedBox className="flex items-center gap-4 px-6 py-4" withBorder>
        <BoxedIcon Icon={Icon} size="sm" tone={tone} />
        <div className="space-y-4">
          <div className="space-y-1">
            <Text as="div" variant="lgMedium">
              {title}
            </Text>
            <Text as="div" className="text-grey-600" variant="sm">
              {description}
            </Text>
          </div>
          {(tags || advancedVersion || essentialsVersion) && (
            <div className="flex gap-1">
              {tags}
              {advancedVersion && <VersionTagPill tag="Advanced" />}
              {essentialsVersion && <VersionTagPill tag="Essentials" />}
            </div>
          )}
        </div>
      </RoundedBox>
    </Link>
  );
};
