/**
 * @generated SignedSource<<b8354f1936e45f2de53a83ef5aa05dac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharesOutstandingCard_EssentialsOverview_Organization$data = {
  readonly poolGrantedSharesBreakdown: {
    readonly total: number;
  };
  readonly poolGrantedToVestSharesBreakdown: {
    readonly total: number;
  };
  readonly poolSettlingSharesBreakdown: {
    readonly total: number;
  };
  readonly poolVestedExercisedSharesBreakdown: {
    readonly total: number;
  };
  readonly poolVestedToExerciseSharesBreakdown: {
    readonly total: number;
  };
  readonly " $fragmentType": "SharesOutstandingCard_EssentialsOverview_Organization";
};
export type SharesOutstandingCard_EssentialsOverview_Organization$key = {
  readonly " $data"?: SharesOutstandingCard_EssentialsOverview_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharesOutstandingCard_EssentialsOverview_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "total",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharesOutstandingCard_EssentialsOverview_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SharesBreakdown",
      "kind": "LinkedField",
      "name": "poolGrantedSharesBreakdown",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SharesBreakdown",
      "kind": "LinkedField",
      "name": "poolGrantedToVestSharesBreakdown",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SharesBreakdown",
      "kind": "LinkedField",
      "name": "poolVestedToExerciseSharesBreakdown",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SharesBreakdown",
      "kind": "LinkedField",
      "name": "poolSettlingSharesBreakdown",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SharesBreakdown",
      "kind": "LinkedField",
      "name": "poolVestedExercisedSharesBreakdown",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "6d937f76f7cb1fa81d8c449ca7af9324";

export default node;
