/**
 * @generated SignedSource<<870336a71a4cfec76d2f7a84c192a420>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VestingSchedules_ConfigureEquity_Query$variables = {
  organizationId: string;
  search?: string | null;
};
export type VestingSchedules_ConfigureEquity_Query$data = {
  readonly organization: {
    readonly foundVestingSchedules: ReadonlyArray<{
      readonly __typename: string;
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"VestingSchedulesTable_VestingScheduleFragment">;
    }>;
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"EditSlideOver_VestingSchedule_Organization">;
  };
};
export type VestingSchedules_ConfigureEquity_Query = {
  response: VestingSchedules_ConfigureEquity_Query$data;
  variables: VestingSchedules_ConfigureEquity_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VestingSchedules_ConfigureEquity_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": "foundVestingSchedules",
              "args": (v4/*: any*/),
              "concreteType": null,
              "kind": "LinkedField",
              "name": "vestingSchedules",
              "plural": true,
              "selections": [
                (v5/*: any*/),
                (v2/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "VestingSchedulesTable_VestingScheduleFragment"
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "EditSlideOver_VestingSchedule_Organization"
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VestingSchedules_ConfigureEquity_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": "foundVestingSchedules",
            "args": (v4/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "vestingSchedules",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v2/*: any*/),
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isVestingSchedule"
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cliffDurationInMonths",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "durationInMonths",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": "backloadedVestingOccurrence",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "vestingOccurrence",
                    "storageKey": null
                  }
                ],
                "type": "BackloadedVestingSchedule",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": "linearVestingOccurrence",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "vestingOccurrence",
                    "storageKey": null
                  }
                ],
                "type": "LinearVestingSchedule",
                "abstractKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "easopGrantCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isUsed",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "vestingSchedules",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "323ea0521c47c4cffc9fc729bd8a6577",
    "id": null,
    "metadata": {},
    "name": "VestingSchedules_ConfigureEquity_Query",
    "operationKind": "query",
    "text": "query VestingSchedules_ConfigureEquity_Query(\n  $organizationId: OrganizationId!\n  $search: String\n) {\n  organization(id: $organizationId) {\n    id\n    name\n    foundVestingSchedules: vestingSchedules(search: $search) {\n      __typename\n      id\n      ...VestingSchedulesTable_VestingScheduleFragment\n    }\n    ...EditSlideOver_VestingSchedule_Organization\n  }\n}\n\nfragment EditSlideOver_VestingSchedule_Organization on Organization {\n  ...useVestingScheduleForm_Organization\n  ...VestingSchedulePreview_Organization\n}\n\nfragment VestingSchedulePreview_Organization on Organization {\n  id\n}\n\nfragment VestingSchedulesTable_VestingScheduleFragment on VestingSchedule {\n  __isVestingSchedule: __typename\n  __typename\n  id\n  name\n  cliffDurationInMonths\n  durationInMonths\n  ... on BackloadedVestingSchedule {\n    backloadedVestingOccurrence: vestingOccurrence\n  }\n  ... on LinearVestingSchedule {\n    linearVestingOccurrence: vestingOccurrence\n  }\n  easopGrantCount\n  isUsed\n}\n\nfragment useFormattedVestingSchedule_Organization on Organization {\n  vestingSchedules {\n    __typename\n    id\n    name\n  }\n}\n\nfragment useVestingScheduleForm_Organization on Organization {\n  ...useFormattedVestingSchedule_Organization\n}\n"
  }
};
})();

(node as any).hash = "a45dd70d788c43d6c564ebeb7bae8a2c";

export default node;
