/**
 * @generated SignedSource<<fc2eb3fba1b17a142438e87859141e50>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VestingScheduleLegend_DraftGrants$data = ReadonlyArray<{
  readonly label: string;
  readonly " $fragmentType": "VestingScheduleLegend_DraftGrants";
}>;
export type VestingScheduleLegend_DraftGrants$key = ReadonlyArray<{
  readonly " $data"?: VestingScheduleLegend_DraftGrants$data;
  readonly " $fragmentSpreads": FragmentRefs<"VestingScheduleLegend_DraftGrants">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "VestingScheduleLegend_DraftGrants",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    }
  ],
  "type": "EasopGrant",
  "abstractKey": null
};

(node as any).hash = "899530ad6cb3d1899939c0e51fcc65ce";

export default node;
