/**
 * @generated SignedSource<<e06c27507e92d40e9b5223379cbeb910>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminLayout_Viewer$data = {
  readonly isSuperAdmin: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"AdvancedApplicationSidebar_Viewer" | "ApplicationSideBar_Viewer" | "BookADemoModal_Viewer" | "EssentialsApplicationSidebar_Viewer">;
  readonly " $fragmentType": "AdminLayout_Viewer";
};
export type AdminLayout_Viewer$key = {
  readonly " $data"?: AdminLayout_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminLayout_Viewer">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminLayout_Viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSuperAdmin",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApplicationSideBar_Viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BookADemoModal_Viewer"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "AdvancedApplicationSidebar_Viewer"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "EssentialsApplicationSidebar_Viewer"
    }
  ],
  "type": "Account",
  "abstractKey": null
};
})();

(node as any).hash = "f2f32cbd32cad7ba43c904221a4f3054";

export default node;
