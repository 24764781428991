/**
 * @generated SignedSource<<78e7183cc5c10fad3d711be220ff888a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoteInputCurrency_Organization$data = {
  readonly defaultCurrencyCode: string;
  readonly " $fragmentType": "RemoteInputCurrency_Organization";
};
export type RemoteInputCurrency_Organization$key = {
  readonly " $data"?: RemoteInputCurrency_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteInputCurrency_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteInputCurrency_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultCurrencyCode",
      "storageKey": null
    }
  ],
  "type": "IOrganization",
  "abstractKey": "__isIOrganization"
};

(node as any).hash = "df73dd207982e84b8ed3a8dddca2db33";

export default node;
