/**
 * @generated SignedSource<<9a34fc78ccae014e7e954d2a85bf73ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteePendingTerminationTag_Grantee$data = {
  readonly terminationInformations: {
    readonly terminationDate: string;
  } | null;
  readonly " $fragmentType": "GranteePendingTerminationTag_Grantee";
};
export type GranteePendingTerminationTag_Grantee$key = {
  readonly " $data"?: GranteePendingTerminationTag_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteePendingTerminationTag_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteePendingTerminationTag_Grantee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "GranteeTerminationInformations",
      "kind": "LinkedField",
      "name": "terminationInformations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "terminationDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "5ce1f06feacac39dcb2b79a62c140258";

export default node;
