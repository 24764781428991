/**
 * @generated SignedSource<<e9f0cd9c3f84da2bfae23a3a617135fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type SubscriptionBillingPeriod = "monthly" | "yearly";
import { FragmentRefs } from "relay-runtime";
export type EasopOrganizationSettingsBilling_Organization$data = {
  readonly billableGrantees: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"BillableGranteesSlideOver_Grantee">;
  }>;
  readonly currentPlan: {
    readonly billingPeriod: SubscriptionBillingPeriod;
    readonly geographiesFees: {
      readonly amountAfterDiscount: number;
      readonly amountBeforeDiscount: number;
      readonly discount: number | null;
      readonly discountEndDate: string | null;
    };
    readonly platformFees: {
      readonly amountAfterDiscount: number;
      readonly amountBeforeDiscount: number;
      readonly discount: number | null;
      readonly discountEndDate: string | null;
    } | null;
  } | null;
  readonly id: string;
  readonly name: string;
  readonly organizationGeographies: ReadonlyArray<{
    readonly __typename: "OrganizationGeography";
  }>;
  readonly upcomingInvoice: {
    readonly amountDue: number;
    readonly billingPeriod: SubscriptionBillingPeriod;
    readonly creationDate: string;
    readonly periodEnd: string | null;
    readonly periodStart: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"BillableGeographiesSlideOver_Organization" | "BillableGranteesSlideOver_Organization" | "FormattedCurrency_Organization">;
  readonly " $fragmentType": "EasopOrganizationSettingsBilling_Organization";
};
export type EasopOrganizationSettingsBilling_Organization$key = {
  readonly " $data"?: EasopOrganizationSettingsBilling_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"EasopOrganizationSettingsBilling_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billingPeriod",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amountBeforeDiscount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "amountAfterDiscount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "discount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "discountEndDate",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EasopOrganizationSettingsBilling_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StripeInvoice",
      "kind": "LinkedField",
      "name": "upcomingInvoice",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amountDue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "periodStart",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "periodEnd",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creationDate",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StripePlan",
      "kind": "LinkedField",
      "name": "currentPlan",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StripePlanItem",
          "kind": "LinkedField",
          "name": "platformFees",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StripePlanItem",
          "kind": "LinkedField",
          "name": "geographiesFees",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "billableGrantees",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BillableGranteesSlideOver_Grantee"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationGeography",
      "kind": "LinkedField",
      "name": "organizationGeographies",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillableGranteesSlideOver_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillableGeographiesSlideOver_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormattedCurrency_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "42b18e341623fe651342dc3687d2b622";

export default node;
