import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { DraftGrantDetailsGrant } from "../pages/Admin/Equity/PrepareYourGrants/DraftGrantDetails/DraftGrantDetailsGrant";
import { DraftGrantDetailsHeader } from "../pages/Admin/Equity/PrepareYourGrants/DraftGrantDetails/DraftGrantDetailsHeader";
import { DraftGrantDetailsVestingSchedule } from "../pages/Admin/Equity/PrepareYourGrants/DraftGrantDetails/DraftGrantDetailsVestingSchedule";
import {
  WarningEasopGrantOutdated,
  WarningValuationExpired,
} from "../pages/Admin/Equity/PrepareYourGrants/useInstrumentValuationGuard";
import { GranteeDetailsBox } from "../pages/Admin/Grantee/GranteeDetails/GranteeDetailsBox";
import { DraftGrantDetailsComponent_Account$key } from "./__generated__/DraftGrantDetailsComponent_Account.graphql";
import {
  DraftGrantDetailsComponent_EasopGrant$data,
  DraftGrantDetailsComponent_EasopGrant$key,
} from "./__generated__/DraftGrantDetailsComponent_EasopGrant.graphql";
import { DraftGrantDetailsComponent_Grantee$key } from "./__generated__/DraftGrantDetailsComponent_Grantee.graphql";
import { DraftGrantDetailsComponent_Organization$key } from "./__generated__/DraftGrantDetailsComponent_Organization.graphql";
import { DraftGrantVestingPreview } from "./DraftGrantVestingPreview";
import { FormattedFMV } from "./FormattedFMV";
import { ShortDate } from "./ShortDate";
import { InformationRows } from "./ui/InformationRows";
import { NoticeMessage } from "./ui/NoticeMessage";
import { RoundedBox } from "./ui/RoundedBox";
import { Tag } from "./ui/Tag";
import { Typography } from "./ui/Typography";

const EASOP_GRANT_FRAGMENT = graphql`
  fragment DraftGrantDetailsComponent_EasopGrant on EasopGrant {
    id
    label
    vestingSchedule {
      id
    }
    vestingStartDate
    warnings
    isVirtual
    quantityGranted
    exercisePrice
    exercisePriceBelowFMVSetOn
    earlyExercise
    accelerationClause
    warnings
    instrumentValuationType
    postTerminationExercisePeriod {
      displayName
    }
    ...DraftGrantDetailsVestingSchedule_EasopGrant
    ...DraftGrantDetailsGrant_EasopGrant
    ...DraftGrantDetailsHeader_EasopGrant
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment DraftGrantDetailsComponent_Organization on Organization {
    ...FormattedFMV_Organization
    ...GranteeDetailsBox_Organization
    ...DraftGrantDetailsGrant_Organization
    ...DraftGrantDetailsHeader_Organization
    ...DraftGrantVestingPreview_Organization
  }
`;

const GRANTEE_FRAGMENT = graphql`
  fragment DraftGrantDetailsComponent_Grantee on Grantee {
    ...DraftGrantVestingPreview_Grantee
    ...GranteeDetailsBox_Grantee
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment DraftGrantDetailsComponent_Account on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    ...DraftGrantDetailsVestingSchedule_Account
    ...GranteeDetailsBox_Viewer @arguments(organizationId: $organizationId)
    ...DraftGrantDetailsGrant_Viewer @arguments(organizationId: $organizationId)
  }
`;

const getAccelerationClauseMessage = (
  accelerationClause: DraftGrantDetailsComponent_EasopGrant$data["accelerationClause"],
) => {
  if (!accelerationClause) return "No";

  switch (accelerationClause) {
    case "DoubleTrigger25":
      return "Yes, double trigger (25%) clause";
    case "DoubleTrigger35":
      return "Yes, double trigger (35%) clause";
    case "DoubleTrigger50":
      return "Yes, double trigger (50%) clause";
    case "DoubleTrigger100":
      return "Yes, double trigger (100%) clause";
    case "SingleTrigger":
      return "Yes, single trigger clause";
  }
};

const PostTerminationExercisePeriodBox: React.FC<{
  easopGrant: DraftGrantDetailsComponent_EasopGrant$data;
}> = ({ easopGrant }) => {
  const ptepLabel = easopGrant.isVirtual
    ? "Post-termination settlement period"
    : "Post-termination exercise period";
  return (
    <RoundedBox className="flex flex-col gap-4 p-6" withBorder withShadow>
      <Typography variant="Medium/Small">{ptepLabel}</Typography>
      <InformationRows>
        <InformationRows.Row label={ptepLabel}>
          {easopGrant.postTerminationExercisePeriod.displayName}
        </InformationRows.Row>
      </InformationRows>
    </RoundedBox>
  );
};

export const DraftGrantDetailsComponent: React.FC<
  Pick<
    React.ComponentProps<typeof DraftGrantDetailsHeader>,
    | "deleteGrantIsInFlight"
    | "onDeleteButtonClicked"
    | "onEditButtonClicked"
    | "showActionButtons"
  > & {
    easopGrantFragment: DraftGrantDetailsComponent_EasopGrant$key;
    granteeFragment: DraftGrantDetailsComponent_Grantee$key;
    organizationFragment: DraftGrantDetailsComponent_Organization$key;
    viewerFragment: DraftGrantDetailsComponent_Account$key;
  }
> = ({
  deleteGrantIsInFlight,
  easopGrantFragment,
  granteeFragment,
  onDeleteButtonClicked,
  onEditButtonClicked,
  organizationFragment,
  showActionButtons,
  viewerFragment,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const easopGrant = useFragment(EASOP_GRANT_FRAGMENT, easopGrantFragment);

  return (
    <div className="flex flex-col gap-4">
      <DraftGrantDetailsHeader
        deleteGrantIsInFlight={deleteGrantIsInFlight}
        easopGrantFragment={easopGrant}
        onDeleteButtonClicked={onDeleteButtonClicked}
        onEditButtonClicked={onEditButtonClicked}
        organizationFragment={organization}
        showActionButtons={showActionButtons}
      />
      {easopGrant.warnings.includes("VALUATION_EXPIRED") && (
        <WarningValuationExpired
          instrumentValuationType={easopGrant.instrumentValuationType}
        />
      )}
      {easopGrant.warnings.includes("VALUATION_OUTDATED") && (
        <WarningEasopGrantOutdated
          instrumentValuationType={easopGrant.instrumentValuationType}
        />
      )}
      <div className="flex flex-col gap-6">
        <DraftGrantDetailsGrant
          easopGrantFragment={easopGrant}
          organizationFragment={organization}
          viewerFragment={viewer}
        />
        <DraftGrantDetailsVestingSchedule
          easopGrantFragment={easopGrant}
          viewerFragment={viewer}
        >
          <DraftGrantVestingPreview
            draftGrant={{
              id: easopGrant.id,
              label: easopGrant.label,
              quantityGranted: easopGrant.quantityGranted,
              vestingScheduleId: easopGrant.vestingSchedule.id,
              vestingStartDate: easopGrant.vestingStartDate,
            }}
            granteeFragment={grantee}
            organizationFragment={organization}
          />
        </DraftGrantDetailsVestingSchedule>

        <RoundedBox className="flex flex-col gap-4 p-6" withBorder withShadow>
          <Typography variant="Medium/Small">Exercise</Typography>
          {easopGrant.exercisePriceBelowFMVSetOn ? (
            <NoticeMessage className="mt-4" size="Small">
              <>
                on <ShortDate value={easopGrant.exercisePriceBelowFMVSetOn} />,
                the exercise price was set below the fair market value, which
                could have adverse tax consequences.
              </>
            </NoticeMessage>
          ) : null}
          <InformationRows>
            <InformationRows.Row
              label={
                easopGrant.isVirtual
                  ? "Virtual exercise price per share"
                  : "Exercise price per share"
              }
            >
              <div className="flex items-center gap-4">
                {easopGrant.warnings.includes("VALUATION_OUTDATED") ? (
                  <Tag className="uppercase" color="red">
                    Exercise price outdated
                  </Tag>
                ) : null}
                <FormattedFMV
                  organizationFragment={organization}
                  value={easopGrant.exercisePrice}
                />
              </div>
            </InformationRows.Row>
            <InformationRows.Row
              label={
                easopGrant.isVirtual
                  ? "Number of SARs (fully vested)"
                  : "Number of shares (fully vested)"
              }
            >
              <FormattedNumber value={easopGrant.quantityGranted} />{" "}
              {easopGrant.quantityGranted > 1 ? "shares" : "share"}
            </InformationRows.Row>
            <InformationRows.Row
              label={
                easopGrant.isVirtual
                  ? "Total virtual exercise price"
                  : "Total exercise price"
              }
            >
              <FormattedFMV
                organizationFragment={organization}
                value={easopGrant.quantityGranted * easopGrant.exercisePrice}
              />
            </InformationRows.Row>
          </InformationRows>
        </RoundedBox>

        <PostTerminationExercisePeriodBox easopGrant={easopGrant} />

        {!easopGrant.isVirtual && (
          <RoundedBox className="flex flex-col gap-4 p-6" withBorder withShadow>
            <Typography variant="Medium/Small">Early exercise</Typography>
            <InformationRows>
              <InformationRows.Row label="Allow grantee to early exercise">
                {easopGrant.earlyExercise ? "Yes" : "No"}
              </InformationRows.Row>
            </InformationRows>
          </RoundedBox>
        )}

        <RoundedBox className="flex flex-col gap-4 p-6" withBorder withShadow>
          <Typography variant="Medium/Small">Acceleration</Typography>
          <InformationRows>
            <InformationRows.Row label="Allow grantee to vest earlier in case of exit">
              {getAccelerationClauseMessage(easopGrant.accelerationClause)}
            </InformationRows.Row>
          </InformationRows>
        </RoundedBox>
        <GranteeDetailsBox
          granteeFragment={grantee}
          organizationFragment={organization}
          viewerFragment={viewer}
        />
      </div>
    </div>
  );
};
