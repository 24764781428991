/**
 * @generated SignedSource<<d03111edb65d5e3056185c284669416b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CTMSSolution = "ANGELLIST" | "ASTRELLA" | "CAPDESK" | "CARTA" | "CERTENT" | "COMPTRACK" | "EQUIFY" | "GLOBAL_SHARES" | "LEDGY" | "LTSE_EQUITY" | "NO_CTMS" | "OTHER" | "PULLEY" | "SHAREWORKS" | "SPREADSHEETS" | "VESTD";
export type CTMSPage_SetEssentialsCTMSOnboardingStepAsCompleted_Mutation$variables = {
  declaredCTMSSolution?: CTMSSolution | null;
  organizationId: string;
};
export type CTMSPage_SetEssentialsCTMSOnboardingStepAsCompleted_Mutation$data = {
  readonly setEssentialsCTMSOnboardingStepAsCompleted: {
    readonly declaredCTMSSolution: CTMSSolution | null;
  };
};
export type CTMSPage_SetEssentialsCTMSOnboardingStepAsCompleted_Mutation = {
  response: CTMSPage_SetEssentialsCTMSOnboardingStepAsCompleted_Mutation$data;
  variables: CTMSPage_SetEssentialsCTMSOnboardingStepAsCompleted_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "declaredCTMSSolution"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "declaredCTMSSolution",
    "variableName": "declaredCTMSSolution"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "declaredCTMSSolution",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CTMSPage_SetEssentialsCTMSOnboardingStepAsCompleted_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "setEssentialsCTMSOnboardingStepAsCompleted",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CTMSPage_SetEssentialsCTMSOnboardingStepAsCompleted_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "setEssentialsCTMSOnboardingStepAsCompleted",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a371cfd5e0481a7f73ac3f06ffb47cff",
    "id": null,
    "metadata": {},
    "name": "CTMSPage_SetEssentialsCTMSOnboardingStepAsCompleted_Mutation",
    "operationKind": "mutation",
    "text": "mutation CTMSPage_SetEssentialsCTMSOnboardingStepAsCompleted_Mutation(\n  $declaredCTMSSolution: CTMSSolution\n  $organizationId: OrganizationId!\n) {\n  setEssentialsCTMSOnboardingStepAsCompleted(declaredCTMSSolution: $declaredCTMSSolution, organizationId: $organizationId) {\n    declaredCTMSSolution\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "510c3ec17e409417e197d419d9c66890";

export default node;
