/**
 * @generated SignedSource<<7b83c2650eed10550cd49355ef1525f0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TestLawFirmCartaConnectionView_Viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"RemoteEquityOnboardingLayout_Viewer">;
  readonly " $fragmentType": "TestLawFirmCartaConnectionView_Viewer";
};
export type TestLawFirmCartaConnectionView_Viewer$key = {
  readonly " $data"?: TestLawFirmCartaConnectionView_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"TestLawFirmCartaConnectionView_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TestLawFirmCartaConnectionView_Viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemoteEquityOnboardingLayout_Viewer"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "8d214e68fe267f5c724b9301ac035b8d";

export default node;
